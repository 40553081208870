import React, { FC, useCallback, useEffect, useState } from 'react';
import { CreateInterviewRequest, Interview } from '../../../api/model';
import ServiziSociali from '../../../../../api/ServiziSociali';
import { Submitting } from './Submitting';
import { Error } from './Error';
import { Success } from './Success';

interface OperationResultProps {
  request: CreateInterviewRequest;
}

export const InterviewOperationResult: FC<OperationResultProps> = ({
  request,
}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Interview>();
  const [error, setError] = useState<Error>();

  const post = useCallback(() => {
    setError(undefined);
    setLoading(true);
    ServiziSociali.journal.interviews.create(
      r => {
        setResponse(r);
        setLoading(false);
      },
      e => {
        setError(e);
        setLoading(false);
      },
      request
    );
  }, [request]);

  useEffect(post, [post]);

  if (loading) return <Submitting />;
  if (error) return <Error onRetry={post} />;
  if (response) return <Success interviewId={response.id} />;
  return null;
};
