import { StateMachine } from 'redux-sigma';
import {
  EditProcedureEvents,
  errorSubmitting,
  procedureSubmitted,
  UserEditBenefits,
  UserEditExpenses,
  UserEditIsee,
} from './editProcedure.events';
import {
  WelfareInstanceDTO,
  WelfareInstanceEditRequest,
  WelfareModuleTypeDTO,
} from '../../gen/api/welfare';
import { createLocalStateMachineContext } from '@moveax/redux-stm-act-react-utils';
import { useContext } from 'react';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import { editProcedure } from '../../components/serviziSociali/api/api';
import store from '../../store';

export enum EditProcedureStmStates {
  editing = 'editing',
  submitting = 'submitting',
  exitConfirm = 'exitConfirm',
  submitted = 'submitted',
}

export enum SectionToEdit {
  expenses = 'expenses',
  benefits = 'benefits',
  isee = 'isee',
}

interface Context {
  section: SectionToEdit;
  procedure: WelfareInstanceEditRequest;
  procedureId: string;
  procedureUpdated?: WelfareInstanceDTO;
}
export class EditProcedureStm extends StateMachine<
  EditProcedureEvents,
  EditProcedureStmStates,
  string,
  Context
> {
  protected readonly initialState = EditProcedureStmStates.editing;

  readonly name = 'editSocialService';

  protected readonly spec = {
    [EditProcedureStmStates.editing]: {
      transitions: {
        [EditProcedureEvents.userEditExpenses]: {
          command: this.saveExpenses,
          target: EditProcedureStmStates.submitting,
        },
        [EditProcedureEvents.userEditBenefits]: {
          command: this.saveBenefits,
          target: EditProcedureStmStates.submitting,
        },
        [EditProcedureEvents.userEditIsee]: {
          command: this.saveIsee,
          target: EditProcedureStmStates.submitting,
        },
        [EditProcedureEvents.userAskExit]: EditProcedureStmStates.exitConfirm,
      },
    },
    [EditProcedureStmStates.exitConfirm]: {
      transitions: {
        [EditProcedureEvents.userAbortExit]: EditProcedureStmStates.editing,
      },
    },
    [EditProcedureStmStates.submitting]: {
      onEntry: this.submit,
      transitions: {
        [EditProcedureEvents.procedureSubmitted]:
          EditProcedureStmStates.submitted,
        [EditProcedureEvents.errorSubmitting]: EditProcedureStmStates.editing,
      },
    },
    [EditProcedureStmStates.submitted]: {},
  };

  *saveExpenses(event: UserEditExpenses): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.procedure.details.undeductedExpenses = event.payload;
    });
  }

  *saveBenefits(event: UserEditBenefits): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.procedure.details.benefits = event.payload;
    });
  }

  *saveIsee(event: UserEditIsee): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.procedure.details.isee = event.payload;
    });
  }

  *submit(): SagaGenerator<void> {
    try {
      const request: WelfareInstanceEditRequest = {
        details: {
          ...this.context.procedure.details,
          module: WelfareModuleTypeDTO.AModule,
        },
      };
      const procedureUpdated = yield* call(
        editProcedure,
        this.context.procedureId,
        request
      );
      yield* this.setContext(ctx => {
        ctx.procedureUpdated = procedureUpdated;
      });
      yield* put(procedureSubmitted());
    } catch (e) {
      yield* call(
        store.app.snackBar.open,
        "Errore durante l'aggiornamento della pratica"
      );
      yield* put(errorSubmitting());
    }
  }
}

export const editProcedureStm = new EditProcedureStm();

export const EditProcedureContext = createLocalStateMachineContext(
  editProcedureStm
);

export function useEditProcedure() {
  return useContext(EditProcedureContext);
}
