import React, { FC, useCallback } from 'react';
import { Checkbox } from './partial/Checkbox';
import { GenericFlagProps } from './model';
import { StyleRules } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';

const styles = (_theme: any): StyleRules => ({
  container: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '896px',
    padding: '4px',
    borderBottom: '1px solid #DDDDDD',
  },
});

const _BooleanFlag: FC<GenericFlagProps> = ({
  label,
  data,
  onChange: onChangeCallback,
  classes,
}) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeCallback(event.target.checked ? [] : undefined);
    },
    [onChangeCallback]
  );

  return (
    <div className={classes?.container}>
      <p
        style={{
          color: '#5C6771',
        }}
      >
        {label}
      </p>
      <Checkbox
        checked={data !== undefined}
        onChange={onChange}
        color="primary"
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const BooleanFlag = withStyles(styles)(_BooleanFlag);
