import { CitizenDTO, UpdateCitizenRequest } from '../../../gen/api/welfare';
import {
  CitizenContext,
  FlagDTO,
  PersonalSituation,
} from '../../serviziSociali/api/model';
import CitizenApi from '../../../api/NewCittadini';
import { v4 } from 'uuid';

export const updateCitizen = (
  data: UpdateCitizenRequest
): Promise<CitizenContext> => {
  return new Promise((resolve, reject) => {
    CitizenApi.updateCitizen(
      data,
      (data: CitizenDTO) => {
        resolve({
          flags: [...data.flags].reduce(
            (acc: PersonalSituation, curr: FlagDTO) => {
              acc[curr.type] = curr.values;
              return acc;
            },
            {}
          ),
          familyMembers: data.familyMembers.map(m => ({
            id: v4(),
            ...m,
          })),
          relativeMembers: data.relativeMembers.map(m => ({
            id: v4(),
            ...m,
          })),
        });
      },
      reject
    );
  });
};
