import React from 'react';
import { observer } from 'mobx-react';

class UserLoggedIn extends React.Component {
  closeModal = () => {
    this.setState(
      {
        show: false,
      },
      () => {
        if (this.props.onClose) this.props.onClose();
      }
    );
  };

  render() {
    return (
      <>
        <div />
      </>
    );
  }
}

export default observer(UserLoggedIn);
