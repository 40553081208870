import { createAction } from '@reduxjs/toolkit';
import { WelfareInstanceDTO } from '../../gen/api/welfare';
import { TemporaryDocument } from '../../components/serviziSociali/api/model';

export enum ProcedureDetailEvents {
  procedureLoaded = 'events/procedureLoaded',
  errorLoading = 'events/errorLoading',
  notFound = 'events/notFound',
  procedureUpdated = 'events/procedureUpdated',
  setTemporaryExecutiveDecision = 'events/setTemporaryExecutiveProcedure',
  setTemporaryAccountingDecision = 'events/setTemporaryAccountingProcedure',
}

export const procedureLoaded = createAction(
  ProcedureDetailEvents.procedureLoaded
);
export const errorLoading = createAction(ProcedureDetailEvents.errorLoading);

export const notFound = createAction(ProcedureDetailEvents.notFound);

export const procedureUpdated = createAction<WelfareInstanceDTO>(
  ProcedureDetailEvents.procedureUpdated
);
export type ProcedureUpdated = ReturnType<typeof procedureUpdated>;

export const setTemporaryExecutiveDecision = createAction<
  TemporaryDocument | undefined
>(ProcedureDetailEvents.setTemporaryExecutiveDecision);

export type SetTemporaryExecutiveDecision = ReturnType<
  typeof setTemporaryExecutiveDecision
>;

export const setTemporaryAccountingDecision = createAction<
  TemporaryDocument | undefined
>(ProcedureDetailEvents.setTemporaryAccountingDecision);

export type SetTemporaryAccountingDecision = ReturnType<
  typeof setTemporaryAccountingDecision
>;
