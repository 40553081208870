import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { useApproveRejectInstanceContext } from '../../../../../../../stm/socialServices/approveRejectInstanceStm';
import { userPressedBack } from '../../../../../../../stm/socialServices/common.events';
import { userConfirmedRejectingInstance } from '../../../../../../../stm/socialServices/approveRejectingInstanceEvents';
import { WelfareInstanceStatusDTO } from '../../../../../../../gen/api/welfare';
import { useProcedureDetail } from '../../../../../../../stm/socialServices/procedureDetail.stm';

const styles = (_theme: any) => ({
  container: {
    width: '432px',
    padding: '16px',
  },
  dialogTitleContainer: {
    borderBottom: '1px solid #DCDCDC',
    padding: '0 0 16px 0',
  },
  dialogContent: {
    borderBottom: '1px solid #DCDCDC',
    padding: '16px 0 24px 0',
  },
  dialogTitle: {
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '150%',
  },
  dialogDescription: {
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
  },
  input: {
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '150%',
  },
  cancelButton: {
    width: '158px',
  },
  confirmButton: {
    width: '172px',
    marginRight: '16px',
  },
});

function titleFromStatus(status: WelfareInstanceStatusDTO | undefined): string {
  switch (status) {
    case WelfareInstanceStatusDTO.RejectedByEmployee:
    case WelfareInstanceStatusDTO.ApprovedByEmployee:
      return "Sicuro di voler rimandare in lavorazione l'istanza?";
    default:
      return "Sicuro di voler rifiutare l'istanza?";
  }
}

const RejectInstanceDialog: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { state: detailState } = useProcedureDetail();
  const stm = useApproveRejectInstanceContext();
  const [rejectReason, setRejectReason] = useState('');
  const onReasonChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRejectReason(event.target.value);
  }, []);
  const onCancel = useCallback(() => {
    stm.dispatch(userPressedBack());
  }, [stm]);
  const confirmReject = useCallback(() => {
    stm.dispatch(userConfirmedRejectingInstance({ reason: rejectReason }));
  }, [stm, rejectReason]);
  return (
    <div className={classes.container}>
      <DialogTitle className={classes.dialogTitleContainer} id="dialog-title">
        <span className={classes.dialogTitle}>
          {titleFromStatus(detailState.context?.procedure?.status)}
        </span>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <span className={classes.dialogDescription}>
          Inserisci la motivazione del rifiuto.
        </span>
        <FormControl style={{ width: '100%', marginTop: '24px' }}>
          <InputLabel htmlFor="rejectReason" shrink={true}>
            Note
          </InputLabel>
          <Input
            id="rejectReason"
            className={classes.input}
            autoFocus={true}
            multiline
            style={{
              width: '100%',
            }}
            rows={1}
            rowsMax={3}
            inputProps={{
              maxLength: 255,
            }}
            value={rejectReason}
            onChange={onReasonChange}
            placeholder="Inserisci la motivazione del rifiuto"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          color="primary"
          onClick={onCancel}
        >
          Annulla
        </Button>
        <Button
          className={classes.confirmButton}
          variant="raised"
          color="primary"
          disabled={!rejectReason}
          onClick={confirmReject}
        >
          Conferma
        </Button>
      </DialogActions>
    </div>
  );
};

const DecoratedRejectInstanceDialog = withStyles(styles)(RejectInstanceDialog);
export { DecoratedRejectInstanceDialog as RejectInstanceDialog };
