import React, { FC, useState } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import { useProcedureDetail } from '../../../../../stm/socialServices/procedureDetail.stm';
import { DetailHeader } from './common/DetailHeader';
import { ProcedureDetail } from './layout/ProcedureDetail';
import { WorkingInstance } from './layout/WorkingInstance';

const styles = (_theme: any) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
    padding: '24px',
  },
});

const Detail: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { state } = useProcedureDetail();

  const [tabSelected, setTabSelected] = useState(1);

  return (
    <>
      <DetailHeader onTabChanged={setTabSelected} />
      <div className={classes.bodyContainer}>
        {tabSelected === 0 && (
          <ProcedureDetail procedure={state.context!.procedure!} />
        )}
        {tabSelected === 1 && (
          <WorkingInstance procedure={state.context!.procedure!} />
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedDetail = withStyles(styles)(Detail);

export { DecoratedDetail as Detail };
