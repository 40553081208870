/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BenefitTypeDTO {
    ContributiPerIlSostegnoLocazione = 'CONTRIBUTI_PER_IL_SOSTEGNO_LOCAZIONE',
    AssegnoDiMaternita = 'ASSEGNO_DI_MATERNITA',
    PensioneSociale = 'PENSIONE_SOCIALE',
    PensioneDiInvaliditaCivile = 'PENSIONE_DI_INVALIDITA_CIVILE',
    IndennitaDiAccompagnamento = 'INDENNITA_DI_ACCOMPAGNAMENTO',
    IndennitaDiDisoccupazione = 'INDENNITA_DI_DISOCCUPAZIONE',
    ContributiEconomiciComunali = 'CONTRIBUTI_ECONOMICI_COMUNALI',
    ContributiEconomiciRegionali = 'CONTRIBUTI_ECONOMICI_REGIONALI',
    RenditeInail = 'RENDITE_INAIL',
    AltreRenditeOIndennita = 'ALTRE_RENDITE_O_INDENNITA'
}

export function BenefitTypeDTOFromJSON(json: any): BenefitTypeDTO {
    return BenefitTypeDTOFromJSONTyped(json, false);
}

export function BenefitTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BenefitTypeDTO {
    return json as BenefitTypeDTO;
}

export function BenefitTypeDTOToJSON(value?: BenefitTypeDTO | null): any {
    return value as any;
}

