import React from 'react';
import { PROCEDURE_TYPE } from '../../constants/common';
import BuildingProcedure from '../edilizia/pratiche/BuildingProcedure';
import LegacyProcedure from '../legacy/LegacyProcedure';

const PROCEDURE_TYPE_COMPONENT_MAP = {
  [PROCEDURE_TYPE.LEGACY]: LegacyProcedure,
  [PROCEDURE_TYPE.BUILDING]: BuildingProcedure,
};

const Procedure = props => {
  const Container = PROCEDURE_TYPE_COMPONENT_MAP[props.procedure.type];

  return <Container {...props} />;
};

export default Procedure;
