import { extendObservable, action } from 'mobx';
import SnackBar from './SnackBar';
import Profile from './Profile';
import AuthApi from '../api/Auth';
import ROUTES from '../constants/routes';
import navigateTo from '../utils/navigate';

export default class App {
  constructor() {
    extendObservable(this, {
      profile: new Profile(),
      snackBar: new SnackBar(),
      listFilters: {
        procedureListFilters: {},
        buildingProceduresListFilters: {},
        buildingProceduresArchiveListFilters: {},
        buildingDepartmentTimetableHistoryListFilters: {},
        citizenListFilters: {},
        requestListFiltersNew: {},
        requestListFiltersRejected: {},
        scadenziarioDaProcessare: {},
      },
      tabs: {
        gestionePratiche: 0,
        gestioneCittadini: 0,
      },
    });
  }

  checkAuth = action((onSuccess = () => {}, onError = () => {}) => {
    AuthApi.checkAuth(
      data => {
        this.profile.init(data);
        onSuccess();
      },
      error => {
        if (error.status === 403) {
          navigateTo(`/${ROUTES.UNATHORIZED_PERSONNEL}`);
          return;
        }
        onError();
        navigateTo(`/${ROUTES.LOGIN}`);
      }
    );
  });

  setFilters = action((listName, filters) => {
    if (this.listFilters.hasOwnProperty(listName)) {
      this.listFilters[listName] = filters;
    }
  });

  setTabIndex = action((name, tab) => {
    if (this.tabs.hasOwnProperty(name)) {
      this.tabs[name] = tab;
    }
  });

  getFilterValue = function(list, key, defaultValue) {
    return list.hasOwnProperty(key) ? list[key] : defaultValue;
  };
}
