import React, { FC, useCallback } from 'react';
import { UpdateCitizenRequest } from '../../../gen/api/welfare';
import { CitizenContext, FamilyMember } from '../../serviziSociali/api/model';
import store from '../../../store';
import { updateCitizen } from './api';
import { FamiliarDialog } from '../../serviziSociali/common/FamiliarDialog';
import { fromContextFlagToApi } from './utils';

interface AddFamilyMembersDialogProps {
  defaultCitizenData: CitizenContext;
  fiscalCode: string;
  updateCitizenContext: (value: CitizenContext) => void;
  closeDialog: () => void;
  forbiddenCFValues?: string[];
}

export const AddFamilyMembersDialog: FC<AddFamilyMembersDialogProps> = ({
  defaultCitizenData,
  fiscalCode,
  updateCitizenContext,
  closeDialog,
  forbiddenCFValues = [],
}) => {
  const { snackBar } = store.app;

  const save = useCallback(
    async (f: FamilyMember) => {
      try {
        const request: UpdateCitizenRequest = {
          citizenUpdateRequest: {
            relativeMembers: defaultCitizenData.relativeMembers,
            flags: fromContextFlagToApi(defaultCitizenData.flags),
            familyMembers: [...defaultCitizenData.familyMembers, f],
          },
          fiscalCode,
        };
        const response = await updateCitizen(request);
        updateCitizenContext(response);
        closeDialog();
      } catch (e) {
        snackBar.open('Si è verificato un errore imprevisto');
      }
    },
    [
      closeDialog,
      defaultCitizenData.familyMembers,
      defaultCitizenData.flags,
      defaultCitizenData.relativeMembers,
      fiscalCode,
      snackBar,
      updateCitizenContext,
    ]
  );

  return (
    <FamiliarDialog
      open
      onSave={save}
      forbiddenCFValues={[fiscalCode, ...forbiddenCFValues]}
      onClose={closeDialog}
    />
  );
};
