import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StatusBadge from '../Common/StatusBadge';
import { Col, Row } from 'react-flexbox-grid';
import DetailsButton from './DetailsButton';
import PropTypes from 'prop-types';
import store from '../../../store';
import { getDepartmentLabel } from '../../../constants/common';
import { ROLES } from '../../../constants/security';
import Number from './Number';
import moment from 'moment';

const styles = theme => ({
  container: {
    margin: '5px 0px',
    border: 'solid 1px',
    padding: '10px 15px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    borderRadius: '1px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    display: 'flex',
    alignItems: 'center',
    height: '90px',
  },
  details: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0',
  },
  detailsContainer: {
    fontSize: '14px',
  },
  detailsElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  elementHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  elementValue: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  flexValue: {
    display: 'flex',
    alignItems: 'center',
  },
  elementIcon: {
    color: '#0073E6',
    marginRight: '8px',
    fontSize: '16px',
  },
  actionContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const ListItem = withRouter(
  withStyles(styles)(props => {
    const { classes, instance, onDetailsClick } = props;
    const { profile } = store.app;

    const onClick = useCallback(() => {
      onDetailsClick(instance);
    }, [instance, onDetailsClick]);

    const isOwner = useMemo(() => {
      return profile.operatingAs.department === instance.ownerDepartment;
    }, [profile, instance]);

    const isManager = useMemo(() => {
      return profile.isHigherOrEqualThanForDepartment([
        {
          department: instance.destDepartment,
          role: ROLES.PO,
        },
      ]);
    }, [profile, instance]);

    const assignedTo = useMemo(() => {
      return instance.assignedTo
        ? `${instance.assignedTo.firstName} ${instance.assignedTo.lastName}`
        : 'Non Assegnata';
    }, [instance]);

    return (
      <div className={classes.container}>
        <Number number={instance.id} />
        <StatusBadge state={instance.status} />
        <Row className={classes.details}>
          <Col xs={10} className={classes.detailsContainer}>
            <Row>
              <Col xs={4} className={classes.detailsElement}>
                <span className={classes.elementHeader}>
                  {isOwner ? 'RICHIESTA A' : 'RICHIESTO DA'}
                </span>
                <span className={classes.elementValue}>
                  {isOwner
                    ? getDepartmentLabel(instance.destDepartment)
                    : getDepartmentLabel(instance.ownerDepartment)}
                </span>
              </Col>
              <Col xs={4} className={classes.detailsElement}>
                <span className={classes.elementHeader}>RICHIESTO IL</span>
                <span className={classes.elementValue}>
                  {moment.utc(instance.createdAt).format('DD/MM/YYYY HH:mm')}
                </span>
              </Col>
              {!isOwner && isManager && (
                <Col xs={4} className={classes.detailsElement}>
                  <span className={classes.elementHeader}>ASSEGNATO A</span>
                  <span className={classes.elementValue}>{assignedTo}</span>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} className={classes.detailsElement}>
                <span className={classes.elementHeader}>OGGETTO</span>
                <span className={classes.elementValue}>{instance.subject}</span>
              </Col>
            </Row>
          </Col>
          <Col xs={2} className={classes.actionContainer}>
            <DetailsButton
              size="small"
              color="default"
              variant="raised"
              onClick={onClick}
            />
          </Col>
        </Row>
      </div>
    );
  })
);

ListItem.propTypes = {
  onDetailsClick: PropTypes.func,
};

ListItem.defaultProps = {
  onDetailsClick: () => {},
};

export default ListItem;
