import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import DetailElement from '../../common/DetailElement';
import ProcedureStatusBadge from './ProcedureStatusBadge';
import {
  BUILDING_PROCEDURE_CATEGORY,
  BUILDING_PROCEDURE_STATUS,
  getLabelBuildingProcedureCategory,
} from '../../../constants/common';
import { Button } from '@material-ui/core';
import Subsection from '../../common/Subsection';
import PersonCard from '../../common/PersonCard';
import { SharingDetails } from '../../common/SharingDetails';
import Api from '../../../api/Edilizia';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import Loader from '../../common/Loader';
import EmptyListMessage from '../../common/EmptyListMessage';
import TableEventi from '../../common/TableEventi';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { RotturaSuoloDetail, RotturaSuoloDetailList } from './RoutturaSuolo';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import AdditionalDocumentsWidget from './RequestDocumentsWidget';
import InfoBox from '../../InfoBox';
import ProcedureExtensionDetails from '../../common/ProcedureExtensionDetails';
import {
  OccupazioneSuoloEdilizioDetail,
  OccupazioneSuoloEdilizioDetailList,
} from './OccupazioneSuoloEdilizio';
import {
  OccupazioneSuoloPubblicoDetail,
  OccupazioneSuoloPubblicoDetailList,
} from './OccupazioneSuoloPubblico';
import {
  TraslochiLavoriDetail,
  TraslochiLavoriDetailList,
} from './TraslochiLavori';
import ProcessWidget from './ProcessWidget';
import store from '../../../store';
import ProcedureApprovalWidget from '../../common/ProcedureApprovalWidget';
import PersonDetailsModal from '../../common/PersonDetailsModal';

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  centeredContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  containerNoPadding: {
    padding: '0 !important',
  },
  detailList: {
    margin: 0,
    listStyle: 'none',
    padding: '0',
  },
  descriptionIcon: {
    color: theme.palette.secondary.main,
  },
  expertsList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  documentList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  documentContainer: {
    display: 'flex',
    borderRadius: '20px',
    border: '1px solid #eee',
    padding: '10px 15px',
    marginRight: '5px',
    marginBottom: '5px',
    minWidth: '150px',
    maxWidth: '250px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    wordWrap: 'break-word',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
  documentIcon: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#555',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px',
    fontSize: '20px',
  },
  documentExtension: {
    marginTop: '2px',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  documentName: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  documentOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#6d6d6d',
    height: '100%',
    width: '100%',
    opacity: '.5',
    top: 0,
    left: 0,
    border: 'none',
    margin: '0',
    borderRadius: 'inherit',
    position: 'absolute',
    padding: 0,
    transition: 'opacity .5s',
    cursor: 'not-allowed',
  },
  documentDate: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
    bottom: '2px',
    right: '15px',
    color: '#555',
  },
  shareDocumentsActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: '10px',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  actionButtonContainer: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  outlinedBtn: {
    border: '2px solid ' + theme.palette.primary.main,
  },
});

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px!important',
  },
}))(Tooltip);

class BuildingProcedure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentsWidgetOpen: false,
      documentsWidgetLoading: false,
      additionalDocuments: [],
      showProcessDialog: false,
      processingProcedure: false,
      approvingProcedure: false,
      rejectingProcedure: false,
      selectedPerson: null,
    };
  }

  onUploadClicked = () => {
    this.props.onUploadClicked();
  };

  onProcessClick = () => {
    this.setState({
      showProcessDialog: true,
    });
  };

  onProcessDialogClose = () => {
    this.setState({
      showProcessDialog: false,
    });
  };

  processProcedure = protocolNumber => {
    this.setState(
      {
        processingProcedure: true,
      },
      () => {
        this.props.doProcedureAction(
          Api.process,
          [protocolNumber],
          () => {
            this.setState({
              processingProcedure: false,
              showProcessDialog: false,
            });
            store.app.snackBar.open('Operazione effettuata con successo');
          },
          error => {
            if (
              error.data.error_code === 100003 &&
              error.data.details.protocol_number
            ) {
              this.setState({
                processError: error.data.details.protocol_number[0],
              });
            }
            this.setState({
              processingProcedure: false,
            });
          }
        );
      }
    );
  };

  acceptProcedure = (files, onFinish) => {
    this.setState(
      {
        approvingProcedure: true,
      },
      () => {
        this.props.doProcedureAction(
          Api.approve,
          [files],
          () => {
            this.setState({
              approvingProcedure: false,
            });
            onFinish();
            this.props.loadDocuments(this.props.procedure);
            store.app.snackBar.open('Pratica approvata con successo.');
          },
          () => {
            this.setState({
              approvingProcedure: false,
            });
            onFinish();
          }
        );
      }
    );
  };

  rejectProcedure = () => {
    this.setState(
      {
        rejectingProcedure: true,
      },
      () => {
        this.props.doProcedureAction(
          Api.reject,
          () => {
            this.setState({
              rejectingProcedure: false,
            });
            store.app.snackBar.open('Pratica rifiutata con successo.');
          },
          () => {
            this.setState({
              rejectingProcedure: false,
            });
          }
        );
      }
    );
  };

  loadEvents = (pageNumber = { page: 1 }) => {
    this.props.loadEvents(pageNumber);
  };

  requestAdditionalDocuments = () => {
    this.setState({
      documentsWidgetOpen: true,
    });
  };

  onDocumentsWidgetClose = () => {
    this.setState({
      documentsWidgetOpen: false,
      additionalDocuments: [],
    });
  };

  onAdditionalDocumentsRequested = () => {
    this.setState(
      {
        documentsWidgetLoading: true,
      },
      () => {
        this.props.onAdditionalDocumentsRequested(
          this.state.additionalDocuments,
          () => {
            this.setState(
              {
                documentsWidgetOpen: false,
                documentsWidgetLoading: false,
                additionalDocuments: [],
              },
              () => {
                this.setState({
                  documentsWidgetLoading: false,
                });
              }
            );
          },
          () => {
            this.setState({
              documentsWidgetLoading: false,
            });
          }
        );
      }
    );
  };

  onAdditionalDocumentRemoved = index => {
    const additionalDocuments = [...this.state.additionalDocuments];
    additionalDocuments.splice(index, 1);
    this.setState({
      additionalDocuments: additionalDocuments,
    });
  };

  onAdditionalDocumentAdded = document => {
    const additionalDocuments = this.state.additionalDocuments;
    additionalDocuments.push(document);
    this.setState({
      additionalDocuments,
    });
  };

  onPersonClicked = person => {
    this.setState({
      selectedPerson: person,
    });
  };

  onPersonDetailsModalClosed = () => {
    this.setState({
      selectedPerson: null,
    });
  };

  render() {
    const {
      classes,
      isOwner,
      procedure,
      loadingDocuments,
      documents,
      uploadingDocument,
      downloadingDocument,
      loadingEvents,
      events,
      paginationEvents,
      onDocumentClick,
      onExtensionApproved,
      onExtensionRejected,
    } = this.props;

    const {
      approvingProcedure,
      rejectingProcedure,
      showProcessDialog,
      processingProcedure,
      processError,
      selectedPerson,
    } = this.state;

    const canPerformActions =
      procedure &&
      [
        BUILDING_PROCEDURE_STATUS.NEW,
        BUILDING_PROCEDURE_STATUS.PROCESSED,
        BUILDING_PROCEDURE_STATUS.PENDING,
      ].indexOf(procedure.status) !== -1 &&
      isOwner;

    const mandatoryDocumentUploaded =
      procedure && procedure.all_mandatory_documents_uploaded;
    const canProcess = mandatoryDocumentUploaded && !showProcessDialog;
    const disableActions = approvingProcedure || rejectingProcedure;
    const canUploadDocuments =
      procedure &&
      [
        BUILDING_PROCEDURE_STATUS.PROCESSED,
        BUILDING_PROCEDURE_STATUS.EXTENSION_REQUESTED,
      ].indexOf(procedure.status) !== -1;

    const standardDocuments = documents.filter(
      document => !document.is_approval
    );
    const approvalDocuments = documents.filter(
      document => document.is_approval
    );

    return (
      <>
        <InfoBox
          visible={
            isOwner &&
            procedure.status === BUILDING_PROCEDURE_STATUS.NEW &&
            !mandatoryDocumentUploaded
          }
          type="error"
          message="La pratica non può essere presa in carico poiché il cittadino non ha ancora caricato i documenti obbligatori"
        />

        <div
          className={classNames(classes.container, classes.containerNoPadding)}
        >
          <span>
            <Row>
              <Col xs={12}>
                <ul className={classes.detailList}>
                  <DetailElement
                    label="Stato"
                    value={
                      <ProcedureStatusBadge
                        style={{ marginTop: '5px' }}
                        state={procedure.status}
                      />
                    }
                    icon="nc-icon nc-chart-bar-32"
                    separator
                  />
                  <DetailElement
                    label="# Protocollo"
                    value={procedure.protocol}
                    icon="nc-icon nc-notes"
                    separator
                  />
                  <DetailElement
                    label="Categoria"
                    value={getLabelBuildingProcedureCategory(
                      procedure.category
                    )}
                    icon="nc-icon nc-tag-content"
                    separator
                  />
                  <DetailElement
                    label="Marca da bollo"
                    value={
                      procedure.stamp_number ? procedure.stamp_number : 'N/A'
                    }
                    icon="nc-icon nc-paper-2"
                    separator
                  />
                  <BuildingProcedureDetailElement procedure={procedure} />
                </ul>
              </Col>
              {canPerformActions && (
                <Col xs={12}>
                  {procedure.status === BUILDING_PROCEDURE_STATUS.NEW && (
                    <div className={classes.actionButtonContainer}>
                      <Button
                        onClick={this.onProcessClick}
                        variant="raised"
                        color="primary"
                        disabled={!canProcess}
                      >
                        Presa in carico
                      </Button>
                    </div>
                  )}
                  {procedure.status === BUILDING_PROCEDURE_STATUS.PROCESSED && (
                    <div className={classes.actionButtonContainer}>
                      <ProcedureApprovalWidget
                        procedureId={procedure.id}
                        procedureType={procedure.type}
                        onDone={this.acceptProcedure}
                        buttonStyle={{ marginRight: '20px' }}
                      />
                      <Button
                        disabled={disableActions}
                        style={{ marginRight: '20px' }}
                        onClick={this.rejectProcedure}
                        variant="raised"
                        color="default"
                      >
                        {rejectingProcedure ? (
                          <CircularProgress size={20} />
                        ) : (
                          'Rifiuta'
                        )}
                      </Button>
                      <Button
                        disabled={disableActions}
                        variant="outline"
                        color="primary"
                        className={classes.outlinedBtn}
                        onClick={this.requestAdditionalDocuments}
                      >
                        Richiedi documenti aggiuntivi
                      </Button>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </span>
        </div>

        <ProcedureExtensionDetails
          classes={classes}
          procedure={procedure}
          loadExtensions={Api.getExtensions}
          onApprove={onExtensionApproved}
          onReject={onExtensionRejected}
        />

        {procedure.owner && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(
                  classes.headerIcon,
                  'nc-icon nc-circle-09'
                )}
              />
              <span className={classes.headerTitle}>Richiedenti</span>
            </div>
            <Subsection>
              <Row>
                {procedure.creator.fiscal_code !==
                procedure.owner.fiscal_code ? (
                  <>
                    <PersonCard
                      additionalInfo="(Referente)"
                      person={procedure.creator}
                      personType={procedure.creator.type}
                      onPersonClicked={this.onPersonClicked}
                    />
                    <PersonCard
                      additionalInfo="(Proprietario)"
                      person={procedure.owner}
                      personType={procedure.owner.type}
                      onPersonClicked={this.onPersonClicked}
                    />
                  </>
                ) : (
                  <PersonCard
                    person={procedure.owner}
                    personType={procedure.owner.type}
                    onPersonClicked={this.onPersonClicked}
                  />
                )}
              </Row>
            </Subsection>
          </div>
        )}

        {procedure.experts.length > 0 && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(
                  classes.headerIcon,
                  'nc-icon nc-ruler-pencil'
                )}
              />
              <span className={classes.headerTitle}>Tecnici</span>
            </div>
            <Subsection>
              <Row>
                <div className={classes.expertsList}>
                  {procedure.experts.map((expert, index) => {
                    return (
                      <PersonCard
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        person={expert}
                        personType={expert.type}
                        isExpert
                        onPersonClicked={this.onPersonClicked}
                      />
                    );
                  })}
                </div>
              </Row>
            </Subsection>
          </div>
        )}

        <BuildingProcedureDetail procedure={procedure} />

        {isOwner && (
          <SharingDetails
            classes={classes}
            procedure={procedure}
            loadSharedTo={Api.getSharedTo}
          />
        )}

        <div className={classes.container}>
          <div className={classes.header}>
            <i
              className={classNames(
                classes.headerIcon,
                'nc-icon nc-single-copy-04'
              )}
            />
            <span className={classes.headerTitle}>Documenti</span>
            {isOwner && canUploadDocuments && (
              <Col className={classes.shareDocumentsActions}>
                <BiggerTooltip
                  title="Carica Documento"
                  className={classes.tooltip}
                >
                  <Button
                    size="small"
                    variant="raised"
                    color="primary"
                    disabled={uploadingDocument || loadingDocuments}
                    className={classes.smallActionButton}
                    onClick={this.onUploadClicked}
                  >
                    {!uploadingDocument ? (
                      <UploadIcon className={classes.smallActionButtonIcon} />
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </Button>
                </BiggerTooltip>
              </Col>
            )}
          </div>
          <Subsection>
            <Loader size={40} loading={loadingDocuments} />
            <EmptyListMessage
              visible={standardDocuments.length === 0 && !loadingDocuments}
            />
            {standardDocuments.length > 0 && !loadingDocuments && (
              <Row>
                <div className={classes.documentList}>
                  {standardDocuments.map(document => {
                    return (
                      <DocumentCard
                        key={document.id}
                        document={document}
                        onClick={onDocumentClick}
                        isDownloading={downloadingDocument === document.id}
                      />
                    );
                  })}
                </div>
              </Row>
            )}
          </Subsection>
        </div>

        {procedure.status === BUILDING_PROCEDURE_STATUS.APPROVED && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(
                  classes.headerIcon,
                  'nc-icon nc-single-copy-04'
                )}
              />
              <span className={classes.headerTitle}>
                Documenti di Approvazione
              </span>
            </div>
            <Subsection>
              <Loader size={40} loading={loadingDocuments} />
              <EmptyListMessage
                visible={approvalDocuments.length === 0 && !loadingDocuments}
              />
              {approvalDocuments.length > 0 && !loadingDocuments && (
                <Row>
                  <div className={classes.documentList}>
                    {approvalDocuments.map(document => {
                      return (
                        <DocumentCard
                          key={document.id}
                          document={document}
                          onClick={onDocumentClick}
                          isDownloading={downloadingDocument === document.id}
                        />
                      );
                    })}
                  </div>
                </Row>
              )}
            </Subsection>
          </div>
        )}

        {isOwner && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(classes.headerIcon, 'nc-icon nc-cctv')}
              />
              <span className={classes.headerTitle}>Eventi</span>
              <Col className={classes.shareDocumentsActions}>
                <BiggerTooltip title="Aggiorna" className={classes.tooltip}>
                  <Button
                    size="small"
                    variant="raised"
                    color="primary"
                    disabled={loadingEvents}
                    className={classes.smallActionButton}
                    onClick={this.loadEvents}
                  >
                    {!loadingEvents ? (
                      <RefreshIcon className={classes.smallActionButtonIcon} />
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </Button>
                </BiggerTooltip>
              </Col>
            </div>
            <Subsection>
              <Loader size={40} loading={loadingEvents} />
              <EmptyListMessage
                visible={events.length === 0 && !loadingEvents}
              />
              {events.length > 0 && !loadingEvents && (
                <TableEventi
                  goToUserDetail={this.props.goToUserDetails}
                  goToSessionDetails={this.props.goToSessionDetails}
                  loading={loadingEvents}
                  loadPage={this.loadEvents}
                  events={events}
                  pagination={paginationEvents}
                />
              )}
            </Subsection>
          </div>
        )}
        {isOwner && (
          <AdditionalDocumentsWidget
            loading={this.state.documentsWidgetLoading}
            open={this.state.documentsWidgetOpen}
            documents={this.state.additionalDocuments}
            onClose={this.onDocumentsWidgetClose}
            onConfirm={this.onAdditionalDocumentsRequested}
            onAdditionalDocumentRemoved={this.onAdditionalDocumentRemoved}
            onAdditionalDocumentAdded={this.onAdditionalDocumentAdded}
          />
        )}

        {isOwner && (
          <ProcessWidget
            loading={processingProcedure}
            open={showProcessDialog}
            onConfirm={this.processProcedure}
            confirmError={processError}
            onClose={this.onProcessDialogClose}
          />
        )}

        <PersonDetailsModal
          person={selectedPerson}
          onClose={this.onPersonDetailsModalClosed}
        />
      </>
    );
  }
}

const DocumentCard = withStyles(styles)(props => {
  const { classes, document, isDownloading } = props;

  const onClick = () => {
    if (props.onClick && !isDownloading) {
      props.onClick(document.id);
    }
  };

  return (
    <div className={classes.documentContainer} onClick={onClick}>
      <div className={classes.documentIcon}>
        <i className="nc-icon nc-single-copy-04" />
        <span className={classes.documentExtension}>{document.extension}</span>
      </div>
      <div className={classes.documentName}>{document.name}</div>
      <div className={classes.documentDate}>{document.date}</div>
      {isDownloading && (
        <div className={classes.documentOverlay}>
          <CircularProgress
            size={30}
            style={{ textAlign: 'center', color: '#fff' }}
          />
        </div>
      )}
    </div>
  );
});

const BuildingProcedureDetailElement = withStyles(styles)(props => {
  const { procedure } = props;
  const map = {
    [BUILDING_PROCEDURE_CATEGORY.ROTTURA_SUOLO]: (
      <RotturaSuoloDetailList procedure={procedure} />
    ),
    [BUILDING_PROCEDURE_CATEGORY.OCCUPAZIONE_SUOLO_EDILIZIO]: (
      <OccupazioneSuoloEdilizioDetailList procedure={procedure} />
    ),
    [BUILDING_PROCEDURE_CATEGORY.OCCUPAZIONE_SUOLO_PUBBLICO]: (
      <OccupazioneSuoloPubblicoDetailList procedure={procedure} />
    ),
    [BUILDING_PROCEDURE_CATEGORY.TRASLOCHI_LAVORI]: (
      <TraslochiLavoriDetailList procedure={procedure} />
    ),
  };

  return map[procedure.category];
});

const BuildingProcedureDetail = withStyles(styles)(props => {
  const { procedure, classes } = props;
  const map = {
    [BUILDING_PROCEDURE_CATEGORY.ROTTURA_SUOLO]: (
      <RotturaSuoloDetail procedure={procedure} classes={classes} />
    ),
    [BUILDING_PROCEDURE_CATEGORY.OCCUPAZIONE_SUOLO_EDILIZIO]: (
      <OccupazioneSuoloEdilizioDetail procedure={procedure} classes={classes} />
    ),
    [BUILDING_PROCEDURE_CATEGORY.OCCUPAZIONE_SUOLO_PUBBLICO]: (
      <OccupazioneSuoloPubblicoDetail procedure={procedure} classes={classes} />
    ),
    [BUILDING_PROCEDURE_CATEGORY.TRASLOCHI_LAVORI]: (
      <TraslochiLavoriDetail procedure={procedure} classes={classes} />
    ),
  };

  return map[procedure.category];
});

DocumentCard.propTypes = {
  classes: PropTypes.object,
  document: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isDownloading: PropTypes.bool,
};

BuildingProcedureDetailElement.propTypes = {
  classes: PropTypes.object,
  procedure: PropTypes.object.isRequired,
};

export default withStyles(styles)(observer(BuildingProcedure));
