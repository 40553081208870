import React, { FC, useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import {
  SECTION_LABEL_MAP,
  SECTION_SERVICE_MAP,
  SERVICE_LABEL_MAP,
} from '../../constants';
import { SectionArrow } from './partial/SectionArrow';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import {
  userSelectKind,
  userSelectSection,
} from '../../../../stm/socialServices/newProcedure.events';
import { SectionTitle } from '../../common/SectionTitle';
import { StepperFooter } from '../StepperFooter';
import { RadioButton } from '../../common/RadioButton';
import { StyledComponentProps } from '@material-ui/core';
import { WelfareInstanceSectionDTO } from '../../../../gen/api/welfare';

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    alignSelf: 'stretch',
    padding: '16px 48px',
  },
  sections: {
    flex: 1,
    alignSelf: 'stretch',
  },
  separator: {
    width: '1px',
    backgroundColor: theme.palette.font.greyLight,
    margin: '0px 32px',
    alignSelf: 'stretch',
  },
  scrollableContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '60vh',
  },
});

const SelectSection: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { dispatch, state } = useNewSocialService();
  const handleSelectSection = useCallback(
    s => () => {
      dispatch(userSelectSection(s));
    },
    [dispatch]
  );
  const handleSelectType = useCallback(
    t => () => {
      dispatch(userSelectKind({ kind: t }));
    },
    [dispatch]
  );

  const section = state.context?.actualForm
    ?.section as WelfareInstanceSectionDTO;
  const kinds = useMemo(
    () =>
      SECTION_SERVICE_MAP[section]?.map(k => (
        <RadioButton
          key={k}
          onClick={handleSelectType}
          kind={{ id: k, text: SERVICE_LABEL_MAP[k] }}
          selected={state.context?.actualForm?.kind === k}
        />
      )),
    [handleSelectType, state.context, section]
  );
  return (
    <>
      <SectionTitle>
        Seleziona la sezione e la tipologia dell&apos;istanza
      </SectionTitle>
      <div className={classes.container}>
        <div className={classes.sections}>
          {Object.values(WelfareInstanceSectionDTO).map(s => (
            <SectionArrow
              key={s}
              onClick={handleSelectSection(s)}
              text={SECTION_LABEL_MAP[s]}
              selected={s === section}
            />
          ))}
        </div>
        <div className={classes.separator} />
        <div className={classes.sections}>
          <div className={classes.scrollableContainer}>{kinds}</div>
        </div>
      </div>
      <StepperFooter />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSelectSection = withStyles(styles)(SelectSection);

export { DecoratedSelectSection as SelectSection };
