import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  ActivityDuration,
  CreateInterviewRequest,
  CreateSecretariatActivityRequest,
  JournalActivityKind,
} from '../api/model';
import { StyleRules } from '@material-ui/core/styles';
import { NavControls } from './common/NavControls';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { SetDuration } from './SetDuration';
import { InstanceSectionValue, SelectSection } from './SelectSection';
import { SelectKind } from './SelectKind';
import { SelectCitizen } from './SelectCitizen';
import { AddNote } from './AddNote';
import { CitizenForm } from '../common/CitizenForm';
import { SecretariatOperationResult } from './secretariat/OperationResult';
import { InterviewOperationResult } from './interviews/OperationResult';

const styles = (_theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
  },
});

interface Data {
  kind?: JournalActivityKind;
  request: Partial<CreateInterviewRequest & CreateSecretariatActivityRequest>;
}

enum State {
  SelectKind,
  SelectCitizen,
  AddCitizen,
  SelectSection,
  AddNote,
  SetDuration,
  OperationResult,
}

const kindState = {
  [JournalActivityKind.INTERVIEW]: [
    State.SelectKind,
    State.SelectCitizen,
    State.SelectSection,
    State.AddNote,
    State.SetDuration,
    State.OperationResult,
  ],
  [JournalActivityKind.SECRETARIAT]: [
    State.SelectKind,
    State.SelectCitizen,
    State.AddCitizen,
    State.AddNote,
    State.OperationResult,
  ],
};

function getSecretariatMaxState(data: Data): State {
  if (data.request.note) {
    return State.OperationResult;
  }

  if (data.request.citizenFiscalCode) {
    return State.AddNote;
  }

  if (data.kind) {
    return State.SelectCitizen;
  }

  return State.SelectKind;
}

function getInterviewMaxState(data: Data): State {
  if (
    data.request.activityDuration &&
    data.request.activityDuration.startDate &&
    data.request.activityDuration.endDate
  ) {
    return State.OperationResult;
  }
  if (data.request.section) {
    return State.SetDuration;
  }
  if (data.request.citizenFiscalCode) {
    return State.SelectSection;
  }
  if (data.kind) {
    return State.SelectCitizen;
  }
  return State.SelectKind;
}

const NewJournalActivityPage: FC<RouteComponentProps &
  // eslint-disable-next-line complexity
  StyledComponentProps> = ({ history, classes = {} }) => {
  const [data, setData] = useState<Data>({ request: {} });
  const [state, setState] = useState<State>(State.SelectKind);
  const maxState = useMemo<State>(() => {
    if (!data.kind) return State.SelectKind;
    return data.kind === JournalActivityKind.SECRETARIAT
      ? getSecretariatMaxState(data)
      : getInterviewMaxState(data);
  }, [data]);

  const states = useMemo<State[]>(() => {
    if (!data.kind) return [];
    return kindState[data.kind];
  }, [data.kind]);

  const onNavNext = useCallback(() => {
    if (
      data.kind === JournalActivityKind.SECRETARIAT &&
      state === State.SelectCitizen &&
      data.request.citizenFiscalCode
    ) {
      setState(State.AddNote);
      return;
    }
    const index = states.indexOf(state);
    setState(states[index + 1]); //next state
  }, [data.kind, data.request.citizenFiscalCode, state, states]);

  const onNavBack = useCallback(() => {
    if (state === State.SelectKind) {
      history.goBack(); //default state; go back
      return;
    }
    setState(state => state - 1); //prev state
  }, [history, state]);

  const onSelectKind = useCallback((kind: Data['kind']) => {
    //rests all data in form
    setData({ kind, request: {} });
  }, []);

  const onSelectCitizen = useCallback(
    (cf?: string) => {
      setData({ ...data, request: { ...data.request, citizenFiscalCode: cf } });
    },
    [data]
  );

  const onSelectSection = useCallback((value?: InstanceSectionValue) => {
    setData(currData => {
      return {
        ...currData,
        request: {
          ...currData.request,
          section: value?.section,
          instanceId: value?.instanceId,
        },
      };
    });
  }, []);

  const onCitizenCreated = useCallback(() => {
    setState(State.AddNote);
  }, []);

  const onAddCitizen = useCallback(
    (fiscalCode?: string) => {
      setData({
        ...data,
        request: { ...data.request, citizenFiscalCode: fiscalCode },
      });
      setState(State.AddCitizen);
    },
    [data]
  );

  const onAddNote = useCallback(
    (value?: Partial<Pick<CreateInterviewRequest, 'note' | 'documents'>>) => {
      setData(currData => {
        return {
          ...currData,
          request: {
            ...currData.request,
            note: value?.note,
            documents: value?.documents ? [...value?.documents] : [],
          },
        };
      });
    },
    []
  );

  const goBackToSearch = useCallback(() => {
    setData({
      ...data,
      request: { ...data.request, citizenFiscalCode: undefined },
    });
    setState(State.SelectCitizen);
  }, [data]);

  const onSetDuration = useCallback((value?: ActivityDuration) => {
    setData(currData => {
      return {
        ...currData,
        request: {
          ...currData.request,
          activityDuration: value as ActivityDuration,
        },
      };
    });
  }, []);

  switch (state) {
    case State.SelectKind:
      return (
        <div className={classes.container}>
          <SelectKind value={data.kind} onChange={onSelectKind} />
          <NavControls
            backProps={{
              text: 'Indietro',
              onClick: onNavBack,
            }}
            nextProps={{
              text: 'Avanti',
              onClick: onNavNext,
              disabled: maxState < State.SelectCitizen,
            }}
          />
        </div>
      );
    case State.SelectCitizen:
      return (
        <div className={classes.container}>
          <SelectCitizen
            notFoundMode={
              data.kind === JournalActivityKind.SECRETARIAT ? 'add' : 'fail'
            }
            citizenFiscalCode={data.request.citizenFiscalCode}
            onChangeCitizenFiscalCode={onSelectCitizen}
            onAddClicked={
              data.kind === JournalActivityKind.SECRETARIAT
                ? onAddCitizen
                : undefined
            }
          />
          <NavControls
            backProps={{
              text: 'Indietro',
              onClick: onNavBack,
            }}
            nextProps={{
              text: 'Avanti',
              onClick: onNavNext,
              disabled: maxState < State.SelectSection,
            }}
          />
        </div>
      );
    case State.AddCitizen:
      return (
        <div className={classes.container}>
          <CitizenForm
            cf={data.request.citizenFiscalCode}
            onCompleted={onCitizenCreated}
            goBack={goBackToSearch}
          />
        </div>
      );
    case State.SelectSection:
      return (
        <div className={classes.container}>
          <SelectSection
            beneficiaryFiscalCode={data.request.citizenFiscalCode!}
            value={
              data.request.section
                ? {
                    section: data.request.section,
                    instanceId: data.request.instanceId,
                  }
                : undefined
            }
            onChange={onSelectSection}
          />
          <NavControls
            backProps={{
              text: 'Indietro',
              onClick: onNavBack,
            }}
            nextProps={{
              text: 'Avanti',
              onClick: onNavNext,
              disabled: maxState < State.AddNote,
            }}
          />
        </div>
      );

    case State.AddNote:
      return (
        <div className={classes.container}>
          <AddNote
            value={
              data.request.note || data.request.documents
                ? { note: data.request.note, documents: data.request.documents }
                : undefined
            }
            onChange={onAddNote}
          />
          <NavControls
            backProps={{
              text: 'Indietro',
              onClick: onNavBack,
            }}
            nextProps={{
              text: 'Avanti',
              onClick: onNavNext,
              disabled: maxState < State.SetDuration,
            }}
          />
        </div>
      );

    case State.SetDuration:
      return (
        <div className={classes.container}>
          <SetDuration
            value={data.request.activityDuration}
            onChange={onSetDuration}
          />
          <NavControls
            backProps={{
              text: 'Indietro',
              onClick: onNavBack,
            }}
            nextProps={{
              text: 'Avanti',
              onClick: onNavNext,
              disabled: maxState < State.OperationResult,
            }}
          />
        </div>
      );
    case State.OperationResult:
      return (
        <div className={classes.container}>
          {data.kind === JournalActivityKind.SECRETARIAT && (
            <SecretariatOperationResult
              request={data.request as CreateSecretariatActivityRequest}
            />
          )}
          {data.kind === JournalActivityKind.INTERVIEW && (
            <InterviewOperationResult
              request={data.request as CreateInterviewRequest}
            />
          )}
        </div>
      );
  }
  return null;
};

const DecoratedNewJournalActivityPage = withStyles(styles)(
  withRouter(NewJournalActivityPage)
);
export { DecoratedNewJournalActivityPage as NewJournalActivityPage };
