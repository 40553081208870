import { withStyles } from '@material-ui/core';
import React from 'react';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    backgroundColor: theme.palette.primary.main,
    marginRight: '20px',
    borderRadius: '5px',
    padding: '5px 25px',
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    height: '60%',
    borderRight: '1px solid #FFF',
    opacity: '.5',
    marginRight: '10px',
    marginLeft: '10px',
  },
  header: {
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
    marginBottom: '5px',
  },
});

export default withStyles(styles)(props => {
  const { classes, number } = props;

  return (
    <div className={classes.container}>
      <div className={classes.element}>
        <div className={classes.header}>NUMERO</div>
        <div>{number ? `${number}`.padStart(6, '0') : '-'}</div>
      </div>
    </div>
  );
});
