/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareModuleTypeDTO {
    AModule = 'A_MODULE'
}

export function WelfareModuleTypeDTOFromJSON(json: any): WelfareModuleTypeDTO {
    return WelfareModuleTypeDTOFromJSONTyped(json, false);
}

export function WelfareModuleTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelfareModuleTypeDTO {
    return json as WelfareModuleTypeDTO;
}

export function WelfareModuleTypeDTOToJSON(value?: WelfareModuleTypeDTO | null): any {
    return value as any;
}

