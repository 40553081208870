import NewApiCallManager from './NewApiCallManager';

export default {
  citizenContext: (cf, onSuccess, onError) => {
    NewApiCallManager.get(
      `welfare/citizens/${cf}`,
      response => onSuccess(response.data),
      onError
    );
  },
  updateCitizen: (data, onSuccess, onError) => {
    NewApiCallManager.put(
      `welfare/citizens/${data.fiscalCode}`,
      response => onSuccess(response.data),
      onError,
      data.citizenUpdateRequest
    );
  },
};
