import React, { FC } from 'react';
import { withStyles } from '@material-ui/core';
import { StyledComponentProps } from '@material-ui/core';
import cx from 'classnames';

const styles = (theme: { [key: string]: any }) => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '38.4px',
  },
});

const SectionTitle: FC<StyledComponentProps & { className?: string }> = ({
  children,
  classes = {},
  className,
}) => <p className={cx(classes.title, className)}>{children}</p>;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSectionTitle = withStyles(styles)(SectionTitle);

export { DecoratedSectionTitle as SectionTitle };
