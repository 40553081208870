import React, { FC, useCallback, useState } from 'react';
import { Avatar, StyledComponentProps, withStyles } from '@material-ui/core';
import cx from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import 'moment/locale/it';
import moment from 'moment';
import store from '../../../../../../store';
import { avatarInitials } from '../../../../../../utils/string';
import Document from '../../../../common/Document';
import Subsection from '../../../../../common/Subsection';
import {
  WelfareInstanceDTO,
  WelfareInstanceEventDTO,
  WelfareInstanceEventTypeDTO,
  WelfareInstanceStatusDTO,
} from '../../../../../../gen/api/welfare';
import Icon from '../../../../../../new/components/icons';
import { getDocument } from '../../../../api/api';

moment.locale('it');

const EVENT_TYPE_APPROVE_EVENT = [
  WelfareInstanceEventTypeDTO.ExecutiveDecisionSigned,
  WelfareInstanceEventTypeDTO.InstanceApproved,
  WelfareInstanceEventTypeDTO.AccountingDecisionAdded,
];

const EVENT_TYPE_REWORK_EVENT = [WelfareInstanceEventTypeDTO.OutcomeRejected];

const EVENT_TYPE_REJECT_EVENT = [WelfareInstanceEventTypeDTO.InstanceRejected];

const EVENT_TYPE_PROMISCUOUS_EVENT = [
  WelfareInstanceEventTypeDTO.OutcomeConfirmed,
];

const EVENT_TYPE_LABEL: { [key in WelfareInstanceEventTypeDTO]: string } = {
  [WelfareInstanceEventTypeDTO.NoteAdded]: '',
  [WelfareInstanceEventTypeDTO.InstanceApproved]: `ha approvato l'istanza`,
  [WelfareInstanceEventTypeDTO.InstanceRejected]: `ha rifiutato l'istanza`,
  [WelfareInstanceEventTypeDTO.OutcomeConfirmed]: `ha confermato la decisione dell'assistente sociale`,
  [WelfareInstanceEventTypeDTO.OutcomeRejected]: `ha richiesto la rilavorazione dell'istanza`,
  [WelfareInstanceEventTypeDTO.AccountingDecisionAdded]: `ha aggiunto la determina in ragioneria`,
  [WelfareInstanceEventTypeDTO.ExecutiveDecisionSigned]: `ha firmato digitalmente la determina`,
};

const styles = (theme: {
  palette: {
    primary: { main: any };
    black: { main: any };
    grey: { dark: any };
  };
}) => ({
  container: {
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },
  noPadding: {
    padding: 0,
    margin: 0,
  },
  greenBottomBorder: {
    borderBottom: '8px solid #00B676',
  },
  yellowBottomBorder: {
    borderBottom: '8px solid #EECE7D',
  },
  redBottomBorder: {
    borderBottom: '8px solid #D1344C',
  },
  card: {
    padding: 0,
    backgroundColor: 'white',
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    borderRadius: 4,
  },
  header: {
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #DDD',
  },
  headerAvatar: {
    marginRight: 8,
    width: 48,
    height: 48,
    backgroundColor: theme.palette.primary.main,
  },
  headerTitle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  departmentLabel: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.black.main,
  },
  date: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey.dark,
    lineHeight: '21px',
  },
  messageBody: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    padding: '32px 24px',
    fontSize: 16,
    lineHeight: '30px',
  },
  subNote: {
    fontSize: 14,
    fortWeight: 400,
    color: theme.palette.grey.dark,
  },
  documents: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  documentsSeparator: {
    margin: '0 24px',
    height: 1,
    borderTop: '1px solid #ddd',
  },
});
const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;

interface MessageRowProps extends StyledComponentProps {
  message: WelfareInstanceEventDTO;
  instance: WelfareInstanceDTO;
}
// eslint-disable-next-line complexity
const MessageRow: FC<MessageRowProps> = ({
  message,
  instance,
  classes = {},
}) => {
  const { snackBar } = store.app;
  const [downloadingDocument, setDownloadingDocument] = useState(undefined);

  const isNote = message.type === WelfareInstanceEventTypeDTO.NoteAdded;

  const isApprovalMessage =
    EVENT_TYPE_APPROVE_EVENT.includes(message.type) ||
    (EVENT_TYPE_PROMISCUOUS_EVENT.includes(message.type) &&
      instance.status !== WelfareInstanceStatusDTO.Rejected);

  const isRejectMessage =
    EVENT_TYPE_REJECT_EVENT.includes(message.type) ||
    (EVENT_TYPE_PROMISCUOUS_EVENT.includes(message.type) &&
      instance.status === WelfareInstanceStatusDTO.Rejected);

  const isReworkMessage = EVENT_TYPE_REWORK_EVENT.includes(message.type);

  const onDocumentClicked = useCallback(
    (fid, name) => {
      setDownloadingDocument(fid);
      getDocument(fid, name)
        .then(() => setDownloadingDocument(undefined))
        .catch(() => {
          snackBar.open(`Errore nel download del file ${name}`);
          setDownloadingDocument(undefined);
        });
    },
    [snackBar]
  );

  return (
    <div className={classes.container}>
      <Row className={classes.noPadding}>
        <Col
          className={cx(classes.card, {
            [classes.greenBottomBorder as string]: isApprovalMessage,
            [classes.redBottomBorder as string]: isRejectMessage,
            [classes.yellowBottomBorder as string]: isReworkMessage,
          })}
          xs={12}
        >
          <div className={classes.header}>
            <Avatar className={classes.headerAvatar}>
              {avatarInitials(
                `${message.performedBy.firstName} ${message.performedBy.lastName}`
              )}
            </Avatar>
            <div className={classes.headerTitle}>
              <span className={classes.departmentLabel}>
                {message.performedBy.firstName} {message.performedBy.lastName}
              </span>
              <span className={classes.date}>{`aggiunto il ${moment(
                message.createdAt
              )
                .format('DD/MM/YYYY H:mm')
                .replace(' ', ' alle ')}`}</span>
            </div>
          </div>
          {isNote && (
            <div className={classes.messageBody}>{message.message}</div>
          )}
          {isApprovalMessage && (
            <div className={classes.messageBody}>
              <div>
                <span className={classes.bold}>
                  {message.performedBy.firstName}&nbsp;
                  {message.performedBy.lastName}&nbsp;
                </span>
                {EVENT_TYPE_LABEL[message.type]}
              </div>
              <Icon type="CheckCircle" color="#00B676" size={48} />
            </div>
          )}
          {isRejectMessage && (
            <div className={classes.messageBody}>
              <div>
                <span className={classes.bold}>
                  {message.performedBy.firstName}&nbsp;
                  {message.performedBy.lastName}&nbsp;
                </span>
                {EVENT_TYPE_LABEL[message.type]}
              </div>
              <Icon type="ErrorCircle" color="#D1344C" size={48} />
              {message.message && (
                <div className={classes.subNote}>{message.message}</div>
              )}
            </div>
          )}
          {isReworkMessage && (
            <div className={classes.messageBody}>
              <div>
                <span className={classes.bold}>
                  {message.performedBy.firstName}&nbsp;
                  {message.performedBy.lastName}&nbsp;
                </span>
                {EVENT_TYPE_LABEL[message.type]}
              </div>
              <Icon type="BackCircle" color="#EECE7D" size={48} />
              {message.message && (
                <div className={classes.subNote}>{message.message}</div>
              )}
            </div>
          )}
          {isNote && message.documents && message.documents.length > 0 && (
            <>
              <Subsection noMargin>
                <div className={classes.documents}>
                  <span className={classes.documentsSeparator} />
                  {message.documents.map(doc => (
                    <Document
                      key={doc.fid}
                      documentId={doc.fid}
                      fileName={doc.name}
                      extension={DOCUMENT_EXTENSION.exec(doc.name)![1]}
                      date={doc.createdAt}
                      onClick={onDocumentClicked}
                      isDownloading={downloadingDocument === doc.fid}
                    />
                  ))}
                </div>
              </Subsection>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedMessageRow = withStyles(styles)(MessageRow);

export { DecoratedMessageRow as MessageRow };
