import React, { useCallback, useState } from 'react';
import { Avatar, withStyles } from '@material-ui/core';
import cx from 'classnames';
import { Row, Col } from 'react-flexbox-grid';
import store from '../../../store';
import Subsection from '../../common/Subsection';
import PropTypes from 'prop-types';
import Document from '../Common/Document';
import PraticheInterne from '../../../api/PraticheInterne';
import { getDepartmentLabel } from '../../../constants/common';
import 'moment/locale/it';
import moment from 'moment';
import { avatarInitials } from '../../../utils/string';
import Icon from '../../../new/components/icons';
moment.locale('it');

const EVENT_TYPE_NOTE = 'notes';
const EVENT_TYPE_SIGN_EVENT = 'sign_event';

const styles = theme => ({
  container: {
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },
  noPadding: {
    padding: 0,
  },
  greenBottomBorder: {
    borderBottom: '8px solid #00B676',
  },
  card: {
    padding: 0,
    backgroundColor: 'white',
    marginTop: 32,
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    borderRadius: 4,
  },
  header: {
    padding: '24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #DDD',
  },
  headerAvatar: {
    marginRight: 8,
    width: 48,
    height: 48,
    backgroundColor: theme.palette.primary.main,
  },
  headerTitle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  departmentLabel: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
    color: theme.palette.black.main,
  },
  date: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey.dark,
    lineHeight: '21px',
  },
  messageBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px 24px',
    fontSize: 18,
    lineHeight: '30px',
  },
  documents: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  documentsSeparator: {
    margin: '0 24px',
    height: 1,
    borderTop: '1px solid #ddd',
  },
});

const MessageRow = props => {
  const { classes, procedure, message } = props;
  const { snackBar } = store.app;
  const [downloadingDocument, setDownloadingDocument] = useState(undefined);

  const onDocumentClicked = useCallback(
    documentId => {
      setDownloadingDocument(documentId);
      PraticheInterne.download(
        procedure.id,
        documentId,
        () => {
          setDownloadingDocument(undefined);
        },
        () => {
          snackBar.open('Errore nel download del file.');
          setDownloadingDocument(undefined);
        }
      );
    },
    [procedure, snackBar]
  );

  return (
    <div className={classes.container}>
      <Row className={classes.noPadding}>
        <Col
          className={cx(classes.card, {
            [classes.greenBottomBorder]:
              message.event_type === EVENT_TYPE_SIGN_EVENT,
          })}
          xs={12}
        >
          <div className={classes.header}>
            <Avatar className={classes.headerAvatar}>
              {avatarInitials(getDepartmentLabel(message.department))}
            </Avatar>
            <div className={classes.headerTitle}>
              <span className={classes.departmentLabel}>
                {getDepartmentLabel(message.department)}
              </span>
              <span
                className={classes.date}
              >{`aggiunto il ${message.created_at.replace(
                ' ',
                ' alle '
              )}`}</span>
            </div>
          </div>
          {message.event_type === EVENT_TYPE_NOTE && (
            <div className={classes.messageBody}>{message.notes}</div>
          )}
          {message.event_type === EVENT_TYPE_SIGN_EVENT && (
            <div className={classes.messageBody}>
              <div>
                <span className={classes.bold}>
                  {getDepartmentLabel(message.department)}
                </span>
                &nbsp;ha apposto la firma digitale all&apos;atto
              </div>
              <Icon type="CheckCircle" color="#00B676" size={48} />
            </div>
          )}
          {message.event_type === EVENT_TYPE_NOTE &&
            message.documents.length > 0 && (
              <>
                <Subsection noMargin>
                  <div className={classes.documents}>
                    <span className={classes.documentsSeparator} />
                    {message.documents.map(doc => (
                      <Document
                        key={doc.id}
                        documentId={doc.id}
                        fileName={doc.name}
                        extension={doc.extension}
                        date={doc.date}
                        onClick={onDocumentClicked}
                        isDownloading={downloadingDocument === doc.id}
                      />
                    ))}
                  </div>
                </Subsection>
              </>
            )}
        </Col>
      </Row>
    </div>
  );
};

MessageRow.propTypes = {
  message: PropTypes.object.isRequired,
  procedure: PropTypes.object.isRequired,
  pinned: PropTypes.bool,
};

MessageRow.defaultProps = {
  pinned: false,
};

export default withStyles(styles)(MessageRow);
