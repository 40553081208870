import React, { FC, useCallback } from 'react';
import { SelectExpensesForm } from '../../../common/forms/SelectExpensesForm';
import { useEditProcedure } from '../../../../../stm/socialServices/editProcedure.stm';
import {
  userAskExit,
  userEditExpenses,
} from '../../../../../stm/socialServices/editProcedure.events';

export const EditUndetectedExpenses: FC = () => {
  const { state, dispatch } = useEditProcedure();

  const onConfirm = useCallback(
    expenses => {
      dispatch(userEditExpenses(expenses));
    },
    [dispatch]
  );
  const goBack = useCallback(() => {
    dispatch(userAskExit());
  }, [dispatch]);
  console.log(state);
  return (
    <SelectExpensesForm
      initialExpenses={
        state?.context?.procedure?.details?.undeductedExpenses ?? []
      }
      onConfirm={onConfirm}
      onAbort={goBack}
      abortText="Annulla"
      confirmText="Conferma i dati"
      skipText="Nessun spesa detratta"
    />
  );
};
