import React, { useCallback } from 'react';
import PageContainer from './common/PageContainer';
import { Col, Row } from 'react-flexbox-grid';
import { withStyles, Button } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import navigateTo from '../utils/navigate';

const styles = theme => ({
  errorPageContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px',
    color: theme.palette.primary.main,
  },
  giantIcon: {
    fontSize: '80px',
    marginBottom: '20px',
  },
  shoutText: {
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: '10px',
  },
  shoutTextSubtitle: {
    fontWeight: '300',
    fontStyle: 'italic',
    fontSize: '18px',
    marginBottom: '30px',
  },
});

export default withStyles(styles)(props => {
  const { classes } = props;

  const onBack = useCallback(() => {
    navigateTo('/');
  }, []);

  return (
    <PageContainer>
      <div>
        <Row>
          <Col xs={12} className={classes.errorPageContainer}>
            <div className={classes.errorContainer}>
              <ErrorOutline className={classes.giantIcon} fontSize={80} />
              <span className={classes.shoutText}>Pagina Non Trovata</span>
              <span className={classes.shoutTextSubtitle}>
                La pagina richiesta non è stata trovata
              </span>
              <Button onClick={onBack} variant="raised" color="primary">
                Torna alla Home
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
});
