import CallManager from './AxiosCallManager';

export default {
  list: ({ page, filters = {} }, onSuccess, onError) => {
    CallManager.get(
      '/departments/internal/procedures',
      response => onSuccess(response.data),
      onError,
      {
        page,
        ...filters,
      }
    );
  },
  create: (data, onSuccess, onError) => {
    CallManager.post(
      '/departments/internal/procedures',
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  get: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `/departments/internal/procedures/${procedureId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  share: (procedureId, departments, onSuccess, onError) => {
    CallManager.post(
      `/departments/internal/procedures/${procedureId}/shares`,
      response => onSuccess(response.data),
      onError,
      {
        departments,
      }
    );
  },
  reply: (procedureId, data, onSuccess, onError) => {
    CallManager.post(
      `/departments/internal/procedures/${procedureId}/messages`,
      onSuccess,
      onError,
      data
    );
  },
  sign: (
    procedureId,
    { username, password, otp, department },
    onSuccess,
    onError
  ) => {
    CallManager.patch(
      `/departments/internal/procedures/${procedureId}`,
      response => onSuccess(response.data),
      onError,
      { username, password, otp, department }
    );
  },
  close: (procedureId, onSuccess, onError) => {
    CallManager.delete(
      `/departments/internal/procedures/${procedureId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  deleteShare: (procedureId, shareId, onSuccess, onError) => {
    CallManager.delete(
      `/departments/internal/procedures/${procedureId}/shares/${shareId}`,
      onSuccess,
      onError
    );
  },
  availableDepartments: ({ filters }, onSuccess, onError) => {
    CallManager.get(
      '/departments/internal/procedures/departments',
      response => onSuccess(response.data),
      onError,
      filters
    );
  },
  messages: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `/departments/internal/procedures/${procedureId}/messages`,
      response => onSuccess(response.data),
      onError
    );
  },
  download: (procedureId, documentId, onFinished, onError) => {
    return CallManager.downloadFile(
      `/departments/internal/procedures/${procedureId}/documents/${documentId}`,
      onFinished,
      onError
    );
  },
};
