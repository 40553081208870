import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import DragAndDrop from '../../../DragAndDrop';
import Icon from '../../../../../new/components/icons';

const styles = theme => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 24px',
  },
  document: {
    flex: 1,
    borderBottom: '2px solid ' + theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    padding: '10px 20px',
    borderRadius: '1px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  documentNameContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  documentName: {
    fontFamily: theme.typography.fontFamily,
    textOverflow: 'ellipsis',
    marginRight: '10px',
    color: theme.palette.primary.main,
    overflow: 'hidden',
    marginLeft: '24px',
    maxWidth: '100%',
  },
  documentAction: {
    fontSize: '22px',
    justifySelf: 'flex-end',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
});

const SelectDocument = ({
  classes,
  onDocumentSelected,
  onDocumentDeleted,
  selectedDocument,
}) => {
  const onDrop = useCallback(
    file => {
      onDocumentSelected(file);
    },
    [onDocumentSelected]
  );

  return (
    <div className={classes.container}>
      {!selectedDocument ? (
        <DragAndDrop onDrop={onDrop} />
      ) : (
        <div className={classes.document}>
          <div className={classes.documentNameContainer}>
            <Icon type="Document" />
            <span className={classes.documentName}>
              {selectedDocument.name}
            </span>
          </div>
          <span className={classes.documentAction} onClick={onDocumentDeleted}>
            &times;
          </span>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(SelectDocument);
