import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageTitle from '../common/PageTitle';
import PageContainer from '../common/PageContainer';
import { withRouter } from 'react-router-dom';
import Widget from './Create/Widget';
import List, { Mode } from './List';
import PraticheInterne from '../../api/PraticheInterne';
import store from '../../store';

const TAB_MODES = [Mode.SHARED_BY_ME, Mode.SHARED_WITH_ME, Mode.ARCHIVED];

export default withRouter(() => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { snackBar, profile } = store.app;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [pagination, setPagination] = useState(undefined);

  const loadList = useCallback(
    (mode, page) => {
      setLoading(true);
      PraticheInterne.list(
        {
          page,
          filters: {
            mode,
            department: profile.operatingAs.department,
          },
        },
        response => {
          setProcedures(response.procedures);
          setPagination(response.meta.pagination);
          setLoading(false);
        },
        () => {
          snackBar.open('Si è verificato un errore imprevisto');
          setLoading(false);
        }
      );
    },
    [profile.operatingAs.department, snackBar]
  );

  const onPageChange = useCallback(page => {
    setPage(page);
  }, []);

  const mode = useMemo(() => {
    return TAB_MODES[selectedTab];
  }, [selectedTab]);

  const updateList = useCallback(() => {
    loadList(mode, 1);
  }, [loadList, mode]);

  const ListComponent = useMemo(() => {
    return (
      <List
        procedures={procedures}
        pagination={pagination}
        onPageChange={onPageChange}
        loading={loading}
        onProcedureShared={updateList}
      />
    );
  }, [procedures, pagination, loading, onPageChange, updateList]);

  useEffect(() => {
    loadList(mode, page);
  }, [loadList, mode, page]);

  return (
    <PageContainer
      whiteBg
      header={
        <PageTitle
          title="Atti Dirigenziali"
          tabs={['Inviati', 'Ricevuti', 'Archivio']}
          onTabChange={setSelectedTab}
          actions={<Widget onProcedureCreated={updateList} />}
        />
      }
    >
      {ListComponent}
    </PageContainer>
  );
});
