import CallManager from './NewApiCallManager';

export default {
  get: (onSuccess, pageNumber = 0, pageSize = 7) => {
    CallManager.get(
      `/notifications`,
      response => onSuccess(response.data),
      () => {},
      {
        pageNumber,
        pageSize,
      }
    );
  },
  markAsRead: (id, onDone = () => {}) => {
    CallManager.delete(`/notifications/${id}`, onDone);
  },
};
