import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const CreateButton = props => {
  const { onClick } = props;

  return (
    <Button variant="raised" color="primary" onClick={onClick}>
      Nuovo Atto
    </Button>
  );
};

CreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateButton;
