import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import ROUTES from '../../constants/routes';

class ManagerialActSigned extends React.Component {
  onClick = () => {
    const procedure = this.props.event.payload.procedure;
    this.props.history.push(`/${ROUTES.SCRIVANIA_VIRTUALE}/${procedure.id}`);
  };

  render() {
    const { event } = this.props;
    const procedure = event.payload.procedure;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onClick}>
              Atto n° {`${procedure.id}`.padStart(6, '0')}
            </TextLinkPrimary>
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(ManagerialActSigned));
