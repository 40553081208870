import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

const styles = () => ({
  container: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
  },
});

const Loader = props => {
  const { classes, loading, color, size, ...otherProps } = props;
  return (
    loading && (
      <div className={classes.container}>
        <CircularProgress
          classes={{
            svg: {
              height: size,
              width: size,
            },
          }}
          {...otherProps}
          color={color}
        />
      </div>
    )
  );
};

Loader.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
};

Loader.defaultProps = {
  loading: false,
  color: 'secondary',
};

export default withStyles(styles)(Loader);
