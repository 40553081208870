import React, { FC } from 'react';
import { BeneficiarySection } from '../common/BeneficiarySection';
import { RequestorSection } from '../common/RequestorSection';
import { CollapsableDetail } from './CollapsableDetail';
import { FileSection } from '../common/FileSection';
import { WelfareInstanceDTO } from '../../../../../../gen/api/welfare';

interface ProcedureDetailProps {
  procedure: WelfareInstanceDTO;
  readOnly?: boolean;
}

export const ProcedureDetail: FC<ProcedureDetailProps> = ({
  procedure,
  readOnly = false,
}) => {
  return (
    <>
      <BeneficiarySection beneficiary={procedure.beneficiary} />
      {procedure.beneficiary.fiscalCode !==
        procedure.requestor.person.fiscalCode && (
        <RequestorSection requestor={procedure.requestor} />
      )}
      <CollapsableDetail
        readOnly={readOnly}
        status={procedure.status}
        detail={procedure.details}
        type={procedure.type}
        section={procedure.section}
        assignTo={procedure.assignee}
        protocol={
          procedure.protocol
            ? `${procedure.protocol.number}/${procedure.protocol.year}`
            : undefined
        }
      />
      <FileSection readOnly={readOnly} docs={procedure.documents ?? []} />
    </>
  );
};
