import React, { FC, useCallback } from 'react';
import { withStyles, Button, StyledComponentProps } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';
import { compile } from 'path-to-regexp';

interface SuccessProps extends RouteComponentProps, StyledComponentProps {
  interviewId: string;
}

const styles = (theme: any) => ({
  container: {
    marginTop: '180px',
    alignSelf: 'center',
    width: '430px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    height: '96px',
    width: '96px',
    marginBottom: '24px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '700',
    marginBottom: '16px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    marginBottom: '56px',
  },
  button: {
    width: '232px',
    marginBottom: '8px',
  },
});

const Success: FC<SuccessProps> = ({ classes = {}, interviewId, history }) => {
  const goToHome = useCallback(() => {
    history.push(`/${ROUTES_SERVIZI_SOCIALI.DIARIO}`);
  }, [history]);

  const goToDetail = useCallback(() => {
    const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_COLLOQUIO}`, {
      encode: encodeURIComponent,
    });
    history.push(toPath({ interviewId }));
  }, [history, interviewId]);

  return (
    <div className={classes.container}>
      <img
        src={require('../../../../../assets/images/success-servizi-sociali.png')}
        alt="success"
        className={classes.image}
      />
      <label className={classes.title}>Operazione Riuscita</label>
      <label className={classes.text}>
        L&apos;attività è stata generata con successo.
        <br />
        Vai al dettaglio o torna al diario.
      </label>
      <Button
        variant="raised"
        color="primary"
        onClick={goToDetail}
        className={classes.button}
      >
        Vai al dettaglio
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={goToHome}
        className={classes.button}
      >
        Torna al diario
      </Button>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSuccess = withStyles(styles)(withRouter(Success));

export { DecoratedSuccess as Success };
