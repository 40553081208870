import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import FormControlValidated from '../../../FormControlValidated';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { DialogContentText, Input } from '@material-ui/core';

const styles = theme => ({
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

const SignCredentials = ({
  classes,
  errors,
  username,
  password,
  otp,
  onUsernameChange,
  onPasswordChange,
  onOtpChange,
}) => {
  return (
    <>
      <DialogContentText style={{ marginBottom: '30px' }}>
        Inserisci le note e gli eventuali allegati della determina
      </DialogContentText>
      <Row>
        <Col xs={12}>
          <FormControlValidated
            inputId="username"
            label="Username"
            error={errors.username}
            shrinkLabel={true}
          >
            <Input
              id="username"
              inputProps={{ tabIndex: 1 }}
              type="text"
              label="Username"
              onChange={e => onUsernameChange(e.target.value)}
              value={username}
              classes={{
                inkbar: !errors.username
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12}>
          <FormControlValidated
            inputId="password"
            label="Password"
            error={errors.password}
            shrinkLabel={true}
          >
            <Input
              id="password"
              inputProps={{ tabIndex: 1 }}
              type="password"
              label="Password"
              onChange={e => onPasswordChange(e.target.value)}
              value={password}
              classes={{
                inkbar: !errors.password
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12}>
          <FormControlValidated
            inputId="otp"
            label="OTP"
            error={errors.otp}
            shrinkLabel={true}
          >
            <Input
              id="otp"
              inputProps={{ tabIndex: 1 }}
              type="text"
              label="OTP"
              onChange={e => onOtpChange(e.target.value)}
              value={otp}
              classes={{
                inkbar: !errors.otp
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
      </Row>
    </>
  );
};

SignCredentials.propTypes = {
  errors: PropTypes.object,
  username: PropTypes.string,
  password: PropTypes.string,
  otp: PropTypes.string,
  onUsernameChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
  onOtpChange: PropTypes.func,
};

export default withStyles(styles)(SignCredentials);
