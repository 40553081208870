import CallManager from './AxiosCallManager';

export default {
  loadSessions: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      'sessions/',
      response => onSuccess(response.data),
      onError,
      { page: page, ...filters }
    );
  },
  loadSession: (sessionId, onSuccess, onError) => {
    CallManager.get(
      `sessions/${sessionId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  loadSessionEvents: ({ sessionId, page, filters }, onSuccess, onError) => {
    CallManager.get(
      `sessions/${sessionId}/events`,
      response => onSuccess(response.data),
      onError,
      { page: page, ...filters }
    );
  },
};
