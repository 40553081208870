import React, { FC, useCallback, useEffect, useState } from 'react';
import { SectionTitle } from '../common/SectionTitle';
import { StyleRules } from '@material-ui/core/styles';
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { SECTION_LABEL_MAP, SERVICE_LABEL_MAP } from '../constants';
import {
  WelfareInstanceListItemDTO,
  WelfareInstanceSectionDTO,
  WelfareInstanceStatusDTO,
} from '../../../gen/api/welfare';
import { getInstanceList } from '../api/api';
import EmptyListMessage from '../../common/EmptyListMessage';
import { SelectProps } from '@material-ui/core/Select';

const styles = (theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 48px',
    gap: '32px',
    width: '496px',
  },
  textLight: {
    color: '#A8B0B8',
  },
  instanceItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  instanceSectionType: {
    display: 'flex',
    flexDirection: 'column',
  },
  instanceSection: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  instanceNumber: {
    display: 'flex',
    width: 150,
  },
  instanceNumberBadge: {
    padding: '1px 6px',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: 'rgba(166, 245, 242, 0.7);',
    color: theme.palette.primary.main,
  },
});

export interface InstanceSectionValue {
  section?: WelfareInstanceSectionDTO;
  instanceId?: string;
}

interface SelectSectionProps extends StyledComponentProps {
  value?: InstanceSectionValue;
  beneficiaryFiscalCode: string;
  onChange: (data?: InstanceSectionValue) => void;
}

const options: {
  id: WelfareInstanceSectionDTO;
  text: string;
}[] = Object.values(WelfareInstanceSectionDTO).map(s => ({
  id: s,
  text: SECTION_LABEL_MAP[s],
}));

const SelectSection: FC<SelectSectionProps> = ({
  value,
  beneficiaryFiscalCode,
  onChange: onChangeCallback,
  classes = {},
}) => {
  const [userInstances, setUserInstances] = useState<
    WelfareInstanceListItemDTO[]
  >([]);
  const [loadingUserInstances, setIsLoadingUserInstances] = useState(false);
  const onSectionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newValue = event.target.value
        ? {
            instanceId: undefined,
            section: event.target.value as WelfareInstanceSectionDTO,
          }
        : undefined;
      onChangeCallback(newValue);
    },
    [onChangeCallback]
  );

  const onInstanceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newValue = event.target.value
        ? { section: value?.section, instanceId: event.target.value }
        : undefined;
      onChangeCallback(newValue);
    },
    [onChangeCallback, value]
  );

  const renderSelectedInstance = useCallback(
    (instanceId: SelectProps['value']) => {
      const instance = userInstances.find(i => i.id === instanceId);
      return (
        instance && (
          <div className={classes.instanceNumber}>
            <div className={classes.instanceNumberBadge}>
              {instance.protocol.number} / {instance.protocol.year}
            </div>
          </div>
        )
      );
    },
    [classes.instanceNumber, classes.instanceNumberBadge, userInstances]
  );

  useEffect(() => {
    if (value?.section) {
      setIsLoadingUserInstances(true);
      getInstanceList(0, 100, {
        beneficiaryFiscalCode,
        section: value?.section ? [value.section] : undefined,
        status: [
          WelfareInstanceStatusDTO.ApprovedByEmployee,
          WelfareInstanceStatusDTO.ApprovedByAdministrative,
          WelfareInstanceStatusDTO.ApprovedByPo,
          WelfareInstanceStatusDTO.Assigned,
          WelfareInstanceStatusDTO.Created,
          WelfareInstanceStatusDTO.RejectedByEmployee,
        ],
      })
        .then(data => {
          setUserInstances(data.instances);
        })
        .finally(() => {
          setIsLoadingUserInstances(false);
        });
    }
  }, [beneficiaryFiscalCode, value]);

  return (
    <div className={classes?.container}>
      <SectionTitle>
        Seleziona la sezione ed il numero dell&apos;istanza
      </SectionTitle>

      <div style={{ width: '100%' }}>
        <InputLabel shrink>Seleziona la sezione del colloquio</InputLabel>
        <Select
          value={value?.section}
          onChange={onSectionChange}
          inputProps={{ name: 'select-section', id: 'select-section' }}
          style={{ width: '481px' }}
        >
          {options.map(({ id: oId, text }) => (
            <MenuItem value={oId} key={`option-${oId}`}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div style={{ width: '100%' }}>
        <InputLabel shrink>
          Numero dell&apos;istanza{' '}
          <span className={classes.textLight}>(Opzionale)</span>
        </InputLabel>
        <Select
          renderValue={renderSelectedInstance}
          disabled={loadingUserInstances || !value?.section}
          value={value?.instanceId}
          onChange={onInstanceChange}
          inputProps={{ name: 'select-instance', id: 'select-instance' }}
          style={{ width: '481px' }}
        >
          {loadingUserInstances && <CircularProgress size={20} />}
          {!loadingUserInstances && userInstances.length === 0 && (
            <MenuItem disabled>
              <EmptyListMessage
                visible
                message="Nessuna istanza attiva per la sezione scelta"
              />
            </MenuItem>
          )}
          {!loadingUserInstances &&
            userInstances.length > 0 &&
            userInstances.map(instance => (
              <MenuItem
                className={classes.instanceItem}
                value={instance.id}
                key={`option-${instance.id}`}
              >
                <div className={classes.instanceNumber}>
                  <div className={classes.instanceNumberBadge}>
                    {instance.protocol.number} / {instance.protocol.year}
                  </div>
                </div>
                <div className={classes.instanceSectionType}>
                  <div className={classes.instanceSection}>
                    {SECTION_LABEL_MAP[instance.section]}
                  </div>
                  <div>{SERVICE_LABEL_MAP[instance.type]}</div>
                </div>
              </MenuItem>
            ))}
        </Select>
      </div>
    </div>
  );
};

const DecoratedSelectSection = withStyles(styles)(SelectSection);
export { DecoratedSelectSection as SelectSection };
