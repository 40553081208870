import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-flexbox-grid';
import PageContainer from '../common/PageContainer';
import EmptyListMessage from '../common/EmptyListMessage';
import PageTitle from '../common/PageTitle';
import Pagination from '../common/Pagination';
import ROUTES from '../../constants/routes';
import Loader from '../common/Loader';
import Pratiche from '../../api/PraticheGate';
import { PraticaBox } from '../common/PraticaBox';
import FilterItem from '../common/filters/Filter';
import {
  FILTER_TYPE,
  FILTRI_CATEGORIA_PRATICA,
} from '../common/filters/constants';
import GenericListFilters from '../common/filters/GenericListFilters';

const styles = theme => ({
  listBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '25px',
    marginBottom: '10px',
  },
  procedureTitle: {
    ...theme.typography.bodyStrong,
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  itemLabel: {
    fontWeight: 'bold',
  },
  procedureSubject: {
    ...theme.typography.bodyStrong,
    paddingBottom: '10px',
    fontSize: '13px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

class ListaPratiche extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pratiche: [],
      pagination: null,
      currFilters: {},
    };
  }

  componentDidMount() {}

  onPageChange = changedPage => {
    this.loadList(changedPage.page, this.state.currFilters, true);
  };

  loadList = (page = 1, filters, loading = false) => {
    this.setState({ loading: loading }, () => {
      Pratiche.loadList(
        { page, filters: filters },
        response => {
          this.setState({
            loading: false,
            pratiche: response.procedures,
            currFilters: filters,
            pagination: response.meta.pagination,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    });
  };

  applyFilters = filters => {
    this.loadList(1, filters, true);
  };

  render() {
    const { pagination } = this.state;
    return (
      <PageContainer whiteBg header={<PageTitle title="Lista Pratiche" />}>
        <GenericListFilters
          listName="procedureListFilters"
          filters={[
            new FilterItem(
              'year',
              FILTER_TYPE.NUMBER,
              'Anno',
              '',
              null,
              6,
              2,
              2,
              2
            ),
            new FilterItem(
              'number',
              FILTER_TYPE.NUMBER,
              'Numero',
              '',
              null,
              6,
              2,
              2,
              2
            ),
            new FilterItem('sub', FILTER_TYPE.TEXT, 'Sub.', '', '', 6, 2, 2, 2),
            new FilterItem(
              'category',
              FILTER_TYPE.SELECT,
              'Categoria',
              '',
              FILTRI_CATEGORIA_PRATICA,
              6,
              2,
              2,
              2
            ),
            new FilterItem(
              'referrer_name',
              FILTER_TYPE.TEXT,
              'Nome',
              '',
              '',
              6,
              2,
              2,
              2
            ),
            new FilterItem(
              'referrer_surname',
              FILTER_TYPE.TEXT,
              'Cognome',
              '',
              '',
              6,
              2,
              2,
              2
            ),
            new FilterItem(
              'location',
              FILTER_TYPE.TEXT,
              'Ubicazione',
              '',
              '',
              6,
              2,
              2,
              2
            ),
          ]}
          applyFilters={this.applyFilters}
        />
        <Loader size={40} loading={this.state.loading} />
        {!this.state.loading &&
          this.state.pratiche.map(pratica => {
            return (
              <PraticaBox
                key={pratica.id}
                goTo={`/${ROUTES.ARCHIVIO_GATE}/${pratica.id}`}
                procedure={pratica}
              />
            );
          })}
        <EmptyListMessage
          visible={this.state.pratiche.length === 0 && !this.state.loading}
        />
        {pagination && (
          <Row>
            <Col xs={12} sm={12}>
              <Pagination
                total={pagination.total_pages}
                current={pagination.current_page}
                display={3}
                onChange={this.onPageChange}
                totalElement={pagination.total}
                elementNumber={pagination.count}
              />
            </Col>
          </Row>
        )}
      </PageContainer>
    );
  }
}

export default withStyles(styles)(observer(ListaPratiche));
