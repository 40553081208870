/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagAltroProvvedimentoAGValueDTO {
    Monitoraggio = 'MONITORAGGIO',
    Valutazione = 'VALUTAZIONE'
}

export function FlagAltroProvvedimentoAGValueDTOFromJSON(json: any): FlagAltroProvvedimentoAGValueDTO {
    return FlagAltroProvvedimentoAGValueDTOFromJSONTyped(json, false);
}

export function FlagAltroProvvedimentoAGValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagAltroProvvedimentoAGValueDTO {
    return json as FlagAltroProvvedimentoAGValueDTO;
}

export function FlagAltroProvvedimentoAGValueDTOToJSON(value?: FlagAltroProvvedimentoAGValueDTO | null): any {
    return value as any;
}

