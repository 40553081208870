import React from 'react';
import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import Subsection from '../../common/Subsection';
import { Col, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import Api from '../../../api/Cittadini';
import { Button } from '@material-ui/core';
import Loader from '../../common/Loader';
import { observer } from 'mobx-react';
import store from '../../../store';
import { REQUEST_STATES } from '../../../constants/common';
import { CircularProgress } from '@material-ui/core';
import RequestStatusBadge from './RequestStatusBadge';
import Detail from '../Detail';
import RejectWidget from './RejectWidget';

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: '10px',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  simpleLabelValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  simpleLabelValueContainerNoPadding: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0px',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

class DettaglioRichiesta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      request: null,
      loading: false,
      loadingApprove: false,
      loadingReject: false,
      showRejectDialog: false,
    };
  }

  componentDidMount() {
    this.loadRequest(this.props.match.params.requestId);
  }

  componentWillReceiveProps(nextProps, _nextContext) {
    if (
      nextProps.match.params.requestId !== this.props.match.params.requestId
    ) {
      this.loadRequest(nextProps.match.params.requestId);
    }
  }

  loadRequest = requestId => {
    this.setState(
      {
        loading: true,
      },
      () => {
        Api.getRequest(
          requestId,
          request => {
            this.setState({
              loading: false,
              request,
            });
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      }
    );
  };

  onRejectClicked = () => {
    this.setState({
      showRejectDialog: true,
    });
  };

  closeRejectWidget = () => {
    this.setState({
      showRejectDialog: false,
    });
  };

  approveRequest = () => {
    this.setState(
      {
        loadingApprove: true,
      },
      () => {
        Api.approveRequest(
          this.state.request.id,
          request => {
            this.setState({
              loadingApprove: false,
              request,
            });
          },
          () => {
            store.app.snackBar.open(
              'Si è verificato un errore, operazione non riuscita'
            );
            this.setState({
              loadingApprove: false,
            });
          }
        );
      }
    );
  };

  rejectRequest = reason => {
    this.setState(
      {
        loadingReject: true,
      },
      () => {
        Api.rejectRequest(
          this.state.request.id,
          reason,
          request => {
            this.setState({
              loadingReject: false,
              showRejectDialog: false,
              request,
            });
          },
          () => {
            store.app.snackBar.open(
              'Si è verificato un errore, operazione non riuscita'
            );
            this.setState({
              loadingReject: false,
              showRejectDialog: false,
            });
          }
        );
      }
    );
  };

  onEditStarted = () => {
    this.setState({
      editing: true,
    });
  };

  onEditCanceled = () => {
    this.setState({
      editing: false,
    });
  };

  onEditFinished = () => {
    this.loadRequest(this.props.match.params.requestId);
    this.setState({
      editing: false,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      loadingApprove,
      loadingReject,
      request,
      showRejectDialog,
      editing,
    } = this.state;
    const loadingAction = loadingApprove || loadingReject;

    return (
      <PageContainer
        header={
          <PageTitle title="Dettagli Richiesta di Iscrizione" backIcon={true} />
        }
      >
        <Loader size={40} loading={loading} />

        {request && !loading && (
          <>
            <div className={classes.container} style={{ minHeight: 'auto' }}>
              <Subsection>
                <Row
                  style={{
                    alignItems: 'center',
                    marginBottom:
                      request.status === REQUEST_STATES.REJECTED ? '20px' : '0',
                  }}
                >
                  <Col
                    md={request.status === REQUEST_STATES.NEW ? 8 : 12}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Col md={4} sm={6} xs={12}>
                      <RequestStatusBadge state={request.status} />
                    </Col>
                    <LabelValue
                      noPadding={true}
                      label="Creata il"
                      value={request.created_at}
                    />
                    <LabelValue
                      noPadding={true}
                      label="Provider Dati"
                      value={
                        request.identity_provider
                          ? request.identity_provider.toUpperCase()
                          : 'N/A'
                      }
                    />
                  </Col>
                  {request.status === REQUEST_STATES.NEW && (
                    <Col
                      md={4}
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <>
                        <Button
                          style={{
                            marginRight: '10px',
                          }}
                          size="small"
                          variant="raised"
                          color="primary"
                          disabled={loadingAction || editing}
                          onClick={this.approveRequest}
                        >
                          {loadingApprove ? (
                            <CircularProgress size={16} />
                          ) : (
                            'Approva'
                          )}
                        </Button>
                        <Button
                          size="small"
                          variant="raised"
                          color="default"
                          disabled={loadingAction || editing}
                          onClick={this.onRejectClicked}
                        >
                          {loadingReject ? (
                            <CircularProgress size={16} />
                          ) : (
                            'Scarta'
                          )}
                        </Button>
                      </>
                    </Col>
                  )}
                </Row>
                {request.status === REQUEST_STATES.REJECTED && (
                  <Row style={{ marginTop: '20px' }}>
                    <Col xs={12} sm={6} md={4} />
                    <LabelValue
                      md={8}
                      sm={6}
                      xs={12}
                      label="Motivazione Scarto"
                      value={request.reason ? request.reason : 'N/A'}
                    />
                  </Row>
                )}
              </Subsection>
            </div>

            <Detail
              editDisabled={request.status !== REQUEST_STATES.NEW}
              user={request.user}
              onEditStarted={this.onEditStarted}
              onEditCanceled={this.onEditCanceled}
              onEditFinished={this.onEditFinished}
              requestId={request.id}
              asRegistrationRequest
            />
          </>
        )}
        <RejectWidget
          loading={loadingReject}
          open={showRejectDialog}
          onConfirm={this.rejectRequest}
          onClose={this.closeRejectWidget}
        />
      </PageContainer>
    );
  }
}

const LabelValue = withStyles(styles)(props => {
  const {
    classes,
    label,
    value,
    noPadding = false,
    md = 4,
    sm = 6,
    xs = 12,
  } = props;

  return (
    <Col
      md={md}
      sm={sm}
      xs={xs}
      className={
        noPadding
          ? classes.simpleLabelValueContainerNoPadding
          : classes.simpleLabelValueContainer
      }
    >
      <span className={classes.simpleLabel}>{label}</span>
      <span className={classes.simpleValue}>{value}</span>
    </Col>
  );
});

export default withStyles(styles)(observer(DettaglioRichiesta));
