import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Number from './Number';
import { Col, Row } from 'react-flexbox-grid';
import DetailsButton from './DetailsButton';
import PropTypes from 'prop-types';
import Widget from '../Share/Widget';
import store from '../../../store';
import {
  getDepartmentLabel,
  INTERNAL_PROCEDURE_STATUS,
} from '../../../constants/common';

const styles = theme => ({
  container: {
    margin: '5px 0px',
    border: 'solid 1px',
    padding: '10px 15px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    borderRadius: '1px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    display: 'flex',
    alignItems: 'center',
    height: '90px',
  },
  details: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0',
  },
  detailsContainer: {
    fontSize: '14px',
  },
  detailsElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  elementHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  elementValue: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  flexValue: {
    display: 'flex',
    alignItems: 'center',
  },
  elementIcon: {
    color: '#0073E6',
    marginRight: '8px',
    fontSize: '16px',
  },
  actionContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const ListItem = withRouter(
  withStyles(styles)(props => {
    const { classes, procedure, onDetailsClick, onProcedureShared } = props;
    const { profile } = store.app;

    const isOwner = useMemo(() => {
      return profile.user.id === procedure.shared_by.id;
    }, [profile, procedure]);

    const isSharable = useMemo(() => {
      return isOwner && procedure.status !== INTERNAL_PROCEDURE_STATUS.CLOSED;
    }, [procedure, isOwner]);

    const sharedWith = useMemo(() => {
      if (procedure.shares.length === 0) return '-';
      else if (procedure.shares.length <= 2) {
        return procedure.shares
          .map(s => getDepartmentLabel(s.department))
          .join(', ');
      } else {
        const first = getDepartmentLabel(procedure.shares[0].department);
        const others = procedure.shares.length - 1;
        return `${first} e altri ${others}`;
      }
    }, [procedure]);

    const sharedBy = useMemo(() => {
      return procedure.shared_by.full_name;
    }, [procedure]);

    const onClick = useCallback(() => {
      onDetailsClick(procedure);
    }, [procedure, onDetailsClick]);

    return (
      <div className={classes.container}>
        <Number year={procedure.year} number={procedure.number} />
        <Row className={classes.details}>
          <Col xs={10} className={classes.detailsContainer}>
            <Row>
              <Col xs={12} className={classes.detailsElement}>
                <span className={classes.elementHeader}>OGGETTO</span>
                <span className={classes.elementValue}>
                  {procedure.subject}
                </span>
              </Col>
            </Row>
            <Row>
              {isOwner ? (
                <Col xs={4} className={classes.detailsElement}>
                  <span className={classes.elementHeader}>CONDIVISO CON</span>
                  <span className={classes.elementValue}>{sharedWith}</span>
                </Col>
              ) : (
                <Col xs={4} className={classes.detailsElement}>
                  <span className={classes.elementHeader}>CONDIVISO DA</span>
                  <span className={classes.elementValue}>{sharedBy}</span>
                </Col>
              )}
              <Col xs={4} className={classes.detailsElement}>
                <span className={classes.elementHeader}>ULTIMA RISPOSTA</span>
                <span className={classes.elementValue}>
                  {procedure.latest_reply ? procedure.latest_reply : '-'}
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={2} className={classes.actionContainer}>
            <DetailsButton
              size="small"
              color="default"
              variant="raised"
              onClick={onClick}
            />
            {isSharable && (
              <Widget
                procedure={procedure}
                onProcedureShared={onProcedureShared}
                buttonProps={{
                  size: 'small',
                  variant: 'raised',
                  color: 'primary',
                  className: classes.smallActionButton,
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  })
);

ListItem.propTypes = {
  onDetailsClick: PropTypes.func,
  onProcedureShared: PropTypes.func,
};

ListItem.defaultProps = {
  onDetailsClick: () => {},
  onProcedureShared: () => {},
};

export default ListItem;
