import React from 'react';
import { Redirect } from 'react-router-dom';
import store from '../store';
import { observer } from 'mobx-react';
import ROUTES from '../constants/routes';
import EmptyListMessage from '../components/common/EmptyListMessage';

const Unauthorized = () => {
  const isAuthenticated = store.app.profile.isAuthenticated;

  if (!isAuthenticated) {
    return <Redirect to={`/${ROUTES.LOGIN}`} />;
  }

  return (
    <EmptyListMessage
      visible
      message="Non si dispone delle autorizzazioni necessarie per accedere alla risorsa"
    />
  );
};

export default observer(Unauthorized);
