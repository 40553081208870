import React, { FC, memo, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import { Checkbox } from './Flag/partial/Checkbox';
import { Button, Collapse, StyledComponentProps } from '@material-ui/core';
import FormControlValidated from '../../common/FormControlValidated';
import { expensesLabelMap } from '../constants';
import { TemporaryDocument } from '../api/model';
import { UploadDialog } from './upload/Dialog';
import { useDialog } from './upload/useDialog';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import { DocumentRow } from './DocumentRow';
import { CurrencyInput } from '../new/section/CurrencyInput';
import { ExpenseTypeDTO } from '../../../gen/api/welfare';

const styles = (theme: any) => ({
  labelAndFlag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkbox: {
    height: '24px',
    width: '24px',
  },
  collapse: {
    padding: '8px 0px',
    margin: '0px 16px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[400],
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

interface ExpensesFlagProps extends StyledComponentProps {
  type: ExpenseTypeDTO;
  value?: { value: string; doc?: TemporaryDocument };
  error?: string;
  handleChange: (type: ExpenseTypeDTO, v?: string | null) => void;
  addDocument: (type: ExpenseTypeDTO, doc: TemporaryDocument) => void;
  removeDocument: (type: ExpenseTypeDTO) => void;
}

// TODO (bug fixed on react 17)
// eslint-disable-next-line react/display-name
const ExpensesFlag: FC<ExpensesFlagProps> = memo(
  ({
    classes = {},
    value,
    type,
    handleChange,
    error,
    addDocument,
    removeDocument,
  }) => {
    const { open, openDialog, closeDialog } = useDialog();
    const toogleCheck = useCallback(() => {
      if (value === undefined) {
        handleChange(type, null);
      } else {
        handleChange(type, undefined);
      }
    }, [handleChange, type, value]);

    const onChange = useCallback(
      e => {
        handleChange(type, e.target.value);
      },
      [handleChange, type]
    );

    const onDocumentLoaded = useCallback(
      (doc: TemporaryDocument) => {
        closeDialog();
        addDocument(type, doc);
      },
      [addDocument, closeDialog, type]
    );
    const onRemoveDocument = useCallback(
      () => () => {
        removeDocument(type);
      },
      [removeDocument, type]
    );
    return (
      <div className={classes.collapse}>
        <div className={classes.labelAndFlag}>
          <label> {expensesLabelMap[type]} </label>
          <Checkbox checked={value !== undefined} onChange={toogleCheck} />
        </div>

        <Collapse in={value !== undefined} timeout="auto" unmountOnExit>
          <FormControlValidated
            inputId="expenseValue"
            label="Valore spesa"
            error={error}
            shrinkLabel
          >
            <CurrencyInput
              id="expenseValue"
              name="expenseValue"
              type="number"
              defaultValue={value?.value}
              onChange={onChange}
              placeholder="Inserisci il valore"
              classes={
                {
                  inkbar: !error
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                } as Record<any, any>
              }
            />
          </FormControlValidated>
          {value?.doc ? (
            <DocumentRow
              disableAnimation
              document={value.doc}
              onDelete={onRemoveDocument}
            />
          ) : (
            <Button variant="outlined" color="primary" onClick={openDialog}>
              <UploadIcon /> Carica Documento
            </Button>
          )}
          <UploadDialog
            open={open}
            onClose={closeDialog}
            onDocumentUploaded={onDocumentLoaded}
          />
        </Collapse>
      </div>
    );
  }
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedExpensesFlag = withStyles(styles)(ExpensesFlag);

export { DecoratedExpensesFlag as ExpensesFlag };
