/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceEventTypeDTO {
    NoteAdded = 'NOTE_ADDED',
    InstanceApproved = 'INSTANCE_APPROVED',
    InstanceRejected = 'INSTANCE_REJECTED',
    OutcomeConfirmed = 'OUTCOME_CONFIRMED',
    OutcomeRejected = 'OUTCOME_REJECTED',
    AccountingDecisionAdded = 'ACCOUNTING_DECISION_ADDED',
    ExecutiveDecisionSigned = 'EXECUTIVE_DECISION_SIGNED'
}

export function WelfareInstanceEventTypeDTOFromJSON(json: any): WelfareInstanceEventTypeDTO {
    return WelfareInstanceEventTypeDTOFromJSONTyped(json, false);
}

export function WelfareInstanceEventTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelfareInstanceEventTypeDTO {
    return json as WelfareInstanceEventTypeDTO;
}

export function WelfareInstanceEventTypeDTOToJSON(value?: WelfareInstanceEventTypeDTO | null): any {
    return value as any;
}

