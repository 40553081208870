/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceTypeDTO {
    AssistenzaDomiciliareAnziani = 'ASSISTENZA_DOMICILIARE_ANZIANI',
    AssistenzaDomiciliareDisabili = 'ASSISTENZA_DOMICILIARE_DISABILI',
    PastiDomicilioAnziani = 'PASTI_DOMICILIO_ANZIANI',
    PastiCaldiDisabili = 'PASTI_CALDI_DISABILI',
    ContributoSolidarietaInserimentoResidenziale = 'CONTRIBUTO_SOLIDARIETA_INSERIMENTO_RESIDENZIALE',
    AssegniMaternita = 'ASSEGNI_MATERNITA',
    AssegniNucleoFamiliare = 'ASSEGNI_NUCLEO_FAMILIARE',
    ContributoContinuativoUnaTantum = 'CONTRIBUTO_CONTINUATIVO_UNA_TANTUM',
    AccessoResidenzeProtette = 'ACCESSO_RESIDENZE_PROTETTE',
    IntegrazioneRettaResidenzeProtette = 'INTEGRAZIONE_RETTA_RESIDENZE_PROTETTE',
    NonAutosufficenzaAssegnoCura = 'NON_AUTOSUFFICENZA_ASSEGNO_CURA',
    Asl4 = 'ASL4',
    CureDomiciliariDisabilitaGravissimaAnziani = 'CURE_DOMICILIARI_DISABILITA_GRAVISSIMA_ANZIANI',
    CureDomiciliariDisabilitaGravissimaDisabili = 'CURE_DOMICILIARI_DISABILITA_GRAVISSIMA_DISABILI',
    AccessoPostiCastagnola = 'ACCESSO_POSTI_CASTAGNOLA',
    AffidoEducativo = 'AFFIDO_EDUCATIVO',
    IncontriProtetti = 'INCONTRI_PROTETTI',
    AffidoFamiliare = 'AFFIDO_FAMILIARE',
    InserimentoMinoriComunita = 'INSERIMENTO_MINORI_COMUNITA',
    ComunitaMadreBambino = 'COMUNITA_MADRE_BAMBINO',
    EquipeAdozioni = 'EQUIPE_ADOZIONI',
    EquipeAffido = 'EQUIPE_AFFIDO',
    Ludoteca = 'LUDOTECA',
    ReteContrastoViolenza = 'RETE_CONTRASTO_VIOLENZA',
    EquipeAbuso = 'EQUIPE_ABUSO',
    ProntaAccoglienzaMinori = 'PRONTA_ACCOGLIENZA_MINORI',
    ServizioEducativoDomiciliare = 'SERVIZIO_EDUCATIVO_DOMICILIARE',
    FondoNonAutosufficenza = 'FONDO_NON_AUTOSUFFICENZA',
    ContributoVitaIndipendente = 'CONTRIBUTO_VITA_INDIPENDENTE',
    RedditoCittadinanza = 'REDDITO_CITTADINANZA',
    ServizioTrasportoDisabili = 'SERVIZIO_TRASPORTO_DISABILI',
    ContributoDopoDiNoi = 'CONTRIBUTO_DOPO_DI_NOI',
    TelesoccorsoTelecontrollo = 'TELESOCCORSO_TELECONTROLLO',
    AgevolazioniTrasportoPubblico = 'AGEVOLAZIONI_TRASPORTO_PUBBLICO'
}

export function WelfareInstanceTypeDTOFromJSON(json: any): WelfareInstanceTypeDTO {
    return WelfareInstanceTypeDTOFromJSONTyped(json, false);
}

export function WelfareInstanceTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelfareInstanceTypeDTO {
    return json as WelfareInstanceTypeDTO;
}

export function WelfareInstanceTypeDTOToJSON(value?: WelfareInstanceTypeDTO | null): any {
    return value as any;
}

