import React from 'react';
import { Button } from '@material-ui/core';

const DetailActions = ({ isOwnerAndOpen, canSign, onClose, onSign }) => {
  return (
    <>
      {isOwnerAndOpen && (
        <span>
          <Button variant="raised" color="primary" onClick={onClose}>
            Chiudi Atto
          </Button>
        </span>
      )}
      {canSign && (
        <span>
          <Button variant="raised" color="primary" onClick={onSign}>
            Firma
          </Button>
        </span>
      )}
    </>
  );
};

export default DetailActions;
