import { extendObservable } from 'mobx';

export default class User {
  constructor(user) {
    extendObservable(this, {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      spidConnected: user.spid_connected,
      status: user.status,
      roles: user.roles,
      person: user.person,
      created_at: user.created_at,
      updated_at: user.updated_at,
    });
  }
}
