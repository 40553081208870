import React, { FC } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import Loader from '../../../../common/Loader';

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
  },
});

const Submitting: FC<StyledComponentProps> = ({ classes = {} }) => {
  return (
    <div className={classes.container}>
      <Loader size={40} loading />
      <label className={classes.label}>Salvataggio pratica in corso...</label>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSubmitting = withStyles(styles)(Submitting);

export { DecoratedSubmitting as Submitting };
