import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import PageContainer from '../../common/PageContainer';
import EmptyListMessage from '../../common/EmptyListMessage';
import ROUTES from '../../../constants/routes';
import Loader from '../../common/Loader';
import Scadenziario from '../../../api/ScadenziarioEdilizia';
import { PraticaWithActions } from './PraticaWithActions';
import { PROCEDURE_STATES } from '../../../constants/common';
import { Col, Row } from 'react-flexbox-grid';
import { compile } from 'path-to-regexp';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import Pagination from '../../common/Pagination';
import GenericListFilters from '../../common/filters/GenericListFilters';
import FilterItem from '../../common/filters/Filter';
import {
  FILTER_TYPE,
  FILTRI_TIPO_PRATICA,
} from '../../common/filters/constants';
import PageTitle from '../../common/PageTitle';

const styles = theme => ({
  listBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '25px',
    marginBottom: '10px',
  },
  procedureTitle: {
    ...theme.typography.bodyStrong,
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  itemLabel: {
    fontWeight: 'bold',
  },
  procedureSubject: {
    ...theme.typography.bodyStrong,
    paddingBottom: '10px',
    fontSize: '13px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  statusListTitle: {
    display: 'block',
    fontSize: '20px',
    fontWeight: 600,
    padding: '30px 0px 10px 0px',
  },
  noWorkToDo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px',
    color: theme.palette.primary.main,
  },
  giantIcon: {
    fontSize: '80px',
    marginBottom: '20px',
  },
  shoutText: {
    fontWeight: 'bold',
    fontSize: '30px',
  },
  shoutTextSubtitle: {
    fontWeight: '300',
    fontStyle: 'italic',
    fontSize: '18px',
  },
  historyButtonContainer: {
    width: '100%',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  historyButton: {
    border: '2px solid ' + theme.palette.primary.main,
  },
});

class ScadenziarioEdilizia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: {
        [PROCEDURE_STATES.NEW]: false,
        [PROCEDURE_STATES.PENDING]: false,
        [PROCEDURE_STATES.COMPLETED]: false,
      },
      updateInterval: null,
      allPratiche: [],
      pratiche: {
        [PROCEDURE_STATES.NEW]: [],
        [PROCEDURE_STATES.PENDING]: [],
        [PROCEDURE_STATES.COMPLETED]: [],
      },
      pagination: {
        [PROCEDURE_STATES.NEW]: [],
      },
      filters: {
        [PROCEDURE_STATES.NEW]: {},
      },
    };
  }

  componentDidMount() {
    this.loadList(PROCEDURE_STATES.NEW, 1, true);
    this.loadList(PROCEDURE_STATES.PENDING, null, true);
    this.loadList(PROCEDURE_STATES.COMPLETED, null, true);
    this.updateListsInBackground();
  }

  componentWillUnmount() {
    if (this.state.updateInterval) {
      clearInterval(this.state.updateInterval);
    }
  }

  updateListsInBackground() {
    const interval = setInterval(() => {
      this.loadList(PROCEDURE_STATES.PENDING, null, false);
      this.loadList(PROCEDURE_STATES.COMPLETED, null, false);
    }, 1000 * 120);

    this.setState({
      updateInterval: interval,
    });
  }

  onNewPageChange = newPage => {
    this.loadList(PROCEDURE_STATES.NEW, newPage.page, true);
  };

  loadList = (status, page = null, loading = true) => {
    const loadingState = this.state.loading;
    loadingState[status] = loading;
    const userFilters = this.state.filters[status];
    const filters = { status, page, ...userFilters };
    this.setState({ loading: loadingState }, () => {
      Scadenziario.loadList(
        { filters: filters },
        response => {
          const pratiche = this.state.pratiche;
          const pagination = this.state.pagination;
          if (response.meta && response.meta.pagination) {
            pagination[status] = response.meta.pagination;
          }
          pratiche[status] = response.data;
          loadingState[status] = false;
          this.setState({
            loading: loadingState,
            pratiche,
            pagination,
          });
        },
        () => {
          loadingState[status] = false;
          this.setState({ loading: loadingState });
        }
      );
    });
  };

  groupPratiche = pratiche => {
    const grouped = {
      [PROCEDURE_STATES.NEW]: [],
      [PROCEDURE_STATES.PENDING]: [],
      [PROCEDURE_STATES.COMPLETED]: [],
    };

    pratiche.forEach(pratica => {
      const status = pratica.status;
      if (grouped.hasOwnProperty(status)) {
        grouped[status].push(pratica);
      }
    });

    return grouped;
  };

  removeProcedure = id => {
    const currProcedures = this.state.pratiche;
    currProcedures[PROCEDURE_STATES.NEW] = currProcedures[
      PROCEDURE_STATES.NEW
    ].filter(p => {
      return p.id !== id;
    });

    this.setState({
      pratiche: currProcedures,
    });
  };

  onProcedureCanceled = (procedureId, onFinished) => {
    Scadenziario.skip(
      procedureId,
      () => {
        this.removeProcedure(procedureId);
        onFinished();
      },
      onFinished
    );
  };

  onProcedureSend = procedureId => {
    const toPath = compile(`/${ROUTES.SCADENZIARIO_EDILIZIA_INVIO_PRATICA}`, {
      encode: encodeURIComponent,
    });
    this.props.history.push(toPath({ procedureId }));
  };

  goToHistory = () => {
    this.props.history.push(`/${ROUTES.SCADENZIARIO_EDILIZIA_STORICO}`);
  };

  applyFiltersToDo = filters => {
    const userFilters = this.state.filters;
    if (userFilters.hasOwnProperty(PROCEDURE_STATES.NEW)) {
      userFilters[PROCEDURE_STATES.NEW] = filters;
      this.setState(
        {
          filters: userFilters,
        },
        () => {
          this.loadList(PROCEDURE_STATES.NEW, 1, true);
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const finishedLoading =
      !loading[PROCEDURE_STATES.NEW] &&
      !loading[PROCEDURE_STATES.PENDING] &&
      !loading[PROCEDURE_STATES.COMPLETED];

    return (
      <PageContainer
        whiteBg
        header={<PageTitle title="Presa in carico CILA e SCIA" />}
      >
        {/*<Loader size={40} loading={!finishedLoading}/>*/}
        <ListByState
          pratiche={this.state.pratiche[PROCEDURE_STATES.NEW]}
          title="Da Processare"
          onCancel={this.onProcedureCanceled}
          onSend={this.onProcedureSend}
          loading={this.state.loading[PROCEDURE_STATES.NEW]}
          pagination={this.state.pagination[PROCEDURE_STATES.NEW]}
          onPageChange={this.onNewPageChange}
          emptyComponent={<NoNewProcedures />}
          filters={[
            new FilterItem(
              'type',
              FILTER_TYPE.SELECT,
              'Tipo',
              null,
              FILTRI_TIPO_PRATICA,
              6,
              2,
              2,
              2
            ),
          ]}
          applyFilters={this.applyFiltersToDo}
        />
        <ListByState
          pratiche={this.state.pratiche[PROCEDURE_STATES.PENDING]}
          title="In Elaborazione"
          onCancel={this.onProcedureCanceled}
          onSend={this.onProcedureSend}
          loading={this.state.loading[PROCEDURE_STATES.PENDING]}
        />
        <ListByState
          pratiche={this.state.pratiche[PROCEDURE_STATES.COMPLETED]}
          title="Processate Oggi"
          onCancel={this.onProcedureCanceled}
          onSend={this.onProcedureSend}
          loading={this.state.loading[PROCEDURE_STATES.COMPLETED]}
        />
        <EmptyListMessage
          visible={this.state.pratiche.length === 0 && !this.state.loading}
        />
        {finishedLoading && (
          <div className={classes.historyButtonContainer}>
            <Button
              className={classes.historyButton}
              variant="flat"
              color="primary"
              onClick={this.goToHistory}
            >
              Storico pratiche processate
            </Button>
          </div>
        )}
      </PageContainer>
    );
  }
}

const ListByState = withStyles(styles)(props => {
  const {
    classes,
    pratiche,
    title,
    onCancel,
    onSend,
    loading,
    emptyComponent,
    pagination,
    onPageChange,
    filters,
    applyFilters,
  } = props;

  return loading || pratiche.length > 0 ? (
    <Row>
      <Col xs={12}>
        <span className={classes.statusListTitle}>{title}</span>
      </Col>
      {filters.length > 0 && (
        <Col xs={12}>
          <GenericListFilters
            listName="scadenziarioDaProcessare"
            filters={filters}
            applyFilters={applyFilters}
            applyFiltersOnMount={false}
          />
        </Col>
      )}
      <Loader size={40} loading={loading} />
      {!loading && (
        <Col xs={12}>
          {pratiche.map(pratica => {
            return (
              <PraticaWithActions
                onCancelClicked={onCancel}
                onSendClicked={onSend}
                key={pratica.id}
                goToDetails={`/${ROUTES.ARCHIVIO_GATE}/${pratica.id}`}
                procedure={pratica}
              />
            );
          })}
        </Col>
      )}
      {pagination && (
        <Col xs={12} sm={12}>
          <Pagination
            total={pagination.total_pages}
            current={pagination.current_page}
            display={3}
            onChange={onPageChange}
            totalElement={pagination.total}
            elementNumber={pagination.count}
          />
        </Col>
      )}
    </Row>
  ) : emptyComponent && !loading ? (
    emptyComponent
  ) : (
    <Row />
  );
});

const NoNewProcedures = withStyles(styles)(props => {
  const { classes } = props;

  return (
    <div className={classes.noWorkToDo}>
      <i className={classNames(classes.giantIcon, 'nc-icon nc-sun-fog-29')} />
      <span className={classes.shoutText}>Fantastico!</span>
      <span className={classes.shoutTextSubtitle}>
        Hai processato tutte le pratiche, prenditi un po di relax!
      </span>
    </div>
  );
});

ListByState.propTypes = {
  pratiche: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  emptyComponent: PropTypes.object,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
  filters: PropTypes.array,
};

ListByState.defaultProps = {
  emptyComponent: undefined,
  pagination: null,
  filters: [],
  onPageChange: () => {},
};

export default withStyles(styles)(observer(ScadenziarioEdilizia));
