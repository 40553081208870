import React, { FC, useCallback, useState } from 'react';
import PageTitle from '../../common/PageTitle';
import { Button } from '@material-ui/core';
import PageContainer from '../../common/PageContainer';
import { ROUTES_SERVIZI_SOCIALI } from '../../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ListaColloqui from './interviews/list/ListaColloqui';
import ListaSegretariatioSociale from './secretariat/list/ListaSegretariatioSociale';

const JournalPage: FC<RouteComponentProps> = ({ history }) => {
  const [tab, setTab] = useState(0);
  const onNew = useCallback(() => {
    history.push(`/${ROUTES_SERVIZI_SOCIALI.NUOVA_ATTIVITA_DIARIO}`);
  }, [history]);

  return (
    <PageContainer
      whiteBg
      header={
        <PageTitle
          /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
          // @ts-ignore
          title="Diario attività"
          actions={[
            <Button key="new" onClick={onNew} variant="raised" color="primary">
              Nuova attività
            </Button>,
          ]}
          tabs={['Segretariato Sociale', 'Colloqui']}
          onTabChange={setTab}
        />
      }
    >
      {tab === 0 && <ListaSegretariatioSociale />}
      {tab === 1 && <ListaColloqui />}
    </PageContainer>
  );
};

const DecoratedJournalPage = withRouter(JournalPage);
export { DecoratedJournalPage as JournalPage };
