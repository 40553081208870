import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #DDDDDD',
    padding: '8px 0',
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 8,
    color: theme.palette.grey.dark,
  },
  value: {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 8,
  },
});

const DetailElement = ({ classes, label, value }) => {
  return (
    <div className={classes.container}>
      <span className={classes.label}>{label}</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

export default withStyles(styles)(DetailElement);
