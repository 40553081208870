import React, { FC } from 'react';
import { SelectionFlagProps } from './model';
import { DetailField } from '../../detail/layout/Detail/common/DetailField';

const _SelectionFlag: FC<SelectionFlagProps> = ({ label, data, options }) => {
  const value =
    !data || data.length < 1
      ? 'Nessuna'
      : options.reduce((acc, { id, text }) => {
          if (id === data[0]) {
            return text;
          }
          return acc;
        }, 'Nessuna');
  return <DetailField label={label} value={value} />;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const SelectionFlag = _SelectionFlag;
