import React from 'react';
import Validators from '../../../utils/validators';
import { Col, Row } from 'react-flexbox-grid';
import Misc from '../../../api/Misc';
import {
  CircularProgress,
  Input,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { getDocumentTypeLabel } from '../../../constants/common';
import { Button } from '@material-ui/core';
import Loader from '../../common/Loader';
import moment from 'moment';
import store from '../../../store';
import FormControlValidated from '../../common/FormControlValidated';
import { createCitizens } from '../api/api';
import { ChevronLeft } from '@material-ui/icons';
import CodiceFiscale from 'codice-fiscale-js';
import { DocumentType } from '../api/model';
import { SectionTitle } from './SectionTitle';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/moment';

const styles = theme => ({
  footerContainer: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    width: '100%',
    alignSelf: 'stretch',
    padding: '24px 0px 48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formControl: {
    width: '100%',
    margin: '10px 0px',
    textAlign: 'left',
  },
  labelError: {
    color: theme.palette.error,
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
  section: {
    marginTop: '30px',
    marginBottom: '20px',
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
});

class CitizenForm extends React.Component {
  static propTypes = {
    cf: PropTypes.string.isRequired,
    onCompleted: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const decodedCF = new CodiceFiscale(props.cf);
    this.state = {
      saving: false,
      fields: {
        firstName: '',
        lastName: '',
        professionalTitle: 'noone',
        gender: decodedCF.gender,
        fiscalCode: props.cf,
        vat: '',
        documentType: '',
        documentNumber: '',
        birthday: decodedCF.birthday,
        birthPlaceCountry:
          decodedCF.birthplace.prov === 'EE' ? decodedCF.birthplace.cc : 'IT',
        birthPlaceCounty:
          decodedCF.birthplace.prov === 'EE' ? '' : decodedCF.birthplace.prov,
        birthPlace:
          decodedCF.birthplace.prov === 'EE' ? '' : decodedCF.birthplace.cc,
        streetName: '',
        postcode: '',
        county: '',
        city: '',
        email: '',
        phoneNumber: '',
      },
      errors: {},
      loadingCountries: false,
      loadingCounties: false,
      loadingBirthMunicipalities: false,
      loadingAddressMunicipalities: false,
      loadingProfessionalTitles: false,
      countries: [],
      counties: [],
      birthMunicipalities: [],
      addressMunicipalities: [],
      professionalTitles: [],
    };
  }

  componentDidMount() {
    this.loadProfessionalTitles();
    this.loadCountries();
    this.loadCounties(() => {
      if (this.state.fields.birthPlaceCounty) {
        this.loadMunicipalities(
          this.state.fields.birthPlaceCounty,
          'birthMunicipalities'
        );
      }

      if (this.state.fields.county) {
        this.loadMunicipalities(
          this.state.fields.county,
          'addressMunicipalities'
        );
      }
    });
  }

  loadCountries = () => {
    this.setState(
      {
        loadingCountries: true,
      },
      () => {
        Misc.getCountries(
          countries => {
            this.setState({
              loadingCountries: false,
              countries,
            });
          },
          () => {
            this.setState({
              loadingCountries: false,
              countries: [],
            });
          }
        );
      }
    );
  };

  loadProfessionalTitles = () => {
    this.setState(
      {
        loadingProfessionalTitles: true,
      },
      () => {
        Misc.getProfessionalTitles(
          professionalTitles => {
            this.setState({
              loadingProfessionalTitles: false,
              professionalTitles,
            });
          },
          () => {
            this.setState({
              loadingProfessionalTitles: false,
              professionalTitles: [],
            });
          }
        );
      }
    );
  };

  loadCounties = (onDone = () => {}) => {
    this.setState(
      {
        loadingCounties: true,
      },
      () => {
        Misc.getCountyList(
          counties => {
            this.setState(
              {
                loadingCounties: false,
                counties,
              },
              onDone
            );
          },
          () => {
            this.setState({
              loadingCounties: false,
              counties: [],
            });
          }
        );
      }
    );
  };

  loadMunicipalities = (countyCode, property) => {
    const loadingProperty =
      property === 'birthMunicipalities'
        ? 'loadingBirthMunicipalities'
        : 'loadingAddressMunicipalities';
    this.setState(
      {
        [loadingProperty]: true,
      },
      () => {
        Misc.getMunicipalities(
          countyCode,
          municipalities => {
            this.setState({
              [loadingProperty]: false,
              [property]: municipalities,
            });
          },
          () => {
            this.setState({
              [loadingProperty]: false,
              [property]: [],
            });
          }
        );
      }
    );
  };

  validateField = (name, value, saveToState = true) => {
    const notRequiredFields = [
      'professionalTitle',
      'vat',
      'streetName',
      'postcode',
      'city',
      'county',
      'email',
      'phoneNumber',
    ];
    const errors = this.state.errors;
    let message = null;

    if (
      (name === 'birthPlaceCounty' || name === 'birthPlace') &&
      this.state.fields.birthPlaceCountry !== 'IT'
    ) {
      notRequiredFields.push('birthPlaceCounty', 'birthPlace');
    }

    if (
      notRequiredFields.indexOf(name) === -1 &&
      Validators.isEmptyString(value)
    ) {
      message = 'Questo campo è richiesto';
    }

    try {
      if (name === 'fiscalCode' && !Validators.isValidFiscalCode(value)) {
        message = 'Il codice fiscale non è valido';
      }
    } catch (e) {
      message = 'Il codice fiscale non è valido';
    }
    if (name === 'birth_date' && !Validators.isValidBirthDate(value)) {
      message = 'La data di nascita non è nei parametri validi';
    }
    if (
      name === 'phoneNumber' &&
      value &&
      !Validators.isValidMobilePhone(value)
    ) {
      message = 'Il numero di telefono non è valido';
    }
    if (name === 'email' && value && !Validators.isValidEmail(value)) {
      message = "L'email inserita non è valida";
    }

    if (message) {
      errors[name] = message;
      if (saveToState) {
        this.setState({
          errors,
        });
      }
      return message;
    }

    return true;
  };

  parseDateString = dateString => {
    if (!dateString) return null;
    return moment(dateString, 'DD/MM/YYYY');
  };

  handleFieldChange = event => {
    const name = event.target.name;
    if (name === 'fiscalCode')
      event.target.value = event.target.value.toUpperCase();
    const value = event.target.value;
    const fields = this.state.fields;
    const errors = this.state.errors;

    const prevVal = fields[name];
    fields[name] = value;
    delete errors[name];

    if (name === 'birthPlaceCountry' && value !== 'IT') {
      fields.birthPlaceCounty = null;
      fields.birthPlace = null;
    }

    if (name === 'birthPlaceCounty') {
      this.loadMunicipalities(value, 'birthMunicipalities');
      fields.birthPlace = prevVal !== value ? null : fields.birthPlace;
    } else if (name === 'county')
      this.loadMunicipalities(value, 'addressMunicipalities');

    this.setState({
      fields,
      errors,
    });
  };

  handleDateChange = name => dateValue => {
    const fields = this.state.fields;
    const errors = this.state.errors;

    delete errors[name];

    fields[name] = dateValue != null ? dateValue.format('DD/MM/YYYY') : null;
    this.setState({
      fields,
      errors,
    });
  };

  onBlur = ({ currentTarget }) => {
    const name = currentTarget.name;
    const value = currentTarget.value;

    this.validateField(name, value);
  };

  fieldError = field => {
    return this.state.errors[field];
  };

  validate = onDone => {
    const errors = {};
    Object.keys(this.state.fields).forEach(key => {
      const message = this.validateField(key, this.state.fields[key], false);
      if (message !== true) errors[key] = message;
    });

    return onDone(errors);
  };

  onSave = async () => {
    this.setState(
      {
        saving: true,
      },
      () => {
        this.validate(errors => {
          if (Object.keys(errors).length > 0) {
            this.setState({
              saving: false,
              errors,
            });
            return false;
          }
          const data = Object.assign({}, this.state.fields);

          if (data.professionalTitle === 'noone') {
            delete data['professionalTitle'];
          } else {
            data.professionalTitle = data.professionalTitle.toLowerCase();
          }
          if (data.vat === '') delete data['vat'];
          if (data.birthPlaceCountry === 'IT') {
            delete data['birthPlaceCountry'];
          } else {
            delete data['birthPlaceCounty'];
            delete data['birthPlace'];
          }

          const addressFields = ['streetName', 'postcode', 'county', 'city'];
          let address;

          addressFields.forEach(key => {
            if (this.state.fields[key]) {
              if (!address) address = {};
              address[key] = this.state.fields[key];
            }
          });

          delete data['streetNumber'];
          delete data['postcode'];
          delete data['county'];
          delete data['city'];

          data.birthday = moment(data.birthday, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          );
          if (address) data.address = address;

          createCitizens(data)
            .then(person => {
              this.setState({ saving: false }, () =>
                this.props.onCompleted(person)
              );
            })
            .catch(error => {
              if (error?.data?.error_code === 100003)
                this.parseValidationError(error.data.details);
              else
                store.app.snackBar.open(
                  'Si è verificato un errore, operazione non riuscita'
                );
              this.setState({ saving: false });
            });
        });
      }
    );
  };

  parseValidationError = details => {
    if (!details) return;
    const errors = {};
    for (const field in details) {
      if (details.hasOwnProperty(field)) {
        errors[field] = details[field][0];
      }
    }
    this.setState({
      errors,
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    const {
      fields,
      loadingCountries,
      loadingCounties,
      loadingBirthMunicipalities,
      loadingAddressMunicipalities,
      loadingProfessionalTitles,
      saving,
    } = this.state;
    const { classes, asRegistrationRequest } = this.props;
    const loading =
      loadingCounties || loadingCountries || loadingProfessionalTitles;
    const isForeigner = fields.birthPlaceCountry !== 'IT';

    return loading ? (
      <Row>
        <Loader loading={loading} />
      </Row>
    ) : (
      <>
        <SectionTitle>Inserisci i dati del cittadino</SectionTitle>
        <Row style={{ margin: '0px 48px' }}>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="professionalTitle"
              label="Titolo Professionale"
            >
              <Select
                name="professionalTitle"
                value={fields.professionalTitle}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 0,
                }}
              >
                <MenuItem value="noone">Nessuno</MenuItem>
                {this.state.professionalTitles.map(title => (
                  <MenuItem key={title} value={title}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={0} sm={6} />
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="firstName"
              label="* Nome"
              error={this.fieldError('firstName')}
              shrinkLabel
            >
              <Input
                id="firstName"
                name="firstName"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 1,
                }}
                type="text"
                label="* Nome"
                onChange={this.handleFieldChange}
                defaultValue={fields.firstName}
                classes={{
                  inkbar: !this.fieldError('firstName')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="lastName"
              label="* Cognome"
              error={this.fieldError('lastName')}
              shrinkLabel
            >
              <Input
                id="lastName"
                name="lastName"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 2,
                }}
                type="text"
                label="Cognome"
                onChange={this.handleFieldChange}
                defaultValue={fields.lastName}
                classes={{
                  inkbar: !this.fieldError('lastName')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="fiscalCode"
              label="* Codice Fiscale"
              error={this.fieldError('fiscalCode')}
              shrinkLabel
            >
              <Input
                id="fiscalCode"
                name="fiscalCode"
                disabled={!asRegistrationRequest}
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 3,
                }}
                type="text"
                label="* Codice Fiscale"
                onChange={this.handleFieldChange}
                defaultValue={fields.fiscalCode}
                classes={{
                  inkbar: !this.fieldError('fiscalCode')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="vat"
              label="Partita IVA"
              error={this.fieldError('vat')}
              shrinkLabel
            >
              <Input
                id="vat"
                name="vat"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 4,
                }}
                type="text"
                label="Partita IVA"
                onChange={this.handleFieldChange}
                defaultValue={fields.vat}
                classes={{
                  inkbar: !this.fieldError('vat')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                style={{
                  width: '100%',
                  margin: '10px 0',
                }}
                label="* Data di nascita"
                name="birthday"
                value={this.parseDateString(fields.birthday)}
                onChange={this.handleDateChange('birthday')}
                format="DD/MM/YYYY"
                disablePast={false}
                minDate={new moment().subtract(120, 'years')}
                maxDate={new moment().subtract(18, 'years')}
                clearable={true}
                cancelLabel="Annulla"
                clearLabel="Ripristina"
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated inputId="gender" label="* Sesso">
              <Select
                name="gender"
                value={fields.gender}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 6,
                }}
              >
                <MenuItem value="M">Maschio</MenuItem>
                <MenuItem value="F">Femmina</MenuItem>
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={4}>
            <FormControlValidated
              inputId="birthPlaceCountry"
              label="* Paese di Nascita"
              error={this.fieldError('birthPlaceCountry')}
              shrinkLabel
            >
              <Select
                name="birthPlaceCountry"
                value={fields.birthPlaceCountry}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 7,
                }}
              >
                <MenuItem value="IT">Italia</MenuItem>
                {this.state.countries.map(country => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={4}>
            <FormControlValidated
              inputId="birthPlaceCounty"
              label="* Provincia di Nascita"
              error={this.fieldError('birthPlaceCounty')}
              shrinkLabel
            >
              <Select
                disabled={isForeigner}
                name="birthPlaceCounty"
                value={fields.birthPlaceCounty}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 8,
                }}
              >
                {this.state.counties.map(county => (
                  <MenuItem key={county.code} value={county.code}>
                    {county.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={4}>
            <FormControlValidated
              inputId="birthPlace"
              label="* Comune di Nascita"
              error={this.fieldError('birthPlace')}
              shrinkLabel
            >
              <Select
                disabled={isForeigner || loadingBirthMunicipalities}
                name="birthPlace"
                value={fields.birthPlace}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 9,
                }}
              >
                {this.state.birthMunicipalities.map(municipality => (
                  <MenuItem key={municipality.code} value={municipality.code}>
                    {municipality.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="documentType"
              label="* Tipo Documento"
              error={this.fieldError('documentType')}
              shrinkLabel
            >
              <Select
                name="documentType"
                value={fields.documentType}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 10,
                }}
              >
                {Object.values(DocumentType).map(value => (
                  <MenuItem key={`document_${value}`} value={value}>
                    {getDocumentTypeLabel(value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="documentNumber"
              label="* Numero Documento"
              error={this.fieldError('documentNumber')}
              shrinkLabel
            >
              <Input
                id="documentNumber"
                name="documentNumber"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 11,
                }}
                type="text"
                label="* Numero Documento"
                onChange={this.handleFieldChange}
                defaultValue={fields.documentNumber}
                classes={{
                  inkbar: !this.fieldError('documentNumber')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} className={classes.section}>
            <Typography className={classes.sectionTitle}>
              Indirizzo di Residenza
            </Typography>
          </Col>
          <Col xs={12} sm={12}>
            <FormControlValidated
              inputId="streetNumber"
              label="Via e Civico"
              error={this.fieldError('streetName')}
              shrinkLabel
            >
              <Input
                id="streetName"
                name="streetName"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 12,
                }}
                type="text"
                label="Via e Civico"
                onChange={this.handleFieldChange}
                defaultValue={fields.streetName}
                classes={{
                  inkbar: !this.fieldError('streetName')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={4}>
            <FormControlValidated
              inputId="postcode"
              label="CAP"
              error={this.fieldError('postcode')}
              shrinkLabel
            >
              <Input
                id="postcode"
                name="postcode"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 13,
                }}
                type="text"
                label="CAP"
                onChange={this.handleFieldChange}
                defaultValue={fields.postcode}
                classes={{
                  inkbar: !this.fieldError('postcode')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={4}>
            <FormControlValidated
              inputId="county"
              label="Provincia"
              shrinkLabel
              error={this.fieldError('county')}
            >
              <Select
                name="county"
                value={fields.county}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 14,
                }}
              >
                {this.state.counties.map(county => (
                  <MenuItem key={county.code} value={county.code}>
                    {county.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={4}>
            <FormControlValidated
              inputId="city"
              label="Città"
              shrinkLabel
              error={this.fieldError('city')}
            >
              <Select
                disabled={loadingAddressMunicipalities}
                name="city"
                value={fields.city}
                onChange={this.handleFieldChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 15,
                }}
              >
                {this.state.addressMunicipalities.map(municipality => (
                  <MenuItem key={municipality.code} value={municipality.code}>
                    {municipality.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
          <Col xs={12} className={classes.section}>
            <Typography className={classes.sectionTitle}>Contatti</Typography>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="email"
              label="PEC o Email"
              error={this.fieldError('email')}
              shrinkLabel
            >
              <Input
                id="email"
                name="email"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 16,
                }}
                type="text"
                label="Email"
                onChange={this.handleFieldChange}
                defaultValue={fields.email}
                classes={{
                  inkbar: !this.fieldError('email')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="phoneNumber"
              label="Cellulare"
              error={this.fieldError('phoneNumber')}
              shrinkLabel
            >
              <Input
                id="phoneNumber"
                name="phoneNumber"
                inputProps={{
                  onBlur: this.onBlur,
                  tabIndex: 17,
                }}
                type="text"
                label="Cellulare"
                onChange={this.handleFieldChange}
                defaultValue={fields.phoneNumber}
                classes={{
                  inkbar: !this.fieldError('phoneNumber')
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                }}
              />
            </FormControlValidated>
          </Col>
          <div className={classes.footerContainer}>
            <Button
              variant="flat"
              color="primary"
              onClick={this.props.goBack}
              disabled={saving}
            >
              <ChevronLeft />
              Indietro
            </Button>
            <Button
              variant="raised"
              color="primary"
              onClick={this.onSave}
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} /> : 'Avanti'}
            </Button>
          </div>
        </Row>
      </>
    );
  }
}
const DecoratedEditCitizen = withStyles(styles)(CitizenForm);

export { DecoratedEditCitizen as CitizenForm };
