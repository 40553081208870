import CallManager from './AxiosCallManager';

export default {
  upload: (contents, onSuccess, onError) => {
    const data = new FormData();
    data.append('file', contents);
    CallManager.post(
      `/temporary_documents`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  delete: (documentId, onSuccess, onError) => {
    CallManager.delete(
      `/temporary_documents/${encodeURI(documentId)}`,
      response => onSuccess(response.data),
      onError
    );
  },
  bulkDelete: (documents, onSuccess, onError) => {
    CallManager.post('/temporary_documents/bulk_delete', onSuccess, onError, {
      documents,
    });
  },
};
