import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  getSharedInstanceStatusLabel,
  SHARED_INSTANCE_STATUS,
} from '../../../constants/common';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
  },
  stateClosed: {
    backgroundColor: '#ba0101',
  },
  stateActive: {
    backgroundColor: '#00600f',
  },
});

const StatusBadge = withStyles(styles)(props => {
  const { classes, state, style } = props;

  return (
    <span
      style={style}
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateActive]: state === SHARED_INSTANCE_STATUS.OPEN,
        [classes.stateClosed]: state === SHARED_INSTANCE_STATUS.CLOSED,
      })}
    >
      {getSharedInstanceStatusLabel(state)}
    </span>
  );
});

StatusBadge.propTypes = {
  state: PropTypes.string.isRequired,
  style: PropTypes.object,
};

StatusBadge.defaultProps = {
  style: {},
};

export default StatusBadge;
