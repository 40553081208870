/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagSegnalatoDaValueDTO {
    TribunaleMinori = 'TRIBUNALE_MINORI',
    ProcuraDeiMinori = 'PROCURA_DEI_MINORI',
    TribunaleOrdinario = 'TRIBUNALE_ORDINARIO',
    ProcuraOrdinaria = 'PROCURA_ORDINARIA',
    UfficioMinori = 'UFFICIO_MINORI',
    ForzeDellOrdine = 'FORZE_DELL_ORDINE',
    ScuolaParitaria = 'SCUOLA_PARITARIA',
    ScuolaPubblica = 'SCUOLA_PUBBLICA',
    Pediatra = 'PEDIATRA',
    AccessoSpontaneo = 'ACCESSO_SPONTANEO',
    Familiari = 'FAMILIARI',
    ViciniDiCasa = 'VICINI_DI_CASA',
    AltroServizioSociale = 'ALTRO_SERVIZIO_SOCIALE',
    ServizioAsl = 'SERVIZIO_ASL'
}

export function FlagSegnalatoDaValueDTOFromJSON(json: any): FlagSegnalatoDaValueDTO {
    return FlagSegnalatoDaValueDTOFromJSONTyped(json, false);
}

export function FlagSegnalatoDaValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagSegnalatoDaValueDTO {
    return json as FlagSegnalatoDaValueDTO;
}

export function FlagSegnalatoDaValueDTOToJSON(value?: FlagSegnalatoDaValueDTO | null): any {
    return value as any;
}

