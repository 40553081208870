import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PraticheInterne from '../../../api/PraticheInterne';
import store from '../../../store';
import SignCredentials from '../Create/Steps/SignCredentials';
import OperationInProgress from '../Create/Steps/OperationInProgress';

const STEP_SIGN_CREDENTIALS = 'SIGN_CREDENTIALS';
const STEP_SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS';
const STEPS = [STEP_SIGN_CREDENTIALS, STEP_SIGNING_IN_PROGRESS];

const styles = theme => ({
  container: {
    minWidth: '600px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
});

const SignDialog = props => {
  const [step, setStep] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({
    username: null,
    password: null,
    otp: null,
  });

  const { classes, open, onClose, procedureId } = props;
  const { snackBar, profile } = store.app;

  const currStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  const onNext = useCallback(() => {
    setStep(step => step + 1);
  }, []);

  const onBack = useCallback(() => {
    setStep(step => step - 1);
  }, []);

  const close = useCallback(
    (procedure = undefined) => {
      setStep(0);
      setUsername('');
      setPassword('');
      setOtp('');
      setErrors({
        username: null,
        password: null,
        otp: null,
      });
      onClose(procedure);
    },
    [onClose]
  );

  const onUsernameChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, username: null }));
    setUsername(value);
  }, []);

  const onPasswordChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, password: null }));
    setPassword(value);
  }, []);

  const onOtpChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, otp: null }));
    setOtp(value);
  }, []);

  const sign = useCallback(() => {
    onNext();
    PraticheInterne.sign(
      procedureId,
      {
        username,
        password,
        otp,
        department: profile.operatingAs.department,
      },
      procedure => {
        snackBar.open('Operazione completata con successo');
        close(procedure);
      },
      err => {
        let message = 'Errore durante la firma del documento';
        if (err.status === 400) {
          message = 'Credenziali errate';
        }
        snackBar.open(message);
        onBack();
      }
    );
  }, [
    close,
    onBack,
    onNext,
    otp,
    password,
    procedureId,
    profile,
    snackBar,
    username,
  ]);

  const validCredentials = useMemo(() => {
    return Boolean(
      username && password && otp && !Object.values(errors).some(v => v)
    );
  }, [errors, username, password, otp]);

  const onCredentialsInserted = useCallback(() => {
    const newErrors = {};
    if (!username) newErrors.username = 'Questo campo è obbligatorio';
    if (!password) newErrors.password = 'Questo campo è obbligatorio';
    if (!otp) newErrors.otp = 'Questo campo è obbligatorio';
    if (Object.values(newErrors).some(v => Boolean(v))) {
      setErrors({ ...errors, newErrors });
    } else {
      sign();
    }
  }, [username, password, otp, errors, sign]);

  return (
    <MaterialDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {}}
      aria-labelledby="internal-procedure-sign-dialog-title"
      aria-describedby="internal-procedure-sign-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle
          className={classes.dialogTitle}
          id="internal-procedure-sign-dialog-title"
        >
          <span>Firma digitalmente l&apos;atto</span>
          <span className={classes.closeIcon} onClick={close}>
            &times;
          </span>
        </DialogTitle>
        <DialogContent>
          {currStep === STEP_SIGN_CREDENTIALS && (
            <SignCredentials
              username={username}
              password={password}
              otp={otp}
              errors={errors}
              onUsernameChange={onUsernameChanged}
              onPasswordChange={onPasswordChanged}
              onOtpChange={onOtpChanged}
            />
          )}
          {currStep === STEP_SIGNING_IN_PROGRESS && (
            <OperationInProgress message="Firma del documento in corso" />
          )}
        </DialogContent>
        <DialogActions>
          {currStep === STEP_SIGN_CREDENTIALS && (
            <>
              <Button onClick={close} className={classes.marginRightButton}>
                Annulla
              </Button>
              <Button
                onClick={onCredentialsInserted}
                variant="raised"
                color="primary"
                disabled={!validCredentials}
              >
                Firma
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </MaterialDialog>
  );
};

SignDialog.propTypes = {
  procedureId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignDialog);
