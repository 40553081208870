import React, { FC, useCallback, useState } from 'react';
import { Attachment } from '../../../../api/model';
import { DetailField } from './DetailField';
import Icon from '../../../../../../new/components/icons';
import {
  withStyles,
  StyledComponentProps,
  CircularProgress,
} from '@material-ui/core';
import store from '../../../../../../store';
import { getDocument } from '../../../../api/api';

interface DocumentFieldProps extends StyledComponentProps {
  file?: Attachment;
  label: string;
}

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
  },
  icon: {
    cursor: 'pointer',
  },
  loader: {
    height: '24px',
    width: '24px',
  },
});

const DocumentField: FC<DocumentFieldProps> = ({
  classes = {},
  file,
  ...otherProps
}) => {
  const [downloading, setDownloading] = useState(false);
  const onDownload = useCallback(() => {
    setDownloading(true);
    getDocument(file!.fid, file!.name)
      .then(() => setDownloading(false))
      .catch(() => {
        store.app.snackBar.open('Errore nel download del file');
        setDownloading(false);
      });
  }, [file]);

  return (
    <DetailField value={file?.name ?? '---'} {...otherProps}>
      {file && (
        <div className={classes.container}>
          {downloading ? (
            <CircularProgress
              size={24}
              className={classes.loader}
              color="secondary"
            />
          ) : (
            <Icon
              type="Download"
              className={classes.icon}
              onClick={onDownload}
            />
          )}
        </div>
      )}
    </DetailField>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedDocumentField = withStyles(styles)(DocumentField);

export { DecoratedDocumentField as DocumentField };
