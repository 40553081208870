/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagSeguitoConValueDTO {
    NeuropsichiatriaInfantile = 'NEUROPSICHIATRIA_INFANTILE',
    ConsultorioFamiliare = 'CONSULTORIO_FAMILIARE',
    Sert = 'SERT',
    NucleoAlcologia = 'NUCLEO_ALCOLOGIA',
    SaluteMentale = 'SALUTE_MENTALE',
    UfficioMinori = 'UFFICIO_MINORI',
    ConsultorioDiocesiano = 'CONSULTORIO_DIOCESIANO',
    ProfessionistiPrivati = 'PROFESSIONISTI_PRIVATI',
    EquipeAffido = 'EQUIPE_AFFIDO',
    EquipeAdozioni = 'EQUIPE_ADOZIONI',
    CentroEducazioneMotoria = 'CENTRO_EDUCAZIONE_MOTORIA',
    CentroAslFisiatra = 'CENTRO_ASL_FISIATRA'
}

export function FlagSeguitoConValueDTOFromJSON(json: any): FlagSeguitoConValueDTO {
    return FlagSeguitoConValueDTOFromJSONTyped(json, false);
}

export function FlagSeguitoConValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagSeguitoConValueDTO {
    return json as FlagSeguitoConValueDTO;
}

export function FlagSeguitoConValueDTOToJSON(value?: FlagSeguitoConValueDTO | null): any {
    return value as any;
}

