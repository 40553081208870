import React, { FC } from 'react';
import { useProcedureEvents } from '../../../../../../../../stm/socialServices/procedureEvents.stm';
import { MessageRow } from '../../../common/MessageRow';
import { useProcedureDetail } from '../../../../../../../../stm/socialServices/procedureDetail.stm';

export const EventStream: FC = () => {
  const { state } = useProcedureEvents();
  const procedureDetail = useProcedureDetail();
  return (
    <>
      {state.context!.events!.map(e => (
        <MessageRow
          key={e.instanceId}
          instance={procedureDetail.state.context!.procedure}
          message={e}
        />
      ))}
    </>
  );
};
