import { DEPARTMENTS, ROLES } from './security';
import ROUTES, { ROUTES_SERVIZI_SOCIALI } from './routes';
import store from '../store';

export const EVENTI = {
  USER_ACTIVATED: 'user.activated',
  USER_LOGGED_IN: 'user.auth.login',
  USER_LOGGED_OUT: 'user.auth.logout',
  USER_CREATED: 'user.created',
  USER_UPDATED: 'user.updated',
  USER_DELETED: 'user.deleted',
  PROCEDURE_ADDED: 'procedure.added',
  PROCEDURE_SENT: 'procedure.sent',
  PROCEDURE_SKIPPED: 'procedure.skipped',
  PROCEDURE_DOCUMENT_UPLOADED: 'procedure.document.uploaded',
  PROCEDURE_CREATED: 'procedure.created',
  PROCEDURE_UPDATED: 'procedure.updated',
  PROCEDURE_PROCESSED: 'procedure.processed',
  PROCEDURE_ADDITIONAL_DOCUMENTS_REQUESTED:
    'procedure.additional_documents_requested',
  PROCEDURE_APPROVED: 'procedure.approved',
  PROCEDURE_REJECTED: 'procedure.rejected',
  PROCEDURE_EXTENSION_REQUESTED: 'procedure.extension.requested',
  PROCEDURE_EXTENSION_APPROVED: 'procedure.extension.approved',
  PROCEDURE_EXTENSION_REJECTED: 'procedure.extension.rejected',
  SHARED_PROCEDURE_CREATED: 'procedure.shared.created',
  SHARED_PROCEDURE_RESOLVED: 'procedure.shared.resolved',
  SHARED_PROCEDURE_CLOSED: 'procedure.shared.closed',
  SHARED_PROCEDURE_DOCUMENT_UPLOADED: 'procedure.shared.document.uploaded',
  SHARED_PROCEDURE_DOCUMENT_DELETED: 'procedure.shared.document.deleted',
  SHARED_PROCEDURE_NOTES_UPDATED: 'procedure.shared.notes.updated',
  REGISTRATION_APPROVED: 'registration.approved',
  REGISTRATION_REJECTED: 'registration.rejected',
  MANAGERIAL_ACT_SIGNED: 'managerial-act.signed',
};

export const DEPARTMENTS_LABEL = {
  [DEPARTMENTS.BUILDING]: 'Ufficio Occupazione e Rottura Suolo',
  [DEPARTMENTS.CED]: 'C.E.D.',
  [DEPARTMENTS.ENVIRONMENT]: 'Ragioneria',
  [DEPARTMENTS.LOGISTIC]: 'Ufficio Edilizia Privata',
  [DEPARTMENTS.SOCIAL_SERVICES]: 'Servizi Sociali',
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: 'Servizi Demografici',
  [DEPARTMENTS.STATO_CIVILE]: 'Ufficio Stato Civile',
  [DEPARTMENTS.ADMIN]: 'Amministratori',
};

export const SESSION_STATE = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
};

export const PROCEDURE_TYPES = {
  ART22: 'ART22',
  ART25: 'ART25',
  RISCONTRO: 'RISCONTRO',
  CILA: 'CILA',
  SCIA: 'SCIA',
};

export const PROCEDURE_STATES = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  SKIPPED: 'SKIPPED',
  COMPLETED: 'COMPLETED',
};

export const SECTORS = {
  NONE: 'none',
  ONE: '1',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  FIVE: '5',
  SIX: '6',
  SEVEN: '7',
};

export const DEPARTMENT_SECTOR = {
  [DEPARTMENTS.ADMIN]: SECTORS.NONE,
  [DEPARTMENTS.CED]: SECTORS.NONE,
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: SECTORS.TWO,
  [DEPARTMENTS.STATO_CIVILE]: SECTORS.TWO,
  [DEPARTMENTS.ENVIRONMENT]: SECTORS.FOUR,
  [DEPARTMENTS.BUILDING]: SECTORS.FIVE,
  [DEPARTMENTS.LOGISTIC]: SECTORS.FIVE,
  [DEPARTMENTS.SOCIAL_SERVICES]: SECTORS.SIX,
};

export const DEPARTMENT_SECTOR_LABEL = {
  [SECTORS.NONE]: 'N/A',
  [SECTORS.ONE]:
    'Settore 1 - Organizzazione, politiche istituzionali e delle relazioni esterne',
  [SECTORS.TWO]:
    'Settore 2 - Servizi culturali, di promozione turistica, servizi demografici',
  [SECTORS.THREE]: 'Settore 3 - Gestione e programmazione finanziaria',
  [SECTORS.FOUR]: 'Settore 4 - Gestione e Programmazione Finanziaria',
  [SECTORS.FIVE]:
    'Settore 5 - Programmazione ed attuazione delle politiche urbanistiche e servizi manutentivi',
  [SECTORS.SIX]: 'Settore 6 - Politiche per la persona',
  [SECTORS.SEVEN]:
    'Settore 7 - Politiche per la sicurezza del cittadino e della mobilità',
};

export const ROLES_LABEL = {
  [ROLES.EMPLOYEE]: 'Dipendente',
  [ROLES.MANAGER]: 'Dirigente',
  [ROLES.PO]: 'Referente',
  [ROLES.ADMINISTRATIVE]: 'Amministrativo',
  [DEPARTMENTS.SOCIAL_SERVICES]: {
    [ROLES.EMPLOYEE]: 'Assistente Sociale',
  },
};

export const PROCEDURE_STATE_LABEL = {
  [PROCEDURE_STATES.NEW]: 'NUOVA',
  [PROCEDURE_STATES.PENDING]: 'ELABORAZIONE',
  [PROCEDURE_STATES.SKIPPED]: 'IGNORATA',
  [PROCEDURE_STATES.COMPLETED]: 'INVIATA',
};

export const PROCEDURE_TYPE = {
  LEGACY: 'procedure',
  BUILDING: 'building_procedure',
};

export const PROCEDURE_TYPE_LABEL = {
  [PROCEDURE_TYPE.LEGACY]: 'GATE',
  [PROCEDURE_TYPE.BUILDING]: 'Pratica Edilizia',
};

export const PROCEDURE_TYPE_CATEGORY_LABEL = {
  [PROCEDURE_TYPE.LEGACY]: () => 'GATE',
  [PROCEDURE_TYPE.BUILDING]: getLabelBuildingProcedureCategory,
};

export const PROCEDURE_DETAIL_LINK = {
  [PROCEDURE_TYPE.LEGACY]: ROUTES.ARCHIVIO_GATE,
  [PROCEDURE_TYPE.BUILDING]: ROUTES.PRATICHE_EDILIZIA,
};

export const SHARE_STATUS = {
  ACTIVE: 'active',
  RESOLVED: 'resolved',
  CLOSED: 'closed',
};

export const SHARE_STATUS_LABEL = {
  [SHARE_STATUS.ACTIVE]: 'In Corso',
  [SHARE_STATUS.RESOLVED]: 'Risolta',
  [SHARE_STATUS.CLOSED]: 'Chiusa',
};

export const EXTENSION_STATUS = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const EXTENSION_STATUS_LABEL = {
  [EXTENSION_STATUS.NEW]: 'Da Gestire',
  [EXTENSION_STATUS.APPROVED]: 'Approvata',
  [EXTENSION_STATUS.REJECTED]: 'Rifiutata',
};

export const BUILDING_PROCEDURE_STATUS = {
  NEW: 'NEW',
  EXTENSION_REQUESTED: 'EXTENSION_REQUESTED',
  PROCESSED: 'PROCESSED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const BUILDING_PROCEDURE_STATUS_LABEL = {
  [BUILDING_PROCEDURE_STATUS.NEW]: 'Da Gestire',
  [BUILDING_PROCEDURE_STATUS.EXTENSION_REQUESTED]: 'Proroga Richiesta',
  [BUILDING_PROCEDURE_STATUS.PROCESSED]: 'In Gestione',
  [BUILDING_PROCEDURE_STATUS.PENDING]: 'In Attesa',
  [BUILDING_PROCEDURE_STATUS.APPROVED]: 'Approvata',
  [BUILDING_PROCEDURE_STATUS.REJECTED]: 'Rifiutata',
};

export const INTERNAL_PROCEDURE_STATUS = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  DRAFT: 'draft',
};

export const SHARED_INSTANCE_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const SHARED_INSTANCE_STATUS_LABEL = {
  [SHARED_INSTANCE_STATUS.OPEN]: 'Attiva',
  [SHARED_INSTANCE_STATUS.CLOSED]: 'Chiusa',
};

export const INTERNAL_PROCEDURE_STATUS_LABEL = {
  [INTERNAL_PROCEDURE_STATUS.ACTIVE]: 'Attivo',
  [INTERNAL_PROCEDURE_STATUS.CLOSED]: 'Chiuso',
  [INTERNAL_PROCEDURE_STATUS.DRAFT]: 'Bozza',
};

export const BUILDING_PROCEDURE_CATEGORY = {
  ROTTURA_SUOLO: 'rottura_suolo',
  OCCUPAZIONE_SUOLO_EDILIZIO: 'occupazione_suolo_edilizio',
  OCCUPAZIONE_SUOLO_PUBBLICO: 'occupazione_suolo_pubblico',
  TRASLOCHI_LAVORI: 'traslochi_lavori',
};

export const BUILDING_PROCEDURE_CATEGORY_LABEL = {
  [BUILDING_PROCEDURE_CATEGORY.ROTTURA_SUOLO]: 'Rottura Suolo',
  [BUILDING_PROCEDURE_CATEGORY.OCCUPAZIONE_SUOLO_EDILIZIO]:
    'Occ. Carat. Edilizio',
  [BUILDING_PROCEDURE_CATEGORY.OCCUPAZIONE_SUOLO_PUBBLICO]:
    'Occ. Suolo Pubblico',
  [BUILDING_PROCEDURE_CATEGORY.TRASLOCHI_LAVORI]: 'Occ. Traslochi/Lavori',
};

export const OCCUPAZIONE_SUOLO_PUBBLICO_TYPE = {
  TYPE_A: 'less_than_year',
  TYPE_B: 'more_than_year',
  TYPE_C: 'permanent',
};

export const OCCUPAZIONE_SUOLO_PUBBLICO_TYPE_LABEL = {
  [OCCUPAZIONE_SUOLO_PUBBLICO_TYPE.TYPE_A]:
    'Occupazione non eccedente i 364 giorni',
  [OCCUPAZIONE_SUOLO_PUBBLICO_TYPE.TYPE_B]:
    'Occupazione eccedente i 364 giorni',
  [OCCUPAZIONE_SUOLO_PUBBLICO_TYPE.TYPE_C]:
    'Occupazione a carattere assolutamente precario',
};

export const TRASLOCHI_LAVORI_THROUGH = {
  THROUGH_VEHICLE: 'vehicle',
  THROUGH_OTHER: 'other',
  THROUGH_STOPOVER: 'stopover',
};

export const TRASLOCHI_LAVORI_THROUGH_LABEL = {
  [TRASLOCHI_LAVORI_THROUGH.THROUGH_OTHER]: 'Altro',
  [TRASLOCHI_LAVORI_THROUGH.THROUGH_VEHICLE]: 'Veicolo',
  [TRASLOCHI_LAVORI_THROUGH.THROUGH_STOPOVER]: 'Solo accesso e sosta',
};

export const PROCEDURE_TYPES_LABEL = {
  [PROCEDURE_TYPES.ART22]: 'Art. 22',
  [PROCEDURE_TYPES.ART25]: 'Art. 25',
  [PROCEDURE_TYPES.RISCONTRO]: 'Riscontro',
  [PROCEDURE_TYPES.CILA]: 'CILA',
  [PROCEDURE_TYPES.SCIA]: 'SCIA',
};

export const PROCEDURE_TYPE_OWNER = {
  [PROCEDURE_TYPE.LEGACY]: DEPARTMENTS.BUILDING,
  [PROCEDURE_TYPE.BUILDING]: DEPARTMENTS.BUILDING,
};

export const REQUEST_STATES = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const REQUEST_STATE_LABEL = {
  [REQUEST_STATES.NEW]: 'Nuova',
  [REQUEST_STATES.APPROVED]: 'Approvata',
  [REQUEST_STATES.REJECTED]: 'Scartata',
};

export const USER_STATES = {
  UNACTIVE: 'unactive',
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};

export const USER_STATE_LABEL = {
  [USER_STATES.ACTIVE]: 'Attivo',
  [USER_STATES.UNACTIVE]: 'Non Attivo',
  [USER_STATES.BLOCKED]: 'Bloccato',
};

export const PERSON_DOCUMENT_TYPE = {
  ID_CARD: 'id_card',
  PASSPORT: 'passport',
  DRIVING_LICENSE: 'driving_license',
  PATENTE_NAUTICA: 'patente_nautica',
  LIBRETTO_PENSIONE: 'libretto_pensione',
  PATENTINO_IMP_TERMICI: 'patentino_imp_termici',
  WEAPON_LICENSE: 'weapon_license',
  TESSERA_RICONOSCIMENTO: 'tessera_riconoscimento',
};

export const PERSON_DOCUMENT_TYPE_LABEL = {
  [PERSON_DOCUMENT_TYPE.ID_CARD]: 'Carta di Identità',
  [PERSON_DOCUMENT_TYPE.PASSPORT]: 'Passaporto',
  [PERSON_DOCUMENT_TYPE.DRIVING_LICENSE]: 'Patente',
  [PERSON_DOCUMENT_TYPE.PATENTE_NAUTICA]: 'Patente Nautica',
  [PERSON_DOCUMENT_TYPE.LIBRETTO_PENSIONE]: 'Libretto Pensione',
  [PERSON_DOCUMENT_TYPE.PATENTINO_IMP_TERMICI]: 'Patentino Imp. Termici',
  [PERSON_DOCUMENT_TYPE.WEAPON_LICENSE]: "Porto d'Armi",
  [PERSON_DOCUMENT_TYPE.TESSERA_RICONOSCIMENTO]: 'Tessera di Riconoscimento',
};

export const PROCEDURE_CATEGORIES = [
  'CONTENZIOSO',
  'INSEGNE',
  '94 COND',
  'PIANI PARTIC.',
  '.PRATICA ED.',
  'URBANISTICA',
  '85 COND',
  'COND. 85 A.48',
  'COMMISSIONI',
  'CDU',
  'C003',
  '<Nessuno>',
  'PREGRESSO AMBIENTE',
  'PRATICA AMBIENTE',
  'AREE PUBBL.',
  'COMM. FISSO',
  'INCARICHI',
  "AGIBILITA'",
  'CERTIFICAZIONI',
  'PUBBL. ESERC.',
  'WUE',
  'SERV.PERSONA',
  'PROCEDIMENTI',
  'POSTEGGI',
  'ATT. RICETTIVE',
  'SETT. TRASPORTI',
  'STAB.BALNEARI',
  'PUBBLICA SICUREZZA',
  'ARTIGIANATO',
  'COMM. FORME SPECIALI',
  'LUNA PARK',
  'ASCENSORI',
  "AGENZIA D'AFFARI",
  'SPETTACOLO VIAGGIANTE',
  'AZ. AGRICOLA',
  'SCIA PUBBL. ESER.',
  '.PEREQUAZIONI',
  'PRESIDI SANITARI - SOCIALI',
  'SUAP',
  'SUAP-COMUNICAZ.',
  'IMPIANTI CARBURANTI',
  'ACCESSO ATTI',
  'SUAP',
  '.MANUTENZIONE ORDINARIA',
  'IMPIANTI SPORTIVI',
  'Bando/Gara',
  'Comunicaz. Trimestrali',
  'Esproprio',
  'Finanziamento',
  'Esecuzione Lavori',
  'LOTTO',
  'Piano dei Costi',
  'PIANO TRIENNALE',
  'LAVORO/INTERVENTO',
  'PARERI',
  'Progettazione',
  'PRATICA TERRITORIO',
];

export const SESSION_STATE_LABEL = {
  [SESSION_STATE.ACTIVE]: 'Attiva',
  [SESSION_STATE.EXPIRED]: 'Terminata',
};

export const BUSINESS_PERSON_TYPE = {
  COMPANY_REPRESENTATIVE: 'company_representative',
  PROPERTY_MANAGER: 'property_manager',
};

export const BUSINESS_PERSON_TYPE_LABEL = {
  [BUSINESS_PERSON_TYPE.COMPANY_REPRESENTATIVE]: 'Rapp. Legale',
  [BUSINESS_PERSON_TYPE.PROPERTY_MANAGER]: 'Avente Titolo',
};

export const EVENTI_LABEL = {
  [EVENTI.USER_ACTIVATED]: 'Utente Attivato',
  [EVENTI.USER_LOGGED_IN]: 'Login effettuato',
  [EVENTI.USER_LOGGED_OUT]: 'Logout effettuato',
  [EVENTI.USER_CREATED]: 'Utente Creato',
  [EVENTI.USER_UPDATED]: 'Utente Modificato',
  [EVENTI.USER_DELETED]: 'Utente Eliminato',
  [EVENTI.PROCEDURE_ADDED]: 'Pratica Aggiunta nel sistema',
  [EVENTI.PROCEDURE_SENT]: 'Email di determina dirigenziale inviata',
  [EVENTI.PROCEDURE_SKIPPED]: 'Pratica ignorata',
  [EVENTI.PROCEDURE_DOCUMENT_UPLOADED]: 'Pratica - Nuovo documento caricato',
  [EVENTI.PROCEDURE_CREATED]: 'Pratica Creata',
  [EVENTI.PROCEDURE_UPDATED]: 'Pratica Aggiornata',
  [EVENTI.PROCEDURE_PROCESSED]: 'Pratica - In Gestione',
  [EVENTI.PROCEDURE_ADDITIONAL_DOCUMENTS_REQUESTED]:
    'Pratica - Documenti Aggiuntivi Richiesti',
  [EVENTI.PROCEDURE_APPROVED]: 'Pratica Approvata',
  [EVENTI.PROCEDURE_REJECTED]: 'Pratica Rifiutata',
  [EVENTI.PROCEDURE_EXTENSION_REQUESTED]: 'Proroga Richiesta',
  [EVENTI.PROCEDURE_EXTENSION_APPROVED]: 'Proroga Approvata',
  [EVENTI.PROCEDURE_EXTENSION_REJECTED]: 'Proroga Rifiutata',
  [EVENTI.SHARED_PROCEDURE_CREATED]: 'Pratica Condivisa',
  [EVENTI.SHARED_PROCEDURE_RESOLVED]: 'Pratica Condivisa Risolta',
  [EVENTI.SHARED_PROCEDURE_CLOSED]: 'Condivisione Pratica Interrotta',
  [EVENTI.SHARED_PROCEDURE_DOCUMENT_UPLOADED]:
    'Pratica Condivisa - Aggiunto Documento',
  [EVENTI.SHARED_PROCEDURE_DOCUMENT_DELETED]:
    'Pratica Condivisa - Documento Eliminato',
  [EVENTI.SHARED_PROCEDURE_NOTES_UPDATED]:
    'Pratica Condivisa - Note aggiornate',
  [EVENTI.REGISTRATION_APPROVED]: 'Cittadini - Richiesta Approvata',
  [EVENTI.REGISTRATION_REJECTED]: 'Cittadini - Richiesta Rigettata',
  [EVENTI.MANAGERIAL_ACT_SIGNED]: 'Atti Dirigenziali - Atto firmato',
};

export const PERIODI = {
  MONTH1: '1m',
  MONTH3: '3m',
  MONTH6: '6m',
  YEAR1: '1y',
  ALL: 'all',
};

export const PERIODI_LABELS = {
  TODAY: 'Oggi',
  WEEK1: 'Ultima Settimana',
  MONTH1: 'Ultimo Mese',
  MONTH3: 'Ultimi 3 Mesi',
  MONTH6: 'Ultimi 6 Mesi',
  YEAR1: 'Ultimo Anno',
  ALL: 'Tutti',
};

export const DEPARTMENT_HOME = {
  [DEPARTMENTS.ADMIN]: `/${ROUTES.DIPARTIMENTI}`,
  [DEPARTMENTS.LOGISTIC]: `/${ROUTES.ARCHIVIO_GATE}`,
  [DEPARTMENTS.BUILDING]: `/${ROUTES.PRATICHE_EDILIZIA}`,
  [DEPARTMENTS.SOCIAL_SERVICES]: `/${ROUTES_SERVIZI_SOCIALI.LISTA_PRATICHE}`,
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: `/${ROUTES.CERTIFICATI_DEMOGRAFICI}`,
  [DEPARTMENTS.STATO_CIVILE]: `/${ROUTES.CERTIFICATI_STATO_CIVILE}`,
  [DEPARTMENTS.CED]: `/${ROUTES.UTENTI}`,
  default: `/${ROUTES.CONDIVISIONE}`,
};

export const CERTIFICATE_TYPES = {
  ANAGRAFICO_DI_NASCITA: 'ANAGRAFICO_DI_NASCITA',
  ANAGRAFICO_DI_MORTE: 'ANAGRAFICO_DI_MORTE',
  ANAGRAFICO_DI_MATRIMONIO: 'ANAGRAFICO_DI_MATRIMONIO',
  CANCELLAZIONE_ANAGRAFICA: 'CANCELLAZIONE_ANAGRAFICA',
  CITTADINANZA: 'CITTADINANZA',
  STORICO_DI_CITTADINANZA: 'STORICO_DI_CITTADINANZA',
  ESISTENZA_IN_VITA: 'ESISTENZA_IN_VITA',
  RESIDENZA: 'RESIDENZA',
  STORICO_DI_RESIDENZA: 'STORICO_DI_RESIDENZA',
  RESIDENZA_AIRE: 'RESIDENZA_AIRE',
  STATO_CIVILE: 'STATO_CIVILE',
  STATO_DI_FAMIGLIA: 'STATO_DI_FAMIGLIA',
  STATO_DI_FAMIGLIA_E_STATO_CIVILE: 'STATO_DI_FAMIGLIA_E_STATO_CIVILE',
  RESIDENZA_IN_CONVIVENZA: 'RESIDENZA_IN_CONVIVENZA',
  STATO_DI_FAMIGLIA_AIRE: 'STATO_DI_FAMIGLIA_AIRE',
  STATO_DI_FAMIGLIA_CON_RAPPORTI_DI_PARENTELA:
    'STATO_DI_FAMIGLIA_CON_RAPPORTI_DI_PARENTELA',
  STATO_LIBERO: 'STATO_LIBERO',
  ANAGRAFICO_UNIONE_CIVILE: 'ANAGRAFICO_UNIONE_CIVILE',
  CONTRATTO_DI_CONVIVENZA: 'CONTRATTO_DI_CONVIVENZA',
  STORICO_RESIDENZA_DALLA_DATA: 'STORICO_RESIDENZA_DALLA_DATA',
};

export const CERTIFICATE_TYPES_LABEL = {
  [CERTIFICATE_TYPES.ANAGRAFICO_DI_NASCITA]: 'Anagrafico di nascita',
  [CERTIFICATE_TYPES.ANAGRAFICO_DI_MORTE]: 'Anagrafico di morte',
  [CERTIFICATE_TYPES.ANAGRAFICO_DI_MATRIMONIO]: 'Anagrafico di matrimonio',
  [CERTIFICATE_TYPES.CANCELLAZIONE_ANAGRAFICA]: 'di Cancellazione anagrafica',
  [CERTIFICATE_TYPES.CITTADINANZA]: 'di Cittadinanza',
  [CERTIFICATE_TYPES.STORICO_DI_CITTADINANZA]: 'storico di Cittadinanza',
  [CERTIFICATE_TYPES.ESISTENZA_IN_VITA]: 'di Esistenza in vita',
  [CERTIFICATE_TYPES.RESIDENZA]: 'di Residenza',
  [CERTIFICATE_TYPES.STORICO_DI_RESIDENZA]: 'storico di Residenza',
  [CERTIFICATE_TYPES.RESIDENZA_AIRE]: 'di Residenza AIRE',
  [CERTIFICATE_TYPES.STATO_CIVILE]: 'di Stato civile',
  [CERTIFICATE_TYPES.STATO_DI_FAMIGLIA]: 'di Stato di famiglia',
  [CERTIFICATE_TYPES.STATO_DI_FAMIGLIA_E_STATO_CIVILE]:
    'di Stato di famiglia e di stato civile',
  [CERTIFICATE_TYPES.RESIDENZA_IN_CONVIVENZA]: 'di Residenza in convivenza',
  [CERTIFICATE_TYPES.STATO_DI_FAMIGLIA_AIRE]: 'di Stato di famiglia AIRE',
  [CERTIFICATE_TYPES.STATO_DI_FAMIGLIA_CON_RAPPORTI_DI_PARENTELA]:
    'di Stato di famiglia con rapporti di parentela',
  [CERTIFICATE_TYPES.STATO_LIBERO]: 'di Stato Libero',
  [CERTIFICATE_TYPES.ANAGRAFICO_UNIONE_CIVILE]: 'Anagrafico di Unione Civile',
  [CERTIFICATE_TYPES.CONTRATTO_DI_CONVIVENZA]: 'di Contratto di Convivenza',
  [CERTIFICATE_TYPES.STORICO_RESIDENZA_DALLA_DATA]:
    'Storico di Residenza alla data',
};

export const CERTIFICATE_REQUEST_STATE = {
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
  HANDLED: 'HANDLED',
  RESOLVED: 'RESOLVED',
};

export const CERTIFICATE_REQUEST_STATE_LABEL = {
  [CERTIFICATE_REQUEST_STATE.SUCCESSFUL]: 'Riuscita',
  [CERTIFICATE_REQUEST_STATE.FAILED]: 'Fallita',
  [CERTIFICATE_REQUEST_STATE.RESOLVED]: 'Risolta',
  [CERTIFICATE_REQUEST_STATE.HANDLED]: 'In Carico',
};

export function getLabelEvento(key) {
  return EVENTI_LABEL[key] ? EVENTI_LABEL[key] : key;
}

export function getLabelSessionState(key) {
  key = key.toLowerCase();
  return SESSION_STATE_LABEL[key] ? SESSION_STATE_LABEL[key] : key;
}

export function getLabelPeriodo(key) {
  return PERIODI_LABELS[key] ? PERIODI_LABELS[key] : key;
}

export function getLabelProcedureType(key) {
  return PROCEDURE_TYPES_LABEL[key] ? PROCEDURE_TYPES_LABEL[key] : key;
}

export function getLabelProcedureState(key) {
  return PROCEDURE_STATE_LABEL[key] ? PROCEDURE_STATE_LABEL[key] : key;
}

export function getLabelShareState(key) {
  return SHARE_STATUS_LABEL[key] ? SHARE_STATUS_LABEL[key] : key;
}

export function getLabelExtensionState(key) {
  return EXTENSION_STATUS_LABEL[key] ? EXTENSION_STATUS_LABEL[key] : key;
}

export function getLabelBuildingProcedureState(key) {
  return BUILDING_PROCEDURE_STATUS_LABEL[key]
    ? BUILDING_PROCEDURE_STATUS_LABEL[key]
    : key;
}

export function getLabelInternalProcedureState(key) {
  return INTERNAL_PROCEDURE_STATUS_LABEL[key]
    ? INTERNAL_PROCEDURE_STATUS_LABEL[key]
    : key;
}

export function getLabelBuildingProcedureCategory(key) {
  return BUILDING_PROCEDURE_CATEGORY_LABEL[key]
    ? BUILDING_PROCEDURE_CATEGORY_LABEL[key]
    : key;
}

export function getLabelOccupazioneSuoloType(key) {
  return OCCUPAZIONE_SUOLO_PUBBLICO_TYPE_LABEL[key]
    ? OCCUPAZIONE_SUOLO_PUBBLICO_TYPE_LABEL[key]
    : key;
}

export function getLabelTraslochiLavoriThrough(key) {
  return TRASLOCHI_LAVORI_THROUGH_LABEL[key]
    ? TRASLOCHI_LAVORI_THROUGH_LABEL[key]
    : key;
}

export function getAbstractProcedureLink(type, id) {
  return PROCEDURE_DETAIL_LINK[type]
    ? `/${PROCEDURE_DETAIL_LINK[type]}/${id}`
    : `#`;
}

export function getLabelBusinessTypePerson(type) {
  return BUSINESS_PERSON_TYPE_LABEL[type]
    ? BUSINESS_PERSON_TYPE_LABEL[type]
    : type;
}

export function isProcedureOwner(type) {
  return (
    store.app.profile.isMemberOf(PROCEDURE_TYPE_OWNER[type]) ||
    store.app.profile.isMemberOf(DEPARTMENTS.ADMIN)
  );
}

export function getRequestStatusLabel(key) {
  return REQUEST_STATE_LABEL[key] ? REQUEST_STATE_LABEL[key] : key;
}

export function getUserStatusLabel(key) {
  return USER_STATE_LABEL[key] ? USER_STATE_LABEL[key] : key;
}

export function getDocumentTypeLabel(key) {
  return PERSON_DOCUMENT_TYPE_LABEL[key]
    ? PERSON_DOCUMENT_TYPE_LABEL[key]
    : key;
}

export function getGroupHomepage(group) {
  return DEPARTMENT_HOME[group]
    ? DEPARTMENT_HOME[group]
    : DEPARTMENT_HOME['default'];
}

export function getFullProcedureType(type, category) {
  const getLabel = PROCEDURE_TYPE_CATEGORY_LABEL[type]
    ? PROCEDURE_TYPE_CATEGORY_LABEL[type]
    : () => 'N/A';
  return getLabel(category);
}

export function getServiceType(type) {
  return PROCEDURE_TYPE_LABEL[type] ? PROCEDURE_TYPE_LABEL[type] : type;
}

export function getCertificateTypeLabel(key) {
  return CERTIFICATE_TYPES_LABEL[key] ? CERTIFICATE_TYPES_LABEL[key] : key;
}

export function getCertificateStateLabel(key) {
  return CERTIFICATE_REQUEST_STATE_LABEL[key]
    ? CERTIFICATE_REQUEST_STATE_LABEL[key]
    : key;
}

export function getDepartmentLabel(key) {
  return DEPARTMENTS_LABEL[key] ? DEPARTMENTS_LABEL[key] : key;
}

export function getRoleLabel(key, department = undefined) {
  if (
    department &&
    Object(ROLES_LABEL).hasOwnProperty(department) &&
    Object(ROLES_LABEL[department]).hasOwnProperty(key)
  ) {
    return ROLES_LABEL[department][key];
  }

  return ROLES_LABEL[key] ? ROLES_LABEL[key] : key;
}

export function getDepartmentSectorLabel(department) {
  const key = DEPARTMENT_SECTOR[department]
    ? DEPARTMENT_SECTOR[department]
    : department;
  return DEPARTMENT_SECTOR_LABEL[key] ? DEPARTMENT_SECTOR_LABEL[key] : key;
}

export function getSharedInstanceStatusLabel(key) {
  return SHARED_INSTANCE_STATUS_LABEL[key]
    ? SHARED_INSTANCE_STATUS_LABEL[key]
    : key;
}
