import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Input,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import {
  TempFileConfirmationRequest,
  WelfareInstanceEventDTO,
} from '../../../../../../gen/api/welfare';
import { drawerWidth } from '../../../../../../index';
import store from '../../../../../../store';
import Loader from '../../../../../common/Loader';
import { useDialog } from '../../../../common/upload/useDialog';
import { UploadDialog } from '../../../../common/upload/Dialog';
import Document from '../../../../common/Document';
import { addNote } from '../../../../api/api';

const styles = (theme: {
  palette: { primary: { main: any }; grey: { dark: any } };
}) => ({
  container: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    marginLeft: `${drawerWidth + 2}px`,
  },
  content: {
    width: '100%',
    padding: '32px 32px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 80px rgba(0, 43, 85, 0.1)',
  },
  header: {
    height: '50px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: '20px 20px',
    color: theme.palette.primary.main,
    borderBottom: '1px solid #eee',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '20px',
    lineHeight: '100%',
  },
  headerIcon: {
    marginRight: '10px',
  },
  headerRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadButton: {
    minWidth: '48px',
    marginRight: '16px',
  },
  uploadButtonIcon: {
    height: '24px',
  },
  form: {
    flex: 1,
    marginRight: 32,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  documents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '360px',
    overflow: 'auto',
    marginTop: 24,
    '& > * + *': {
      marginTop: 8,
    },
  },
  inputLabel: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.grey.dark,
    marginBottom: 16,
  },
  replyButton: {
    height: 48,
  },
  documentButton: {
    marginRight: '8px',
  },
});

const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;
interface ReplyBoxProps extends StyledComponentProps {
  procedureId: string;
  onNewReply: (reply: WelfareInstanceEventDTO) => void;
}
const ReplyBox: FC<ReplyBoxProps> = ({
  classes = {},
  procedureId,
  onNewReply,
}) => {
  const { snackBar } = store.app;
  const { open, openDialog, closeDialog } = useDialog();

  const [notes, setNotes] = useState('');
  const [documents, setDocuments] = useState<TempFileConfirmationRequest[]>([]);
  const [postingReply, setPostingReply] = useState(false);

  const onNotesChanged = useCallback(event => {
    setNotes(event.target.value);
  }, []);

  const onReplyClick = useCallback(() => {
    setPostingReply(true);

    addNote(procedureId, notes, documents)
      .then((response: WelfareInstanceEventDTO) => {
        snackBar.open('Nota aggiunta con successo');
        setPostingReply(false);
        setNotes('');
        setDocuments([]);
        onNewReply(response);
      })
      .catch(() => {
        snackBar.open("Si è verificato un problema durante l'invio della nota");
        setPostingReply(false);
      });
  }, [procedureId, notes, documents, snackBar, onNewReply]);

  const onDocumentUploaded = useCallback(({ fid, name }) => {
    setDocuments(docs => {
      return [...docs, { fid, name }];
    });
  }, []);

  const onDocumentDeleted = useCallback(id => {
    // DocumentiTemporanei.delete(id);
    setDocuments(docs => {
      return docs.filter(d => d.fid !== id);
    });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Loader loading={postingReply} style={{ marginBottom: '30px' }} />
        {!postingReply && (
          <>
            <span className={classes.inputLabel}>Aggiungi Nota</span>
            <UploadDialog
              open={open}
              onClose={closeDialog}
              onDocumentUploaded={onDocumentUploaded}
            />
            <div className={classes.formContainer}>
              <div className={classes.form}>
                <FormControl fullWidth>
                  <Input
                    placeholder="Inizia a scrivere una nota"
                    id="notes"
                    fullWidth
                    inputProps={{
                      maxLength: 2000,
                    }}
                    value={notes}
                    onChange={onNotesChanged}
                  />
                </FormControl>
              </div>
              <div className={classes.actions}>
                <Button
                  variant="outlined"
                  disabled={open}
                  color="primary"
                  onClick={openDialog}
                  className={classes.documentButton}
                >
                  <UploadIcon />
                </Button>
                <Button
                  className={classes.replyButton}
                  size="large"
                  variant="raised"
                  color="primary"
                  disabled={!notes}
                  onClick={onReplyClick}
                >
                  Inserisci
                </Button>
              </div>
            </div>
          </>
        )}
        {documents.length > 0 && (
          <div className={classes.documents}>
            {documents.map(d => (
              <Document
                key={d.fid}
                deletable
                documentId={d.fid}
                fileName={d.name}
                extension={DOCUMENT_EXTENSION.exec(d.name)![1]}
                onClick={onDocumentDeleted}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedReplyBox = withStyles(styles)(ReplyBox);
export { DecoratedReplyBox as ReplyBox };
