import React, { FC, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { useApproveRejectInstanceContext } from '../../../../../../../stm/socialServices/approveRejectInstanceStm';
import { userPressedBack } from '../../../../../../../stm/socialServices/common.events';
import { userConfirmedApproveInstance } from '../../../../../../../stm/socialServices/approveRejectingInstanceEvents';
import Icon from '../../../../../../../new/components/icons';
import { useProcedureDetail } from '../../../../../../../stm/socialServices/procedureDetail.stm';
import { WelfareInstanceStatusDTO } from '../../../../../../../gen/api/welfare';

const styles = (theme: any) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  message: {
    width: '100%',
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.black.main,
  },
  text: {
    width: '100%',
    textAlign: 'center',
  },
  icon: {
    marginBottom: '10px',
  },
});

function titleMsgFromStatus(
  status: WelfareInstanceStatusDTO | undefined
): { title: string; message: string } {
  switch (status) {
    case WelfareInstanceStatusDTO.RejectedByEmployee:
    case WelfareInstanceStatusDTO.ApprovedByEmployee:
      return {
        title: 'Conferma decisione',
        message:
          "Sei sicuro di voler confermare la decisione dell'assistente sociale?",
      };
    case WelfareInstanceStatusDTO.ApprovedByPo:
    default:
      return {
        title: 'Conferma approvazione',
        message: "Sei sicuro di voler approvare l'istanza?",
      };
  }
}

const ApproveInstanceDialog: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { state: detailState } = useProcedureDetail();
  const stm = useApproveRejectInstanceContext();
  const onCancel = useCallback(() => {
    stm.dispatch(userPressedBack());
  }, [stm]);
  const confirmApprove = useCallback(() => {
    stm.dispatch(userConfirmedApproveInstance());
  }, [stm]);
  const { title, message } = titleMsgFromStatus(
    detailState.context?.procedure?.status
  );
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText className={classes.content}>
          <Icon type="Info" size={48} className={classes.icon} />
          <div className={classes.message}>{title}</div>
          <div className={classes.text}>{message}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default" autoFocus>
          Annulla
        </Button>
        <Button onClick={confirmApprove} color="primary" variant="raised">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedApproveInstanceDialog = withStyles(styles)(
  ApproveInstanceDialog
);
export { DecoratedApproveInstanceDialog as ApproveInstanceDialog };
