import React from 'react';
import { withStyles } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    margin: '10px 0px',
    padding: '10px 15px',
    border: 'solid 1px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    borderRadius: '2px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  arrow: {
    textAlign: 'right',
    color: theme.palette.grey.main,
  },
  contents: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
  },
});

const ListBox = withStyles(styles)(
  withRouter(
    class extends React.Component {
      static propTypes = {
        goTo: PropTypes.string,
        onItemClick: PropTypes.func,
      };

      onClick = () => {
        if (this.props.goTo) {
          this.props.history.push(this.props.goTo);
        } else if (this.props.onItemClick) {
          this.props.onItemClick();
        }
      };

      render() {
        const { classes } = this.props;
        return (
          <div className={classes.container} onClick={this.onClick}>
            <Row middle="xs" className={classes.contents}>
              <Col xs={11} sm={11} md={11} lg={11}>
                {this.props.children}
              </Col>
              <Col xs={1} sm={1} md={1} lg={1} className={classes.arrow}>
                {this.props.onItemClick && (
                  <i className="fa fa-chevron-right" />
                )}
              </Col>
            </Row>
          </div>
        );
      }
    }
  )
);

export default ListBox;
