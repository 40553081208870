import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from '../../hooks/notifications';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { Notification } from '../../types/notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Notifications, Close } from '@material-ui/icons';
import cx from 'classnames';
import store from '../../../store';

const styles = (theme: any) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    fontSize: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
});

interface Props extends StyledComponentProps, RouteComponentProps {}

const NotificationHandler: FC<Props> = ({ history, classes }) => {
  const [notification, setNotification] = useState<Notification<any>>();
  const [isOpen, setIsOpen] = useState(false);
  const { profile } = store.app;

  const { formatNotification, markAsRead } = useNotifications(n =>
    setNotification(n)
  );
  const { message, action } = useMemo(() => {
    return notification
      ? formatNotification(notification)
      : {
          message: undefined,
          action: undefined,
        };
  }, [formatNotification, notification]);

  const reset = useCallback(() => {
    setNotification(undefined);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (notification) {
      setIsOpen(true);
    }
  }, [notification]);

  const onSnackbarClicked = useCallback(() => {
    if (notification) {
      setIsOpen(false);
      markAsRead(notification.id);
      if (action?.department) profile.switchDepartment(action.department);
      if (action?.redirectTo) history.push(action.redirectTo);
    }
  }, [action, history, markAsRead, notification, profile]);

  const onSnackbarClosed = useCallback(() => {
    reset();
  }, [reset]);

  const onCloseClicked = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onSnackbarClosed();
    },
    [onSnackbarClosed]
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isOpen}
      autoHideDuration={8000}
      onClick={onSnackbarClicked}
      onClose={onSnackbarClosed}
    >
      <SnackbarContent
        className={classes?.container}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes?.message}>
            <Notifications
              className={cx(classes?.icon, classes?.iconVariant)}
            />
            {message || ''}
          </span>
        }
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onCloseClicked}
          >
            <Close className={classes?.icon} />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withRouter(withStyles(styles)(NotificationHandler));
