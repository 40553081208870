import { envValidation as env } from '@moveax/env-validation';

const API_URL = env.get('REACT_APP_API_URL').asUrl();
const NEW_API_URL = env.get('REACT_APP_NEW_API_URL').asUrl();
const REDIRECT_URL = env.get('REACT_APP_REDIRECT_URL').asUrl();
const LOGIN_URL = env.get('REACT_APP_LOGIN_URL').asUrl();
const LOGOUT_URL = env.get('REACT_APP_LOGOUT_URL').asUrl();
const FRONTOFFICE_URL = env.get('REACT_APP_FRONTOFFICE_URL').asUrl();
const UPLOAD_MAX_SIZE = env
  .get('REACT_APP_MAX_FILE_SIZE', 5)
  .asInteger('nullable');
const SENTRY_ENABLED = env
  .get('REACT_APP_SENTRY_ENABLED', false)
  .asBoolean('nullable');
const SENTRY_DSN = env.get('REACT_APP_SENTRY_DSN').asString('nullable');
const SENTRY_ENVIRONMENT = env
  .get('REACT_APP_SENTRY_ENV', 'local')
  .asString('nullable');
const ANALYTICS_URL = env.get('REACT_APP_ANALYTICS_REDIRECT_URL').asUrl();
const NOTIFICATIONS_POLL_INTERVAL = env
  .get('REACT_APP_NOTIFICATIONS_POLL_INTERVAL', 15)
  .asInteger();

export {
  API_URL,
  NEW_API_URL,
  REDIRECT_URL,
  LOGIN_URL,
  LOGOUT_URL,
  FRONTOFFICE_URL,
  UPLOAD_MAX_SIZE,
  SENTRY_ENABLED,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  ANALYTICS_URL,
  NOTIFICATIONS_POLL_INTERVAL,
};
