// eslint-ignore
import Client from './NewApiCallManager';
import { objectToFormData } from '../utils/form';

export default {
  sign: (options, document, onSuccess, onError) => {
    const data = objectToFormData({ ...options, file: document });
    Client.post(
      '/signed-documents/CADES/remote',
      response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        onSuccess(file);
      },
      onError,
      data,
      {
        responseType: 'blob',
      }
    );
  },
};
