import React, { FC, memo, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import { Checkbox } from './Flag/partial/Checkbox';
import { Collapse, StyledComponentProps } from '@material-ui/core';
import FormControlValidated from '../../common/FormControlValidated';
import { benefitLabelMap } from '../constants';
import { CurrencyInput } from '../new/section/CurrencyInput';
import { BenefitTypeDTO } from '../../../gen/api/welfare';

const styles = (theme: any) => ({
  labelAndFlag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkbox: {
    height: '24px',
    width: '24px',
  },
  collapse: {
    padding: '8px 0px',
    margin: '0px 16px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[400],
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

interface BenefitFlagProps extends StyledComponentProps {
  type: BenefitTypeDTO;
  value?: string;
  error?: string;
  handleChange: (type: BenefitTypeDTO, v?: string | null) => void;
}

// TODO (bug fixed on react 17)
// eslint-disable-next-line react/display-name
const BenefitFlag: FC<BenefitFlagProps> = memo(
  ({ classes = {}, value, type, handleChange, error }) => {
    const toogleCheck = useCallback(() => {
      if (value === undefined) {
        handleChange(type, null);
      } else {
        handleChange(type, undefined);
      }
    }, [handleChange, type, value]);

    const onChange = useCallback(
      e => {
        handleChange(type, e.target.value);
      },
      [handleChange, type]
    );
    return (
      <div className={classes.collapse}>
        <div className={classes.labelAndFlag}>
          <label> {benefitLabelMap[type]} </label>
          <Checkbox checked={value !== undefined} onChange={toogleCheck} />
        </div>

        <Collapse in={value !== undefined} timeout="auto" unmountOnExit>
          <FormControlValidated
            inputId="benefitValue"
            label="Valore beneficio"
            error={error}
            shrinkLabel
          >
            <CurrencyInput
              id="benefitValue"
              name="benefitValue"
              type="number"
              defaultValue={value}
              onChange={onChange}
              placeholder="Inserisci il valore"
              classes={
                {
                  inkbar: !error
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                } as Record<any, any>
              }
            />
          </FormControlValidated>
        </Collapse>
      </div>
    );
  }
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedBenefitFlag = withStyles(styles)(BenefitFlag);

export { DecoratedBenefitFlag as BenefitFlag };
