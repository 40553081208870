import React, { FC, useCallback } from 'react';
import { Button, StyledComponentProps } from '@material-ui/core';
import { FamilyMember } from '../api/model';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { useDialog } from './upload/useDialog';
import { FamiliarDialog } from './FamiliarDialog';

const styles = (theme: any) => ({
  container: {
    backgroundColor: theme.palette.blue.light,
    padding: '16px',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '12px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: '8px',
  },
  value: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '400',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    margin: '4px 0px',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
    marginTop: '26px',
  },
  action: {
    flex: 1,
  },
});

interface FamilyPanelProps extends StyledComponentProps {
  item: FamilyMember;
  onEdit?: (f: FamilyMember) => void;
  onRemove?: (id: string) => void;
  forbiddenCFValues?: string[];
}

const FamilyPanel: FC<FamilyPanelProps> = ({
  item,
  onEdit,
  onRemove,
  forbiddenCFValues = [],
  classes = {},
}) => {
  const { open, closeDialog, openDialog } = useDialog();
  const remove = useCallback(() => {
    if (onRemove) onRemove(item.id);
  }, [item.id, onRemove]);
  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          {item.firstName + ' ' + item.lastName}
        </div>
        <div className={classes.labelContainer}>
          <label className={classes.label}>Codice Fiscale:</label>
          <label className={classes.value}>{item.fiscalCode}</label>
        </div>
        <div className={classes.labelContainer}>
          <label className={classes.label}>Parentela:</label>
          <label className={classes.value}>{item.relationship}</label>
        </div>

        <div className={classes.labelContainer}>
          <label className={classes.label}>Data di nascita:</label>
          <label className={classes.value}>
            {moment(item.birthDate).format('DD-MM-YYYY')}
          </label>
        </div>
        <div className={classes.labelContainer}>
          <label className={classes.label}>Professione:</label>
          <label className={classes.value}>{item.profession ?? ''}</label>
        </div>
        {(onEdit || onRemove) && (
          <div className={classes.actionContainer}>
            {onEdit && (
              <Button
                variant="raised"
                color="primary"
                size="small"
                className={classes.action}
                onClick={openDialog}
              >
                Modifica
              </Button>
            )}
            {onRemove && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.action}
                onClick={remove}
              >
                Rimuovi
              </Button>
            )}
          </div>
        )}
      </div>
      <FamiliarDialog
        forbiddenCFValues={forbiddenCFValues}
        onClose={closeDialog}
        onSave={onEdit}
        open={open}
        defaultValues={item}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedFamilyPanel = withStyles(styles)(FamilyPanel);

export { DecoratedFamilyPanel as FamilyPanel };
