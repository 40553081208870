import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
  NewSocialServiceStates,
  useNewSocialService,
} from '../../../stm/socialServices/newSocialService.stm';
import {
  userPressedBack,
  userPressedGoOn,
} from '../../../stm/socialServices/common.events';
import { useIsValidStep } from '../../../stm/socialServices/hooks';
import { ChevronLeft } from '@material-ui/icons';

const styles = theme => ({
  container: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    alignSelf: 'stretch',
    padding: '24px 32px 48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export const StepperFooter = withStyles(styles)(({ classes }) => {
  const { dispatch, state } = useNewSocialService();
  const goOn = useCallback(() => {
    dispatch(userPressedGoOn);
  }, [dispatch]);
  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);
  const isValidStep = useIsValidStep();
  return (
    <div className={classes.container}>
      <Button
        variant="flat"
        color="primary"
        onClick={goBack}
        disabled={state.state === NewSocialServiceStates.selectingKind}
      >
        <ChevronLeft />
        Indietro
      </Button>
      <Button
        variant="raised"
        color="primary"
        onClick={goOn}
        disabled={!isValidStep}
      >
        Avanti
      </Button>
    </div>
  );
});
