import React, { FC, useCallback } from 'react';
import { Button, StyledComponentProps, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useProcedureEvents } from '../../../../../../../../stm/socialServices/procedureEvents.stm';
import { userPressRetry } from '../../../../../../../../stm/socialServices/common.events';

const styles = (theme: any) => ({
  container: {
    marginTop: '180px',
    alignSelf: 'center',
    width: '430px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    height: '96px',
    width: '96px',
    marginBottom: '24px',
    backgroundColor: '#fdd8dd',
    color: theme.palette.font.redDark,
    borderRadius: '48px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '700',
    marginBottom: '16px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    marginBottom: '56px',
  },
  button: {
    width: '232px',
    marginBottom: '8px',
  },
});

const ErrorLoading: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { dispatch } = useProcedureEvents();

  const onRetry = useCallback(() => {
    dispatch(userPressRetry());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <Close className={classes.image} />
      <label className={classes.title}>Ops...</label>
      <label className={classes.text}>
        Si è verificato un errore imprevisto durante la lettura degli eventi. Si
        prega di riprovare più tardi
      </label>
      <Button
        variant="raised"
        color="primary"
        onClick={onRetry}
        className={classes.button}
      >
        Riprova
      </Button>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedErrorLoading = withStyles(styles)(ErrorLoading);

export { DecoratedErrorLoading as ErrorLoading };
