import React, { useCallback } from 'react';
import { Button, withStyles } from '@material-ui/core';
import { FRONTOFFICE_URL } from '../config';
import EmptyListMessage from '../components/common/EmptyListMessage';

const styles = () => ({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const UnauthorizedPersonnel = ({ classes }) => {
  const onClick = useCallback(() => {
    window.location.href = FRONTOFFICE_URL;
  }, []);

  return (
    <div className={classes.container}>
      <EmptyListMessage
        visible
        message="Non si dispone delle autorizzazioni necessarie per accedere al servizio"
      />
      <Button variant="text" size="large" color="primary" onClick={onClick}>
        Torna ai servizi
      </Button>
    </div>
  );
};

export default withStyles(styles)(UnauthorizedPersonnel);
