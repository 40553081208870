import { extendObservable, action } from 'mobx';

export default class Snackbar {
  constructor() {
    extendObservable(this, {
      isOpen: false,
      message: null,
    });
  }

  open = action(message => {
    this.message = message;
    this.isOpen = true;
  });

  close = action(() => {
    this.message = null;
    this.isOpen = false;
  });
}
