import 'moment/locale/it';
import moment from 'moment';

export const formatTimestamp = (date, format = 'DD/MM/YYYY HH:mm') => {
  return moment
    .utc(date)
    .local()
    .format(format);
};

export const humanizedDiff = date => {
  return moment
    .utc(date)
    .local()
    .fromNow();
};

export const dateStringFromSeconds = seconds => {
  const data = new Date(seconds * 1000);
  const day = data.getDate();
  const month = data.getMonth() + 1;
  const year = data.getFullYear();
  return addZero(day) + '/' + addZero(month) + '/' + year;
};

export const timeStringFromSeconds = seconds => {
  const data = new Date(seconds * 1000);
  const hours = data.getHours();
  const minutes = data.getMinutes();
  return addZero(hours) + ':' + addZero(minutes);
};

export const countDownTimerFromSeconds = seconds => {
  const minutesTot = (seconds / 60) >> 0;
  const hours = (minutesTot / 60) >> 0;
  const minutes = minutesTot % 60;
  const second = seconds % 60;
  return addZero(hours) + ':' + addZero(minutes) + ':' + addZero(second);
};

function addZero(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}
