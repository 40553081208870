import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  CreateSecretariatActivityRequest,
  SecretariatActivity,
} from '../../../api/model';
import ServiziSociali from '../../../../../api/ServiziSociali';
import { Submitting } from './Submitting';
import { Error } from './Error';
import { Success } from './Success';

interface OperationResultProps {
  request: CreateSecretariatActivityRequest;
}

export const SecretariatOperationResult: FC<OperationResultProps> = ({
  request,
}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<SecretariatActivity>();
  const [error, setError] = useState<Error>();

  const post = useCallback(() => {
    setError(undefined);
    setLoading(true);
    ServiziSociali.journal.secretariat.create(
      r => {
        setResponse(r);
        setLoading(false);
      },
      e => {
        setError(e);
        setLoading(false);
      },
      request
    );
  }, [request]);

  useEffect(post, [post]);

  if (loading) return <Submitting />;
  if (error) return <Error onRetry={post} />;
  if (response) return <Success activityId={response.id} />;
  return null;
};
