import React, { ComponentType, FC, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router/index';
import { useProcedureDetail } from '../../../../stm/socialServices/procedureDetail.stm';
import { useLocalStateMachine } from '@moveax/redux-stm-act-react-utils';
import {
  EditProcedureContext,
  editProcedureStm,
  EditProcedureStmStates,
  SectionToEdit,
} from '../../../../stm/socialServices/editProcedure.stm';
import { Submitted } from './layout/Submitted';
import { Submitting } from './layout/Submitting';
import { Editing } from './layout/Editing';
import { Loading } from '../layout/Loading';

const ComponentMap: Record<EditProcedureStmStates, ComponentType> = {
  [EditProcedureStmStates.submitted]: Submitted,
  [EditProcedureStmStates.submitting]: Submitting,
  [EditProcedureStmStates.editing]: Editing,
  [EditProcedureStmStates.exitConfirm]: Editing,
};
const Edit: FC<RouteComponentProps<{ sectionToEdit: string }>> = ({
  match,
}) => {
  const { state } = useProcedureDetail();
  const section = match.params.sectionToEdit as SectionToEdit;
  const contextValue = useLocalStateMachine(editProcedureStm, {
    section,
    procedure: state.context?.procedure!,
    procedureId: state.context?.id!,
  });

  const Component = useMemo(
    () =>
      ComponentMap[contextValue.state?.state ?? EditProcedureStmStates.editing],
    [contextValue.state]
  );
  return (
    <EditProcedureContext.Provider value={contextValue}>
      {state.context?.procedure && !!contextValue.state?.context ? (
        <Component />
      ) : (
        <Loading />
      )}
    </EditProcedureContext.Provider>
  );
};

const DecoratedEdit = withRouter(Edit);

export { DecoratedEdit as EditProcedure };
