import { FILTER_TYPE, FILTER_SIZE } from './constants';

export default class FilterItem {
  constructor(id, type, label, defaultValue, values, xs, sm, md, lg, modifier) {
    this.id = id;
    this.type = type ? type : FILTER_TYPE.TEXT;
    this.label = label;
    this.defaultValue = defaultValue;
    this.values = values ? values : [];
    this.xs = xs ? xs : FILTER_SIZE.xs;
    this.sm = sm ? sm : FILTER_SIZE.sm;
    this.md = md ? md : FILTER_SIZE.md;
    this.lg = lg ? lg : FILTER_SIZE.lg;
    this.modifier = modifier;
  }
}
