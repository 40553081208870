import React, { ComponentType, FC, useCallback, useMemo } from 'react';
import {
  EditProcedureStmStates,
  SectionToEdit,
  useEditProcedure,
} from '../../../../../stm/socialServices/editProcedure.stm';
import { EditUndetectedExpenses } from './EditUndetectedExpenses';
import { withStyles } from '@material-ui/core/styles';
import { StyledComponentProps } from '@material-ui/core';
import { EditIsee } from './EditIsee';
import { EditOtherBenefits } from './EditOtherBenefits';
import { compile } from 'path-to-regexp';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import { userAbortExit } from '../../../../../stm/socialServices/editProcedure.events';

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: theme.palette.white.main,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

const ComponentMap: Record<SectionToEdit, ComponentType> = {
  [SectionToEdit.isee]: EditIsee,
  [SectionToEdit.benefits]: EditOtherBenefits,
  [SectionToEdit.expenses]: EditUndetectedExpenses,
};

const modalTitle: Record<SectionToEdit, string> = {
  [SectionToEdit.isee]: 'Modifica ISEE',
  [SectionToEdit.benefits]: 'Modifica altri benefici',
  [SectionToEdit.expenses]: 'Modifica spese non detratte',
};
const Editing: FC<StyledComponentProps & RouteComponentProps> = ({
  classes = {},
  history,
}) => {
  const { state, dispatch } = useEditProcedure();
  const Component = useMemo(
    () => ComponentMap[state?.context?.section ?? SectionToEdit.isee],
    [state]
  );
  const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`, {
    encode: encodeURIComponent,
  });

  const confirmExit = useCallback(() => {
    history.goBack();
  }, [history]);

  const cancel = useCallback(() => {
    dispatch(userAbortExit());
  }, [dispatch]);
  return (
    <div className={classes.container}>
      {Component ? (
        <Component />
      ) : (
        <>
          <Redirect
            from={ROUTES_SERVIZI_SOCIALI.EDIT_PRATICA}
            to={toPath({ praticaId: state.context!.procedureId })}
          />
        </>
      )}
      <ConfirmDialog
        title={modalTitle[state.context!.section]}
        onConfirm={confirmExit}
        message="Attenzione, i dati non sono stati salvati, sei sicuro di voler uscire?"
        onCancel={cancel}
        open={state.state === EditProcedureStmStates.exitConfirm}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedEditing = withStyles(styles)(withRouter(Editing));

export { DecoratedEditing as Editing };
