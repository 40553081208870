/* eslint-disable react/no-multi-comp */
import React, { FC, useCallback } from 'react';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Avatar, StyledComponentProps, withStyles } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import Utenti from '../../../api/Utenti';
import { DEPARTMENTS, ROLES } from '../../../constants/security';
import { getCitizenFromApi } from '../api/transformer';
import { Citizen } from '../api/model';
import { WithTheme } from '@material-ui/core/es';
import { isString } from 'lodash';

const styles = (theme: any): StyleRules => ({
  avatar: {
    color: '#fff',
    height: 32,
    width: 32,
    fontSize: 16,
    backgroundColor: theme.palette.primary.main,
    marginRight: 8,
  },
  root: {},
  input: {
    display: 'flex',
    padding: '0 0 6px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    zIndex: 9999,
  },
});

function NoOptionsMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Nessun Risultato
    </Typography>
  );
}

function LoadingMessage(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Caricamento...
    </Typography>
  );
}

function inputComponent({
  inputRef,
  ...props
}: any & { inputRef: HTMLDivElement }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: any) {
  return (
    <TextField
      fullWidth
      label={props.selectProps.label}
      placeholder={props.selectProps.placeholder}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      InputLabelProps={{ shrink: true }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props: any) {
  const avatarString = isString(props.children)
    ? (props.children as string)
    : props.children[0];
  const matches = avatarString.match(/\b(\w)/g);
  const acronym =
    matches && matches.length > 1 ? [matches[0], matches[1]].join('') : 'PH';

  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar className={props.selectProps.classes.avatar}>{acronym}</Avatar>
        {props.children}
      </div>
    </MenuItem>
  );
}

function SingleValue(props: any) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: any) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props: any) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Option,
  Control,
  NoOptionsMessage,
  LoadingMessage,
  SingleValue,
  ValueContainer,
  IndicatorSeparator: () => null,
  DropdownIndicator: () => null,
  Menu,
};

interface SocialWorkerTypeAheadProps extends StyledComponentProps, WithTheme {
  label?: string;
  onChange: (value?: Citizen) => void;
  value?: Citizen;
}

const SocialWorkerTypeahead: FC<SocialWorkerTypeAheadProps> = ({
  label = 'Assistente sociale',
  onChange,
  value,
  classes = {},
}) => {
  const handleChange = useCallback(
    (c: Citizen | null) => {
      onChange(c ?? undefined);
    },
    [onChange]
  );

  const loadSocialWorkers = useCallback((inputValue, callback) => {
    Utenti.newSearchUser(
      {
        department: DEPARTMENTS.SOCIAL_SERVICES,
        onlyRoles: [ROLES.PO, ROLES.MANAGER, ROLES.EMPLOYEE],
        excludeSelf: 0,
        query: inputValue,
      },
      (data: { first_name: string; last_name: string }[]) => {
        const results = data.map(getCitizenFromApi);
        //setCitizens(results);
        callback(
          results.map(r => ({
            value: r,
            label: `${r.firstName} ${r.lastName}`,
          }))
        );
      },
      (error: Error) => {
        console.error('error during users retrieval', error);
        callback([]);
      }
    );
  }, []);

  return (
    <div className={classes.root}>
      <AsyncSelect
        styles={{
          clearIndicator(base, _props) {
            return {
              ...base,
              padding: 0,
            };
          },
        }}
        label={label}
        classes={classes}
        components={components}
        value={value ?? null}
        onChange={handleChange}
        defaultOptions={true}
        cacheOptions
        loadOptions={loadSocialWorkers}
        placeholder="Cerca per Nome o Cognome"
        isClearable
      />
    </div>
  );
};

const DecoratedSocialWorkerTypeahead = withStyles(styles, { withTheme: true })(
  SocialWorkerTypeahead
);

export { DecoratedSocialWorkerTypeahead as SocialWorkerTypeahead };
