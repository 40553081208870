import React, { useEffect, useState } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import PageTitle from '../../../../common/PageTitle';
import PageContainer from '../../../../common/PageContainer';
import { SecretariatActivity } from '../../../api/model';
import moment from 'moment';
import { getSecretariatActivity } from '../../../api/api';
import store from '../../../../../store';
import Loader from '../../../../common/Loader';
import CitizenSummary from '../../../common/CitizenSummary';
import ActivityDetails from './ActivityDetails';

interface SecretariatActivityRouteParams {
  activityId: string;
}

interface SecretariatActivityDetailProps
  extends StyledComponentProps,
    RouteComponentProps<SecretariatActivityRouteParams> {}

const SecretariatActivityDetail: React.FC<SecretariatActivityDetailProps> = ({
  history,
  match,
}) => {
  const { snackBar } = store.app;
  const { activityId } = match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState<SecretariatActivity>();

  useEffect(() => {
    setIsLoading(true);
    getSecretariatActivity(activityId)
      .then(setActivity)
      .catch(() => {
        snackBar.open('Risorsa non trovata');
        history.goBack();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [activityId, history, snackBar]);

  return (
    <PageContainer
      header={
        <PageTitle
          /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
          // @ts-ignore
          backIcon
          title="Dettaglio Attività"
          subtitle={
            activity
              ? `${moment(activity.createdAt).format(
                  '[Segretariato sociale del ] DD/MM/YYYY'
                )}`
              : '-'
          }
        />
      }
    >
      <Loader size={40} loading={isLoading} />
      {!isLoading && activity && (
        <>
          <CitizenSummary citizen={activity.citizen} />
          <ActivityDetails activity={activity} />
        </>
      )}
    </PageContainer>
  );
};

export default SecretariatActivityDetail;
