import React, { useMemo } from 'react';
import { DEPARTMENTS } from '../../../constants/security';
import ServiziDemograficiInstance from './Mapping/ServiziDemografici/ServiziDemograficiInstance';
import StatoCivileInstance from './Mapping/StatoCivile/StatoCivileInstance';
import WelfareInstanceDetails from './Mapping/ServiziSociali/WelfareInstanceDetails';

const INSTANCE_DEPARTMENT_MAP = {
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: ServiziDemograficiInstance,
  [DEPARTMENTS.STATO_CIVILE]: StatoCivileInstance,
  [DEPARTMENTS.SOCIAL_SERVICES]: WelfareInstanceDetails,
};

const InstanceDetails = ({ instance }) => {
  const Component = useMemo(() => {
    return INSTANCE_DEPARTMENT_MAP[instance.sharedInstance.ownerDepartment];
  }, [instance]);

  return <Component instanceDetails={instance.details} />;
};

export default InstanceDetails;
