import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Detail from './Detail';
import { DEPARTMENTS } from '../../constants/security';
import store from '../../store';
import Certificati from '../../api/Certificati';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import PageTitle from '../common/PageTitle';
import DetailActions from './Detail/DetailActions';
import PageContainer from '../common/PageContainer';

import { getCertificateTypeLabel } from '../../constants/common';
import moment from 'moment';
import Loader from '../common/Loader';

const DettagliCertificato = ({ location, history, match }) => {
  const { profile, snackBar } = store.app;
  const { certificateId } = match.params;
  const [selectedRequest, setSelectedRequest] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadingCertificate, setDownloadingCertificate] = useState(false);

  const canHandle = useMemo(() => {
    return profile.isMemberOf(
      location.pathname.indexOf('demo') > -1
        ? DEPARTMENTS.SERVIZI_DEMOGRAFICI
        : DEPARTMENTS.STATO_CIVILE
    );
  }, [location.pathname, profile]);

  const api =
    location.pathname.indexOf('demo') > -1
      ? Certificati.demographic
      : Certificati.civilState;

  const downloadCertificate = useCallback(() => {
    const slugName = selectedRequest.details.user.lastName.replace(' ', '-');
    const slugType = `Certificato ${getCertificateTypeLabel(
      selectedRequest.type
    )}`.replace(' ', '-');
    const slugDate = moment
      .utc(selectedRequest.details.createdAt)
      .local()
      .format('DD-MM-YYYY');
    setDownloadingCertificate(true);
    api.downloadCertificate(
      selectedRequest.details.id,
      `${slugName}_${slugType}_${slugDate}`,
      () => setDownloadingCertificate(false),
      () => {
        setDownloadingCertificate(false);
        snackBar.open('Errore durante il download del certificato');
      }
    );
  }, [api, selectedRequest, snackBar]);

  const loadRequest = useCallback(() => {
    setIsLoading(true);
    api.get(
      certificateId,
      request => {
        setIsLoading(false);
        setSelectedRequest(request);
      },
      () => {
        setIsLoading(false);
        snackBar.open('Il certificato richiesto non è stato trovato');
        const route =
          location.pathname.indexOf('demo') > -1
            ? ROUTES.CERTIFICATI_DEMOGRAFICI
            : ROUTES.CERTIFICATI_STATO_CIVILE;
        history.push(`/${route}`);
      }
    );
  }, [api, certificateId, history, location.pathname, snackBar]);

  useEffect(() => {
    loadRequest();
  }, [loadRequest]);

  return (
    <PageContainer
      header={
        <PageTitle
          title="Dettagli del Certificato Richiesto"
          backIcon
          actions={
            selectedRequest && (
              <DetailActions
                selectedRequest={selectedRequest}
                downloadCertificate={downloadCertificate}
                downloadingCertificate={downloadingCertificate}
              />
            )
          }
        />
      }
    >
      {selectedRequest && !isLoading && (
        <Detail request={selectedRequest} canHandle={canHandle} api={api} />
      )}
      <Loader loading={isLoading} />
    </PageContainer>
  );
};

export default withRouter(DettagliCertificato);
