/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagProblematicheValueDTO {
    Abitative = 'ABITATIVE',
    Disoccupazione = 'DISOCCUPAZIONE',
    Sanitarie = 'SANITARIE',
    Economiche = 'ECONOMICHE',
    NessunaReteFamiliare = 'NESSUNA_RETE_FAMILIARE'
}

export function FlagProblematicheValueDTOFromJSON(json: any): FlagProblematicheValueDTO {
    return FlagProblematicheValueDTOFromJSONTyped(json, false);
}

export function FlagProblematicheValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagProblematicheValueDTO {
    return json as FlagProblematicheValueDTO;
}

export function FlagProblematicheValueDTOToJSON(value?: FlagProblematicheValueDTO | null): any {
    return value as any;
}

