import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.png';
import scrivaniaVirtuale from '../../assets/images/scrivania-virtuale.png';

const PROP_TYPES = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

const DEFAULT_SIZE = {
  width: 40,
  height: 41,
};

export const AppLogo = props => (
  <img
    src={logo}
    alt="Comune Di Chiavari"
    width={props.width}
    height={props.height}
  />
);
AppLogo.propTypes = { ...PROP_TYPES };
AppLogo.defaultProps = { ...DEFAULT_SIZE };

export const VirtualDeskIcon = props => (
  <img
    src={scrivaniaVirtuale}
    className={props.className}
    alt="Scrivania Virtuale"
    width={props.width}
    height={props.height}
  />
);
VirtualDeskIcon.propTypes = { ...PROP_TYPES };
VirtualDeskIcon.defaultProps = { width: 30, height: 30 };
