import React, { FC } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import { RouteChildrenProps } from 'react-router';
import { useLocalStateMachine } from '@moveax/redux-stm-act-react-utils';
import {
  procedureDetailStm,
  ProcedureDetailStmContext,
} from '../../../stm/socialServices/procedureDetail.stm';

import {
  procedureEventStm,
  ProcedureEventStmContext,
} from '../../../stm/socialServices/procedureEvents.stm';
import { Redirect, Switch } from 'react-router/index';
import { PrivateRoute } from '../../common/navigation/PrivateRoute';
import { DEPARTMENTS, ROLES } from '../../../constants/security';
import { ROUTES_SERVIZI_SOCIALI } from '../../../constants/routes';
import { ProcedureDetail } from './ProcedureDetail';
import { compile } from 'path-to-regexp';
import { EditProcedure } from './edit';

const styles = (theme: any) => ({
  container: {
    backgroundColor: theme.palette.grey.light,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '38.4px',
  },
});

const ProcedureDetailSwitch: FC<StyledComponentProps &
  RouteChildrenProps<{ praticaId: string }>> = ({ classes = {}, match }) => {
  const praticaId = match?.params.praticaId;
  const contextValue = useLocalStateMachine(procedureDetailStm, {
    id: praticaId!,
  });
  const eventsContextValue = useLocalStateMachine(procedureEventStm, {
    id: praticaId!,
  });
  const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`, {
    encode: encodeURIComponent,
  });

  return (
    <ProcedureDetailStmContext.Provider value={contextValue}>
      <ProcedureEventStmContext.Provider value={eventsContextValue}>
        <div className={classes.container}>
          <Switch>
            <PrivateRoute
              department={DEPARTMENTS.SOCIAL_SERVICES}
              role={ROLES.ANY}
              path={`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`}
              routeComponent={ProcedureDetail}
              exact
            />
            <PrivateRoute
              department={DEPARTMENTS.SOCIAL_SERVICES}
              role={ROLES.ANY}
              path={`/${ROUTES_SERVIZI_SOCIALI.EDIT_PRATICA}`}
              routeComponent={EditProcedure}
              exact
            />
            <Redirect from="*" to={toPath({ praticaId: praticaId! })} />
          </Switch>
        </div>
      </ProcedureEventStmContext.Provider>
    </ProcedureDetailStmContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedProcedureDetailSwitch = withStyles(styles)(
  ProcedureDetailSwitch
);

export { DecoratedProcedureDetailSwitch as ProcedureDetailSwitch };
