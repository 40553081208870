import React, { FC, useCallback, useState } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Widget from '../../../../../common/documents/upload';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import moment from 'moment';
import { Attachment, TemporaryDocument } from '../../../../api/model';
import Document from '../../../../common/Document';
import NewDocumentiTermporanei from '../../../../../../api/NewDocumentiTermporanei';
import { getDocument } from '../../../../api/api';
import store from '../../../../../../store';

type ComponentVariant = 'bozza' | 'ragioneria';

const titleMap: Record<ComponentVariant, string> = {
  bozza: 'Determina in bozza',
  ragioneria: 'Determina di ragioneria',
};

const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;

const styles = (theme: any) => ({
  container: {
    padding: '24px',
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadButton: {
    minWidth: '40px',
    marginRight: '10px',
  },
  uploadButtonIcon: {
    height: '19px',
  },
  documentLoaded: {
    width: '100%',
    marginTop: '24px',
  },
});

interface AttachDocumentsProps extends StyledComponentProps {
  variant: ComponentVariant;
  onChange: (document?: TemporaryDocument) => void;
  document?: Attachment;
  deletable?: boolean;
}
const AttachDocuments: FC<AttachDocumentsProps> = ({
  classes = {},
  document,
  onChange,
  deletable = false,
  variant,
}) => {
  const { snackBar } = store.app;
  const [downloadingDocument, setDownloadingDocument] = useState(undefined);

  const onDownload = useCallback(
    (fid, name) => {
      setDownloadingDocument(fid);
      getDocument(fid, name)
        .then(() => setDownloadingDocument(undefined))
        .catch(() => {
          snackBar.open(`Errore nel download del file ${name}`);
          setDownloadingDocument(undefined);
        });
    },
    [snackBar]
  );

  const uploadDocument = useCallback((file, name, onSuccess, onError) => {
    NewDocumentiTermporanei.upload(
      file,
      (data: { fid: string }) => onSuccess(data.fid, name),
      onError
    );
  }, []);

  const onRemove = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const onDocumentUploaded = useCallback(
    (fid, name) => {
      onChange({ fid, name });
    },
    [onChange]
  );

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <label>{titleMap[variant]}</label>
        {!document && (
          <Widget
            buttonMode="collapsed"
            digitalSignatureEnabled={false}
            uploadDocumentApi={uploadDocument}
            onDocumentUploaded={onDocumentUploaded}
            buttonClassName={classes.uploadButton}
            buttonChild={
              <>
                <UploadIcon className={classes.uploadButtonIcon} />
                Allega documento
              </>
            }
          />
        )}
      </div>
      {document && (
        <div className={classes.documentLoaded}>
          <Document
            documentId={document.fid}
            fileName={document.name}
            extension={DOCUMENT_EXTENSION.exec(document.name)![1] ?? ''}
            deletable={deletable}
            date={moment(document.createdAt).format('DD/MM/YYYY HH:mm:SS')}
            onClick={deletable ? onRemove : onDownload}
            isDownloading={downloadingDocument !== undefined}
          />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedAttacchDocuments = withStyles(styles)(AttachDocuments);

export { DecoratedAttacchDocuments as AttachDocuments };
