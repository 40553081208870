import {
  BenefitTypeDTO,
  ExpenseTypeDTO,
  FlagAltroProvvedimentoAGValueDTO,
  FlagProblematicheValueDTO,
  FlagSegnalatoDaValueDTO,
  FlagSeguitoConValueDTO,
  FlagTipoAccessoValueDTO,
  FlagTypeDTO,
  WelfareInstanceSectionDTO,
  WelfareInstanceStatusDTO,
  WelfareInstanceTypeDTO,
  WelfareRequestorTypeDTO,
} from '../../gen/api/welfare';

export const SECTION_LABEL_MAP: Record<WelfareInstanceSectionDTO, string> = {
  [WelfareInstanceSectionDTO.Elders]: 'Anziani',
  [WelfareInstanceSectionDTO.Disabled]: 'Disabili',
  [WelfareInstanceSectionDTO.Family]: 'Minori',
  [WelfareInstanceSectionDTO.Poverty]: 'Povertà e disagio Adulti',
};

export const SERVICE_LABEL_MAP: Record<WelfareInstanceTypeDTO, string> = {
  [WelfareInstanceTypeDTO.AssistenzaDomiciliareAnziani]:
    'Assistenza Domiciliare',
  [WelfareInstanceTypeDTO.PastiDomicilioAnziani]: 'Pasti Caldi a Domicilio',
  [WelfareInstanceTypeDTO.AccessoResidenzeProtette]:
    'Accesso alle Residenze Protette',
  [WelfareInstanceTypeDTO.Asl4]: 'Convenzionate con la ASL4 Chiavarese',
  [WelfareInstanceTypeDTO.IntegrazioneRettaResidenzeProtette]:
    'Integrazione della Retta per gli Inserimenti in Residenza Protetta',
  [WelfareInstanceTypeDTO.AccessoPostiCastagnola]:
    'Accesso ai Posti in Convenzione presso la Residenza Protetta Castagnola',
  [WelfareInstanceTypeDTO.NonAutosufficenzaAssegnoCura]:
    'Fondo per la non Autosufficienza - Assegno di Cura',
  [WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaAnziani]:
    "Interventi Sociali per il Sostegno a Casa di Persone in Condizioni di Disabilita' Gravissima",
  [WelfareInstanceTypeDTO.AffidoEducativo]:
    'Affido Educativo Individuale e di Gruppo',
  [WelfareInstanceTypeDTO.IncontriProtetti]: 'Incontri Protetti',
  [WelfareInstanceTypeDTO.AffidoFamiliare]: 'Affido Familiare',
  [WelfareInstanceTypeDTO.InserimentoMinoriComunita]:
    "Inserimento dei Minori in Comunita' o Casa ",
  [WelfareInstanceTypeDTO.ComunitaMadreBambino]:
    "Famiglia o Comunita' Madre Bambino",
  [WelfareInstanceTypeDTO.EquipeAdozioni]:
    'Equipe Adozioni Nazionali ed Internazionali',
  [WelfareInstanceTypeDTO.EquipeAffido]: 'Equipe Affido',
  [WelfareInstanceTypeDTO.AssegniMaternita]:
    "Assegni di Maternita' (Legge 448/98)",
  [WelfareInstanceTypeDTO.AssegniNucleoFamiliare]:
    'Assegni al Nucleo Familiare con almeno 3 Figli Minori ',
  [WelfareInstanceTypeDTO.Ludoteca]: 'Ludoteca Comunale',
  [WelfareInstanceTypeDTO.ReteContrastoViolenza]:
    'Rete di Contrasto alla Violenza',
  [WelfareInstanceTypeDTO.EquipeAbuso]:
    'Equipe Abuso e maltrattamento (minori che hanno subito abusi e maltrattamenti e vengono seguiti da operatori specializzati)',
  [WelfareInstanceTypeDTO.ProntaAccoglienzaMinori]: 'Pronta accoglienza minori',
  [WelfareInstanceTypeDTO.AssistenzaDomiciliareDisabili]:
    'Assistenza Domiciliare',
  [WelfareInstanceTypeDTO.PastiCaldiDisabili]: 'Pasti Caldi a Domicilio',
  [WelfareInstanceTypeDTO.ServizioEducativoDomiciliare]:
    'Servizio Educativo Domiciliare e Scolastico',
  [WelfareInstanceTypeDTO.FondoNonAutosufficenza]:
    'Fondo per la non Autosufficienza - Assegno di Cura ',
  [WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaDisabili]:
    "Interventi Sociali per il Sostegno a Casa di Persone in Condizioni di Disabilita' Gravissima",
  [WelfareInstanceTypeDTO.ContributoVitaIndipendente]:
    'Contributo Vita Indipendente',
  [WelfareInstanceTypeDTO.ContributoSolidarietaInserimentoResidenziale]:
    "Contributo di Solidarieta' a Sostegno dell'Inserimento Residenziale o Semiresidenziale",
  [WelfareInstanceTypeDTO.RedditoCittadinanza]:
    'Reddito di Inclusione/Cittadinanza',
  [WelfareInstanceTypeDTO.ContributoContinuativoUnaTantum]:
    'Contributo Continuativo e Una Tantum',
  //   SERVIZIO_TRASPORTO_DISABILI
  // - CONTRIBUTO_DOPO_DI_NOI
  // - TELESOCCORSO_TELECONTROLLO
  // - AGEVOLAZIONI_TRASPORTO_PUBBLICO
  [WelfareInstanceTypeDTO.ServizioTrasportoDisabili]:
    'Servizio Trasporto Disabili',
  [WelfareInstanceTypeDTO.ContributoDopoDiNoi]: 'Contributo "Dopo di noi"',
  [WelfareInstanceTypeDTO.TelesoccorsoTelecontrollo]:
    'Telesoccorso/Telecontrollo',
  [WelfareInstanceTypeDTO.AgevolazioniTrasportoPubblico]:
    'Agevolazioni tariffe sui trasporti pubblici ',
};
export const SECTION_SERVICE_MAP: {
  [key in WelfareInstanceSectionDTO]: WelfareInstanceTypeDTO[];
} = {
  [WelfareInstanceSectionDTO.Elders]: [
    WelfareInstanceTypeDTO.AssistenzaDomiciliareAnziani,
    WelfareInstanceTypeDTO.PastiDomicilioAnziani,
    WelfareInstanceTypeDTO.AccessoResidenzeProtette,
    WelfareInstanceTypeDTO.Asl4,
    WelfareInstanceTypeDTO.IntegrazioneRettaResidenzeProtette,
    WelfareInstanceTypeDTO.AccessoPostiCastagnola,
    WelfareInstanceTypeDTO.NonAutosufficenzaAssegnoCura,
    WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaAnziani,
    WelfareInstanceTypeDTO.TelesoccorsoTelecontrollo,
    WelfareInstanceTypeDTO.AgevolazioniTrasportoPubblico,
  ],
  [WelfareInstanceSectionDTO.Family]: [
    WelfareInstanceTypeDTO.AffidoEducativo,
    WelfareInstanceTypeDTO.IncontriProtetti,
    WelfareInstanceTypeDTO.AffidoFamiliare,
    WelfareInstanceTypeDTO.InserimentoMinoriComunita,
    WelfareInstanceTypeDTO.ComunitaMadreBambino,
    WelfareInstanceTypeDTO.EquipeAdozioni,
    WelfareInstanceTypeDTO.EquipeAffido,
    WelfareInstanceTypeDTO.AssegniMaternita,
    WelfareInstanceTypeDTO.AssegniNucleoFamiliare,
    WelfareInstanceTypeDTO.Ludoteca,
    WelfareInstanceTypeDTO.EquipeAbuso,
    WelfareInstanceTypeDTO.ProntaAccoglienzaMinori,
  ],
  [WelfareInstanceSectionDTO.Disabled]: [
    WelfareInstanceTypeDTO.AssistenzaDomiciliareDisabili,
    WelfareInstanceTypeDTO.PastiCaldiDisabili,
    WelfareInstanceTypeDTO.ServizioEducativoDomiciliare,
    WelfareInstanceTypeDTO.FondoNonAutosufficenza,
    WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaDisabili,
    WelfareInstanceTypeDTO.ContributoVitaIndipendente,
    WelfareInstanceTypeDTO.ContributoSolidarietaInserimentoResidenziale,
    WelfareInstanceTypeDTO.ServizioTrasportoDisabili,
    WelfareInstanceTypeDTO.ContributoDopoDiNoi,
  ],
  [WelfareInstanceSectionDTO.Poverty]: [
    WelfareInstanceTypeDTO.RedditoCittadinanza,
    WelfareInstanceTypeDTO.ContributoContinuativoUnaTantum,
  ],
};

export const flagSituazionePersonaleLabelMap: Record<FlagTypeDTO, string> = {
  [FlagTypeDTO.ConflittoIntrafamiliare]:
    'Conflittualità intrafamiliare (compresa violenza assistita anche presunta, separazione conflittuale)',
  [FlagTypeDTO.FragilitaSocioEducativa]: 'Fragilità socio-educative',
  [FlagTypeDTO.NessunaReteFamiliare]: 'Mancanza di rete familiare e sociale',
  [FlagTypeDTO.VulnerabilitaGenitoriale]: 'Vulnerabilità genitoriale',
  [FlagTypeDTO.DipenzaDaDroghe]: 'Tossicodipendenza',
  [FlagTypeDTO.DipendenzaDaAlcool]: 'Alcooldipendenza',
  [FlagTypeDTO.PatologieMentali]: 'Patologie psichiche',
  [FlagTypeDTO.PatologieFisiche]: 'Patologie fisiche',
  [FlagTypeDTO.AbusiOMaltrattamenti]:
    'Abuso e/o Grave maltrattamento (anche presunto)',
  [FlagTypeDTO.DifficoltaFinaziarie]: 'Difficoltà economiche',
  [FlagTypeDTO.ProblematichePenali]: 'Problematiche penali',
  [FlagTypeDTO.Separazione]:
    'Separazione (sia della coppia di fatto che della coppia sposata)',
  [FlagTypeDTO.Monogenitoriale]:
    'Nucleo Monogenitoriale (bambino riconosciuto da un solo genitore)',
  [FlagTypeDTO.Adozione]: 'Adozione',
  [FlagTypeDTO.Affido]: 'Affido',
  [FlagTypeDTO.AffidoComunale]: 'Affidato al Comune di Chiavari',
  [FlagTypeDTO.AltroProvvedimentoAg]: 'Altro provvedimento A.G.',
  [FlagTypeDTO.SegnalatoDa]: 'Da chi è arrivata la segnalazione',
  [FlagTypeDTO.SeguitoCon]: 'Caso seguito con',
  [FlagTypeDTO.AffidiEducativi]: 'Affidi educativi',
  [FlagTypeDTO.PresenzaReteDiSupporto]: 'Presenza rete di supporto',
  [FlagTypeDTO.TipologiaDiAccesso]: 'Tipologia di accesso',
  [FlagTypeDTO.ViveSolo]: 'Vive solo',
  [FlagTypeDTO.Problematiche]: 'Problematiche',
  [FlagTypeDTO.ContributoSolidarietaPsichici]:
    'Contributo solidarieta psichici',
};

const AltroProvvedimentoAGLabelMap: Record<
  FlagAltroProvvedimentoAGValueDTO,
  string
> = {
  //[AltroProvvedimentoAG.None]: 'Nessun provvedimento',
  [FlagAltroProvvedimentoAGValueDTO.Monitoraggio]: 'Monitoraggio',
  [FlagAltroProvvedimentoAGValueDTO.Valutazione]: 'Valutazione',
} as const;

export const AltroProvvedimentoAGOptionList: {
  id: string;
  text: string;
}[] = Object.entries(AltroProvvedimentoAGLabelMap).map(value => ({
  id: value[0],
  text: value[1],
}));

const SegnalatoDaLabelMap: Record<FlagSegnalatoDaValueDTO, string> = {
  [FlagSegnalatoDaValueDTO.TribunaleMinori]: 'Tribunale minori',
  [FlagSegnalatoDaValueDTO.ProcuraDeiMinori]: 'Procura dei minori',
  [FlagSegnalatoDaValueDTO.TribunaleOrdinario]: 'Tribunale ordinario',
  [FlagSegnalatoDaValueDTO.ProcuraOrdinaria]: 'Procura ordinaria',
  [FlagSegnalatoDaValueDTO.UfficioMinori]: 'Ufficio servizio sociale minori',
  [FlagSegnalatoDaValueDTO.ForzeDellOrdine]: "Forze dell'Ordine",
  [FlagSegnalatoDaValueDTO.ScuolaParitaria]: 'Scuola paritaria',
  [FlagSegnalatoDaValueDTO.ScuolaPubblica]: 'Scuola pubblica',
  [FlagSegnalatoDaValueDTO.Pediatra]: 'Pediatra',
  [FlagSegnalatoDaValueDTO.AccessoSpontaneo]: 'Accesso spontaneo',
  [FlagSegnalatoDaValueDTO.Familiari]: 'Familiari',
  [FlagSegnalatoDaValueDTO.ViciniDiCasa]: 'Vicini di casa',
  [FlagSegnalatoDaValueDTO.AltroServizioSociale]: 'Altro servizio sociale',
  [FlagSegnalatoDaValueDTO.ServizioAsl]: 'Servizio ASL',
} as const;

export const SegnalatoDaOptionList: {
  id: string;
  text: string;
}[] = Object.entries(SegnalatoDaLabelMap).map(value => ({
  id: value[0],
  text: value[1],
}));

const TipoAccessoLabelMap: Record<FlagTipoAccessoValueDTO, string> = {
  [FlagTipoAccessoValueDTO.ProcuraOrdinaria]: 'Procura ordinaria',
  [FlagTipoAccessoValueDTO.AccessoSpontaneo]: 'Accesso spontaneo',
  [FlagTipoAccessoValueDTO.Familiari]: 'Familiari entro il 4o grado',
  [FlagTipoAccessoValueDTO.ViciniDiCasa]: 'Vicini di casa',
  [FlagTipoAccessoValueDTO.MedicoDiBase]: 'Medico di base',
  [FlagTipoAccessoValueDTO.UnitaValutazioneGeriatrica]:
    'Unità di valutazione geriatrica',
  [FlagTipoAccessoValueDTO.ProgettoMeglioACasa]: 'Progetto meglio a casa',
  [FlagTipoAccessoValueDTO.ServizioSanitario]: 'Servizio sanitario',
  [FlagTipoAccessoValueDTO.RepartoOspedaliero]: 'Reparto ospedaliero',
  [FlagTipoAccessoValueDTO.Sert]: 'SER.T',
  [FlagTipoAccessoValueDTO.NucleoAlcologia]: 'Nucleo alcologia',
  [FlagTipoAccessoValueDTO.SaluteMentale]: 'Salute mentale',
  [FlagTipoAccessoValueDTO.ForzeDellOrdine]: "Forze dell'Ordine",
  [FlagTipoAccessoValueDTO.EsecuzionePenaleEsterna]:
    'Ufficio esecuzione penale esterna',
  [FlagTipoAccessoValueDTO.GiudiceTutelare]: 'Giudice tutelare',
  [FlagTipoAccessoValueDTO.Volontariato]: 'Volontariato',
} as const;

export const TipoAccessoOptionList: {
  id: string;
  text: string;
}[] = Object.entries(TipoAccessoLabelMap).map(value => ({
  id: value[0],
  text: value[1],
}));

const SeguitoConLabelMap: Record<FlagSeguitoConValueDTO, string> = {
  [FlagSeguitoConValueDTO.NeuropsichiatriaInfantile]:
    'Neuropsichiatria infantile',
  [FlagSeguitoConValueDTO.ConsultorioFamiliare]: 'Consultorio familiare',
  [FlagSeguitoConValueDTO.Sert]: 'SER.T',
  [FlagSeguitoConValueDTO.NucleoAlcologia]: 'Nucleo alcologia',
  [FlagSeguitoConValueDTO.SaluteMentale]: 'Salute mentale',
  [FlagSeguitoConValueDTO.UfficioMinori]: 'Ufficio servizi sociali minori',
  [FlagSeguitoConValueDTO.ConsultorioDiocesiano]: 'Consultorio diocesiano',
  [FlagSeguitoConValueDTO.ProfessionistiPrivati]: 'Professionisti privati',
  [FlagSeguitoConValueDTO.EquipeAffido]: 'Equipe affido',
  [FlagSeguitoConValueDTO.EquipeAdozioni]: 'Equipe adozioni',
  [FlagSeguitoConValueDTO.CentroEducazioneMotoria]: 'Centro educazione motoria',
  [FlagSeguitoConValueDTO.CentroAslFisiatra]: 'Centro ASL fisiatra',
} as const;

export const SeguitoConOptionList: {
  id: string;
  text: string;
}[] = Object.entries(SeguitoConLabelMap).map(value => ({
  id: value[0],
  text: value[1],
}));

const ProblematicheLabelMap: Record<FlagProblematicheValueDTO, string> = {
  [FlagProblematicheValueDTO.Abitative]: 'Problematiche Abitative',
  [FlagProblematicheValueDTO.Disoccupazione]: 'Assenza di lavoro stabile',
  [FlagProblematicheValueDTO.Sanitarie]: 'Problematiche sanitarie',
  [FlagProblematicheValueDTO.Economiche]: 'Problematiche economiche',
  [FlagProblematicheValueDTO.NessunaReteFamiliare]:
    'Assenza di una rete familiare',
} as const;

export const ProblematicheOptionList: {
  id: string;
  text: string;
}[] = Object.entries(ProblematicheLabelMap).map(value => ({
  id: value[0],
  text: value[1],
}));

export enum FLAG_TYPE {
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM',
  ENUM_MULTIPLE = 'ENUM_MULTIPLE',
}

export interface TypePagination {
  totalPages: number;
  currentPage: number;
  totalItemsCount: number;
  pageItemsCount: number;
}

interface BaseFlag {
  id: FlagTypeDTO;
}

export interface BooleanFlag extends BaseFlag {
  type: FLAG_TYPE.BOOLEAN;
}

export interface EnumFlag extends BaseFlag {
  type: FLAG_TYPE.ENUM | FLAG_TYPE.ENUM_MULTIPLE;
  options: { id: string; text: string }[];
}

type Flag = BooleanFlag | EnumFlag;

export const FlagTypes: Record<FlagTypeDTO, Flag> = {
  [FlagTypeDTO.ConflittoIntrafamiliare]: {
    id: FlagTypeDTO.ConflittoIntrafamiliare,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.FragilitaSocioEducativa]: {
    id: FlagTypeDTO.FragilitaSocioEducativa,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.NessunaReteFamiliare]: {
    id: FlagTypeDTO.NessunaReteFamiliare,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.VulnerabilitaGenitoriale]: {
    id: FlagTypeDTO.VulnerabilitaGenitoriale,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.DipenzaDaDroghe]: {
    id: FlagTypeDTO.DipenzaDaDroghe,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.DipendenzaDaAlcool]: {
    id: FlagTypeDTO.DipendenzaDaAlcool,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.PatologieMentali]: {
    id: FlagTypeDTO.PatologieMentali,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.PatologieFisiche]: {
    id: FlagTypeDTO.PatologieFisiche,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.AbusiOMaltrattamenti]: {
    id: FlagTypeDTO.AbusiOMaltrattamenti,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.DifficoltaFinaziarie]: {
    id: FlagTypeDTO.DifficoltaFinaziarie,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.ProblematichePenali]: {
    id: FlagTypeDTO.ProblematichePenali,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.Separazione]: {
    id: FlagTypeDTO.Separazione,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.Monogenitoriale]: {
    id: FlagTypeDTO.Monogenitoriale,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.Adozione]: {
    id: FlagTypeDTO.Adozione,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.Affido]: {
    id: FlagTypeDTO.Affido,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.AffidoComunale]: {
    id: FlagTypeDTO.AffidoComunale,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.AltroProvvedimentoAg]: {
    id: FlagTypeDTO.AltroProvvedimentoAg,
    type: FLAG_TYPE.ENUM,
    options: AltroProvvedimentoAGOptionList,
  },
  [FlagTypeDTO.SegnalatoDa]: {
    id: FlagTypeDTO.SegnalatoDa,
    type: FLAG_TYPE.ENUM,
    options: SegnalatoDaOptionList,
  },
  [FlagTypeDTO.SeguitoCon]: {
    id: FlagTypeDTO.SeguitoCon,
    type: FLAG_TYPE.ENUM_MULTIPLE,
    options: SeguitoConOptionList,
  },
  [FlagTypeDTO.AffidiEducativi]: {
    id: FlagTypeDTO.AffidiEducativi,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.PresenzaReteDiSupporto]: {
    id: FlagTypeDTO.PresenzaReteDiSupporto,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.TipologiaDiAccesso]: {
    id: FlagTypeDTO.TipologiaDiAccesso,
    type: FLAG_TYPE.ENUM,
    options: TipoAccessoOptionList,
  },
  [FlagTypeDTO.ViveSolo]: {
    id: FlagTypeDTO.ViveSolo,
    type: FLAG_TYPE.BOOLEAN,
  },
  [FlagTypeDTO.Problematiche]: {
    id: FlagTypeDTO.Problematiche,
    type: FLAG_TYPE.ENUM_MULTIPLE,
    options: ProblematicheOptionList,
  },
  [FlagTypeDTO.ContributoSolidarietaPsichici]: {
    id: FlagTypeDTO.ContributoSolidarietaPsichici,
    type: FLAG_TYPE.BOOLEAN,
  },
} as const;

const defaultMinoriFlagList = [
  FlagTypeDTO.ConflittoIntrafamiliare,
  FlagTypeDTO.FragilitaSocioEducativa,
  FlagTypeDTO.NessunaReteFamiliare,
  FlagTypeDTO.VulnerabilitaGenitoriale,
  FlagTypeDTO.DipenzaDaDroghe,
  FlagTypeDTO.DipendenzaDaAlcool,
  FlagTypeDTO.PatologieMentali,
  FlagTypeDTO.PatologieFisiche,
  FlagTypeDTO.AbusiOMaltrattamenti,
  FlagTypeDTO.DifficoltaFinaziarie,
  FlagTypeDTO.ProblematichePenali,
  FlagTypeDTO.Separazione,
  FlagTypeDTO.Monogenitoriale,
  FlagTypeDTO.Adozione,
  FlagTypeDTO.Affido,
  FlagTypeDTO.AffidoComunale,
  FlagTypeDTO.AltroProvvedimentoAg,
  FlagTypeDTO.SegnalatoDa,
  FlagTypeDTO.SeguitoCon,
] as const;

const defaultDisabiliFlagList = [
  FlagTypeDTO.SeguitoCon,
  FlagTypeDTO.AffidiEducativi,
  FlagTypeDTO.PresenzaReteDiSupporto,
] as const;

const defaultAnzianiFlagList = [
  FlagTypeDTO.TipologiaDiAccesso,
  FlagTypeDTO.ViveSolo,
  FlagTypeDTO.PresenzaReteDiSupporto,
] as const;

const defaultPovertaFlagList = [
  FlagTypeDTO.TipologiaDiAccesso,
  FlagTypeDTO.Problematiche,
  FlagTypeDTO.ContributoSolidarietaPsichici,
] as const;

export const allFlagsList: FlagTypeDTO[] = Object.values(FlagTypeDTO);

export const SERVICE_FLAGS_MAP: Record<
  WelfareInstanceTypeDTO,
  readonly FlagTypeDTO[]
> = {
  [WelfareInstanceTypeDTO.AssistenzaDomiciliareDisabili]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.PastiCaldiDisabili]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.ServizioEducativoDomiciliare]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.FondoNonAutosufficenza]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaDisabili]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.ContributoVitaIndipendente]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.ContributoSolidarietaInserimentoResidenziale]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.ServizioTrasportoDisabili]: defaultDisabiliFlagList,
  [WelfareInstanceTypeDTO.ContributoDopoDiNoi]: defaultDisabiliFlagList,

  [WelfareInstanceTypeDTO.AssistenzaDomiciliareAnziani]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.PastiDomicilioAnziani]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.AccessoResidenzeProtette]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.Asl4]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.IntegrazioneRettaResidenzeProtette]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.AccessoPostiCastagnola]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.NonAutosufficenzaAssegnoCura]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaAnziani]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.TelesoccorsoTelecontrollo]: defaultAnzianiFlagList,
  [WelfareInstanceTypeDTO.AgevolazioniTrasportoPubblico]: defaultAnzianiFlagList,

  [WelfareInstanceTypeDTO.AffidoEducativo]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.IncontriProtetti]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.AffidoFamiliare]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.InserimentoMinoriComunita]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.ComunitaMadreBambino]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.EquipeAdozioni]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.EquipeAffido]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.AssegniMaternita]: [],
  [WelfareInstanceTypeDTO.AssegniNucleoFamiliare]: [],
  [WelfareInstanceTypeDTO.Ludoteca]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.ReteContrastoViolenza]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.EquipeAbuso]: defaultMinoriFlagList,
  [WelfareInstanceTypeDTO.ProntaAccoglienzaMinori]: defaultMinoriFlagList,

  [WelfareInstanceTypeDTO.RedditoCittadinanza]: defaultPovertaFlagList,
  [WelfareInstanceTypeDTO.ContributoContinuativoUnaTantum]: defaultPovertaFlagList,
};

export const iseeType: Record<WelfareInstanceTypeDTO, string> = {
  [WelfareInstanceTypeDTO.Asl4]: 'socio sanitario',
  [WelfareInstanceTypeDTO.AccessoPostiCastagnola]: 'socio sanitario',
  [WelfareInstanceTypeDTO.AccessoResidenzeProtette]: 'socio sanitario',
  [WelfareInstanceTypeDTO.AffidoEducativo]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.AffidoFamiliare]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.AssegniMaternita]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.AssegniNucleoFamiliare]:
    'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.AssistenzaDomiciliareAnziani]: 'ordinario',
  [WelfareInstanceTypeDTO.AssistenzaDomiciliareDisabili]: 'ordinario',
  [WelfareInstanceTypeDTO.ComunitaMadreBambino]:
    'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.ContributoContinuativoUnaTantum]: 'ordinario',
  [WelfareInstanceTypeDTO.ContributoSolidarietaInserimentoResidenziale]:
    'ordinario',
  [WelfareInstanceTypeDTO.ContributoVitaIndipendente]: 'socio sanitario',
  [WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaAnziani]:
    'socio sanitario',
  [WelfareInstanceTypeDTO.CureDomiciliariDisabilitaGravissimaDisabili]:
    'socio sanitario',
  [WelfareInstanceTypeDTO.EquipeAbuso]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.EquipeAdozioni]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.EquipeAffido]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.FondoNonAutosufficenza]: 'socio sanitario',
  [WelfareInstanceTypeDTO.IncontriProtetti]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.InserimentoMinoriComunita]:
    'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.IntegrazioneRettaResidenzeProtette]:
    'socio sanitario',
  [WelfareInstanceTypeDTO.Ludoteca]: 'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.PastiCaldiDisabili]: 'ordinario',
  [WelfareInstanceTypeDTO.PastiDomicilioAnziani]: 'ordinario',
  [WelfareInstanceTypeDTO.NonAutosufficenzaAssegnoCura]: 'socio sanitario',
  [WelfareInstanceTypeDTO.ProntaAccoglienzaMinori]:
    'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.RedditoCittadinanza]: 'ordinario',
  [WelfareInstanceTypeDTO.ReteContrastoViolenza]:
    'per minori o famiglia con minori',
  [WelfareInstanceTypeDTO.ServizioEducativoDomiciliare]: 'socio sanitario',
  [WelfareInstanceTypeDTO.ServizioTrasportoDisabili]: 'ordinario',
  [WelfareInstanceTypeDTO.ContributoDopoDiNoi]: 'ordinario',
  [WelfareInstanceTypeDTO.TelesoccorsoTelecontrollo]: 'ordinario',
  [WelfareInstanceTypeDTO.AgevolazioniTrasportoPubblico]: 'ordinario',
};
export const benefitLabelMap: Record<BenefitTypeDTO, string> = {
  [BenefitTypeDTO.ContributiPerIlSostegnoLocazione]:
    'Contributi per il sostegno locazione',
  [BenefitTypeDTO.AssegnoDiMaternita]: 'Assegno di maternità',
  [BenefitTypeDTO.PensioneSociale]: 'Pensione sociale',
  [BenefitTypeDTO.PensioneDiInvaliditaCivile]: 'Pensione di invalidità civile',
  [BenefitTypeDTO.IndennitaDiAccompagnamento]: 'Indennità di accompagnamento',
  [BenefitTypeDTO.IndennitaDiDisoccupazione]: 'Indennità di disoccupazione',
  [BenefitTypeDTO.ContributiEconomiciComunali]: 'Contributi economici comunali',
  [BenefitTypeDTO.ContributiEconomiciRegionali]:
    'Contributi economici regionali',
  [BenefitTypeDTO.RenditeInail]: 'Rendite INAIL',
  [BenefitTypeDTO.AltreRenditeOIndennita]: 'Altre rendite o indennità',
};

export const expensesLabelMap: Record<ExpenseTypeDTO, string> = {
  [ExpenseTypeDTO.AmministrazioneCondominiale]: 'Amministrazione condominiale',
  [ExpenseTypeDTO.Riscaldamento]: 'Riscaldamento',
  [ExpenseTypeDTO.SpeseAssistenziali]: 'Spese assistenziali',
  [ExpenseTypeDTO.SpeseSanitarieNonCoperteDaSsn]:
    'Spese sanitarie non coperte dal SSN',
};

export const STATUS_LABEL_MAP: Record<WelfareInstanceStatusDTO, string> = {
  [WelfareInstanceStatusDTO.Created]: 'Creata',
  [WelfareInstanceStatusDTO.Assigned]: 'Assegnata',
  [WelfareInstanceStatusDTO.ApprovedByEmployee]:
    "Approvata dall'assistente sociale",
  [WelfareInstanceStatusDTO.ApprovedByPo]: 'Approvata dal referente',
  [WelfareInstanceStatusDTO.ApprovedByAdministrative]:
    'Approvata dalla posizione amministrativa',
  [WelfareInstanceStatusDTO.Approved]: 'Approvata',
  [WelfareInstanceStatusDTO.RejectedByEmployee]:
    "Rifiutata dall'assistente sociale",
  [WelfareInstanceStatusDTO.Rejected]: 'Rifiutata',
};
export const requestorTypeLabelMap: {
  [key in WelfareRequestorTypeDTO]: string;
} = {
  [WelfareRequestorTypeDTO.Beneficiario]: 'Beneficiario del servizio',
  [WelfareRequestorTypeDTO.Tutore]: 'Tutore',
  [WelfareRequestorTypeDTO.Procuratore]: 'Procuratore',
  [WelfareRequestorTypeDTO.Amministratore]: 'Amministratore di sostegno',
};
