import CallManager from './AxiosCallManager';

export default {
  loadList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      'gate/procedure/',
      response => onSuccess(response.data),
      onError,
      { page: page, ...filters }
    );
  },
  get: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `gate/procedure/${procedureId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  getDocuments: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `gate/procedure/${procedureId}/document`,
      response => onSuccess(response.data),
      onError
    );
  },
  getEvents: (procedureId, { page }, onSuccess, onError) => {
    CallManager.get(
      `gate/procedure/${procedureId}/events`,
      response => onSuccess(response.data),
      onError,
      { page: page }
    );
  },
  downloadDocument: (procedureId, documentId, onFinished, onError) => {
    CallManager.downloadFile(
      `procedure/${procedureId}/document/${documentId}`,
      onFinished,
      onError
    );
  },
  uploadDocument: (procedureId, contents, onSuccess, onError) => {
    const data = new FormData();
    data.append('file', contents);
    data.append('name', contents.name);
    CallManager.post(
      `gate/procedure/${procedureId}/document`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  sharedWithMeList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      'shared_procedure',
      response => onSuccess(response.data),
      onError,
      {
        page: page,
        list_type: 'shared_with_me',
        ...filters,
      }
    );
  },
  sharedByMeList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      'shared_procedure',
      response => onSuccess(response.data),
      onError,
      {
        page: page,
        list_type: 'shared_by_me',
        ...filters,
      }
    );
  },
  getShared: (shareId, onSuccess, onError) => {
    CallManager.get(
      `shared_procedure/${shareId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  getSharedTo: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `procedure/${procedureId}/shared_to`,
      response => onSuccess(response.data),
      onError
    );
  },
  shareProcedure: (
    { procedureId, procedureType, shareTo, documents, notes },
    onSuccess,
    onError
  ) => {
    CallManager.post(
      'shared_procedure/',
      response => onSuccess(response.data),
      onError,
      {
        procedure_id: procedureId,
        procedure_type: procedureType,
        share_to: shareTo,
        documents: documents,
        notes,
      }
    );
  },
  getSharedDocuments: (shareId, onSuccess, onError) => {
    CallManager.get(
      `shared_procedure/${shareId}/documents`,
      response => onSuccess(response.data),
      onError
    );
  },
  updateNotes: (shareId, notes, onSuccess, onError) => {
    CallManager.post(
      `shared_procedure/${shareId}/notes`,
      response => onSuccess(response.data),
      onError,
      {
        notes,
      }
    );
  },
  downloadSharedDocument: (shareId, documentId, onFinished, onError) => {
    CallManager.downloadFile(
      `shared_procedure/${shareId}/documents/${documentId}`,
      onFinished,
      onError
    );
  },
  uploadSharedDocument: (shareId, contents, onSuccess, onError) => {
    const data = new FormData();
    data.append('file', contents);
    data.append('name', contents.name);
    CallManager.post(
      `shared_procedure/${shareId}/documents`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  deleteSharedDocument: (shareId, documentId, onSuccess, onError) => {
    CallManager.delete(
      `shared_procedure/${shareId}/documents/${documentId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  resolveSharedProcedure: (shareId, onSuccess, onError) => {
    CallManager.patch(
      `shared_procedure/${shareId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  closeSharedProcedure: (shareId, onSuccess, onError) => {
    CallManager.delete(
      `shared_procedure/${shareId}`,
      response => onSuccess(response.data),
      onError
    );
  },
};
