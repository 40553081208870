import NewApiCallManager from './NewApiCallManager';

export default {
  upload: (contents, onSuccess, onError) => {
    const data = new FormData();
    data.append('file', contents);
    NewApiCallManager.post(
      //TODO
      `/temporary-document`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  delete: (id, onSuccess, onError) => {
    NewApiCallManager.delete(
      `/temporary-document?fids=${id}`,
      onSuccess,
      onError
    );
  },
};
