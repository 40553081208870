import NewApiCallManager from './NewApiCallManager';
import { AxiosError, AxiosResponse } from 'axios';
import {
  AddDocumentsRequest,
  AddNoteRequest,
  ApproveWelfareInstanceOperationRequest,
  AssignWelfareInstanceOperationRequest,
  RejectWelfareInstanceOperationRequest,
  SignWelfareInstanceOperationRequest,
  TempFileConfirmationRequest,
  WelfareInstanceCreationRequest,
  WelfareInstanceDTO,
  WelfareInstanceEditRequest,
  WelfareInstanceListRequest,
  WelfareInstanceListResponse,
  WelfareInstanceEventDTO,
  WelfareInstanceEventListResponse,
} from '../gen/api/welfare';

export default {
  getDocument: (
    id: string,
    name: string,
    onSuccess: (response: AxiosResponse<void>) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.downloadFile(
      `/welfare/documents/${id}/file`,
      onSuccess,
      onError,
      name
    );
  },
  createInstance: (
    data: WelfareInstanceCreationRequest,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    //TODO: know it
    NewApiCallManager.post(`welfare/instances`, onSuccess, onError, data);
  },
  rejectInstance: (
    data: RejectWelfareInstanceOperationRequest,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.patch(
      `welfare/instances/${data.id}/reject`,
      onSuccess,
      onError,
      data.rejectWelfareInstanceRequest,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
  },
  approveInstance: (
    data: ApproveWelfareInstanceOperationRequest,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.patch(
      `welfare/instances/${data.id}/approve`,
      onSuccess,
      onError,
      data.approveWelfareInstanceRequest ?? {}
    );
  },
  assignInstance: (
    data: AssignWelfareInstanceOperationRequest,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.patch(
      `welfare/instances/${data.id}/assign`,
      onSuccess,
      onError,
      data.assignWelfareInstanceRequest
    );
  },
  signInstance: (
    data: SignWelfareInstanceOperationRequest,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.patch(
      `welfare/instances/${data.id}/sign`,
      onSuccess,
      onError,
      data.signWelfareInstanceRequest
    );
  },
  list: (
    data: WelfareInstanceListRequest,
    onSuccess: (response: WelfareInstanceListResponse) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.get(
      `/welfare/instances`,
      (response: AxiosResponse<WelfareInstanceListResponse>) =>
        onSuccess(response.data),
      onError,
      data
    );
  },
  getProcedure: (
    id: string,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.get(`welfare/instances/${id}`, onSuccess, onError);
  },

  editProcedure: (
    id: string,
    procedure: WelfareInstanceEditRequest,
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (response: AxiosError) => void
  ) => {
    NewApiCallManager.patch(
      `/welfare/instances/${id}`,
      onSuccess,
      onError,
      procedure
    );
  },

  addNote: (
    procedureId: string,
    note: string,
    documents: TempFileConfirmationRequest[],
    onSuccess: (response: AxiosResponse<WelfareInstanceEventDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    const request: AddNoteRequest = {
      documents,
      message: note,
    };

    NewApiCallManager.post(
      `/welfare/instances/${procedureId}/notes`,
      onSuccess,
      onError,
      request
    );
  },

  addDocument: (
    id: string,
    documents: TempFileConfirmationRequest[],
    onSuccess: (response: AxiosResponse<WelfareInstanceDTO>) => void,
    onError: (error: AxiosError) => void
  ) => {
    const request: AddDocumentsRequest = {
      documents,
    };

    NewApiCallManager.post(
      `/welfare/instances/${id}/documents`,
      onSuccess,
      onError,
      request
    );
  },

  getEvents: (
    procedureId: string,
    onSuccess: (
      response: AxiosResponse<WelfareInstanceEventListResponse>
    ) => void,
    onError: (error: AxiosError) => void
  ) => {
    NewApiCallManager.get(
      `/welfare/instances/${procedureId}/events`,
      onSuccess,
      onError
    );
  },
};
