import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const ShareButton = props => {
  const {
    onClick,
    variant = 'raised',
    color = 'primary',
    size,
    className,
  } = props;

  return (
    <Button
      className={className}
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
    >
      Richiedi Parere
    </Button>
  );
};

ShareButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ShareButton;
