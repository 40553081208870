import React, { FC } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { FlagDisplay } from '../../../../common/FlagDisplay';
import {
  FlagTypeDTO,
  WelfareInstanceTypeDTO,
} from '../../../../../../gen/api/welfare';
import { SERVICE_FLAGS_MAP } from '../../../../constants';

const styles = (theme: any) => ({
  emptyFlagContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '38px',
    margin: '24px 0',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: theme.palette.grey.dark,
  },
  fieldContainer: {
    margin: '24px 0px',
    display: 'inline-block',
    width: '100%',
    '& > div': {
      width: '30%',
      marginBottom: '24px',
    },
    '& > div:not(:nth-child(3))': {
      marginRight: '24px',
    },
  },
});

interface FlagSectionProps extends StyledComponentProps {
  flags: {
    type: FlagTypeDTO;
    data: string[];
  }[];
  serviceFilter?: WelfareInstanceTypeDTO;
}

const FlagSection: FC<FlagSectionProps> = ({
  flags,
  serviceFilter,
  classes = {},
}) => {
  const filteredFlags = !serviceFilter
    ? flags
    : flags.filter(({ type }) =>
        SERVICE_FLAGS_MAP[serviceFilter].includes(type)
      );

  if (!filteredFlags || filteredFlags.length < 1) {
    return (
      <div className={classes.emptyFlagContainer}>
        <label className={classes.label}>
          Nessuna informazione inserita sulla situazione personale
        </label>
      </div>
    );
  }
  return (
    <div className={classes.fieldContainer}>
      {filteredFlags.map(flag => {
        return <FlagDisplay key={`flagvalue-${flag.type}`} flag={flag} />;
      })}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedFlagSection = withStyles(styles)(FlagSection);

export { DecoratedFlagSection as FlagSection };
