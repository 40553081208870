import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import ConfirmButton from '../common/ConfirmButton';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import FormControlValidated from '../common/FormControlValidated';
import Loader from '../common/Loader';
import ApiUtenti from '../../api/Utenti';
import store from '../../store';
import { Col, Row } from 'react-flexbox-grid';
import { Input } from '@material-ui/core';
import { Button } from '@material-ui/core';
import ROUTES from '../../constants/routes';
import validators from '../../utils/validators';
import {
  DEPARTMENTS,
  isHigherThan,
  ROLES,
  VISIBLE_DEPARTMENTS,
  VISIBLE_ROLES,
} from '../../constants/security';
import { Hidden, MenuItem, Select } from '@material-ui/core';
import { getDepartmentLabel, getRoleLabel } from '../../constants/common';
import { Add, Close } from '@material-ui/icons';
import { HorizontalLabelValue } from '../common/HorizontalLabelValue';
import PrettySection from '../common/PrettySection';
import { DangerButton } from '../common/DangerButton';

const styles = theme => ({
  actions: {
    marginTop: '20px',
  },
  tableContainer: {
    marginTop: '20px',
  },
  card: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  removeBtn: {
    color: theme.palette.font.red,
  },
  departmentLabel: {
    marginBottom: 0,
    justifyContent: 'center',
  },
});

class NewUtente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      utente: null,
      newUser: {
        first_name: '',
        last_name: '',
        email: '',
      },
      departments: [],
      savingUser: false,
      loadingDepartments: false,
      errors: {},
      roles: [{ role: null, department: null }],
    };
  }

  componentDidMount() {
    const idUtente = this.props.match.params.idUtente;
    this.setState({ loadingUtente: true }, () => {
      if (idUtente) {
        ApiUtenti.get(
          idUtente,
          user => {
            this.setState({
              loadingUtente: false,
              utente: user,
              newUser: {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
              },
              roles:
                user.roles && user.roles.length
                  ? user.roles
                  : [{ role: null, department: null }],
            });
          },
          () => {
            this.setState({ loadingUtente: false });
          }
        );
      } else {
        this.setState({
          loadingUtente: false,
          utente: {},
          roles: [{ role: null, department: null }],
        });
      }
    });
  }

  addRole = () => {
    this.setState({
      roles: [...this.state.roles, { role: null, department: null }],
    });
  };

  removeRole = index => {
    const roles = this.state.roles;
    roles.splice(index, 1);
    this.setState({
      roles,
    });
  };

  cleanErrorOnField = fieldName => {
    const errors = this.state.errors;
    if (errors[fieldName]) {
      delete errors[fieldName];
    }
    this.setState({ errors: errors });
  };

  handleChangeName = event => {
    this.cleanErrorOnField('first_name');
    const utente = this.state.newUser;
    utente.first_name = event.target.value;
    this.setState({ newUser: utente });
  };

  handleChangeSurname = event => {
    this.cleanErrorOnField('last_name');
    const utente = this.state.newUser;
    utente.last_name = event.target.value;
    this.setState({ newUser: utente });
  };

  handleChangeEmail = event => {
    this.cleanErrorOnField('email');
    const utente = this.state.newUser;
    utente.email = event.target.value;
    this.setState({ newUser: utente });
  };

  validate = () => {
    const errors = {};

    const utenteEsistente = this.state.utente && this.state.utente.id;

    if (validators.isEmptyString(this.state.newUser.email)) {
      errors.email = 'Campo obbligatorio';
    } else if (!validators.isValidEmail(this.state.newUser.email)) {
      errors.email = 'Inserire un indirizzo email valido';
    }

    if (
      utenteEsistente &&
      validators.isEmptyString(this.state.newUser.first_name)
    ) {
      errors.first_name = 'Campo obbligatorio';
    }

    if (
      utenteEsistente &&
      validators.isEmptyString(this.state.newUser.last_name)
    ) {
      errors.last_name = 'Campo obbligatorio';
    }

    if (
      !store.app.profile.isMemberOf(DEPARTMENTS.ADMIN, true) &&
      !this.state.roles[0].role
    ) {
      errors.role = 'Campo obbligatorio';
    }

    this.setState({ errors: errors });

    if (
      store.app.profile.isMemberOf(DEPARTMENTS.ADMIN, true) &&
      this.state.roles.filter(r => r.role && r.department).length === 0
    ) {
      store.app.snackBar.open(
        "E' necessario specificare almeno un dipartimento e un ruolo!"
      );
      return false;
    }

    return Object.keys(errors).length === 0;
  };

  save = () => {
    const utente = this.state.newUser;
    if (this.validate()) {
      const roleData = store.app.profile.isMemberOf(
        [DEPARTMENTS.CED, DEPARTMENTS.ADMIN],
        true
      )
        ? {
            roles: this.state.roles.filter(r => r.role && r.department),
          }
        : {
            role: this.state.roles[0].role,
            department: store.app.profile.operatingAs.department,
          };
      this.setState({ savingUser: true }, () => {
        ApiUtenti.update(
          this.state.utente.id,
          {
            first_name: utente.first_name,
            last_name: utente.last_name,
            email: utente.email,
            ...(store.app.profile.user.id !== utente.id ? roleData : {}),
          },
          response => {
            this.setState({ savingUser: false, utente: response.data });
            store.app.snackBar.open('Modifiche effettuate.');
            store.app.checkAuth();
          },
          error => {
            const state = { savingUser: false };
            if (error.data.error_code === 300005) {
              // Department already has a manager
              store.app.snackBar.open(
                `Errore: esiste già un dirigente per il dipartimento '${getDepartmentLabel(
                  error.data.details.department
                )}'`
              );
            } else {
              store.app.snackBar.open('Errore nel salvataggio.');
              if (error.status === 422) {
                state.errors = error.data.details;
              }
            }
            this.setState(state);
          }
        );
      });
    }
  };

  create = () => {
    const utente = this.state.newUser;
    if (this.validate()) {
      const roleData = store.app.profile.isMemberOf(DEPARTMENTS.ADMIN, true)
        ? {
            roles: this.state.roles.filter(r => r.role && r.department),
          }
        : {
            role: this.state.roles[0].role,
            department: store.app.profile.operatingAs.department,
          };
      this.setState({ savingUser: true }, () => {
        ApiUtenti.create(
          {
            email: utente.email,
            ...roleData,
          },
          () => {
            this.setState({ savingUser: false });
            store.app.snackBar.open('Invito inviato.');
            this.props.history.push(`/${ROUTES.UTENTI}`);
          },
          error => {
            const state = { savingUser: false };
            if (error.data.error_code === 300005) {
              // Department already has a manager
              store.app.snackBar.open(
                `Errore: esiste già un dirigente per il dipartimento '${getDepartmentLabel(
                  error.data.details.department
                )}'`
              );
            } else {
              store.app.snackBar.open('Errore nella creazione.');
              if (error.status === 422) {
                state.errors = error.data.details;
              }
            }
            this.setState(state);
          }
        );
      });
    }
  };

  setDepartment = (index, department) => {
    const roles = this.state.roles;
    roles[index].department = department;
    this.setState({ roles });
  };

  setRole = (index, role) => {
    const roles = this.state.roles;
    roles[index].role = role;
    this.setState({ roles });
  };

  render() {
    const { classes } = this.props;
    const { utente, newUser, loadingUtente, savingUser } = this.state;
    const loading = loadingUtente || savingUser;
    const utenteEsistente = utente && utente.id;
    const { profile } = store.app;

    const availableDepartments = Object.values(VISIBLE_DEPARTMENTS).filter(
      d => !this.state.roles.some(r => r.department === d)
    );

    const availableRoles = Object.values(VISIBLE_ROLES).filter(r =>
      isHigherThan(profile.operatingAs.role, r)
    );

    return (
      <PageContainer
        header={
          utenteEsistente ? (
            <PageTitle
              title={!loadingUtente ? 'Modifica Utente' : 'Attendi...'}
              subtitle={`${utente.first_name} ${utente.last_name}`}
              actions={
                !loadingUtente && (
                  <UpdateActions save={this.save} utente={utente} />
                )
              }
              backIcon={true}
            />
          ) : (
            <PageTitle
              title={!loadingUtente ? 'Invita Utente' : 'Attendi...'}
              backIcon={true}
              actions={!loadingUtente && <CreateActions create={this.create} />}
            />
          )
        }
      >
        <Loader size={40} loading={loading} />
        {!loading && utente && (
          <div>
            <PrettySection title="Informazioni Account" icon="nc-circle-09">
              <Col xs={12} sm={6} md={6}>
                <FormControlValidated
                  inputId="email"
                  label="Email"
                  error={this.state.errors.email}
                  shrinkLabel={true}
                >
                  <Input
                    id="email"
                    inputProps={{ tabIndex: 3 }}
                    type="text"
                    label="Email"
                    onChange={this.handleChangeEmail}
                    defaultValue={newUser.email}
                    classes={{
                      inkbar: !this.state.errors.email
                        ? classes.inputInkbar
                        : classes.inputInkbarError,
                    }}
                  />
                </FormControlValidated>
              </Col>
              <Hidden mdDown>
                <Col xs={6} />
              </Hidden>
              <EditUserForm
                visible={utenteEsistente}
                errors={this.state.errors}
                user={newUser}
                onFirstNameChange={this.handleChangeName}
                onLastNameChange={this.handleChangeSurname}
              />
            </PrettySection>
            <RolesSection
              canEdit={!utenteEsistente || profile.user.id !== utente.id}
              profile={profile}
              errors={this.state.errors}
              roles={this.state.roles}
              availableDepartments={availableDepartments}
              availableRoles={availableRoles}
              setDepartment={this.setDepartment}
              setRole={this.setRole}
              addRole={this.addRole}
              removeRole={this.removeRole}
            />
          </div>
        )}
      </PageContainer>
    );
  }
}

export default withStyles(styles, {
  withTheme: true,
})(withRouter(observer(NewUtente)));

const CreateActions = withRouter(props => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12}>
        <ConfirmButton
          buttonProps={{
            variant: 'raised',
            color: 'primary',
          }}
          confirmMessage="Creazione Utente"
          text="Vuoi procedere con la creazione dell'utente?"
          buttonLabel="Crea Utente"
          onConfirm={props.create}
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => props.history.push(`/${ROUTES.UTENTI}`)}
          variant="raised"
          color="default"
        >
          Annulla
        </Button>
      </Col>
    </Row>
  );
});

const EditUserForm = withStyles(styles)(
  ({
    classes,
    visible = false,
    user,
    errors,
    onFirstNameChange,
    onLastNameChange,
  }) => {
    return visible ? (
      <>
        <Col xs={12} sm={6} md={6}>
          <FormControlValidated
            inputId="first_name"
            label="Nome"
            error={errors.first_name}
            shrinkLabel={true}
          >
            <Input
              id="name"
              inputProps={{ tabIndex: 1 }}
              type="text"
              label="Nome"
              onChange={onFirstNameChange}
              defaultValue={user.first_name}
              classes={{
                inkbar: !errors.first_name
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <FormControlValidated
            inputId="last_name"
            label="Cognome"
            error={errors.last_name}
            shrinkLabel={true}
          >
            <Input
              id="last_name"
              inputProps={{ tabIndex: 2 }}
              type="text"
              label="Cognome"
              onChange={onLastNameChange}
              defaultValue={user.last_name}
              classes={{
                inkbar: !errors.last_name
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
      </>
    ) : null;
  }
);

const RolesSection = withStyles(styles)(
  ({
    canEdit,
    profile,
    errors,
    roles,
    availableDepartments,
    availableRoles,
    setDepartment,
    setRole,
    addRole,
    removeRole,
  }) => {
    return canEdit ? (
      <PrettySection title="Ruoli e dipartimenti" icon="nc-badge">
        {profile.isMemberOf([DEPARTMENTS.ADMIN, DEPARTMENTS.CED], true) ? (
          <>
            {roles.map((r, i) => (
              <DepartmentRoleRow
                /* eslint-disable-next-line react/no-array-index-key */
                key={i}
                errors={errors}
                availableDepartments={availableDepartments}
                availableRoles={ROLES}
                selectedDepartment={r.department}
                selectedRole={r.role}
                onDepartmentChanged={event =>
                  setDepartment(i, event.target.value)
                }
                onRoleChanged={event => setRole(i, event.target.value)}
                onRemove={() => removeRole(i)}
              />
            ))}
            <Col
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <Button
                size="small"
                variant="raised"
                color="primary"
                onClick={addRole}
              >
                <Add /> Aggiungi Ruolo
              </Button>
            </Col>
          </>
        ) : (
          <RoleRow
            errors={errors}
            department={profile.operatingAs.department}
            availableRoles={availableRoles}
            selectedRole={roles[0].role}
            onRoleChanged={event => setRole(0, event.target.value)}
          />
        )}
      </PrettySection>
    ) : null;
  }
);

const UpdateActions = withRouter(props => {
  return (
    <span>
      <ConfirmButton
        buttonProps={{
          variant: 'raised',
          color: 'primary',
        }}
        confirmMessage="Modifica Utente"
        text="Vuoi procedere con la modifica dell'utente?"
        buttonLabel="Salva"
        onConfirm={props.save}
      />
      &nbsp;&nbsp;
      <Button
        onClick={() =>
          props.history.push(`/${ROUTES.UTENTI}/${props.utente.id}`)
        }
        variant="raised"
        color="default"
      >
        Annulla
      </Button>
    </span>
  );
});

const DepartmentRoleRow = withStyles(styles)(
  ({
    key,
    classes,
    errors,
    availableDepartments,
    availableRoles,
    selectedDepartment,
    selectedRole,
    disabled = false,
    onDepartmentChanged,
    onRoleChanged,
    onRemove,
  }) => {
    const { profile } = store.app;

    return (
      <>
        <Col xs={11} sm={6} md={6}>
          <FormControlValidated
            inputId="department"
            label="Dipartimento"
            shrinkLabel={true}
            error={errors.department}
          >
            <Select
              name={`department.${key}`}
              value={selectedDepartment}
              displayEmpty="Seleziona un dipartimento"
              renderValue={getDepartmentLabel}
              disabled={disabled}
              onChange={onDepartmentChanged}
              fullWidth={false}
            >
              {Object.values(availableDepartments).map(dept => (
                <MenuItem key={dept} value={dept}>
                  {getDepartmentLabel(dept)}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={10} sm={5} md={5}>
          <FormControlValidated
            inputId="role"
            label="Ruolo"
            shrinkLabel={true}
            error={errors.role}
          >
            <Select
              name={`role.${key}`}
              value={selectedRole}
              disabled={disabled}
              onChange={onRoleChanged}
              fullWidth={false}
            >
              {selectedDepartment &&
                Object.values(availableRoles)
                  .filter(r =>
                    selectedDepartment === DEPARTMENTS.CED
                      ? r === ROLES.EMPLOYEE
                      : profile.isMemberOf(
                          [DEPARTMENTS.CED, DEPARTMENTS.ADMIN],
                          true
                        ) ||
                        profile.isHigherThanForDepartment(
                          [{ role: r, department: selectedDepartment }],
                          true
                        )
                  )
                  .map(
                    role =>
                      role !== ROLES.ANY && (
                        <MenuItem value={role}>
                          {getRoleLabel(role, selectedDepartment)}
                        </MenuItem>
                      )
                  )}
            </Select>
          </FormControlValidated>
        </Col>
        <Col
          xs={1}
          sm={1}
          md={1}
          style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 5 }}
        >
          <DangerButton
            size="small"
            variant="flat"
            color="secondary"
            className={classes.removeBtn}
            onClick={onRemove}
          >
            <Close />
          </DangerButton>
        </Col>
      </>
    );
  }
);

const RoleRow = withStyles(styles)(
  ({
    classes,
    department,
    disabled = false,
    errors,
    selectedRole,
    availableRoles,
    onRoleChanged,
  }) => {
    return (
      <>
        <HorizontalLabelValue
          className={classes.departmentLabel}
          label="Dipartimento"
          value={getDepartmentLabel(department)}
          noPadding
          xs={12}
          md={4}
          sm={4}
        />
        <Col xs={12} sm={8} md={8}>
          <FormControlValidated
            inputId="role"
            label="Ruolo"
            shrinkLabel={true}
            error={errors.role}
          >
            <Select
              name="role"
              value={selectedRole}
              disabled={disabled}
              onChange={onRoleChanged}
              fullWidth={false}
            >
              {Object.values(availableRoles).map(
                role =>
                  role !== ROLES.ANY && (
                    <MenuItem value={role}>
                      {getRoleLabel(role, department)}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControlValidated>
        </Col>
      </>
    );
  }
);
