import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import ListBox from '../../common/ListBox';
import StatusBadge from '../common/StatusBadge';
import PropTypes from 'prop-types';
import { getCertificateTypeLabel } from '../../../constants/common';
import moment from 'moment';
import ListItemLayout from './ListItemLayout';
import cx from 'classnames';

const styles = theme => ({
  bodyStrong: {
    ...theme.typography.bodyStrong,
    fontSize: 'inherit',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
});

const ListItem = props => {
  const { classes, request, onClick } = props;

  const onItemClick = useCallback(() => {
    onClick(request);
  }, [request, onClick]);

  return (
    <ListBox onItemClick={onItemClick}>
      <ListItemLayout>
        <StatusBadge state={request.details.status} />
        <div className={cx(classes.bodyStrong, classes.centered)}>
          {request.details.user.firstName} {request.details.user.lastName}
        </div>
        <>{`${getCertificateTypeLabel(request.type)}`}</>
        <>
          {moment
            .utc(request.details.createdAt)
            .local()
            .format('DD/MM/YYYY H:mm')}
        </>
        <div className={cx(classes.bodyStrong, classes.centered)}>
          {request.details.handler
            ? `${request.details.handler.firstName} ${request.details.handler.lastName}`
            : ''}
        </div>
      </ListItemLayout>
    </ListBox>
  );
};

ListItem.propTypes = {
  request: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  onClick: () => {},
};

export default withStyles(styles)(ListItem);
