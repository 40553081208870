import React, { useCallback, useMemo, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Loader from '../common/Loader';
import EmptyListMessage from '../common/EmptyListMessage';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_HUMAN } from './filters/constants';
import store from '../../store';

const styles = theme => ({
  container: {
    fontFamily: theme.typography.fontFamily,
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  groupList: {
    width: '100%',
    listStyle: 'none',
    marginTop: '20px',
    marginBottom: '10px',
    padding: 0,
  },
  groupListItem: {
    border: '1px solid ' + theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    padding: '10px 20px',
    borderRadius: '1px',
    marginBottom: '5px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
    display: 'flex',
  },
  documentList: {
    width: '100%',
    maxHeight: '400px',
    listStyle: 'none',
    marginBottom: '10px',
    padding: 0,
  },
  documentListItem: {
    border: '1px solid ' + theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    padding: '10px 20px',
    borderRadius: '1px',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  removeDocument: {
    alignSelf: 'flex-end',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const ProcedureApprovalWidget = props => {
  const {
    procedureId,
    procedureType,
    onDone,
    disabled,
    buttonStyle = {},
  } = props;
  const [isOpen, setIsOpened] = useState(false);

  const openDialog = useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);

  const closeDialog = useCallback(() => {
    setIsOpened(false);
  }, [setIsOpened]);

  return (
    <>
      <Button
        disabled={disabled}
        color="primary"
        variant="raised"
        onClick={openDialog}
        style={buttonStyle}
      >
        Approva
      </Button>
      <ApprovalDialog
        open={isOpen}
        onComplete={onDone}
        onClose={closeDialog}
        procedureId={procedureId}
        procedureType={procedureType}
      />
    </>
  );
};

const STEP_SELECT_DOCUMENTS = 'SELECT_DOCUMENTS';
const STEP_APPROVAL_IN_PROGRESS = 'APPROVAL_IN_PROGRESS';
const STEPS = [STEP_SELECT_DOCUMENTS, STEP_APPROVAL_IN_PROGRESS];

const ApprovalDialog = withStyles(styles)(props => {
  const [step, setStep] = useState(0);
  const [documents, setDocuments] = useState([]);
  const { classes, open, onClose, onComplete, procedureId } = props;

  const currStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  const canApprove = useMemo(() => {
    return documents && documents.length > 0;
  }, [documents]);

  const onDocumentsSelected = useCallback(
    docs => {
      setDocuments(docs);
    },
    [setDocuments]
  );

  const onDialogClose = useCallback(() => {
    onClose();
    setStep(0);
    setDocuments([]);
  }, [setStep, setDocuments, onClose]);

  const onDone = useCallback(() => {
    onComplete(documents, onDialogClose);
  }, [documents, onComplete, onDialogClose]);

  const onNext = useCallback(() => {
    setStep(step => {
      if (step + 1 === STEPS.length - 1) onDone();
      return step + 1;
    });
  }, [setStep, onDone]);

  return (
    <Dialog
      className={classes.container}
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={onDialogClose}
      aria-labelledby="approval-dialog-title"
      aria-describedby="approval-dialog-description"
    >
      <DialogTitle id="share-dialog-title">
        {currStep === STEP_APPROVAL_IN_PROGRESS
          ? 'Approvazione in corso...'
          : 'Approva Pratica'}
      </DialogTitle>
      <DialogContent>
        {currStep === STEP_SELECT_DOCUMENTS && (
          <DocumentSelector
            procedureId={procedureId}
            onChange={onDocumentsSelected}
          />
        )}
        {currStep === STEP_APPROVAL_IN_PROGRESS && (
          <Loader loading={true} color="primary" />
        )}
      </DialogContent>
      <DialogActions>
        {currStep !== STEP_APPROVAL_IN_PROGRESS && (
          <>
            <Button onClick={onDialogClose}>Annulla</Button>
            <Button disabled={!canApprove} onClick={onNext}>
              Approva
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
});

const DocumentSelector = withStyles(styles)(props => {
  const { classes, onChange } = props;
  const [documents, setDocuments] = useState([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const fileInput = useRef(null);

  const onDocumentSelected = useCallback(
    event => {
      const files = event.target.files;
      const newDocs = documents;
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > MAX_FILE_SIZE) {
          store.app.snackBar.open(
            `Il file '${files[i].name}' selezionato è troppo grande (Max ${MAX_FILE_SIZE_HUMAN} MB)`
          );
          return;
        }
        newDocs.push(files[i]);
      }
      setDocuments(newDocs);
      onChange(newDocs);
      setInputKey(Date.now());
    },
    [onChange, documents, setDocuments]
  );

  const onUploadClicked = useCallback(() => {
    fileInput.current.click();
  }, [fileInput]);

  const removeDocument = useCallback(
    documentId => {
      const newDocs = documents;
      const index = newDocs.findIndex(function(doc) {
        return doc.name === documentId;
      });
      if (index !== -1) {
        newDocs.splice(index, 1);
        onChange(newDocs);
        setInputKey(Date.now());
        setDocuments(newDocs);
      }
    },
    [onChange, documents, setDocuments]
  );

  return (
    <div>
      <div>
        <input
          multiple
          onChange={onDocumentSelected}
          name="file"
          ref={fileInput}
          key={inputKey}
          type="file"
          hidden
        />
        <DialogContentText>
          Seleziona i documenti da inviare nella comunicazione di approvazione
        </DialogContentText>
        <ul className={classes.documentList}>
          {documents.map(doc => (
            <li className={classes.documentListItem} key={doc.name}>
              <span>{doc.name}</span>
              <span
                className={classes.removeDocument}
                onClick={() => removeDocument(doc.name)}
              >
                &times;
              </span>
            </li>
          ))}
        </ul>
        <EmptyListMessage
          visible={documents.length === 0}
          message="Nessun documento caricato"
        />
        <Button color="primary" onClick={onUploadClicked}>
          Aggiungi File
        </Button>
      </div>
    </div>
  );
});

export default withStyles(styles)(
  observer(withRouter(ProcedureApprovalWidget))
);
