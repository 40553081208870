import React, { FC } from 'react';
import { GenericFlagProps } from './model';
import { DetailField } from '../../detail/layout/Detail/common/DetailField';

const _BooleanFlag: FC<GenericFlagProps> = ({ label, data }) => {
  return <DetailField label={label} value={data ? 'Sì' : 'No'} />;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const BooleanFlag = _BooleanFlag;
