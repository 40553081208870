import React, { FC } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import { FamilyMember } from '../../../../api/model';
import { Col, Row } from 'react-flexbox-grid';
import moment from 'moment';

const styles = (theme: any) => ({
  container: {
    '& > div:not(:last-child)': {
      borderBottom: '1px solid #E6E9F2',
    },
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '38px',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: theme.palette.grey.dark,
  },
  familiarRow: {
    height: '64px',
    padding: '8px 0px',
    '& > div:not(:last-child) ': {
      borderRight: '1px solid #E6E9F2',
      paddingRight: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& > div:first-child': {
      justifyContent: 'flex-start',
    },
  },
});

interface FamiliarMemberProps extends StyledComponentProps {
  members: FamilyMember[];
}

const FamiliarMember: FC<FamiliarMemberProps> = ({ classes = {}, members }) => {
  if (members.length < 1) {
    return (
      <div className={classes.emptyContainer}>
        <label className={classes.label}>Nessuna persona indicata</label>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {members.map(m => (
        <Row key={m.id} className={classes.familiarRow}>
          <Col xs={2}>
            <b>{`${m.firstName} ${m.lastName}`}</b>
          </Col>
          <Col xs={2}>{m.fiscalCode}</Col>
          <Col xs={2}>{m.profession}</Col>
          <Col xs={2}>{moment(m.birthDate).format('DD/MM/YYYY')}</Col>
          <Col xs={2}>{m.relationship}</Col>
          <Col xs={2} />
        </Row>
      ))}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedFamiliarMember = withStyles(styles)(FamiliarMember);

export { DecoratedFamiliarMember as FamiliarMember };
