import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import ROUTES from '../../constants/routes';

class ProcedureAdded extends React.Component {
  onClick = () => {
    this.props.history.push(
      `/${ROUTES.ARCHIVIO_GATE}/${this.props.event.payload.procedure.id}`
    );
  };

  render() {
    const { event } = this.props;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onClick}>
              Pratica n° {event.payload.procedure.full_number}
            </TextLinkPrimary>
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(ProcedureAdded));
