import React, { FC, useCallback, useState } from 'react';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import { Citizen } from '../../api/model';
import { CitizenForm } from '../../common/CitizenForm';
import { SearchCitizen } from '../../common/SearchCitizen';
import { SectionTitle } from '../../common/SectionTitle';
import { userSelectRequestor } from '../../../../stm/socialServices/newProcedure.events';
import { userPressedBack } from '../../../../stm/socialServices/common.events';

export const SearchRequestor: FC = () => {
  const { dispatch } = useNewSocialService();
  const [
    citizenFiscalCodeForCreation,
    setCitizenFiscalCodeForCreation,
  ] = useState<string | undefined>();

  const onSelected = useCallback(
    (citizen: Citizen) => {
      dispatch(userSelectRequestor(citizen));
    },
    [dispatch]
  );

  //NAVIGATION
  const goBackToSelection = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  const switchToCreation = useCallback((fiscalCode: string) => {
    setCitizenFiscalCodeForCreation(fiscalCode);
  }, []);

  const goBackToSearch = useCallback(() => {
    setCitizenFiscalCodeForCreation(undefined);
  }, [setCitizenFiscalCodeForCreation]);

  if (citizenFiscalCodeForCreation) {
    return (
      <CitizenForm
        cf={citizenFiscalCodeForCreation}
        onCompleted={onSelected}
        goBack={goBackToSearch}
      />
    );
  }
  return (
    <>
      <SectionTitle>Cerca il cittadino per codice fiscale</SectionTitle>

      <SearchCitizen
        onChange={onSelected}
        onCreate={switchToCreation}
        goBack={goBackToSelection}
      />
    </>
  );
};
