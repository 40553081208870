/* eslint-disable react/jsx-handler-names */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleRules } from '@material-ui/core/styles';
import { StyledComponentProps } from '@material-ui/core/es';
import { Button, Input, withStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FormControlValidated from '../../common/FormControlValidated';
import { CitizenCard } from '../common/SearchCitizen/partial/CitizenCard';
import CodiceFiscale from 'codice-fiscale-js';
import { Citizen } from '../api/model';
import { getCitizenByCf } from '../api/api';
import { SectionTitle } from '../common/SectionTitle';

const styles = (_theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    padding: '16px 48px',
    gap: '16px',
  },
  inputLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    alignSelf: 'center',
    width: '40%',
  },
  resultLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'center',
    gap: '8px',
  },
});

const defaultCFInvalidMessage = 'Il codice fiscale non é valido';

interface SelectCitizenProps extends StyledComponentProps {
  notFoundMode?: 'fail' | 'add';
  citizenFiscalCode?: string;
  onChangeCitizenFiscalCode: (cf?: string) => void;
  onAddClicked?: (fiscalCode?: string) => void;
}

const SelectCitizen: FC<SelectCitizenProps> = ({
  notFoundMode = 'fail',
  onAddClicked,
  citizenFiscalCode,
  onChangeCitizenFiscalCode,
  classes,
}) => {
  const [fiscalCode, setFiscalCode] = useState<
    Citizen['fiscalCode'] | undefined
  >(citizenFiscalCode);

  const [citizen, setCitizen] = useState<Citizen | undefined | 'NOT_FOUND'>(
    undefined
  );

  const [loading, setLoading] = useState(false);

  const [invalidCFMessage, setInvalidCFMessage] = useState<
    string | undefined
  >();

  const isValidFiscalCode = useMemo(() => {
    setInvalidCFMessage(undefined);
    if (fiscalCode) {
      try {
        if (new CodiceFiscale(fiscalCode).isValid()) {
          return true;
        }
      } catch (e) {
        //ignore
      }
      setInvalidCFMessage(defaultCFInvalidMessage);
    }
    return false;
  }, [fiscalCode]);

  const isInputDisabled = loading;

  useEffect(() => {
    async function getUser() {
      setLoading(true);
      try {
        const result = await getCitizenByCf(fiscalCode!);

        if (result) {
          setCitizen(result);
          onChangeCitizenFiscalCode(result.fiscalCode);
        } else {
          setCitizen('NOT_FOUND');
        }
      } catch (e) {
        console.log(
          'An error has occurred during citizen data retrival:',
          invalidCFMessage
        );
        //TODO: remove when ep is not fucked up
        if (isValidFiscalCode) {
          setCitizen('NOT_FOUND');
        } else {
          setInvalidCFMessage('Si verificato un errore');
        }
      } finally {
        setLoading(false);
      }
    }
    if (isValidFiscalCode && !loading) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidFiscalCode]);

  const onChangeFiscalCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (citizen) {
        onChangeCitizenFiscalCode(undefined);
      }
      setCitizen(undefined);
      setFiscalCode(event.target.value);
    },
    [citizen, onChangeCitizenFiscalCode]
  );

  const onAdd = useCallback(() => {
    if (onAddClicked) onAddClicked(fiscalCode);
  }, [fiscalCode, onAddClicked]);

  return (
    <div className={classes?.container}>
      <SectionTitle>
        Inserisci il codice fiscale dell&apos;intervistato
      </SectionTitle>
      <div className={classes?.inputLayout}>
        <Search
          color={invalidCFMessage ? 'error' : 'primary'}
          style={{ marginTop: '36px' }}
        />
        <FormControlValidated
          inputId="searchString"
          label="Codice Fiscale"
          error={invalidCFMessage}
        >
          <Input
            value={fiscalCode}
            id="searchString"
            name="searchString"
            type="text"
            onChange={onChangeFiscalCode}
            classes={
              {
                inkbar: !invalidCFMessage
                  ? classes?.inputInkbar
                  : classes?.inputInkbarError,
              } as Record<any, any>
            }
            disabled={isInputDisabled}
            autoFocus
          />
        </FormControlValidated>
      </div>
      {citizen ? (
        citizen === 'NOT_FOUND' ? (
          <div className={classes?.resultLayout}>
            {notFoundMode === 'fail' && (
              <p>
                Nessun cittadino registrato con questo codice fiscale. Torna
                indietro per iniziare un attività di segretariato
              </p>
            )}
            {notFoundMode === 'add' && onAddClicked && (
              <>
                <p>Nessun cittadino registrato con questo codice fiscale</p>
                <br />
                <Button variant="raised" color="primary" onClick={onAdd}>
                  Aggiungi nuovo cittadino
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className={classes?.resultLayout}>
            <CitizenCard data={citizen} />
          </div>
        )
      ) : null}
    </div>
  );
};

const DecoratedSelectCitizen = withStyles(styles)(SelectCitizen);
export { DecoratedSelectCitizen as SelectCitizen };
