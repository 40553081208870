import React, { useCallback } from 'react';
import { Person } from '../api/model';
import { StyledComponentProps } from '@material-ui/core';
import { DetailField } from '../detail/layout/Detail/common/DetailField';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { compile } from 'path-to-regexp';
import TextLinkPrimary from '../../common/TextLinkPrimary';

interface CitizenSummaryProps
  extends StyledComponentProps,
    RouteComponentProps {
  citizen: Person;
}

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 24,
  },
  titleSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
});

const CitizenSummary: React.FC<CitizenSummaryProps> = ({
  classes = {},
  citizen,
  history,
}) => {
  const onCitizenDetailClick = useCallback(() => {
    const toPath = compile(`/${ROUTES.DETTAGLIO_CITTADINO}`, {
      encode: encodeURIComponent,
    });
    history.push(toPath({ userId: citizen.userId }));
  }, [citizen, history]);

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Dettagli del Cittadino</label>
        <TextLinkPrimary bold onClick={onCitizenDetailClick}>
          Dettaglio completo
        </TextLinkPrimary>
      </div>
      <div className={classes.fieldContainer}>
        <DetailField label="Nome" value={citizen.firstName} />
        <DetailField label="Cognome" value={citizen.lastName} />
        <DetailField label="Codice Fiscale" value={citizen.fiscalCode} />
        <DetailField
          label="Data di nascita"
          value={
            citizen.birthDate
              ? moment(citizen.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : 'N/A'
          }
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(withRouter(CitizenSummary));
