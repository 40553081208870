import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Icon from '../../../../../../new/components/icons';
import moment from 'moment';
import { StatusSection } from '../../../../list/partial/StatusSection';
import TabBar from '../../../../../common/TabBar';
import { WelfareInstanceStatusDTO } from '../../../../../../gen/api/welfare';
import { useLocalStateMachine } from '@moveax/redux-stm-act-react-utils';
import {
  ApproveRejectInstanceContext,
  approveRejectInstanceStm,
} from '../../../../../../stm/socialServices/approveRejectInstanceStm';
import {
  userPressedApproveInstance,
  userPressedRejectInstance,
} from '../../../../../../stm/socialServices/approveRejectingInstanceEvents';
import { ApproveRejectInstanceDialog } from './ApproveRefuseInstanceDialog';
import store from '../../../../../../store';
import { useProcedureDetail } from '../../../../../../stm/socialServices/procedureDetail.stm';
import { DEPARTMENTS, ROLES } from '../../../../../../constants/security';
import { useProcedureEvents } from '../../../../../../stm/socialServices/procedureEvents.stm';
import { SignInstanceDialog } from './SignInstanceDialog';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../../constants/routes';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShareDialog from '../../../../../sharing/Share/Dialog';

const styles = (theme: any) => ({
  mainContainer: {
    backgroundColor: theme.palette.white.main,
    position: 'sticky',
    top: '80px',
    width: '100%',
    zIndex: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: '20px 26px',
    alignSelf: 'stretch',
    borderBottom: '1px solid #DCDCDC',
  },
  iconContainer: {
    cursor: 'pointer',
    paddingRight: '24px',
    alignSelf: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: '24px',
    borderLeft: '1px solid #DCDCDC',
  },
  headerTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  detailProcedure: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: '700',
  },
  button: {
    width: '186px',
  },
  acceptOrRefuseInstanceButtonContainer: {
    display: 'grid',
    gridTemplateColumns: 'min-content min-content min-content',
    gridColumnGap: '24px',
    justifyContent: 'end',
    marginLeft: 'auto',
  },
});

interface DetailHeaderProps extends StyledComponentProps, RouteComponentProps {
  number: string;
  createdAt: Date;
  status?: WelfareInstanceStatusDTO;
  onTabChanged: (tab: number) => void;
}

const EVALUATED_PROCEDURE_STATUSES = [
  WelfareInstanceStatusDTO.ApprovedByEmployee,
  WelfareInstanceStatusDTO.RejectedByEmployee,
];

// eslint-disable-next-line complexity
const DetailHeader: FC<DetailHeaderProps> = ({
  classes = {},
  history,
  onTabChanged,
}) => {
  const { snackBar } = store.app;
  const { state, dispatch: detailDispatch } = useProcedureDetail();
  const { dispatch: eventDispatch } = useProcedureEvents();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const isActionMenuOpen = Boolean(anchorEl);
  const procedure = state.context!.procedure!;

  const goBack = useCallback(() => {
    history.push(`/${ROUTES_SERVIZI_SOCIALI.LISTA_PRATICHE}`);
  }, [history]);

  const status = procedure!.status;
  const number = `${procedure.protocol!.number}/${procedure.protocol!.year}`;
  const userId = store.app.profile.user.id;
  const { profile } = store.app;

  const canShare =
    procedure.assignee?.userId === userId ||
    profile.isHigherOrEqualThanForDepartment(
      [
        {
          department: DEPARTMENTS.SOCIAL_SERVICES,
          role: ROLES.ADMINISTRATIVE,
        },
      ],
      true
    );

  const canAcceptOrRefuseInstance =
    procedure.assignee?.userId === userId &&
    procedure.status === WelfareInstanceStatusDTO.Assigned;

  const canConfirmOrRejectEvaluation =
    EVALUATED_PROCEDURE_STATUSES.includes(procedure.status) &&
    profile.isHigherOrEqualThanForDepartment(
      [
        {
          department: DEPARTMENTS.SOCIAL_SERVICES,
          role: ROLES.PO,
        },
      ],
      true
    );

  const canAddAccountingDecision =
    WelfareInstanceStatusDTO.ApprovedByPo === procedure.status &&
    profile.getDepartmentRole(DEPARTMENTS.SOCIAL_SERVICES) ===
      ROLES.ADMINISTRATIVE;

  const approveStm = useLocalStateMachine(approveRejectInstanceStm, {
    instanceId: procedure.id,
    detailDispatch: detailDispatch,
    eventsDispatch: eventDispatch,
  });

  const onApproveInstanceClick = useCallback(() => {
    approveStm.dispatch(userPressedApproveInstance());
  }, [approveStm]);
  const onRefuseInstanceClick = useCallback(() => {
    approveStm.dispatch(userPressedRejectInstance());
  }, [approveStm]);

  const onConfirmEvaluationClick = useCallback(() => {
    if (
      procedure.status === WelfareInstanceStatusDTO.ApprovedByEmployee &&
      !state.context?.executiveDecision
    )
      snackBar.open(
        'Per poter approvare la pratica è necessario allegare la determina in bozza'
      );
    else {
      approveStm.dispatch(
        userPressedApproveInstance(state?.context?.executiveDecision)
      );
    }
  }, [procedure.status, state, snackBar, approveStm]);

  const onRejectEvaluationClick = onRefuseInstanceClick;

  const canSign =
    WelfareInstanceStatusDTO.ApprovedByAdministrative === procedure.status &&
    profile.getDepartmentRole(DEPARTMENTS.SOCIAL_SERVICES) === ROLES.MANAGER;

  const [showSignDialog, setShowSignDialog] = useState<boolean>(false);

  const onAccountingApproveClick = useCallback(() => {
    if (
      procedure.status === WelfareInstanceStatusDTO.ApprovedByPo &&
      !state.context?.accountingDecision
    )
      snackBar.open(
        'per poter approvare la pratica è necessario allegare la determina in ragioneria'
      );
    else {
      approveStm.dispatch(
        userPressedApproveInstance(state?.context?.accountingDecision)
      );
    }
  }, [procedure.status, state, snackBar, approveStm]);

  const onApproveAndSign = useCallback(() => {
    setShowSignDialog(true);
  }, []);

  const onSignInstanceDialogClose = useCallback(() => {
    setShowSignDialog(false);
  }, []);

  const onActionsMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onShareClicked = useCallback(() => {
    setShareDialogOpen(true);
  }, []);

  const onShareDialogClose = useCallback(() => {
    setShareDialogOpen(false);
  }, []);

  const onActionMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div onClick={goBack} className={classes.iconContainer}>
            <Icon type="Back" size={20} />
          </div>
          <div className={classes.titleContainer}>
            <label className={classes.headerTitle}>Dettaglio istanza</label>
            <label className={classes.detailProcedure}>
              Istanza n. {number} del{' '}
              {moment(procedure.createdAt).format('DD/MM/YYYY')}{' '}
              {status && <StatusSection status={status} />}
            </label>
          </div>
          <div className={classes.acceptOrRefuseInstanceButtonContainer}>
            {canAcceptOrRefuseInstance && (
              <>
                <Button
                  variant="outlined"
                  className={classes.button}
                  color="primary"
                  onClick={onRefuseInstanceClick}
                >
                  Rifiuta
                </Button>
                <Button
                  className={classes.button}
                  variant="raised"
                  color="primary"
                  onClick={onApproveInstanceClick}
                >
                  Approva
                </Button>
              </>
            )}
            {canConfirmOrRejectEvaluation && (
              <>
                <Button
                  variant="outlined"
                  className={classes.button}
                  color="primary"
                  onClick={onRejectEvaluationClick}
                >
                  Rifiuta
                </Button>
                <Button
                  className={classes.button}
                  variant="raised"
                  color="primary"
                  onClick={onConfirmEvaluationClick}
                >
                  Conferma
                </Button>
              </>
            )}
            {canAddAccountingDecision && (
              <Button
                className={classes.button}
                variant="raised"
                color="primary"
                onClick={onAccountingApproveClick}
              >
                Conferma
              </Button>
            )}
            {canSign && (
              <Button
                className={classes.button}
                variant="raised"
                color="primary"
                onClick={onApproveAndSign}
              >
                Approva e Firma
              </Button>
            )}
            {canShare && (
              <div>
                <IconButton
                  aria-label="Azioni aggiuntive"
                  aria-owns={isActionMenuOpen ? 'actions-menu' : undefined}
                  aria-haspopup={true}
                  onClick={onActionsMenuClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  open={isActionMenuOpen}
                  onClose={onActionMenuClose}
                  anchorEl={anchorEl}
                >
                  <MenuItem onClick={onShareClicked}>Richiedi Parere</MenuItem>
                </Menu>
                <ShareDialog
                  open={shareDialogOpen}
                  instanceId={procedure.id}
                  onClose={onShareDialogClose}
                />
              </div>
            )}
          </div>
        </div>
        <TabBar
          onTabChanged={onTabChanged}
          tabs={["Dettaglio dell'istanza", 'Lavorazione']}
        />
      </div>
      <ApproveRejectInstanceContext.Provider value={approveStm}>
        <ApproveRejectInstanceDialog />
      </ApproveRejectInstanceContext.Provider>
      {showSignDialog && (
        <SignInstanceDialog
          id={procedure.id}
          onClose={onSignInstanceDialogClose}
        />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedDetailHeader = withStyles(styles)(withRouter(DetailHeader));

export { DecoratedDetailHeader as DetailHeader };
