import React, { FC, useCallback, useState } from 'react';
import { Button, StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { SectionTitle } from '../../common/SectionTitle';
import { ChevronLeft } from '@material-ui/icons';
import { BenefitFlag } from '../../common/BenefitFlag';
import { BenefitDTO, BenefitTypeDTO } from '../../../../gen/api/welfare';

const styles = (theme: any) => ({
  mainContainer: {
    flex: 1,
    width: '40%',
    padding: '16px 48px',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    width: '496px',
    marginTop: '64px',
  },
  footer: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    width: '100%',
    alignSelf: 'stretch',
    padding: '24px 24px 48px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

interface SelectOtherBenefitsFormProps extends StyledComponentProps {
  initialBenefits?: BenefitDTO[];
  onConfirm: (benefits: BenefitDTO[]) => void;
  onAbort: () => void;
  title?: string;
  abortText?: string;
  confirmText?: string;
  skipText?: string;
}
const SelectOtherBenefitsForm: FC<SelectOtherBenefitsFormProps> = ({
  classes = {},
  initialBenefits = [],
  onConfirm,
  onAbort,
  title = 'Inserisci eventuali benefici a cui ha gia accesso',
  abortText = 'Indietro',
  confirmText = 'Conferma i dati e prosegui',
  skipText = 'Non ha altri benefici',
}) => {
  const [selectedBenefits, setSelectedBenefits] = useState<
    Partial<Record<BenefitTypeDTO, string>>
  >(
    initialBenefits
      .filter(b => !!b.value)
      .reduce(
        (acc, curr) => ({
          [curr.type]: curr.value,
        }),
        {}
      )
  );

  const [errors, setErrors] = useState<Partial<Record<BenefitTypeDTO, string>>>(
    {}
  );

  const handleChange = useCallback(
    (ben: BenefitTypeDTO, val?: string | null) => {
      setSelectedBenefits(old => ({
        ...old,
        [ben]: val,
      }));
      if (val !== null && errors[ben]) {
        setErrors(er => ({
          ...er,
          [ben]: undefined,
        }));
      }
    },
    [errors]
  );

  const goOn = useCallback(() => {
    const errors = Object.entries(selectedBenefits).reduce((prev, act) => {
      if (act[1] === null) {
        return {
          ...prev,
          [act[0]]: 'Il campo è richiesto',
        };
      }
      return prev;
    }, {});
    if (Object.values(errors).filter(o => !!o).length > 0) {
      setErrors(errors);
    } else {
      const benefits = Object.entries(selectedBenefits)
        .filter(([_, value]) => !!value)
        .map(([type, value]) => ({
          type: type as BenefitTypeDTO,
          value: value!,
        }));
      onConfirm(benefits);
    }
  }, [onConfirm, selectedBenefits]);

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <div className={classes.container}>
        {Object.values(BenefitTypeDTO).map(ben => (
          <BenefitFlag
            key={ben}
            value={selectedBenefits[ben]}
            type={ben}
            handleChange={handleChange}
            error={errors[ben] ?? undefined}
          />
        ))}
      </div>
      <div className={classes.footer}>
        <Button variant="flat" color="primary" onClick={onAbort}>
          <ChevronLeft />
          {abortText}
        </Button>
        {Object.values(selectedBenefits).filter(val => val !== undefined)
          .length < 1 ? (
          <Button variant="outlined" color="primary" onClick={goOn}>
            {skipText}
          </Button>
        ) : (
          <Button variant="raised" color="primary" onClick={goOn}>
            {confirmText}
          </Button>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSelectOtherBenefitsForm = withStyles(styles)(
  SelectOtherBenefitsForm
);

export { DecoratedSelectOtherBenefitsForm as SelectOtherBenefitsForm };
