import React, { FC, useCallback } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import Icon from '../../../../../new/components/icons';
import DragAndDrop from '../../../../common/DragAndDrop';

const styles = (theme: any) => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  document: {
    flex: 1,
    borderBottom: '2px solid ' + theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    padding: '10px 20px',
    borderRadius: '1px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  documentNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  documentName: {
    fontFamily: theme.typography.fontFamily,
    textOverflow: 'ellipsis',
    marginRight: '10px',
    color: theme.palette.primary.main,
    marginLeft: '24px',
  },
  documentAction: {
    fontSize: '22px',
    justifySelf: 'flex-end',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
});

interface SelectDocumentProps extends StyledComponentProps {
  onDocumentSelected: (file: File) => void;
  onDocumentDeleted: () => void;
  selectedDocument: File;
}

const SelectDocument: FC<SelectDocumentProps> = ({
  classes = {},
  onDocumentSelected,
  onDocumentDeleted,
  selectedDocument,
}) => {
  const onDrop = useCallback(
    (file: File) => {
      onDocumentSelected(file);
    },
    [onDocumentSelected]
  );

  return (
    <div className={classes.container}>
      {!selectedDocument ? (
        <DragAndDrop onDrop={onDrop} />
      ) : (
        <div className={classes.document}>
          <div className={classes.documentNameContainer}>
            <Icon type="Document" />
            <span className={classes.documentName}>
              {selectedDocument.name}
            </span>
          </div>
          <span className={classes.documentAction} onClick={onDocumentDeleted}>
            &times;
          </span>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(SelectDocument);
