import CallManager from './AxiosCallManager';

export default {
  getSharedDocuments: (shareId, onSuccess, onError) => {
    CallManager.get(
      `shared_procedure/${shareId}/documents`,
      response => onSuccess(response.data),
      onError
    );
  },
  updateNotes: (shareId, notes, onSuccess, onError) => {
    CallManager.post(
      `shared_procedure/${shareId}/notes`,
      response => onSuccess(response.data),
      onError,
      {
        notes,
      }
    );
  },
  downloadSharedDocument: (shareId, documentId, onFinished, onError) => {
    CallManager.downloadFile(
      `shared_procedure/${shareId}/documents/${documentId}`,
      onFinished,
      onError
    );
  },
  uploadSharedDocument: (shareId, contents, onSuccess, onError) => {
    const data = new FormData();
    data.append('file', contents);
    data.append('name', contents.name);
    CallManager.post(
      `shared_procedure/${shareId}/documents`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  deleteSharedDocument: (shareId, documentId, onSuccess, onError) => {
    CallManager.delete(
      `shared_procedure/${shareId}/documents/${documentId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  resolveSharedProcedure: (shareId, onSuccess, onError) => {
    CallManager.patch(
      `shared_procedure/${shareId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  closeSharedProcedure: (shareId, onSuccess, onError) => {
    CallManager.delete(
      `shared_procedure/${shareId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  searchSharableGroups: (query, procedureType, onSuccess, onError) => {
    CallManager.get(
      `shared_procedure/search_groups`,
      response => onSuccess(response.data),
      onError,
      {
        procedure_type: procedureType,
        query,
      }
    );
  },
};
