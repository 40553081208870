import { Checkbox } from './partial/Checkbox';
import {
  Chip,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { SelectionFlagProps } from './model';
import { StyleRules } from '@material-ui/core/styles';

const styles = (_theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '896px',
    padding: '4px',
    borderBottom: '1px solid #DDDDDD',
  },
  flagContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collapseContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    background: '#E6E9F2',
    color: '#142373',
  },
  noneOption: {
    color: '#5c6771',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const _MultipleSelectionFlag: FC<SelectionFlagProps> = ({
  label,
  data = [],
  onChange: onChangeCallback,
  options,
  classes,
}) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newValue = (event.target.value as unknown) as string[];
      onChangeCallback(newValue.length !== 0 ? newValue : undefined);
    },
    [onChangeCallback]
  );

  const textMap = useMemo(() => {
    return options.reduce((prev: { [id: string]: string }, curr) => {
      prev[curr.id] = curr.text;
      return prev;
    }, {});
  }, [options]);

  // eslint-disable-next-line react/no-multi-comp
  const optionRenderer = (selected: string[]) => {
    if (selected.length === 0) {
      return <div className={classes?.noneOption}>Nessuno</div>;
    }
    const displayed = selected.filter((value, idx) => idx < 2);
    return (
      <div className={classes?.chips}>
        {displayed.map(value => (
          <Chip
            key={value}
            label={textMap[value] ?? value}
            className={classes?.chip}
          />
        ))}
        {selected.length > 2 && (
          <Chip
            key="extra-values-chip"
            label={`+${selected.length - 2}`}
            className={classes?.chip}
          />
        )}
      </div>
    );
  };

  const noop = useCallback(() => {
    return;
  }, []);

  return (
    <FormControl className={classes?.container}>
      <div className={classes?.flagContainer}>
        <p
          style={{
            color: '#5C6771',
          }}
        >
          {label}
        </p>
        <Select
          multiple
          value={data}
          onChange={onChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={
            optionRenderer as (
              value:
                | string
                | number
                | boolean
                | (string | number | boolean)[]
                | undefined
            ) => ReactNode
          }
          MenuProps={MenuProps}
          style={{ width: '481px' }}
          displayEmpty
        >
          {options.map(({ id: optionId, text: optionText }) => (
            <MenuItem key={optionId} value={optionId} onClick={noop}>
              <Checkbox
                checked={data && data.indexOf(optionId) > -1}
                color="primary"
              />
              <ListItemText primary={optionText} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const MultipleSelectionFlag = withStyles(styles)(_MultipleSelectionFlag);
