import React, { FC } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import Loader from '../../../../common/Loader';

const styles = (theme: any) => ({
  container: {
    flex: 1,
    height: 150,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  text: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    lineHeight: '27px',
    textAlign: 'center',
  },
});

export interface OperationInProgress extends StyledComponentProps {
  message: string;
}

const OperationInProgress: FC<OperationInProgress> = ({
  classes = {},
  message,
}) => {
  return (
    <div className={classes.container}>
      <Loader size={20} color="primary" loading />
      <div className={classes.text}>{message}</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(OperationInProgress);
