import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { getUserStatusLabel, USER_STATES } from '../../constants/common';
import React from 'react';
import PropTypes from 'prop-types';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
    width: 'fit-content',
  },
  stateNew: {
    backgroundColor: '#0277bd',
  },
  stateApproved: {
    backgroundColor: '#00600f',
  },
  stateRejected: {
    backgroundColor: '#ba0101',
  },
});

const UserStatusBadge = withStyles(styles)(props => {
  const { classes, state } = props;

  return (
    <div
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateNew]: state === USER_STATES.UNACTIVE,
        [classes.stateApproved]: state === USER_STATES.ACTIVE,
        [classes.stateRejected]: state === USER_STATES.BLOCKED,
      })}
    >
      {getUserStatusLabel(state)}
    </div>
  );
});

UserStatusBadge.propTypes = {
  state: PropTypes.string.isRequired,
};

export default UserStatusBadge;
