import { StateMachine } from 'redux-sigma';
import { CommonEvents } from './common.events';
import { createLocalStateMachineContext } from '@moveax/redux-stm-act-react-utils';
import { useContext } from 'react';
import { call, put, SagaGenerator } from 'typed-redux-saga';

import { getEvents } from '../../components/serviziSociali/api/api';
import { WelfareInstanceEventDTO } from '../../gen/api/welfare';
import {
  AddedNewReply,
  errorLoading,
  eventsLoaded,
  ProcedureEventsEvents,
} from './procedureEvents.events';

export enum ProcedureEventsStates {
  loading = 'states/loading',
  loaded = 'states/loaded',
  errorLoading = 'states/errorLoading',
}

interface Context {
  id: string;
  events?: WelfareInstanceEventDTO[];
}

export class ProcedureEventsStm extends StateMachine<
  CommonEvents & ProcedureEventsEvents,
  ProcedureEventsStates,
  string,
  Context
> {
  protected readonly initialState = ProcedureEventsStates.loading;

  readonly name = 'serviziSocialiEventi';

  protected readonly spec = {
    [ProcedureEventsStates.loaded]: {
      transitions: {
        [ProcedureEventsEvents.reloadRequested]: ProcedureEventsStates.loading,
      },
      reactions: {
        [ProcedureEventsEvents.addedNewReply]: this.storeNewReply,
      },
    },
    [ProcedureEventsStates.loading]: {
      onEntry: this.loadEvents,
      transitions: {
        [ProcedureEventsEvents.eventsLoaded]: ProcedureEventsStates.loaded,
        [ProcedureEventsEvents.errorLoading]:
          ProcedureEventsStates.errorLoading,
      },
    },
    [ProcedureEventsStates.errorLoading]: {
      transitions: {
        [CommonEvents.userPressRetry]: ProcedureEventsStates.loading,
      },
    },
  };

  *loadEvents(): SagaGenerator<void> {
    try {
      const events = yield* call(getEvents, this.context.id);
      yield* this.setContext(ctx => {
        ctx.events = events;
      });
      yield* put(eventsLoaded());
    } catch (e) {
      console.error(e);
      yield* put(errorLoading());
    }
  }

  *storeNewReply(event: AddedNewReply): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.events?.unshift(event.payload);
    });
  }
}

export const procedureEventStm = new ProcedureEventsStm();
export const ProcedureEventStmContext = createLocalStateMachineContext(
  procedureEventStm
);

export function useProcedureEvents() {
  return useContext(ProcedureEventStmContext);
}
