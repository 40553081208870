import CallManager from './AxiosCallManager';
import { ROLES } from '../constants/security';

export default {
  all: (onSuccess, onError) => {
    CallManager.get(
      'departments?all=true',
      response => onSuccess(response.data.departments),
      onError
    );
  },
  loadList: ({ page }, onSuccess, onError) => {
    CallManager.get(
      'departments/',
      response => onSuccess(response.data),
      onError,
      { page: page }
    );
  },
  get: (id, onSuccess, onError) => {
    CallManager.get(
      'departments/' + id,
      response => onSuccess(response.data),
      onError
    );
  },
  create: (data, onSuccess, onError) => {
    CallManager.post('departments/', onSuccess, onError, data);
  },
  update: (id, data, onSuccess, onError) => {
    CallManager.patch(`departments/${id}`, onSuccess, onError, data);
  },
  delete: (id, onSuccess, onError) => {
    CallManager.delete(`departments/${id}`, onSuccess, onError);
  },
  getDepartmentUsers: (id, { page }, onSuccess, onError) => {
    CallManager.get(
      `departments/${id}/users`,
      response => onSuccess(response.data),
      onError,
      { page: page, role: ROLES.EMPLOYEE }
    );
  },
};
