import { createAction } from '@reduxjs/toolkit';

export enum CommonEvents {
  userPressedBack = 'events/userPressedBack',
  userPressedGoOn = 'events/userPressedGoOn',
  userPressRetry = 'events/userPressRetry',
}

export const userPressedBack = createAction(CommonEvents.userPressedBack);
export const userPressedGoOn = createAction(CommonEvents.userPressedGoOn);
export const userPressRetry = createAction(CommonEvents.userPressRetry);
