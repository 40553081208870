import React, { FC } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import Loader from '../../../common/Loader';

const styles = (theme: any) => ({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
  },
});

const Submitting: FC<StyledComponentProps> = ({ classes = {} }) => {
  return (
    <div className={classes.container}>
      <Loader size={48} color="primary" loading />
      <div className={classes.text}>Creazione pratica in corso...</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSubmitting = withStyles(styles)(Submitting);

export { DecoratedSubmitting as Submitting };
