import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { FirstPage as NavigationFirstPage } from '@material-ui/icons';
import { NavigateNext as NavigationNextPage } from '@material-ui/icons';
import { LastPage as NavigationLastPage } from '@material-ui/icons';
import { NavigateBefore as NavigationBeforePage } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '10px 0px 0px 0px',
    alignSelf: 'flex-end',
    color: theme.palette.grey.dark,
  },
  item: {
    margin: '0px 3px 0px 3px',
  },
});

const calculateRange = arg => {
  const { total, current, display } = arg;
  let end = total;
  let start = 1;
  if (display < end) {
    // rounded to the nearest integer smaller
    let beforeNumber = Math.round(display / 2 - 0.5);
    const afterNumber = beforeNumber;
    if (display % 2 === 0) {
      beforeNumber -= 1;
    }

    if (current <= beforeNumber + 1) {
      end = display;
    } else if (current >= total - afterNumber) {
      start = total - display + 1;
    } else {
      start = current - beforeNumber;
      end = current + afterNumber;
    }
  }

  return { end, start };
};

const getStateFromProps = props => {
  let { total, current, display } = props;
  total = total > 0 ? total : 1;
  current = current > 0 ? current : 1;
  display = display > 0 ? display : 1;
  current = current < total ? current : total;
  display = display < total ? display : total;
  return { current, display, total };
};

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    const tem = getStateFromProps(props);
    this.setCurrent = this.setCurrent.bind(this);

    this.state = {
      ...tem,
      ...calculateRange(tem),
    };
  }

  componentWillReceiveProps(nextProps) {
    const tem = getStateFromProps(nextProps);
    this.setState({
      ...tem,
      ...calculateRange(tem),
    });
  }

  setCurrent(current) {
    const tem = { ...this.state, current };
    this.props.onChange({ page: current });
    this.setState({
      ...tem,
      ...calculateRange(tem),
    });
  }

  render() {
    const array = [];
    for (let i = this.state.start; i <= this.state.end; i += 1) {
      array.push(i);
    }
    const {
      classes,
      total: totalPages,
      totalElement,
      elementNumber,
    } = this.props;
    const isVisible = totalPages > 1;
    return (
      isVisible && (
        <>
          <Row style={{ margin: '0px', padding: '5px 0px 0px' }}>
            <Col xs={12} sm={12} style={{ textAlign: 'right' }}>
              <Typography variant="caption">
                Risultati in questa pagina: {elementNumber}/{totalElement}
              </Typography>
            </Col>
          </Row>
          <Row style={{ margin: '0px', textAlign: 'center' }}>
            <Col xs={12} sm={12}>
              <Button
                mini
                size="small"
                disabled={this.state.current === 1}
                className={classes.item}
                onClick={() => this.setCurrent(1)}
              >
                <NavigationFirstPage />
              </Button>
              <Button
                mini
                size="small"
                disabled={this.state.current === 1}
                className={classes.item}
                onClick={() =>
                  this.setCurrent(
                    this.state.current > 1 ? this.state.current - 1 : 1
                  )
                }
              >
                <NavigationBeforePage />
              </Button>
              {array.map(page => (
                <Button
                  mini
                  size="small"
                  key={page}
                  className={classes.item}
                  color={this.state.current === page ? 'primary' : 'default'}
                  style={{
                    fontWeight: this.state.current === page ? 'bold' : 'normal',
                  }}
                  onClick={() => {
                    if (this.state.current !== page) {
                      this.setCurrent(page);
                    }
                  }}
                >
                  {page}
                </Button>
              ))}
              <Button
                mini
                size="small"
                className={classes.item}
                disabled={this.state.current === this.state.total}
                onClick={() =>
                  this.setCurrent(
                    this.state.current < this.state.total
                      ? this.state.current + 1
                      : this.state.total
                  )
                }
              >
                <NavigationNextPage />
              </Button>
              <Button
                mini
                size="small"
                disabled={this.state.current === this.state.total}
                className={classes.item}
                onClick={() => this.setCurrent(this.state.total)}
              >
                <NavigationLastPage />
              </Button>
            </Col>
          </Row>
        </>
      )
    );
  }
}

Pagination.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  total: PropTypes.number,

  // eslint-disable-next-line react/no-unused-prop-types
  current: PropTypes.number,

  // eslint-disable-next-line react/no-unused-prop-types
  display: PropTypes.number,
  onChange: PropTypes.func,

  styleRoot: PropTypes.object,
  styleFirstPageLink: PropTypes.object,
  styleLastPageLink: PropTypes.object,
  styleButton: PropTypes.object,
  stylePrimary: PropTypes.object,
  totalElement: PropTypes.number,
  elementNumber: PropTypes.number,
};

Pagination.defaultProps = {
  styleRoot: null,
  styleFirstPageLink: null,
  styleLastPageLink: null,
  styleButton: null,
  stylePrimary: null,
};

Pagination.displayName = 'Pagination';
export default withStyles(styles)(Pagination);
