import React, { useCallback, useRef, useState } from 'react';
import { Button, Input, withStyles } from '@material-ui/core';
import { drawerWidth } from '../../../index';
import { FormControl } from '@material-ui/core';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import store from '../../../store';
import DocumentiTemporanei from '../../../api/DocumentiTemporanei';
import Document from '../Common/Document';
import PraticheInterne from '../../../api/PraticheInterne';
import PropTypes from 'prop-types';
import Loader from '../../common/Loader';
import Widget from '../../common/documents/upload';
import { DEPARTMENTS } from '../../../constants/security';

const styles = theme => ({
  container: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    marginLeft: `${drawerWidth + 2}px`,
  },
  content: {
    width: '100%',
    padding: '32px 32px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 80px rgba(0, 43, 85, 0.1)',
  },
  header: {
    height: '50px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: '20px 20px',
    color: theme.palette.primary.main,
    borderBottom: '1px solid #eee',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '20px',
    lineHeight: '100%',
  },
  headerIcon: {
    marginRight: '10px',
  },
  headerRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  uploadButton: {
    minWidth: '48px',
    marginRight: '16px',
  },
  uploadButtonIcon: {
    height: '24px',
  },
  form: {
    flex: 1,
    marginRight: 32,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  documents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    '& > * + *': {
      marginTop: 8,
    },
  },
  inputLabel: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.grey.dark,
    marginBottom: 16,
  },
  replyButton: {
    height: 48,
  },
});

const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;

const ReplyBox = props => {
  const { classes, procedure, onNewReply } = props;
  const { snackBar, profile } = store.app;
  const notesInput = useRef();
  const [notes, setNotes] = useState('');
  const [documents, setDocuments] = useState([]);
  const [postingReply, setPostingReply] = useState(false);

  const onNotesChanged = useCallback(event => {
    setNotes(event.target.value);
  }, []);

  const onReplyClick = useCallback(() => {
    setPostingReply(true);
    PraticheInterne.reply(
      procedure.id,
      {
        notes,
        department: profile.isMemberOf(DEPARTMENTS.ADMIN, true)
          ? DEPARTMENTS.ADMIN
          : profile.operatingAs.department,
        documents: Object.fromEntries(documents),
      },
      () => {
        snackBar.open('Nota aggiunta con successo');
        setPostingReply(false);
        setNotes('');
        setDocuments([]);
        onNewReply();
      },
      () => {
        snackBar.open("Si è verificato un problema durante l'invio della nota");
        setPostingReply(false);
      }
    );
  }, [procedure.id, notes, profile, documents, snackBar, onNewReply]);

  const onDocumentUploaded = useCallback((id, name) => {
    setDocuments(docs => {
      return [...docs, [id, name]];
    });
  }, []);

  const onDocumentDeleted = useCallback(id => {
    DocumentiTemporanei.delete(id);
    setDocuments(docs => {
      return docs.filter(d => d[0] !== id);
    });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Loader loading={postingReply} style={{ marginBottom: '30px' }} />
        {!postingReply && (
          <>
            <span className={classes.inputLabel}>Aggiungi Nota</span>
            <div className={classes.formContainer}>
              <div className={classes.form}>
                <FormControl fullWidth>
                  <Input
                    placeholder="Inizia a scrivere una nota"
                    inputRef={el => (notesInput.current = el)}
                    id="notes"
                    fullWidth
                    inputProps={{
                      maxLength: 2000,
                    }}
                    value={notes}
                    onChange={onNotesChanged}
                  />
                </FormControl>
              </div>
              <div className={classes.actions}>
                <Widget
                  buttonMode="collapsed"
                  onDocumentUploaded={onDocumentUploaded}
                  buttonClassName={classes.uploadButton}
                  buttonChild={
                    <UploadIcon className={classes.uploadButtonIcon} />
                  }
                  buttonProps={{
                    style: { minWidth: 48, height: 48, padding: 0 },
                    variant: 'outlined',
                    color: 'primary',
                    size: 'large',
                  }}
                />
                <Button
                  className={classes.replyButton}
                  size="large"
                  variant="raised"
                  color="primary"
                  disabled={!notes}
                  onClick={onReplyClick}
                >
                  Invia Risposta
                </Button>
              </div>
            </div>
          </>
        )}
        {documents.length > 0 && (
          <div className={classes.documents}>
            {documents.map((d, i) => (
              <Document
                /* eslint-disable-next-line react/no-array-index-key */
                key={i}
                deletable
                documentId={d[0]}
                fileName={d[1]}
                extension={DOCUMENT_EXTENSION.exec(d[1])[1]}
                onClick={onDocumentDeleted}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ReplyBox.propTypes = {
  procedure: PropTypes.object.isRequired,
  onNewReply: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReplyBox);
