import React from 'react';
import { Chip, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getDepartmentLabel } from '../../../constants/common';
import Icon from '../../../new/components/icons';

const baseStyles = {
  root: {
    marginRight: '5px',
    marginBottom: '5px',
    position: 'relative',
    color: 'white',
    fontWeight: 'bold',
  },
  deleteIcon: {
    color: 'rgba(255,255,255,0.7)',
    '&:hover': {
      color: 'rgba(255,255,255,1)',
    },
  },
};

const doneStyles = () => ({
  ...baseStyles,
  root: {
    ...baseStyles.root,
    backgroundColor: '#00B676',
    '&:focus': {
      backgroundColor: '#00B676',
    },
  },
  deleteIcon: {
    ...baseStyles.deleteIcon,
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    cursor: 'auto',
  },
});

const pendingStyles = theme => ({
  ...baseStyles,
  root: {
    ...baseStyles.root,
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  pending: {
    backgroundColor: theme.palette.primary.main,
  },
});

const ShareChip = props => {
  const { status } = props;

  if (status === 'pending') return <PendingChip {...props} />;
  return <DoneChip {...props} />;
};

const PendingChip = withStyles(pendingStyles)(props => {
  const { classes, department, onDelete, deletable } = props;

  return (
    <Chip
      classes={classes}
      label={getDepartmentLabel(department)}
      onDelete={deletable ? onDelete : null}
    />
  );
});

const DoneChip = withStyles(doneStyles)(props => {
  const { classes, department } = props;

  return (
    <Chip
      classes={classes}
      label={getDepartmentLabel(department)}
      onDelete={() => {}}
      deleteIcon={<Icon type="CheckCircle" color="#fff" size={24} />}
    />
  );
});

ShareChip.propTypes = {
  department: PropTypes.object.isRequired,
  status: PropTypes.oneOf(['pending', 'done']),
  onDelete: PropTypes.func,
  deletable: PropTypes.bool,
};

ShareChip.defaultProps = {
  status: 'pending',
  onDelete: () => {},
  deletable: false,
};

export default ShareChip;
