export default {
  LOGIN: 'login',
  PROFILO: 'profilo',
  DIPARTIMENTI: 'dipartimenti',
  RICERCA: 'ricerca',
  NUOVO_GRUPPO: 'nuovogruppo',
  UTENTI: 'utenti',
  NUOVO_UTENTE: 'nuovoutente',
  EVENTI: 'eventi',
  AUDIT: 'audit',
  UNAUTHORIZED: 'unauthorized',
  UNATHORIZED_PERSONNEL: 'notauthorized',
  INVITO: 'invito',
  PASSWORD_RESET: 'password/reset',
  SESSIONI: 'sessioni',
  ARCHIVIO_GATE: 'logistica/gate',
  PRATICHE_EDILIZIA: 'edilizia/pratiche',
  SCADENZIARIO_EDILIZIA: 'edilizia/scadenziario',
  SCADENZIARIO_EDILIZIA_STORICO: 'edilizia/scadenziario/storico',
  SCADENZIARIO_EDILIZIA_INVIO_PRATICA:
    'edilizia/scadenziario/:procedureId/invio',
  CONDIVISIONE: 'condivisione',
  DETTAGLIO_CONDIVISIONE: 'condivisione/:instanceId',
  CITTADINI: 'cittadini',
  DETTAGLIO_CITTADINO: 'cittadini/:userId',
  RICHIESTE: 'richieste',
  SCRIVANIA_VIRTUALE: 'scrivania_virtuale',
  PRATICA_INTERNA: 'scrivania_virtuale/:procedureId',
  CERTIFICATI_DEMOGRAFICI: 'servizi_demografici/certificati',
  CERTIFICATI_STATO_CIVILE: 'stato_civile/certificati',
  DEMOGRAFICI_DETTAGLIO: 'servizi_demografici/certificati/:certificateId',
  STATO_CIVILE_DETTAGLIO: 'stato_civile/certificati/:certificateId',
};

const SERVIZISOCIALIBASE = 'servizi_sociali';

export const ROUTES_SERVIZI_SOCIALI = {
  ROOT: SERVIZISOCIALIBASE,
  DASHBOARD_BASE: `${SERVIZISOCIALIBASE}/dashboard`,
  DASHBOARD_SECTION: `${SERVIZISOCIALIBASE}/dashboard/:section`,
  LISTA_PRATICHE: `${SERVIZISOCIALIBASE}/pratiche`,
  NUOVA_PRATICA: `${SERVIZISOCIALIBASE}/pratiche/new`,
  DETTAGLIO_PRATICA: `${SERVIZISOCIALIBASE}/pratiche/:praticaId`,
  EDIT_PRATICA: `${SERVIZISOCIALIBASE}/pratiche/:praticaId/edit/:sectionToEdit`,

  DIARIO: `${SERVIZISOCIALIBASE}/diario`,
  NUOVA_ATTIVITA_DIARIO: `${SERVIZISOCIALIBASE}/diario/new`,

  DETTAGLIO_COLLOQUIO: `${SERVIZISOCIALIBASE}/diario/colloqui/:interviewId`,
  DETTAGLIO_SEGRETARIATO: `${SERVIZISOCIALIBASE}/diario/segretariato/:activityId`,
};
