import React, { useCallback, useEffect, useState } from 'react';
import Subsection from '../../common/Subsection';
import { Col, Row } from 'react-flexbox-grid';
import StatusBadge from '../common/StatusBadge';
import LabelValue from './LabelValue';
import {
  CERTIFICATE_REQUEST_STATE,
  getCertificateTypeLabel,
} from '../../../constants/common';
import CitizenDetails from '../../cittadini/Detail';
import Loader from '../../common/Loader';
import Cittadini from '../../../api/Cittadini';
import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AddCircle, CheckCircle } from '@material-ui/icons';
import classnames from 'classnames';
import store from '../../../store';

const styles = theme => ({
  container: {
    margin: '10px 0',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  headerContainer: {
    alignItems: 'center',
    marginBottom: '20px',
  },
  detailsContainer: {
    alignItems: 'center',
    marginBottom: '28px',
  },
  controlsContainer: {
    alignItems: 'center',
    justifyContent: 'end',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  assigneeValue: {
    fontWeight: 'normal',
  },
  button: {
    justifySelf: 'end',
    marginLeft: 'auto',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 16,
  },
});

const Detail = props => {
  const {
    classes,
    canHandle,
    request: { type, details },
    api,
  } = props;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [handler, setHandler] = useState(details.handler ?? undefined);
  const { snackBar } = store.app;

  //local state for item to hanlde takeCharge
  const [state, setState] = useState(details.status);

  const onTakeCharge = useCallback(
    event => {
      event.stopPropagation();
      api.handle(
        details.id,
        () => {
          setState(CERTIFICATE_REQUEST_STATE.HANDLED);
          setHandler({
            firstName: store.app.profile.user.person.first_name,
            lastName: store.app.profile.user.person.last_name,
          });
        },
        () => {
          snackBar.open("Errore durante l'esecuzione dell'operazione");
        }
      );
    },
    [api, details.id, snackBar]
  );

  const onSetResolved = useCallback(
    event => {
      event.stopPropagation();
      api.resolve(
        details.id,
        () => setState(CERTIFICATE_REQUEST_STATE.RESOLVED),
        () => {
          snackBar.open("Errore durante l'esecuzione dell'operazione");
        }
      );
    },
    [api, details.id, snackBar]
  );

  useEffect(() => {
    setLoading(true);
    Cittadini.getCittadinoByFiscalCode(details.user.fiscalCode, user => {
      setUser(user);
      setLoading(false);
    });
  }, [details.user.fiscalCode]);

  return (
    <>
      <div className={classes.container}>
        <Subsection>
          <Row className={classes.headerContainer}>
            <Col xs={3}>
              <StatusBadge state={state} />
            </Col>
            <Col xs={6}>
              {!(
                state === CERTIFICATE_REQUEST_STATE.SUCCESSFUL ||
                state === CERTIFICATE_REQUEST_STATE.RESOLVED
              ) &&
                handler && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    <h3 className={classes.label}>In carico a</h3>
                    <h3
                      className={classes.assigneeValue}
                    >{`${handler.firstName} ${handler.lastName}`}</h3>
                  </div>
                )}
            </Col>
            <Col
              xs={3}
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              {canHandle && state === CERTIFICATE_REQUEST_STATE.FAILED && (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={onTakeCharge}
                  color="primary"
                >
                  <AddCircle
                    className={classnames(classes.leftIcon, classes.iconSmall)}
                  />
                  Prendi in carico
                </Button>
              )}
              {canHandle && state === CERTIFICATE_REQUEST_STATE.HANDLED && (
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={onSetResolved}
                  color="primary"
                >
                  <CheckCircle
                    className={classnames(classes.leftIcon, classes.iconSmall)}
                  />
                  Risolvi
                </Button>
              )}
            </Col>
          </Row>
          <Row className={classes.detailsContainer}>
            <Col className={classes.details} xs={12} noPadding>
              <LabelValue
                label="N.ro Protocollo"
                value={
                  details.protocol
                    ? String(details.protocol).padStart(6, '0')
                    : '-'
                }
                md={3}
                sm={3}
                xs={12}
              />
              <LabelValue
                label="Creata il"
                value={moment
                  .utc(details.createdAt)
                  .local()
                  .format('DD/MM/YYYY H:mm')}
                md={3}
                sm={3}
                xs={12}
              />
              <LabelValue
                label="Tipologia Certificato"
                value={getCertificateTypeLabel(type)}
                md={3}
                sm={3}
                xs={12}
              />
            </Col>
          </Row>
          {details.failureReasons && details.failureReasons.length > 0 && (
            <Row>
              <Col xs={12}>
                <div className={classes.label}>MOTIVI DEL FALLIMENTO</div>
                <ul>
                  {details.failureReasons.map(value => {
                    return <li key={value}>{value}</li>;
                  })}
                </ul>
              </Col>
            </Row>
          )}
        </Subsection>
      </div>
      <Loader loading={loading} />
      {!loading && user && <CitizenDetails user={user} editDisabled />}
    </>
  );
};

Detail.propTypes = {
  request: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
};

export default withStyles(styles)(Detail);
