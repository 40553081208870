import React, { FC } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#EAEAEA',
    cursor: 'pointer',
    padding: '36px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.30)',
    },
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '27px',
    color: '#A8B0B8',
  },
  icon: {
    marginTop: '12px',
    height: '28px',
    width: '28px',
    color: theme.palette.primary.main,
  },
});

interface AddFamiliarProps extends StyledComponentProps {
  handleClick: () => {};
}

const AddFamiliar: FC<AddFamiliarProps> = ({ handleClick, classes = {} }) => {
  return (
    <div className={classes.container} onClick={handleClick}>
      <label className={classes.text}>Aggiungi nuovo membro</label>
      <Add classes={{ root: classes.icon }} viewBox="0 0 24 24" />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedAddFamiliar = withStyles(styles)(AddFamiliar);

export { DecoratedAddFamiliar as AddFamiliar };
