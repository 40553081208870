import { withStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogContentText,
  Input,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import Loader from '../../common/Loader';
import { Col, Row } from 'react-flexbox-grid';
import FormControlValidated from '../../common/FormControlValidated';

const styles = theme => ({
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

export default withStyles(styles)(props => {
  const { classes, loading, open, confirmError } = props;

  const [reason, setReason] = useState(null);
  const [error, setError] = useState(undefined);

  const onConfirm = useCallback(() => {
    props.onConfirm(reason);
  }, [reason, props]);

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'Enter') {
        onConfirm();
      }
    },
    [onConfirm]
  );

  const onFieldChange = useCallback(
    event => {
      setReason(event.target.value);
      setError(false);
    },
    [setError]
  );

  const onClose = useCallback(() => {
    setReason(null);
    props.onClose();
  }, [props]);

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={onClose}
      aria-labelledby="rr-reject-dialog-title"
      aria-describedby="rr-reject-dialog-description"
    >
      <DialogTitle id="rr-reject-dialog-title">Scarta Richiesta</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row>
            <Col xs={12}>
              <DialogContentText>
                Vuoi aggiungere la motivazione dello scarto? <b>Attenzione: </b>{' '}
                qualora verrà specificata la motivazione, questa verrà
                comunicata via e-mail al cittadino.
              </DialogContentText>
            </Col>
            <Col xs={12} style={{ marginTop: '15px' }}>
              <FormControlValidated
                inputId="reason"
                label="Motivazione"
                error={error || confirmError}
              >
                <Input
                  placeholder="Inserisci una motivazione (Facoltativo), Max 255 caratteri"
                  onKeyPress={handleKeyPress}
                  id="reason"
                  type="text"
                  defaultValue={reason}
                  onChange={onFieldChange}
                  classes={{
                    inkbar:
                      error || confirmError
                        ? classes.inputInkbar
                        : classes.inputInkbarError,
                  }}
                  multiline
                  style={{
                    width: '100%',
                  }}
                  inputProps={{
                    rows: 5,
                    maxLength: 255,
                  }}
                />
              </FormControlValidated>
            </Col>
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={onConfirm} disabled={loading}>
            Conferma
          </Button>
          <Button onClick={onClose} disabled={loading}>
            Annulla
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
});
