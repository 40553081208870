import React, { useCallback, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import Subsection from '../../common/Subsection';
import { Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import ShareChip from './ShareChip';
import Widget from '../Share/Widget';
import EmptyListMessage from '../../common/EmptyListMessage';
import { INTERNAL_PROCEDURE_STATUS } from '../../../constants/common';

const styles = theme => ({
  container: {
    padding: 24,
  },
  header: {
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: 16,
    color: theme.palette.grey.dark,
  },
  headerRight: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  list: {
    margin: 0,
  },
  noPadding: {
    padding: 0,
  },
  shares: {
    padding: 0,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const SharesList = props => {
  const {
    classes,
    isOwner,
    procedure,
    onProcedureShared,
    onShareDelete,
  } = props;

  const onDelete = useCallback(
    share => {
      if (isOwner) onShareDelete(share);
    },
    [isOwner, onShareDelete]
  );

  const canShare = useMemo(() => {
    return isOwner && procedure.status !== INTERNAL_PROCEDURE_STATUS.CLOSED;
  }, [isOwner, procedure]);

  return (
    <div className={classes.container}>
      <Subsection noMargin>
        <Row className={classes.list}>
          <Col sm={10} xs={12} className={classes.shares}>
            <Col xs={12} className={classes.noPadding}>
              <div className={classes.header}>Firma richiesta a</div>
            </Col>
            <Col xs={12} className={classes.noPadding}>
              {procedure.shares.map((share, index) => (
                <ShareChip
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  status={share.signed_by ? 'done' : 'pending'}
                  department={share.department}
                  onDelete={canShare ? () => onDelete(share) : undefined}
                  deletable={canShare}
                />
              ))}
              <EmptyListMessage
                style={{ width: '100%' }}
                message="Non hai ancora condiviso questa pratica"
                visible={!procedure.shares.length}
              />
            </Col>
          </Col>
          <Col sm={2} xs={12} className={classes.action}>
            {canShare && (
              <Widget
                procedure={procedure}
                onProcedureShared={onProcedureShared}
                buttonProps={{
                  variant: 'outlined',
                  color: 'primary',
                  size: 'large',
                  withIcon: true,
                }}
              />
            )}
          </Col>
        </Row>
      </Subsection>
    </div>
  );
};

SharesList.propTypes = {
  isOwner: PropTypes.bool.isRequired,
  procedure: PropTypes.object.isRequired,
  onShareDelete: PropTypes.func.isRequired,
  onProcedureShared: PropTypes.func,
};

SharesList.defaultProps = {
  onProcedureShared: () => {},
};

export default withStyles(styles)(SharesList);
