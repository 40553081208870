import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useCallback,
  useState,
} from 'react';
import { Dialog as MaterialDialog } from '@material-ui/core';
import FormControlValidated from '../../common/FormControlValidated';
import {
  Input,
  StyledComponentProps,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import * as yup from 'yup';
import { setLocale, StringSchema } from 'yup';
import { it } from 'yup-locales';
import { FamilyMember } from '../api/model';
import { Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import moment, { Moment } from 'moment';
import { Select } from '@material-ui/core';
import { v4 } from 'uuid';
import CodiceFiscale from 'codice-fiscale-js';
import DateFnsUtils from '@date-io/moment';
import { FamilyMemberRelationshipDTO } from '../../../gen/api/welfare';

const styles = (theme: any) => ({
  dialog: {
    width: '634px',
  },
  container: {
    width: '100%',
    padding: '24px',
    boxSizing: 'border-box',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
  },
  row: {
    margin: '24px 0px',
  },
  rowError: {
    margin: '24px 0px -20px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    marginTop: '48px',
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

setLocale(it);
setLocale({
  mixed: {
    required: 'Questo campo è richiesto',
  },
});
export interface FamiliarDialogProps extends StyledComponentProps {
  onClose: () => void;
  open: boolean;
  defaultValues?: FamilyMember;
  onSave: (f: FamilyMember) => void;
  forbiddenCFValues?: string[];
}

function isValidCF(this: StringSchema): StringSchema {
  return this.test({
    name: 'cf',
    message: 'Il codice fiscale non è valido',
    test(value) {
      try {
        if (!value) {
          return false;
        }
        const cf = new CodiceFiscale(value);
        return cf.isValid();
      } catch (e) {
        return false;
      }
    },
  });
}
yup.addMethod(yup.string, 'cf', isValidCF);

const FamiliarSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  fiscalCode: yup
    .string()
    .required()
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    .cf(),
  birthDate: yup.date().required(),
  profession: yup.string(),
  relationship: yup
    .mixed<FamilyMemberRelationshipDTO>()
    .oneOf(Object.values(FamilyMemberRelationshipDTO))
    .required(),
});

const defaultForm = {
  firstName: '',
  lastName: '',
  fiscalCode: '',
  birthDate: moment().toDate(),
  relationship: FamilyMemberRelationshipDTO.Padre,
};

// eslint-disable-next-line complexity
const FamiliarDialog: FC<FamiliarDialogProps> = ({
  open,
  onClose,
  classes = {},
  forbiddenCFValues = [],
  defaultValues = defaultForm,
  onSave,
}) => {
  const [errors, setErrors] = useState<
    {
      [key in keyof FamilyMember]?: string;
    }
  >({});
  const [cfAlreadyInUse, setCfAlreadyInUse] = useState<boolean>(false);
  const [form, setForm] = useState<Partial<FamilyMember>>(defaultValues);
  const onBlur = ({ currentTarget }: FocusEvent<HTMLInputElement>) => {
    const name = currentTarget.name;
    const value = currentTarget.value;

    FamiliarSchema.validateAt(name, { [name]: value })
      .then(() => {
        setErrors(errs => ({ ...errs, [name]: undefined }));
      })
      .catch(e => setErrors(errs => ({ ...errs, [name]: e.errors[0] })));
  };

  const onChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      if (name === 'fiscalCode') {
        setCfAlreadyInUse(forbiddenCFValues?.includes(value));
        try {
          const cf = new CodiceFiscale(value);
          setForm(oldForm => ({
            ...oldForm,
            fiscalCode: value,
            birthDate: cf.isValid() ? cf.birthday! : moment().toDate(),
          }));
        } catch (e) {
          setForm(oldForm => ({
            ...oldForm,
            fiscalCode: value,
          }));
        }
      }
      setForm(oldForm => ({
        ...oldForm,
        [name]: value,
      }));
    },
    [forbiddenCFValues]
  );

  const dateChange = useCallback(
    (date: Date | Moment) => {
      setForm(f => ({
        ...f,
        birthDate: 'toDate' in date ? date.toDate() : date,
      }));
    },
    [setForm]
  );

  const save = useCallback(() => {
    FamiliarSchema.validate(form, { abortEarly: false })
      .then(form => {
        if (!cfAlreadyInUse) {
          onSave({ ...form, id: v4() });
        }
        setErrors({});
        setForm(defaultForm);
      })
      .catch(errs => {
        setErrors(
          errs.inner.reduce(
            (acc: { [key in keyof FamilyMember]?: string }, val: any) => {
              return { ...acc, [val.path]: val.message };
            },
            {}
          )
        );
      });
  }, [cfAlreadyInUse, form, onSave]);

  const cleanAndClose = useCallback(() => {
    setForm(defaultValues);
    setCfAlreadyInUse(false);
    setErrors({});
    onClose();
  }, [defaultValues, onClose]);
  return (
    <MaterialDialog
      open={open}
      onClose={cleanAndClose}
      aria-labelledby="document-upload-dialog-title"
      aria-describedby="document-upload-dialog-description"
      classes={{
        paper: classes.dialog,
      }}
    >
      <div className={classes.container}>
        <label className={classes.title}>
          Inserisci le informazioni del membro
        </label>
        <Row
          className={
            errors.firstName || errors.lastName ? classes.rowError : classes.row
          }
        >
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="firstName"
              label="Nome"
              error={errors.firstName}
              shrinkLabel
            >
              <Input
                id="firstName"
                name="firstName"
                inputProps={{
                  onBlur: onBlur,
                  tabIndex: 1,
                }}
                type="text"
                onChange={onChange}
                defaultValue={form.firstName}
                classes={
                  {
                    inkbar: !errors.firstName
                      ? classes.inputInkbar
                      : classes.inputInkbarError,
                  } as Record<any, any>
                }
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="lastName"
              label="Cognome"
              error={errors.lastName}
              shrinkLabel
            >
              <Input
                id="lastName"
                name="lastName"
                inputProps={{
                  onBlur: onBlur,
                  tabIndex: 1,
                }}
                type="text"
                onChange={onChange}
                defaultValue={form.lastName}
                classes={
                  {
                    inkbar: !errors.lastName
                      ? classes.inputInkbar
                      : classes.inputInkbarError,
                  } as Record<any, any>
                }
              />
            </FormControlValidated>
          </Col>
        </Row>
        <Row
          className={
            errors.fiscalCode || errors.birthDate
              ? classes.rowError
              : classes.row
          }
        >
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="fiscalCode"
              label="Codice Fiscale"
              error={
                cfAlreadyInUse
                  ? 'Il codice fiscale è già utilizzato'
                  : errors.fiscalCode
              }
              shrinkLabel
            >
              <Input
                id="fiscalCode"
                name="fiscalCode"
                inputProps={{
                  onBlur: onBlur,
                  tabIndex: 1,
                }}
                type="text"
                onChange={onChange}
                defaultValue={form.fiscalCode}
                classes={
                  {
                    inkbar: !errors.fiscalCode
                      ? classes.inputInkbar
                      : classes.inputInkbarError,
                  } as Record<any, any>
                }
              />
            </FormControlValidated>
          </Col>
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="birthDate"
              label="Data di nascita"
              error={errors.birthDate}
              shrinkLabel
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  style={{
                    width: '100%',
                  }}
                  label="Data di nascita"
                  name="birthday"
                  disabled
                  autoOk
                  /* eslint-disable-next-line react/jsx-no-bind */
                  labelFunc={date =>
                    date
                      ? moment(date)
                          .locale('it')
                          .format('DD/MM/YYYY')
                      : ''
                  }
                  value={form.birthDate}
                  onChange={dateChange}
                  placeholder="es. 01/01/1990"
                  format="DD/MM/YYYY"
                  defaultValue={undefined}
                  minDate={moment()
                    .subtract('120', 'years')
                    .toDate()}
                  disableFuture
                  animateYearScrolling
                  cancelLabel="Annulla"
                />
              </MuiPickersUtilsProvider>
            </FormControlValidated>
          </Col>
        </Row>
        <Row
          className={
            errors.profession || errors.relationship
              ? classes.rowError
              : classes.row
          }
        >
          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="profession"
              label="Professione"
              error={errors.profession}
              shrinkLabel
            >
              <Input
                id="profession"
                name="profession"
                inputProps={{
                  onBlur: onBlur,
                  tabIndex: 1,
                }}
                type="text"
                onChange={onChange}
                defaultValue={form.profession}
                classes={
                  {
                    inkbar: !errors.profession
                      ? classes.inputInkbar
                      : classes.inputInkbarError,
                  } as Record<any, any>
                }
              />
            </FormControlValidated>
          </Col>

          <Col xs={12} sm={6}>
            <FormControlValidated
              inputId="relationship"
              label="Parentela"
              error={errors.relationship}
              shrinkLabel
            >
              <Select
                name="relationship"
                value={form.relationship}
                onChange={onChange}
                fullWidth={false}
                inputProps={{
                  tabIndex: 6,
                }}
              >
                {Object.values(FamilyMemberRelationshipDTO).map(fam => (
                  <MenuItem key={fam} value={fam}>
                    {fam}
                  </MenuItem>
                ))}
              </Select>
            </FormControlValidated>
          </Col>
        </Row>
        <div className={classes.actionContainer}>
          <Button variant="outlined" color="primary" onClick={cleanAndClose}>
            Annulla
          </Button>
          <Button variant="raised" onClick={save} color="primary">
            Aggiungi membro
          </Button>
        </div>
      </div>
    </MaterialDialog>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedFamiliarDialog = withStyles(styles)(FamiliarDialog);

export { DecoratedFamiliarDialog as FamiliarDialog };
