import React from 'react';
import { Chip, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    marginRight: '5px',
    marginBottom: '5px',
    position: 'relative',
    backgroundColor: '#E6E9F2',
    color: theme.palette.primary.dark,
    fontWeight: 'normal',
    height: 34,
    '&:focus': {
      backgroundColor: '#E6E9F2',
    },
  },
  deleteIcon: {
    color: theme.palette.primary.dark,
    opacity: 0.8,
    '&:hover': {
      color: theme.palette.primary.dark,
      opacity: 1,
    },
  },
});

const FilterChip = props => {
  const { classes } = props;

  return <Chip classes={classes} color="secondary" {...props} />;
};

export default withStyles(styles)(FilterChip);
