import React, { FC, useCallback, useState } from 'react';
import {
  ExpenseTypeDTO,
  UndeductedExpenseDTO,
  UndeductedExpenseRequest,
} from '../../../../gen/api/welfare';
import { Button, StyledComponentProps } from '@material-ui/core';
import { TemporaryDocument } from '../../api/model';
import { SectionTitle } from '../SectionTitle';
import { ExpensesFlag } from '../ExpensesFlag';
import { ChevronLeft } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  mainContainer: {
    flex: 1,
    width: '40%',
    padding: '16px 48px',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    width: '496px',
    marginTop: '64px',
  },
  footer: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    width: '100%',
    alignSelf: 'stretch',
    padding: '24px 24px 48px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

interface SelectExpensesFormProps extends StyledComponentProps {
  initialExpenses?: UndeductedExpenseDTO[];
  onConfirm: (expenses: UndeductedExpenseRequest[]) => void;
  onAbort: () => void;
  title?: string;
  abortText?: string;
  confirmText?: string;
  skipText?: string;
}
const SelectExpensesForm: FC<SelectExpensesFormProps> = ({
  classes = {},
  initialExpenses = [],
  onConfirm,
  onAbort,
  title = 'Inserisci delle eventuali spese non detratte',
  abortText = 'Indietro',
  confirmText = 'Conferma i dati e prosegui',
  skipText = 'Non ha altre spese',
}) => {
  const [selectedExpense, setSelectedExpense] = useState<
    Partial<Record<ExpenseTypeDTO, { value: string; doc?: TemporaryDocument }>>
  >(
    initialExpenses.reduce((acc, curr) => {
      const newValue = {
        ...acc,
        [curr.type]: {
          value: curr.value,
          doc: curr.document ?? undefined,
        },
      };
      return newValue;
    }, {})
  );

  const [errors, setErrors] = useState<Partial<Record<ExpenseTypeDTO, string>>>(
    {}
  );

  const handleChange = useCallback(
    (ben: ExpenseTypeDTO, val?: string | null) => {
      setSelectedExpense(old => {
        if (val !== undefined) {
          return {
            ...old,
            [ben]: { ...(old[ben] ?? {}), value: val },
          };
        }
        return {
          ...old,
          [ben]: undefined,
        };
      });
      if (val !== null && errors[ben]) {
        setErrors(er => ({
          ...er,
          [ben]: undefined,
        }));
      }
    },
    [errors]
  );

  const removeDocument = useCallback((exp: ExpenseTypeDTO) => {
    setSelectedExpense(oldExp => ({
      ...oldExp,
      [exp]: {
        value: oldExp[exp]!.value,
        doc: undefined,
      },
    }));
  }, []);

  const addDocument = useCallback(
    (exp: ExpenseTypeDTO, doc: TemporaryDocument) => {
      setSelectedExpense(oldExp => ({
        ...oldExp,
        [exp]: {
          value: oldExp[exp]!.value,
          doc,
        },
      }));
    },
    []
  );

  const goOn = useCallback(() => {
    const errors = Object.entries(selectedExpense).reduce((prev, act) => {
      if (act[1]?.value === null) {
        return {
          ...prev,
          [act[0]]: 'Il campo è richiesto',
        };
      }
      return prev;
    }, {});
    if (Object.values(errors).filter(o => !!o).length > 0) {
      setErrors(errors);
    } else {
      const expenses = Object.entries(selectedExpense)
        .filter(([_, value]) => value !== undefined)
        .map(([type, value]) => ({
          type: type as ExpenseTypeDTO,
          value: value!.value,
          document: value!.doc,
        }));
      onConfirm(expenses);
    }
  }, [onConfirm, selectedExpense]);

  const goBack = useCallback(() => {
    onAbort();
  }, [onAbort]);

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <div className={classes.container}>
        {Object.values(ExpenseTypeDTO).map(exp => (
          <ExpensesFlag
            key={exp}
            value={selectedExpense[exp]}
            type={exp}
            handleChange={handleChange}
            error={errors[exp] ?? undefined}
            addDocument={addDocument}
            removeDocument={removeDocument}
          />
        ))}
      </div>
      <div className={classes.footer}>
        <Button variant="flat" color="primary" onClick={goBack}>
          <ChevronLeft />
          {abortText}
        </Button>
        {Object.values(selectedExpense).filter(val => val !== undefined)
          .length < 1 ? (
          <Button variant="outlined" color="primary" onClick={goOn}>
            {skipText}
          </Button>
        ) : (
          <Button variant="raised" color="primary" onClick={goOn}>
            {confirmText}
          </Button>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSelectExpensesForm = withStyles(styles)(SelectExpensesForm);

export { DecoratedSelectExpensesForm as SelectExpensesForm };
