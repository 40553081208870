import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';

const formControlStyle = theme => ({
  formControl: {
    width: '100%',
    margin: '10px 0px',
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
});

const FormControlValidated = props => {
  const { classes, error, label, shrinkLabel, inputId } = props;
  const fieldHasError = error != null;
  const fieldHasLabel = label != null;
  return (
    <FormControl className={classes.formControl} error={fieldHasError}>
      {fieldHasLabel && (
        <InputLabel
          FormControlClasses={{
            focused: classes.inputLabelFocused,
          }}
          shrink={shrinkLabel}
          htmlFor={inputId}
        >
          {label}
        </InputLabel>
      )}
      {props.children}
      {fieldHasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

FormControlValidated.defaultProps = {
  shrinkLabel: PropTypes.false,
};

FormControlValidated.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  shrinkLabel: PropTypes.bool,
};

export default withStyles(formControlStyle)(FormControlValidated);
