import React from 'react';
import Pratiche from '../../api/PraticheGate';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import Loader from '../common/Loader';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import store from '../../store';
import Procedure from '../common/Procedure';
import { isProcedureOwner } from '../../constants/common';
import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_HUMAN,
} from '../common/filters/constants';
import ROUTES from '../../constants/routes';

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingDocuments: false,
      loadingEvents: false,
      uploadingDocument: false,
      procedure: null,
      documents: [],
      events: [],
      paginationEvents: {},
      downloadingDocument: null,
    };
  }

  componentDidMount() {
    this.loadProcedure(this.props.match.params.procedureId);
  }

  componentWillReceiveProps(nextProps, _nextContext) {
    if (
      nextProps.match.params.procedureId !== this.props.match.params.procedureId
    ) {
      this.loadProcedure(nextProps.match.params.procedureId);
    }
  }

  loadProcedure = procedureId => {
    this.setState(
      {
        loading: true,
      },
      () => {
        Pratiche.get(
          procedureId,
          procedure => {
            this.setState({
              loading: false,
              procedure,
            });
            this.loadDocuments(procedureId);
            if (isProcedureOwner(procedure.type)) this.loadEvents(procedureId);
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      }
    );
  };

  loadDocuments = procedureId => {
    this.setState(
      {
        loadingDocuments: true,
      },
      () => {
        Pratiche.getDocuments(
          procedureId,
          documents => {
            this.setState({
              loadingDocuments: false,
              documents,
            });
          },
          () => {
            this.setState({
              loadingDocuments: false,
              documents: [],
            });
          }
        );
      }
    );
  };

  loadEvents = (pageNumber = { page: 1 }) => {
    const procedureId = this.props.match.params.procedureId;
    this.setState(
      {
        loadingEvents: true,
      },
      () => {
        Pratiche.getEvents(
          procedureId,
          pageNumber,
          response => {
            this.setState({
              loadingEvents: false,
              paginationEvents: response.meta.pagination,
              events: response.events,
            });
          },
          () => {
            this.setState({
              loadingEvents: false,
              paginationEvents: {},
              events: [],
            });
          }
        );
      }
    );
  };

  onDocumentClick = documentId => {
    this.setState(
      {
        downloadingDocument: documentId,
      },
      () => {
        Pratiche.downloadDocument(
          this.state.procedure.id,
          documentId,
          () => {
            this.setState({
              downloadingDocument: null,
            });
          },
          () => {
            this.setState({
              downloadingDocument: null,
            });
            store.app.snackBar.open('Errore nel download del file.');
          }
        );
      }
    );
  };

  uploadDocument = event => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      store.app.snackBar.open(
        `Il file selezionato è troppo grande (Max ${MAX_FILE_SIZE_HUMAN} MB)`
      );
      return;
    }
    this.setState(
      {
        uploadingDocument: true,
      },
      () =>
        Pratiche.uploadDocument(
          this.state.procedure.id,
          file,
          documents => {
            this.setState({
              uploadingDocument: false,
              documents,
            });
            store.app.snackBar.open('File caricato con successo!');
          },
          () => {
            this.setState({
              uploadingDocument: false,
            });
            store.app.snackBar.open(
              "Errore nell'upload del file, riprova in seguito."
            );
          }
        )
    );
  };

  onUploadClicked = instance => () => {
    instance._fileInput.click();
  };

  goToUserDetails = user => {
    this.props.history.push(`/${ROUTES.UTENTI}/${user.id}`);
  };

  render() {
    const {
      procedure,
      loading,
      loadingDocuments,
      documents,
      downloadingDocument,
      loadingEvents,
      events,
      paginationEvents,
      uploadingDocument,
    } = this.state;
    const isOwner = procedure && isProcedureOwner(procedure.type);
    const instance = this;

    return (
      <PageContainer
        header={
          <PageTitle
            title="Dettaglio Pratica"
            subtitle={
              procedure ? (
                <span>{`Pratica n° ${procedure.full_number}`}</span>
              ) : null
            }
            backIcon={true}
          />
        }
      >
        <Loader size={40} loading={loading} />
        <input
          multiple={false}
          onChange={this.uploadDocument}
          name="file"
          ref={node => (instance._fileInput = node)}
          type="file"
          hidden
        />
        {procedure && !loading && (
          <Procedure
            procedure={procedure}
            loadingDocuments={loadingDocuments}
            documents={documents}
            uploadingDocument={uploadingDocument}
            downloadingDocument={downloadingDocument}
            onDocumentClick={this.onDocumentClick}
            loadEvents={this.loadEvents}
            loadingEvents={loadingEvents}
            events={events}
            paginationEvents={paginationEvents}
            doProcedureAction={this.doProcedureAction}
            onUploadClicked={this.onUploadClicked(instance)}
            goToUserDetails={this.goToUserDetails}
            isOwner={isOwner}
          />
        )}
      </PageContainer>
    );
  }
}

export default observer(withRouter(Detail));
