import React, { FC } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import '../../common/animation.css';
import PropTypes from 'prop-types';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: any): StyleRules => ({
  container: {
    padding: '20px 16px',
    boxSizing: 'border-box',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.font.greyLight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    animationName: 'slidein',
    animationDuration: '0.5s',
    '&:hover': {
      backgroundColor: theme.palette.grey.light,
    },
    width: '100%',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.grey.dark,
    flex: 2,
    flexWrap: 'wrap',
  },
  img: {
    height: '24px',
    width: '24px',
  },
});

interface RadioButtonProps<T extends string> extends StyledComponentProps {
  text: string;
  onClick: () => void;
  selected?: boolean;
}

const RadioButton: FC<RadioButtonProps<any>> = ({
  onClick,
  text,
  classes,
  selected = false,
}) => {
  return (
    <div className={classes?.container} onClick={onClick}>
      <label className={classes?.text}>{text}</label>
      {selected ? (
        <img
          src={require('../../../../assets/icons/radio-active.png')}
          className={classes?.img}
          alt="active"
        />
      ) : (
        <img
          src={require('../../../../assets/icons/radio-inactive.png')}
          className={classes?.img}
          alt="inactive"
        />
      )}
    </div>
  );
};

RadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

const DecoratedRadioButton = withStyles(styles)(RadioButton);
export { DecoratedRadioButton as SimplerRadioButton };
