import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import {
  getAbstractProcedureLink,
  getFullProcedureType,
} from '../../constants/common';

class ProcedureAdditionalDocumentsRequested extends React.Component {
  onClick = () => {
    const procedure = this.props.event.payload.procedure;
    this.props.history.push(
      getAbstractProcedureLink(procedure.type, procedure.id)
    );
  };

  render() {
    const { event } = this.props;
    const procedure = event.payload.procedure;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onClick}>
              Pratica n° {procedure.number}
            </TextLinkPrimary>
            <br />
            <b>Tipo Pratica: </b>{' '}
            {getFullProcedureType(procedure.type, procedure.category)}
            <br />
            <b>Richiesto da: </b>{' '}
            {event.payload.performed_by
              ? event.payload.performed_by.full_name
              : 'N/A'}
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(ProcedureAdditionalDocumentsRequested));
