import { createAction } from '@reduxjs/toolkit';
import {
  Citizen,
  CitizenContext,
  FamilyMember,
  TemporaryDocument,
} from '../../components/serviziSociali/api/model';
import {
  BenefitDTO,
  UndeductedExpenseDTO,
  WelfareRequestorTypeDTO,
} from '../../gen/api/welfare';

export enum NewSocialServiceEvents {
  userSelectKind = 'events/userSelectKind',
  userSelectSection = 'events/userSelectSection',

  userSelectRequestor = 'events/userSelectRequestor',
  userSelectRequestorType = 'events/userSelectRequestorType',
  beneficiaryKnown = 'events/beneficiaryKnown',
  beneficiaryNeeded = 'events/beneficiaryNeeded',
  userSelectBeneficiary = 'events/userSelectBeneficiary',
  needsPersonalSituation = 'events/needsPersonalSituation',
  skipPersonalSituation = 'events/skipPersonalSituation',
  userSelectPersonalSituation = 'events/userSelectPersonalSituation',
  userSelectFamilyMembers = 'events/userSelectFamilyMembers',
  userSelectRelativeMembers = 'events/userSelectRelativeMembers',
  userSkipStep = 'events/userSkipStep',
  userLoadIsee = 'events/userLoadIsee',
  userSelectOtherBenefits = 'events/userSelectOtherBenefits',
  userSelectExpenses = 'events/userSelectExpenses',
  procedureCreated = 'events/procedureCreated',
  errorCreating = 'events/errorCreating',
}

export const userSelectKind = createAction<{ kind: string }>(
  NewSocialServiceEvents.userSelectKind
);

export type UserSelectKindType = ReturnType<typeof userSelectKind>;

export const userSelectSection = createAction<string>(
  NewSocialServiceEvents.userSelectSection
);

export type UserSelectSectionType = ReturnType<typeof userSelectSection>;

export const userSelectRequestor = createAction<Citizen>(
  NewSocialServiceEvents.userSelectRequestor
);

export type UserSelectRequestorType = ReturnType<typeof userSelectRequestor>;

export const userSelectRequestorType = createAction(
  NewSocialServiceEvents.userSelectRequestorType,
  (type: WelfareRequestorTypeDTO, documents?: TemporaryDocument[]) => ({
    payload: {
      type: type,
      documents: documents ?? [],
    },
  })
);

export type UserSelectRequestorTypeType = ReturnType<
  typeof userSelectRequestorType
>;

export const beneficiaryKnown = createAction(
  NewSocialServiceEvents.beneficiaryKnown
);

export const beneficairyNeeded = createAction(
  NewSocialServiceEvents.beneficiaryNeeded
);

export const userSelectBeneficiary = createAction<Citizen>(
  NewSocialServiceEvents.userSelectBeneficiary
);

export type UserSelectBeneficiaryType = ReturnType<
  typeof userSelectBeneficiary
>;

export const userSelectFamilyMembers = createAction<FamilyMember[]>(
  NewSocialServiceEvents.userSelectFamilyMembers
);

export type UserSelectFamilyMembersType = ReturnType<
  typeof userSelectFamilyMembers
>;

export const userSelectRelativeMembers = createAction<FamilyMember[]>(
  NewSocialServiceEvents.userSelectRelativeMembers
);

export type UserSelectRelativeMembersType = ReturnType<
  typeof userSelectRelativeMembers
>;

export const userSkipStep = createAction(NewSocialServiceEvents.userSkipStep);

export const userLoadIsee = createAction<{
  value: string;
  document: TemporaryDocument;
}>(NewSocialServiceEvents.userLoadIsee);

export type UserLoadIseeType = ReturnType<typeof userLoadIsee>;

export const skipPersonalSituation = createAction<Citizen>(
  NewSocialServiceEvents.skipPersonalSituation
);

export type SkipPersonalSituationType = ReturnType<
  typeof skipPersonalSituation
>;

export const needsPersonalSituation = createAction<Citizen>(
  NewSocialServiceEvents.needsPersonalSituation
);

export type NeedsPersonalSituationType = ReturnType<
  typeof needsPersonalSituation
>;

export const userSelectPersonalSituation = createAction<
  CitizenContext['flags']
>(NewSocialServiceEvents.userSelectPersonalSituation);

export type UserSelectPersonalSituation = ReturnType<
  typeof userSelectPersonalSituation
>;

export const userSelectOtherBenefits = createAction<BenefitDTO[]>(
  NewSocialServiceEvents.userSelectOtherBenefits
);

export type UserSelectOtherBenefitsType = ReturnType<
  typeof userSelectOtherBenefits
>;

export const userSelectExpenses = createAction<UndeductedExpenseDTO[]>(
  NewSocialServiceEvents.userSelectExpenses
);

export type UserSelectExpensesType = ReturnType<typeof userSelectExpenses>;

export const procedureCreated = createAction(
  NewSocialServiceEvents.procedureCreated
);

export const errorCreating = createAction(NewSocialServiceEvents.errorCreating);
