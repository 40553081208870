import { withStyles } from '@material-ui/core';
import React from 'react';

const styles = theme => ({
  praticaNumberContainer: {
    width: '200px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    backgroundColor: theme.palette.primary.main,
    marginRight: '20px',
    borderRadius: '2px',
    padding: '0 15px',
  },
  praticaNumberElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  praticaNumberDivider: {
    height: '60%',
    borderRight: '1px solid #FFF',
    opacity: '.5',
    marginRight: '10px',
    marginLeft: '10px',
  },
  praticaNumberHeader: {
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
    marginBottom: '5px',
  },
});

export const PraticaNumber = withStyles(styles)(props => {
  const { classes, number, year, sub } = props;

  return (
    <div className={classes.praticaNumberContainer}>
      <div className={classes.praticaNumberElement}>
        <div className={classes.praticaNumberHeader}>ANNO</div>
        <div>{year}</div>
      </div>
      <div className={classes.praticaNumberDivider} />
      <div className={classes.praticaNumberElement}>
        <div className={classes.praticaNumberHeader}>NUMERO</div>
        <div>{number ? number : '-'}</div>
      </div>
      <div className={classes.praticaNumberDivider} />
      <div className={classes.praticaNumberElement}>
        <div className={classes.praticaNumberHeader}>SUB</div>
        <div>{sub ? sub : '-'}</div>
      </div>
    </div>
  );
});
