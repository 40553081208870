import React from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import ConfirmButton from '../common/ConfirmButton';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import Loader from '../common/Loader';
import ApiUtenti from '../../api/Utenti';
import store from '../../store';
import { Button } from '@material-ui/core';
import ROUTES from '../../constants/routes';
import PrettySection from '../common/PrettySection';
import { Check as SuccessIcon } from '@material-ui/icons';
import {
  HorizontalLabelValue,
  HorizontalList,
} from '../common/HorizontalLabelValue';
import Detail from '../cittadini/Detail';
import { Col, Row } from 'react-flexbox-grid';
import { ErrorOutline } from '@material-ui/icons';
import history from '../../utils/history';
import DetailElement from '../common/DetailElement';
import { getDepartmentLabel, getRoleLabel } from '../../constants/common';
import { DEPARTMENTS } from '../../constants/security';

const styles = theme => ({
  spidConnected: {
    color: '#28a745',
    fontWeight: '600',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  spidConnectedIcon: {
    marginRight: '10px',
    fontSize: '12px',
    fontWeigth: 'bold',
  },
  errorPageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '150px',
    justifyContent: 'center',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px',
    color: theme.palette.primary.main,
  },
  giantIcon: {
    fontSize: '80px',
    marginBottom: '20px',
  },
  shoutText: {
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: '10px',
  },
  shoutTextSubtitle: {
    fontWeight: '300',
    fontStyle: 'italic',
    fontSize: '18px',
    marginBottom: '30px',
  },
  roleListHeader: {
    width: '100%',
    padding: 10,
    fontWeight: 'bold',
    marginTop: 20,
    fontSize: '18px',
    lineHeight: '27px',
  },
  roleList: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
    width: '100%',
  },
});

class DetailUtente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      utente: null,
      loadingUtente: false,
      errors: {},
    };
  }

  componentDidMount() {
    const idUtente = this.props.match.params.idUtente;
    this.setState({ loadingUtente: true, loadingGruppi: true }, () => {
      ApiUtenti.get(
        idUtente,
        data => {
          this.setState({
            loadingUtente: false,
            utente: data,
          });
        },
        () => {
          this.setState({ loadingUtente: false });
        }
      );
    });
  }

  deleteUtente = () => {
    const utente = this.state.utente;
    const profile = store.app.profile;
    ApiUtenti.delete(
      {
        id: utente.id,
        ...(!profile.isMemberOf(DEPARTMENTS.ADMIN, true)
          ? { department: profile.operatingAs.department }
          : {}),
      },
      () => {
        store.app.snackBar.open('Utente eliminato.');
        this.props.history.push(`/${ROUTES.UTENTI}`);
      },
      () => {
        store.app.snackBar.open("Errore: impossibile eliminare l'utente.");
      }
    );
  };

  resendLink = () => {
    ApiUtenti.resendLink(
      this.state.utente.id,
      () => {
        store.app.snackBar.open('Invito inviato con successo');
      },
      () => {
        store.app.snackBar.open(
          'Impossibile effettuare il reinvio: Si è verificato un errore imprevisto.'
        );
      }
    );
  };

  onBack = () => {
    history.goBack();
  };

  render() {
    const { classes } = this.props;
    const { utente, loadingUtente } = this.state;
    const loading = loadingUtente;

    return (
      <PageContainer
        header={
          <PageTitle
            title="Dettagli Utente"
            subtitle={utente ? `${utente.first_name} ${utente.last_name}` : '-'}
            actions={
              utente ? (
                <UpdateActions
                  utente={utente}
                  deleteUtente={this.deleteUtente}
                  resendLink={this.resendLink}
                />
              ) : null
            }
            backIcon={true}
          />
        }
      >
        <Loader size={40} loading={loading} />
        {utente && !loading && (
          <div>
            <Row>
              <PrettySection
                title="Informazioni Account"
                icon="nc-circle-09"
                headerActions={
                  !utente.spid_connected
                    ? []
                    : [
                        <div key="spid" className={classes.spidConnected}>
                          <SuccessIcon className={classes.spidConnectedIcon} />
                          <span>Collegato con SPID</span>
                        </div>,
                      ]
                }
              >
                <HorizontalList>
                  <HorizontalLabelValue
                    md={4}
                    sm={6}
                    label="Email"
                    value={utente.email}
                  />
                  <HorizontalLabelValue
                    md={4}
                    sm={6}
                    label="Data Iscrizione"
                    value={utente.created_at}
                  />
                </HorizontalList>
                {utente.roles.length > 0 && (
                  <>
                    <div className={classes.roleListHeader}>
                      Ruoli e Dipartimenti
                    </div>
                    <ul className={classes.roleList}>
                      {utente.roles.map((r, i) => (
                        <DetailElement
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={i}
                          xs={12}
                          label={getDepartmentLabel(r.department)}
                          value={getRoleLabel(r.role, r.department)}
                          separator={i < utente.roles.length - 1}
                        />
                      ))}
                    </ul>
                  </>
                )}
              </PrettySection>
              {utente.person && (
                <Detail
                  title="Informazioni Anagrafiche"
                  editDisabled={true}
                  user={utente}
                />
              )}
            </Row>
          </div>
        )}
        {!utente && !loading && (
          <div>
            <Row>
              <Col xs={12} className={classes.errorPageContainer}>
                <div className={classes.errorContainer}>
                  <ErrorOutline className={classes.giantIcon} fontSize={80} />
                  <span className={classes.shoutText}>Utente Eliminato</span>
                  <span className={classes.shoutTextSubtitle}>
                    L&apos;utente richiesto è stato eliminato
                  </span>
                  <Button
                    onClick={this.onBack}
                    variant="raised"
                    color="primary"
                  >
                    Torna indietro
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </PageContainer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  observer(withRouter(DetailUtente))
);

const UpdateActions = observer(
  withRouter(props => {
    const profile = store.app.profile;
    const canEdit =
      profile.ownsUser(props.utente.roles) ||
      profile.is(props.utente) ||
      profile.isMemberOf(DEPARTMENTS.CED);
    const canDelete =
      profile.ownsUser(props.utente.roles) && !profile.is(props.utente);

    return (
      <span>
        {canEdit && (
          <Button
            size="small"
            variant="raised"
            color="primary"
            disabled={props.utente.status === 'unactive'}
            onClick={() => {
              props.history.push(`/${ROUTES.UTENTI}/${props.utente.id}/edit`);
            }}
          >
            Modifica Utente
          </Button>
        )}
        {canEdit && props.utente.status === 'unactive' && (
          <Button
            size="small"
            variant="flat"
            color="default"
            onClick={props.resendLink}
          >
            Re-Invia Link
          </Button>
        )}
        {canDelete && (
          <>
            &nbsp;&nbsp;
            <ConfirmButton
              buttonType="error"
              buttonProps={{
                size: 'small',
                variant: 'raised',
                color: 'error',
              }}
              confirmMessage="Conferma eliminazione"
              text="Questa azione non è reversibile, vuoi procedere?"
              buttonLabel="Elimina Utente"
              onConfirm={props.deleteUtente}
            />
          </>
        )}
      </span>
    );
  })
);
