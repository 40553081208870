import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import EventList from './Tabs/AllEvents';
import ROUTES from '../../constants/routes';

const styles = () => ({
  tabs: {
    marginTop: '30px',
    lineHeight: '1.5',
  },
});

class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  goToUserDetail = user => {
    this.props.history.push(`/${ROUTES.UTENTI}/${user.id}`);
  };

  goToSessionDetail = sessionId => {
    this.props.history.push(`/${ROUTES.SESSIONI}/${sessionId}`);
  };

  render() {
    return (
      <PageContainer
        whiteBg
        header={
          <PageTitle
            title="Audit"
            subtitle="Monitoraggio delle attività"
            backIcon={true}
          />
        }
      >
        <EventList
          goToUserDetails={this.goToUserDetail}
          goToSessionDetails={this.goToSessionDetail}
        />
      </PageContainer>
    );
  }
}

export default withStyles(styles)(observer(Audit));
