import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Loader from '../../common/Loader';
import EmptyListMessage from '../../common/EmptyListMessage';
import ListItem from './ListItem';
import Pagination from '../../common/Pagination';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../../constants/routes';

export const Mode = {
  SHARED_BY_ME: 'sharedByMe',
  SHARED_WITH_ME: 'sharedWithMe',
  ARCHIVED: 'archived',
};

const List = withRouter(props => {
  const {
    procedures,
    pagination,
    onPageChange,
    onProcedureShared,
    loading,
    history,
  } = props;

  const onProcedureSelected = useCallback(
    procedure => {
      history.push(`/${ROUTES.SCRIVANIA_VIRTUALE}/${procedure.id}`);
    },
    [history]
  );

  return (
    <Row>
      <Col xs={12}>
        <Loader size={40} loading={loading} />
        <EmptyListMessage visible={procedures.length === 0 && !loading} />
        {procedures.length > 0 &&
          !loading &&
          procedures.map((p, i) => (
            <ListItem
              /* eslint-disable-next-line react/no-array-index-key */
              key={i}
              procedure={p}
              onDetailsClick={onProcedureSelected}
              onProcedureShared={onProcedureShared}
            />
          ))}
        {pagination && (
          <Row>
            <Col xs={12} sm={12}>
              <Pagination
                total={pagination.total_pages}
                current={pagination.current_page}
                display={3}
                onChange={onPageChange}
                totalElement={pagination.total}
                elementNumber={pagination.count}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
});

List.propTypes = {
  procedures: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onProcedureShared: PropTypes.func.isRequired,
};

export default List;
