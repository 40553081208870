import {
  NewSocialServiceStates,
  useNewSocialService,
} from './newSocialService.stm';
import { useMemo } from 'react';

export const useIsValidStep = () => {
  const { state } = useNewSocialService();

  const isValidStep = useMemo(() => {
    switch (state.state) {
      case NewSocialServiceStates.selectingKind: {
        return state.context?.actualForm?.kind;
      }
      default: {
        return false;
      }
    }
  }, [state.context, state.state]);

  return isValidStep;
};
