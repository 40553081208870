import React, { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  withStyles,
} from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { HorizontalLabelValue, HorizontalList } from './HorizontalLabelValue';
import {
  getDocumentTypeLabel,
  getLabelBusinessTypePerson,
} from '../../constants/common';
import PersonCard from './PersonCard';

const styles = theme => ({
  container: {
    fontFamily: theme.typography.fontFamily,
  },
});

export default withStyles(styles)(props => {
  const { classes, person, onClose, isLegacy = false } = props;

  const [subPerson, setSubPerson] = useState(null);

  const name = useMemo(() => {
    if (person && !isLegacy) {
      return person.type === 'business' ? person.name : person.full_name;
    } else if (person && isLegacy) {
      return person.full_name;
    }

    return null;
  }, [person, isLegacy]);

  const personType = useMemo(() => {
    if (person && isLegacy)
      return person.gender === 'G' ? 'business' : 'person';
    if (person && !isLegacy) return person.type;

    return null;
  }, [person, isLegacy]);

  const onBack = useCallback(() => {
    setSubPerson(null);
  }, [setSubPerson]);

  const subPersonClicked = useCallback(
    person => {
      setSubPerson(person);
    },
    [setSubPerson]
  );

  return (
    <Dialog
      maxWidth="md"
      className={classes.container}
      open={person}
      onClose={onClose}
      aria-labelledby="person-dialog-title"
      aria-describedby="person-dialog-description"
    >
      <DialogTitle id="person-dialog-title">
        {!subPerson
          ? name
          : `${subPerson.full_name} (${getLabelBusinessTypePerson(
              subPerson.business_person_type
            )})`}
      </DialogTitle>
      <DialogContent>
        {person && !subPerson && (
          <Row>
            <Col xs={12}>
              {!isLegacy ? (
                personType === 'business' ? (
                  <BusinessDetails
                    business={person}
                    onBusinessPersonClicked={subPersonClicked}
                  />
                ) : (
                  <PersonDetails person={person} />
                )
              ) : (
                <LegacyPersonDetails person={person} />
              )}
            </Col>
          </Row>
        )}
        {subPerson !== null && (
          <Row>
            <Col xs={12}>
              <PersonDetails person={subPerson} />
            </Col>
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Chiudi</Button>
        {subPerson !== null && <Button onClick={onBack}>Indietro</Button>}
      </DialogActions>
    </Dialog>
  );
});

const PersonDetails = props => {
  const { person } = props;

  const birthplace = useMemo(() => {
    if (!person) return null;
    let birthplace = person.birthplace.city_name;
    birthplace += person.birthplace.county
      ? ` (${person.birthplace.county})`
      : '';

    birthplace = birthplace.trim();

    return !birthplace ? '-' : birthplace;
  }, [person]);

  const fullAddress = useMemo(() => {
    if (!person) return null;
    let address = person.address.street_name ? person.address.street_name : '';
    address += person.address.postcode ? ` ${person.address.postcode}` : '';
    address += person.address.city ? ` ${person.address.city}` : '';
    address += person.address.county_code
      ? ` ${person.address.county_code}`
      : '';

    address = address.trim();

    return !address ? '-' : address;
  }, [person]);

  return (
    <HorizontalList>
      <HorizontalLabelValue label="Nome" value={person.first_name} />
      <HorizontalLabelValue label="Cognome" value={person.last_name} />
      <HorizontalLabelValue
        label="Titolo"
        value={
          person.professional_title ? person.professional_title.long : 'N/A'
        }
      />
      <HorizontalLabelValue label="Codice Fiscale" value={person.fiscal_code} />
      {person.birthplace.is_foreign ? (
        <HorizontalLabelValue
          label="Luogo di Nascita"
          value={
            person.birthplace.county_name ? person.birthplace.county_name : '-'
          }
        />
      ) : (
        <HorizontalLabelValue label="Luogo di Nascita" value={birthplace} />
      )}
      <HorizontalLabelValue label="Sesso" value={person.gender} />
      <HorizontalLabelValue label="Telefono" value={person.phone} />
      <HorizontalLabelValue label="Indirizzo" value={fullAddress} />
      <HorizontalLabelValue label="PEC/Email" value={person.email} />
      <HorizontalLabelValue
        label="P. IVA"
        value={person.vat ? person.vat : 'N/A'}
      />
      <HorizontalLabelValue
        label="Documento di Identità"
        value={
          person.document && person.document.type && person.document.number
            ? `${getDocumentTypeLabel(person.document.type)} - ${
                person.document.number
              }`
            : '-'
        }
      />
    </HorizontalList>
  );
};

const BusinessDetails = props => {
  const { business, onBusinessPersonClicked } = props;

  const onSubPersonClick = useCallback(
    person => {
      onBusinessPersonClicked(person);
    },
    [onBusinessPersonClicked]
  );

  const fullAddress = useMemo(() => {
    if (!business) return null;
    let address = business.address.street_name
      ? business.address.street_name
      : '';
    address += business.address.postcode ? ` ${business.address.postcode}` : '';
    address += business.address.city ? ` ${business.address.city}` : '';
    address += business.address.county_code
      ? ` ${business.address.county_code}`
      : '';

    address = address.trim();

    return !address ? '-' : address;
  }, [business]);

  return (
    <Row>
      <Col xs={12}>
        <HorizontalList>
          <HorizontalLabelValue label="Ragione Sociale" value={business.name} />
          <HorizontalLabelValue label="Telefono" value={business.phone} />
          <HorizontalLabelValue label="Email" value={business.email} />
          <HorizontalLabelValue label="P. IVA" value={business.vat} />
          <HorizontalLabelValue label="Indirizzo" value={fullAddress} />
        </HorizontalList>
      </Col>
      {business.persons.length > 0 && (
        <Col xs={12}>
          {business.persons.map(person => (
            <PersonCard
              key={person.email}
              additionalInfo={`(${getLabelBusinessTypePerson(
                person.business_person_type
              )})`}
              person={person}
              personType="person"
              onPersonClicked={() => onSubPersonClick(person)}
            />
          ))}
        </Col>
      )}
    </Row>
  );
};

const LegacyPersonDetails = props => {
  const { person } = props;

  return (
    <HorizontalList>
      <HorizontalLabelValue
        label="Nome"
        value={person.first_name ? person.first_name : 'N/A'}
      />
      <HorizontalLabelValue
        label="Cognome"
        value={person.last_name ? person.last_name : 'N/A'}
      />
      <HorizontalLabelValue
        label="Codice Fiscale"
        value={person.fiscal_code ? person.fiscal_code : 'N/A'}
      />
      <HorizontalLabelValue label="Sesso" value={person.gender} />
      <HorizontalLabelValue
        label="Indirizzo"
        value={person.address ? person.address : 'N/A'}
      />
      <HorizontalLabelValue
        label="PEC/Email"
        value={person.email ? person.email : 'N/A'}
      />
      <HorizontalLabelValue
        label="P. IVA"
        value={person.vat ? person.vat : 'N/A'}
      />
      <HorizontalLabelValue
        label="Data di Nascita"
        value={person.birthday ? person.birthday : 'N/A'}
      />
    </HorizontalList>
  );
};
