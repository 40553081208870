/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import store from '../../../store';
import { ROLES } from '../../../constants/security';
import Loader from '../../common/Loader';
import PraticheInterne from '../../../api/PraticheInterne';
import ProcedureDetail from './ProcedureDetail';
import {
  getDepartmentLabel,
  INTERNAL_PROCEDURE_STATUS,
} from '../../../constants/common';
import MessageRow from './MessageRow';
import ConfirmDialog from '../../common/ConfirmDialog';
import { Row, Col } from 'react-flexbox-grid';
import ReplyBox from './ReplyBox';
import DetailActions from './DetailActions';
import SignDialog from '../Sign/SignDialog';
import StatusBadge from '../Common/StatusBadge';

const styles = theme => ({
  messages: {
    padding: '0 0.5rem',
    marginBottom: '200px',
  },
  messagesHeader: {
    marginTop: 32,
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 400,
    color: theme.palette.grey.dark,
  },
  subtitle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
});

const Detail = props => {
  const { classes } = props;
  const { procedureId } = props.match.params;
  const { profile, snackBar } = store.app;
  const [procedure, setProcedure] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [shareDeleteDialogIsOpen, setShareDeleteDialogIsOpen] = useState(false);
  const [shareToDelete, setShareToDelete] = useState(undefined);
  const [isDeletingShare, setIsDeletingShare] = useState(false);
  const [closeDialogIsOpen, setCloseDialogIsOpen] = useState(false);
  const [closingProcedure, setClosingProcedure] = useState(false);
  const [signDialogIsOpen, setSignDialogIsOpen] = useState(false);

  const isOwner = useMemo(() => {
    return procedure && profile.user.id === procedure.shared_by.id;
  }, [profile, procedure]);

  const isOwnerAndOpen = useMemo(() => {
    return isOwner && procedure.status !== INTERNAL_PROCEDURE_STATUS.CLOSED;
  }, [isOwner, procedure]);

  const canSign = useMemo(() => {
    return (
      procedure &&
      procedure.status === INTERNAL_PROCEDURE_STATUS.ACTIVE &&
      !isOwner &&
      procedure.shares.find(
        s =>
          s.department === profile.operatingAs.department &&
          s.signed_by === null
      )
    );
  }, [isOwner, procedure, profile.operatingAs.department]);

  const canReply = useMemo(() => {
    return (
      procedure &&
      procedure.status === INTERNAL_PROCEDURE_STATUS.ACTIVE &&
      (isOwner ||
        procedure.shares.find(s =>
          profile.hasRoleIn(ROLES.MANAGER, s.department)
        ))
    );
  }, [profile, isOwner, procedure]);

  const closeProcedure = useCallback(() => {
    setCloseDialogIsOpen(true);
  }, []);

  const signProcedure = useCallback(() => {
    setSignDialogIsOpen(true);
  }, []);

  const onCloseProcedureConfirmed = useCallback(() => {
    setClosingProcedure(true);
    PraticheInterne.close(
      procedureId,
      procedure => {
        snackBar.open('Atto chiuso correttamente');
        setClosingProcedure(false);
        setCloseDialogIsOpen(false);
        setProcedure(procedure);
      },
      () => {
        snackBar.open(
          'Si è verificato un errore imprevisto durante la chiusura'
        );
        setClosingProcedure(false);
        setCloseDialogIsOpen(false);
      }
    );
  }, [snackBar, procedureId]);

  const onCloseProcedureCanceled = useCallback(() => {
    setCloseDialogIsOpen(false);
  }, []);

  const loadProcedure = useCallback(
    id => {
      setLoading(true);
      PraticheInterne.get(
        id,
        procedure => {
          setProcedure(procedure);
          setLoading(false);
        },
        () => {
          snackBar.open(
            'Si è verificato un errore imprevisto durante il caricamento'
          );
          setLoading(false);
        }
      );
    },
    [snackBar]
  );

  const loadMessages = useCallback(
    (id, shouldScrollDown = false) => {
      setLoadingMessages(true);
      PraticheInterne.messages(
        id,
        response => {
          setMessages(response.messages);
          setLoadingMessages(false);
          if (shouldScrollDown) {
            window.scrollTo(0, document.body.scrollHeight);
          }
        },
        () => {
          snackBar.open(
            'Si è verificato un errore imprevisto durante il caricamento delle determine'
          );
          setLoadingMessages(false);
        }
      );
    },
    [snackBar]
  );

  const onNewReply = useCallback(() => {
    loadMessages(procedureId, true);
  }, [loadMessages, procedureId]);

  const onProcedureShared = useCallback(() => {
    loadProcedure(procedureId);
    loadMessages(procedureId);
  }, [procedureId, loadProcedure, loadMessages]);

  const onShareDelete = useCallback(share => {
    setShareToDelete(share);
    setShareDeleteDialogIsOpen(true);
  }, []);

  const onShareDeleteConfirmed = useCallback(() => {
    setIsDeletingShare(true);
    PraticheInterne.deleteShare(
      procedureId,
      shareToDelete.id,
      () => {
        snackBar.open(
          `Condivisione con "${getDepartmentLabel(
            shareToDelete.department
          )}" interrotta`
        );
        loadProcedure(procedureId);
        loadMessages(procedureId);
        setShareDeleteDialogIsOpen(false);
        setShareToDelete(undefined);
        setIsDeletingShare(false);
      },
      () => {
        snackBar.open(
          "Si è verificato un errore imprevisto durante l'interruzione della condivisione"
        );
        setShareDeleteDialogIsOpen(false);
        setShareToDelete(undefined);
        setIsDeletingShare(false);
      }
    );
  }, [snackBar, shareToDelete, procedureId, loadProcedure, loadMessages]);

  const onShareDeleteDialogClosed = useCallback(() => {
    setShareToDelete(undefined);
    setShareDeleteDialogIsOpen(false);
  }, []);

  const onSignComplete = useCallback(
    procedure => {
      if (procedure) setProcedure(procedure);
      setSignDialogIsOpen(false);
      loadMessages(procedureId, true);
    },
    [loadMessages, procedureId]
  );

  useEffect(() => {
    loadProcedure(procedureId);
    loadMessages(procedureId);
  }, [procedureId, loadMessages, loadProcedure]);

  const number = procedure ? `${procedure.number}`.padStart(6, '0') : '-';

  return (
    <>
      <PageContainer
        header={
          <PageTitle
            title="Dettagli Atto Dirigenziale"
            subtitle={
              procedure ? (
                <div className={classes.subtitle}>
                  <span>{`Atto n°${number} del ${
                    procedure.created_at.split(' ')[0]
                  }`}</span>
                  <StatusBadge state={procedure.status} />
                </div>
              ) : null
            }
            actions={
              <DetailActions
                isOwnerAndOpen={isOwnerAndOpen}
                canSign={canSign}
                onClose={closeProcedure}
                onSign={signProcedure}
              />
            }
            backIcon={true}
          />
        }
      >
        <Loader size={40} loading={loading} />
        {procedure && !loading && (
          <>
            <ProcedureDetail
              procedure={procedure}
              isOwner={isOwner}
              onProcedureShared={onProcedureShared}
              onShareDelete={onShareDelete}
            />
            {messages.length > 0 && (
              <Row className={classes.messagesHeader}>
                <Col xs={12}>Eventi</Col>
              </Row>
            )}
            <Row className={classes.messages}>
              <Col xs={12}>
                <Loader size={40} loading={loadingMessages} />
                {messages.map((m, i) => {
                  return (
                    <MessageRow key={i} message={m} procedure={procedure} />
                  );
                })}
                {isOwnerAndOpen && shareToDelete && (
                  <ConfirmDialog
                    open={shareDeleteDialogIsOpen}
                    loading={isDeletingShare}
                    title="Interruzione Condivisione"
                    message={`Sei sicuro di voler interrompere la condivisione della pratica con "${getDepartmentLabel(
                      shareToDelete.department
                    )}"? Il dipartimento rimosso non potrà più visualizzare i dettagli della pratica.`}
                    onConfirm={onShareDeleteConfirmed}
                    onCancel={onShareDeleteDialogClosed}
                  />
                )}
                {isOwnerAndOpen && (
                  <ConfirmDialog
                    open={closeDialogIsOpen}
                    loading={closingProcedure}
                    title="Chiusura Atto"
                    message="Sei sicuro di voler chiudere l'atto? Una volta chiuso non potranno essere più aggiunte determine, ma l'atto rimmarrà consultabile tra gli atti archiviati."
                    onConfirm={onCloseProcedureConfirmed}
                    onCancel={onCloseProcedureCanceled}
                  />
                )}
                {canSign && (
                  <SignDialog
                    procedureId={procedure.id}
                    open={signDialogIsOpen}
                    onClose={onSignComplete}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </PageContainer>
      {procedure && canReply && (
        <ReplyBox procedure={procedure} onNewReply={onNewReply} />
      )}
    </>
  );
};

export default withStyles(styles)(Detail);
