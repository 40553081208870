import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import moment from 'moment';
import CodiceFiscale from 'codice-fiscale-js';

function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && re.test(email.toLowerCase());
}

function isEmptyString(str) {
  return str === '' || str === null || str === undefined;
}

function isNumber(field) {
  const isNumeric = /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)$/;
  return !isEmptyString(field) && isNumeric.test(field);
}

function containNumber(field) {
  const numNumber = (field.match(/[0-9]/g) || []).length;
  return numNumber > 0;
}

function isValidFiscalCode(value) {
  const cf = new CodiceFiscale(value);
  return cf.isValid();
}

function isValidBirthDate(date, format = 'D/MM/YYYY') {
  const birthday = moment(date, format);
  const start = moment('120 years ago');
  const end = moment('18 years ago');
  return birthday.isBetween(start, end);
}

function isValidMobilePhone(phone) {
  return phone && +parsePhoneNumberFromString(phone, 'IT').isValid();
}

export default {
  isValidEmail: isValidEmail,
  isEmptyString: isEmptyString,
  isNumber: isNumber,
  containNumber: containNumber,
  isValidFiscalCode: isValidFiscalCode,
  isValidBirthDate: isValidBirthDate,
  isValidMobilePhone: isValidMobilePhone,
};
