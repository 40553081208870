import Routes from '../../constants/routes';
import { getDepartmentLabel } from '../../constants/common';
import store from '../../store';
import { ROLES } from '../../constants/security';

export enum NotificationType {
  managerialActCreated = 'managerial-act.shared',
  managerialActNewMessage = 'managerial-act.new-message',
  managerialActArchived = 'managerial-act.archived',
  managerialActSigned = 'managerial-act.signed',
}

export interface Notification<T> {
  id: string;
  type: NotificationType;
  payload: T;
  createdAt: string;
  readAt: string | null;
}

export interface Subject {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ManagerialActInstance {
  id: number;
  departments?: string[];
}

export interface ManagerialActCreatedPayload {
  subject: Subject;
  instance: ManagerialActInstance;
}

export interface NewManagerialActMessagePayload {
  subject: Subject;
  instance: ManagerialActInstance;
}

export interface ManagerialActArchivedPayload {
  subject: Subject;
  instance: ManagerialActInstance;
}

export interface ManagerialActSignedPayload {
  department: string;
  instance: ManagerialActInstance;
}

export const NotificationMessages = {
  [NotificationType.managerialActArchived]: (
    payload: ManagerialActArchivedPayload
  ) =>
    `L'atto dirigenziale n°${payload.instance.id
      .toString()
      .padStart(6, '0')} è stato archiviato da ${payload.subject.firstName} ${
      payload.subject.lastName
    }`,
  [NotificationType.managerialActCreated]: (
    payload: ManagerialActCreatedPayload
  ) =>
    `${payload.subject.firstName} ${payload.subject.lastName} ha condiviso un nuovo atto dirigenziale con il tuo dipartimento`,
  [NotificationType.managerialActNewMessage]: (
    payload: NewManagerialActMessagePayload
  ) =>
    `${payload.subject.firstName} ${
      payload.subject.lastName
    } ha inserito una nuova nota per l'atto dirigenziale n°${payload.instance.id
      .toString()
      .padStart(6, '0')}`,
  [NotificationType.managerialActSigned]: (
    payload: ManagerialActSignedPayload
  ) =>
    `${getDepartmentLabel(
      payload.department
    )} ha firmato digitalmente l'atto dirigenziale n°${payload.instance.id
      .toString()
      .padStart(6, '0')}`,
};

interface NotificationActionType {
  redirectTo: string;
  department?: string;
}

const detectSwitchingDepartment = (
  departments?: string[],
  role?: string | string[]
) => {
  if (!departments) return undefined;
  if (!role) {
    return departments.find(d => store.app.profile.isMemberOf(d, true));
  }
  let detectedDept: { role: string; department: string } | undefined;
  if (Array.isArray(role)) {
    detectedDept = store.app.profile.firstEligibleRole(
      departments.map(d => role.map(r => ({ department: d, role: r })))
    );
  } else {
    detectedDept = store.app.profile.firstEligibleRole(
      departments.map(d => ({ role, department: d }))
    );
  }

  return detectedDept?.department ?? undefined;
};

export const NotificationAction: {
  [key in NotificationType]: (payload: any) => NotificationActionType;
} = {
  [NotificationType.managerialActNewMessage]: (
    payload: NewManagerialActMessagePayload
  ) => ({
    redirectTo: `/${Routes.SCRIVANIA_VIRTUALE}/${payload.instance.id}`,
    department: detectSwitchingDepartment(
      payload.instance.departments,
      ROLES.MANAGER
    ),
  }),
  [NotificationType.managerialActArchived]: (
    payload: ManagerialActArchivedPayload
  ) => ({
    redirectTo: `/${Routes.SCRIVANIA_VIRTUALE}/${payload.instance.id}`,
    department: detectSwitchingDepartment(
      payload.instance.departments,
      ROLES.MANAGER
    ),
  }),
  [NotificationType.managerialActCreated]: (
    payload: ManagerialActCreatedPayload
  ) => ({
    redirectTo: `/${Routes.SCRIVANIA_VIRTUALE}/${payload.instance.id}`,
    department: detectSwitchingDepartment(
      payload.instance.departments,
      ROLES.MANAGER
    ),
  }),
  [NotificationType.managerialActSigned]: (
    payload: ManagerialActSignedPayload
  ) => ({
    redirectTo: `/${Routes.SCRIVANIA_VIRTUALE}/${payload.instance.id}`,
    department: detectSwitchingDepartment(
      payload.instance.departments,
      ROLES.MANAGER
    ),
  }),
};
