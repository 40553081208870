import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageTitle from '../common/PageTitle';
import PageContainer from '../common/PageContainer';
import { withRouter } from 'react-router-dom';
import List from './List';
import Condivisione from '../../api/Condivisione';
import store from '../../store';

const API_CALL = [Condivisione.getSharedWithMe, Condivisione.getSharedByMe];

export default withRouter(() => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { snackBar, profile } = store.app;
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [instances, setInstances] = useState([]);
  const [pagination, setPagination] = useState(undefined);

  const apiCall = useMemo(() => {
    return API_CALL[selectedTab];
  }, [selectedTab]);

  const loadList = useCallback(
    pageNumber => {
      setLoading(true);
      apiCall(
        {
          department: profile.operatingAs.department,
          pageNumber,
        },
        response => {
          setInstances(response.items);
          setPagination(response.pagination);
          setLoading(false);
        },
        () => {
          snackBar.open('Si è verificato un errore imprevisto');
          setLoading(false);
        }
      );
    },
    [apiCall, snackBar, profile]
  );

  const onPageChange = useCallback(page => {
    setPage(page);
  }, []);

  const ListComponent = useMemo(() => {
    return (
      <List
        instances={instances}
        pagination={pagination}
        onPageChange={onPageChange}
        loading={loading}
      />
    );
  }, [instances, pagination, loading, onPageChange]);

  useEffect(() => {
    loadList(page);
  }, [loadList, page]);

  return (
    <PageContainer
      whiteBg
      header={
        <PageTitle
          title="Pareri da altri uffici"
          tabs={['Pareri richiesti a me', 'Ricevuti da altri uffici']}
          onTabChange={setSelectedTab}
        />
      }
    >
      {ListComponent}
    </PageContainer>
  );
});
