import axios from 'axios';
import store from '../store';
import _ from 'lodash';
import errorMessages from '../constants/errorMessages';
import ROUTES from '../constants/routes';
import AuthApi from './Auth';
import navigateTo from '../utils/navigate';
import { API_URL } from '../config';

export const interceptFulfilled = response => {
  return response;
};

export const interceptErrors = error => {
  const response = error.response;
  if (response === null || response === undefined) {
    return Promise.reject({ messaggio: 'Errore nella chiamata' });
  }
  if (401 === error.response.status) {
    if (!error.request.responseURL.endsWith('/backoffice/profile')) {
      response.shouldNotForward = true;
    }
    checkAuthentication();
  } else if (404 === error.response.status) {
    response.messaggio = 'Risorsa non trovata';
  } else if (403 === error.response.status) {
    response.messaggio =
      "Non hai l'autorizzazione per effettuare questa operazione.";
  } else if (413 === error.response.status) {
    response.messaggio =
      'Errore: La dimensione totale dei file caricati è troppo grande!';
    store.app.snackBar.open(response.messaggio);
  }
  if (error.response.error_code) {
    response.messaggio = errorMessages[error.response.error_code];
  }
  return Promise.reject(response);
};

const defaultOnError = response => {
  if (response.messaggio || response.data) {
    store.app.snackBar.open(response.messaggio || response.data.message);
  }
};

const handleError = (response, onError) => {
  if (response.shouldNotForward) {
    return;
  }

  onError(response);
};

const checkAuthentication = () => {
  const currPath = window.location.href.substr(
    window.location.href.lastIndexOf('/') + 1
  );
  if (currPath !== ROUTES.LOGIN) {
    AuthApi.checkAuth(
      () => {
        //Autenticato ma non posso accedere alla risorsa
        navigateTo(`${ROUTES.UNAUTHORIZED}`);
      },
      err => {
        // Non autenticato, resetto il profilo e rimando alla Login
        store.app.profile.reset();
        if (err.status === 403) {
          navigateTo(`/${ROUTES.UNATHORIZED_PERSONNEL}`);
          return;
        }
        navigateTo(`/${ROUTES.LOGIN}`);
      }
    );
  }
};

const extractParamsWithValue = (paramsMap = {}) => {
  const params = {};
  _.forEach(paramsMap, (value, key) => {
    if (value !== null && value !== undefined && value !== '')
      params[key] = value;
  });
  return params;
};

const prepareQueryParamStr = paramsMap => {
  let queryParams = '';
  if (paramsMap) {
    _.forEach(paramsMap, (value, key) => {
      if (value !== null && value !== undefined && value !== '')
        queryParams += '&' + key + '=' + value;
    });
  }
  return queryParams;
};

const getFilenameFromHeaders = response => {
  const disposition = response.headers['content-disposition'];
  if (
    disposition &&
    (disposition.indexOf('attachment') !== -1 ||
      disposition.indexOf('inline') !== -1)
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    } else {
      return 'file';
    }
  }
};

export class Client {
  constructor(baseURL, options = {}) {
    this.client = axios.create({
      baseURL,
      timeOut: 5000,
      withCredentials: true,
      ...options,
    });

    this._initClient();
  }

  _initClient = () => {
    this.client.interceptors.response.use(interceptFulfilled, interceptErrors);
  };

  makeRequest = (
    method,
    url,
    onSuccess,
    onError = defaultOnError,
    params,
    data,
    options = {}
  ) => {
    this.client
      .request({
        url: url,
        method: method,
        params: params,
        data: data,
        withCredential: true,
        ...options,
      })
      .then(onSuccess)
      .catch(r => handleError(r, onError));
  };

  get = (url, onSuccess, onError, params, options = {}) => {
    const onlyParamsWithValue = extractParamsWithValue(params);
    this.makeRequest(
      'get',
      url,
      onSuccess,
      onError,
      onlyParamsWithValue,
      null,
      options
    );
  };

  patch = (url, onSuccess, onError, data, options = {}) => {
    this.makeRequest('patch', url, onSuccess, onError, null, data, options);
  };

  put = (url, onSuccess, onError, data, options = {}) => {
    this.makeRequest('put', url, onSuccess, onError, null, data, options);
  };

  post = (url, onSuccess, onError, data, options = {}) => {
    this.makeRequest('post', url, onSuccess, onError, null, data, options);
  };

  delete = (url, onSuccess, onError, data, options = {}) => {
    this.makeRequest('delete', url, onSuccess, onError, data, null, options);
  };

  getExportUrl = (url, params) => {
    const queryParams = prepareQueryParamStr(params);
    return `${url}?export=true${queryParams}`;
  };

  initExport = (url, onSuccess, onError) => {
    this.makeRequest('get', url, onSuccess, onError);
  };

  downloadFile = (uri, onFinished, onError, name = undefined) => {
    this.client
      .request({
        url: `${this.client.defaults.baseURL}${
          uri.startsWith('/') ? uri : '/' + uri
        }`,
        method: 'GET',
        responseType: 'blob', // important
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name ?? getFilenameFromHeaders(response));
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        onFinished();
        setTimeout(() => URL.revokeObjectURL(url), 1000 * 10);
      })
      .catch(onError);
  };

  openFile = (uri, onFinished, onError) => {
    this.client
      .request({
        url: `${this.client.defaults.baseURL}${
          uri.startsWith('/') ? uri : '/' + uri
        }`,
        method: 'GET',
        responseType: 'blob', // important
      })
      .then(response => {
        const newWindow = window.open('/documentLoading', '_blank');
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers['content-type'] })
        );
        newWindow.onload = () => {
          newWindow.location = url;
        };
        onFinished();
        setTimeout(() => URL.revokeObjectURL(url), 1000 * 10);
      })
      .catch(onError);
  };

  downloadExport = jobId => {
    window.open(
      `${this.client.defaults.baseURL}/exports/${jobId}?download=true`
    );
  };

  checkExport = (jobId, onSuccess, onError) => {
    this.makeRequest('get', `exports/${jobId}`, onSuccess, onError);
  };
}

export default new Client(API_URL);
