import { withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Visibility as EyeIcon } from '@material-ui/icons';
import React from 'react';
import BiggerTooltip from '../../common/BiggerTooltip';

const styles = () => ({
  smallActionButton: {
    minWidth: '40px',
    marginRight: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
});

export default withStyles(styles)(props => {
  const { classes, disabled, size, variant, color, onClick } = props;

  return (
    <BiggerTooltip title="Vai ai dettagli" className={classes.tooltip}>
      <Button
        disabled={disabled}
        size={size}
        variant={variant}
        color={color}
        className={classes.smallActionButton}
        onClick={onClick}
      >
        <EyeIcon className={classes.smallActionButtonIcon} />
      </Button>
    </BiggerTooltip>
  );
});
