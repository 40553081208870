import { createAction } from '@reduxjs/toolkit';
import { BenefitDTO, UndeductedExpenseDTO } from '../../gen/api/welfare';
import { TemporaryDocument } from '../../components/serviziSociali/api/model';

export enum EditProcedureEvents {
  userEditExpenses = 'events/userEditExpenses',
  userEditBenefits = 'events/userEditBenefits',
  userEditIsee = 'events/userEditIsee',
  userPressConfirm = 'events/userPressConfirm',
  userAskExit = 'events/userAskExit',
  userAbortExit = 'events/userAbortExit',
  procedureSubmitted = 'events/procedureSubmitted',
  errorSubmitting = 'events/errorSubmitting',
}

export const userEditExpenses = createAction<UndeductedExpenseDTO[]>(
  EditProcedureEvents.userEditExpenses
);

export type UserEditExpenses = ReturnType<typeof userEditExpenses>;

export const userEditBenefits = createAction<BenefitDTO[]>(
  EditProcedureEvents.userEditBenefits
);

export type UserEditBenefits = ReturnType<typeof userEditBenefits>;

export const userEditIsee = createAction<
  | {
      value: string;
      document: TemporaryDocument;
    }
  | undefined
>(EditProcedureEvents.userEditIsee);

export type UserEditIsee = ReturnType<typeof userEditIsee>;

export const userPressConfirm = createAction(
  EditProcedureEvents.userPressConfirm
);

export const userAskExit = createAction(EditProcedureEvents.userAskExit);

export const userAbortExit = createAction(EditProcedureEvents.userAbortExit);

export const procedureSubmitted = createAction(
  EditProcedureEvents.procedureSubmitted
);

export const errorSubmitting = createAction(
  EditProcedureEvents.errorSubmitting
);
