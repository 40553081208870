import CallManager from './AxiosCallManager';
import { REDIRECT_URL } from '../config';

export default {
  loadList: ({ page, department }, onSuccess, onError) => {
    CallManager.get('users/', response => onSuccess(response.data), onError, {
      page: page,
      department,
    });
  },
  get: (id, onSuccess, onError) => {
    CallManager.get(
      `users/${id}`,
      response => onSuccess(response.data),
      onError
    );
  },
  update: (id, data, onSuccess, onError) => {
    CallManager.patch(`users/${id}`, onSuccess, onError, data);
  },
  delete: ({ id, department }, onSuccess, onError) => {
    CallManager.delete(`users/${id}`, onSuccess, onError, { department });
  },
  create: (data, onSuccess, onError) => {
    data.redirect_url = REDIRECT_URL;
    CallManager.post('users/', onSuccess, onError, data);
  },
  resendLink: (id, onSuccess, onError) => {
    CallManager.post(`users/${id}/resend`, onSuccess, onError);
  },
  getUserDepartments: (id, { page }, onSuccess, onError) => {
    CallManager.get(
      `users/${id}/departments`,
      response => onSuccess(response.data),
      onError,
      { page: page }
    );
  },
  search: (
    { department, query, onlyRoles = [], excludeSelf = true },
    onSuccess,
    onError
  ) => {
    CallManager.get(
      `/users/search`,
      response => onSuccess(response.data.users),
      onError,
      {
        department,
        query,
        onlyRoles,
        excludeSelf: excludeSelf ? 1 : 0,
      }
    );
  },
  newSearchUser: (
    { department, onlyRoles, excludeSelf, query },
    onSuccess,
    onError
  ) => {
    CallManager.get(
      `/users/search`,
      response => onSuccess(response.data.users),
      onError,
      { department, onlyRoles, excludeSelf, query }
    );
  },
};
