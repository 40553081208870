const tableStyle = () => ({
  tableContainer: {
    overflowX: 'auto',
    position: 'relative',
  },
  table: {},
  headRow: {
    height: '32px',
    backgroundColor: '#F5F7FF',
    marginBottom: '10px',
    border: 'none',
  },
  headCell: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    border: 'none',
    fontSize: '14px',
    lineHeight: '21px',
  },
  bodyRow: {
    padding: '20px 16px',
    '& td': {
      fontSize: '16px',
      lineHeight: '24px',
      '&:first-child': {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.5',
    },
  },
  tablePagination: {
    height: '33px',
    minHeight: '33px',
  },
});

export default tableStyle;
