import React, { FC, useCallback } from 'react';
import { SectionTitle } from '../common/SectionTitle';
import { StyleRules } from '@material-ui/core/styles';
import {
  Button,
  Input,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { CreateInterviewRequest, TemporaryDocument } from '../api/model';
import FormControlValidated from '../../common/FormControlValidated';
import { DocumentRow } from '../common/DocumentRow';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import { UploadDialog } from '../common/upload/Dialog';
import { deleteTemporaryDocument } from '../api/api';
import { useDialog } from '../common/upload/useDialog';

const styles = (_theme: any): StyleRules => ({
  inputMultiline: {
    height: '100%!important',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 48px',
    gap: '32px',
    width: '496px',
  },
  documentUpload: {
    width: '100%',
    marginTop: '24px',
    animationName: 'slidein',
    animationDuration: '0.5s',
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    gap: '8px',
    paddingTop: '8px',
  },
});

type Value = Partial<Pick<CreateInterviewRequest, 'note' | 'documents'>>;

interface AddNoteProps extends StyledComponentProps {
  value?: Value;
  onChange: (data?: Value) => void;
}

const AddNote: FC<AddNoteProps> = ({
  value = {},
  onChange: onChangeCallback,
  classes,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const onChangeNote = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeCallback({ ...value, note: e.target.value });
    },
    [onChangeCallback, value]
  );

  const onDocumentUpload = useCallback(
    (document: TemporaryDocument) => {
      const newDocuments = value?.documents
        ? [...value.documents, document]
        : [document];
      onChangeCallback({ ...value, documents: newDocuments });
    },
    [onChangeCallback, value]
  );

  const removeDocument = useCallback(
    id => () => {
      deleteTemporaryDocument(id).catch(console.warn);
      const newDocuments = value?.documents
        ? [...value.documents.filter(doc => doc.fid !== id)]
        : [];
      onChangeCallback({ ...value, documents: newDocuments });
    },
    [onChangeCallback, value]
  );

  return (
    <div className={classes?.container}>
      <SectionTitle>
        Inserisci eventuali note ed allegati relativi al colloquio
      </SectionTitle>

      <div style={{ width: '100%' }}>
        <FormControlValidated
          inputId="note"
          label="Inserisci eventuali note relative al colloquio"
          shrinkLabel
        >
          <Input
            id="note"
            name="note"
            type="string"
            classes={classes}
            autoFocus
            multiline
            onChange={onChangeNote}
            placeholder="Inserisci le note"
          />
        </FormControlValidated>
        <>
          <Button
            disabled={open}
            color="primary"
            onClick={openDialog}
            variant="outlined"
            className={classes!.documentUpload}
          >
            <UploadIcon /> Allega documentazione
          </Button>
          <div className={classes!.documentContainer}>
            {value?.documents?.map(doc => (
              <DocumentRow
                key={doc.fid}
                document={doc}
                onDelete={removeDocument}
              />
            ))}
          </div>
        </>
      </div>
      <UploadDialog
        open={open}
        onClose={closeDialog}
        onDocumentUploaded={onDocumentUpload}
      />
    </div>
  );
};

const DecoratedAddNote = withStyles(styles)(AddNote);
export { DecoratedAddNote as AddNote };
