import React from 'react';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import { observer } from 'mobx-react';
import List from './List';
import store from '../../store';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../constants/routes';

class GestioneCittadini extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCitizen: null,
      selectedTab: store.app.tabs.gestioneCittadini,
    };
  }

  componentWillUnmount() {
    store.app.setTabIndex('gestioneCittadini', this.state.selectedTab);
  }

  onCitizenSelected = citizen => {
    this.props.history.push(`/${ROUTES.CITTADINI}/${citizen.id}`);
  };

  render() {
    return (
      <PageContainer
        whiteBg
        header={
          <PageTitle title="Cittadini" onBackClicked={this.onProfileClose} />
        }
      >
        <List onCitizenSelected={this.onCitizenSelected} />
      </PageContainer>
    );
  }
}

export default withRouter(observer(GestioneCittadini));
