import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import cx from 'classnames';
import { ChevronRight } from '@material-ui/icons';

const styles = theme => ({
  container: {
    padding: '20px 16px',
    boxSizing: 'border-box',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.font.greyLight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey.light,
    },
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.grey.dark,
  },
  icon: {
    height: '24px',
    width: '24px',
    color: theme.palette.grey.dark,
  },
  active: {
    color: theme.palette.primary.main,
  },
});

export const SectionArrow = memo(
  withStyles(styles)(({ selected, onClick, text, classes }) => {
    return (
      <div className={classes.container} onClick={onClick}>
        <label
          className={cx({ [classes.text]: true, [classes.active]: selected })}
        >
          {text}
        </label>
        <ChevronRight
          className={cx({ [classes.icon]: true, [classes.active]: selected })}
        />
      </div>
    );
  })
);

SectionArrow.propTypes = {
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
