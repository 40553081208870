import React from 'react';
import { withStyles } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';

const styles = theme => ({
  bodyStrong: theme.typography.bodyStrong,
  centered: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
});

const ListItemLayout = props => {
  const { classes, children } = props;

  if (!children || children.length !== 5) {
    console.error('layout should contain 4 elements');
    return null;
  }

  return (
    <Row className={classes.centered}>
      <Col xs={12} sm={2} md={2} className={classes.centered}>
        {children[0]}
      </Col>
      <Col xs={12} sm={3} md={3} className={classes.centered}>
        {children[1]}
      </Col>
      <Col xs={12} sm={3} md={3} className={classes.centered}>
        {children[2]}
      </Col>
      <Col xs={12} sm={2} md={2} className={classes.centered}>
        {children[3]}
      </Col>
      <Col xs={12} sm={2} md={2} className={classes.centered}>
        {children[4]}
      </Col>
    </Row>
  );
};

ListItemLayout.propTypes = {
  request: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ListItemLayout.defaultProps = {
  onClick: () => {},
};

export default withStyles(styles)(ListItemLayout);
