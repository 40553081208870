import React, { useCallback, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import cx from 'classnames';
import { colors as Colors } from '@material-ui/core';

const styles = theme => ({
  container: {
    flex: 1,
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F0F0F0',
    },
  },
  selected: {
    backgroundColor: '#F0F0F0',
  },
  avatar: {
    color: '#fff',
    marginRight: '20px',
  },
  separator: {
    borderBottom: '1px solid #F0F0F0',
  },
  user: {
    fontFamily: theme.typography.fontFamily,
  },
});

const UserItem = ({ classes, user, separator, onClick, selected }) => {
  const onUserClicked = useCallback(() => {
    onClick(user);
  }, [onClick, user]);

  const backgroundColor = useRef(
    Object.values(Colors)[
      Math.floor(Math.random() * Object.values(Colors).length)
    ][500]
  );

  return (
    <div
      className={cx(classes.container, {
        [classes.separator]: separator,
        [classes.selected]: selected,
      })}
      onClick={onUserClicked}
    >
      <Avatar
        className={classes.avatar}
        style={{
          backgroundColor: backgroundColor.current,
        }}
      >
        {user.first_name[0] + user.last_name[0]}
      </Avatar>
      <span
        className={classes.user}
      >{`${user.first_name} ${user.last_name}`}</span>
    </div>
  );
};

export default withStyles(styles)(UserItem);
