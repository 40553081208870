import React, { FC, useCallback } from 'react';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';

import { userSelectExpenses } from '../../../../stm/socialServices/newProcedure.events';
import { userPressedBack } from '../../../../stm/socialServices/common.events';
import { SelectExpensesForm } from '../../common/forms/SelectExpensesForm';

export const SelectExpenses: FC = () => {
  const { state, dispatch } = useNewSocialService();

  const goOn = useCallback(
    expenses => {
      dispatch(userSelectExpenses(expenses));
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  return (
    <SelectExpensesForm
      initialExpenses={state.context?.expenses ?? []}
      onAbort={goBack}
      onConfirm={goOn}
    />
  );
};
