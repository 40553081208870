import React, { FC, useCallback, useState } from 'react';
import { Collapse, StyledComponentProps, withStyles } from '@material-ui/core';
import { DetailField } from '../common/DetailField';
import { FamiliarMember } from '../common/FamiliarMember';
import { DocumentField } from '../common/DocumentField';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { FlagSection } from '../common/FlagSection';
import { AssignmentField } from '../common/AssignmentField';
import {
  benefitLabelMap,
  expensesLabelMap,
  SECTION_LABEL_MAP,
  SERVICE_LABEL_MAP,
} from '../../../../constants';
import {
  PersonDataDTO,
  WelfareAModuleDetailsDTO,
  WelfareInstanceSectionDTO,
  WelfareInstanceStatusDTO,
  WelfareInstanceTypeDTO,
} from '../../../../../../gen/api/welfare';
import TextLinkPrimary from '../../../../../common/TextLinkPrimary';
import { SectionToEdit } from '../../../../../../stm/socialServices/editProcedure.stm';
import { compile } from 'path-to-regexp';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../../constants/routes';
import { RouteComponentProps } from 'react-router/index';
import { withRouter } from 'react-router-dom';
import store from '../../../../../../store';
import { DEPARTMENTS, ROLES } from '../../../../../../constants/security';

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '32px',
  },
  titleSection: {
    padding: '20px 24px 0px',
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  collapsable: { padding: '20px 24px 0px' },
  fieldContainer: {
    margin: '24px 0px',
    display: 'flex',
    flexDirection: 'row',
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
  fullHeader: {
    background: '#F5F7FF',
    color: theme.palette.black.main,
    marginLeft: '-24px',
    paddingRight: '48px',
    width: '100%',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& label': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '18px',
      lineHeight: '27px',
      fontWeight: '700',
      marginLeft: '24px',
    },
  },
  closing: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.palette.grey.main,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    padding: '38px',
  },
  emptyLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: theme.palette.grey.dark,
  },
});
interface CollapsableDetailProps
  extends StyledComponentProps,
    RouteComponentProps<{ praticaId: string }> {
  status: WelfareInstanceStatusDTO;
  detail: WelfareAModuleDetailsDTO;
  type: WelfareInstanceTypeDTO;
  section: WelfareInstanceSectionDTO;
  assignTo?: PersonDataDTO;
  protocol?: string;
  readOnly?: boolean;
}
// eslint-disable-next-line complexity
const CollapsableDetail: FC<CollapsableDetailProps> = ({
  classes = {},
  status,
  detail,
  type,
  section,
  assignTo,
  protocol,
  history,
  match,
  readOnly = false,
}) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = useCallback(() => {
    setOpen(op => !op);
  }, []);

  const assign = useCallback(console.log, []);
  const goToEdit = useCallback(
    (section: SectionToEdit) => () => {
      const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.EDIT_PRATICA}`, {
        encode: encodeURIComponent,
      });
      const praticaId = match.params.praticaId;
      history.push(toPath({ praticaId, sectionToEdit: section }));
    },
    [history, match.params.praticaId]
  );

  const { profile } = store.app;
  const isOpen =
    status !== WelfareInstanceStatusDTO.Rejected &&
    status !== WelfareInstanceStatusDTO.Approved;
  const isAssignee = assignTo?.fiscalCode === profile.user.person.fiscal_code;
  const isAtLeastPO = profile.isHigherOrEqualThanForDepartment(
    [
      {
        department: DEPARTMENTS.SOCIAL_SERVICES,
        role: ROLES.PO,
      },
    ],
    true
  );
  const canEdit = !readOnly && isOpen && (isAssignee || isAtLeastPO);

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Dettaglio</label>
        <div onClick={toggleOpen} style={{ cursor: 'pointer' }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <Collapse in={open} className={classes.collapsable}>
        <div className={classes.fieldContainer}>
          <DetailField
            style={{ flex: 1 }}
            label="Sezione"
            value={SECTION_LABEL_MAP[section]}
          />
          <DetailField
            style={{ flex: 2 }}
            label="Tipologia"
            value={SERVICE_LABEL_MAP[type]}
          />
        </div>
        <div className={classes.fieldContainer}>
          {!readOnly ? (
            <AssignmentField
              style={{ flex: 1 }}
              label="Assegnato a"
              assigned={
                assignTo
                  ? `${assignTo.firstName} ${assignTo.lastName}`
                  : undefined
              }
              onAssign={assign}
            />
          ) : (
            <DetailField
              style={{ flex: 1 }}
              label="Assegnato a"
              value={
                assignTo ? `${assignTo.firstName} ${assignTo.lastName}` : 'N/A'
              }
            />
          )}
          <DetailField
            style={{ flex: 1 }}
            label="Protocollo"
            value={protocol}
          />
          <div style={{ flex: 1 }} />
        </div>

        <div className={classes.fullHeader}>
          <label>Nucleo familiare</label>
        </div>
        <FamiliarMember members={detail.familyMembers} />
        <div className={classes.fullHeader}>
          <label>Situazione personale</label>
        </div>
        <FlagSection serviceFilter={type} flags={detail.flags} />
        <div className={classes.fullHeader}>
          <label>I.S.E.E.</label>
          {canEdit && (
            <TextLinkPrimary bold onClick={goToEdit(SectionToEdit.isee)}>
              Modifica
            </TextLinkPrimary>
          )}
        </div>
        <div className={classes.fieldContainer}>
          {detail.isee ? (
            <DocumentField
              style={{ flex: 1 }}
              label="Valore I.S.E.E."
              value={`${detail.isee.value} €`}
              file={detail.isee!.document}
            />
          ) : (
            <div className={classes.emptyContainer}>
              <label className={classes.emptyLabel}>
                Modello I.S.E.E. non specificato
              </label>
            </div>
          )}
        </div>
        <div className={classes.fullHeader}>
          <label>Altri benefici</label>
          {canEdit && (
            <TextLinkPrimary bold onClick={goToEdit(SectionToEdit.benefits)}>
              Modifica
            </TextLinkPrimary>
          )}
        </div>
        <div
          className={classes.fieldContainer}
          style={{ display: 'inline-block', width: '100%' }}
        >
          {detail.benefits.length < 1 && (
            <div className={classes.emptyContainer}>
              <label className={classes.emptyLabel}>
                Nessun beneficio indicato
              </label>
            </div>
          )}
          {detail.benefits.map(b => (
            <DetailField
              key={b.type}
              style={{ width: '30%' }}
              label={benefitLabelMap[b.type]}
              value={`${b.value} €`}
            />
          ))}
        </div>
        <div className={classes.fullHeader}>
          <label>Spese non detratte</label>
          {canEdit && (
            <TextLinkPrimary bold onClick={goToEdit(SectionToEdit.expenses)}>
              Modifica
            </TextLinkPrimary>
          )}
        </div>
        <div
          className={classes.fieldContainer}
          style={{ display: 'inline-block', width: '100%' }}
        >
          {detail.undeductedExpenses.length < 1 && (
            <div className={classes.emptyContainer}>
              <label className={classes.emptyLabel}>
                Nessuna spesa non detratta indicata
              </label>
            </div>
          )}
          {detail.undeductedExpenses.map(e => (
            <DocumentField
              key={e.type}
              style={{ width: '30%' }}
              label={expensesLabelMap[e.type]}
              value={`${e.value} €`}
              file={e.document}
            />
          ))}
        </div>
        <div className={classes.fullHeader}>
          <label>Congiunti obbligati al mantenimento</label>
        </div>
        <FamiliarMember members={detail.relativeMembers} />
        <div className={classes.closing} />
      </Collapse>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedCollapsableDetail = withStyles(styles)(
  withRouter(CollapsableDetail)
);

export { DecoratedCollapsableDetail as CollapsableDetail };
