import NumberFormat from 'react-number-format';
import React, { FC, useCallback } from 'react';
import { InputComponentProps } from '@material-ui/core/Input';

interface CurrencyFormatProps extends InputComponentProps {
  onChange?: (event: {
    target: { value: string };
    currentTarget: { value: string };
  }) => void;
}
export const CurrencyFormat: FC<CurrencyFormatProps> = ({
  inputRef,
  onChange,
  value,
  type,

  ...other
}) => {
  const onValueChanges = useCallback(
    values => {
      if (!onChange) {
        return;
      }
      onChange({
        target: {
          value: values.floatValue?.toFixed(2).toString(),
        },
        currentTarget: {
          value: values.floatValue?.toFixed(2).toString(),
        },
      });
    },
    [onChange]
  );
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChanges}
      decimalSeparator="."
      allowedDecimalSeparators={['.', ',']}
      decimalScale={2}
      prefix="€ "
      allowEmptyFormatting
      allowNegative={false}
    />
  );
};
