import React from 'react';
import { withStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import store from '../store';
import { observer } from 'mobx-react';
import { getGroupHomepage } from '../constants/common';
import Loader from '../components/common/Loader';
import { AppLogo } from './Icons';
import { LOGIN_URL, REDIRECT_URL } from '../config';

const styles = () => ({
  loadingContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(90deg,#323c8d,#141f78)`,
  },
  roundWhiteBackground: {
    width: '150px',
    height: '150px',
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 140px 7px rgba(0,0,0,0.5);',
  },
});

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    reason: '',
    loading: true,
    loginLoading: false,
    errors: {},
    showError: false,
    message: '',
  };

  componentDidMount() {
    this._isMounted = true;

    store.app.checkAuth(this.onCheckAuthFinished, this.onCheckAuthFinished);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isMounted = false;

  onCheckAuthFinished = () => {
    if (this._isMounted) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const isAuthenticated = store.app.profile.isAuthenticated;
    const { loading } = this.state;

    let redirectTo = this.props.location.state
      ? this.props.location.state.from.pathname
      : null;

    if (isAuthenticated) {
      redirectTo = redirectTo
        ? redirectTo
        : getGroupHomepage(store.app.profile.operatingAs.department);
      return <Redirect to={redirectTo} />;
    } else if (!loading) {
      window.location.href = `${LOGIN_URL}?redirect_url=${REDIRECT_URL}${
        redirectTo ? redirectTo : '/'
      }`;
    }

    return (
      <div className={classes.loadingContainer}>
        <div className={classes.roundWhiteBackground}>
          <AppLogo width={120} height={120} />
        </div>
        <Loader style={{ color: '#fff' }} loading={loading} />
      </div>
    );
  }
}

export default withStyles(styles)(observer(Login));
