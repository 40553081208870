export default {
  100000: 'La pagina richiesta non esiste',
  100001: 'La risorsa richiesta non esiste',
  100002: 'La richiesta è in un formato non valido',
  100003: 'I campi inseriti non sono validi',
  100005: "Hai effettuato troppe richieste nell'arco di un minuto, riprova fra qualche minuto.",
  200000: 'Combinazione email e password errata',
  200001: 'Non hai i permessi per effettuare l’azione richiesta',
  //200002: 'L’utente non è loggato, lo devi redirectare alla pagina di login',
  999999: 'Si è verificato un errore imprevisto, riprova in seguito o contattaci se il problema persiste',
};
