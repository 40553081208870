import React, { useCallback, useEffect, useState } from 'react';
import {
  StyledComponentProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Loader from '../../../../common/Loader';
import EmptyTableMessage from '../../../../common/EmptyTableMessage';
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../../../../styles/table.style';
import { getJournalInterviews } from '../../../api/api';
import store from '../../../../../store';
import {
  InterviewListFilters,
  InterviewListItem,
  InterviewListResponse,
} from '../../../api/model';
import { SECTION_LABEL_MAP, TypePagination } from '../../../constants';
import { Item } from './Item';
import { Col, Row } from 'react-flexbox-grid';
import Pagination from '../../../../common/Pagination';
import cx from 'classnames';
import GenericListFilters from '../../../../common/newFilters/GenericListFilters';
import FilterItem from '../../../../common/filters/Filter';
import { FILTER_TYPE } from '../../../../common/newFilters/constants';
import moment, { Moment } from 'moment';

const styles = () => ({
  ...tableStyle(),
  paginationContainer: {
    margin: 0,
  },
  container: {
    paddingBottom: 30,
  },
  totalItemCount: {
    width: '100%',
    display: 'flex',
    marginBottom: 8,
    fontSize: 18,
  },
});

const sectionOptions = Object.entries(SECTION_LABEL_MAP).map(
  ([value, description]) => ({
    value,
    description,
  })
);

const ListaColloqui: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  const { snackBar } = store.app;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<InterviewListFilters>();
  const [interviews, setInterviews] = useState<InterviewListItem[]>([]);
  const [pagination, setPagination] = useState<TypePagination>();

  const onPageChange = useCallback(changed => {
    setPage(changed.page - 1);
  }, []);

  const applyFilters = useCallback(filters => {
    setFilters({
      ...filters,
      createdBy: filters.createdBy?.value,
      since: filters.since
        ? moment.unix(filters.since).toISOString()
        : undefined,
      until: filters.until
        ? moment.unix(filters.until).toISOString()
        : undefined,
    });
    setPage(0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const nFilters = {
      ...filters,
      createdBy: filters?.createdBy?.uuid,
    };
    getJournalInterviews({ pageNumber: page, filters: nFilters })
      .then((data: InterviewListResponse) => {
        setInterviews(data.interviews);
        setPagination(data.pagination);
      })
      .catch(() => {
        snackBar.open(
          'Si è verificato un errore durante il caricamento dei colloqui'
        );
        setInterviews([]);
      })
      .finally(() => setIsLoading(false));
  }, [filters, page, snackBar]);

  return (
    <div className={cx(classes.tableContainer, classes.container)}>
      {pagination && (
        <div className={classes.totalItemCount}>
          N° Totale Attività:&nbsp;&nbsp;<b>{pagination.totalItemsCount}</b>
        </div>
      )}
      <GenericListFilters
        filters={[
          new FilterItem(
            'beneficiaryFiscalCode',
            FILTER_TYPE.TEXT,
            'Nome, Cognome o codice fiscale cittadino',
            '',
            null,
            null,
            6,
            4,
            4
          ),
          new FilterItem(
            'section',
            FILTER_TYPE.SELECT,
            'Sezione',
            '',
            sectionOptions,
            6,
            3,
            2,
            3
          ),
          new FilterItem(
            'since',
            FILTER_TYPE.DATE,
            'Dalla data',
            '',
            null,
            6,
            4,
            2,
            2,
            (date: Moment) => (date ? date.startOf('day') : null)
          ),
          new FilterItem(
            'until',
            FILTER_TYPE.DATE,
            'Alla data',
            '',
            null,
            6,
            4,
            2,
            2,
            (date: number | null) =>
              date ? moment.unix(date / 1000).endOf('day') : null
          ),
          new FilterItem(
            'createdBy',
            FILTER_TYPE.CITIZEN,
            'Assistente sociale',
            undefined,
            null,
            6,
            4,
            4,
            4
          ),
        ]}
        applyFilters={applyFilters}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headRow}>
            <TableCell className={classes.headCell}>ID Attività</TableCell>
            <TableCell className={classes.headCell}>Codice Fiscale</TableCell>
            <TableCell className={classes.headCell}>Sezione</TableCell>
            <TableCell className={classes.headCell}>Inizio Attività</TableCell>
            <TableCell className={classes.headCell}>Fine Attività</TableCell>
            <TableCell className={classes.headCell} style={{ width: '20%' }}>
              Assistente Sociale
            </TableCell>
            <TableCell className={classes.headCell} />
          </TableRow>
        </TableHead>
        {!isLoading && interviews.length > 0 && (
          <TableBody>
            {interviews.map(interview => (
              <Item key={interview.id} interview={interview} />
            ))}
          </TableBody>
        )}
      </Table>

      <Loader size={40} loading={isLoading} />
      <EmptyTableMessage visible={!isLoading && !interviews.length} />
      {pagination && (
        <Row className={classes.paginationContainer}>
          <Col xs={12}>
            <Pagination
              total={pagination.totalPages}
              current={pagination.currentPage + 1}
              totalElement={pagination.totalItemsCount}
              elementNumber={pagination.pageItemsCount}
              onChange={onPageChange}
              display={3}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(ListaColloqui);
