import React, { useMemo } from 'react';
import { DEPARTMENTS } from '../../../../constants/security';
import { ReactComponent as Admin } from '../../../../assets/icons/departments/admin.svg';
import { ReactComponent as Ambiente } from '../../../../assets/icons/departments/ambiente.svg';
import { ReactComponent as Anagrafe } from '../../../../assets/icons/departments/anagrafe.svg';
import { ReactComponent as StatoCivile } from '../../../../assets/icons/departments/stato-civile.svg';
import { ReactComponent as Edilizia } from '../../../../assets/icons/departments/edilizia.svg';
import { ReactComponent as Logistica } from '../../../../assets/icons/departments/logistica.svg';
import { ReactComponent as ServiziSociali } from '../../../../assets/icons/departments/servizi-sociali.svg';

const Icons = {
  [DEPARTMENTS.ADMIN]: Admin,
  [DEPARTMENTS.BUILDING]: Edilizia,
  [DEPARTMENTS.ENVIRONMENT]: Ambiente,
  [DEPARTMENTS.LOGISTIC]: Logistica,
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: Anagrafe,
  [DEPARTMENTS.STATO_CIVILE]: StatoCivile,
  [DEPARTMENTS.SOCIAL_SERVICES]: ServiziSociali,
};

export interface DepartmentIconProps {
  className?: string;
  department: string;
  size?: number;
  color?: string;
}

const DepartmentIcon: React.FC<DepartmentIconProps> = ({
  className,
  department,
  size = 24,
  color = '#2667C5',
}) => {
  const IconComponent = useMemo(() => {
    return department in Icons
      ? Icons[department]
      : Icons[DEPARTMENTS.BUILDING];
  }, [department]);

  return (
    <div className={className}>
      <IconComponent height={size} width={size} fill={color} />
    </div>
  );
};

export default DepartmentIcon;
