import CallManager from './AxiosCallManager';

export default {
  getExtensionDocuments: (extensionId, onSuccess, onError) => {
    CallManager.get(
      `/extensions/${extensionId}/documents`,
      response => onSuccess(response.data),
      onError
    );
  },
  downloadExtensionDocument: (extensionId, documentId, onFinished, onError) => {
    CallManager.downloadFile(
      `/extensions/${extensionId}/documents/${documentId}`,
      onFinished,
      onError
    );
  },
};
