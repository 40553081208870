import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';

const InsertSubjectStep = props => {
  const { subject, onChange } = props;

  const onSubjectChange = useCallback(
    event => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div>
      <DialogContentText style={{ marginBottom: '30px' }}>
        Inserisci l&apos;oggetto dell&apos;atto
      </DialogContentText>
      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor="subject" shrink={true}>
          Oggetto
        </InputLabel>
        <Input
          id="subject"
          autoFocus={true}
          multiline
          style={{
            width: '100%',
          }}
          rows={1}
          rowsMax={3}
          inputProps={{
            maxLength: 255,
          }}
          value={subject}
          onChange={onSubjectChange}
        />
      </FormControl>
    </div>
  );
};

InsertSubjectStep.propTypes = {
  subject: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InsertSubjectStep;
