import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../new/components/icons';
import theme from '../../styles/theme';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_HUMAN } from './filters/constants';
import store from '../../store';

const styles = theme => ({
  dropZone: {
    flex: 1,
    display: 'flex',
    height: 150,
    border: '2px dashed ' + theme.palette.grey.main,
    borderRadius: '6px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropZoneActive: {
    border: '2px dashed ' + theme.palette.primary.main,
    backgroundColor: theme.palette.blue.light,
    '&:hover': {
      cursor: 'grabbing',
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  overlay: {},
  icon: {},
  messageText: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey.dark,
    userSelect: 'none',
  },
  messageTextActive: {
    color: theme.palette.primary.main,
  },
});

const DragAndDrop = ({
  classes,
  className,
  draggingClassName,
  overlayClassName,
  withoutOverlay = false,
  onDrag = () => {},
  onDragIn = () => {},
  onDragOut = () => {},
  onDrop,
  children,
  onClick = () => {},
}) => {
  const dropzoneRef = useRef();
  const fileInput = useRef();
  const [inputKey, setInputKey] = useState(Date.now());
  const [isDragging, setIsDragging] = useState(false);

  const stopEventPropagation = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrag = useCallback(
    e => {
      stopEventPropagation(e);
      onDrag(e);
    },
    [stopEventPropagation, onDrag]
  );

  const handleDragIn = useCallback(
    e => {
      stopEventPropagation(e);
      if (
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length > 0
      ) {
        setIsDragging(true);
        onDragIn(e);
      }
    },
    [stopEventPropagation, onDragIn]
  );

  const handleDragOut = useCallback(
    e => {
      stopEventPropagation(e);
      setIsDragging(false);
      onDragOut(e);
    },
    [stopEventPropagation, onDragOut]
  );

  const handleDrop = useCallback(
    e => {
      stopEventPropagation(e);
      setIsDragging(false);
      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length > 0
      ) {
        onDrop(e.dataTransfer.files[0], e);
      }
    },
    [stopEventPropagation, onDrop]
  );

  const onDocumentSelected = useCallback(
    event => {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        store.app.snackBar.open(
          `Il file '${file.name}' selezionato è troppo grande (Max ${MAX_FILE_SIZE_HUMAN})`
        );
      } else {
        onDrop(file);
      }
      setInputKey(Date.now());
    },
    [onDrop]
  );

  const handleClick = useCallback(
    e => {
      if (e.target.name !== 'file') {
        fileInput.current.click();
        stopEventPropagation(e);
        onClick(e);
      }
    },
    [onClick, stopEventPropagation]
  );

  useEffect(() => {
    const dropzone = dropzoneRef ? dropzoneRef.current : undefined;
    if (dropzone) {
      dropzone.addEventListener('dragenter', handleDragIn);
      dropzone.addEventListener('dragleave', handleDragOut);
      dropzone.addEventListener('dragover', handleDrag);
      dropzone.addEventListener('drop', handleDrop);
      dropzone.addEventListener('click', handleClick);
    }

    return () => {
      if (dropzone) {
        dropzone.removeEventListener('dragenter', handleDragIn);
        dropzone.removeEventListener('dragleave', handleDragOut);
        dropzone.removeEventListener('dragover', handleDrag);
        dropzone.removeEventListener('drop', handleDrop);
        dropzone.removeEventListener('click', handleClick);
      }
    };
  }, [
    handleClick,
    handleDragIn,
    handleDrag,
    handleDragOut,
    handleDrop,
    dropzoneRef,
  ]);

  return (
    <>
      <div
        className={cx(classes.dropZone, className, {
          [classes.dropZoneActive]: isDragging,
          [draggingClassName]: isDragging,
        })}
        ref={dropzoneRef}
      >
        <input
          onClick={e => e.stopPropagation()}
          onChange={onDocumentSelected}
          name="file"
          ref={fileInput}
          key={inputKey}
          type="file"
          hidden
        />
        {!withoutOverlay && isDragging && (
          <div className={cx(classes.content, overlayClassName)}>
            <Icon className={classes.icon} type="DocumentAdd" size={48} />
            <span
              className={cx(classes.messageText, classes.messageTextActive)}
            >
              Rilascia per selezionare il file
            </span>
          </div>
        )}
        {!isDragging && (
          <div className={classes.content}>
            <Icon
              className={classes.icon}
              type="Document"
              size={48}
              color={theme.palette.grey.dark}
            />
            <span className={classes.messageText}>
              Trascina o clicca per selezionare un file
            </span>
          </div>
        )}
        {children}
      </div>
    </>
  );
};

DragAndDrop.propTypes = {
  className: PropTypes.string,
  draggingClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  withoutOverlay: PropTypes.bool,
  onDrag: PropTypes.func,
  onDragIn: PropTypes.func,
  onDragOut: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(DragAndDrop);
