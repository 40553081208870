import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    color: theme.palette.font.red,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.font.red,
      color: theme.palette.white.main,
    },
  },
  raised: {
    backgroundColor: theme.palette.font.red,
    '&:hover': {
      backgroundColor: theme.palette.font.redDark,
    },
    color: theme.palette.white.main,
  },
  label: {
    color: 'inherit',
  },
});

export const DangerButton = withStyles(styles)(
  ({ children, className, classes, size, variant, color, onClick }) => {
    return (
      <Button
        className={className}
        classes={classes}
        size={size}
        variant={variant}
        color={color}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
);
