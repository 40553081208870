/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagValueDTO {
    Monitoraggio = 'MONITORAGGIO',
    Valutazione = 'VALUTAZIONE',
    TribunaleMinori = 'TRIBUNALE_MINORI',
    ProcuraDeiMinori = 'PROCURA_DEI_MINORI',
    TribunaleOrdinario = 'TRIBUNALE_ORDINARIO',
    ProcuraOrdinaria = 'PROCURA_ORDINARIA',
    UfficioMinori = 'UFFICIO_MINORI',
    ForzeDellOrdine = 'FORZE_DELL_ORDINE',
    ScuolaParitaria = 'SCUOLA_PARITARIA',
    ScuolaPubblica = 'SCUOLA_PUBBLICA',
    Pediatra = 'PEDIATRA',
    AccessoSpontaneo = 'ACCESSO_SPONTANEO',
    Familiari = 'FAMILIARI',
    ViciniDiCasa = 'VICINI_DI_CASA',
    AltroServizioSociale = 'ALTRO_SERVIZIO_SOCIALE',
    ServizioAsl = 'SERVIZIO_ASL',
    MedicoDiBase = 'MEDICO_DI_BASE',
    UnitaValutazioneGeriatrica = 'UNITA_VALUTAZIONE_GERIATRICA',
    ProgettoMeglioACasa = 'PROGETTO_MEGLIO_A_CASA',
    ServizioSanitario = 'SERVIZIO_SANITARIO',
    RepartoOspedaliero = 'REPARTO_OSPEDALIERO',
    Sert = 'SERT',
    NucleoAlcologia = 'NUCLEO_ALCOLOGIA',
    SaluteMentale = 'SALUTE_MENTALE',
    EsecuzionePenaleEsterna = 'ESECUZIONE_PENALE_ESTERNA',
    GiudiceTutelare = 'GIUDICE_TUTELARE',
    Volontariato = 'VOLONTARIATO',
    NeuropsichiatriaInfantile = 'NEUROPSICHIATRIA_INFANTILE',
    ConsultorioFamiliare = 'CONSULTORIO_FAMILIARE',
    ConsultorioDiocesiano = 'CONSULTORIO_DIOCESIANO',
    ProfessionistiPrivati = 'PROFESSIONISTI_PRIVATI',
    EquipeAffido = 'EQUIPE_AFFIDO',
    EquipeAdozioni = 'EQUIPE_ADOZIONI',
    CentroEducazioneMotoria = 'CENTRO_EDUCAZIONE_MOTORIA',
    CentroAslFisiatra = 'CENTRO_ASL_FISIATRA',
    Abitative = 'ABITATIVE',
    Disoccupazione = 'DISOCCUPAZIONE',
    Sanitarie = 'SANITARIE',
    Economiche = 'ECONOMICHE',
    NessunaReteFamiliare = 'NESSUNA_RETE_FAMILIARE'
}

export function FlagValueDTOFromJSON(json: any): FlagValueDTO {
    return FlagValueDTOFromJSONTyped(json, false);
}

export function FlagValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagValueDTO {
    return json as FlagValueDTO;
}

export function FlagValueDTOToJSON(value?: FlagValueDTO | null): any {
    return value as any;
}

