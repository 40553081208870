import { extendObservable, action } from 'mobx';
import Notifications from '../api/Notifications';
import { NOTIFICATIONS_POLL_INTERVAL } from '../config';

export default class NotificationListener {
  constructor() {
    extendObservable(this, {
      notifications: [],
      unreadNotificationCount: 0,
      handler: undefined,
    });
  }

  init = action(() => {
    this.getNotifications();
    this.listenNotifications();
  });

  reset = action(() => {
    if (this.handler) clearInterval(this.handler);
    this.setNotifications([]);
    this.setUnreadNotificationsCount(0);
  });

  listenNotifications = action(() => {
    this.handler = setInterval(
      this.getNotifications,
      1000 * NOTIFICATIONS_POLL_INTERVAL
    );
  });

  getNotifications = action(() => {
    Notifications.get(n => {
      this.setNotifications(n.notifications);
      this.setUnreadNotificationsCount(n.pagination.totalItemsCount);
    });
  });

  setNotifications = action(n => {
    this.notifications = n;
  });

  setUnreadNotificationsCount = action(c => {
    this.unreadNotificationCount = c;
  });

  markAsRead = action(notificationId => {
    const index = this.notifications.findIndex(n => n.id === notificationId);
    if (index > -1) {
      const notifications = [...this.notifications];
      notifications.splice(index, 1);
      this.setNotifications(notifications);
      this.setUnreadNotificationsCount(this.unreadNotificationCount - 1);
      Notifications.markAsRead(notificationId);
    }
  });
}
