import { StateMachine } from 'redux-sigma';
import { CommonEvents } from './common.events';
import { createLocalStateMachineContext } from '@moveax/redux-stm-act-react-utils';
import { useContext } from 'react';
import { call, put } from 'typed-redux-saga';
import {
  errorLoadingList,
  FilterChangedType,
  listLoaded,
  PageChangedType,
  SocialServiceListEvents,
} from './socialServiceList.events';
import { TypePagination } from '../../components/serviziSociali/constants';
import { getInstanceList } from '../../components/serviziSociali/api/api';
import {
  WelfareInstanceListItemDTO,
  WelfareInstanceListRequest,
} from '../../gen/api/welfare';

export enum SocialServiceListStates {
  loading = 'states/loading',
  loaded = 'states/loaded',
  errorLoading = 'states/errorLoading',
}

type Filters = Pick<
  WelfareInstanceListRequest,
  | 'type'
  | 'section'
  | 'status'
  | 'beneficiaryFiscalCode'
  | 'createdAfter'
  | 'createdBefore'
  | 'assigneeFiscalCode'
>;

interface Context {
  list?: WelfareInstanceListItemDTO[];
  pagination?: TypePagination;
  page?: number;
  filters?: Filters;
}

export class SocialServiceListStm extends StateMachine<
  CommonEvents & SocialServiceListEvents,
  SocialServiceListStates,
  string,
  Context
> {
  protected readonly initialState = SocialServiceListStates.loading;

  readonly name = 'serviziSocialiLista';

  protected readonly spec = {
    [SocialServiceListStates.loaded]: {
      transitions: {
        [SocialServiceListEvents.pageChanged]: {
          command: this.storePage,
          target: SocialServiceListStates.loading,
        },
        [SocialServiceListEvents.filterChanged]: {
          command: this.storeFilters,
          target: SocialServiceListStates.loading,
        },
      },
    },
    [SocialServiceListStates.loading]: {
      onEntry: this.loadList,
      transitions: {
        [SocialServiceListEvents.listLoaded]: SocialServiceListStates.loaded,
        [SocialServiceListEvents.errorLoadingList]:
          SocialServiceListStates.errorLoading,
        [SocialServiceListEvents.filterChanged]: {
          command: this.storeFilters,
          target: SocialServiceListStates.loading,
        },
      },
    },
    [SocialServiceListStates.errorLoading]: {
      transitions: {
        [CommonEvents.userPressRetry]: SocialServiceListStates.loading,
      },
    },
  };

  *storePage(event: PageChangedType) {
    console.log(event);
    yield* this.setContext(ctx => {
      ctx.page = event.payload.page - 1;
    });
  }

  *storeFilters(event: FilterChangedType) {
    console.log(event);
    yield* this.setContext(ctx => {
      ctx.filters = ctx.filters
        ? { ...ctx.filters, ...event.payload }
        : ({ ...event.payload } as Filters);
    });
  }

  *loadList() {
    try {
      const response = yield* call(
        getInstanceList,
        this.context.page,
        15,
        this.context.filters
      );
      yield* this.setContext(ctx => {
        ctx.list = response.instances;
        ctx.pagination = {
          currentPage: response.pagination.currentPage ?? 0,
          pageItemsCount: response.pagination.pageItemsCount ?? 0,
          totalItemsCount: response.pagination.totalItemsCount ?? 0,
          totalPages: response.pagination.totalPages ?? 0,
        };
      });
      yield* put(listLoaded());
    } catch (e) {
      yield* put(errorLoadingList());
    }
  }
}

export const socialServiceListStm = new SocialServiceListStm();
export const SocialServiceListContext = createLocalStateMachineContext(
  socialServiceListStm
);

export function useSocialServiceList() {
  return useContext(SocialServiceListContext);
}
