import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, Input } from '@material-ui/core';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import store from '../../../../../../store';
import useDebounce from '../../../../../../utils/hooks';
import Utenti from '../../../../../../api/Utenti';
import Loader from '../../../../../common/Loader';
import EmptyListMessage from '../../../../../common/EmptyListMessage';
import UserItem from '../../../../../sharing/SearchUser/UserItem';
import { ROLES } from '../../../../../../constants/security';

const styles = theme => ({
  container: {
    minWidth: '600px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
  loader: {
    marginTop: '20px',
  },
  results: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    flex: 1,
    padding: '24px',
    margin: 0,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  assignToMeButton: {
    marginRight: 'auto',
  },
});

const SearchUser = ({
  classes,
  open,
  onUserSelected,
  loading,
  onClose,
  onCLickAssignToMe,
}) => {
  const { profile } = store.app;
  const [selectedUser, setSelectedUser] = useState();
  const [results, setResults] = useState([]);
  const [loadingResults, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedSearchTerm = useDebounce(query, 500);

  const close = useCallback(() => {
    setSelectedUser(undefined);
    setIsLoading(false);
    setResults([]);
    onClose();
  }, [onClose]);

  const onQueryChanged = useCallback(event => {
    setQuery(event.target.value);
  }, []);

  const onConfirm = useCallback(() => {
    onUserSelected(selectedUser);
  }, [onUserSelected, selectedUser]);

  const search = useCallback(
    searchTerm => {
      setIsLoading(true);
      Utenti.search(
        {
          department: profile.operatingAs.department,
          query: searchTerm,
          onlyRoles: [ROLES.EMPLOYEE],
        },
        users => {
          setIsLoading(false);
          setResults(users);
        },
        () => {
          setIsLoading(false);
          setResults([]);
        }
      );
    },
    [profile]
  );

  useEffect(() => {
    search(debouncedSearchTerm);
  }, [debouncedSearchTerm, search]);

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={close}
      aria-labelledby="search-user-dialog-title"
      aria-describedby="search-user-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle
          className={classes.dialogTitle}
          id="search-user-dialog-title"
        >
          <span>Assegna ad un utente</span>
          <span className={classes.closeIcon} onClick={close}>
            &times;
          </span>
        </DialogTitle>
        {!loading && (
          <DialogContent>
            <DialogContentText>Assegna l&apos;istanza a:</DialogContentText>
            <FormControl style={{ width: '100%', marginTop: '30px' }}>
              <InputLabel htmlFor="search" shring={true}>
                Cerca Utente
              </InputLabel>
              <Input
                autoFocus={true}
                placeholder="Inizia a digitare..."
                id="search"
                value={query}
                onChange={onQueryChanged}
              />
            </FormControl>
            <Loader className={classes.loader} loading={loadingResults} />
            {!loadingResults && (
              <div className={classes.results}>
                <EmptyListMessage
                  visible={results.length === 0}
                  message={
                    query
                      ? 'Nessun utente trovato'
                      : 'Inizia a digitare per visualizzare i risultati'
                  }
                />
                {results.map(user => (
                  <UserItem
                    key={user.id}
                    user={user}
                    selected={selectedUser && selectedUser.id === user.id}
                    onClick={setSelectedUser}
                  />
                ))}
              </div>
            )}
          </DialogContent>
        )}
        {loading && (
          <DialogContent>
            <DialogContentText>Assegnazione in corso...</DialogContentText>
            <Loader loading={true} />
          </DialogContent>
        )}
        {!loading && (
          <DialogActions
            classes={{
              root: classes.root,
              action: classes.action,
            }}
          >
            {onCLickAssignToMe && (
              <Button
                onClick={onCLickAssignToMe}
                className={classes.assignToMeButton}
                variant="outlined"
                color="primary"
              >
                Assegna a me
              </Button>
            )}
            <Button onClick={close} className={classes.marginRightButton}>
              Annulla
            </Button>
            <Button
              className={classes.marginRightButton}
              onClick={onConfirm}
              variant="raised"
              color="primary"
              disabled={!selectedUser}
            >
              Conferma
            </Button>
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};

export const AssignInstanceSearchUser = withStyles(styles)(SearchUser);
