import React, { FC, useCallback } from 'react';
import {
  StyledComponentProps,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import moment from 'moment';
import cx from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SecretariatActivityListItem } from '../../../api/model';
import tableStyle from '../../../../../styles/table.style';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';
import { compile } from 'path-to-regexp';
import AssigneeItem from '../../../common/AssigneeItem';

const styles = (theme: any) => ({
  ...tableStyle(),
  customBody: {
    height: '64px',
    '& td:first-child': {
      padding: '0px',
      '& > div': {
        padding: '0px 24px',
        height: '48px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
    },
    '& td:not(:first-child)': {
      padding: '0px',
      '& > div': {
        padding: '0px 24px ',
        height: '48px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderLeft: '1px solid',
        borderLeftColor: '#e0e0e0',
      },
    },
  },
  type: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  section: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
  },
  kind: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
  },
  action: {
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '100px',
      backgroundColor: '#e0e0e0',
      opacity: '0.5',
    },
  },
  assignee: {
    flexDirection: 'row!important',
    alignItems: 'center!important',
  },
});

interface ItemProps extends StyledComponentProps, RouteComponentProps {
  activity: SecretariatActivityListItem;
}

const Item: FC<ItemProps> = ({ classes = {}, activity, history }) => {
  const onClick = useCallback(() => {
    const toPath = compile(
      `/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_SEGRETARIATO}`,
      {
        encode: encodeURIComponent,
      }
    );
    history.push(toPath({ activityId: activity.id }));
  }, [history, activity]);
  return (
    <TableRow className={cx([classes.bodyRow, classes.customBody])}>
      <TableCell>
        <div>{`${activity.number}/${activity.year}`}</div>
      </TableCell>
      <TableCell>
        <div>{activity.citizenFiscalCode}</div>
      </TableCell>
      <TableCell>
        <div>
          {moment(activity.createdAt).format('DD/MM/YYYY [alle] HH:mm')}
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.assignee}>
          <AssigneeItem
            firstName={activity.createdBy.firstName}
            lastName={activity.createdBy.lastName}
          />
        </div>
      </TableCell>
      <TableCell>
        <div style={{ paddingRight: '12px' }}>
          <ChevronRight onClick={onClick} className={classes.action} />
        </div>
      </TableCell>
    </TableRow>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedItem = withStyles(styles)(withRouter(Item));

export { DecoratedItem as Item };
