import React, { useCallback, useState } from 'react';
import ShareButton from './ShareButton';
import Dialog from './Dialog';
import PropTypes from 'prop-types';

const Widget = props => {
  const { onProcedureShared, buttonProps, procedure } = props;
  const [dialogOpened, setDialogOpened] = useState(false);
  const onShareClicked = useCallback(() => {
    setDialogOpened(true);
  }, []);

  const onDialogClosed = useCallback(
    shouldUpdate => {
      setDialogOpened(false);
      if (shouldUpdate) onProcedureShared();
    },
    [onProcedureShared]
  );

  return (
    <>
      <ShareButton {...buttonProps} onClick={onShareClicked} />
      <Dialog
        open={dialogOpened}
        procedure={procedure}
        onClose={onDialogClosed}
      />
    </>
  );
};

Widget.propTypes = {
  buttonProps: PropTypes.object,
  procedure: PropTypes.object.isRequired,
  onProcedureShared: PropTypes.func,
};

Widget.defaultProps = {
  buttonProps: {},
  onProcedureShared: () => {},
};

export default Widget;
