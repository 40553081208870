import React from 'react';
import Api from '../../../api/Edilizia';
import SharingApi from '../../../api/Sharing';
import PageContainer from '../../common/PageContainer';
import Procedure from '../../common/Procedure';
import PageTitle from '../../common/PageTitle';
import Loader from '../../common/Loader';
import store from '../../../store';
import { isProcedureOwner } from '../../../constants/common';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_HUMAN,
} from '../../common/filters/constants';
import ROUTES from '../../../constants/routes';

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputKey: Date.now(),
      loading: false,
      loadingDocuments: false,
      loadingEvents: false,
      uploadingDocument: false,
      procedure: null,
      documents: [],
      events: [],
      paginationEvents: {},
      downloadingDocument: null,
    };
  }

  componentDidMount() {
    this.loadProcedure(this.props.match.params.procedureId);
  }

  componentWillReceiveProps(nextProps, _nextContext) {
    if (
      nextProps.match.params.procedureId !== this.props.match.params.procedureId
    ) {
      this.loadProcedure(nextProps.match.params.procedureId);
    }
  }

  loadProcedure = procedureId => {
    this.setState(
      {
        loading: true,
      },
      () => {
        Api.get(
          procedureId,
          procedure => {
            this.setState({
              loading: false,
              procedure,
            });
            this.loadDocuments(procedure);
            if (isProcedureOwner(procedure.type)) this.loadEvents(procedureId);
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      }
    );
  };

  loadDocuments = procedure => {
    const isOwner = isProcedureOwner(procedure.type);
    const procedureId = isOwner ? procedure.id : this.state.sharedProcedureId;
    const callApi = isOwner ? Api.getDocuments : SharingApi.getSharedDocuments;
    this.setState(
      {
        loadingDocuments: true,
      },
      () => {
        callApi(
          procedureId,
          documents => {
            this.setState({
              loadingDocuments: false,
              documents: documents,
            });
          },
          () => {
            this.setState({
              loadingDocuments: false,
              documents: [],
            });
          }
        );
      }
    );
  };

  loadEvents = (pageNumber = { page: 1 }) => {
    const procedureId = this.props.match.params.procedureId;
    this.setState(
      {
        loadingEvents: true,
      },
      () => {
        Api.getEvents(
          procedureId,
          pageNumber,
          response => {
            this.setState({
              loadingEvents: false,
              paginationEvents: response.meta.pagination,
              events: response.events,
            });
          },
          () => {
            this.setState({
              loadingEvents: false,
              paginationEvents: {},
              events: [],
            });
          }
        );
      }
    );
  };

  onDocumentClick = documentId => {
    const isOwner = isProcedureOwner(this.state.procedure.type);
    const downloadApi = isOwner
      ? Api.downloadDocument
      : SharingApi.downloadSharedDocument;
    this.setState(
      {
        downloadingDocument: documentId,
      },
      () => {
        downloadApi(
          this.state.procedure.id,
          documentId,
          () => {
            this.setState({
              downloadingDocument: null,
            });
          },
          () => {
            this.setState({
              downloadingDocument: null,
            });
            store.app.snackBar.open('Errore nel download del file.');
          }
        );
      }
    );
  };

  uploadDocument = event => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      store.app.snackBar.open(
        `Il file selezionato è troppo grande (Max ${MAX_FILE_SIZE_HUMAN} MB)`
      );
      return;
    }
    this.setState(
      {
        uploadingDocument: true,
      },
      () =>
        Api.uploadDocument(
          this.state.procedure.id,
          file,
          documents => {
            this.setState({
              inputKey: Date.now(),
              uploadingDocument: false,
              documents,
            });
            store.app.snackBar.open('File caricato con successo!');
          },
          () => {
            this.setState({
              inputKey: Date.now(),
              uploadingDocument: false,
            });
            store.app.snackBar.open(
              "Errore nell'upload del file, riprova in seguito."
            );
          }
        )
    );
  };

  onUploadClicked = instance => () => {
    instance._fileInput.click();
  };

  doProcedureAction = (
    action,
    data = [],
    onSuccess = () => {},
    onError = () => {}
  ) => {
    action(
      this.state.procedure.id,
      procedure => {
        this.setState(
          {
            procedure,
          },
          onSuccess
        );
      },
      error => {
        store.app.snackBar.open(
          'Si è verificato un errore imprevisto, se il problema persiste contatta gli amministratori.'
        );
        onError(error);
      },
      ...data
    );
  };

  onAdditionalDocumentsRequested = (documents, onSuccess, onFailure) => {
    const procedureId = this.props.match.params.procedureId;
    Api.requestDocuments(
      procedureId,
      documents,
      procedure => {
        this.setState(
          {
            procedure,
          },
          onSuccess
        );
      },
      () => {
        store.app.snackBar.open(
          'Si è verificato un errore imprevisto, se il problema persiste contatta gli amministratori.'
        );
        onFailure();
      }
    );
  };

  onExtensionAction = () => {
    this.loadProcedure(this.props.match.params.procedureId);
  };

  goToUserDetails = user => {
    this.props.history.push(`/${ROUTES.UTENTI}/${user.id}`);
  };

  render() {
    const {
      procedure,
      loading,
      loadingDocuments,
      documents,
      downloadingDocument,
      loadingEvents,
      events,
      paginationEvents,
      uploadingDocument,
    } = this.state;
    const instance = this;
    const isOwner = procedure && isProcedureOwner(procedure.type);

    return (
      <PageContainer
        header={
          <PageTitle
            title="Dettaglio Pratica"
            subtitle={
              procedure ? (
                <span>{`Pratica n° ${procedure.id} del ${
                  procedure.created_at.split(' ')[0]
                }`}</span>
              ) : (
                '-'
              )
            }
            backIcon={true}
          />
        }
      >
        <Loader size={40} loading={loading} />
        <input
          multiple={false}
          onChange={this.uploadDocument}
          name="file"
          ref={node => (instance._fileInput = node)}
          key={this.state.inputKey}
          type="file"
          hidden
        />
        {procedure && !loading && (
          <Procedure
            procedure={procedure}
            loadDocuments={this.loadDocuments}
            loadingDocuments={loadingDocuments}
            documents={documents}
            onDocumentClick={this.onDocumentClick}
            uploadingDocument={uploadingDocument}
            downloadingDocument={downloadingDocument}
            loadEvents={this.loadEvents}
            loadingEvents={loadingEvents}
            events={events}
            paginationEvents={paginationEvents}
            doProcedureAction={this.doProcedureAction}
            onUploadClicked={this.onUploadClicked(instance)}
            onAdditionalDocumentsRequested={this.onAdditionalDocumentsRequested}
            onExtensionApproved={this.onExtensionAction}
            onExtensionRejected={this.onExtensionAction}
            goToUserDetails={this.goToUserDetails}
            isOwner={isOwner}
          />
        )}
      </PageContainer>
    );
  }
}

export default observer(withRouter(Detail));
