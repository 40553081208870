/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback, useState } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { CitizenContext } from '../../serviziSociali/api/model';
import { FlagDisplay } from '../../serviziSociali/common/FlagDisplay';
import { FamilyPanel } from '../../serviziSociali/common/FamilyPanel';
import { Col, Row } from 'react-flexbox-grid';
import { FlagTypeDTO, FlagValueDTO } from '../../../gen/api/welfare';
import { EditQuestionarioDialog } from './EditQuestionarioDialog';
import { useDialog } from '../../serviziSociali/common/upload/useDialog';
import { RemoveFamilyMembersDialog } from './RemoveFamilyMemberDialog';
import { AddFamilyMembersDialog } from './AddFamilyMembersDialog';
import { AddRelativeMembersDialog } from './AddRelativeMembersDialog';
import { RemoveRelativeMembersDialog } from './RemoveRelativeMemberDialog';
import TextLinkPrimary from '../../common/TextLinkPrimary';

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleSection: {
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },

  subtitleSection: {
    padding: '8px 24px',
    margin: '8px -24px 0 -24px',
    backgroundColor: '#F5F7FF',
    display: 'flex',
    justifyContent: 'space-between',
  },

  subtitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 700,
  },

  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
  subsectionContainer: {
    margin: '24px 0px',
  },
  familyMemberCard: {
    paddingBottom: '16px',
  },
});

interface CitizenSectionProps extends StyledComponentProps {
  data: CitizenContext;
  fiscalCode: string;
  setCitizenContext: (value: CitizenContext) => void;
}

const _PersonalSituation: FC<CitizenSectionProps> = ({
  classes = {},
  data,
  setCitizenContext,
  fiscalCode,
}) => {
  const { flags, familyMembers, relativeMembers } = data;
  const flagsArray = Object.entries(flags)
    .sort(([idA], [idB]) => {
      //sorting by alphabetical order of keys to display consistently
      return idA < idB ? -1 : idA > idB ? 1 : 0;
    })
    .reduce((acc, [type, data], idx) => {
      const matrixIndex = Math.floor(idx / 3);
      const curr = { type: type as FlagTypeDTO, data: data };
      acc[matrixIndex] =
        acc[matrixIndex] === undefined ? [curr] : [...acc[matrixIndex], curr];
      return acc;
    }, [] as { type: FlagTypeDTO; data: string[] | undefined }[][]);
  const editQuestionarioDialog = useDialog();
  const addFamilyMemberDialog = useDialog();
  const removeFamilyMemberDialog = useDialog();
  const addRelativeMemberDialog = useDialog();
  const removeRelativeMemberDialog = useDialog();
  const [fiscalCodeToDelete, setFiscalCodeToDelete] = useState<
    string | undefined
  >();
  const openRemoveFamilyMemberDialog = useCallback(
    newFiscalCodeToDelete => () => {
      setFiscalCodeToDelete(newFiscalCodeToDelete);
      removeFamilyMemberDialog.openDialog();
    },
    [removeFamilyMemberDialog]
  );
  const openRemoveRelativeMemberDialog = useCallback(
    newFiscalCodeToDelete => () => {
      setFiscalCodeToDelete(newFiscalCodeToDelete);
      removeRelativeMemberDialog.openDialog();
    },
    [removeRelativeMemberDialog]
  );
  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Situazione personale</label>
      </div>
      <div>
        <div className={classes.subtitleSection} style={{ marginTop: '0px' }}>
          <label className={classes.subtitle}>Questionario</label>
          <TextLinkPrimary bold onClick={editQuestionarioDialog.openDialog}>
            Modifica
          </TextLinkPrimary>
        </div>
        {flagsArray.length < 1 ? (
          <div className={classes.subsectionContainer}>
            <label>Nessuna informazione</label>
          </div>
        ) : (
          <div>
            {flagsArray.map((flagsLine, idx) => {
              return (
                <div className={classes.fieldContainer} key={`flagline-${idx}`}>
                  {flagsLine.map(({ type, data }) => {
                    return (
                      <FlagDisplay
                        flag={{
                          type,
                          values: data as FlagValueDTO[],
                        }}
                        key={`flagvalue-${type}`}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>
        <div className={classes.subtitleSection}>
          <label className={classes.subtitle}>Nucleo Familiare</label>
          <TextLinkPrimary bold onClick={addFamilyMemberDialog.openDialog}>
            Aggiungi
          </TextLinkPrimary>
        </div>
        <div className={classes.subsectionContainer}>
          {familyMembers.length < 1 ? (
            <label>Nessuna Informazione</label>
          ) : (
            <Row>
              {familyMembers.map(item => {
                return (
                  <Col
                    xs={3}
                    key={item.id}
                    className={classes.familyMemberCard}
                  >
                    <FamilyPanel
                      item={item}
                      onRemove={openRemoveFamilyMemberDialog(item.fiscalCode)}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
      <div>
        <div className={classes.subtitleSection}>
          <label className={classes.subtitle}>
            Congiunti obbligati al mantenimento
          </label>
          <TextLinkPrimary bold onClick={addRelativeMemberDialog.openDialog}>
            Aggiungi
          </TextLinkPrimary>
        </div>
        <div className={classes.subsectionContainer}>
          {relativeMembers.length < 1 ? (
            <label>Nessuna Informazione</label>
          ) : (
            <Row>
              {relativeMembers.map(item => {
                return (
                  <Col
                    xs={3}
                    className={classes.familyMemberCard}
                    key={item.id}
                  >
                    <FamilyPanel
                      item={item}
                      onRemove={openRemoveRelativeMemberDialog(item.fiscalCode)}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
      {editQuestionarioDialog.open && (
        <EditQuestionarioDialog
          closeDialog={editQuestionarioDialog.closeDialog}
          defaultCitizenData={data}
          fiscalCode={fiscalCode}
          updateCitizenContext={setCitizenContext}
        />
      )}
      {addFamilyMemberDialog.open && (
        <AddFamilyMembersDialog
          closeDialog={addFamilyMemberDialog.closeDialog}
          defaultCitizenData={data}
          fiscalCode={fiscalCode}
          updateCitizenContext={setCitizenContext}
          forbiddenCFValues={familyMembers.map(fm => fm.fiscalCode)}
        />
      )}
      {removeFamilyMemberDialog.open && (
        <RemoveFamilyMembersDialog
          closeDialog={removeFamilyMemberDialog.closeDialog}
          defaultCitizenData={data}
          fiscalCode={fiscalCode}
          updateCitizenContext={setCitizenContext}
          fiscalCodeToDelete={fiscalCodeToDelete}
        />
      )}
      {addRelativeMemberDialog.open && (
        <AddRelativeMembersDialog
          closeDialog={addRelativeMemberDialog.closeDialog}
          defaultCitizenData={data}
          fiscalCode={fiscalCode}
          updateCitizenContext={setCitizenContext}
          forbiddenCFValues={relativeMembers.map(rm => rm.fiscalCode)}
        />
      )}
      {removeRelativeMemberDialog.open && (
        <RemoveRelativeMembersDialog
          closeDialog={removeRelativeMemberDialog.closeDialog}
          defaultCitizenData={data}
          fiscalCode={fiscalCode}
          updateCitizenContext={setCitizenContext}
          fiscalCodeToDelete={fiscalCodeToDelete}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedPersonalSituation = withStyles(styles)(_PersonalSituation);

export { DecoratedPersonalSituation as PersonalSituation };
