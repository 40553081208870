import React, { FC, useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { TemporaryDocument } from '../../api/model';
import { RadioButton } from '../../common/RadioButton';
import { SectionTitle } from '../../common/SectionTitle';
import { Button, StyledComponentProps } from '@material-ui/core';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import { ChevronLeft, FileUpload as UploadIcon } from '@material-ui/icons';
import { userPressedBack } from '../../../../stm/socialServices/common.events';
import { useDialog } from '../../common/upload/useDialog';
import { UploadDialog } from '../../common/upload/Dialog';
import { DocumentRow } from '../../common/DocumentRow';
import { deleteTemporaryDocument } from '../../api/api';
import { userSelectRequestorType } from '../../../../stm/socialServices/newProcedure.events';
import { requestorTypeLabelMap } from '../../constants';
import { WelfareRequestorTypeDTO } from '../../../../gen/api/welfare';

const styles = (theme: any) => ({
  container: {
    flex: 1,
    width: '40%',
    padding: '16px 48px',
    overflow: 'hidden',
  },
  subtitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '500',
    color: theme.palette.grey.dark,
    marginTop: '18px',
    marginBottom: '46px',
  },
  footer: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    alignSelf: 'stretch',
    padding: '24px 32px 48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  documentUpload: {
    width: '100%',
    marginTop: '24px',
    animationName: 'slidein',
    animationDuration: '0.5s',
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    gap: '8px',
    paddingTop: '8px',
  },
});

const SelectRequestorType: FC<StyledComponentProps> = ({ classes }) => {
  const [requestorType, setRequestorType] = useState<
    WelfareRequestorTypeDTO | undefined
  >();
  const { open, openDialog, closeDialog } = useDialog();
  const [documents, setDocuments] = useState<TemporaryDocument[]>([]);

  const { dispatch } = useNewSocialService();

  const onChangeRequestor = useCallback(
    rt => () => {
      setRequestorType(rt);
    },
    [setRequestorType]
  );

  const onConfirm = useCallback(() => {
    dispatch(userSelectRequestorType(requestorType!, documents));
  }, [dispatch, documents, requestorType]);

  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  const onDocumentUpload = useCallback((document: TemporaryDocument) => {
    setDocuments(docs => [...docs, document]);
  }, []);

  const removeDocument = useCallback(
    id => () => {
      deleteTemporaryDocument(id).catch(console.warn);
      setDocuments(docs => docs.filter(doc => doc.fid !== id));
    },
    []
  );

  const goOnDisabled = !requestorType;
  return (
    <>
      <SectionTitle>
        Il cittadino richiede il servizio in qualità di
      </SectionTitle>
      <div className={classes!.subtitle}>
        Nel caso di tutore, procuratore o amministratore di sostegno si dovranno
        inserire i documenti di riconoscimento necessari.
      </div>
      <div className={classes!.container}>
        {Object.values(WelfareRequestorTypeDTO).map(reqtype => (
          <React.Fragment key={reqtype}>
            <RadioButton
              onClick={onChangeRequestor}
              kind={{ id: reqtype, text: requestorTypeLabelMap[reqtype] }}
              selected={reqtype === requestorType}
            />
          </React.Fragment>
        ))}
        {!requestorType ||
          (requestorType !== WelfareRequestorTypeDTO.Beneficiario && (
            <>
              <Button
                disabled={open}
                color="primary"
                onClick={openDialog}
                variant="outlined"
                className={classes!.documentUpload}
              >
                <UploadIcon /> Allega documentazione
              </Button>
              <div className={classes!.documentContainer}>
                {documents?.map(doc => (
                  <DocumentRow
                    key={doc.fid}
                    document={doc}
                    onDelete={removeDocument}
                  />
                ))}
              </div>
            </>
          ))}
      </div>

      <div className={classes!.footer}>
        <Button variant="flat" color="primary" onClick={goBack}>
          <ChevronLeft />
          Indietro
        </Button>
        <Button
          variant="raised"
          color="primary"
          onClick={onConfirm}
          disabled={goOnDisabled}
        >
          Avanti
        </Button>
      </div>
      <UploadDialog
        open={open}
        onClose={closeDialog}
        onDocumentUploaded={onDocumentUpload}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSelectRequestType = withStyles(styles)(SelectRequestorType);

export { DecoratedSelectRequestType as SelectRequestorType };
