import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { Tooltip } from '@material-ui/core';
import { Visibility as DetailsIcon } from '@material-ui/icons';
import { Clear as CancelSendIcon } from '@material-ui/icons';
import { PraticaNumber } from '../../common/PraticaNumber';
import classNames from 'classnames';
import {
  getLabelProcedureState,
  PROCEDURE_STATES,
  getLabelProcedureType,
} from '../../../constants/common';
import ConfirmButton from '../../common/ConfirmButton';

const styles = theme => ({
  container: {
    margin: '5px 0px',
    border: 'solid 1px',
    padding: '10px 15px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    borderRadius: '1px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    display: 'flex',
    alignItems: 'center',
    height: '90px',
  },
  details: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  contents: {
    fontSize: '12px',
  },
  header: {
    ...theme.typography.bodyStrong,
  },
  subject: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  praticaWithActions: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  },
  praticaWithActionsContainer: {
    fontSize: '14px',
  },
  praticaWithActionsElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  praticaStateElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  praticaWithActionsHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  praticaWithActionsValue: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  mainActionButton: {
    border: '2px solid ' + theme.palette.primary.main,
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  statusBadge: {
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
  },
  stateNew: {
    backgroundColor: '#0277bd',
  },
  statePending: {
    backgroundColor: '#ffb300',
  },
  stateRejected: {
    backgroundColor: '#ba0101',
  },
  stateCompleted: {
    backgroundColor: '#00600f',
  },
});

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
}))(Tooltip);

export const PraticaWithActions = withStyles(styles)(
  withRouter(
    class extends React.Component {
      static propTypes = {
        goTo: PropTypes.string,
        procedure: PropTypes.object.isRequired,
      };

      onDetailsClick = () => {
        if (this.props.goToDetails !== null) {
          this.props.history.push(this.props.goToDetails);
        }
      };

      onSendClick = () => {
        if (this.props.onSendClicked !== null) {
          this.props.onSendClicked(this.props.procedure.id);
        }
      };

      onCancelClick = onFinished => {
        if (this.props.onCancelClicked !== null) {
          this.props.onCancelClicked(this.props.procedure.id, onFinished);
        }
      };

      render() {
        const { classes, procedure } = this.props;
        return (
          <div className={classes.container}>
            <PraticaNumber
              number={procedure.number}
              year={procedure.year}
              sub={procedure.sub}
            />
            <ProcedureStatus state={procedure.status} />
            <Row className={classes.praticaWithActions}>
              <Col xs={10} className={classes.praticaWithActionsContainer}>
                <Row>
                  <Col xs={4} className={classes.praticaWithActionsElement}>
                    <span className={classes.praticaWithActionsHeader}>
                      TIPO
                    </span>
                    <span className={classes.praticaWithActionsValue}>
                      {getLabelProcedureType(procedure.type)}
                    </span>
                  </Col>
                  <Col xs={4} className={classes.praticaWithActionsElement}>
                    <span className={classes.praticaWithActionsHeader}>
                      REFERENTE
                    </span>
                    <span className={classes.praticaWithActionsValue}>
                      {procedure.owner ? procedure.owner : '-'}
                    </span>
                  </Col>
                  <Col xs={4} className={classes.praticaWithActionsElement}>
                    <span className={classes.praticaWithActionsHeader}>
                      UBICAZIONE
                    </span>
                    <span className={classes.praticaWithActionsValue}>
                      {procedure.location ? procedure.location : '-'}
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                  <Col xs={12} className={classes.praticaWithActionsElement}>
                    <span className={classes.praticaWithActionsHeader}>
                      OGGETTO
                    </span>
                    <span className={classes.praticaWithActionsValue}>
                      {procedure.subject ? procedure.subject : '-'}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col className={classes.details} xs={2}>
                {procedure.status === PROCEDURE_STATES.NEW && (
                  <Button
                    size="small"
                    variant="raised"
                    color="primary"
                    className={classes.mainActionButton}
                    onClick={this.onSendClick}
                  >
                    Invia
                  </Button>
                )}
                <BiggerTooltip
                  title="Visualizza Dettagli"
                  className={classes.tooltip}
                >
                  <Button
                    size="small"
                    variant="raised"
                    color="default"
                    className={classes.smallActionButton}
                    onClick={this.onDetailsClick}
                  >
                    <DetailsIcon className={classes.smallActionButtonIcon} />
                  </Button>
                </BiggerTooltip>
                {procedure.status === PROCEDURE_STATES.NEW && (
                  <BiggerTooltip
                    title="Non Inviare"
                    className={classes.tooltip}
                  >
                    <ConfirmButton
                      buttonProps={{
                        size: 'small',
                        variant: 'raised',
                        color: 'default',
                        className: classes.smallActionButton,
                      }}
                      buttonLabel={
                        <CancelSendIcon
                          className={classes.smallActionButtonIcon}
                        />
                      }
                      onConfirm={this.onCancelClick}
                      confirmMessage="Conferma operazione"
                      text={
                        "Sei sicuro di voler saltare l'invio della pratica? L'azione non è reversibile"
                      }
                      withLoading
                    />
                  </BiggerTooltip>
                )}
              </Col>
            </Row>
          </div>
        );
      }
    }
  )
);

const ProcedureStatus = withStyles(styles)(props => {
  const { classes, state } = props;

  return (
    <span
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateNew]: state === PROCEDURE_STATES.NEW,
        [classes.statePending]: state === PROCEDURE_STATES.PENDING,
        [classes.stateRejected]: state === PROCEDURE_STATES.SKIPPED,
        [classes.stateCompleted]: state === PROCEDURE_STATES.COMPLETED,
      })}
    >
      {getLabelProcedureState(state)}
    </span>
  );
});

ProcedureStatus.propTypes = {
  state: PropTypes.string.isRequired,
};

PraticaWithActions.propTypes = {
  onSendClicked: PropTypes.func,
  goToDetails: PropTypes.string,
  onCancelClicked: PropTypes.func,
  procedure: PropTypes.object.isRequired,
};

PraticaWithActions.defaultProps = {
  onSendClicked: null,
  goToDetails: null,
  onCancelClicked: null,
};
