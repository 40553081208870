import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import store from '../../../store';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import FilterButtons from '../../common/FilterButtons';
import { OnlyDate as DatePicker } from '../../common/Picker';
import { FILTER_TYPE } from './constants';
import { Button } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { ExpandLess } from '@material-ui/icons';
import classnames from 'classnames';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import FilterItem from './Filter';

import moment from 'moment';
import 'moment/locale/it';
import FilterChip from './FilterChip';
import { DangerButton } from '../DangerButton';

moment.locale('it');

const styles = theme => ({
  filterContainer: {
    margin: '0px 0px 15px',
    fontSize: '12px',
    borderRadius: '6px',
    padding: '12px 16px',
    backgroundColor: theme.palette.grey.light,
  },
  filterElement: {
    margin: '5px 0px',
  },
  filterForm: {
    marginTop: '15px',
    paddingTop: '15px',
  },
  formControl: {
    margin: '0px',
    minWidth: 130,
    width: '100%',
  },
  filtersHeader: {
    display: 'flex',
    '&:last-child': {
      marginLeft: 'auto',
    },
  },
  filter: {
    alignSelf: 'flex-start',
  },
  filterList: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    '&:last-child': {
      marginLeft: 'auto',
    },
  },
  resetAll: {
    color: theme.palette.font.red,
    alignSelf: 'flex-start',
  },
  resetButton: {
    color: theme.palette.font.red,
  },
  filterBadge: {
    backgroundColor: theme.palette.primary.main,
    height: 24,
    borderRadius: '50%',
    color: '#fff',
    width: '24px',
    lineHeight: '23px',
    fontWeight: '600',
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  filterBadgeActive: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },
  buttonIcon: {
    marginRight: '5px',
  },
  chipContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.primary.dark,
  },
  chipTitle: {
    fontWeight: 'normal',
    marginRight: 5,
  },
  filterButton: {
    marginRight: '20px',
    borderRadius: '100px',
    height: '34px',
    border: '2px solid ' + theme.palette.primary.main,
  },
});

class GenericFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      showFilters: false,
      activeFiltersCount: 0,
      activeFilters: {},
      hasErrors: {},
      errors: {},
    };
  }

  componentDidMount() {
    if (
      this.props.hasOwnProperty('applyFiltersOnMount')
        ? this.props.applyFiltersOnMount
        : true
    ) {
      const defaultValues = this.getDefaultFilters();
      this.setState({ filters: defaultValues }, () => {
        this.applyFilters();
      });
    }
  }

  handleFilter = name => event => {
    const errors = this.state.errors;
    const filters = this.state.filters;
    const filterItem = this.props.filters.find(o => o.id === name);
    let value = event.target.value;

    if (filterItem.modifier) {
      value = filterItem.modifier(value);
    }

    if (errors.hasOwnProperty(filterItem.id)) delete errors[filterItem.id];

    filters[name] = value;
    this.setState({ filters: filters, errors: errors });
  };

  handleFilterDate = name => dateValue => {
    const filters = this.state.filters;
    const filterItem = this.props.filters.find(o => o.id === name);

    if (filterItem.modifier && dateValue) {
      dateValue = filterItem.modifier(dateValue);
    }

    filters[name] = dateValue != null ? dateValue.unix() : null;
    this.setState({ filters: filters });
  };

  applyFilters = () => {
    this.setState(
      {
        activeFiltersCount: this.getActiveFiltersCount(),
        activeFilters: this.getActiveFilters(),
        hasErrors: false,
        errors: {},
      },
      () => {
        if (this.validateFilters()) {
          if (this.props.listName) {
            store.app.setFilters(this.props.listName, this.state.filters);
          }

          this.props.applyFilters(this.state.filters);
        }
      }
    );
  };

  validateFilters = () => {
    if (this.state.activeFiltersCount === 0) return true;

    const filterItems = this.props.filters;
    const filters = this.getActiveFilters();

    let isValid = true;

    for (const key in filters) {
      const filter = filters[key];
      const filterItem = filterItems.find(o => o.id === filter.id);
      const fieldValid = this.validateField(
        filter.id,
        filter.value,
        filterItem.type
      );
      isValid = fieldValid ? isValid : false;
    }

    this.setState({
      hasErrors: !isValid,
    });

    return isValid;
  };

  validateField = (name, value, type) => {
    const errors = this.state.errors;
    let isValid = true;

    if (type === FILTER_TYPE.NUMBER) {
      isValid = !isNaN(value);
      if (!isValid) {
        errors[name] = 'Il valore inserito deve essere numerico';
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  };

  resetFilters = () => {
    if (this.props.listName) {
      store.app.setFilters(this.props.listName, {});
    }

    const defaultValues = this.getDefaultFilters();
    this.setState({ filters: defaultValues }, () => {
      this.setState({
        activeFiltersCount: this.getActiveFiltersCount(),
        activeFilters: this.getActiveFilters(),
      });
      this.props.applyFilters(defaultValues);
    });
  };

  resetFilter = filterId => {
    const filterItem = this.props.filters.find(o => o.id === filterId);
    if (!filterItem) return;

    const filters = this.state.filters;
    filters[filterId] = filterItem.defaultValue;

    if (this.props.listName) {
      store.app.setFilters(this.props.listName, filters);
    }

    this.setState(
      {
        filters: filters,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.applyFilters();
    }
  };

  getDefaultFilters = () => {
    return this.props.filters.reduce((obj, filterItem) => {
      obj[filterItem.id] = this.props.listName
        ? store.app.getFilterValue(
            store.app.listFilters[this.props.listName],
            filterItem.id,
            filterItem.defaultValue
          )
        : filterItem.defaultValue;

      return obj;
    }, {});
  };

  getActiveFiltersCount = () => {
    let count = 0;

    if (!this.state.filters) return count;

    Object.keys(this.state.filters).forEach(key => {
      const value = this.state.filters[key];
      const filterItem = this.props.filters.find(filterItem => {
        return filterItem.id === key;
      });
      if (filterItem && value !== '' && value !== null && value !== undefined)
        count++;
    });

    return count;
  };

  getActiveFilters = () => {
    return this.props.filters.reduce((obj, filterItem) => {
      if (
        !this.state.filters.hasOwnProperty(filterItem.id) ||
        this.state.filters[filterItem.id] === null ||
        this.state.filters[filterItem.id] === undefined ||
        this.state.filters[filterItem.id] === ''
      ) {
        return obj;
      }

      let value = null;
      if (filterItem.type === FILTER_TYPE.SELECT) {
        filterItem.values.some(option => {
          if (option.value === this.state.filters[filterItem.id]) {
            value = option.description;
            return true;
          }
          return false;
        });
      } else if (filterItem.type === FILTER_TYPE.DATE) {
        value = moment
          .unix(this.state.filters[filterItem.id])
          .format('D/M/YYYY');
      } else {
        value = this.state.filters[filterItem.id];
      }

      if (value) {
        obj[filterItem.label] = {
          id: filterItem.id,
          value: value,
        };
      }

      return obj;
    }, {});
  };

  toggleFiltersForm = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  handleChipDelete = filterId => () => {
    this.resetFilter(filterId);
  };

  render() {
    const { classes } = this.props;
    const {
      filters,
      showFilters,
      activeFiltersCount,
      activeFilters,
      errors,
    } = this.state;

    return (
      <div className={classes.filterContainer}>
        <Row className={classes.filtersHeader}>
          <div className={classes.filter}>
            <Button
              onClick={this.toggleFiltersForm}
              size="small"
              variant={showFilters ? 'raised' : 'outline'}
              color="primary"
              className={classes.filterButton}
            >
              {showFilters ? (
                <ExpandMore className={classes.buttonIcon} />
              ) : (
                <ExpandLess className={classes.buttonIcon} />
              )}
              Filtri
              <span
                className={classnames({
                  [classes.filterBadge]: true,
                  [classes.filterBadgeActive]: showFilters,
                })}
              >
                {activeFiltersCount}
              </span>
            </Button>
          </div>
          <div className={classes.filterList}>
            {Object.keys(activeFilters).map(key => {
              return (
                <FilterChip
                  key={key}
                  label={
                    <div className={classes.chipContent}>
                      <span className={classes.chipTitle}>{key}:</span>
                      <span>{_(activeFilters[key].value).truncate(15)}</span>
                    </div>
                  }
                  onDelete={this.handleChipDelete(activeFilters[key].id)}
                />
              );
            })}
          </div>
          <div className={classes.resetAll}>
            <DangerButton
              onClick={this.resetFilters}
              size="small"
              variant="flat"
              fullWidth
            >
              Rimuovi Tutti
            </DangerButton>
          </div>
        </Row>
        {showFilters && (
          <div className={classes.filterForm}>
            <Row>
              {this.props.filters.map(filterItem => {
                return (
                  <Col
                    key={'filter-' + filterItem.id}
                    xs={filterItem.xs}
                    sm={filterItem.sm}
                    md={filterItem.md}
                    className={classes.filterElement}
                  >
                    {filterItem.type === FILTER_TYPE.DATE && (
                      <DatePicker
                        label={filterItem.label}
                        id={filterItem.id}
                        placeholder={filterItem.label}
                        onKeyPress={this.handleKeyPress}
                        clearable={true}
                        value={
                          filters[filterItem.id]
                            ? moment.unix(filters[filterItem.id])
                            : null
                        }
                        handleDate={this.handleFilterDate(filterItem.id)}
                      />
                    )}
                    {(filterItem.type === FILTER_TYPE.TEXT ||
                      filterItem.type === FILTER_TYPE.NUMBER) && (
                      <FormControl
                        className={classes.formControl}
                        error={errors.hasOwnProperty(filterItem.id)}
                      >
                        <InputLabel htmlFor={filterItem.id} shrink={true}>
                          {filterItem.label}
                        </InputLabel>
                        <Input
                          placeholder={filterItem.label}
                          onKeyPress={this.handleKeyPress}
                          id={filterItem.id}
                          value={filters[filterItem.id]}
                          onChange={this.handleFilter(filterItem.id)}
                        />
                        {errors.hasOwnProperty(filterItem.id) && (
                          <FormHelperText>
                            {errors[filterItem.id]}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                    {filterItem.type === FILTER_TYPE.SELECT && (
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          htmlFor={filters[filterItem.id] + '_FILTER'}
                        >
                          {filterItem.label}
                        </InputLabel>
                        <Select
                          value={
                            filters[filterItem.id] ? filters[filterItem.id] : ''
                          }
                          onChange={this.handleFilter(filterItem.id)}
                          onKeyPress={this.handleKeyPress}
                          fullWidth
                          inputProps={{
                            id: filters[filterItem.id] + '_FILTER',
                          }}
                        >
                          {filterItem.values.map(option => {
                            return (
                              <MenuItem key={option.value} value={option.value}>
                                {option.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Col>
                );
              })}
            </Row>
            <FilterButtons applyFilters={this.applyFilters} />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(observer(GenericFilters));

GenericFilters.propTypes = {
  applyFilters: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.instanceOf(FilterItem)),
  listName: PropTypes.string,
};

GenericFilters.defaulteProps = {
  filters: [],
  listName: null,
};
