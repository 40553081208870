import React, { useCallback, useState } from 'react';
import Dialog from './Dialog';
import PropTypes from 'prop-types';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import BiggerTooltip from '../../BiggerTooltip';

const Widget = ({
  onDocumentUploaded,
  buttonMode,
  buttonClassName,
  buttonChild,
  digitalSignatureEnabled = true,
  uploadDocumentApi,
  buttonProps = {},
}) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const onButtonClicked = useCallback(() => {
    setDialogOpened(true);
  }, []);

  const onDialogClosed = useCallback(() => {
    setDialogOpened(false);
  }, []);

  return (
    <>
      {buttonMode === 'normal' ? (
        <Button
          variant="outlined"
          disabled={dialogOpened}
          color="primary"
          onClick={onButtonClicked}
          {...buttonProps}
        >
          <UploadIcon /> Carica Documento
        </Button>
      ) : (
        <BiggerTooltip title="Carica un allegato">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            disabled={dialogOpened}
            className={buttonClassName}
            onClick={onButtonClicked}
            {...buttonProps}
          >
            {buttonChild}
          </Button>
        </BiggerTooltip>
      )}
      <Dialog
        open={dialogOpened}
        onClose={onDialogClosed}
        uploadDocumentApi={uploadDocumentApi}
        digitalSignatureEnabled={digitalSignatureEnabled}
        onDocumentUploaded={onDocumentUploaded}
      />
    </>
  );
};

Widget.propTypes = {
  buttonMode: PropTypes.string,
  buttonChild: PropTypes.any,
  buttonClassName: PropTypes.string,
  digitalSignatureEnabled: PropTypes.bool,
  onDocumentUploaded: PropTypes.func.isRequired,
};

Widget.defaultProps = {
  buttonMode: 'normal',
  digitalSignatureEnabled: true,
};

export default Widget;
