import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  message: {
    textAlign: 'center',
    color: theme.palette.grey.main,
    padding: '30px',
  },
});

const EmptyTableMessage = props => {
  const { classes, visible } = props;
  return (
    visible && (
      <div className={classes.message}>
        <strong>Nessun dato</strong>
      </div>
    )
  );
};

export default withStyles(styles)(EmptyTableMessage);

EmptyTableMessage.propTypes = {
  visible: PropTypes.bool,
};

EmptyTableMessage.defaultProps = {
  visible: false,
};
