import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import { DashBoardItemProps, DETAIL_ACTION } from './DashboardItem';
import { SECTION_LABEL_MAP, SERVICE_LABEL_MAP } from '../constants';

import { DashboardList } from './DashboardList';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import api, { DashboardData } from '../../../api/ServiziSociali';
import Loader from '../../common/Loader';
import {
  WelfareInstanceSectionDTO,
  WelfareInstanceTypeDTO,
} from '../../../gen/api/welfare';

function countInstances(
  id: WelfareInstanceSectionDTO | WelfareInstanceTypeDTO,
  data: DashboardData
): number {
  if (
    Object.values(WelfareInstanceSectionDTO).includes(
      id as WelfareInstanceSectionDTO
    )
  ) {
    //searching for section
    return Object.entries(data[id as WelfareInstanceSectionDTO]).reduce(
      (acc, [_, value]) => {
        return value ? acc + value : acc;
      },
      0
    );
  } else {
    //searching for service
    let count = 0;
    for (const sectionData of Object.values(data)) {
      count += sectionData[id as WelfareInstanceTypeDTO] ?? 0;
      if (count > 0) {
        //service has instances
        return count;
      }
    }
    //service not found
    return count;
  }
}

const DashboardPage: FC<RouteComponentProps> = ({ history, match }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<DashboardData | undefined>(undefined);

  const { section } = match.params as {
    section?: WelfareInstanceSectionDTO;
  };

  const loadData = useCallback(() => {
    setIsLoading(true);
    api.dashboard.get(
      d => {
        setData(d);
        setIsLoading(false);
      },
      error => {
        console.error('on get Dashboard data: ', error);
      }
    );
  }, []);

  const items = useMemo(() => {
    if (!data) return [];
    if (!section) {
      return Object.entries(data).map(([s, _]) => ({
        title: SECTION_LABEL_MAP[s as WelfareInstanceSectionDTO],
        count: countInstances(s as WelfareInstanceSectionDTO, data),
        history,
        detailAction: {
          type: DETAIL_ACTION.SHOW_SUBSECTION,
          payload: s as WelfareInstanceSectionDTO,
        },
      }));
    }
    return Object.entries(data[section]).map(([s, _]) => ({
      title: SERVICE_LABEL_MAP[s as WelfareInstanceTypeDTO],
      count: countInstances(s as WelfareInstanceTypeDTO, data),
      history,
      detailAction: {
        type: DETAIL_ACTION.GOTO_FILTERED_LIST,
        payload: s as WelfareInstanceSectionDTO,
      },
    }));
  }, [data, history, section]);

  useEffect(loadData, []);

  return (
    <PageContainer
      header={
        <PageTitle
          /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
          // @ts-ignore
          title={section ? SECTION_LABEL_MAP[section] : 'Dashboard'}
          backIcon={!!section}
        />
      }
    >
      <Loader size={40} loading={isLoading} />
      {!isLoading && <DashboardList items={items as DashBoardItemProps[]} />}
    </PageContainer>
  );
};
const DecoratedDashboard = withRouter(DashboardPage);

export { DecoratedDashboard as DashboardPage };
