import React, { useMemo } from 'react';
import { CERTIFICATE_REQUEST_STATE } from '../../../constants/common';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import Widget from '../../sharing/Share/Widget';

const styles = theme => ({
  circularLoader: {
    color: theme.palette.grey.dark,
    marginRight: 10,
  },
});

export default withStyles(styles)(
  ({
    classes,
    selectedRequest,
    downloadCertificate,
    downloadingCertificate,
  }) => {
    const isSuccessful = useMemo(() => {
      return (
        selectedRequest.details.status === CERTIFICATE_REQUEST_STATE.SUCCESSFUL
      );
    }, [selectedRequest.details.status]);

    return (
      <>
        {selectedRequest && (
          <>
            <Widget instanceId={selectedRequest.details.id} />
            {isSuccessful && (
              <Button
                style={{
                  marginLeft: 8,
                }}
                color="primary"
                variant="raised"
                onClick={downloadCertificate}
                disabled={downloadingCertificate}
              >
                {downloadingCertificate && (
                  <CircularProgress
                    className={classes.circularLoader}
                    size={20}
                  />
                )}
                Scarica Certificato
              </Button>
            )}
          </>
        )}
      </>
    );
  }
);
