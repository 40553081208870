import React, { FC, useCallback } from 'react';
import { SimplerRadioButton } from './common/RadioButton';
import { SectionTitle } from '../common/SectionTitle';
import { StyleRules } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { StyledComponentProps } from '@material-ui/core';
import { JournalActivityKind } from '../api/model';

const styles = (_theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 48px',
    gap: '32px',
    width: '496px',
  },
});

interface SelectKindProps extends StyledComponentProps {
  value?: JournalActivityKind;
  onChange: (data?: JournalActivityKind) => void;
}

const options: { id: JournalActivityKind; label: string }[] = [
  {
    id: JournalActivityKind.INTERVIEW,
    label: 'Colloquio',
  },
  {
    id: JournalActivityKind.SECRETARIAT,
    label: 'Segretariato sociale',
  },
];

const SelectKind: FC<SelectKindProps> = ({ value, onChange, classes }) => {
  const onClick = useCallback(
    (newValue: JournalActivityKind) => {
      return () => {
        onChange(newValue === value ? undefined : newValue);
      };
    },
    [onChange, value]
  );

  return (
    <div className={classes?.container}>
      <SectionTitle>Seleziona la tipologia di attività</SectionTitle>
      <div style={{ width: '100%' }}>
        {options.map(({ id, label }) => {
          return (
            <SimplerRadioButton
              onClick={onClick(id)}
              key={id}
              text={label}
              selected={id === value}
            />
          );
        })}
      </div>
    </div>
  );
};

const DecoratedSelectKind = withStyles(styles)(SelectKind);
export { DecoratedSelectKind as SelectKind };
