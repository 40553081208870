import { extendObservable, action } from 'mobx';
import User from './User';
import {
  DEPARTMENTS,
  isHigherOrEqualThan,
  isHigherThan,
  ROLES,
} from '../constants/security';
import NotificationListener from './NotificationListener';

export default class Profile {
  constructor() {
    extendObservable(this, {
      isAuthenticated: false,
      operatingAs: {},
      notificationListener: new NotificationListener(),
      user: {
        roles: [],
      },
    });
  }

  reset = action(() => {
    this.isAuthenticated = false;
    this.operatingAs = {};
    this.notificationListener.reset();
    this.user = {
      roles: [],
    };
  });

  init = action(user => {
    this.isAuthenticated = true;
    this.user = new User(user);
    let operatingAs;
    try {
      operatingAs = JSON.parse(window.sessionStorage.getItem('operatingAs'));
    } catch (e) {
      /* PASS*/
    }
    if (
      operatingAs &&
      (this.hasRoleIn(operatingAs.role, operatingAs.department, true) ||
        this.isMemberOf(DEPARTMENTS.ADMIN))
    ) {
      this.operatingAs = operatingAs;
    } else {
      this.operatingAs = this.user.roles[0];
    }
    this.notificationListener.init();
    this.operatingAs = this.user.roles[0];
  });

  switchDepartment = action(department => {
    if (this.isMemberOf(DEPARTMENTS.ADMIN, true)) {
      this.operatingAs = {
        role: ROLES.MANAGER,
        department,
      };
    } else {
      const role = this.user.roles.find(r => r.department === department);
      if (role) {
        this.operatingAs = role;
      }
    }
    window.sessionStorage.setItem(
      'operatingAs',
      JSON.stringify(this.operatingAs)
    );
  });

  isMemberOf = action((department, globalScope = false) => {
    if (!this.isAuthenticated) return false;
    if (department === DEPARTMENTS.ANY) return true;
    if (!globalScope) {
      if (Array.isArray(department))
        return department.indexOf(this.operatingAs.department) !== -1;
      return this.operatingAs.department === department;
    } else {
      if (Array.isArray(department))
        return department.some(d =>
          this.user.roles.find(r => r.department === d)
        );
      return this.user.roles.map(r => r.department).includes(department);
    }
  });

  hasRole = action((role, globalScope = false) => {
    if (!this.isAuthenticated) return false;
    if (role === ROLES.ANY) return true;
    if (!globalScope) {
      if (Array.isArray(role))
        return role.indexOf(this.operatingAs.role) !== -1;
      return role === this.operatingAs.role;
    } else {
      if (Array.isArray(role))
        return role.some(r => this.user.roles.find(ur => ur.role === r));
      return this.user.roles.map(r => r.role).includes(role);
    }
  });

  hasRoleIn = action((role, department, globalScope = false) => {
    if (role === ROLES.ANY && department !== DEPARTMENTS.ANY)
      return this.isMemberOf(department, globalScope);
    if (role !== ROLES.ANY && department === DEPARTMENTS.ANY)
      return this.hasRole(role, globalScope);
    if (role === ROLES.ANY && department === DEPARTMENTS.ANY) return true;
    if (!globalScope) {
      return Array.isArray(role)
        ? role.includes(this.operatingAs.role)
        : this.operatingAs.role === role &&
            this.operatingAs.department === department;
    } else {
      return this.user.roles.some(
        r =>
          (Array.isArray(role) ? role.includes(r.role) : r.role === role) &&
          r.department === department
      );
    }
  });

  firstEligibleRole = action(roles => {
    return this.user.roles.find(ur =>
      roles.find(r => ur.role === r.role && ur.department === r.department)
    );
  });

  getDepartmentRole = action(department => {
    const r = this.user.roles.find(r => r.department === department);
    return r ? r.role : undefined;
  });

  isHigherThan = action(other => {
    return isHigherThan(this.operatingAs.role, other);
  });

  isHigherThanForDepartment = action((roles, globalScope = false) => {
    const userRoles = globalScope ? this.user.roles : [this.operatingAs];
    for (const i in roles) {
      const r = roles[i];
      const userRoleDepartment = userRoles.find(
        ur => ur.department === r.department
      );
      if (!userRoleDepartment) return false;
      if (!isHigherThan(userRoleDepartment.role, r.role)) return false;
    }
    return true;
  });

  isHigherOrEqualThanForDepartment = action((roles, globalScope = false) => {
    const userRoles = globalScope ? this.user.roles : [this.operatingAs];
    for (const i in roles) {
      const r = roles[i];
      const userRoleDepartment = userRoles.find(
        ur => ur.department === r.department
      );
      if (!userRoleDepartment) return false;
      if (!isHigherOrEqualThan(userRoleDepartment.role, r.role)) return false;
    }

    return true;
  });

  ownsUser = action(userRoles => {
    const userAdminRole = userRoles.find(
      r => r.department === DEPARTMENTS.ADMIN
    );
    if (!userAdminRole) {
      if (this.isMemberOf(DEPARTMENTS.ADMIN, true)) return true;
      return (
        this.hasRole([ROLES.MANAGER, ROLES.PO]) &&
        this.isMemberOf(userRoles.map(r => r.department)) &&
        this.isHigherThanForDepartment(userRoles)
      );
    }

    return isHigherThan(
      this.getDepartmentRole(DEPARTMENTS.ADMIN),
      userAdminRole.role
    );
  });

  is = action(user => {
    return Object(user).hasOwnProperty('id') && this.user.id === user.id;
  });

  getStatus = action(() => {
    return this.user.status;
  });
}
