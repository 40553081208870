import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Tabs } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  container: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  MuiTab: {
    root: {
      textTransform: 'none',
    },
  },
});

const TabBar = ({ classes, tabs, onTabChanged }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = useCallback((event, value) => {
    setSelectedTab(value);
  }, []);

  useEffect(() => {
    onTabChanged(selectedTab);
  }, [selectedTab, onTabChanged]);

  return (
    <AppBar position="static" color="default" className={classes.container}>
      <Tabs
        className={classes.tabContainer}
        value={selectedTab}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Tab key={index} value={index} label={tab} />;
        })}
      </Tabs>
    </AppBar>
  );
};

TabBar.propTypes = {
  classes: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.string),
  onTabChanged: PropTypes.func.isRequired,
};

export default withStyles(styles)(TabBar);
