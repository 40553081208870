import React, { useCallback } from 'react';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import List from './List';
import { withStyles } from '@material-ui/core';
import Certificati from '../../api/Certificati';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { DEPARTMENTS } from '../../constants/security';

const styles = _theme => ({});

const Certificate = withStyles(styles)(({ location, history }) => {
  const api =
    location.pathname.indexOf('demo') > -1
      ? Certificati.demographic
      : Certificati.civilState;

  const onItemSelected = useCallback(
    request => {
      const route =
        location.pathname.indexOf('demo') > -1
          ? ROUTES.CERTIFICATI_DEMOGRAFICI
          : ROUTES.CERTIFICATI_STATO_CIVILE;

      history.push(`/${route}/${request.details.id}`);
    },
    [history, location.pathname]
  );

  return (
    <PageContainer whiteBg header={<PageTitle title="Elenco Richieste" />}>
      <List
        onItemSelected={onItemSelected}
        api={api}
        department={
          location.pathname.indexOf('demo') > -1
            ? DEPARTMENTS.SERVIZI_DEMOGRAFICI
            : DEPARTMENTS.STATO_CIVILE
        }
      />
    </PageContainer>
  );
});

export default withRouter(Certificate);
