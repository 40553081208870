import React, { FC } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import moment from 'moment';
import { DetailField } from '../serviziSociali/detail/layout/Detail/common/DetailField';
import { Citizen } from '../serviziSociali/api/model';

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleSection: {
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
});
interface CitizenSectionProps extends StyledComponentProps {
  data: Citizen;
}
const Detail: FC<CitizenSectionProps> = ({ classes = {}, data }) => {
  const formattedAddress =
    data.address &&
    data.address.streetName &&
    data.address.postcode &&
    data.address.city
      ? `${data.address.streetName}, ${data.address.postcode} ${data.address.city}`
      : '---';

  const formattedCity =
    data.address && data.address.city && data.address.county
      ? `${data.address.city} (${data.address.county})`
      : '---';

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Dati Anagrafici</label>
      </div>
      <div className={classes.fieldContainer}>
        <DetailField label="Nome" value={data.firstName} />
        <DetailField label="Cognome" value={data.lastName} />
        <DetailField
          label="Sesso"
          value={data.gender === 'M' ? 'Maschile' : 'Femminile'}
        />
      </div>
      <div className={classes.fieldContainer}>
        <DetailField
          label="Data di Nascita"
          value={moment(data.birthday).format('DD/MM/YYYY')}
        />
        <DetailField
          label="Luogo di Nascita"
          value={`${data.birthPlaceCityName ?? '---'} (${
            data.birthPlaceCounty
          })`}
        />
        <DetailField label="Codice Fiscale" value={data.fiscalCode} />
      </div>
      <div className={classes.fieldContainer}>
        <DetailField label="Indirizzo di residenza" value={formattedAddress} />
        <DetailField label="Città di residenza" value={formattedCity} />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedDetail = withStyles(styles)(Detail);

export { DecoratedDetail as NewDetail };
