import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../common/navigation/PrivateRoute';
import { DEPARTMENTS, ROLES } from '../../constants/security';
import { ROUTES_SERVIZI_SOCIALI } from '../../constants/routes';
import { ListaPratiche } from './list/ListaPratiche';
import { NuovaPratica } from './new/NuovaPratica';
import { DashboardPage } from './dashboard/DashboardPage';
import { JournalPage } from './journal/JournalPage';
import { NewJournalActivityPage } from './journal/NewJournalActivityPage';
import InterviewDetail from './journal/interviews/detail';
import SecretariatActivityDetail from './journal/secretariat/detail';
import { ProcedureDetailSwitch } from './detail/ProcedureDetailSwitch';

export const ServiziSocialiRouter: FC = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={[
          `/${ROUTES_SERVIZI_SOCIALI.DASHBOARD_BASE}`,
          `/${ROUTES_SERVIZI_SOCIALI.DASHBOARD_SECTION}`,
        ]}
        routeComponent={DashboardPage}
      />
      <PrivateRoute
        exact
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.LISTA_PRATICHE}`}
        routeComponent={ListaPratiche}
      />
      <PrivateRoute
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.NUOVA_PRATICA}`}
        routeComponent={NuovaPratica}
        exact
      />
      <PrivateRoute
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`}
        routeComponent={ProcedureDetailSwitch}
      />
      <PrivateRoute
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.DIARIO}`}
        routeComponent={JournalPage}
        exact
      />
      <PrivateRoute
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.NUOVA_ATTIVITA_DIARIO}`}
        routeComponent={NewJournalActivityPage}
        exact
      />
      <PrivateRoute
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_COLLOQUIO}`}
        routeComponent={InterviewDetail}
        exact
      />
      <PrivateRoute
        department={DEPARTMENTS.SOCIAL_SERVICES}
        role={ROLES.ANY}
        path={`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_SEGRETARIATO}`}
        routeComponent={SecretariatActivityDetail}
        exact
      />
    </Switch>
  );
};
