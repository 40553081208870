import React, { memo } from 'react';
import { withStyles } from '@material-ui/core';
import './animation.css';
import PropTypes from 'prop-types';

const styles = theme => ({
  container: {
    padding: '20px 16px',
    boxSizing: 'border-box',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.font.greyLight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    animationName: 'slidein',
    animationDuration: '0.5s',
    '&:hover': {
      backgroundColor: theme.palette.grey.light,
    },
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.grey.dark,
    flex: 2,
    flexWrap: 'wrap',
  },
  img: {
    height: '24px',
    width: '24px',
  },
});
export const RadioButton = memo(
  withStyles(styles)(({ selected, onClick, kind, classes }) => {
    return (
      <div className={classes.container} onClick={onClick(kind.id)}>
        <label className={classes.text}>{kind.text}</label>
        {selected ? (
          <img
            src={require('../../../assets/icons/radio-active.png')}
            className={classes.img}
            alt="active"
          />
        ) : (
          <img
            src={require('../../../assets/icons/radio-inactive.png')}
            className={classes.img}
            alt="inactive"
          />
        )}
      </div>
    );
  })
);

RadioButton.propTypes = {
  kind: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};
