import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  container: {
    position: 'relative',
  },
  contents: {
    margin: '20px 40px 20px 40px',
  },
  icon: {
    position: 'absolute',
    left: '10px',
    top: '-4px',
    ...theme.typography.bodyStrong,
  },
  separator: {
    borderBottom: '1px solid #ccc',
    margin: '10px 0px',
  },
});

const Subsection = props => {
  const { classes, separator, noMargin } = props;
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.icon}>{props.icon}</div>
        <div className={noMargin ? '' : classes.contents}>{props.children}</div>
      </div>
      {separator && <div className={classes.separator} />}
    </div>
  );
};

Subsection.propTypes = {
  separator: PropTypes.bool,
  noMargin: PropTypes.bool,
};

Subsection.defaultProps = {
  separator: false,
  noMargin: false,
};

export default withStyles(styles)(Subsection);
