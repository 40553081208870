import React from 'react';
import { withStyles } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

const _BiggerToolTip = props => (
  <Tooltip title={props.title} className={props.classes.tooltip}>
    {props.children}
  </Tooltip>
);

_BiggerToolTip.propTypes = {
  title: PropTypes.string.isRequired,
};
export default withStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
}))(_BiggerToolTip);
