import React, { FC, useCallback } from 'react';
import {
  StyledComponentProps,
  withStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import moment from 'moment';
import cx from 'classnames';
import { StatusSection } from './StatusSection';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import tableStyle from '../../styles/table.style';
import { getDepartmentLabel } from '../../constants/common';
import { getCallToAction, getInstanceTypeLabel } from './constants';
import store from '../../store';

const styles = (theme: any) => ({
  ...tableStyle(),
  customBody: {
    height: '64px',
    '& td:first-child': {
      padding: '0px',
      '& div': {
        padding: '0px 24px',
        height: '48px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
    },
    '& td:not(:first-child)': {
      padding: '0px',
      '& div': {
        padding: '0px 24px ',
        height: '48px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderLeft: '1px solid',
        borderLeftColor: '#e0e0e0',
      },
    },
  },
  department: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  section: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
  },
  type: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
  },
  action: {
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '100px',
      backgroundColor: '#e0e0e0',
      opacity: '0.5',
    },
  },
});

interface TableRowProcedure extends StyledComponentProps, RouteComponentProps {
  instance: {
    identifier: string;
    protocolNumber: string;
    department: string;
    type: string;
    status: string;
    createdAt: string;
  };
}

const TableRowProcedure: FC<TableRowProcedure> = ({
  classes = {},
  instance,
  history,
}) => {
  const { profile } = store.app;

  const onClick = useCallback(() => {
    if (profile.operatingAs.department !== instance.department) {
      profile.switchDepartment(instance.department);
    }
    history.push(getCallToAction(instance.department, instance.identifier));
  }, [history, instance.department, instance.identifier, profile]);
  return (
    <TableRow className={cx([classes.bodyRow, classes.customBody])}>
      <TableCell>
        <div>{`${instance.protocolNumber}`}</div>
      </TableCell>
      <TableCell>
        <div>{moment(instance.createdAt).format('DD MMMM YYYY')}</div>
      </TableCell>
      <TableCell>
        <div className={classes.department}>
          <label className={classes.section}>
            {getDepartmentLabel(instance.department)}
          </label>
          <label className={classes.type}>
            {getInstanceTypeLabel(instance.department, instance.type)}
          </label>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <StatusSection status={instance.status} />
        </div>
      </TableCell>
      <TableCell>
        <div style={{ paddingRight: '12px' }}>
          <ChevronRight onClick={onClick} className={classes.action} />
        </div>
      </TableCell>
    </TableRow>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedTableRowProcedure = withStyles(styles)(
  withRouter(TableRowProcedure)
);

export { DecoratedTableRowProcedure as TableRowProcedure };
