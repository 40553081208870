import { FlagTypeDTO, FlagValueDTO } from '../../../gen/api/welfare';
import { PersonalSituation } from '../../serviziSociali/api/model';

export const fromContextFlagToApi = (data: PersonalSituation) => {
  return Object.entries(data)
    .filter(([_, values]) => !!values)
    .map(([type, values]) => ({
      type: type as FlagTypeDTO,
      values: values as FlagValueDTO[],
    }));
};
