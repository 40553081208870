import React from 'react';
import Detail from '../../../../certificates/Detail';

const ServiziDemograficiInstance = ({ instanceDetails }) => {
  return (
    <Detail
      canHandle={false}
      request={{
        type: instanceDetails.type,
        details: instanceDetails.details,
      }}
    />
  );
};

export default ServiziDemograficiInstance;
