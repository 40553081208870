import React, { FC } from 'react';
import {
  ProcedureEventsStates,
  useProcedureEvents,
} from '../../../../../../stm/socialServices/procedureEvents.stm';
import { Loading } from './layout/EventSectionLayout/Loading';
import { ErrorLoading } from './layout/EventSectionLayout/ErrorLoading';
import { EventStream } from './layout/EventSectionLayout/EventStream';

const ComponentMap: Record<ProcedureEventsStates, FC> = {
  [ProcedureEventsStates.loading]: Loading,
  [ProcedureEventsStates.errorLoading]: ErrorLoading,
  [ProcedureEventsStates.loaded]: EventStream,
};

export const EventsSection: FC = () => {
  const { state } = useProcedureEvents();
  const Component = ComponentMap[state.state ?? ProcedureEventsStates.loading];
  return <Component />;
};
