import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import PlusIcon from '@material-ui/icons/AddCircleOutline';

const ShareButton = props => {
  const { onClick, variant, color, size, className, withIcon = false } = props;

  return (
    <Button
      className={className}
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
    >
      {withIcon && <PlusIcon style={{ marginRight: 8 }} />}
      Aggiungi
    </Button>
  );
};

ShareButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ShareButton;
