import React, { useCallback } from 'react';
import Icon from '../../../new/components/icons';
import {
  Button,
  CircularProgress,
  StyledComponentProps,
} from '@material-ui/core';
import Download from '@material-ui/icons/FileDownload';
import Delete from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

interface DocumentProps extends StyledComponentProps {
  className?: string;
  documentId: string;
  fileName: string;
  extension: string;
  date: string;
  deletable: boolean;
  onClick?: (documentId: string, fileName: string) => void;
  isDownloading?: boolean;
}

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 24,
  },
  deletable: {
    backgroundColor: '#F5F7FF',
  },
  icon: {
    height: 48,
    width: 48,
  },
  textContainer: {
    flex: 1,
    marginLeft: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  fileName: {
    fontWeight: 700,
    fontSize: 18,
    color: theme.palette.primary.main,
    lineHeight: '27px',
  },
  fileDescription: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: theme.palette.grey.dark,
  },
  button: {
    minWidth: 48,
    height: 48,
    padding: 0,
  },
});

const Document: React.FC<DocumentProps> = ({
  classes = {},
  className,
  documentId,
  fileName,
  extension,
  date,
  deletable = false,
  onClick,
  isDownloading = false,
}) => {
  const onDownloadClicked = useCallback(() => {
    if (onClick) {
      onClick(documentId, fileName);
    }
  }, [documentId, fileName, onClick]);

  return (
    <div
      className={cx(
        classes.container,
        { [classes.deletable as string]: deletable },
        className
      )}
    >
      <Icon className={classes.icon} type="Document" size={48} />
      <div className={classes.textContainer}>
        <span className={classes.fileName}>{fileName}</span>
        <span className={classes.fileDescription}>
          {`File ${extension.toUpperCase()}`}
          {date ? ` - ${date}` : ''}
        </span>
      </div>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="large"
        disabled={isDownloading}
        onClick={onDownloadClicked}
      >
        {!isDownloading ? (
          deletable ? (
            <Delete />
          ) : (
            <Download />
          )
        ) : (
          <CircularProgress size={24} />
        )}
      </Button>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(Document);
