import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import { PraticaNumber } from './PraticaNumber';

const styles = theme => ({
  container: {
    margin: '5px 0px',
    border: 'solid 1px',
    padding: '10px 15px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    borderRadius: '1px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    display: 'flex',
    alignItems: 'center',
    height: '90px',
  },
  details: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contents: {
    fontSize: '12px',
  },
  header: {
    ...theme.typography.bodyStrong,
  },
  subject: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  praticaDetails: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: '0',
  },
  praticaDetailsContainer: {
    fontSize: '14px',
  },
  praticaDetailsElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  praticaDetailsHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  praticaDetailsValue: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  detailsButton: {
    border: '2px solid ' + theme.palette.primary.main,
  },
});

export const PraticaBox = withStyles(styles)(
  withRouter(
    class extends React.Component {
      static propTypes = {
        goTo: PropTypes.string,
        procedure: PropTypes.object.isRequired,
        statusBadge: PropTypes.object,
        categoryTransformer: PropTypes.func,
      };

      onClick = () => {
        if (this.props.goTo !== null) {
          this.props.history.push(this.props.goTo);
        }
      };

      transformCategory = category => {
        if (this.props.categoryTransformer) {
          return this.props.categoryTransformer(category);
        }

        return category ? category : '-';
      };

      render() {
        const { classes, procedure, statusBadge } = this.props;
        const category = this.transformCategory(procedure.category);
        return (
          <div className={classes.container}>
            <PraticaNumber
              number={procedure.number}
              year={procedure.year}
              sub={procedure.sub}
            />
            {statusBadge}
            <Row className={classes.praticaDetails}>
              <Col xs={10} className={classes.praticaDetailsContainer}>
                <Row>
                  <Col xs={2} className={classes.praticaDetailsElement}>
                    <span className={classes.praticaDetailsHeader}>
                      CATEGORIA
                    </span>
                    <span className={classes.praticaDetailsValue}>
                      {category}
                    </span>
                  </Col>
                  <Col xs={5} className={classes.praticaDetailsElement}>
                    <span className={classes.praticaDetailsHeader}>
                      REFERENTE
                    </span>
                    <span className={classes.praticaDetailsValue}>
                      {procedure.owner ? procedure.owner : '-'}
                    </span>
                  </Col>
                  <Col xs={5} className={classes.praticaDetailsElement}>
                    <span className={classes.praticaDetailsHeader}>
                      UBICAZIONE
                    </span>
                    <span className={classes.praticaDetailsValue}>
                      {procedure.location ? procedure.location : '-'}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={classes.praticaDetailsElement}>
                    <span className={classes.praticaDetailsHeader}>
                      OGGETTO
                    </span>
                    <span className={classes.praticaDetailsValue}>
                      {procedure.subject ? procedure.subject : '-'}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col className={classes.details} xs={2}>
                <Button
                  size="small"
                  variant="outline"
                  color="primary"
                  className={classes.detailsButton}
                  onClick={this.onClick}
                >
                  Dettagli
                </Button>
              </Col>
            </Row>
          </div>
        );
      }
    }
  )
);
