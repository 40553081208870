import store from '../../../store';
import { Redirect, Route } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import React from 'react';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ department, role, ...rest }) => {
  const profile = store.app.profile;
  const { routeComponent: Component } = rest;
  const allowed = role
    ? profile.hasRoleIn(role, department)
    : profile.isMemberOf(department);
  return (
    <Route
      {...rest}
      render={props =>
        profile.isAuthenticated ? (
          allowed ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: `/${ROUTES.UNAUTHORIZED}`,
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/${ROUTES.LOGIN}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  department: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};
