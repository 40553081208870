import React, { FC, useCallback, useMemo, useState } from 'react';
import { DetailField } from './DetailField';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import TextLinkPrimary from '../../../../../common/TextLinkPrimary';
import { useDialog } from '../../../../common/upload/useDialog';
import { useProcedureDetail } from '../../../../../../stm/socialServices/procedureDetail.stm';
import { procedureUpdated } from '../../../../../../stm/socialServices/procedureDetail.events';
import { assignInstance } from '../../../../api/api';
import {
  AssignWelfareInstanceOperationRequest,
  WelfareInstanceStatusDTO,
} from '../../../../../../gen/api/welfare';
import { AssignInstanceSearchUser } from './AssignInstanceSearchUser';
import store from '../../../../../../store';
import { DEPARTMENTS, ROLES } from '../../../../../../constants/security';

interface AssignmentFieldProps extends StyledComponentProps {
  assigned: string;
  label: string;
  onAssign: (id: string) => void;
}

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
  },
  icon: {
    cursor: 'pointer',
  },
});

const AssignmentField: FC<AssignmentFieldProps> = ({
  classes = {},
  assigned,
  onAssign,
  ...otherProps
}) => {
  const stm = useProcedureDetail();
  const { openDialog, open, closeDialog } = useDialog();
  const [assigning, setAssigning] = useState(false);
  const { snackBar } = store.app;
  const onUserSelected = useCallback(
    async user => {
      setAssigning(true);
      const request: AssignWelfareInstanceOperationRequest = {
        id: stm.state.context!.procedure!.id,
        assignWelfareInstanceRequest: {
          assigneeFiscalCode: user.person.fiscal_code,
        },
      };
      try {
        const procedure = await assignInstance(request);
        stm.dispatch(procedureUpdated(procedure));
      } catch (e) {
        snackBar.open(
          "Si è verificato un errore imprevisto durante l'assegnazione dell'istanza"
        );
      }
      closeDialog();
      setAssigning(false);
    },
    [closeDialog, snackBar, stm]
  );
  const onCLickAssignToMe = useMemo(() => {
    if (
      store.app.profile.user.person.fiscal_code !==
      stm.state.context!.procedure!.assignee?.fiscalCode
    ) {
      return () =>
        onUserSelected({
          person: { fiscal_code: store.app.profile.user.person.fiscal_code },
        });
    }
  }, [onUserSelected, stm.state.context]);

  const canChangeAssignee =
    stm.state.context?.procedure?.status &&
    [
      WelfareInstanceStatusDTO.Assigned,
      WelfareInstanceStatusDTO.Created,
    ].includes(stm.state.context?.procedure?.status) &&
    store.app.profile.isHigherOrEqualThanForDepartment(
      [{ role: ROLES.PO, department: DEPARTMENTS.SOCIAL_SERVICES }],
      true
    );
  return (
    <>
      <AssignInstanceSearchUser
        open={open}
        onClose={closeDialog}
        onUserSelected={onUserSelected}
        loading={assigning}
        onCLickAssignToMe={onCLickAssignToMe}
      />
      <DetailField {...otherProps} value={assigned ?? '---'}>
        {canChangeAssignee && (
          <div className={classes.container}>
            <TextLinkPrimary onClick={openDialog}>
              {assigned ? 'Cambia' : 'Assegna'}
            </TextLinkPrimary>
          </div>
        )}
      </DetailField>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedAssignmentField = withStyles(styles)(AssignmentField);

export { DecoratedAssignmentField as AssignmentField };
