/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FamilyMemberRelationshipDTO {
    Fratello = 'FRATELLO',
    Sorella = 'SORELLA',
    Madre = 'MADRE',
    Padre = 'PADRE',
    Figlio = 'FIGLIO',
    Figlia = 'FIGLIA',
    Coniuge = 'CONIUGE'
}

export function FamilyMemberRelationshipDTOFromJSON(json: any): FamilyMemberRelationshipDTO {
    return FamilyMemberRelationshipDTOFromJSONTyped(json, false);
}

export function FamilyMemberRelationshipDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FamilyMemberRelationshipDTO {
    return json as FamilyMemberRelationshipDTO;
}

export function FamilyMemberRelationshipDTOToJSON(value?: FamilyMemberRelationshipDTO | null): any {
    return value as any;
}

