import React, { FC, useCallback } from 'react';
import {
  StyledComponentProps,
  withStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import moment from 'moment';
import tableStyle from '../../../../styles/table.style';
import cx from 'classnames';
import { StatusSection } from './StatusSection';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compile } from 'path-to-regexp';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../constants/routes';
import { SECTION_LABEL_MAP, SERVICE_LABEL_MAP } from '../../constants';
import { WelfareInstanceListItemDTO } from '../../../../gen/api/welfare';
import AssigneeItem from '../../common/AssigneeItem';

const styles = (theme: any) => ({
  ...tableStyle(),
  customBody: {
    height: '64px',
    '& td:first-child': {
      padding: '0px',
      '& > div': {
        padding: '0px 24px',
        height: '48px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
    },
    '& td:not(:first-child)': {
      padding: '0px',
      '& > div': {
        padding: '0px 24px ',
        height: '48px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderLeft: '1px solid',
        borderLeftColor: '#e0e0e0',
      },
    },
  },
  type: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  section: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '400',
  },
  kind: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
  },
  action: {
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '100px',
      backgroundColor: '#e0e0e0',
      opacity: '0.5',
    },
  },
  assignee: {
    flexDirection: 'row!important',
    alignItems: 'center!important',
  },
});

interface TableRowProcedure extends StyledComponentProps, RouteComponentProps {
  procedure: WelfareInstanceListItemDTO;
}

const TableRowProcedure: FC<TableRowProcedure> = ({
  classes = {},
  procedure,
  history,
}) => {
  const onClick = useCallback(() => {
    const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`, {
      encode: encodeURIComponent,
    });
    history.push(toPath({ praticaId: procedure.id }));
  }, [history, procedure.id]);
  return (
    <TableRow className={cx([classes.bodyRow, classes.customBody])}>
      <TableCell>
        <div>{`${procedure.protocol!.number}/${procedure.protocol!.year}`}</div>
      </TableCell>
      <TableCell>
        <div>{moment(procedure.createdAt).format('DD MMMM YYYY')}</div>
      </TableCell>
      <TableCell>
        <div className={classes.type}>
          <label className={classes.section}>
            {SECTION_LABEL_MAP[procedure.section]}
          </label>
          <label className={classes.kind}>
            {SERVICE_LABEL_MAP[procedure.type]}
          </label>
        </div>
      </TableCell>
      <TableCell>
        {procedure.assignee ? (
          <div className={classes.assignee}>
            <AssigneeItem
              firstName={procedure.assignee.firstName}
              lastName={procedure.assignee.lastName}
            />
          </div>
        ) : (
          <div>Non assegnata</div>
        )}
      </TableCell>
      <TableCell>
        <div>
          <StatusSection status={procedure.status} />
        </div>
      </TableCell>
      <TableCell>
        <div style={{ paddingRight: '12px' }}>
          <ChevronRight onClick={onClick} className={classes.action} />
        </div>
      </TableCell>
    </TableRow>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedTableRowProcedure = withStyles(styles)(
  withRouter(TableRowProcedure)
);

export { DecoratedTableRowProcedure as TableRowProcedure };
