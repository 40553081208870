import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';

const styles = () => ({
  filterButton: {
    marginTop: '12px',
  },
  filterButtonContainer: {
    marginTop: '20px',
  },
});

class FilterButtons extends React.Component {
  static propTypes = {
    applyFilters: PropTypes.func.isRequired,
  };

  apply = () => {
    this.props.applyFilters();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Row className={classes.filterButtonContainer}>
          <Col xs={2} sm={2} md={1} lg={1}>
            <Button
              onClick={this.apply}
              size="small"
              variant="raised"
              color="primary"
              className={classes.filterButton}
              fullWidth
            >
              Applica
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default withStyles(styles)(FilterButtons);
