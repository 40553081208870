import React, { FC, useEffect } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compile } from 'path-to-regexp';
import { useProcedureDetail } from '../../../../../stm/socialServices/procedureDetail.stm';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';
import { procedureUpdated } from '../../../../../stm/socialServices/procedureDetail.events';
import { useEditProcedure } from '../../../../../stm/socialServices/editProcedure.stm';

const styles = (theme: any) => ({
  container: {
    marginTop: '180px',
    alignSelf: 'center',
    width: '430px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    height: '96px',
    width: '96px',
    marginBottom: '24px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '700',
    marginBottom: '16px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    marginBottom: '56px',
  },
  button: {
    width: '232px',
    marginBottom: '8px',
  },
});

const Submitted: FC<StyledComponentProps & RouteComponentProps> = ({
  classes = {},
  history,
}) => {
  const {
    state: { context },
    dispatch: procedureDetailDispatch,
  } = useProcedureDetail();

  const { state: editState } = useEditProcedure();

  useEffect(() => {
    if (context?.id) {
      setTimeout(() => {
        const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`, {
          encode: encodeURIComponent,
        });
        procedureDetailDispatch(
          procedureUpdated(editState.context!.procedureUpdated!)
        );
        history.push(toPath({ praticaId: context!.id! }));
      }, 1000);
    }
  }, [context, editState.context, history, procedureDetailDispatch]);
  return (
    <div className={classes.container}>
      <img
        src={require('../../../../../assets/images/success-servizi-sociali.png')}
        alt="success"
        className={classes.image}
      />
      <label className={classes.title}>Operazione Riuscita</label>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSubmitted = withStyles(styles)(withRouter(Submitted));

export { DecoratedSubmitted as Submitted };
