import {
  StyledComponentProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import tableStyle from '../../../../styles/table.style';
import Loader from '../../../common/Loader';
import EmptyTableMessage from '../../../common/EmptyTableMessage';
import React, { FC, useCallback } from 'react';
import {
  SocialServiceListStates,
  useSocialServiceList,
} from '../../../../stm/socialServices/socialServiceList.stm';
import { TableRowProcedure } from './TableRowProcedure';
import { ErrorLoading } from './ErrorLoading';
import { Col, Row } from 'react-flexbox-grid';
import Pagination from '../../../common/Pagination';
import { pageChanged } from '../../../../stm/socialServices/socialServiceList.events';
import cx from 'classnames';

const styles = () => ({
  ...tableStyle(),
  paginationContainer: {
    margin: 0,
  },
  container: {
    paddingBottom: 30,
  },
});

const TableProcedure: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { state, dispatch } = useSocialServiceList();

  const onPageChange = useCallback(
    changedPage => {
      dispatch(
        pageChanged({
          page: changedPage.page,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={cx(classes.tableContainer, classes.container)}>
      {state.state !== SocialServiceListStates.errorLoading && (
        <>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headRow}>
                <TableCell className={classes.headCell}>N Richiesta</TableCell>
                <TableCell className={classes.headCell}>
                  Presentata il
                </TableCell>
                <TableCell
                  className={classes.headCell}
                  style={{ width: '30%' }}
                >
                  Tipologia
                </TableCell>
                <TableCell className={classes.headCell}>Assegnata a</TableCell>
                <TableCell className={classes.headCell}>Stato</TableCell>
                <TableCell className={classes.headCell} />
              </TableRow>
            </TableHead>
            {state.state === SocialServiceListStates.loaded &&
              state.context.list!.length > 0 && (
                <TableBody>
                  {state.context.list!.map(proc => (
                    <TableRowProcedure key={proc.id} procedure={proc} />
                  ))}
                </TableBody>
              )}
          </Table>
          <EmptyTableMessage
            visible={!state?.context?.list || state.context?.list.length < 1}
          />
        </>
      )}

      <Loader
        size={40}
        loading={state.state === SocialServiceListStates.loading}
      />

      {state.state === SocialServiceListStates.errorLoading && <ErrorLoading />}

      {state.context?.pagination &&
        state.state === SocialServiceListStates.loaded && (
          <Row className={classes.paginationContainer}>
            <Col xs={12}>
              <Pagination
                total={state.context.pagination.totalPages}
                current={state.context.pagination.currentPage + 1}
                totalElement={state.context.pagination.totalItemsCount}
                elementNumber={state.context.pagination.pageItemsCount}
                onChange={onPageChange}
                display={3}
              />
            </Col>
          </Row>
        )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecorateTableProcedure = withStyles(styles)(TableProcedure);

export { DecorateTableProcedure as TableProcedure };
