import React, { useCallback, useMemo, useState } from 'react';
import Subsection from '../common/Subsection';
import { Col, Row } from 'react-flexbox-grid';
import { getDocumentTypeLabel } from '../../constants/common';
import EditCitizen from './Edit';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Button } from '@material-ui/core';

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
    width: '100%',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: '10px',
  },
  simpleLabelValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  simpleLabelValueContainerNoPadding: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0px',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(props => {
  const {
    user,
    editDisabled = true,
    classes,
    title = 'Profilo Cittadino',
    asRegistrationRequest = false,
    requestId,
    onEditStarted = () => {},
    onEditCanceled = () => {},
    onEditFinished = () => {},
  } = props;

  const [editMode, setEditMode] = useState(false);
  const onEditClick = useCallback(() => {
    setEditMode(true);
    onEditStarted();
  }, [setEditMode, onEditStarted]);

  const onCancel = useCallback(() => {
    setEditMode(false);
    onEditCanceled();
  }, [setEditMode, onEditCanceled]);

  const onFinish = useCallback(
    person => {
      setEditMode(false);
      onEditFinished(person);
    },
    [setEditMode, onEditFinished]
  );

  const fullAddress = useMemo(() => {
    const person = props.user.person;
    if (!person) return null;
    let address = person.address.street_name ? person.address.street_name : '';
    address += person.address.postcode ? ` ${person.address.postcode}` : '';
    address += person.address.city ? ` ${person.address.city}` : '';
    address += person.address.county_code
      ? ` ${person.address.county_code}`
      : '';

    address = address.trim();

    return !address ? '-' : address;
  }, [props]);

  const birthplace = useMemo(() => {
    const person = props.user.person;
    if (!person) return null;
    let birthplace = person.birthplace.city_name;
    birthplace += person.birthplace.county
      ? ` (${person.birthplace.county})`
      : '';

    birthplace = birthplace.trim();

    return !birthplace ? '-' : birthplace;
  }, [props]);

  const { person } = user;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <i className={classNames(classes.headerIcon, 'nc-icon nc-single-02')} />
        <span className={classes.headerTitle}>{title}</span>
        {!editDisabled && person && (
          <Col className={classes.headerAction}>
            <Button
              size="small"
              variant="raised"
              color="primary"
              disabled={editMode}
              className={classes.smallActionButton}
              onClick={onEditClick}
            >
              Modifica
            </Button>
          </Col>
        )}
      </div>
      {!editMode ? (
        <Subsection>
          {person ? (
            <Row>
              <LabelValue label="Nome" value={person.first_name} />
              <LabelValue label="Cognome" value={person.last_name} />
              <LabelValue
                label="Titolo Professionale"
                value={
                  person.professional_title
                    ? person.professional_title.long
                    : 'N/A'
                }
              />
              <LabelValue label="Sesso" value={person.gender} />
              <LabelValue label="Data di nascita" value={person.birth_date} />
              {person.birthplace.is_foreign ? (
                <LabelValue
                  label="Luogo di Nascita"
                  value={
                    person.birthplace.county_name
                      ? person.birthplace.county_name
                      : '-'
                  }
                />
              ) : (
                <LabelValue label="Luogo di Nascita" value={birthplace} />
              )}
              <LabelValue label="Codice Fiscale" value={person.fiscal_code} />
              <LabelValue
                label="Partita IVA"
                value={person.vat ? person.vat : '-'}
              />
              <LabelValue
                label="Documento di Identità"
                value={
                  person.document &&
                  person.document.type &&
                  person.document.number
                    ? `${getDocumentTypeLabel(person.document.type)} - ${
                        person.document.number
                      }`
                    : '-'
                }
              />
              <LabelValue label="Indirizzo di residenza" value={fullAddress} />
              <LabelValue
                label="Email"
                value={person.email ? person.email : '-'}
              />
              <LabelValue
                label="Telefono"
                value={person.phone ? person.phone : '-'}
              />
            </Row>
          ) : (
            <Row>
              <Col xs={12}>Nessuna informazione anagrafica presente.</Col>
            </Row>
          )}
        </Subsection>
      ) : (
        <Subsection>
          <EditCitizen
            user={user}
            onCancel={onCancel}
            onComplete={onFinish}
            asRegistrationRequest={asRegistrationRequest}
            requestId={requestId}
          />
        </Subsection>
      )}
    </div>
  );
});

const LabelValue = withStyles(styles)(props => {
  const { classes, label, value, noPadding } = props;

  return (
    <Col
      md={3}
      sm={6}
      xs={12}
      className={
        noPadding
          ? classes.simpleLabelValueContainerNoPadding
          : classes.simpleLabelValueContainer
      }
    >
      <span className={classes.simpleLabel}>{label}</span>
      <span className={classes.simpleValue}>{value}</span>
    </Col>
  );
});
