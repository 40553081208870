import React from 'react';
import Api from '../../api/Cittadini';
import { Col, Row } from 'react-flexbox-grid';
import Loader from '../common/Loader';
import EmptyListMessage from '../common/EmptyListMessage';
import Pagination from '../common/Pagination';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import ListBox from '../common/ListBox';
import FilterItem from '../common/filters/Filter';
import { FILTER_TYPE } from '../common/filters/constants';
import GenericListFilters from '../common/filters/GenericListFilters';

const styles = theme => ({
  bodyStrong: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  listItemText: {
    fontSize: '16px',
  },
});

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cittadini: [],
      pagination: null,
      currFilters: {},
    };

    this.loadList(1, this.state.currFilters, true);
  }

  onPageChange = changedPage => {
    this.loadList(changedPage.page, this.state.currFilters, true);
  };

  loadList = (page = 1, filters, loading = false) => {
    this.setState({ loading: loading }, () => {
      Api.loadCittadini(
        { page, filters },
        response => {
          this.setState({
            loading: false,
            cittadini: response.users,
            currFilters: filters,
            pagination: response.meta.pagination,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    });
  };

  applyFilters = filters => {
    this.loadList(1, filters, true);
  };

  onCitizenSelected = index => {
    this.props.onCitizenSelected(this.state.cittadini[index]);
  };

  render() {
    const { pagination, cittadini, loading } = this.state;

    return (
      <Row>
        <Col xs={12}>
          <GenericListFilters
            listName="citizenListFilters"
            filters={[
              new FilterItem(
                'first_name',
                FILTER_TYPE.TEXT,
                'Nome',
                '',
                null,
                6,
                2,
                2,
                2
              ),
              new FilterItem(
                'last_name',
                FILTER_TYPE.TEXT,
                'Cognome',
                '',
                null,
                6,
                2,
                2,
                2
              ),
              new FilterItem(
                'email',
                FILTER_TYPE.TEXT,
                'Email',
                '',
                null,
                6,
                2,
                2,
                2
              ),
              new FilterItem(
                'fiscal_code',
                FILTER_TYPE.TEXT,
                'Cod. Fiscale',
                '',
                null,
                6,
                2,
                2,
                2
              ),
              new FilterItem(
                'vat',
                FILTER_TYPE.TEXT,
                'P. IVA',
                '',
                null,
                6,
                2,
                2,
                2
              ),
              new FilterItem(
                'document_number',
                FILTER_TYPE.TEXT,
                'Numero Documento',
                '',
                null,
                6,
                2,
                2,
                2
              ),
              new FilterItem(
                'mobile_phone',
                FILTER_TYPE.TEXT,
                'Cellulare',
                '',
                null,
                6,
                2,
                2,
                2
              ),
            ]}
            applyFilters={this.applyFilters}
          />
          <Loader size={40} loading={loading} />
          {!loading &&
            cittadini.map((cittadino, index) => {
              return (
                <ListItem
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  citizen={cittadino}
                  onCitizenSelected={() => this.onCitizenSelected(index)}
                />
              );
            })}
          <EmptyListMessage visible={cittadini.length === 0 && !loading} />
          {pagination && (
            <Row>
              <Col xs={12} sm={12}>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.current_page}
                  display={3}
                  onChange={this.onPageChange}
                  totalElement={pagination.total}
                  elementNumber={pagination.count}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    );
  }
}

const ListItem = withStyles(styles)(props => {
  const { citizen, classes } = props;
  const onSelected = () => {
    props.onCitizenSelected();
  };

  return (
    <ListBox onItemClick={onSelected}>
      <Row className={classes.listItemText}>
        <Col xs={12} sm={6} md={6}>
          <div className={classes.bodyStrong}>
            {citizen.first_name} {citizen.last_name}
          </div>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <b>Iscritto il:</b> {citizen.created_at}
        </Col>
      </Row>
    </ListBox>
  );
});

export default withStyles(styles)(observer(List));
