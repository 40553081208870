/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagTypeDTO {
    ConflittoIntrafamiliare = 'CONFLITTO_INTRAFAMILIARE',
    FragilitaSocioEducativa = 'FRAGILITA_SOCIO_EDUCATIVA',
    NessunaReteFamiliare = 'NESSUNA_RETE_FAMILIARE',
    VulnerabilitaGenitoriale = 'VULNERABILITA_GENITORIALE',
    DipenzaDaDroghe = 'DIPENZA_DA_DROGHE',
    DipendenzaDaAlcool = 'DIPENDENZA_DA_ALCOOL',
    PatologieMentali = 'PATOLOGIE_MENTALI',
    PatologieFisiche = 'PATOLOGIE_FISICHE',
    AbusiOMaltrattamenti = 'ABUSI_O_MALTRATTAMENTI',
    DifficoltaFinaziarie = 'DIFFICOLTA_FINAZIARIE',
    ProblematichePenali = 'PROBLEMATICHE_PENALI',
    Separazione = 'SEPARAZIONE',
    Monogenitoriale = 'MONOGENITORIALE',
    Adozione = 'ADOZIONE',
    Affido = 'AFFIDO',
    AffidoComunale = 'AFFIDO_COMUNALE',
    AltroProvvedimentoAg = 'ALTRO_PROVVEDIMENTO_AG',
    SegnalatoDa = 'SEGNALATO_DA',
    SeguitoCon = 'SEGUITO_CON',
    AffidiEducativi = 'AFFIDI_EDUCATIVI',
    PresenzaReteDiSupporto = 'PRESENZA_RETE_DI_SUPPORTO',
    TipologiaDiAccesso = 'TIPOLOGIA_DI_ACCESSO',
    ViveSolo = 'VIVE_SOLO',
    Problematiche = 'PROBLEMATICHE',
    ContributoSolidarietaPsichici = 'CONTRIBUTO_SOLIDARIETA_PSICHICI'
}

export function FlagTypeDTOFromJSON(json: any): FlagTypeDTO {
    return FlagTypeDTOFromJSONTyped(json, false);
}

export function FlagTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagTypeDTO {
    return json as FlagTypeDTO;
}

export function FlagTypeDTOToJSON(value?: FlagTypeDTO | null): any {
    return value as any;
}

