import React from 'react';
import { observer } from 'mobx-react';
import { getDepartmentLabel } from '../../constants/common';
import _ from 'lodash';

class UserUpdated extends React.Component {
  closeModal = () => {
    this.setState(
      {
        show: false,
      },
      () => {
        if (this.props.onClose) this.props.onClose();
      }
    );
  };

  getDiffs = (_new, _old) => {
    const except = [
      'id',
      'first_name',
      'last_name',
      'status',
      'groups',
      'permissions',
      'created_at',
      'updated_at',
      'deleted_at',
    ];
    const diffs = {};

    Object.keys(_new).forEach(key => {
      if (_new[key] !== _old[key] && except.indexOf(key) === -1) {
        diffs[key] = { new: _new[key], old: _old[key] };
      }
    });

    if (_new.hasOwnProperty('groups') && _old.hasOwnProperty('groups')) {
      const newGroups = _new.groups.map(item => {
        return getDepartmentLabel(_.isObject(item) ? item.name : item);
      });

      const oldGroups = _old.groups.map(item => {
        return getDepartmentLabel(_.isObject(item) ? item.name : item);
      });

      if (this.checkDiff(newGroups, oldGroups)) {
        diffs['groups'] = { new: newGroups, old: oldGroups };
      }
    }

    return diffs;
  };

  checkDiff = (_new, _old) => {
    const difference = _new
      .filter(x => !_old.includes(x))
      .concat(_old.filter(x => !_new.includes(x)));

    return difference.length > 0;
  };

  render() {
    const { event } = this.props;
    const diffs =
      event.payload && event.payload.user && event.payload.old_user
        ? this.getDiffs(event.payload.user, event.payload.old_user)
        : null;

    return (
      <>
        {event.payload &&
          event.payload.user &&
          event.payload.old_user &&
          diffs && (
            <span>
              <b style={{ fontSize: '1.1rem' }}>Modifiche Effettuate</b>
              <br />
              <br />
              {diffs.hasOwnProperty('full_name') && (
                <span>
                  <b>Nome: </b> {diffs.full_name.old} &raquo;{' '}
                  {diffs.full_name.new}
                  <br />
                </span>
              )}
              {diffs.hasOwnProperty('email') && (
                <span>
                  <b>Email: </b> {diffs.email.old} &raquo; {diffs.email.new}
                  <br />
                </span>
              )}
            </span>
          )}
      </>
    );
  }
}

export default observer(UserUpdated);
