import { withStyles } from '@material-ui/core';
import { Col } from 'react-flexbox-grid';
import cx from 'classnames';
import React from 'react';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  simpleLabelValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  simpleLabelValueContainerNoPadding: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0px',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

export const HorizontalList = withStyles(styles)(props => {
  const { classes, children } = props;

  return (
    <Col xs={12} className={classes.container}>
      {children}
    </Col>
  );
});

export const HorizontalLabelValue = withStyles(styles)(props => {
  const {
    classes,
    className,
    label,
    value,
    noPadding = false,
    md = 3,
    sm = 6,
    xs = 12,
  } = props;

  return (
    <Col
      md={md}
      sm={sm}
      xs={xs}
      className={cx(
        {
          [classes.simpleLabelValueContainerNoPadding]: noPadding,
          [classes.simpleLabelValueContainer]: !noPadding,
        },
        className
      )}
    >
      <span className={classes.simpleLabel}>{label}</span>
      <span className={classes.simpleValue}>{value}</span>
    </Col>
  );
});
