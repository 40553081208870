import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  getLabelInternalProcedureState,
  INTERNAL_PROCEDURE_STATUS,
} from '../../../constants/common';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    borderRadius: '12px',
    padding: '0 16px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginLeft: '8px',
    color: '#fff',
  },
  stateDraft: {
    color: '#fff',
    backgroundColor: '#0277bd',
  },
  stateClosed: {
    color: '#ba0101',
    backgroundColor: 'rgba(186, 1, 1, 0.2)',
  },
  stateActive: {
    color: '#008255',
    backgroundColor: 'rgba(0, 182, 118, 0.2)',
  },
});

const StatusBadge = withStyles(styles)(props => {
  const { classes, state, style } = props;

  return (
    <span
      style={style}
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateActive]: state === INTERNAL_PROCEDURE_STATUS.ACTIVE,
        [classes.stateClosed]: state === INTERNAL_PROCEDURE_STATUS.CLOSED,
        [classes.stateDraft]: state === INTERNAL_PROCEDURE_STATUS.DRAFT,
      })}
    >
      {getLabelInternalProcedureState(state)}
    </span>
  );
});

StatusBadge.propTypes = {
  state: PropTypes.string.isRequired,
  style: PropTypes.object,
};

StatusBadge.defaultProps = {
  style: {},
};

export default StatusBadge;
