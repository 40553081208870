import { createAction } from '@reduxjs/toolkit';
import { TemporaryDocument } from '../../components/serviziSociali/api/model';

export enum ApproveRejectingInstanceEvents {
  userPressedRejectInstance = 'events/userPressedRejectInstance',
  userPressedApproveInstance = 'events/userPressedApproveInstance',
  userConfirmedRejectInstance = 'events/userConfirmedRejectInstance',
  userConfirmedApproveInstance = 'events/userConfirmedApproveInstance',
  errorApprovingInstance = 'events/errorApprovingInstance',
  errorRejectingInstance = 'events/errorRejectingInstance',
  instanceApproved = 'events/instanceApproved',
  instanceRejected = 'events/instanceRejected',
}

export const userPressedRejectInstance = createAction(
  ApproveRejectingInstanceEvents.userPressedRejectInstance
);

export const userPressedApproveInstance = createAction<
  TemporaryDocument | undefined
>(ApproveRejectingInstanceEvents.userPressedApproveInstance);

export type UserPressedApproveInstance = ReturnType<
  typeof userPressedApproveInstance
>;

export const errorRejectingInstance = createAction<Error>(
  ApproveRejectingInstanceEvents.errorRejectingInstance
);
export const errorApprovingInstance = createAction<Error>(
  ApproveRejectingInstanceEvents.errorApprovingInstance
);
export const userConfirmedRejectingInstance = createAction<{ reason: string }>(
  ApproveRejectingInstanceEvents.userConfirmedRejectInstance
);
export type UserConfirmedRejectInstance = ReturnType<
  typeof userConfirmedRejectingInstance
>;
export const userConfirmedApproveInstance = createAction(
  ApproveRejectingInstanceEvents.userConfirmedApproveInstance
);
export const instanceApproved = createAction(
  ApproveRejectingInstanceEvents.instanceApproved
);
export const instanceRejected = createAction(
  ApproveRejectingInstanceEvents.instanceRejected
);
