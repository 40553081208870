import CallManager from './AxiosCallManager';

export default {
  loadList: ({ filters }, onSuccess, onError) => {
    CallManager.get(
      `departments/edilizia/timetable`,
      response => onSuccess(response.data),
      onError,
      { ...filters }
    );
  },
  get: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `departments/edilizia/timetable/${procedureId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  skip: (procedureId, onSuccess, onError) => {
    CallManager.delete(
      `departments/edilizia/timetable/${procedureId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  send: (procedureId, data, onSuccess, onError) => {
    CallManager.patch(
      `departments/edilizia/timetable/${procedureId}`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  preview: (procedureId, onFinished, onError) => {
    CallManager.downloadFile(
      `departments/edilizia/timetable/${procedureId}?preview=true`,
      onFinished,
      onError
    );
  },
  historyList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      'departments/edilizia/timetablehistory',
      response => onSuccess(response.data),
      onError,
      { page: page, ...filters }
    );
  },
};
