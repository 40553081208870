import React, { FC, useCallback, useState } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import { DetailField } from './DetailField';
import moment from 'moment';
import { requestorTypeLabelMap } from '../../../../constants';
import ROUTES from '../../../../../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router/index';
import TextLinkPrimary from '../../../../../common/TextLinkPrimary';
import {
  RequestorDTO,
  WelfareRequestorTypeDTO,
} from '../../../../../../gen/api/welfare';
import Document from '../../../../common/Document';
import { getDocument } from '../../../../api/api';
import store from '../../../../../../store';

const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  documentsList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
});
interface RequestorSection extends StyledComponentProps, RouteComponentProps {
  requestor: RequestorDTO;
  type: WelfareRequestorTypeDTO;
}
const RequestorSection: FC<RequestorSection> = ({
  classes = {},
  requestor,
  history,
}) => {
  const goToDetail = useCallback(() => {
    history.push(`/${ROUTES.CITTADINI}/${requestor.person.userId}`);
  }, [history, requestor]);
  const { snackBar } = store.app;
  const [downloadingDocument, setDownloadingDocument] = useState();

  const onDownload = useCallback(
    (fid, name) => {
      setDownloadingDocument(fid);
      getDocument(fid, name)
        .then(() => setDownloadingDocument(undefined))
        .catch(() => {
          snackBar.open(`Errore nel download del file ${name}`);
          setDownloadingDocument(undefined);
        });
    },
    [snackBar]
  );

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Richiedente</label>
        <TextLinkPrimary bold onClick={goToDetail}>
          Dettaglio completo
        </TextLinkPrimary>
      </div>
      <div className={classes.fieldContainer}>
        <DetailField
          label="In qualità di"
          value={requestorTypeLabelMap[requestor.type]}
        />
        <DetailField label="Nome" value={requestor.person.firstName} />
        <DetailField label="Cognome" value={requestor.person.lastName} />
      </div>
      <div className={classes.fieldContainer}>
        <DetailField
          label="Codice fiscale"
          value={requestor.person.fiscalCode}
        />
        <DetailField
          label="Data di nascita"
          value={moment(requestor.person.birthDate).format('DD/MM/YYYY')}
        />
      </div>
      {requestor.documents.length > 0 && (
        <div className={classes.documentsList}>
          {requestor.documents.map(document => (
            <Document
              key={document.fid}
              documentId={document.fid}
              fileName={document.name}
              extension={DOCUMENT_EXTENSION.exec(document.name)![1] ?? ''}
              date={moment(document.createdAt).format('DD/MM/YYYY HH:mm:SS')}
              onClick={onDownload}
              isDownloading={downloadingDocument !== undefined}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DecoratedRequestorSection =
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  withStyles(styles)(withRouter(RequestorSection));

export { DecoratedRequestorSection as RequestorSection };
