import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  getRequestStatusLabel,
  REQUEST_STATES,
} from '../../../constants/common';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
    width: 'fit-content',
  },
  stateNew: {
    backgroundColor: '#0277bd',
  },
  stateApproved: {
    backgroundColor: '#00600f',
  },
  stateRejected: {
    backgroundColor: '#ba0101',
  },
});

const RequestStatusBadge = withStyles(styles)(props => {
  const { classes, state } = props;

  return (
    <div
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateNew]: state === REQUEST_STATES.NEW,
        [classes.stateApproved]: state === REQUEST_STATES.APPROVED,
        [classes.stateRejected]: state === REQUEST_STATES.REJECTED,
      })}
    >
      {getRequestStatusLabel(state)}
    </div>
  );
});

RequestStatusBadge.propTypes = {
  state: PropTypes.string.isRequired,
};

export default RequestStatusBadge;
