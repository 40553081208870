import { withStyles } from '@material-ui/core';
import { Col } from 'react-flexbox-grid';
import React from 'react';
import PropTypes from 'prop-types';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

const LabelValue = withStyles(styles)(props => {
  const { classes, label, value, md = 3, sm = 5, xs = 12 } = props;

  return (
    <Col md={md} sm={sm} xs={xs} className={classes.container}>
      <span className={classes.label}>{label}</span>
      {Array.isArray(value) ? (
        value.map((v, i) => (
          <>
            <span>{v}</span>
            {i < value.length - 1 && <br />}
          </>
        ))
      ) : (
        <span>{value}</span>
      )}
    </Col>
  );
});

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  noPadding: PropTypes.bool,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
};

LabelValue.defaultProps = {
  noPadding: false,
  md: 3,
  sm: 5,
  xs: 12,
};

export default LabelValue;
