import Client from './NewApiCallManager';

export default {
  demographic: {
    get: (id, onSuccess, onError) => {
      Client.get(
        `/registry/demographics/certificates/${id}`,
        response => onSuccess(response.data),
        onError
      );
    },
    list: ({ page, filters = {} }, onSuccess, onError) => {
      Client.get(
        '/registry/demographics/certificates',
        response => onSuccess(response.data),
        onError,
        {
          pageNumber: page,
          pageSize: 20,
          ...filters,
        }
      );
    },
    handle: (id, onSuccess, onError) => {
      Client.patch(
        `/registry/certificates/${id}/handle`,
        onSuccess,
        onError,
        {},
        { method: 'PUT' }
      );
    },
    resolve: (id, onSuccess, onError) => {
      Client.patch(
        `/registry/certificates/${id}/resolve`,
        onSuccess,
        onError,
        {},
        { method: 'PUT' }
      );
    },
    downloadCertificate: (id, name, onSuccess, onError) => {
      Client.downloadFile(
        `/registry/certificates/${id}`,
        onSuccess,
        onError,
        `${name}.pdf`
      );
    },
  },
  civilState: {
    get: (id, onSuccess, onError) => {
      Client.get(
        `/registry/civil-state/certificates/${id}`,
        response => onSuccess(response.data),
        onError
      );
    },
    list: ({ page, filters = {} }, onSuccess, onError) => {
      Client.get(
        '/registry/civil-state/certificates',
        response => onSuccess(response.data),
        onError,
        {
          pageNumber: page,
          pageSize: 20,
          ...filters,
        }
      );
    },
    handle: (id, onSuccess, onError) => {
      Client.patch(
        `/registry/certificates/${id}/handle`,
        onSuccess,
        onError,
        {},
        { method: 'PUT' }
      );
    },
    resolve: (id, onSuccess, onError) => {
      Client.patch(
        `/registry/certificates/${id}/resolve`,
        onSuccess,
        onError,
        {},
        { method: 'PUT' }
      );
    },
    downloadCertificate: (id, name, onSuccess, onError) => {
      Client.downloadFile(
        `/registry/certificates/${id}`,
        onSuccess,
        onError,
        `${name}.pdf`
      );
    },
  },
};
