import React, { FC } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import Loader from '../../../../../../common/Loader';

const styles = (_theme: any) => ({
  container: {
    width: '432px',
    padding: '16px',
  },
});

const Loading: FC<StyledComponentProps> = ({ classes = {} }) => {
  return (
    <div className={classes.container}>
      <Loader loading size={100} />
    </div>
  );
};

const DecoratedLoading = withStyles(styles)(Loading);
export { DecoratedLoading as Loading };
