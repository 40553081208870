import React, { FC, useMemo } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { useLocalStateMachine } from '@moveax/redux-stm-act-react-utils';
import {
  NewSocialServiceContext,
  NewSocialServiceStates,
  newSocialServiceStm,
} from '../../../stm/socialServices/newSocialService.stm';
import { SearchRequestor } from './section/SearchRequestor';
import { SelectRequestorType } from './section/SelectRequestorType';
import { SearchBeneficiary } from './section/SearchBeneficiary';
import { SelectFamilyMember } from './section/SelectFamilyMember';
import { SelectRelativeMembers } from './section/SelectRelativeMember';
import { SelectIsee } from './section/SelectIsee';
import { SetPersonalSituation } from './section/SetPersonalSituation';
import { defaultCitizenContext } from '../api/model';
import { SelectOtherBenefits } from './section/SelectOtherBenefits';
import { SelectSection } from './section/SelectSection';
import { SelectExpenses } from './section/SelectExpenses';
import { Submitting } from './section/Submitting';
import { Success } from './section/Success';
import { Error } from './section/Error';

const styles = (theme: {
  palette: { white: { main: any } };
  typography: { fontFamily: any };
}) => ({
  container: {
    backgroundColor: theme.palette.white.main,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '38.4px',
  },
});

const ComponentMap: {
  [key in NewSocialServiceStates]: FC<unknown>;
} = {
  [NewSocialServiceStates.selectingKind]: SelectSection,
  [NewSocialServiceStates.selectingOtherBenefits]: SelectOtherBenefits,
  [NewSocialServiceStates.selectingRequestor]: SearchRequestor,
  [NewSocialServiceStates.selectingRequestorType]: SelectRequestorType,
  [NewSocialServiceStates.selectingBeneficiary]: SearchBeneficiary,
  [NewSocialServiceStates.selectingPersonalSituation]: SetPersonalSituation as FC<
    unknown
  >,
  [NewSocialServiceStates.selectingFamilyMembers]: SelectFamilyMember,
  [NewSocialServiceStates.selectingRelativeMembers]: SelectRelativeMembers,
  [NewSocialServiceStates.selectingIsee]: SelectIsee,
  [NewSocialServiceStates.selectingExpenses]: SelectExpenses,
  [NewSocialServiceStates.success]: Success,
  [NewSocialServiceStates.error]: Error,
  [NewSocialServiceStates.submitting]: Submitting,
};

const NuovaPratica: FC<StyledComponentProps> = ({ classes = {} }) => {
  const contextValue = useLocalStateMachine(newSocialServiceStm, {
    actualForm: {},
    defaults: defaultCitizenContext,
  });
  const Component = useMemo(
    () =>
      ComponentMap[
        contextValue.state.state ?? NewSocialServiceStates.selectingKind
      ],
    [contextValue]
  );
  return (
    <NewSocialServiceContext.Provider value={contextValue}>
      <div className={classes.container}>
        <Component />
      </div>
    </NewSocialServiceContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedNuovaPratica = withStyles(styles)(NuovaPratica);

export { DecoratedNuovaPratica as NuovaPratica };
