import React, { FC, useCallback } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import FormControlValidated from '../../../../../../common/FormControlValidated';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { DialogContentText, Input } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: any): StyleRules => ({
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

export interface SignCredentialsProps extends StyledComponentProps {
  errors: Record<string, string>;
  username?: string;
  password?: string;
  otp?: string;
  onUsernameChange: (username?: string) => void;
  onPasswordChange: (password?: string) => void;
  onOtpChange: (otp?: string) => void;
}

const SignCredentials: FC<SignCredentialsProps> = ({
  classes = {},
  errors,
  username,
  password,
  otp,
  onUsernameChange,
  onPasswordChange,
  onOtpChange,
}) => {
  const inputTextWrapper = useCallback((f: (value: string) => void) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      f(event.target.value);
    };
  }, []);

  return (
    <>
      <DialogContentText style={{ marginBottom: '30px' }}>
        Inserisci le credeziali di firma elettronica
      </DialogContentText>
      <Row>
        <Col xs={12}>
          <FormControlValidated
            inputId="username"
            label="Username"
            error={errors.username}
            shrinkLabel={true}
          >
            <Input
              id="username"
              inputProps={{ tabIndex: 1 }}
              type="text"
              onChange={inputTextWrapper(onUsernameChange)}
              value={username}
              classes={
                {
                  inkbar: !errors.username
                    ? classes.inputInkbar!
                    : classes.inputInkbarError!,
                } as Record<any, any>
              }
            />
          </FormControlValidated>
        </Col>
        <Col xs={12}>
          <FormControlValidated
            inputId="password"
            label="Password"
            error={errors.password}
            shrinkLabel={true}
          >
            <Input
              id="password"
              inputProps={{ tabIndex: 1 }}
              type="password"
              onChange={inputTextWrapper(onPasswordChange)}
              value={password}
              classes={
                {
                  inkbar: !errors.password
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                } as Record<any, any>
              }
            />
          </FormControlValidated>
        </Col>
        <Col xs={12}>
          <FormControlValidated
            inputId="otp"
            label="OTP"
            error={errors.otp}
            shrinkLabel={true}
          >
            <Input
              id="otp"
              inputProps={{ tabIndex: 1 }}
              type="text"
              onChange={inputTextWrapper(onOtpChange)}
              value={otp}
              classes={
                {
                  inkbar: !errors.otp
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                } as Record<any, any>
              }
            />
          </FormControlValidated>
        </Col>
      </Row>
    </>
  );
};
export default withStyles(styles)(SignCredentials);
