import Cittadini from '../../../api/Cittadini';
import {
  Citizen,
  CitizenContext,
  CitizenRequest,
  FlagDTO,
  Interview,
  InterviewListResponse,
  PersonalSituation,
  SecretariatActivity,
  SecretariatActivityListResponse,
  TemporaryDocument,
} from './model';
import moment from 'moment';
import { getCitizenFromApi } from './transformer';
import NewDocumentiTermporanei from '../../../api/NewDocumentiTermporanei';
import CitizenApi from '../../../api/NewCittadini';
import NewWelfareInstance from '../../../api/NewWelfareInstance';
import ServiziSociali from '../../../api/ServiziSociali';
import {
  ApproveWelfareInstanceOperationRequest,
  CitizenDTO,
  RejectWelfareInstanceOperationRequest,
  TempFileConfirmationRequest,
  WelfareInstanceCreationRequest,
  WelfareInstanceDTO,
  WelfareInstanceEventDTO,
  AssignWelfareInstanceOperationRequest,
  WelfareInstanceListRequest,
  WelfareInstanceListResponse,
  SignWelfareInstanceOperationRequest,
  WelfareInstanceEditRequest,
} from '../../../gen/api/welfare';
import { AxiosResponse } from 'axios';
import { v4 } from 'uuid';

export const createCitizens = (citizen: CitizenRequest): Promise<Citizen> => {
  return new Promise((resolve, reject) =>
    Cittadini.createCitizen(
      {
        first_name: citizen.firstName,
        last_name: citizen.lastName,
        professional_title: citizen.professionalTitle,
        fiscal_code: citizen.fiscalCode,
        vat: citizen.vat,
        document_type: citizen.documentType,
        document_number: citizen.documentNumber,
        birthday: moment(citizen.birthday).format('YYYY-MM-DD'),
        gender: citizen.gender,
        country_of_birth: citizen.birthPlaceCountry,
        county_of_birth: citizen.birthPlaceCounty,
        birthplace: citizen.birthPlace,
        address: {
          street_name: citizen.address?.streetName,
          postcode: citizen.address?.postcode,
          county: citizen.address?.county,
          city: citizen.address?.city,
        },
        email: citizen.email,
        phone: citizen.phoneNumber,
      },
      (user: any) => resolve(getCitizenFromApi(user)),
      reject
    )
  );
};

export const getCitizenByCf = (cf: string): Promise<Citizen> => {
  return new Promise((resolve, reject) => {
    Cittadini.getCittadinoByFiscalCode(
      cf,
      (user: any) => {
        resolve(getCitizenFromApi(user));
      },
      reject
    );
  });
};

export const uploadTemporaryDocument = (
  file: File
): Promise<Omit<TemporaryDocument, 'name'>> => {
  return new Promise((resolve, reject) => {
    NewDocumentiTermporanei.upload(
      file,
      (data: { fid: string }) => resolve({ fid: data.fid }),
      reject
    );
  });
};

export const deleteTemporaryDocument = (id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    NewDocumentiTermporanei.delete(id, resolve, reject);
  });
};

export const getCitizenContext = (cf: string): Promise<CitizenContext> => {
  return new Promise((resolve, reject) => {
    CitizenApi.citizenContext(
      cf,
      (data: CitizenDTO) => {
        resolve({
          flags: [...data.flags].reduce(
            (acc: PersonalSituation, curr: FlagDTO) => {
              acc[curr.type] = curr.values;
              return acc;
            },
            {}
          ),
          familyMembers: data.familyMembers.map(m => ({
            id: v4(),
            ...m,
          })),
          relativeMembers: data.relativeMembers.map(m => ({
            id: v4(),
            ...m,
          })),
        });
      },
      reject
    );
  });
};

export const getDocument = (id: string, name: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.getDocument(
      id,
      name,
      () => {
        resolve();
      },
      reject
    );
  });
};

export const getInstanceList = (
  pageNumber: number = 0,
  pageSize: number = 20,
  filters: Pick<
    WelfareInstanceListRequest,
    | 'type'
    | 'section'
    | 'status'
    | 'beneficiaryFiscalCode'
    | 'createdAfter'
    | 'createdBefore'
  > = {}
): Promise<WelfareInstanceListResponse> => {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.list(
      { pageNumber, pageSize, ...filters },
      resolve,
      reject
    );
  });
};

export function createInstance(
  data: WelfareInstanceCreationRequest
): Promise<WelfareInstanceDTO> {
  return new Promise((resolve, reject) => {
    try {
      NewWelfareInstance.createInstance(
        data,
        (response: AxiosResponse<WelfareInstanceDTO>) => {
          resolve(response.data);
        },
        error => {
          console.debug('instance parsing failed', error);
          reject(error);
        }
      );
    } catch (error) {
      console.error('error during instance creation', error);
    }
  });
}

export function rejectInstance(
  data: RejectWelfareInstanceOperationRequest
): Promise<WelfareInstanceDTO> {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.rejectInstance(
      data,
      (response: AxiosResponse<WelfareInstanceDTO>) => {
        resolve(response.data);
      },
      error => {
        console.debug('instance parsing failed', error);
        reject(error);
      }
    );
  });
}

export function approveInstance(
  data: ApproveWelfareInstanceOperationRequest
): Promise<WelfareInstanceDTO> {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.approveInstance(
      data,
      (response: AxiosResponse<WelfareInstanceDTO>) => {
        resolve(response.data);
      },
      error => {
        console.debug('instance parsing failed', error);
        reject(error);
      }
    );
  });
}

export function assignInstance(
  data: AssignWelfareInstanceOperationRequest
): Promise<WelfareInstanceDTO> {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.assignInstance(
      data,
      (response: AxiosResponse<WelfareInstanceDTO>) => {
        resolve(response.data);
      },
      error => {
        console.debug('instance parsing failed', error);
        reject(error);
      }
    );
  });
}

export function rejectEvaluation(
  data: RejectWelfareInstanceOperationRequest
): Promise<WelfareInstanceDTO> {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.rejectInstance(
      data,
      (response: AxiosResponse<WelfareInstanceDTO>) => {
        resolve(response.data);
      },
      error => {
        console.debug('instance parsing failed', error);
        reject(error);
      }
    );
  });
}

export function signInstance(
  data: SignWelfareInstanceOperationRequest
): Promise<WelfareInstanceDTO> {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.signInstance(
      data,
      (response: AxiosResponse<WelfareInstanceDTO>) => {
        resolve(response.data);
      },
      error => {
        console.debug('instance parsing failed', error);
        reject(error);
      }
    );
  });
}

export const getProcedure = (id: string): Promise<WelfareInstanceDTO> => {
  return new Promise<WelfareInstanceDTO>((resolve, reject) => {
    NewWelfareInstance.getProcedure(
      id,
      response => {
        resolve(response.data);
      },
      error => {
        reject(error);
      }
    );
  });
};

export const editProcedure = (
  id: string,
  procedure: WelfareInstanceEditRequest
): Promise<WelfareInstanceDTO> => {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.editProcedure(
      id,
      procedure,
      response => resolve(response.data),
      reject
    );
  });
};

export function getJournalInterviews({
  pageNumber = 0,
  pageSize = 20,
  filters = {},
}): Promise<InterviewListResponse> {
  return new Promise((resolve, reject) => {
    ServiziSociali.journal.interviews.list(
      { pageNumber, pageSize, filters },
      resolve,
      reject
    );
  });
}

export function getJournalInterview(id: string): Promise<Interview> {
  return new Promise((resolve, reject) => {
    ServiziSociali.journal.interviews.get(id, resolve, reject);
  });
}

export function getSecretariatActivities({
  pageNumber = 0,
  pageSize = 20,
}): Promise<SecretariatActivityListResponse> {
  return new Promise((resolve, reject) => {
    ServiziSociali.journal.secretariat.list(
      { pageNumber, pageSize },
      resolve,
      reject
    );
  });
}

export function getSecretariatActivity(
  id: string
): Promise<SecretariatActivity> {
  return new Promise((resolve, reject) => {
    ServiziSociali.journal.secretariat.get(id, resolve, reject);
  });
}

export const addNote = (
  procedureId: string,
  note: string,
  documents: TempFileConfirmationRequest[]
): Promise<WelfareInstanceEventDTO> => {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.addNote(
      procedureId,
      note,
      documents,
      response => resolve(response.data),
      reject
    );
  });
};

export const getEvents = (
  procedureId: string
): Promise<WelfareInstanceEventDTO[]> => {
  return new Promise((resolve, reject) => {
    NewWelfareInstance.getEvents(
      procedureId,
      response => resolve(response.data.events),
      reject
    );
  });
};
