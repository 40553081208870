import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Subsection from './Subsection';
import { Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import Loader from './Loader';
import EmptyListMessage from './EmptyListMessage';
import Pratiche from '../../api/PraticheGate';
import ConfirmButton from './ConfirmButton';
import ShareStatus from '../share/ShareStatus';
import store from '../../store';
import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Visibility as DetailsIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getDepartmentLabel } from '../../constants/common';

const styles = () => ({
  sharedListContainer: {
    contents: {
      padding: 0,
      margin: 0,
    },
  },
  sharedList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  sharedListItem: {
    height: '50px',
    borderBottom: '1px solid #eee',
    padding: '20px',
    alignItems: 'center',
  },
  sharedListItemContent: {
    height: '100%',
    alignItems: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
});

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px!important',
  },
}))(Tooltip);

export const SharingDetails = withRouter(
  withStyles(styles)(props => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [selectedShared, setSelectedShared] = useState(undefined);
    const [shares, setShares] = useState([]);

    const { classes, procedure, loadSharedTo } = props;

    const divRef = useRef(null);

    const loadShares = useCallback(() => {
      setLoading(true);
      setShares([]);
      loadSharedTo(
        procedure.id,
        response => {
          setShares(response.shared_procedures);
          setLoading(false);
        },
        () => {
          setShares([]);
          setLoading(false);
        }
      );
    }, [procedure, loadSharedTo, setShares]);

    useEffect(() => {
      loadShares();
      const params = new URLSearchParams(props.location.search);
      if (params.get('hlshared')) {
        window.scrollTo(0, divRef.current.offsetTop);
      }
    }, [loadShares, props]);

    const onClose = useCallback(
      (callback, shareId) => {
        Pratiche.closeSharedProcedure(
          shareId,
          () => {
            callback();
            loadShares();
            store.app.snackBar.open('Condivisione interrotta con successo');
          },
          () => {
            store.app.snackBar.open(
              'Si è verificato un errore imprevisto. Riprova più tardi.'
            );
            callback();
          }
        );
      },
      [loadShares]
    );

    const onDetailsClick = useCallback(
      index => {
        const sharedProcedure = shares[index];
        setSelectedShared(sharedProcedure);
        setShowModal(true);
        setLoadingDocuments(true);
        Pratiche.getSharedDocuments(
          sharedProcedure.id,
          documents => {
            setDocuments(documents);
            setLoadingDocuments(false);
          },
          () => {
            setDocuments([]);
            setLoadingDocuments(false);
          }
        );
      },
      [shares, setSelectedShared, setShowModal]
    );

    const onDialogClose = useCallback(() => {
      setShowModal(false);
      setSelectedShared(undefined);
      setDocuments([]);
      setLoadingDocuments(false);
    }, [setShowModal]);

    return (
      <div ref={divRef} className={classes.container}>
        <div className={classes.header}>
          <i className={classNames(classes.headerIcon, 'nc-icon nc-vector')} />
          <span className={classes.headerTitle}>Condivisioni</span>
        </div>
        <Subsection noMargin>
          <Loader size={40} loading={loading} />
          <EmptyListMessage visible={shares.length === 0 && !loading} />
          <Row>
            <Col xs={12}>
              <ul className={classes.sharedList}>
                {shares.map((shared, index) => (
                  <li key={shared.id} className={classes.sharedListItem}>
                    <Row className={classes.sharedListItemContent}>
                      <Col xs={2}>
                        <ShareStatus state={shared.status} />
                      </Col>
                      <Col xs={2}>{getDepartmentLabel(shared.shared_to)}</Col>
                      <Col xs={4}>
                        {shared.shared_documents === 0
                          ? 'Nessun documento condiviso'
                          : `${shared.shared_documents} documenti condivisi`}{' '}
                      </Col>
                      <Col xs={4} className={classes.actionBar}>
                        {shared.status !== 'closed' && (
                          <ConfirmButton
                            confirmMessage="Vuoi interrompere la condivisione?"
                            withLoading
                            onConfirm={callback => onClose(callback, shared.id)}
                            text="Interrompendo la condivisione, il dipartimento destinatario non potrà più essere in grado di vedere la pratica"
                            buttonLabel="Interrompi"
                            buttonProps={{
                              color: 'primary',
                            }}
                          />
                        )}
                        <BiggerTooltip
                          title="Visualizza Dettagli"
                          className={classes.tooltip}
                        >
                          <Button
                            size="small"
                            variant="raised"
                            color="default"
                            className={classes.smallActionButton}
                            onClick={() => onDetailsClick(index)}
                          >
                            <DetailsIcon
                              className={classes.smallActionButtonIcon}
                            />
                          </Button>
                        </BiggerTooltip>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <DetailsDialog
            open={showModal}
            onDialogClose={onDialogClose}
            sharedProcedure={selectedShared}
            documents={documents}
            loadingDocuments={loadingDocuments}
          />
        </Subsection>
      </div>
    );
  })
);

const DetailsDialog = props => {
  const {
    open,
    onDialogClose,
    sharedProcedure,
    documents,
    loadingDocuments,
  } = props;

  return sharedProcedure ? (
    <Dialog
      style={{
        fontFamily: 'Lato',
        lineHeight: '30px',
      }}
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={onDialogClose}
      aria-labelledby="share-details-dialog-title"
      aria-describedby="share-details-dialog-details"
    >
      <DialogTitle id="share-details-dialog-title">
        Dettagli Condivisione
      </DialogTitle>
      <DialogContent>
        <span>
          <b>Stato: </b>
          <ShareStatus state={sharedProcedure.status} />
          <br />
          <b>Condivisa a: </b>
          {getDepartmentLabel(sharedProcedure.shared_to)}
          <br />
          <b>Note condivise: </b>
          {sharedProcedure.sender_notes || 'N/A'}
          <br />
          <b>Note dal dipartimento: </b>
          {sharedProcedure.receiver_notes || 'N/A'}
          <br />
          <br />
          <b>Documenti condivisi:</b>
          <br />
          <Loader size={30} loading={loadingDocuments} color="primary" />
          <EmptyListMessage
            visible={documents.length === 0 && !loadingDocuments}
            message="Nessun documento condiviso."
          />
          {!loadingDocuments && documents.length > 0 && (
            <ul>
              {documents.map((document, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{document.name}</li>
              ))}
            </ul>
          )}
        </span>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  ) : (
    <div />
  );
};

DetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  sharedProcedure: PropTypes.object,
  documents: PropTypes.array.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
};

SharingDetails.propTypes = {
  procedure: PropTypes.object.isRequired,
  loadSharedTo: PropTypes.func.isRequired,
};
