import React from 'react';
import { withStyles } from '@material-ui/core';
import cx from 'classnames';

const styles = theme => ({
  link: {
    textDecoration: 'none',
    fontSize: 'inherit',
    color: theme.palette.primary.light,
    fontWeight: 'inherit',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  bold: {
    fontWeight: 600,
  },
});

const TextLinkPrimary = ({ classes, children, bold = false, ...linkProps }) => {
  return (
    <span {...linkProps} className={cx(classes.link, { [classes.bold]: bold })}>
      {children}
    </span>
  );
};

export default withStyles(styles)(TextLinkPrimary);
