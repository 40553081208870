import React, { FC, useCallback } from 'react';
import {
  WelfareInstanceDTO,
  WelfareInstanceEventDTO,
  WelfareInstanceStatusDTO,
} from '../../../../../../gen/api/welfare';
import { ReplyBox } from '../common/ReplyBox';
import { withStyles } from '@material-ui/core/styles';
import { StyledComponentProps } from '@material-ui/core';
import { useProcedureEvents } from '../../../../../../stm/socialServices/procedureEvents.stm';
import { addedNewReply } from '../../../../../../stm/socialServices/procedureEvents.events';
import { EventsSection } from './EventsSection';
import { AttachDocuments } from '../common/AttachDocuments';
import { DEPARTMENTS, ROLES } from '../../../../../../constants/security';
import store from '../../../../../../store';
import { TemporaryDocument } from '../../../../api/model';
import { useProcedureDetail } from '../../../../../../stm/socialServices/procedureDetail.stm';
import {
  setTemporaryAccountingDecision,
  setTemporaryExecutiveDecision,
} from '../../../../../../stm/socialServices/procedureDetail.events';

const styles = () => ({
  bodyContainer: {
    paddingBottom: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
  },
});

interface WorkingInstanceProps extends StyledComponentProps {
  procedure: WelfareInstanceDTO;
}
// eslint-disable-next-line complexity
const WorkingInstance: FC<WorkingInstanceProps> = ({
  procedure,
  classes = {},
}) => {
  const { profile } = store.app;
  const { state: detailState, dispatch: detailDispatch } = useProcedureDetail();
  const { state: eventState, dispatch: eventDispatch } = useProcedureEvents();

  const onNewReplyAdded = useCallback(
    (reply: WelfareInstanceEventDTO) => {
      eventDispatch(addedNewReply(reply));
    },
    [eventDispatch]
  );

  const isAssignee =
    procedure.assignee?.fiscalCode === profile.user.person.fiscal_code;
  const isAtLeastPO = profile.isHigherOrEqualThanForDepartment(
    [
      {
        department: DEPARTMENTS.SOCIAL_SERVICES,
        role: ROLES.PO,
      },
    ],
    true
  );
  const canReply =
    procedure.status !== WelfareInstanceStatusDTO.Rejected &&
    (isAssignee || isAtLeastPO);

  const setExecutiveDecision = useCallback(
    (document?: TemporaryDocument) => {
      detailDispatch(setTemporaryExecutiveDecision(document));
    },
    [detailDispatch]
  );

  const setAccountingDecision = useCallback(
    (document?: TemporaryDocument) => {
      detailDispatch(setTemporaryAccountingDecision(document));
    },
    [detailDispatch]
  );

  //box is displayed if the following conditions are met:
  //if document has been uploaded or
  //An executive decision can be attached (employee already approved)
  const showExecutiveDecisionSection =
    procedure.executiveDecision ||
    (procedure.status === WelfareInstanceStatusDTO.ApprovedByEmployee &&
      profile.isHigherOrEqualThanForDepartment(
        [
          {
            department: DEPARTMENTS.SOCIAL_SERVICES,
            role: ROLES.PO,
          },
        ],
        true
      ));

  const canAttachAccountingDecision =
    procedure.accountingDecision ||
    (procedure?.status === WelfareInstanceStatusDTO.ApprovedByPo &&
      profile.hasRoleIn(
        ROLES.ADMINISTRATIVE,
        DEPARTMENTS.SOCIAL_SERVICES,
        true
      ));

  return (
    <div className={classes.bodyContainer}>
      {showExecutiveDecisionSection && (
        <AttachDocuments
          variant="bozza"
          onChange={setExecutiveDecision}
          document={
            procedure.executiveDecision ??
            detailState.context?.executiveDecision
          }
          deletable={!procedure.executiveDecision}
        />
      )}
      {canAttachAccountingDecision && (
        <AttachDocuments
          variant="ragioneria"
          onChange={setAccountingDecision}
          document={
            procedure.accountingDecision ??
            detailState.context?.accountingDecision
          }
          deletable={!procedure.accountingDecision}
        />
      )}
      <EventsSection />
      {canReply && (
        <ReplyBox
          onNewReply={onNewReplyAdded}
          events={eventState.context?.events ?? []}
          procedureId={procedure.id}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedWorkingInstance = withStyles(styles)(WorkingInstance);

export { DecoratedWorkingInstance as WorkingInstance };
