import React, { useCallback, useState } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import SharesList from './SharesList';
import DetailRow from './DetailRow';
import DetailElement from './DetailElement';
import Document from '../Common/Document';
import PraticheInterne from '../../../api/PraticheInterne';
import store from '../../../store';

const styles = () => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    borderRadius: '4px',
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
  },
  details: {
    marginTop: 24,
  },
  subject: {
    marginBottom: 24,
  },
  documentContainer: {
    borderTop: '1px solid #DDD',
    margin: 0,
  },
});

const ProcedureDetail = props => {
  const {
    classes,
    procedure,
    isOwner,
    onProcedureShared,
    onShareDelete,
  } = props;

  const { snackBar } = store.app;

  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const onDocumentClicked = useCallback(() => {
    setIsDownloadingDocument(true);
    PraticheInterne.download(
      procedure.id,
      procedure.document.id,
      () => {
        setIsDownloadingDocument(false);
      },
      () => {
        setIsDownloadingDocument(false);
        snackBar.open('Si è verificato un errore durante il download del file');
      }
    );
  }, [procedure.document.id, procedure.id, snackBar]);

  return (
    <div className={classes.container}>
      <span>
        <Row style={{ margin: 0 }}>
          <Col xs={12} className={classes.details}>
            <DetailRow className={classes.subject}>
              <DetailElement label="Oggetto" value={procedure.subject} />
            </DetailRow>
            <DetailRow>
              <DetailElement
                label="Creato Da"
                value={procedure.shared_by.full_name}
              />
              <DetailElement
                label="Data di Creazione"
                value={procedure.created_at}
              />
            </DetailRow>
          </Col>
          <Col xs={12} className={classes.sharesList}>
            <SharesList
              isOwner={isOwner}
              onProcedureShared={onProcedureShared}
              onShareDelete={onShareDelete}
              procedure={procedure}
            />
          </Col>
          <Col xs={12} className={classes.documentContainer}>
            <Document
              fileName={procedure.document.name}
              extension={procedure.document.extension}
              date={procedure.document.date}
              isDownloading={isDownloadingDocument}
              onClick={onDocumentClicked}
            />
          </Col>
        </Row>
      </span>
    </div>
  );
};

ProcedureDetail.propTypes = {
  procedure: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  onShareDelete: PropTypes.func.isRequired,
  onProcedureShared: PropTypes.func,
};

export default withRouter(withStyles(styles)(ProcedureDetail));
