import { StateMachine } from 'redux-sigma';
import { createLocalStateMachineContext } from '@moveax/redux-stm-act-react-utils';
import { useContext } from 'react';
import { CommonEvents } from './common.events';
import {
  ApproveRejectingInstanceEvents,
  errorApprovingInstance,
  errorRejectingInstance,
  instanceApproved,
  instanceRejected,
  UserConfirmedRejectInstance,
  UserPressedApproveInstance,
} from './approveRejectingInstanceEvents';
import { put, StrictEffect } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import {
  approveInstance,
  rejectInstance,
} from '../../components/serviziSociali/api/api';
import {
  ApproveWelfareInstanceOperationRequest,
  RejectWelfareInstanceOperationRequest,
} from '../../gen/api/welfare';
import store from '../../store';
import { procedureUpdated } from './procedureDetail.events';
import { reloadRequested } from './procedureEvents.events';
import { TemporaryDocument } from '../../components/serviziSociali/api/model';

export enum ApproveRejectStates {
  waiting = 'states/waiting',
  confirmReject = 'states/confirmReject',
  refusing = 'states/refusing',
  confirmApprove = 'states/confirmApprove',
  approving = 'states/approving',
}

export interface Context {
  instanceId: string;
  rejectReason?: string;
  executiveDecision?: TemporaryDocument;
  detailDispatch: (ev: any) => void;
  eventsDispatch: (ev: any) => void;
}

export class ApproveRejectInstanceStm extends StateMachine<
  ApproveRejectingInstanceEvents & CommonEvents,
  ApproveRejectStates,
  string,
  Context
> {
  readonly name = 'approveRejectInstance';

  protected readonly initialState = ApproveRejectStates.waiting;

  protected readonly spec = {
    [ApproveRejectStates.waiting]: {
      transitions: {
        [ApproveRejectingInstanceEvents.userPressedRejectInstance]:
          ApproveRejectStates.confirmReject,
        [ApproveRejectingInstanceEvents.userPressedApproveInstance]: {
          target: ApproveRejectStates.confirmApprove,
          command: this.setTemporaryExecutiveDecision,
        },
      },
    },
    [ApproveRejectStates.confirmReject]: {
      transitions: {
        [CommonEvents.userPressedBack]: ApproveRejectStates.waiting,
        [ApproveRejectingInstanceEvents.userConfirmedRejectInstance]: {
          target: ApproveRejectStates.refusing,
          command: this.saveRejectReasonInCtx,
        },
      },
    },
    [ApproveRejectStates.confirmApprove]: {
      transitions: {
        [CommonEvents.userPressedBack]: ApproveRejectStates.waiting,
        [ApproveRejectingInstanceEvents.userConfirmedApproveInstance]:
          ApproveRejectStates.approving,
      },
    },
    [ApproveRejectStates.refusing]: {
      onEntry: this.rejectInstance,
      transitions: {
        [ApproveRejectingInstanceEvents.instanceRejected]:
          ApproveRejectStates.waiting,
        [ApproveRejectingInstanceEvents.errorRejectingInstance]:
          ApproveRejectStates.waiting,
      },
    },
    [ApproveRejectStates.approving]: {
      onEntry: this.approveInstance,
      transitions: {
        [ApproveRejectingInstanceEvents.instanceApproved]:
          ApproveRejectStates.waiting,
        [ApproveRejectingInstanceEvents.errorApprovingInstance]:
          ApproveRejectStates.waiting,
      },
    },
  };

  *saveRejectReasonInCtx(
    event: UserConfirmedRejectInstance
  ): Generator<StrictEffect, void> {
    yield* this.setContext(ctx => {
      ctx.rejectReason = event.payload.reason;
    });
  }

  *setTemporaryExecutiveDecision(event: UserPressedApproveInstance) {
    yield* this.setContext(ctx => {
      ctx.executiveDecision = event.payload;
    });
  }

  *rejectInstance(): Generator<StrictEffect, void> {
    const request: RejectWelfareInstanceOperationRequest = {
      id: this.context.instanceId,
      rejectWelfareInstanceRequest: {
        reason: this.context.rejectReason!,
      },
    };
    try {
      const instance = yield* call(rejectInstance, request);
      this.context.detailDispatch(procedureUpdated(instance));
      this.context.eventsDispatch(reloadRequested());
      yield put(instanceRejected());
    } catch (e) {
      const { snackBar } = store.app;
      snackBar.open('Si è verificato un errore imprevisto');
      yield put(errorRejectingInstance(e));
    }
  }

  *approveInstance(): Generator<StrictEffect, void> {
    const request: ApproveWelfareInstanceOperationRequest = {
      id: this.context.instanceId,
      approveWelfareInstanceRequest: this.context.executiveDecision
        ? { approvalDocument: this.context.executiveDecision }
        : undefined,
    };
    try {
      const instance = yield* call(approveInstance, request);
      this.context.detailDispatch(procedureUpdated(instance));
      this.context.eventsDispatch(reloadRequested());
      yield put(instanceApproved());
    } catch (e) {
      const { snackBar } = store.app;
      snackBar.open('Si è verificato un errore imprevisto');
      yield put(errorApprovingInstance(e));
    }
  }
}

export const approveRejectInstanceStm = new ApproveRejectInstanceStm();

export const ApproveRejectInstanceContext = createLocalStateMachineContext(
  approveRejectInstanceStm
);

export function useApproveRejectInstanceContext() {
  return useContext(ApproveRejectInstanceContext);
}
