import React from 'react';
import { ProcedureDetail } from '../../../../serviziSociali/detail/layout/Detail/layout/ProcedureDetail';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
    padding: '24px',
  },
});

const WelfareInstanceDetails = ({ classes, instanceDetails }) => {
  return (
    <div className={classes.bodyContainer}>
      <ProcedureDetail readOnly procedure={instanceDetails} />
    </div>
  );
};

export default withStyles(styles)(WelfareInstanceDetails);
