import React from 'react';
import Validators from '../../utils/validators';
import { Col, Row } from 'react-flexbox-grid';
import Misc from '../../api/Misc';
import FormControlValidated from '../common/FormControlValidated';
import {
  CircularProgress,
  Input,
  MenuItem,
  Select,
  Typography,
  Button,
  withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  PERSON_DOCUMENT_TYPE,
  getDocumentTypeLabel,
} from '../../constants/common';
import Loader from '../common/Loader';
import moment from 'moment';
import Cittadini from '../../api/Cittadini';
import store from '../../store';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/moment';

const styles = theme => ({
  formControl: {
    width: '100%',
    margin: '10px 0px',
    textAlign: 'left',
  },
  labelError: {
    color: theme.palette.error,
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
  section: {
    marginTop: '30px',
    marginBottom: '20px',
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
});

class EditCitizen extends React.Component {
  constructor(props) {
    super(props);
    const person = this.props.user.person;
    this.state = {
      saving: false,
      fields: {
        first_name: person.first_name,
        last_name: person.last_name,
        professional_title: person.professional_title
          ? person.professional_title.long
          : 'noone',
        gender: person.gender,
        fiscal_code: person.fiscal_code,
        vat: person.vat ? person.vat : '',
        document_type: person.document ? person.document.type : '',
        document_number: person.document ? person.document.number : '',
        birthday: person.birth_date,
        country_of_birth: person.birthplace.is_foreign
          ? person.birthplace.county
          : 'IT',
        county_of_birth: person.birthplace.is_foreign
          ? ''
          : person.birthplace.county,
        birthplace: person.birthplace.is_foreign ? '' : person.birthplace.city,
        street_name: person.address.street_name,
        postcode: person.address.postcode,
        county: person.address.county_code ? person.address.county_code : '',
        city: person.address.city_code ? person.address.city_code : '',
        email: person.email,
        phone: person.phone,
      },
      errors: {},
      loadingCountries: false,
      loadingCounties: false,
      loadingBirthMunicipalities: false,
      loadingAddressMunicipalities: false,
      loadingProfessionalTitles: false,
      countries: [],
      counties: [],
      birthMunicipalities: [],
      addressMunicipalities: [],
      professionalTitles: [],
    };
  }

  componentDidMount() {
    this.loadProfessionalTitles();
    this.loadCountries();
    this.loadCounties(() => {
      if (this.state.fields.county_of_birth) {
        this.loadMunicipalities(
          this.state.fields.county_of_birth,
          'birthMunicipalities'
        );
      }

      if (this.state.fields.county) {
        this.loadMunicipalities(
          this.state.fields.county,
          'addressMunicipalities'
        );
      }
    });
  }

  loadCountries = () => {
    this.setState(
      {
        loadingCountries: true,
      },
      () => {
        Misc.getCountries(
          countries => {
            this.setState({
              loadingCountries: false,
              countries,
            });
          },
          () => {
            this.setState({
              loadingCountries: false,
              countries: [],
            });
          }
        );
      }
    );
  };

  loadProfessionalTitles = () => {
    this.setState(
      {
        loadingProfessionalTitles: true,
      },
      () => {
        Misc.getProfessionalTitles(
          professionalTitles => {
            this.setState({
              loadingProfessionalTitles: false,
              professionalTitles,
            });
          },
          () => {
            this.setState({
              loadingProfessionalTitles: false,
              professionalTitles: [],
            });
          }
        );
      }
    );
  };

  loadCounties = (onDone = () => {}) => {
    this.setState(
      {
        loadingCounties: true,
      },
      () => {
        Misc.getCountyList(
          counties => {
            this.setState(
              {
                loadingCounties: false,
                counties,
              },
              onDone
            );
          },
          () => {
            this.setState({
              loadingCounties: false,
              counties: [],
            });
          }
        );
      }
    );
  };

  loadMunicipalities = (countyCode, property) => {
    const loadingProperty =
      property === 'birthMunicipalities'
        ? 'loadingBirthMunicipalities'
        : 'loadingAddressMunicipalities';
    this.setState(
      {
        [loadingProperty]: true,
      },
      () => {
        Misc.getMunicipalities(
          countyCode,
          municipalities => {
            this.setState({
              [loadingProperty]: false,
              [property]: municipalities,
            });
          },
          () => {
            this.setState({
              [loadingProperty]: false,
              [property]: [],
            });
          }
        );
      }
    );
  };

  validateField = (name, value, saveToState = true) => {
    const notRequiredFields = [
      'professional_title',
      'vat',
      'street_name',
      'postcode',
      'city',
      'county',
      'email',
    ];
    const errors = this.state.errors;
    let message = null;

    if (
      (name === 'county_of_birth' || name === 'birthplace') &&
      this.state.fields.country_of_birth !== 'IT'
    ) {
      notRequiredFields.push('county_of_birth', 'birthplace');
    }

    if (
      notRequiredFields.indexOf(name) === -1 &&
      Validators.isEmptyString(value)
    ) {
      message = 'Questo campo è richiesto';
    }

    try {
      if (name === 'fiscal_code' && !Validators.isValidFiscalCode(value)) {
        message = 'Il codice fiscale non è valido';
      }
    } catch (e) {
      message = 'Il codice fiscale non è valido';
    }
    if (name === 'birth_date' && !Validators.isValidBirthDate(value)) {
      message = 'La data di nascita non è nei parametri validi';
    }
    if (name === 'phone' && !Validators.isValidMobilePhone(value)) {
      message = 'Il numero di telefono non è valido';
    }
    if (name === 'email' && !Validators.isValidEmail(value)) {
      message = "L'email inserita non è valida";
    }

    if (message) {
      errors[name] = message;
      if (saveToState) {
        this.setState({
          errors,
        });
      }
      return message;
    }

    return true;
  };

  handleFieldChange = event => {
    const name = event.target.name;
    if (name === 'fiscal_code')
      event.target.value = event.target.value.toUpperCase();
    const value = event.target.value;
    const fields = this.state.fields;
    const errors = this.state.errors;

    const prevVal = fields[name];
    fields[name] = value;
    delete errors[name];

    if (name === 'country_of_birth' && value !== 'IT') {
      fields.county_of_birth = null;
      fields.birthplace = null;
    }

    if (name === 'county_of_birth') {
      this.loadMunicipalities(value, 'birthMunicipalities');
      fields.birthplace = prevVal !== value ? null : fields.birthplace;
    } else if (name === 'county')
      this.loadMunicipalities(value, 'addressMunicipalities');

    this.setState({
      fields,
      errors,
    });
  };

  parseDateString = dateString => {
    if (!dateString) return null;
    return moment(dateString, 'DD/MM/YYYY');
  };

  handleDateChange = name => dateValue => {
    const fields = this.state.fields;
    const errors = this.state.errors;

    delete errors[name];

    fields[name] = dateValue != null ? dateValue.format('DD/MM/YYYY') : null;
    this.setState({
      fields,
      errors,
    });
  };

  onBlur = ({ currentTarget }) => {
    const name = currentTarget.name;
    const value = currentTarget.value;

    this.validateField(name, value);
  };

  fieldError = field => {
    return this.state.errors[field];
  };

  validate = onDone => {
    const errors = {};
    Object.keys(this.state.fields).forEach(key => {
      const message = this.validateField(key, this.state.fields[key], false);
      if (message !== true) errors[key] = message;
    });

    return onDone(errors);
  };

  onSave = () => {
    this.setState(
      {
        saving: true,
      },
      () => {
        this.validate(errors => {
          if (Object.keys(errors).length > 0) {
            this.setState({
              saving: false,
              errors,
            });
            return false;
          }
          const data = Object.assign({}, this.state.fields);

          if (data.professional_title === 'noone') {
            delete data['professional_title'];
          } else {
            data.professional_title = data.professional_title.toLowerCase();
          }
          if (data.vat === '') delete data['vat'];
          if (data.country_of_birth === 'IT') {
            delete data['country_of_birth'];
          } else {
            delete data['county_of_birth'];
            delete data['birthplace'];
          }

          const addressFields = ['street_name', 'postcode', 'county', 'city'];
          let address;

          addressFields.forEach(key => {
            if (this.state.fields[key]) {
              if (!address) address = {};
              address[key] = this.state.fields[key];
            }
          });

          delete data['street_name'];
          delete data['postcode'];
          delete data['county'];
          delete data['city'];

          data.birthday = moment(data.birthday, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          );
          if (address) data.address = address;

          const editCallback = this.props.asRegistrationRequest
            ? Cittadini.editRequest
            : Cittadini.editPerson;
          const resourceId = this.props.asRegistrationRequest
            ? this.props.requestId
            : this.props.user.id;

          editCallback(
            resourceId,
            data,
            person => {
              this.setState({ saving: false }, () =>
                this.props.onComplete(person)
              );
            },
            error => {
              if (error.data.error_code === 100003)
                this.parseValidationError(error.data.details);
              else
                store.app.snackBar.open(
                  'Si è verificato un errore, operazione non riuscita'
                );
              this.setState({ saving: false });
            }
          );
        });
      }
    );
  };

  parseValidationError = details => {
    if (!details) return;
    const errors = {};
    for (const field in details) {
      if (details.hasOwnProperty(field)) {
        errors[field] = details[field][0];
      }
    }
    this.setState({
      errors,
    });
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    const {
      fields,
      loadingCountries,
      loadingCounties,
      loadingBirthMunicipalities,
      loadingAddressMunicipalities,
      loadingProfessionalTitles,
      saving,
    } = this.state;
    const { classes, asRegistrationRequest } = this.props;
    const loading =
      loadingCounties || loadingCountries || loadingProfessionalTitles;
    const isForeigner = fields.country_of_birth !== 'IT';

    return loading ? (
      <Row>
        <Loader loading={loading} />
      </Row>
    ) : (
      <Row>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="professional_title"
            label="Titolo Professionale"
          >
            <Select
              name="professional_title"
              value={fields.professional_title}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 0,
              }}
            >
              <MenuItem value="noone">Nessuno</MenuItem>
              {this.state.professionalTitles.map(title => (
                <MenuItem key={title} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={0} sm={6} />
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="first_name"
            label="Nome"
            error={this.fieldError('first_name')}
            shrinkLabel
          >
            <Input
              id="first_name"
              name="first_name"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 1,
              }}
              type="text"
              label="Nome"
              onChange={this.handleFieldChange}
              defaultValue={fields.first_name}
              classes={{
                inkbar: !this.fieldError('first_name')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="last_name"
            label="Cognome"
            error={this.fieldError('last_name')}
            shrinkLabel
          >
            <Input
              id="last_name"
              name="last_name"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 2,
              }}
              type="text"
              label="Cognome"
              onChange={this.handleFieldChange}
              defaultValue={fields.last_name}
              classes={{
                inkbar: !this.fieldError('last_name')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="fiscal_code"
            label="Codice Fiscale"
            error={this.fieldError('fiscal_code')}
            shrinkLabel
          >
            <Input
              id="fiscal_code"
              name="fiscal_code"
              disabled={!asRegistrationRequest}
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 3,
              }}
              type="text"
              label="Codice Fiscale"
              onChange={this.handleFieldChange}
              defaultValue={fields.fiscal_code}
              classes={{
                inkbar: !this.fieldError('fiscal_code')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="vat"
            label="Partita IVA"
            error={this.fieldError('vat')}
            shrinkLabel
          >
            <Input
              id="vat"
              name="vat"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 4,
              }}
              type="text"
              label="Partita IVA"
              onChange={this.handleFieldChange}
              defaultValue={fields.vat}
              classes={{
                inkbar: !this.fieldError('vat')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              style={{
                width: '100%',
                margin: '10px 0',
              }}
              label="Data di nascita"
              name="birthday"
              value={this.parseDateString(fields.birthday)}
              onChange={this.handleDateChange('birthday')}
              format="DD/MM/YYYY"
              disablePast={false}
              minDate={new moment().subtract(120, 'years')}
              maxDate={new moment().subtract(18, 'years')}
              clearable={true}
              cancelLabel="Annulla"
              clearLabel="Ripristina"
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated inputId="gender" label="Sesso">
            <Select
              name="gender"
              value={fields.gender}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 6,
              }}
            >
              <MenuItem value="M">Maschio</MenuItem>
              <MenuItem value="F">Femmina</MenuItem>
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={4}>
          <FormControlValidated
            inputId="country_of_birth"
            label="Paese di Nascita"
            error={this.fieldError('country_of_birth')}
            shrinkLabel
          >
            <Select
              name="country_of_birth"
              value={fields.country_of_birth}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 7,
              }}
            >
              <MenuItem value="IT">Italia</MenuItem>
              {this.state.countries.map(country => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={4}>
          <FormControlValidated
            inputId="county_of_birth"
            label="Provincia di Nascita"
            error={this.fieldError('county_of_birth')}
            shrinkLabel
          >
            <Select
              disabled={isForeigner}
              name="county_of_birth"
              value={fields.county_of_birth}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 8,
              }}
            >
              {this.state.counties.map(county => (
                <MenuItem key={county.code} value={county.code}>
                  {county.name}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={4}>
          <FormControlValidated
            inputId="birthplace"
            label="Comune di Nascita"
            error={this.fieldError('birthplace')}
            shrinkLabel
          >
            <Select
              disabled={isForeigner || loadingBirthMunicipalities}
              name="birthplace"
              value={fields.birthplace}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 9,
              }}
            >
              {this.state.birthMunicipalities.map(municipality => (
                <MenuItem key={municipality.code} value={municipality.code}>
                  {municipality.name}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="document_type"
            label="Tipo Documento"
            shrinkLabel
          >
            <Select
              name="document_type"
              value={fields.document_type}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 10,
              }}
            >
              {Object.values(PERSON_DOCUMENT_TYPE).map(value => (
                <MenuItem key={`document_${value}`} value={value}>
                  {getDocumentTypeLabel(value)}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="document_number"
            label="Numero Documento"
            error={this.fieldError('document_number')}
            shrinkLabel
          >
            <Input
              id="document_number"
              name="document_number"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 11,
              }}
              type="text"
              label="Numero Documento"
              onChange={this.handleFieldChange}
              defaultValue={fields.document_number}
              classes={{
                inkbar: !this.fieldError('document_number')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} className={classes.section}>
          <Typography className={classes.sectionTitle}>
            Indirizzo di Residenza
          </Typography>
        </Col>
        <Col xs={12} sm={12}>
          <FormControlValidated
            inputId="street_name"
            label="Via e Civico"
            error={this.fieldError('street_name')}
            shrinkLabel
          >
            <Input
              id="street_name"
              name="street_name"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 12,
              }}
              type="text"
              label="Via e Civico"
              onChange={this.handleFieldChange}
              defaultValue={fields.street_name}
              classes={{
                inkbar: !this.fieldError('street_name')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={4}>
          <FormControlValidated
            inputId="postcode"
            label="CAP"
            error={this.fieldError('postcode')}
            shrinkLabel
          >
            <Input
              id="postcode"
              name="postcode"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 13,
              }}
              type="text"
              label="CAP"
              onChange={this.handleFieldChange}
              defaultValue={fields.postcode}
              classes={{
                inkbar: !this.fieldError('postcode')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={4}>
          <FormControlValidated inputId="county" label="Provincia">
            <Select
              name="county"
              value={fields.county}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 14,
              }}
            >
              {this.state.counties.map(county => (
                <MenuItem key={county.code} value={county.code}>
                  {county.name}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={4}>
          <FormControlValidated inputId="city" label="Città" shrinkLabel>
            <Select
              disabled={loadingAddressMunicipalities}
              name="city"
              value={fields.city}
              onChange={this.handleFieldChange}
              fullWidth={false}
              inputProps={{
                tabIndex: 15,
              }}
            >
              {this.state.addressMunicipalities.map(municipality => (
                <MenuItem key={municipality.code} value={municipality.code}>
                  {municipality.name}
                </MenuItem>
              ))}
            </Select>
          </FormControlValidated>
        </Col>
        <Col xs={12} className={classes.section}>
          <Typography className={classes.sectionTitle}>Contatti</Typography>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="email"
            label="PEC o Email"
            error={this.fieldError('email')}
            shrinkLabel
          >
            <Input
              id="email"
              name="email"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 16,
              }}
              type="text"
              label="Email"
              onChange={this.handleFieldChange}
              defaultValue={fields.email}
              classes={{
                inkbar: !this.fieldError('email')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12} sm={6}>
          <FormControlValidated
            inputId="phone"
            label="Cellulare"
            error={this.fieldError('phone')}
            shrinkLabel
          >
            <Input
              id="phone"
              name="phone"
              inputProps={{
                onBlur: this.onBlur,
                tabIndex: 17,
              }}
              type="text"
              label="Cellulare"
              onChange={this.handleFieldChange}
              defaultValue={fields.phone}
              classes={{
                inkbar: !this.fieldError('phone')
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              }}
            />
          </FormControlValidated>
        </Col>
        <Col xs={12}>
          <Button
            disabled={saving}
            style={{ marginRight: '10px' }}
            variant="raised"
            color="primary"
            onClick={this.onSave}
          >
            {saving ? <CircularProgress size={20} /> : 'SALVA'}
          </Button>
          <Button
            disabled={saving}
            variant="raised"
            color="default"
            onClick={this.onCancel}
          >
            ANNULLA
          </Button>
        </Col>
      </Row>
    );
  }
}

export default withStyles(styles)(observer(EditCitizen));
