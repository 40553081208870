import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = _theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 24px',
    '& > * + *': {
      marginLeft: 24,
    },
  },
});

const DetailRow = ({ classes, className, children }) => {
  return (
    <Row>
      <Col xs={12} className={cx(classes.container, className)}>
        {children}
      </Col>
    </Row>
  );
};

export default withStyles(styles)(DetailRow);
