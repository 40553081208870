import { DEPARTMENTS } from '../../constants/security';
import ROUTES, { ROUTES_SERVIZI_SOCIALI } from '../../constants/routes';
import {
  getCertificateTypeLabel,
  getServiceType,
} from '../../constants/common';
import { compile } from 'path-to-regexp';
import { SERVICE_LABEL_MAP } from '../serviziSociali/constants';
import { WelfareInstanceTypeDTO } from '../../gen/api/welfare';

const INSTANCE_TYPE_LABELS: { [key: string]: (t: string) => string } = {
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: type =>
    `Certificato ${getCertificateTypeLabel(type)}`,
  [DEPARTMENTS.STATO_CIVILE]: type =>
    `Certificato ${getCertificateTypeLabel(type)}`,
  [DEPARTMENTS.SOCIAL_SERVICES]: type =>
    `Richiesta per ${
      SERVICE_LABEL_MAP[getServiceType(type) as WelfareInstanceTypeDTO]
    }`,
};

const INSTANCE_CALL_TO_ACTION: { [key: string]: (id: string) => string } = {
  [DEPARTMENTS.SERVIZI_DEMOGRAFICI]: (identifier: string) =>
    `/${ROUTES.CERTIFICATI_DEMOGRAFICI}/${identifier}`,
  [DEPARTMENTS.STATO_CIVILE]: (identifier: string) =>
    `/${ROUTES.CERTIFICATI_STATO_CIVILE}/${identifier}`,
  [DEPARTMENTS.SOCIAL_SERVICES]: (identifier: string) => {
    const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`, {
      encode: encodeURIComponent,
    });
    return toPath({ praticaId: identifier });
  },
};

export const getInstanceTypeLabel = (department: string, type: string) => {
  return INSTANCE_TYPE_LABELS[department](type);
};

export const getCallToAction = (department: string, identifier: string) => {
  return INSTANCE_CALL_TO_ACTION[department](identifier);
};
