import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    white: {
      main: '#FFF',
      dark: '#FFF',
      light: '#FFF',
      contrastText: '#555',
    },
    black: {
      main: '#17324D',
      dark: '#17324D',
      light: '#17324D',
      contrastText: '#FFF',
    },
    blue: {
      light: '#E8F2FB',
    },
    primary: {
      main: '#2667C5',
      dark: '#142373',
      light: '#0073E6',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#5f93cd',
      dark: '#ff743f',
      light: '#ffab5c',
      contrastText: '#555',
    },
    grey: {
      main: '#DCDCDC',
      dark: '#5C6771',
      light: '#FAFAFA',
    },
    font: {
      dark: '#000',
      lightDark: '#666666',
      link: '#e8c13b',
      greyDark: '#888888',
      grey: '#aaaaaa',
      greyLight: '#dddddd',
      green: '#619823',
      red: '#FF667D',
      redDark: '#A10018',
    },
    badge: {
      success: '#619823',
      error: '#FF667D',
      warning: '#f5a623',
      info: '#1976d2',
    },
    printButton: {
      main: '#E53935',
      dark: '#D32F2F',
    },
  },
  typography: {
    fontFamily: '"Titillium Web", "Roboto","Helvetica Neue",Arial,sans-serif',
    boxBody: {
      height: '16px',
      fontFamily: '"Titillium Web", "Roboto","Helvetica Neue",Arial,sans-serif',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#17324D',
    },
    bodyStrong: {
      height: '20px',
      fontFamily: '"Titillium Web", "Roboto","Helvetica Neue",Arial,sans-serif',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#17324D',
    },
  },
  custom: {
    appBar: {
      boxShadow: 'none',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '4px',
      },
      sizeSmall: {
        '& > span': {
          lineHeight: '19px!important',
          fontSize: '13px!important',
        },
      },
      label: {
        lineHeight: '27px',
        fontWeight: 'bold',
        fontSize: '18px',
        textTransform: 'none',
        alignItems: 'center',
      },
      raised: {
        boxShadow: 'none',
      },
      outlined: {
        borderWidth: '2px!important',
      },
      outlinedPrimary: {
        border: '2px solid #2667C5',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: '600',
      },
      label: {
        fontSize: '18px!important',
        lineHeight: '27px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none!important',
        borderBottom: '1px solid #DCDCDC',
      },
    },
    MuiToolbar: {
      root: {
        background: '#fff',
        paddingLeft: '0!important',
        paddingRight: '0!important',
        fontSize: '1.5rem',
        fontWeight: 400,
        fontFamily: 'Titillium Web, Roboto, Helvetica Neue, Arial,sans-serif',
        height: '72px!important',
        display: 'flex',
        alignItems: 'center!important',
        flexDirection: 'row!important',
        justifyContent: 'space-evenly!important',
      },
    },
    MuiTypography: {
      title: {
        color: '#17324D',
      },
      body1: {
        color: '#17324D',
      },
    },
  },
});
