import React from 'react';
import { EVENTI } from '../../constants/common';
import UserLoggedIn from './UserLoggedIn';
import UserUpdated from './UserUpdated';
import ProcedureAdded from './ProcedureAdded';
import ProcedureSent from './ProcedureSent';
import ProcedureSkipped from './ProcedureSkipped';
import ProcedureDocumentUploaded from './ProcedureDocumentUploaded';
import SharedProcedureCreated from './SharedProcedureCreated';
import SharedProcedureResolved from './SharedProcedureResolved';
import SharedProcedureClosed from './SharedProcedureClosed';
import SharedProcedureDocumentUploaded from './SharedProcedureDocumentUploaded';
import SharedProcedureDocumentDeleted from './SharedProcedureDocumentDeleted';
import SharedProcedureNotesUpdated from './SharedProcedureNotesUpdated';
import ProcedureCreated from './ProcedureCreated';
import ProcedureUpdated from './ProcedureUpdated';
import ProcedureProcessed from './ProcedureProcessed';
import ProcedureAdditionalDocumentsRequested from './ProcedureAdditionalDocumentsRequested';
import ProcedureApproved from './ProcedureApproved';
import ProcedureRejected from './ProcedureRejected';
import ProcedureExtensionRequested from './ProcedureExtensionRequested';
import ProcedureExtensionApproved from './ProcedureExtensionApproved';
import ProcedureExtensionRejected from './ProcedureExtensionRejected';
import ManagerialActSigned from './ManagerialActSigned';

export const map = {
  [EVENTI.USER_LOGGED_IN]: <UserLoggedIn />,
  [EVENTI.USER_UPDATED]: <UserUpdated />,
  [EVENTI.PROCEDURE_ADDED]: <ProcedureAdded />,
  [EVENTI.PROCEDURE_SENT]: <ProcedureSent />,
  [EVENTI.PROCEDURE_SKIPPED]: <ProcedureSkipped />,
  [EVENTI.PROCEDURE_DOCUMENT_UPLOADED]: <ProcedureDocumentUploaded />,
  [EVENTI.PROCEDURE_CREATED]: <ProcedureCreated />,
  [EVENTI.PROCEDURE_UPDATED]: <ProcedureUpdated />,
  [EVENTI.PROCEDURE_PROCESSED]: <ProcedureProcessed />,
  [EVENTI.PROCEDURE_ADDITIONAL_DOCUMENTS_REQUESTED]: (
    <ProcedureAdditionalDocumentsRequested />
  ),
  [EVENTI.PROCEDURE_APPROVED]: <ProcedureApproved />,
  [EVENTI.PROCEDURE_REJECTED]: <ProcedureRejected />,
  [EVENTI.PROCEDURE_EXTENSION_REQUESTED]: <ProcedureExtensionRequested />,
  [EVENTI.PROCEDURE_EXTENSION_APPROVED]: <ProcedureExtensionApproved />,
  [EVENTI.PROCEDURE_EXTENSION_REJECTED]: <ProcedureExtensionRejected />,
  [EVENTI.SHARED_PROCEDURE_CREATED]: <SharedProcedureCreated />,
  [EVENTI.SHARED_PROCEDURE_RESOLVED]: <SharedProcedureResolved />,
  [EVENTI.SHARED_PROCEDURE_CLOSED]: <SharedProcedureClosed />,
  [EVENTI.SHARED_PROCEDURE_DOCUMENT_UPLOADED]: (
    <SharedProcedureDocumentUploaded />
  ),
  [EVENTI.SHARED_PROCEDURE_DOCUMENT_DELETED]: (
    <SharedProcedureDocumentDeleted />
  ),
  [EVENTI.SHARED_PROCEDURE_NOTES_UPDATED]: <SharedProcedureNotesUpdated />,
  [EVENTI.MANAGERIAL_ACT_SIGNED]: <ManagerialActSigned />,
};

export const eventHasDetails = event => {
  return event && map.hasOwnProperty(event.type);
};

export const renderEvent = event => {
  if (eventHasDetails(event)) {
    return map[event.type];
  }

  return null;
};
