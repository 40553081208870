import React, { FC, useMemo } from 'react';
import { StyledComponentProps } from '@material-ui/core';

import {
  ProcedureDetailStates,
  useProcedureDetail,
} from '../../../stm/socialServices/procedureDetail.stm';
import { ErrorLoading } from './layout/ErrorLoading';
import { Detail } from './layout/Detail';
import { Loading } from './layout/Loading';
import { NotFound } from './layout/NotFound';

const ComponentMap: Record<ProcedureDetailStates, FC> = {
  [ProcedureDetailStates.errorLoading]: ErrorLoading,
  [ProcedureDetailStates.loaded]: Detail,
  [ProcedureDetailStates.loading]: Loading,
  [ProcedureDetailStates.notFound]: NotFound,
};

export const ProcedureDetail: FC<StyledComponentProps> = () => {
  const { state } = useProcedureDetail();

  const Component = useMemo(
    () => ComponentMap[state.state ?? ProcedureDetailStates.loading],
    [state.state]
  );
  return <Component />;
};
