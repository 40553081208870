import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Dialog as MaterialDialog,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { Attachment, TemporaryDocument } from '../../../../api/model';
import { Add, FileUpload } from '@material-ui/icons';
import { useDialog } from '../../../../common/upload/useDialog';
import { UploadDialog } from '../../../../common/upload/Dialog';
import NewWelfareInstance from '../../../../../../api/NewWelfareInstance';
import { useProcedureDetail } from '../../../../../../stm/socialServices/procedureDetail.stm';
import { procedureUpdated } from '../../../../../../stm/socialServices/procedureDetail.events';
import OperationInProgress from '../../../../common/upload/Steps/OperationInProgress';
import moment from 'moment';
import Document from '../../../../common/Document';
import { getDocument } from '../../../../api/api';
import store from '../../../../../../store';
import { DEPARTMENTS, ROLES } from '../../../../../../constants/security';
import EmptyListMessage from '../../../../../common/EmptyListMessage';
import { WelfareInstanceStatusDTO } from '../../../../../../gen/api/welfare';

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleSection: {
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '8px',
  },
  titleButton: {
    alignSelf: 'stretch',
    maxWidth: '300px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0px',
  },
});

interface FileSectionProps extends StyledComponentProps {
  docs: Attachment[];
  readOnly?: boolean;
}

const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;

const FileSection: FC<FileSectionProps> = ({
  classes = {},
  docs: propDocs,
  readOnly = false,
}) => {
  const {
    state: { context: { procedure = undefined } = {} },
    dispatch,
  } = useProcedureDetail();
  const { snackBar, profile } = store.app;
  const { open, openDialog, closeDialog } = useDialog();

  const [tempDocs, setTempDocs] = useState<TemporaryDocument[]>([]);
  const [
    currentDownloadingDocuments,
    setCurrentDownloadingDocuments,
  ] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onDocumentUpload = useCallback((newDoc: TemporaryDocument) => {
    setTempDocs(d => [...d, newDoc]);
  }, []);

  const onDocumentDownload = useCallback(
    ({ fid, name }: Attachment) => {
      return () => {
        setCurrentDownloadingDocuments(d => [...d, fid]);
        getDocument(fid, name)
          .then(() =>
            setCurrentDownloadingDocuments(dArr =>
              dArr.filter(dd => dd !== fid)
            )
          )
          .catch(() => {
            snackBar.open(`Errore nel download del file ${name}`);
            setCurrentDownloadingDocuments(dArr =>
              dArr.filter(dd => dd !== fid)
            );
          });
      };
    },
    [snackBar]
  );

  const onDocumentConfirm = useCallback(() => {
    if (procedure) {
      setLoading(true);
      NewWelfareInstance.addDocument(
        procedure.id,
        tempDocs,
        response => {
          setTempDocs([]);
          dispatch(procedureUpdated(response.data));
          setLoading(false);
        },
        () => {
          //TODO: handle this
          console.error('error during document upload for procedure', {
            procedureId: procedure.id,
            tempDocs,
          });
          snackBar.open(
            'Si è verificato un errore durante il caricamento dei file'
          );
          setLoading(false);
        }
      );
    }
  }, [dispatch, procedure, snackBar, tempDocs]);

  const onDocumentRemove = useCallback((fid: string) => {
    //TODO add callback to remove documents
    return () => {
      setTempDocs(dArr => dArr.filter(d => d.fid !== fid));
    };
  }, []);

  const hasTemporaryDocuments = tempDocs.length > 0;

  const isOpen =
    procedure?.status !== WelfareInstanceStatusDTO.Rejected &&
    procedure?.status !== WelfareInstanceStatusDTO.Approved;
  const isAssignee =
    procedure?.assignee?.fiscalCode === profile.user.person.fiscal_code;
  const isAtLeastPO = profile.isHigherOrEqualThanForDepartment(
    [
      {
        department: DEPARTMENTS.SOCIAL_SERVICES,
        role: ROLES.PO,
      },
    ],
    true
  );
  const canAddDocument = !readOnly && isOpen && (isAssignee || isAtLeastPO);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleSection}>
          <label className={classes.title} style={{ marginRight: 'auto' }}>
            Altra documentazione a supporto
          </label>
          {canAddDocument && (
            <Button
              variant="outlined"
              disabled={open}
              color="primary"
              size="small"
              onClick={openDialog}
              className={classes.titleButton}
            >
              <Add />
              {hasTemporaryDocuments
                ? 'Aggiungi Documento'
                : 'Allega documentazione'}
            </Button>
          )}
          {hasTemporaryDocuments && (
            <Button
              variant="raised"
              disabled={open}
              color="primary"
              size="small"
              onClick={onDocumentConfirm}
              className={classes.titleButton}
            >
              <FileUpload />
              Conferma Caricamento
            </Button>
          )}
        </div>

        <div className={classes.documentContainer}>
          <EmptyListMessage
            visible={tempDocs.length === 0 && propDocs.length === 0}
            message="Non ci sono documenti allegati"
          />
          {propDocs.map(doc => (
            <Document
              key={doc.fid}
              documentId={doc.fid}
              fileName={doc.name}
              extension={DOCUMENT_EXTENSION.exec(doc.name)![1] ?? ''}
              date={moment(doc.createdAt).format('DD/MM/YYYY HH:mm:SS')}
              onClick={onDocumentDownload(doc)}
              isDownloading={currentDownloadingDocuments.includes(doc.fid)}
            />
          ))}
          {tempDocs.map(doc => (
            <Document
              key={doc.fid}
              documentId={doc.fid}
              fileName={doc.name}
              extension={DOCUMENT_EXTENSION.exec(doc.name)![1] ?? ''}
              deletable
              onClick={onDocumentRemove(doc.fid)}
            />
          ))}
        </div>
      </div>
      <UploadDialog
        onDocumentUploaded={onDocumentUpload}
        onClose={closeDialog}
        open={open}
      />
      <MaterialDialog
        open={loading}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="document-upload-dialog-title"
        aria-describedby="document-upload-dialog-description"
      >
        <OperationInProgress message="Upload dei documenti in corso..." />
      </MaterialDialog>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedFileSection = withStyles(styles)(FileSection);

export { DecoratedFileSection as FileSection };
