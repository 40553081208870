import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import EventiAPI from '../../../api/Eventi';
import store from '../../../store';
import { DEPARTMENTS } from '../../../constants/security';
import TableEventi from '../../common/TableEventi';
import FilterItem from '../../common/filters/Filter';
import { FILTER_TYPE, FILTRI_EVENTI } from '../../common/filters/constants';
import GenericListFilters from '../../common/filters/GenericListFilters';

const styles = theme => ({
  bodyStrong: theme.typography.bodyStrong,
});

class EventList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      loadingEvents: false,
      paginationEvents: null,
      filtersEvents: {},
    };
  }

  componentDidMount() {}

  loadEvents = ({ page = 1, filters = this.state.filtersEvents }) => {
    this.setState(
      {
        loadingEvents: true,
      },
      this.getEvents(page, filters)
    );
  };

  getEvents = (page, filters) => {
    return () => {
      EventiAPI.loadList(
        { page: page, filters: filters },
        response => {
          this.setState({
            loadingEvents: false,
            events: response.events,
            paginationEvents: response.meta ? response.meta.pagination : null,
          });
        },
        () => {
          this.setState({ loadingEvents: false });
        }
      );
    };
  };

  applyEventFilters = filters => {
    this.setState({ loadingEvents: true, filtersEvents: filters }, () =>
      this.loadEvents({ page: 1, filters: filters })
    );
  };

  render() {
    const { events, paginationEvents } = this.state;
    const profile = store.app.profile;
    return (
      <span>
        {profile.isMemberOf(DEPARTMENTS.ADMIN) && (
          <div style={{ margin: '0px 20px' }}>
            <GenericListFilters
              filters={[
                new FilterItem(
                  'date_since',
                  FILTER_TYPE.DATE,
                  'Dal',
                  '',
                  null,
                  6,
                  2,
                  2,
                  2,
                  date => date.startOf('day')
                ),
                new FilterItem(
                  'date_until',
                  FILTER_TYPE.DATE,
                  'Al',
                  '',
                  null,
                  6,
                  2,
                  2,
                  2,
                  date => date.endOf('day')
                ),
                new FilterItem(
                  'type',
                  FILTER_TYPE.SELECT,
                  'Evento',
                  '',
                  FILTRI_EVENTI,
                  6,
                  2,
                  2,
                  2
                ),
                new FilterItem(
                  'eventable',
                  FILTER_TYPE.TEXT,
                  'Utente',
                  '',
                  '',
                  6,
                  2,
                  2,
                  2
                ),
                new FilterItem(
                  'executed_by',
                  FILTER_TYPE.TEXT,
                  'Eseguito Da',
                  '',
                  '',
                  6,
                  2,
                  2,
                  2
                ),
              ]}
              applyFilters={this.applyEventFilters}
            />
            <TableEventi
              goToUserDetail={this.props.goToUserDetails}
              goToSessionDetails={this.props.goToSessionDetails}
              loading={this.state.loadingEvents}
              loadPage={this.loadEvents}
              events={events}
              pagination={paginationEvents}
            />
          </div>
        )}
      </span>
    );
  }
}

export default withStyles(styles)(EventList);

EventList.propTypes = {
  goToUserDetails: PropTypes.func.isRequired,
  goToSessionDetails: PropTypes.func.isRequired,
};
