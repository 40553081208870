import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  message: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
    color: theme.palette.grey.dark,
    padding: '30px',
    fontSize: '16px',
  },
});

const EmptyListMessage = props => {
  const { classes, visible } = props;
  return (
    visible && (
      <div className={classes.message}>
        <strong>{props.message}</strong>
      </div>
    )
  );
};

export default withStyles(styles)(EmptyListMessage);

EmptyListMessage.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
};

EmptyListMessage.defaultProps = {
  visible: false,
  message: 'Nessun risultato trovato',
};
