import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import ROUTES from '../../constants/routes';

class ProcedureSent extends React.Component {
  onProcedureClick = () => {
    this.props.history.push(
      `${ROUTES.ARCHIVIO_GATE}/${this.props.event.payload.procedure.id}`
    );
  };

  onUserClick = () => {
    this.props.history.push(
      `${ROUTES.UTENTI}/${this.props.event.payload.performed_by.id}`
    );
  };

  render() {
    const { event } = this.props;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onProcedureClick}>
              Pratica n° {event.payload.procedure.full_number}
            </TextLinkPrimary>
            <br />
            <b>Destinatari:</b>
            <br />
            <ul>
              {event.payload.message.to.map(value => (
                <li key={value}>{value}</li>
              ))}
            </ul>
            {event.payload.message.cc.length === 0 ? (
              <>
                <b>CC: </b> Nessuno
                <br />
              </>
            ) : (
              <>
                <b>CC:</b>
                <br />
                <ul>
                  {event.payload.message.cc.map(value => (
                    <li key={value}>{value}</li>
                  ))}
                </ul>
              </>
            )}
            {event.payload.message.cc.length === 0 ? (
              <>
                <b>CCN: </b> Nessuno
                <br />
              </>
            ) : (
              <>
                <b>CCN:</b>
                <br />
                <ul>
                  {event.payload.message.bcc.map(value => (
                    <li key={value}>{value}</li>
                  ))}
                </ul>
              </>
            )}
            <b>Eseguito Da: </b>&nbsp;
            <TextLinkPrimary onClick={this.onUserClick}>
              {event.payload.performed_by.full_name}{' '}
            </TextLinkPrimary>
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(ProcedureSent));
