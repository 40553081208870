import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import Loader from './Loader';
import { DangerButton } from './DangerButton';
import Icon from '../../new/components/icons';

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  message: {
    width: '100%',
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.black.main,
  },
  text: {
    width: '100%',
    textAlign: 'center',
  },
  icon: {
    marginBottom: '10px',
  },
});

const ConfirmButton = withRouter(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        openModal: false,
      };
    }

    onConfirm = () => {
      if (!this.props.withLoading) {
        this.setState({ openModal: false }, () => {
          this.props.onConfirm();
        });
      } else {
        this.setState(
          {
            loading: true,
          },
          () => {
            this.props.onConfirm(() => {
              this.setState({
                loading: false,
                openModal: false,
              });
            });
          }
        );
      }
    };

    openModal = () => {
      this.setState({ openModal: true });
    };

    closeModal = () => {
      this.setState({ openModal: false });
    };

    render() {
      const { classes } = this.props;
      const confirmMessage = this.state.loading
        ? 'Operazione in corso...'
        : this.props.confirmMessage;
      const ButtonComponent =
        this.props.buttonType === 'error' ? DangerButton : Button;

      return (
        <span>
          <ButtonComponent {...this.props.buttonProps} onClick={this.openModal}>
            {this.props.buttonLabel}
          </ButtonComponent>
          <Dialog
            open={this.state.openModal}
            onClose={this.closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.props.text && (
              <DialogContent>
                <Loader size={30} loading={this.state.loading} />
                {!this.state.loading && (
                  <DialogContentText className={classes.content}>
                    <Icon type="Info" size={48} className={classes.icon} />
                    <div className={classes.message}>{confirmMessage}</div>
                    <div className={classes.text}>{this.props.text}</div>
                  </DialogContentText>
                )}
              </DialogContent>
            )}
            {!this.state.loading && (
              <DialogActions>
                <Button onClick={this.closeModal} color="default" autoFocus>
                  Annulla
                </Button>
                <Button
                  onClick={this.onConfirm}
                  color="primary"
                  variant="raised"
                >
                  Conferma
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </span>
      );
    }
  }
);

export default withStyles(styles)(ConfirmButton);

ConfirmButton.propTypes = {
  buttonProps: PropTypes.object,
  buttonType: PropTypes.oneOf(['normal', 'error']).isRequired,
  buttonLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onConfirm: PropTypes.func,
  confirmMessage: PropTypes.string,
  text: PropTypes.string,
  withLoading: PropTypes.bool,
};

ConfirmButton.defaultProps = {
  onConfirm: () => {},
  buttonType: 'normal',
  confirmMessage: 'Vuoi Procedere?',
  text: null,
  withLoading: false,
};
