import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/it';
import {
  TimePicker,
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from 'material-ui-pickers';
import DateFnsUtils from '@date-io/moment';
moment.locale('it');

const styles = {
  pickerIcon: {
    width: '30px',
    height: '30px',
    fontSize: '1.3rem',
    textAlign: 'center',
    color: '#b2b2b2',
  },
};

function format(date, formatting) {
  if (date === null) {
    return '';
  }
  return moment(date)
    .locale('it')
    .format(formatting);
}

export const Date = withStyles(styles)(props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        value={props.value}
        onChange={props.handleDate}
        labelFunc={date => format(date, 'DD/MM/YYYY')}
        placeholder={props.placeholder}
        disablePast={props.disablePast}
        maxDate={props.maxDate}
        ampm={undefined}
        InputProps={{
          endAdornment: (
            <span className={props.classes.pickerIcon}>
              <i className="fa fa-calendar" />
            </span>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
});

Date.propTypes = {
  handleDate: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment),
  placeholder: PropTypes.string,
  disablePast: PropTypes.bool,
};

Date.defaultProps = {
  placeholder: 'dd/mm/aaaa',
  disablePast: false,
};

export const OnlyDate = withStyles(styles)(props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        label={props.label}
        value={props.value}
        onChange={props.handleDate}
        labelFunc={date => format(date, 'DD/MM/YYYY')}
        placeholder={props.placeholder}
        disablePast={props.disablePast}
        maxDate={props.maxDate}
        clearable={props.clearable}
        ampm={undefined}
        cancelLabel="Annulla"
        clearLabel="Svuota"
        InputProps={{
          endAdornment: (
            <span className={props.classes.pickerIcon}>
              <i className="fa fa-calendar" />
            </span>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
});

OnlyDate.propTypes = {
  handleDate: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment),
  placeholder: PropTypes.string,
  disablePast: PropTypes.bool,
  clearable: PropTypes.bool,
};

OnlyDate.defaultProps = {
  placeholder: 'dd/mm/aaaa',
  disablePast: false,
  clearable: false,
};

export const Time = withStyles(styles)(props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        value={props.value}
        onChange={props.handleTime}
        ampm={undefined}
        placeholder={props.placeholder}
        InputProps={{
          endAdornment: (
            <span className={props.classes.pickerIcon}>
              <i className="fa fa-clock-o" />
            </span>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
});

Time.propTypes = {
  handleTime: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment),
  placeholder: PropTypes.string,
};

Time.defaultProps = {
  placeholder: 'hh:mm',
};
