/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationError,
    ApplicationErrorFromJSON,
    ApplicationErrorToJSON,
    CitizenDTO,
    CitizenDTOFromJSON,
    CitizenDTOToJSON,
    CitizenUpdateRequest,
    CitizenUpdateRequestFromJSON,
    CitizenUpdateRequestToJSON,
} from '../models';

export interface GetCitizenRequest {
    fiscalCode: string;
}

export interface UpdateCitizenRequest {
    fiscalCode: string;
    citizenUpdateRequest: CitizenUpdateRequest;
}

/**
 * 
 */
export class CitizensApi extends runtime.BaseAPI {

    /**
     * Get citizen details by fiscal code
     */
    async getCitizenRaw(requestParameters: GetCitizenRequest): Promise<runtime.ApiResponse<CitizenDTO>> {
        if (requestParameters.fiscalCode === null || requestParameters.fiscalCode === undefined) {
            throw new runtime.RequiredError('fiscalCode','Required parameter requestParameters.fiscalCode was null or undefined when calling getCitizen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/welfare/citizens/{fiscalCode}`.replace(`{${"fiscalCode"}}`, encodeURIComponent(String(requestParameters.fiscalCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CitizenDTOFromJSON(jsonValue));
    }

    /**
     * Get citizen details by fiscal code
     */
    async getCitizen(requestParameters: GetCitizenRequest): Promise<CitizenDTO> {
        const response = await this.getCitizenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update citizen details by fiscal code
     */
    async updateCitizenRaw(requestParameters: UpdateCitizenRequest): Promise<runtime.ApiResponse<CitizenDTO>> {
        if (requestParameters.fiscalCode === null || requestParameters.fiscalCode === undefined) {
            throw new runtime.RequiredError('fiscalCode','Required parameter requestParameters.fiscalCode was null or undefined when calling updateCitizen.');
        }

        if (requestParameters.citizenUpdateRequest === null || requestParameters.citizenUpdateRequest === undefined) {
            throw new runtime.RequiredError('citizenUpdateRequest','Required parameter requestParameters.citizenUpdateRequest was null or undefined when calling updateCitizen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/welfare/citizens/{fiscalCode}`.replace(`{${"fiscalCode"}}`, encodeURIComponent(String(requestParameters.fiscalCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CitizenUpdateRequestToJSON(requestParameters.citizenUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CitizenDTOFromJSON(jsonValue));
    }

    /**
     * Update citizen details by fiscal code
     */
    async updateCitizen(requestParameters: UpdateCitizenRequest): Promise<CitizenDTO> {
        const response = await this.updateCitizenRaw(requestParameters);
        return await response.value();
    }

}
