import React, { FC } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import cx from 'classnames';

const StatusLabels: { [key: string]: string } = {
  open: 'In Lavorazione',
  approved: 'Approvata',
  rejected: 'Rifiutata/Fallita',
};

const styles = (theme: any) => ({
  container: {
    padding: '1px 6px',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
  },
  rejected: {
    color: '#ba0101',
    backgroundColor: 'rgba(186, 1, 1, 0.2)',
  },
  open: {
    backgroundColor: 'rgba(166, 245, 242, 0.7);',
    color: theme.palette.primary.main,
  },
  approved: {
    color: '#008255',
    backgroundColor: 'rgba(0, 182, 118, 0.2)',
  },
});
interface StatusSectionProps extends StyledComponentProps {
  status: string;
}
const StatusSection: FC<StatusSectionProps> = ({ classes = {}, status }) => (
  <label
    className={cx({
      [classes.container as string]: true,
      [classes.open as string]: status === 'open',
      [classes.rejected as string]: status === 'rejected',
      [classes.approved as string]: status === 'approved',
    })}
  >
    {StatusLabels[status]}
  </label>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedStatusSection = withStyles(styles)(StatusSection);

export { DecoratedStatusSection as StatusSection };
