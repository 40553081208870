import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Button, StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { SectionTitle } from '../../common/SectionTitle';
import { useDialog } from '../../common/upload/useDialog';
import { UploadDialog } from '../../common/upload/Dialog';
import { ChevronLeft, FileUpload as UploadIcon } from '@material-ui/icons';
import FormControlValidated from '../../../common/FormControlValidated';
import { DocumentRow } from '../../common/DocumentRow';
import { TemporaryDocument } from '../../api/model';
import { iseeType } from '../../constants';
import { IseeDTO, WelfareInstanceTypeDTO } from '../../../../gen/api/welfare';
import { CurrencyInput } from '../../new/section/CurrencyInput';

const styles = (theme: any) => ({
  mainContainer: {
    flex: 1,
    width: '40%',
    padding: '16px 48px',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    width: '375px',
    marginTop: '64px',
  },
  footer: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    width: '100%',
    alignSelf: 'stretch',
    padding: '24px 24px 48px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

interface SelectIseeFormProps extends StyledComponentProps {
  initialIsee?: IseeDTO;
  instanceType: WelfareInstanceTypeDTO;
  onConfirm: (isee: { value: string; document: TemporaryDocument }) => void;
  onAbort: () => void;
  abortText?: string;
  confirmText?: string;
  skipAction?: () => void;
  skipText?: string;
}

// eslint-disable-next-line complexity
const SelectIseeForm: FC<SelectIseeFormProps> = ({
  classes = {},
  instanceType,
  initialIsee,
  onConfirm,
  onAbort,
  skipAction,
  abortText = 'Indietro',
  confirmText = 'Conferma i dati e prosegui',
  skipText = 'Salta questo passaggio',
}) => {
  const type = iseeType[instanceType] ?? 'ordinario';
  const [iseeValue, setIseeValue] = useState<string | undefined>(
    initialIsee?.value ?? undefined
  );
  const [iseeError, setIseeError] = useState<string | undefined>();
  const { open, openDialog, closeDialog } = useDialog();
  const [isee, setIsee] = useState<TemporaryDocument | null>(
    initialIsee?.document ?? null
  );

  const onBlur = useCallback(() => {
    if (!iseeValue) {
      setIseeError('Il campo è richiesto');
    } else {
      setIseeError(undefined);
    }
  }, [iseeValue]);
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIseeValue(event.currentTarget.value);
  }, []);
  const onDocumentUpload = useCallback((file: TemporaryDocument) => {
    setIsee(file);
  }, []);

  const onRemove = useCallback(
    () => () => {
      setIsee(null);
    },
    []
  );

  const goOn = useCallback(() => {
    if (isee && iseeValue) {
      onConfirm({ value: iseeValue, document: isee });
    } else if (skipAction) {
      skipAction();
    }
  }, [isee, iseeValue, onConfirm, skipAction]);

  return (
    <>
      <SectionTitle>
        Inserisci il valore I.S.E.E {type} e la certificazione
      </SectionTitle>
      <div className={classes.container}>
        <FormControlValidated
          inputId="iseeValue"
          label="Valore I.S.E.E ordinario"
          error={iseeError}
          shrinkLabel
        >
          <CurrencyInput
            id="iseeValue"
            name="iseeValue"
            inputProps={{
              onBlur: onBlur,
              tabIndex: 1,
            }}
            onChange={onChange}
            defaultValue={iseeValue}
            placeholder="Inserisci il valore"
            classes={
              {
                inkbar: !iseeError
                  ? classes.inputInkbar
                  : classes.inputInkbarError,
              } as Record<string, string>
            }
          />
        </FormControlValidated>
        {!isee && (
          <Button
            variant="outlined"
            disabled={open || !!isee}
            color="primary"
            onClick={openDialog}
            className={classes.documentUpload}
          >
            <UploadIcon /> Allega I.S.E.E
          </Button>
        )}
        {isee && <DocumentRow document={isee} onDelete={onRemove} />}
        <UploadDialog
          open={open}
          onClose={closeDialog}
          onDocumentUploaded={onDocumentUpload}
        />
      </div>
      <div className={classes.footer}>
        <Button variant="flat" color="primary" onClick={onAbort}>
          <ChevronLeft />
          {abortText}
        </Button>
        {!isee || !iseeValue ? (
          skipAction ? (
            <Button variant="outlined" color="primary" onClick={goOn}>
              {skipText}
            </Button>
          ) : (
            <></>
          )
        ) : (
          <Button variant="raised" color="primary" onClick={goOn}>
            {confirmText}
          </Button>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSelectIseeForm = withStyles(styles)(SelectIseeForm);

export { DecoratedSelectIseeForm as SelectIseeForm };
