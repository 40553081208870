import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, StyledComponentProps, withStyles } from '@material-ui/core';
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import SelectDocument from './Steps/SelectDocument';
import OperationInProgress from './Steps/OperationInProgress';
import store from '../../../../store';
import { uploadTemporaryDocument } from '../../api/api';
import { TemporaryDocument } from '../../api/model';

const styles = (theme: any) => ({
  container: {
    minWidth: '600px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
  floatRightButton: {
    justifySelf: 'flex-end',
  },
  root: {
    flex: 1,
  },
  action: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface DialogProps extends StyledComponentProps {
  open?: boolean;
  onClose: () => void;
  onDocumentUploaded: (document: TemporaryDocument) => void;
}

const Dialog: FC<DialogProps> = ({
  classes = {},
  open = false,
  onClose,
  onDocumentUploaded,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { snackBar } = store.app;
  const [uploading, setUploding] = useState(false);
  const [document, setDocument] = useState<File | null>(null);

  const onUpload = useCallback(() => {
    setUploding(true);
  }, [setUploding]);

  const close = useCallback(() => {
    setUploding(false);
    setDocument(null);
    onClose();
  }, [onClose]);

  const onSave = useCallback(
    (id, name) => {
      close();
      onDocumentUploaded({ fid: id, name });
    },
    [close, onDocumentUploaded]
  );

  const storeFile = useCallback(
    (file, name) => {
      uploadTemporaryDocument(file)
        .then(data => onSave(data.fid, name))
        .catch(() => {
          snackBar.open(
            'Si è verificato un errore imprevisto durante il caricamento del file.'
          );
        });
    },
    [onSave, snackBar]
  );

  const uploadDocument = useCallback(() => {
    onUpload();
    storeFile(document, document?.name);
  }, [document, onUpload, storeFile]);

  const onCancel = useCallback(() => {
    close();
  }, [close]);

  const onDocumentSelected = useCallback(d => {
    setDocument(d);
  }, []);

  const onDocumentDeleted = useCallback(() => {
    setDocument(null);
  }, []);

  const dialogTitle = useMemo(() => {
    return uploading
      ? 'Caricamento documento in corso...'
      : 'Seleziona un documento';
  }, [uploading]);

  return (
    <MaterialDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={close}
      aria-labelledby="document-upload-dialog-title"
      aria-describedby="document-upload-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle
          className={classes.dialogTitle}
          id="document-upload-dialog-title"
        >
          <span>{dialogTitle}</span>
          {uploading && (
            <span className={classes.closeIcon} onClick={onCancel}>
              &times;
            </span>
          )}
        </DialogTitle>
        <DialogContent>
          {!uploading && (
            <SelectDocument
              onDocumentSelected={onDocumentSelected}
              onDocumentDeleted={onDocumentDeleted}
              selectedDocument={document!}
            />
          )}
          {uploading && (
            <OperationInProgress message="Upload del documento in corso..." />
          )}
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.root,
            action: classes.action,
          }}
        >
          {!uploading && (
            <>
              <Button onClick={onCancel}>Annulla</Button>
              <Button
                disabled={!document}
                variant="raised"
                color="primary"
                onClick={uploadDocument}
                className={classes.floatRightButton}
              >
                Carica
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </MaterialDialog>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedUploadDialog = withStyles(styles)(Dialog);

export { DecoratedUploadDialog as UploadDialog };
