import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PageTitle from '../common/PageTitle';
import PageContainer from '../common/PageContainer';
import Utenti from '../../api/Utenti';
import store from '../../store';
import Loader from '../common/Loader';
import { getCitizenContext } from '../serviziSociali/api/api';
import { getCitizenFromApi } from '../serviziSociali/api/transformer';
import { NewDetail } from './NewDetail';
import { PersonalSituation } from './PersonalSituation';
import InstancesList from './InstancesList';
import { DEPARTMENTS } from '../../constants/security';
import ListaColloqui from './ListaColloqui';

const DEFAULT_TABS = ['Informazioni', 'Istanze'];

const DettaglioCittadino = props => {
  //TODO: WARNING imodium is on the bottom left drawer until refactor. check indexes in rendering section if modified
  const tabs = store.app.profile.isMemberOf(DEPARTMENTS.SOCIAL_SERVICES)
    ? [...DEFAULT_TABS, 'Situazione personale', 'Colloqui']
    : DEFAULT_TABS;
  const [loading, setIsLoading] = useState(false);
  const [currTab, setCurrentTab] = useState(0);

  const [user, setUser] = useState();
  const [citizenContext, setCitizenContext] = useState();
  const { snackBar } = store.app;

  const { userId } = props.match.params;
  const { history } = props;

  const loadUser = useCallback(
    userId => {
      const handleError = () => {
        snackBar.open(
          'Si è verificato un errore imprevisto durante il caricamento del cittadino'
        );
        setIsLoading(false);
        history.goBack();
      };
      setIsLoading(true);
      Utenti.get(
        userId,
        user => {
          setUser(user);
          getCitizenContext(user.person.fiscal_code)
            .then(data => {
              setCitizenContext(data);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false); //TODO change in handle error
            });
        },
        handleError
      );
    },
    [history, snackBar]
  );

  useEffect(() => {
    loadUser(userId);
  }, [loadUser, userId]);

  //TODO: add citizen context
  return (
    <PageContainer
      whiteBg={[1, 3].includes(currTab)}
      header={
        <PageTitle
          title="Dettagli Cittadino"
          backIcon
          tabs={tabs}
          onTabChange={setCurrentTab}
        />
      }
    >
      <Loader loading={loading} />
      {!loading && user && (
        <>
          {currTab === 0 && <NewDetail data={getCitizenFromApi(user)} />}
          {currTab === 1 && (
            <InstancesList fiscalCode={user.person.fiscal_code} />
          )}
          {currTab === 2 && (
            <PersonalSituation
              data={citizenContext}
              setCitizenContext={setCitizenContext}
              fiscalCode={user.person.fiscal_code}
            />
          )}
          {currTab === 3 && (
            <ListaColloqui beneficiaryFiscalCode={user.person.fiscal_code} />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default withRouter(DettaglioCittadino);
