import { StateMachine } from 'redux-sigma';
import { CommonEvents } from './common.events';
import { createLocalStateMachineContext } from '@moveax/redux-stm-act-react-utils';
import { useContext } from 'react';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import {
  errorLoading,
  notFound,
  ProcedureDetailEvents,
  procedureLoaded,
  ProcedureUpdated,
  SetTemporaryAccountingDecision,
  SetTemporaryExecutiveDecision,
} from './procedureDetail.events';
import { getProcedure } from '../../components/serviziSociali/api/api';
import { WelfareInstanceDTO } from '../../gen/api/welfare';
import { StrictEffect } from 'redux-saga/effects';
import { TemporaryDocument } from '../../components/serviziSociali/api/model';

export enum ProcedureDetailStates {
  loading = 'states/loading',
  loaded = 'states/loaded',
  errorLoading = 'states/errorLoading',
  notFound = 'states/notFound',
}

interface Context {
  id: string;
  procedure?: WelfareInstanceDTO;
  executiveDecision?: TemporaryDocument;
  accountingDecision?: TemporaryDocument;
}

export class ProcedureDetailStm extends StateMachine<
  CommonEvents & ProcedureDetailEvents,
  ProcedureDetailStates,
  string,
  Context
> {
  protected readonly initialState = ProcedureDetailStates.loading;

  readonly name = 'serviziSocialiDettaglioIstanza';

  protected readonly spec = {
    [ProcedureDetailStates.loaded]: {
      reactions: {
        [ProcedureDetailEvents.procedureUpdated]: this.updateProcedure,
        [ProcedureDetailEvents.setTemporaryExecutiveDecision]: this
          .setTemporaryExecutiveDecision,
        [ProcedureDetailEvents.setTemporaryAccountingDecision]: this
          .setTemporaryAccountingDecision,
      },
    },
    [ProcedureDetailStates.loading]: {
      onEntry: this.loadProcedure,
      transitions: {
        [ProcedureDetailEvents.procedureLoaded]: ProcedureDetailStates.loaded,
        [ProcedureDetailEvents.errorLoading]:
          ProcedureDetailStates.errorLoading,
        [ProcedureDetailEvents.notFound]: ProcedureDetailStates.notFound,
      },
    },
    [ProcedureDetailStates.errorLoading]: {
      transitions: {
        [CommonEvents.userPressRetry]: ProcedureDetailStates.loading,
      },
    },
    [ProcedureDetailStates.notFound]: {},
  };

  *updateProcedure(event: ProcedureUpdated): Generator<StrictEffect, void> {
    yield* this.setContext(ctx => {
      ctx.procedure = event.payload;
    });
  }

  *setTemporaryExecutiveDecision(event: SetTemporaryExecutiveDecision) {
    yield* this.setContext(ctx => {
      ctx.executiveDecision = event.payload;
    });
  }

  *setTemporaryAccountingDecision(event: SetTemporaryAccountingDecision) {
    yield* this.setContext(ctx => {
      ctx.accountingDecision = event.payload;
    });
  }

  *loadProcedure(): SagaGenerator<void> {
    try {
      const procedure = yield* call(getProcedure, this.context.id);
      yield* this.setContext(ctx => {
        ctx.procedure = procedure;
      });

      yield* put(procedureLoaded());
    } catch (e) {
      console.log(e);
      if ('status' in e && e.status === 404) {
        yield* put(notFound());
      }
      yield* put(errorLoading());
    }
  }
}

export const procedureDetailStm = new ProcedureDetailStm();
export const ProcedureDetailStmContext = createLocalStateMachineContext(
  procedureDetailStm
);

export function useProcedureDetail() {
  return useContext(ProcedureDetailStmContext);
}
