import React, { useCallback, useEffect, useState } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import PageTitle from '../../../../common/PageTitle';
import PageContainer from '../../../../common/PageContainer';
import { Interview } from '../../../api/model';
import moment from 'moment';
import { getJournalInterview } from '../../../api/api';
import store from '../../../../../store';
import Loader from '../../../../common/Loader';
import InstanceSummary from './InstanceSummary';
import CitizenSummary from '../../../common/CitizenSummary';
import ActivityDetails from './ActivityDetails';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';

interface InterviewRouteParams {
  interviewId: string;
}

interface InterviewDetailProps
  extends StyledComponentProps,
    RouteComponentProps<InterviewRouteParams> {}

const InterviewDetail: React.FC<InterviewDetailProps> = ({
  history,
  match,
}) => {
  const { snackBar } = store.app;
  const { interviewId } = match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [interview, setInterview] = useState<Interview>();

  useEffect(() => {
    setIsLoading(true);
    getJournalInterview(interviewId)
      .then(setInterview)
      .catch(() => {
        snackBar.open('Risorsa non trovata');
        history.goBack();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [history, interviewId, snackBar]);

  const backToList = useCallback(() => {
    history.push({
      pathname: `/${ROUTES_SERVIZI_SOCIALI.DIARIO}`,
      search: '?section=interviews',
    });
  }, [history]);

  return (
    <PageContainer
      header={
        <PageTitle
          /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
          // @ts-ignore
          backIcon
          onBackClicked={backToList}
          title="Dettaglio Attività"
          subtitle={
            interview
              ? `${moment(interview.activityDuration.startDate).format(
                  '[Colloquio del ] DD/MM/YYYY'
                )}`
              : '-'
          }
        />
      }
    >
      <Loader size={40} loading={isLoading} />
      {!isLoading && interview && (
        <>
          {interview.instanceSummary && (
            <InstanceSummary instanceSummary={interview.instanceSummary} />
          )}
          <CitizenSummary citizen={interview.citizen} />
          <ActivityDetails interview={interview} />
        </>
      )}
    </PageContainer>
  );
};

export default InterviewDetail;
