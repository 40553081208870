import React, { FC, useCallback } from 'react';
import { useEditProcedure } from '../../../../../stm/socialServices/editProcedure.stm';
import {
  userAskExit,
  userEditBenefits,
} from '../../../../../stm/socialServices/editProcedure.events';
import { SelectOtherBenefitsForm } from '../../../common/forms/SelectOtherBenefitsForm';

export const EditOtherBenefits: FC = () => {
  const { state, dispatch } = useEditProcedure();

  const onConfirm = useCallback(
    benefits => {
      dispatch(userEditBenefits(benefits));
    },
    [dispatch]
  );
  const goBack = useCallback(() => {
    dispatch(userAskExit());
  }, [dispatch]);

  return (
    <SelectOtherBenefitsForm
      initialBenefits={state?.context?.procedure?.details?.benefits ?? []}
      onConfirm={onConfirm}
      onAbort={goBack}
      abortText="Annulla"
      confirmText="Conferma i dati"
      skipText="Nessun beneficio"
    />
  );
};
