import React, { FC, useCallback } from 'react';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import { userSelectOtherBenefits } from '../../../../stm/socialServices/newProcedure.events';
import { userPressedBack } from '../../../../stm/socialServices/common.events';
import { SelectOtherBenefitsForm } from '../../common/forms/SelectOtherBenefitsForm';

export const SelectOtherBenefits: FC = () => {
  const { state, dispatch } = useNewSocialService();

  const goOn = useCallback(
    benefits => {
      dispatch(userSelectOtherBenefits(benefits));
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  return (
    <SelectOtherBenefitsForm
      initialBenefits={state.context?.otherBenefits}
      onConfirm={goOn}
      onAbort={goBack}
    />
  );
};
