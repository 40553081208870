import React, { useMemo } from 'react';
import { ReactComponent as Analytics } from '../../../assets/icons/analytics.svg';
import { ReactComponent as AnalyticsV2 } from '../../../assets/icons/analytics.v2.svg';
import { ReactComponent as ApprovedDocument } from '../../../assets/icons/approved-document.svg';
import { ReactComponent as Archive } from '../../../assets/icons/archive.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as Back } from '../../../assets/icons/back.svg';
import { ReactComponent as Buildings } from '../../../assets/icons/buildings.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg';
import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as DocumentAdd } from '../../../assets/icons/document-add.svg';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Document } from '../../../assets/icons/document.svg';
import { ReactComponent as Download } from '../../../assets/icons/download.svg';
import { ReactComponent as Email } from '../../../assets/icons/email.svg';
import { ReactComponent as Error } from '../../../assets/icons/error.svg';
import { ReactComponent as Handicap } from '../../../assets/icons/handicap.svg';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as List } from '../../../assets/icons/list.svg';
import { ReactComponent as NewDocument } from '../../../assets/icons/new-document.svg';
import { ReactComponent as PagoPA } from '../../../assets/icons/pagopa.svg';
import { ReactComponent as Pin } from '../../../assets/icons/pin.svg';
import { ReactComponent as DemographicServicesOffice } from '../../../assets/icons/registry-office.svg';
import { ReactComponent as CivilStateOffice } from '../../../assets/icons/departments/stato-civile.svg';
import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { ReactComponent as Share } from '../../../assets/icons/share.svg';
import { ReactComponent as SharingCircle } from '../../../assets/icons/sharing-circle.svg';
import { ReactComponent as SocialServices } from '../../../assets/icons/social-services.svg';
import { ReactComponent as Stamp } from '../../../assets/icons/stamp.svg';
import { ReactComponent as Tax } from '../../../assets/icons/tax.svg';
import { ReactComponent as User } from '../../../assets/icons/user.svg';
import { ReactComponent as Users } from '../../../assets/icons/users.svg';
import { ReactComponent as View } from '../../../assets/icons/view.svg';
import { ReactComponent as VirtualDesk } from '../../../assets/icons/virtual-desk.svg';
import { ReactComponent as Diary } from '../../../assets/icons/diary.svg';
import { ReactComponent as BackCircle } from '../../../assets/icons/back-circle.svg';
import { ReactComponent as ErrorCircle } from '../../../assets/icons/error-circle.svg';

const Icons = {
  Analytics,
  AnalyticsV2,
  ApprovedDocument,
  Archive,
  ArrowRight,
  Back,
  BackCircle,
  Buildings,
  Calendar,
  CheckCircle,
  Check,
  ChevronDown,
  Close,
  Document,
  DocumentAdd,
  Download,
  Diary,
  Email,
  Error,
  ErrorCircle,
  Handicap,
  Home,
  Info,
  List,
  NewDocument,
  PagoPA,
  Pin,
  DemographicServicesOffice,
  CivilStateOffice,
  Search,
  Share,
  SharingCircle,
  SocialServices,
  Stamp,
  Tax,
  User,
  Users,
  VirtualDesk,
  View,
};

export type IconType = keyof typeof Icons;

export interface IconProps {
  className?: string;
  type: IconType;
  size?: number;
  color?: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = ({
  className,
  type,
  size = 24,
  color = '#2667C5',
  onClick,
}) => {
  const IconComponent = useMemo(() => {
    return Icons[type];
  }, [type]);

  return (
    <div className={className} onClick={onClick}>
      <IconComponent height={size} width={size} fill={color} />
    </div>
  );
};

export default Icon;
