import CallManager from './AxiosCallManager';

export default {
  loadList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get('events/', response => onSuccess(response.data), onError, {
      page: page,
      ...filters,
    });
  },
};
