import React, { FC, useCallback } from 'react';
import { UpdateCitizenRequest } from '../../../gen/api/welfare';
import { CitizenContext } from '../../serviziSociali/api/model';
import store from '../../../store';
import { updateCitizen } from './api';
import { fromContextFlagToApi } from './utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import Icon from '../../../new/components/icons';

interface FamilyMembersDialogProps extends StyledComponentProps {
  defaultCitizenData: CitizenContext;
  fiscalCode: string;
  updateCitizenContext: (value: CitizenContext) => void;
  closeDialog: () => void;
  fiscalCodeToDelete: string;
}

const styles = (theme: any) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  message: {
    width: '100%',
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 'bold',
    lineHeight: '32px',
    color: theme.palette.black.main,
  },
  text: {
    width: '100%',
    textAlign: 'center',
  },
  icon: {
    marginBottom: '10px',
  },
});

const RemoveFamilyMembersDialog: FC<FamilyMembersDialogProps> = ({
  defaultCitizenData,
  fiscalCode,
  updateCitizenContext,
  closeDialog,
  classes = {},
  fiscalCodeToDelete,
}) => {
  const { snackBar } = store.app;

  const save = useCallback(async () => {
    try {
      const request: UpdateCitizenRequest = {
        citizenUpdateRequest: {
          relativeMembers: defaultCitizenData.relativeMembers,
          flags: fromContextFlagToApi(defaultCitizenData.flags),
          familyMembers: defaultCitizenData.familyMembers.filter(
            member => member.fiscalCode !== fiscalCodeToDelete
          ),
        },
        fiscalCode,
      };
      const response = await updateCitizen(request);
      updateCitizenContext(response);
      closeDialog();
    } catch (e) {
      snackBar.open('Si è verificato un errore imprevisto');
    }
  }, [
    closeDialog,
    defaultCitizenData.familyMembers,
    defaultCitizenData.flags,
    defaultCitizenData.relativeMembers,
    fiscalCode,
    fiscalCodeToDelete,
    snackBar,
    updateCitizenContext,
  ]);

  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText className={classes.content}>
          <Icon type="Info" size={48} className={classes.icon} />
          <div className={classes.message}>Conferma elimnazione</div>
          <div className={classes.text}>
            Sei sicuro di voler eliminare l`&apos;utente?
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="default" autoFocus>
          Annulla
        </Button>
        <Button onClick={save} color="primary" variant="raised">
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedRemoveFamilyMembersDialog = withStyles(styles)(
  RemoveFamilyMembersDialog
);
export { DecoratedRemoveFamilyMembersDialog as RemoveFamilyMembersDialog };
