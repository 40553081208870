import React, { FC, useCallback, useRef, useState } from 'react';
import {
  Badge,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  StyledComponentProps,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import { StyleRules } from '@material-ui/core/styles';
import { Notification } from '../../new/types/notifications';
import { useNotifications } from '../../new/hooks/notifications';
import NotificationMenuItem from './NotificationMenuItem';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import store from '../../store';

const styles = (_theme: any): StyleRules => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badge: {
    top: -9,
    right: -9,
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    userSelect: 'none',
  },
  icon: {
    width: '32px',
    height: '32px',
  },
});

interface Props extends StyledComponentProps, RouteComponentProps {
  notifications: Notification<any>[];
  unreadCount: number;
}

const _NotificationBell: FC<Props> = ({
  history,
  classes,
  notifications,
  unreadCount,
}) => {
  const anchorEl = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const { markAsRead, formatNotification } = useNotifications();
  const { profile } = store.app;

  const handleToggle = useCallback(() => {
    setOpen(curr => !curr);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onItemClicked = useCallback(
    notification => {
      const { action } = formatNotification(notification);
      markAsRead(notification.id);
      setOpen(false);
      if (action?.department) {
        profile.switchDepartment(action.department);
      }
      if (action?.redirectTo) {
        history.push(action.redirectTo);
      }
    },
    [formatNotification, history, markAsRead, profile]
  );

  if (notifications.length > 0) {
    return (
      <div ref={anchorEl} className={classes?.container}>
        <Badge
          badgeContent={unreadCount > 99 ? '99+' : unreadCount}
          color="primary"
          classes={{ badge: classes?.badge }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup={true}
          onClick={handleToggle}
        >
          <Notifications
            style={{
              width: '32px',
              height: '32px',
            }}
          />
        </Badge>
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {notifications.length > 0 &&
                      notifications.map((n: Notification<any>) => (
                        <NotificationMenuItem
                          key={n.id}
                          notification={n}
                          onNotificationClick={onItemClicked}
                        >
                          {n.type}
                        </NotificationMenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
  return (
    <Notifications
      style={{
        width: '32px',
        height: '32px',
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const NotificationBell = withRouter(
  withStyles(styles)(_NotificationBell)
);
