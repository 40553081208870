import React, { FC } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import {
  EnumFlag,
  FLAG_TYPE,
  flagSituazionePersonaleLabelMap,
  FlagTypes,
} from '../../constants';
import { SelectionFlag } from './SelectionFlag';
import { MultipleSelectionFlag } from './MultipleSelectionFlag';
import { BooleanFlag } from './BooleanFlag';
import { StyleRules } from '@material-ui/core/styles';
import { FlagDTO } from '../../../../gen/api/welfare';

const styles = (_theme: any): StyleRules => ({});

interface FlagProps extends StyledComponentProps {
  flag: FlagDTO;
}

const _Flag: FC<FlagProps> = ({ flag }) => {
  const { type, values } = flag;
  const { type: flagType } = FlagTypes[type];

  if (flagType === FLAG_TYPE.BOOLEAN)
    return (
      <BooleanFlag
        id={type}
        label={flagSituazionePersonaleLabelMap[type]}
        data={values}
      />
    );

  const { options } = FlagTypes[type] as EnumFlag;

  if (flagType === FLAG_TYPE.ENUM)
    return (
      <SelectionFlag
        id={type}
        label={flagSituazionePersonaleLabelMap[type]}
        data={values}
        options={options}
      />
    );

  return (
    <MultipleSelectionFlag
      id={type}
      label={flagSituazionePersonaleLabelMap[type]}
      data={values}
      options={options}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const FlagDisplay = withStyles(styles)(_Flag);
