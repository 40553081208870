import React, { useCallback } from 'react';
import { InstanceSummary as InstanceSummaryInterface } from '../../../api/model';
import { StyledComponentProps } from '@material-ui/core';
import { DetailField } from '../../../detail/layout/Detail/common/DetailField';
import { SECTION_LABEL_MAP } from '../../../constants';
import { withStyles } from '@material-ui/core/styles';
import TextLinkPrimary from '../../../../common/TextLinkPrimary';
import { compile } from 'path-to-regexp';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface InstanceSummaryProps
  extends StyledComponentProps,
    RouteComponentProps {
  instanceSummary: InstanceSummaryInterface;
}

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 24,
  },
  titleSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
});

const InstanceSummary: React.FC<InstanceSummaryProps> = ({
  classes = {},
  instanceSummary,
  history,
}) => {
  const onDetailClicked = useCallback(() => {
    const toPath = compile(`/${ROUTES_SERVIZI_SOCIALI.DETTAGLIO_PRATICA}`, {
      encode: encodeURIComponent,
    });
    history.push(toPath({ praticaId: instanceSummary.id }));
  }, [history, instanceSummary.id]);

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Istanza Associata</label>
        <TextLinkPrimary bold onClick={onDetailClicked}>
          Dettaglio completo
        </TextLinkPrimary>
      </div>
      <div className={classes.fieldContainer}>
        <DetailField
          label="Sezione"
          value={SECTION_LABEL_MAP[instanceSummary.section]}
        />
        <DetailField
          label="Istanza di Riferimento"
          value={`n° ${instanceSummary.protocol.number} / ${instanceSummary.protocol.year}`}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(withRouter(InstanceSummary));
