import { TypePagination } from '../constants';
import {
  BenefitTypeDTO,
  ExpenseTypeDTO,
  FamilyMemberDTO,
  FlagTypeDTO,
  ProtocolDTO,
  WelfareInstanceSectionDTO,
  WelfareInstanceTypeDTO,
  WelfareModuleTypeDTO,
  WelfareRequestorTypeDTO,
} from '../../../gen/api/welfare';

export enum DocumentType {
  idCard = 'id_card',
  passport = 'passport',
  drivingLicense = 'driving_license',
}

export interface FamilyMember extends FamilyMemberDTO {
  id: string;
}

export type PersonalSituation = Partial<Record<FlagTypeDTO, string[]>>;

export interface CitizenRequest {
  firstName: string;
  lastName: string;
  professionalTitle?: string;
  fiscalCode: string;
  vat?: string;
  documentType: DocumentType;
  documentNumber: string;
  birthday: Date;
  gender: 'M' | 'F';
  birthPlaceCountry: string;
  birthPlaceCounty: string;
  birthPlaceCityName?: string; //TODO: remove optional
  birthPlace: string;
  address: {
    streetName?: string;
    postcode?: string;
    county?: string;
    city?: string;
  };
  email?: string;
  phoneNumber?: string;
}

export interface Citizen extends CitizenRequest {
  id: string;
  uuid: string;
}

export interface Person {
  userId: number;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  birthDate: Date;
  phoneNumber: string;
  email: string;
}

export interface TemporaryDocument {
  fid: string;
  name: string;
}

export interface Document {
  fid: string;
  name: string;
  createdAt: Date;
}

interface UploadedTempFileReference {
  fid: string;
}

interface TempFileConfirmationRequest extends UploadedTempFileReference {
  name: string;
}

export interface CitizenContext {
  familyMembers: FamilyMember[];
  relativeMembers: FamilyMember[];
  flags: PersonalSituation;
}

export const defaultCitizenContext: CitizenContext = {
  familyMembers: [],
  flags: {},
  relativeMembers: [],
};

export interface BenefitSelected {
  type: BenefitTypeDTO;
  value: string;
}

export interface ExpenseSelected {
  type: ExpenseTypeDTO;
  value: string;
  doc?: TemporaryDocument;
}

export interface UndeductedExpenseRequest {
  type: ExpenseTypeDTO;
  value: string;
  document?: TempFileConfirmationRequest;
}

export interface Attachment {
  name: string;
  fid: string;
  createdAt: Date;
}

export interface Note {
  author: {
    id: number;
    name: string;
    lastname: string;
  };
  text: string;
  createdAt: Date;
}

type ExpensesDefinitive = Omit<UndeductedExpenseRequest, 'doc'> & {
  doc: Attachment;
};
export interface ModuleADetail {
  flag: { type: FlagTypeDTO; data: string[] }[];
  familyMembers: FamilyMember[];
  relativeMembers: FamilyMember[];
  isee?: {
    value: string;
    document: Attachment;
  };
  expenses: ExpensesDefinitive[];
  benefits: BenefitSelected[];
}

export interface FlagDTO {
  type: FlagTypeDTO;
  values?: string[];
}

interface WelfareInstanceModuleDiscriminator {
  module: WelfareModuleTypeDTO.AModule;
}

interface RequestorDataRequest {
  personFiscalCode: string;
  type: WelfareRequestorTypeDTO;
  documents: TempFileConfirmationRequest[];
}

interface IseeRequest {
  value: string;
  document: TempFileConfirmationRequest;
}

interface WelfareAModuleDetailsRequest
  extends WelfareInstanceModuleDiscriminator {
  flags?: FlagDTO[];
  familyMembers?: FamilyMember[];
  relativeMembers?: FamilyMember[];
  isee?: IseeRequest;
  undeductedExpenses?: UndeductedExpenseRequest[];
  benefits?: BenefitSelected[];
}

export interface WelfareInstanceCreationRequest {
  requestor: RequestorDataRequest;
  beneficiaryFiscalCode: string;
  section: WelfareInstanceSectionDTO;
  type: WelfareInstanceTypeDTO;
  details: WelfareAModuleDetailsRequest;
}

export interface CreateJournalActivityRequest {
  citizenFiscalCode: string;
  documents?: TemporaryDocument[];
}
export interface ActivityDuration {
  startDate: string;
  endDate: string;
}

export interface InstanceSummary {
  id: string;
  protocol: ProtocolDTO;
  section: WelfareInstanceSectionDTO;
  type: WelfareInstanceTypeDTO;
}

export interface CreateInterviewRequest extends CreateJournalActivityRequest {
  activityDuration: ActivityDuration;
  section: WelfareInstanceSectionDTO;
  note?: string;
  instanceId?: string;
}

export interface CreateSecretariatActivityRequest
  extends CreateJournalActivityRequest {
  note: string;
}

export interface InterviewListItem {
  id: string;
  number: number;
  year: number;
  createdAt: Date;
  createdBy: Person;
  citizenFiscalCode: string;
  activityDuration: ActivityDuration;
  section: WelfareInstanceSectionDTO;
}

export interface Interview extends InterviewListItem {
  citizen: Person;
  instanceSummary: InstanceSummary;
  note?: string;
  documents?: Document[];
}

export interface InterviewListResponse {
  interviews: InterviewListItem[];
  pagination: TypePagination;
}

export interface InterviewListFilters {
  beneficiaryFiscalCode?: string;
  section?: WelfareInstanceSectionDTO[];
  createdBy?: Pick<Citizen, 'firstName' | 'lastName' | 'uuid'>;
  since: string;
  until: string;
}

export interface SecretariatActivityListItem {
  id: string;
  number: number;
  year: number;
  citizenFiscalCode: string;
  createdBy: Person;
  createdAt: Date;
}

export interface SecretariatActivity extends SecretariatActivityListItem {
  citizen: Person;
  note: string;
  documents?: Document[];
}

export interface SecretariatActivityListResponse {
  activities: SecretariatActivityListItem[];
  pagination: TypePagination;
}

export enum JournalActivityKind {
  INTERVIEW = 'INTERVIEW',
  SECRETARIAT = 'SECRETARIAT',
}
