import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import {
  getAbstractProcedureLink,
  getServiceType,
} from '../../constants/common';

class ProcedureExtensionRequested extends React.Component {
  onClick = () => {
    const extension = this.props.event.payload.extension;
    this.props.history.push(
      getAbstractProcedureLink(extension.procedure_type, extension.procedure_id)
    );
  };

  render() {
    const { event } = this.props;
    const extension = event.payload.extension;

    return (
      <>
        {event.payload && (
          <span>
            <b>Tipo Pratica: </b>{' '}
            <TextLinkPrimary onClick={this.onClick}>
              {getServiceType(extension.procedure_type)}
            </TextLinkPrimary>
            <br />
            <b>Nuova scadenza richiesta: </b> {extension.expires_on}
            <br />
            <b>Richiesta da: </b>{' '}
            {event.payload.performed_by
              ? event.payload.performed_by.full_name
              : 'N/A'}
            <br />
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(ProcedureExtensionRequested));
