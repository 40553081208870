import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  getCertificateStateLabel,
  CERTIFICATE_REQUEST_STATE,
} from '../../../constants/common';
import React from 'react';
import PropTypes from 'prop-types';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
    width: 'fit-content',
  },
  stateSuccessful: {
    backgroundColor: '#00600f',
  },
  stateFailed: {
    backgroundColor: '#ba0101',
  },
  stateHandled: {
    backgroundColor: '#EED202',
  },
  stateResolved: {
    backgroundColor: '#2667C5',
  },
});

const StatusBadge = withStyles(styles)(props => {
  const { classes, state } = props;

  return (
    <div
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateSuccessful]:
          state === CERTIFICATE_REQUEST_STATE.SUCCESSFUL,
        [classes.stateResolved]: state === CERTIFICATE_REQUEST_STATE.RESOLVED,
        [classes.stateHandled]: state === CERTIFICATE_REQUEST_STATE.HANDLED,
        [classes.stateFailed]: state === CERTIFICATE_REQUEST_STATE.FAILED,
      })}
    >
      {getCertificateStateLabel(state)}
    </div>
  );
});

StatusBadge.propTypes = {
  state: PropTypes.string.isRequired,
};

export default StatusBadge;
