import React, { FC, useMemo } from 'react';
import { SelectionFlagProps } from '../model';
import { DetailField } from './CustomDetailField';

const _MultipleSelectionFlag: FC<SelectionFlagProps> = ({
  label,
  data = [],
  options,
}) => {
  const value = useMemo(
    () => data?.map(v => options.find(opt => opt.id === v)?.text ?? v),
    [data, options]
  );
  return <DetailField label={label} value={value} oneline tooltip={value} />;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const MultipleSelectionFlag = _MultipleSelectionFlag;
