import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  bodyStrong: theme.typography.bodyStrong,
});

const LabelWithValue = props => {
  const { classes } = props;
  return (
    <>
      <div style={{ display: props.horizontal ? 'inline-block' : 'block' }}>
        <strong>{props.label}</strong>&nbsp;
      </div>
      <div
        style={{ display: props.horizontal ? 'inline-block' : 'block' }}
        className={classes.bodyStrong}
      >
        {props.value}
      </div>
    </>
  );
};

LabelWithValue.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  horizontal: PropTypes.bool,
};

LabelWithValue.defaultProps = {
  horizontal: false,
};

export default withStyles(styles)(LabelWithValue);
