import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import history from '../../utils/history';
import TabBar from './TabBar';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderBottom: '1px solid ' + theme.palette.grey.main,
    paddingTop: 20,
    backgroundColor: theme.palette.white.main,
  },
  content: {
    display: 'flex',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  backContainer: {
    display: 'flex',
    borderRight: '1px solid ' + theme.palette.grey.main,
    alignItems: 'center',
    paddingLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'center',
  },
  tabContainer: {
    flex: 1,
    marginTop: 20,
  },
  title: {
    textAlign: 'left',
    fontWeight: '700',
    marginTop: '0px',
  },
  subtitle: {
    textAlign: 'left',
    marginTop: '0px',
    fontSize: '16px',
    lineHeight: '24px',
  },
  caption: {
    textAlign: 'left',
    fontWeight: 'normal',
    color: theme.palette.grey.dark,
    marginTop: '0px',
    lineHeight: '1',
    fontSize: '14px',
  },
  actionContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  tabsContainer: {
    flex: 1,
    marginTop: 20,
  },
});

const PageTitle = props => {
  const onArrowBackClick = () => {
    if (props.onBackClicked) {
      props.onBackClicked();
    } else {
      history.goBack();
    }
  };

  const {
    classes,
    caption,
    actions,
    backIcon,
    title,
    subtitle,
    tabs = [],
    onTabChange = () => {},
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {backIcon && (
          <div className={classes.backContainer}>
            <TextLinkPrimary
              style={{
                verticalAlign: 'sub',
                marginRight: '26px',
                height: 24,
                width: 24,
                color: '#5C6771',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={onArrowBackClick}
            >
              <ArrowBackIcon />
            </TextLinkPrimary>
          </div>
        )}
        <div className={classes.titleContainer}>
          {/* This inversion is done on purpose */}
          {subtitle === undefined ? (
            <>
              <Typography
                align="left"
                variant="title"
                className={classes.title}
              >
                {title}
              </Typography>
              {caption && (
                <Typography
                  align="left"
                  variant="title"
                  className={classes.subtitle}
                >
                  {caption ?? '-'}
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography align="left" className={classes.subtitle}>
                {title}
              </Typography>
              <Typography
                align="left"
                variant="title"
                className={classes.title}
              >
                {subtitle ?? '-'}
              </Typography>
            </>
          )}
        </div>
        <div className={classes.actionContainer}>
          {actions != null ? actions : null}
        </div>
      </div>
      <div className={classes.tabsContainer}>
        {tabs && tabs.length > 0 && (
          <TabBar tabs={tabs} onTabChanged={onTabChange} />
        )}
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.string,
  caption: PropTypes.string,
  actions: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.string),
  onTabChange: PropTypes.func,
};

export default withRouter(withStyles(styles)(PageTitle));
