/* eslint-disable react/jsx-handler-names */
import React, { FC } from 'react';
import { StyleRules } from '@material-ui/core/styles';
import { StyledComponentProps } from '@material-ui/core/es';
import { Button, withStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

const styles = (theme: any): StyleRules => ({
  navControls: {
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    alignSelf: 'stretch',
    justifySelf: 'end',
    marginTop: 'auto',
    padding: '24px 32px 48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

interface NavControlsProps extends StyledComponentProps {
  backProps: ButtonProps;
  nextProps: ButtonProps;
}

const NavControls: FC<NavControlsProps> = ({
  backProps,
  nextProps,
  classes,
}) => {
  const {
    text: backButtonText,
    onClick: onClickBack,
    disabled: isBackDisabled = false,
  } = backProps;

  const {
    text: nextButtonText,
    onClick: onClickNext,
    disabled: isNextDisabled = false,
  } = nextProps;

  return (
    <div className={classes!.navControls}>
      <Button
        variant="flat"
        color="primary"
        onClick={onClickBack}
        disabled={isBackDisabled}
      >
        <ChevronLeft />
        {backButtonText}
      </Button>
      <Button
        variant="raised"
        color="primary"
        onClick={onClickNext}
        disabled={isNextDisabled}
      >
        {nextButtonText}
      </Button>
    </div>
  );
};

const DecoratedNavControls = withStyles(styles)(NavControls);
export { DecoratedNavControls as NavControls };
