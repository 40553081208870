import React, { FC } from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { STATUS_LABEL_MAP } from '../../constants';
import cx from 'classnames';
import { WelfareInstanceStatusDTO } from '../../../../gen/api/welfare';

const styles = (theme: any) => ({
  container: {
    padding: '1px 6px',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
  },
  progress: {
    backgroundColor: 'rgba(166, 245, 242, 0.7);',
    color: '#2667C5',
  },
  warning: {
    backgroundColor: 'rgba(238, 206, 125, 0.7);',
    color: '#CD9912',
  },
  danger: { backgroundColor: 'rgba(255, 102, 125, 0.5);', color: '#D1344C' },
  done: {
    color: '#008255',
    backgroundColor: 'rgba(0, 130, 85, 0.2);',
  },
});
interface StatusSectionProps extends StyledComponentProps {
  status: WelfareInstanceStatusDTO;
}

const PROGRESS_STATUSES_ARR = [
  WelfareInstanceStatusDTO.Created,
  WelfareInstanceStatusDTO.Assigned,
  WelfareInstanceStatusDTO.ApprovedByEmployee,
  WelfareInstanceStatusDTO.ApprovedByPo,
  WelfareInstanceStatusDTO.ApprovedByAdministrative,
];

const DONE_STATUSES_ARR = [WelfareInstanceStatusDTO.Approved];

const WARN_STATUSES_ARR = [WelfareInstanceStatusDTO.RejectedByEmployee];
const DANGER_STATUS_ARR = [WelfareInstanceStatusDTO.Rejected];

const StatusSection: FC<StatusSectionProps> = ({ classes = {}, status }) => (
  <label
    className={cx({
      [classes.container as string]: true,
      [classes.progress as string]: PROGRESS_STATUSES_ARR.includes(status),
      [classes.done as string]: DONE_STATUSES_ARR.includes(status),
      [classes.warning as string]: WARN_STATUSES_ARR.includes(status),
      [classes.danger as string]: DANGER_STATUS_ARR.includes(status),
    })}
  >
    {STATUS_LABEL_MAP[status]}
  </label>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedStatusSection = withStyles(styles)(StatusSection);

export { DecoratedStatusSection as StatusSection };
