import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-flexbox-grid';
import PageContainer from '../common/PageContainer';
import EmptyListMessage from '../common/EmptyListMessage';
import PageTitle from '../common/PageTitle';
import Pagination from '../common/Pagination';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ListBox from '../common/ListBox';
import ROUTES from '../../constants/routes';
import Loader from '../common/Loader';
import ApiUtenti from '../../api/Utenti';
import store from '../../store';
import { DEPARTMENTS, ROLES } from '../../constants/security';
import UserStatusBadge from './UserStatusBadge';
import { getDepartmentLabel } from '../../constants/common';

const styles = theme => ({
  bodyStrong: theme.typography.bodyStrong,
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
});

class ListaUtenti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      utenti: [],
      pagination: null,
    };
  }

  componentDidMount() {
    this.loadList({ page: 1 }, true);
  }

  loadList = (pageNumber = { page: 1 }, loading = true) => {
    this.setState({ loading: loading }, () => {
      ApiUtenti.loadList(
        {
          ...pageNumber,
          department: !store.app.profile.isMemberOf(
            [DEPARTMENTS.ADMIN, DEPARTMENTS.CED],
            true
          )
            ? store.app.profile.operatingAs.department
            : null,
        },
        response => {
          this.setState({
            loading: false,
            utenti: response.users,
            pagination: response.meta.pagination,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    });
  };

  render() {
    const { classes } = this.props;
    const { pagination } = this.state;
    const profile = store.app.profile;

    const isUserManager = profile.isMemberOf([
      DEPARTMENTS.ADMIN,
      DEPARTMENTS.CED,
    ]);
    const title = isUserManager ? 'Utenti del comune' : 'Utenti';

    return (
      <PageContainer
        whiteBg
        header={
          <PageTitle
            title={title}
            subtitle={
              !isUserManager
                ? getDepartmentLabel(profile.operatingAs.department)
                : undefined
            }
            actions={
              (isUserManager || profile.hasRole([ROLES.MANAGER, ROLES.PO])) && (
                <TitleActions />
              )
            }
          />
        }
      >
        <Loader size={40} loading={this.state.loading} />
        {!this.state.loading &&
          this.state.utenti.map(user => {
            return (
              <ListBox key={user.id} goTo={`/${ROUTES.UTENTI}/${user.id}`}>
                <Row className={classes.centered}>
                  <Col xs={12} sm={1} md={1}>
                    <UserStatusBadge state={user.status} />
                  </Col>
                  <Col xs={12} sm={5} md={5}>
                    <div className={classes.bodyStrong}>
                      {user.first_name} {user.last_name}
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6}>
                    {/* TODO: handle new department structure */}
                  </Col>
                </Row>
              </ListBox>
            );
          })}
        <EmptyListMessage
          visible={this.state.utenti.length === 0 && !this.state.loading}
        />
        {pagination && (
          <Row>
            <Col xs={12} sm={12}>
              <Pagination
                total={pagination.total_pages}
                current={pagination.current_page}
                display={3}
                onChange={this.loadList}
                totalElement={pagination.total}
                elementNumber={pagination.count}
              />
            </Col>
          </Row>
        )}
      </PageContainer>
    );
  }
}

export default withStyles(styles)(observer(ListaUtenti));

const TitleActions = () => {
  return (
    <span>
      <Button
        size="small"
        variant="raised"
        color="primary"
        component={props => <Link to={`/${ROUTES.NUOVO_UTENTE}`} {...props} />}
      >
        Crea Nuovo Utente
      </Button>
    </span>
  );
};
