import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Loader from '../../common/Loader';
import EmptyListMessage from '../../common/EmptyListMessage';
import ListItem from './ListItem';
import Pagination from '../../common/Pagination';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../../constants/routes';

const List = withRouter(props => {
  const { instances, pagination, onPageChange, loading, history } = props;

  const onProcedureSelected = useCallback(
    instance => {
      history.push(`/${ROUTES.CONDIVISIONE}/${instance.id}`);
    },
    [history]
  );

  return (
    <Row>
      <Col xs={12}>
        <Loader size={40} loading={loading} />
        <EmptyListMessage visible={instances.length === 0 && !loading} />
        {instances.length > 0 &&
          !loading &&
          instances.map(instance => (
            <ListItem
              key={instance.id}
              instance={instance}
              onDetailsClick={onProcedureSelected}
            />
          ))}
        {pagination && (
          <Row>
            <Col xs={12} sm={12}>
              <Pagination
                total={pagination.total_pages}
                current={pagination.current_page}
                display={3}
                onChange={onPageChange}
                totalElement={pagination.total}
                elementNumber={pagination.count}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
});

List.propTypes = {
  instances: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default List;
