import React from 'react';
import Pratiche from '../../api/PraticheGate';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import Loader from '../common/Loader';
import { observer } from 'mobx-react';
import Subsection from '../common/Subsection';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import store from '../../store';
import { CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import EditableField from '../common/EditableField';
import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import { Delete as DeleteIcon } from '@material-ui/icons';
import ConfirmButton from '../common/ConfirmButton';
import ROUTES from '../../constants/routes';
import Procedure from '../common/Procedure';
import {
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_HUMAN,
} from '../common/filters/constants';

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  fieldLabel: {
    display: 'block',
    textTransform: 'none',
    color: theme.palette.black,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  fieldValue: {
    padding: '0 20px',
  },
  detailTitle: {
    textAlign: 'left',
    fontWeight: '600',
    color: theme.palette.black,
    marginTop: '0px',
    fontSize: '16px',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  centeredContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  containerNoPadding: {
    padding: '0 !important',
  },
  detailList: {
    margin: 0,
    listStyle: 'none',
    padding: '0',
  },
  descriptionIcon: {
    color: theme.palette.secondary.main,
  },
  detailElementContainer: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  detailElementIconContainer: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    marginRight: '15px',
  },
  detailElementDataContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailElementLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  detailElementSeparator: {
    borderBottom: '1px solid #eee',
  },
  personContainer: {
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid #eee',
    padding: '15px',
    marginRight: '5px',
    marginBottom: '5px',
    minWidth: '250px',
  },
  personIcon: {
    color: theme.palette.secondary.main,
    fontSize: '24px',
    height: '100%',
    marginRight: '15px',
    textAlign: 'center',
  },
  personDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  personDetailsHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  personDetailsName: {
    fontSize: '16px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: '2px',
  },
  personDetailsType: {
    fontSize: '12px',
    color: '#555',
    fontWeight: '400',
  },
  personDetailBody: {
    listStyle: 'none',
    padding: '0',
  },
  personDetailBodyItem: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  personDetailsBodyIcon: {
    fontSize: '20px',
    color: theme.palette.secondary.main,
    marginRight: '10px',
  },
  personDetailsBodyValue: {
    height: '100%',
    lineHeight: '100%',
  },
  expertsList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  documentList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  documentContainer: {
    display: 'flex',
    borderRadius: '20px',
    border: '1px solid #eee',
    padding: '10px 15px',
    marginRight: '5px',
    marginBottom: '5px',
    maxWidth: '250px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    wordWrap: 'break-word',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
  documentContainerDeletable: {
    minWidth: '150px',
    borderRadius: '20px 0 0 20px!important',
    borderRight: 'none!important',
    marginRight: '0',
  },
  documentIcon: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#555',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px',
    fontSize: '20px',
  },
  documentAction: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#555',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    padding: '18px 15px',
    border: '1px solid #eee',
    borderRadius: '0 20px 20px 0',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
  documentExtension: {
    marginTop: '2px',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  documentName: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  documentOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#6d6d6d',
    height: '100%',
    width: '100%',
    opacity: '.5',
    top: 0,
    left: 0,
    border: 'none',
    margin: '0',
    borderRadius: 'inherit',
    position: 'absolute',
    padding: 0,
    transition: 'opacity .5s',
    cursor: 'not-allowed',
  },
  documentDate: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
    bottom: '2px',
    right: '15px',
    color: '#555',
  },
  sharingRow: {
    width: '100%',
    margin: 0,
    padding: '20px',
    minHeight: '56px',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  shareDocumentsActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  sharingActions: {
    paddingTop: '10px',
  },
});

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
}))(Tooltip);

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inputKey: Date.now(),
      uploadingDocument: false,
      deletingDocuments: [],
      loadingDocuments: false,
      loadingEvents: false,
      updatingNotes: false,
      sharedProcedure: null,
      documents: [],
      uploadedDocuments: [],
      events: [],
      notes: null,
      paginationEvents: {},
      downloadingDocument: null,
    };
  }

  componentDidMount() {
    this.loadProcedure(this.props.match.params.shareId);
  }

  componentWillReceiveProps(nextProps, _nextContext) {
    if (nextProps.match.params.shareId !== this.props.match.params.shareId) {
      this.loadProcedure(nextProps.match.params.shareId);
    }
  }

  loadProcedure = shareId => {
    this.setState(
      {
        loading: true,
      },
      () => {
        Pratiche.getShared(
          shareId,
          sharedProcedure => {
            this.setState({
              loading: false,
              sharedProcedure,
              notes: sharedProcedure.receiver_notes,
            });
            this.loadDocuments(shareId);
          },
          () => {
            this.setState({
              loading: false,
            });
          }
        );
      }
    );
  };

  loadDocuments = shareId => {
    this.setState(
      {
        loadingDocuments: true,
      },
      () => {
        Pratiche.getSharedDocuments(
          shareId,
          documents => {
            const parsedDocuments = this.parseDocuments(documents);
            this.setState({
              loadingDocuments: false,
              ...parsedDocuments,
            });
          },
          () => {
            this.setState({
              loadingDocuments: false,
              documents: [],
              uploadedDocuments: [],
            });
          }
        );
      }
    );
  };

  parseDocuments = documents => {
    const userId = store.app.profile.user.id;
    return {
      documents: documents.filter(d => d.uploaded_by.id !== userId),
      uploadedDocuments: documents.filter(d => d.uploaded_by.id === userId),
    };
  };

  onDocumentClick = documentId => {
    this.setState(
      {
        downloadingDocument: documentId,
      },
      () => {
        Pratiche.downloadSharedDocument(
          this.state.sharedProcedure.id,
          documentId,
          () => {
            this.setState({
              downloadingDocument: null,
            });
          },
          () => {
            this.setState({
              downloadingDocument: null,
            });
            store.app.snackBar.open('Errore nel download del file.');
          }
        );
      }
    );
  };

  renderNote = (field, notes) => {
    return notes ? notes : 'Nessuna nota.';
  };

  onNoteChange = (field, notes) => {
    this.setState(
      {
        updatingNotes: true,
      },
      () => this.updateNotes(notes)
    );
  };

  onUploadClicked = instance => {
    instance._fileInput.click();
  };

  updateNotes = notes => {
    Pratiche.updateNotes(
      this.state.sharedProcedure.id,
      notes,
      sharedProcedure => {
        this.setState({
          sharedProcedure,
          notes: sharedProcedure.receiver_notes,
          updatingNotes: false,
        });
        store.app.snackBar.open('Note aggiornate con successo.');
      },
      () => {
        this.setState({
          updatingNotes: false,
        });
        store.app.snackBar.open(
          "Errore nell'aggiornamento della nota, riprova in seguito."
        );
      }
    );
  };

  uploadDocument = event => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      store.app.snackBar.open(
        `Il file selezionato è troppo grande (Max ${MAX_FILE_SIZE_HUMAN} MB)`
      );
      return;
    }
    this.setState(
      {
        inputKey: Date.now(),
        uploadingDocument: true,
      },
      () =>
        Pratiche.uploadSharedDocument(
          this.state.sharedProcedure.id,
          file,
          documents => {
            this.setState({
              inputKey: Date.now(),
              uploadingDocument: false,
              ...this.parseDocuments(documents),
            });
          },
          () => {
            this.setState({
              uploadingDocument: false,
            });
            store.app.snackBar.open(
              "Errore nell'upload del file, riprova in seguito."
            );
          }
        )
    );
  };

  onDocumentDelete = documentId => {
    const deletingDocuments = this.state.deletingDocuments;
    deletingDocuments.push(documentId);
    this.setState(
      {
        deletingDocuments,
      },
      () => {
        Pratiche.deleteSharedDocument(
          this.state.sharedProcedure.id,
          documentId,
          documents => {
            const parsedDocuments = this.parseDocuments(documents);
            this.setState({
              deletingDocuments: this.state.deletingDocuments.filter(
                id => id !== documentId
              ),
              ...parsedDocuments,
            });
          },
          () => {
            this.setState({
              deletingDocuments: this.state.deletingDocuments.filter(
                id => id !== documentId
              ),
            });
            store.app.snackBar.open(
              "Errore nell'eliminazione del file, riprova in seguito."
            );
          }
        );
      }
    );
  };

  resolveProcedure = callback => {
    Pratiche.resolveSharedProcedure(
      this.state.sharedProcedure.id,
      () => {
        store.app.snackBar.open('Operazione completata con successo');
        callback();
        this.props.history.replace(`/${ROUTES.CONDIVISIONE}`);
      },
      () => {
        store.app.snackBar.open(
          'Si è verificato un errore imprevisto, riprova in seguito.'
        );
        callback();
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      sharedProcedure,
      loading,
      loadingDocuments,
      documents,
      downloadingDocument,
      deletingDocuments,
    } = this.state;
    const procedure = sharedProcedure ? sharedProcedure.procedure : null;
    const instance = this;

    return (
      <PageContainer
        header={
          <PageTitle
            title="Dettaglio Pratica Condivisa"
            subtitle={
              procedure ? <span>{`Pratica n° ${procedure.number}`}</span> : null
            }
            backIcon
          />
        }
      >
        <Loader size={40} loading={loading} />
        {procedure && !loading && (
          <>
            <div
              className={classes.container}
              style={{
                marginBottom: '20px',
              }}
            >
              <div className={classes.header}>
                <i
                  className={classNames(
                    classes.headerIcon,
                    'nc-icon nc-vector'
                  )}
                />
                <span className={classes.headerTitle}>Condivisione</span>
              </div>
              <Subsection>
                <Row className={classes.sharingRow}>
                  <Col xs={12}>
                    <span className={classes.fieldLabel}>Condivisa Da</span>
                  </Col>
                  <Col xs={12} className={classes.fieldValue}>
                    {sharedProcedure.shared_by.full_name}
                  </Col>
                </Row>
                <Row className={classes.sharingRow}>
                  <Col xs={12}>
                    <span className={classes.fieldLabel}>
                      Nota dall&apos;utente
                    </span>
                  </Col>
                  <Col xs={12} className={classes.fieldValue}>
                    {sharedProcedure.sender_notes
                      ? sharedProcedure.sender_notes
                      : 'Nessuna nota condivisa.'}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <EditableField
                      name="notes"
                      label="Note del tuo dipartimento"
                      value={this.state.notes}
                      onValueChange={this.onNoteChange}
                      renderValue={this.renderNote}
                      disabled={this.state.updatingNotes}
                      labelProps={{
                        className: classes.fieldLabel,
                      }}
                      inputProps={{
                        multiline: true,
                        inputProps: {
                          maxLength: 500,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row className={classes.sharingRow}>
                  <Col xs={10}>
                    <Col xs={12}>
                      <span className={classes.fieldLabel}>
                        Documenti condivisi da te
                      </span>
                    </Col>
                    <Col xs={12} className={classes.fieldValue}>
                      {this.state.uploadedDocuments.length === 0 ? (
                        !loadingDocuments ? (
                          'Nessun documento condiviso.'
                        ) : (
                          'Caricamento in corso...'
                        )
                      ) : (
                        <div className={classes.documentList}>
                          {this.state.uploadedDocuments.map(document => {
                            return (
                              <DocumentCard
                                key={document.id}
                                document={document}
                                onClick={this.onDocumentClick}
                                isDownloading={
                                  downloadingDocument === document.id
                                }
                                onDelete={this.onDocumentDelete}
                                isDeleting={
                                  deletingDocuments.indexOf(document.id) !== -1
                                }
                                deletable
                              />
                            );
                          })}
                        </div>
                      )}
                    </Col>
                  </Col>
                  <Col xs={2} className={classes.shareDocumentsActions}>
                    <input
                      multiple={false}
                      onChange={this.uploadDocument}
                      name="file"
                      ref={node => (instance._fileInput = node)}
                      key={this.state.inputKey}
                      type="file"
                      hidden
                    />
                    <BiggerTooltip
                      title="Carica Documento"
                      className={classes.tooltip}
                    >
                      <Button
                        size="small"
                        variant="raised"
                        color="primary"
                        disabled={this.state.uploadingDocument}
                        className={classes.smallActionButton}
                        onClick={() => this.onUploadClicked(instance)}
                      >
                        {!this.state.uploadingDocument ? (
                          <UploadIcon
                            className={classes.smallActionButtonIcon}
                          />
                        ) : (
                          <CircularProgress size={16} />
                        )}
                      </Button>
                    </BiggerTooltip>
                  </Col>
                </Row>
                <Row className={classes.sharingActions}>
                  <Col
                    xs={12}
                    style={{ justifyContent: 'flex-end', display: 'flex' }}
                  >
                    <ConfirmButton
                      confirmMessage="Risolvi condivisione"
                      text="Risolvendo la condivisione non potrai più modificare la pratica condivisa. Procedere?"
                      withLoading
                      buttonProps={{
                        color: 'primary',
                        variant: 'raised',
                      }}
                      onConfirm={this.resolveProcedure}
                      buttonLabel="Risolvi"
                    />
                  </Col>
                </Row>
              </Subsection>
            </div>

            <Typography
              className={classes.detailTitle}
              align="left"
              gutterBottom
            >
              Dettagli della Pratica
            </Typography>

            <Procedure
              procedure={procedure}
              loadingDocuments={loadingDocuments}
              documents={documents}
              onDocumentClick={this.onDocumentClick}
              downloadingDocument={downloadingDocument}
              isOwner={false}
            />
          </>
        )}
      </PageContainer>
    );
  }
}

const DetailElement = withStyles(styles)(props => {
  const { classes, icon, label, value, separator } = props;

  return (
    <li
      className={classNames({
        [classes.detailElementSeparator]: separator,
      })}
    >
      <div className={classes.detailElementContainer}>
        {icon && (
          <div className={classes.detailElementIconContainer}>
            <i className={classNames(classes.detailElementIcon, icon)} />
          </div>
        )}
        <div className={classes.detailElementDataContainer}>
          <span className={classes.detailElementLabel}>{label}</span>
          {value ? value : 'N/A'}
        </div>
      </div>
    </li>
  );
});

const PersonCard = withStyles(styles)(props => {
  const { classes, person, isExpert } = props;

  return (
    <div className={classes.personContainer}>
      <div className={classes.personIcon}>
        {isExpert ? (
          <i className="nc-icon nc-badge" />
        ) : person.gender === 'G' ? (
          <i className="nc-icon nc-bag" />
        ) : (
          <i className="nc-icon nc-single-02" />
        )}
      </div>
      <div className={classes.personDetailsContainer}>
        <div className={classes.personDetailsHeader}>
          <span className={classes.personDetailsName}>{person.full_name}</span>
          <span className={classes.personDetailsType}>
            {person.gender === 'G' ? 'Persona Giuridica' : 'Persona Fisica'}
          </span>
        </div>
        <div>
          <ul className={classes.personDetailBody}>
            <li className={classes.personDetailBodyItem}>
              <i
                className={classNames(
                  classes.personDetailsBodyIcon,
                  'nc-icon',
                  'nc-email-85'
                )}
              />
              <span className={classes.personDetailsBodyValue}>
                {person.email ? (
                  <a href={'mailto:' + person.email}>{person.email}</a>
                ) : (
                  'N/A'
                )}
              </span>
            </li>
            <li className={classes.personDetailBodyItem}>
              <i
                className={classNames(
                  classes.personDetailsBodyIcon,
                  'nc-icon',
                  'nc-pin-3'
                )}
              />
              <span className={classes.personDetailsBodyValue}>
                {person.address ? person.address : 'N/A'}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

const DocumentCard = withStyles(styles)(props => {
  const { classes, document, isDownloading, deletable, isDeleting } = props;

  const onClick = () => {
    if (props.onClick && !isDownloading && !isDeleting) {
      props.onClick(document.id);
    }
  };

  const onDelete = () => {
    if (props.onDelete && !isDownloading && !isDeleting) {
      props.onDelete(document.id);
    }
  };

  return (
    <>
      <div
        className={classNames(classes.documentContainer, {
          [classes.documentContainerDeletable]: deletable,
        })}
        onClick={onClick}
      >
        <div className={classes.documentIcon}>
          <i className="nc-icon nc-single-copy-04" />
          <span className={classes.documentExtension}>
            {document.extension}
          </span>
        </div>
        <div className={classes.documentName}>{document.name}</div>
        <div className={classes.documentDate}>{document.date}</div>
        {isDownloading && (
          <div className={classes.documentOverlay}>
            <CircularProgress
              size={30}
              style={{ textAlign: 'center', color: '#fff' }}
            />
          </div>
        )}
      </div>
      {deletable && (
        <div className={classes.documentAction} onClick={onDelete}>
          {!isDeleting ? (
            <DeleteIcon color="disabled" fontSize={20} />
          ) : (
            <CircularProgress size={20} color="secondary" />
          )}
        </div>
      )}
    </>
  );
});

DetailElement.defaultProps = {
  icon: null,
  separator: false,
};

DetailElement.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  separator: PropTypes.bool,
};

PersonCard.propTypes = {
  classes: PropTypes.object,
  person: PropTypes.object.isRequired,
  isExpert: PropTypes.bool,
};

PersonCard.defaultProps = {
  isExpert: false,
};

DocumentCard.propTypes = {
  classes: PropTypes.object,
  document: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isDownloading: PropTypes.bool,
  deletable: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onDelete: PropTypes.func,
};

DocumentCard.defaultProps = {
  deletable: false,
  isDeleting: false,
  onDelete: () => {},
};

export default withStyles(styles)(observer(withRouter(Detail)));
