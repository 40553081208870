import React, { FC } from 'react';
import { Citizen } from '../../../api/model';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import moment from 'moment';
import { StyleRules } from '@material-ui/core/styles';

const styles = (theme: any): StyleRules => ({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    background: theme.palette.blue.light,
    padding: '16px',
  },
  dataLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '32px',
    margin: '16px 0px',
  },
  fieldLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '8px',
  },
  field: {
    whiteSpace: 'nowrap',
    margin: '4px 0',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    margin: '4px 0',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '150%',
    marginBottom: '16px',
  },
});

interface CitizenCardProps extends StyledComponentProps {
  data: Citizen;
}

const emptyDataDefault = 'N/A';

const _CitizenCard: FC<CitizenCardProps> = ({ data, classes = {} }) => {
  const fullName = `${data.firstName} ${data.lastName}`;
  const birthDate = `${moment(data.birthday).format('DD/MM/YYYY')}`;
  const address =
    data.address.streetName && data.address.postcode && data.address.city
      ? `${data.address.streetName}, ${data.address.postcode} ${data.address.city}`
      : emptyDataDefault;
  const phone = `${data.phoneNumber ?? emptyDataDefault}`;
  const fiscalCode = `${data.fiscalCode}`;

  return (
    <div className={classes.container}>
      <label className={classes.title}>{fullName}</label>
      <div className={classes.dataLayout}>
        <div className={classes.fieldLayout}>
          <p className={classes.label}>Data di nascita:</p>
          <p className={classes.field}>{birthDate}</p>
        </div>
        <div className={classes.fieldLayout}>
          <p className={classes.label}>Residenza:</p>
          <p className={classes.field}>{address}</p>
        </div>
      </div>
      <div className={classes.dataLayout}>
        <div className={classes.fieldLayout}>
          <p className={classes.label}>Numero di telefono:</p>
          <p className={classes.field}>{phone}</p>
        </div>
        <div className={classes.fieldLayout}>
          <p className={classes.label}>Codice fiscale:</p>
          <p className={classes.field}>{fiscalCode}</p>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const CitizenCard = withStyles(styles)(_CitizenCard);
