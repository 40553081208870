import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import ROUTES from '../../constants/routes';

class ProcedureSkipped extends React.Component {
  onProcedureClicked = () => {
    this.props.history.push(
      `${ROUTES.ARCHIVIO_GATE}/${this.props.event.payload.procedure.id}`
    );
  };

  onUserClick = () => {
    this.props.history.push(
      `${ROUTES.UTENTI}/${this.props.event.payload.performed_by.id}`
    );
  };

  render() {
    const { event } = this.props;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onProcedureClicked}>
              Pratica n° {event.payload.procedure.full_number}
            </TextLinkPrimary>
            <br />
            <b>Eseguito Da: </b>&nbsp;
            <TextLinkPrimary onClick={this.onUserClick}>
              {event.payload.performed_by.full_name}{' '}
            </TextLinkPrimary>
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(ProcedureSkipped));
