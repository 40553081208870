import React, { useCallback, useMemo, useState } from 'react';
import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import Loader from '../../common/Loader';
import SubjectStep from './Steps/InsertSubjectStep';
import PraticheInterne from '../../../api/PraticheInterne';
import DocumentiTemporanei from '../../../api/DocumentiTemporanei';
import SelectDeparmentsStep from '../Share/Steps/SelectDeparmentsStep';
import { DEPARTMENTS } from '../../../constants/security';
import SelectDocument from './Steps/SelectDocument';
import SignCredentials from './Steps/SignCredentials';
import OperationInProgress from './Steps/OperationInProgress';
import FirmaDigitale from '../../../api/FirmaDigitale';
import store from '../../../store';

const STEP_INSERT_SUBJECT = 'INSERT_SUBJECT';
const STEP_SELECT_DOCUMENT = 'SELECT_DOCUMENT';
const STEP_SIGN_CREDENTIALS = 'SIGN_CREDENTIALS';
const STEP_SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS';
const STEP_SHARE = 'SHARE';
const STEP_CREATION_IN_PROGRESS = 'CREATION_IN_PROGRESS';
const STEPS = [
  STEP_INSERT_SUBJECT,
  STEP_SELECT_DOCUMENT,
  STEP_SIGN_CREDENTIALS,
  STEP_SIGNING_IN_PROGRESS,
  STEP_SHARE,
  STEP_CREATION_IN_PROGRESS,
];

const styles = theme => ({
  container: {
    minWidth: '600px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
});

const Dialog = props => {
  const [step, setStep] = useState(0);
  const [subject, setSubject] = useState('');
  const [document, setDocument] = useState(undefined);
  const [documentId, setDocumentId] = useState(undefined);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({
    username: null,
    password: null,
    otp: null,
  });
  const [departments, setDepartments] = useState([]);

  const { classes, open, onClose } = props;
  const { snackBar } = store.app;

  const currStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  const onNext = useCallback(() => {
    setStep(step => step + 1);
  }, []);

  const onBack = useCallback(() => {
    setStep(step => step - 1);
  }, []);

  const close = useCallback(
    (shouldUpdate = false) => {
      setStep(0);
      setSubject('');
      setDocument(undefined);
      setDocumentId(undefined);
      setDepartments([]);
      setUsername('');
      setPassword('');
      setOtp('');
      setErrors({
        username: null,
        password: null,
        otp: null,
      });
      onClose(shouldUpdate);
    },
    [onClose]
  );

  const onUsernameChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, username: null }));
    setUsername(value);
  }, []);

  const onPasswordChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, password: null }));
    setPassword(value);
  }, []);

  const onOtpChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, otp: null }));
    setOtp(value);
  }, []);

  const onSave = useCallback(() => {
    onNext();
    const documentName = document.name.endsWith('.p7m')
      ? document.name
      : document.name + '.p7m';
    PraticheInterne.create(
      {
        subject,
        department: store.app.profile.isMemberOf(DEPARTMENTS.ADMIN, true)
          ? DEPARTMENTS.ADMIN
          : store.app.profile.operatingAs.department,
        departments,
        document: {
          id: documentId,
          name: documentName,
        },
      },
      () => {
        close(true);
        store.app.snackBar.open('Atto creato con successo');
      },
      () => {
        onBack();
        store.app.snackBar.open(
          'Si è verificato un errore imprevisto. Riprova fra qualche istante'
        );
      }
    );
  }, [onNext, subject, departments, documentId, document, close, onBack]);

  const onCancel = useCallback(() => {
    if (documentId) {
      DocumentiTemporanei.delete(documentId);
    }
    close();
  }, [close, documentId]);

  const onDocumentDeleted = useCallback(() => {
    setDocument(undefined);
  }, []);

  const onDocumentSelected = useCallback(d => {
    setDocument(d);
  }, []);

  const addDepartment = useCallback(
    department => {
      const newDept = !departments.includes(department) ? [department] : [];
      setDepartments([...departments, ...newDept]);
    },
    [departments]
  );

  const removeDepartment = useCallback(
    department => {
      const index = departments.findIndex(d => d === department);
      if (index > -1) {
        const newDepartments = [...departments];
        newDepartments.splice(index, 1);
        setDepartments(newDepartments);
      }
    },
    [departments]
  );

  const storeFile = useCallback(
    file => {
      DocumentiTemporanei.upload(
        file,
        data => {
          setDocumentId(data.id);
        },
        () => {
          snackBar.open(
            'Si è verificato un errore imprevisto durante il caricamento del file.'
          );
          close();
        }
      );
    },
    [close, snackBar]
  );

  const signDocument = useCallback(() => {
    onNext();
    FirmaDigitale.sign(
      {
        username,
        password,
        otp,
      },
      document,
      file => {
        onNext();
        storeFile(file);
      },
      err => {
        let message = 'Errore durante la firma del documento';
        if (err.status === 400) {
          message = 'Credenziali errate';
        }
        snackBar.open(message);
        onBack();
      }
    );
  }, [document, onBack, onNext, otp, password, snackBar, storeFile, username]);

  const validCredentials = useMemo(() => {
    return Boolean(
      username && password && otp && !Object.values(errors).some(v => v)
    );
  }, [errors, username, password, otp]);

  const onCredentialsInserted = useCallback(() => {
    const newErrors = {};
    if (!username) newErrors.username = 'Questo campo è obbligatorio';
    if (!password) newErrors.password = 'Questo campo è obbligatorio';
    if (!otp) newErrors.otp = 'Questo campo è obbligatorio';
    if (Object.values(newErrors).some(v => Boolean(v))) {
      setErrors({ ...errors, newErrors });
    } else {
      signDocument();
    }
  }, [signDocument, errors, username, password, otp]);

  return (
    <MaterialDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {}}
      aria-labelledby="internal-procedure-dialog-title"
      aria-describedby="internal-procedure-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle
          className={classes.dialogTitle}
          id="internal-procedure-dialog-title"
        >
          <span>
            {currStep === STEP_CREATION_IN_PROGRESS
              ? 'Creazione in corso...'
              : 'Nuovo atto dirigenziale'}
          </span>
          <span className={classes.closeIcon} onClick={onCancel}>
            &times;
          </span>
        </DialogTitle>
        <DialogContent>
          {currStep === STEP_INSERT_SUBJECT && (
            <SubjectStep subject={subject} onChange={setSubject} />
          )}
          {currStep === STEP_SELECT_DOCUMENT && (
            <SelectDocument
              onDocumentSelected={onDocumentSelected}
              onDocumentDeleted={onDocumentDeleted}
              selectedDocument={document}
            />
          )}
          {currStep === STEP_SIGN_CREDENTIALS && (
            <SignCredentials
              username={username}
              password={password}
              otp={otp}
              errors={errors}
              onUsernameChange={onUsernameChanged}
              onPasswordChange={onPasswordChanged}
              onOtpChange={onOtpChanged}
            />
          )}
          {currStep === STEP_SIGNING_IN_PROGRESS && (
            <OperationInProgress message="Firma del documento in corso" />
          )}
          {currStep === STEP_SHARE && (
            <SelectDeparmentsStep
              departments={departments}
              message="Seleziona i dipartimenti con in quali vuoi condividere l'atto (Opzionale)"
              onDepartmentSelected={addDepartment}
              onDepartmentRemoved={removeDepartment}
            />
          )}
          {currStep === STEP_CREATION_IN_PROGRESS && (
            <Loader loading={true} color="primary" />
          )}
        </DialogContent>
        <DialogActions>
          {currStep === STEP_INSERT_SUBJECT && (
            <>
              <Button onClick={onCancel} className={classes.marginRightButton}>
                Annulla
              </Button>
              <Button
                onClick={onNext}
                variant="raised"
                color="primary"
                disabled={!subject.length}
              >
                Prosegui
              </Button>
            </>
          )}
          {currStep === STEP_SELECT_DOCUMENT && (
            <>
              <Button onClick={onBack} className={classes.marginRightButton}>
                Indietro
              </Button>
              <Button
                onClick={onNext}
                variant="raised"
                color="primary"
                disabled={!document}
              >
                Avanti
              </Button>
            </>
          )}
          {currStep === STEP_SIGN_CREDENTIALS && (
            <>
              <Button onClick={onBack} className={classes.marginRightButton}>
                Indietro
              </Button>
              <Button
                onClick={onCredentialsInserted}
                variant="raised"
                color="primary"
                disabled={!validCredentials}
              >
                Firma
              </Button>
            </>
          )}
          {currStep === STEP_SHARE && (
            <>
              <Button onClick={onBack} className={classes.marginRightButton}>
                Indietro
              </Button>
              <Button onClick={onSave} variant="raised" color="primary">
                Salva
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Dialog);
