import _ from 'lodash';

export const DEPARTMENTS = {
  ANY: 'any',
  ADMIN: 'admin',
  CED: 'ced',
  BUILDING: 'edilizia',
  ENVIRONMENT: 'ambiente',
  SERVIZI_DEMOGRAFICI: 'anagrafe',
  STATO_CIVILE: 'stato_civile',
  SOCIAL_SERVICES: 'servizi_sociali',
  LOGISTIC: 'logistica',
};

export const VISIBLE_DEPARTMENTS = _.omit(DEPARTMENTS, 'ANY');

export const ROLES = {
  ANY: 'any',
  MANAGER: 'dirigente',
  PO: 'posizione_organizzativa',
  ADMINISTRATIVE: 'amministrativo',
  EMPLOYEE: 'dipendente',
};

export const ROLES_RANK = {
  [ROLES.MANAGER]: 0,
  [ROLES.PO]: 1,
  [ROLES.ADMINISTRATIVE]: 2,
  [ROLES.EMPLOYEE]: 3,
};

export const VISIBLE_ROLES = _.omit(ROLES, 'ANY');

export function departmentsExcept(departments) {
  if (!Array.isArray(departments)) departments = [departments];

  return Object.values(VISIBLE_DEPARTMENTS).filter(
    d => !departments.includes(d)
  );
}

export function getRoleRank(role) {
  return ROLES_RANK[role];
}

export function isHigherThan(role, other) {
  return other ? getRoleRank(role) < getRoleRank(other) : true;
}

export function isHigherOrEqualThan(role, other) {
  return other ? getRoleRank(role) <= getRoleRank(other) : true;
}
