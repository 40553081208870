import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const styles = theme => ({
  detailElementContainer: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  detailElementIconContainer: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    marginRight: '15px',
  },
  detailElementDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
  },
  detailElementLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  detailElementSeparator: {
    borderBottom: '1px solid #eee',
  },
});

const DetailElement = withStyles(styles)(props => {
  const { classes, icon, label, value, separator } = props;

  return (
    <li
      className={classNames({
        [classes.detailElementSeparator]: separator,
      })}
    >
      <div className={classes.detailElementContainer}>
        {icon && (
          <div className={classes.detailElementIconContainer}>
            <i className={classNames(classes.detailElementIcon, icon)} />
          </div>
        )}
        <div className={classes.detailElementDataContainer}>
          <span className={classes.detailElementLabel}>{label}</span>
          {value ? value : 'N/A'}
        </div>
      </div>
    </li>
  );
});

DetailElement.defaultProps = {
  icon: null,
  separator: false,
};

DetailElement.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  separator: PropTypes.bool,
};

export default DetailElement;
