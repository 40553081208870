import React from 'react';
import { withStyles } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ListItemLayout from './ListItemLayout';

const styles = theme => ({
  container: {
    backgroundColor: '#F5F7FF', //TODO: move to theme
    padding: '10px 15px',
    margin: '0',
    fontSize: '16px',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
  bodyStrong: { ...theme.typography.bodyStrong, fontSize: '16px' },
});

const ListHeader = props => {
  const { classes } = props;

  return (
    <Row className={classes.container}>
      <Col xs={11}>
        <ListItemLayout>
          <div
            className={cx(classes.centered, classes.bodyStrong)}
            style={{ marginLeft: '4px' }}
          >
            STAT0
          </div>
          <div className={cx(classes.bodyStrong, classes.centered)}>
            RICHIEDENTE
          </div>
          <div className={cx(classes.bodyStrong, classes.centered)}>
            TIPOLOGIA CERTIFICATO
          </div>
          <div className={cx(classes.bodyStrong, classes.centered)}>
            DATA RICHIESTA
          </div>
          <div className={cx(classes.bodyStrong, classes.centered)}>
            IN CARICO A
          </div>
        </ListItemLayout>
      </Col>
    </Row>
  );
};

ListHeader.propTypes = {
  request: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ListHeader.defaultProps = {
  onClick: () => {},
};

export default withStyles(styles)(ListHeader);
