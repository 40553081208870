import {
  PERIODI,
  getLabelPeriodo,
  SESSION_STATE,
  getLabelSessionState,
  EVENTI_LABEL,
  getLabelEvento,
  PROCEDURE_CATEGORIES,
  PROCEDURE_TYPES,
  getLabelProcedureType,
  BUILDING_PROCEDURE_CATEGORY,
  getLabelBuildingProcedureCategory,
  BUILDING_PROCEDURE_STATUS,
  getLabelBuildingProcedureState,
  SHARE_STATUS,
  getLabelShareState,
} from '../../../constants/common';
import moment from 'moment';
import { UPLOAD_MAX_SIZE } from '../../../config';

export const MAX_FILE_SIZE = UPLOAD_MAX_SIZE * 1024 * 1024;
export const MAX_FILE_SIZE_HUMAN = `${UPLOAD_MAX_SIZE} MB`;

export const FILTER_SIZE = {
  xs: 6,
  sm: 2,
  md: 2,
  lg: 2,
};

export const FILTER_TYPE = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  DATE: 'DATE',
  SELECT: 'SELECT',
};

export const FILTRI_EVENTI = (() => {
  const filtriEventi = [];

  for (const k in EVENTI_LABEL) {
    filtriEventi.push({ value: k, description: getLabelEvento(k) });
  }

  return filtriEventi;
})();

export const FILTRI_PERIODI = (() => {
  const filtriPeriodi = [];

  for (const k in PERIODI) {
    filtriPeriodi.push({ value: PERIODI[k], description: getLabelPeriodo(k) });
  }

  return filtriPeriodi;
})();

export const FILTRI_STATO_SESSIONE = (() => {
  const filtriStatiSessione = [];

  for (const k in SESSION_STATE) {
    filtriStatiSessione.push({
      value: SESSION_STATE[k],
      description: getLabelSessionState(k),
    });
  }

  return filtriStatiSessione;
})();

export const FILTRI_CATEGORIA_PRATICA = (() => {
  return PROCEDURE_CATEGORIES.map(function(category) {
    return { value: category, description: category };
  });
})();

export const FILTRI_CATEGORIA_PRATICA_EDILIZIA = (() => {
  const filtriCategoria = [];

  for (const k in BUILDING_PROCEDURE_CATEGORY) {
    filtriCategoria.push({
      value: BUILDING_PROCEDURE_CATEGORY[k],
      description: getLabelBuildingProcedureCategory(
        BUILDING_PROCEDURE_CATEGORY[k]
      ),
    });
  }

  return filtriCategoria;
})();

export const FILTRI_IN_CORSO_PRATICA_EDILIZIA = [
  {
    value: 'true',
    description: 'In Corso',
  },
  {
    value: 'false',
    description: 'Terminata',
  },
];

export const FILTRI_STATO_PRATICA_EDILIZIA = (() => {
  const filtriStato = [];

  for (const k in BUILDING_PROCEDURE_STATUS) {
    filtriStato.push({
      value: BUILDING_PROCEDURE_STATUS[k],
      description: getLabelBuildingProcedureState(BUILDING_PROCEDURE_STATUS[k]),
    });
  }

  return filtriStato;
})();

export const FILTRI_TIPO_PRATICA = (() => {
  const filtriTipoPratiche = [];

  for (const k in PROCEDURE_TYPES) {
    filtriTipoPratiche.push({
      value: PROCEDURE_TYPES[k],
      description: getLabelProcedureType(k),
    });
  }

  return filtriTipoPratiche;
})();

export const FILTRI_STATO_PRATICA_CONDIVISA = (() => {
  const filtriTipoPratiche = [];

  for (const k in SHARE_STATUS) {
    filtriTipoPratiche.push({
      value: SHARE_STATUS[k],
      description: getLabelShareState(SHARE_STATUS[k]),
    });
  }

  return filtriTipoPratiche;
})();

const getFilterHumanizedValue = (filters, value) => {
  const results = filters.filter(item => {
    return item.value === value;
  });

  if (results.length > 0) return results[0].description;

  return value;
};

const humanizeDate = value => {
  const date = moment.unix(value);
  return date.format('D/M/YYYY');
};

const filterKeys = {
  period: 'Periodo',
  page: 'Pagina',
  date_since: 'Dal',
  date_until: 'Al',
  direction: 'Direzione',
  type: 'Tipo',
  counterpart: 'Controparte',
  description: 'Descrizione',
  reserve_type: 'Accantonamento',
  sender: 'Mittente',
  receiver: 'Destinatario',
  id: 'ID',
  name: 'Nome',
  surname: 'Cognome',
  vat: 'P.IVA',
  fiscal_code: 'C.F.',
  phone_number: 'Telefono',
  email: 'Email',
  created_at_since: 'Creato dopo il',
  date: 'Data',
  phone: 'Telefono',
  state: 'Stato',
};

const filterMaps = {
  period: FILTRI_PERIODI.concat(FILTRI_PERIODI),
};

const dates = [
  'date_since',
  'date_until',
  'created_at_since',
  'created_at_until',
  'date',
];

export const humanizedFilters = (key, value) => {
  const humanizedKey = filterKeys.hasOwnProperty(key) ? filterKeys[key] : key;
  let humanizedValue = filterMaps.hasOwnProperty(key)
    ? getFilterHumanizedValue(filterMaps[key], value)
    : value;
  humanizedValue = dates.includes(key) ? humanizeDate(value) : humanizedValue;

  return { key: humanizedKey, value: humanizedValue };
};
