import React, { FC } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { TemporaryDocument } from '../api/model';
import { Clear } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import Icon from '../../../new/components/icons';
import cx from 'classnames';

const styles = (theme: any) => ({
  container: {
    borderBottom: '4px solid',
    borderBottomColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignSelf: 'stretch',
  },
  animate: {
    animation: 'expand 1s',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  documentIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '4px',
  },
});

export interface DocumentRowProps extends StyledComponentProps {
  document: TemporaryDocument;
  onDelete: (id: string) => () => void;
  disableAnimation?: boolean;
}

const DocumentRow: FC<DocumentRowProps> = ({
  document,
  classes = {},
  onDelete,
  disableAnimation,
}) => (
  <div
    className={cx({
      [classes.container as string]: true,
      [classes.animate as string]: !disableAnimation,
    })}
  >
    <label>
      <Icon className={classes.documentIcon} type="Document" />
      {document.name}
    </label>
    <Clear className={classes.deleteIcon} onClick={onDelete(document.fid)} />
  </div>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedDocumentRow = withStyles(styles)(DocumentRow);

export { DecoratedDocumentRow as DocumentRow };
