import { DocumentType, FamilyMember, ModuleADetail } from './model';
import moment from 'moment';
import { v4 } from 'uuid';
import {
  FamilyMemberDTO,
  FamilyMemberRelationshipDTO,
  FlagTypeDTO,
  WelfareRequestorTypeDTO,
} from '../../../gen/api/welfare';

export const fromApiToDocumentType = (apiDocument: string): DocumentType => {
  switch (apiDocument) {
    case 'id_card': {
      return DocumentType.idCard;
    }
    case 'driving_license': {
      return DocumentType.drivingLicense;
    }
    case 'passport': {
      return DocumentType.passport;
    }
    default: {
      return DocumentType.idCard;
    }
  }
};

export const fromApiToRequestorType = (
  apiValue: string
): WelfareRequestorTypeDTO => {
  const checkvalue = apiValue as WelfareRequestorTypeDTO;
  if (!Object.values(WelfareRequestorTypeDTO).includes(checkvalue)) {
    throw new Error('unknwon REQUESTOR_TYPE, please regenerate model');
  }
  return checkvalue;
};

export const getCitizenFromApi = (user: any) => {
  return {
    id: user.id,
    uuid: user.uuid,
    firstName: user.person.first_name,
    lastName: user.person.last_name,
    professionalTitle: user.person.professional_title,
    fiscalCode: user.person.fiscal_code,
    vat: user.person.vat,
    documentType: fromApiToDocumentType(user.person.document.type),
    documentNumber: user.person.document.number,
    birthday: moment(user.person.birth_date, 'DD/MM/YYYY').toDate(),
    gender: user.person.gender,
    birthPlaceCountry: user.person.birthplace?.isForeign ? '' : 'IT',
    birthPlaceCounty: user.person.birthplace?.county,
    birthPlace: user.person.birthplace?.city,
    birthPlaceCityName: user.person.birthplace?.city_name,
    address: {
      streetName: user.person.address.street_name,
      postcode: user.person.address.postcode,
      county: user.person.address.county,
      city: user.person.address.city,
    },
    email: user.person.email,
    phoneNumber: user.person.phone,
  };
};

export const fromApiToFamilyMemberRelationshipDTO = (
  apiValue: string
): FamilyMemberRelationshipDTO => {
  const checkvalue = apiValue as FamilyMemberRelationshipDTO;
  if (!Object.values(FamilyMemberRelationshipDTO).includes(checkvalue)) {
    throw new Error(
      'unknwon FamilyMemberRelationshipDTO, please regenerate models'
    );
  }
  return checkvalue;
};

export const fromApiToFamliyMembers = (
  apiValue: FamilyMemberDTO
): FamilyMember => ({
  id: v4(),
  ...apiValue,
});

export const fromApiToModuleADetails = (apiValue: any): ModuleADetail => ({
  benefits: apiValue.benefits ?? [],
  expenses: apiValue.undeductedExpenses ?? [],
  familyMembers: apiValue.familyMembers
    ? apiValue.familyMembers.map(fromApiToFamliyMembers)
    : [],
  relativeMembers: apiValue.relativeMembers
    ? apiValue.relativeMembers.map(fromApiToFamliyMembers)
    : [],
  flag:
    apiValue.flags?.map(
      ({ type, values }: { type: FlagTypeDTO; values: string[] }) => ({
        type,
        data: values,
      })
    ) ?? [],
  isee: apiValue.isee,
});
