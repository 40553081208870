import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Loader from '../../common/Loader';
import { Col, Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import {
  DialogActions,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core';
import { observer } from 'mobx-react';

const styles = theme => ({
  documentContainer: {
    margin: '10px 0px',
    padding: '10px 15px',
    border: 'solid 1px',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    borderRadius: '2px',
  },
  closeIcon: {
    textAlign: 'right',
    color: theme.palette.grey.main,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  documentContents: {
    fontSize: '12px',
  },
  formControl: {
    margin: '0px',
    minWidth: 130,
    width: '100%',
  },
});

class AdditionalDocumentsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currDocument: '',
    };
  }

  onDocumentRemove = index => {
    this.props.onAdditionalDocumentRemoved(index);
  };

  handleFieldChange = event => {
    this.setState({
      currDocument: event.target.value,
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && this.state.currDocument !== '') {
      this.props.onAdditionalDocumentAdded(this.state.currDocument);
      this.setState({
        currDocument: '',
      });
    }
  };

  sendRequest = () => {
    this.props.onConfirm();
  };

  onClose = () => {
    this.setState({
      currDocument: '',
    });
    this.props.onClose();
  };

  render() {
    const { open, classes, loading, documents } = this.props;

    return (
      <Dialog
        open={open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={this.onClose}
        aria-labelledby="request-documents-dialog-title"
        aria-describedby="request-documents-dialog-description"
      >
        <DialogTitle id="request-documents-dialog-title">
          Richiedi documenti aggiuntivi
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Row>
              <Col xs={12}>
                <DialogContentText>
                  Inserisci un documento nel campo di testo sottostanete e premi{' '}
                  <b>INVIO</b> per aggiungerlo alla lista. Quando hai finito
                  clicca su &quot;Invia Richiesta&quot; per inviare la richiesta
                  al cittadino.
                </DialogContentText>
              </Col>
              <Col xs={12} style={{ marginTop: '15px' }}>
                <Row>
                  {documents.map((document, index) => (
                    <DocumentItem
                      key={document}
                      document={document}
                      index={index}
                      onRemove={this.onDocumentRemove}
                    />
                  ))}
                </Row>
              </Col>
              <Col xs={12} style={{ marginTop: '15px' }}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="documentTitle" shrink={true}>
                    Descrizione documento
                  </InputLabel>
                  <Input
                    placeholder="es. Fotocopia fronte/retro del documento di identità"
                    onKeyPress={this.handleKeyPress}
                    id="documentTitle"
                    value={this.state.currDocument}
                    onChange={this.handleFieldChange}
                  />
                </FormControl>
              </Col>
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          {!loading && (
            <>
              {documents.length > 0 && (
                <Button onClick={this.sendRequest}>Invia Richiesta</Button>
              )}
              <Button onClick={this.onClose}>Annulla</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const DocumentItem = withStyles(styles)(props => {
  const { document, index, classes } = props;

  const onRemove = () => {
    props.onRemove(index);
  };

  return (
    <Col xs={12}>
      <div className={classes.documentContainer}>
        <Row middle="xs" className={classes.documentContents}>
          <Col xs={11} sm={11} md={11} lg={11}>
            {document}
          </Col>
          <Col
            xs={1}
            sm={1}
            md={1}
            lg={1}
            className={classes.closeIcon}
            onClick={onRemove}
          >
            <i className="fa fa-times" />
          </Col>
        </Row>
      </div>
    </Col>
  );
});

export default withStyles(styles)(observer(AdditionalDocumentsWidget));
