import React, { FC, useCallback } from 'react';
import { useEditProcedure } from '../../../../../stm/socialServices/editProcedure.stm';
import {
  userAskExit,
  userEditIsee,
} from '../../../../../stm/socialServices/editProcedure.events';
import { SelectIseeForm } from '../../../common/forms/SelectIseeForm';

export const EditIsee: FC = () => {
  const { state, dispatch } = useEditProcedure();

  const onConfirm = useCallback(
    isee => {
      dispatch(userEditIsee(isee));
    },
    [dispatch]
  );
  const goBack = useCallback(() => {
    dispatch(userAskExit());
  }, [dispatch]);

  return (
    <SelectIseeForm
      initialIsee={state?.context?.procedure?.details?.isee ?? []}
      onConfirm={onConfirm}
      onAbort={goBack}
      abortText="Annulla"
      confirmText="Conferma i dati"
      skipText="Nessun isee specificato"
      skipAction={onConfirm}
    />
  );
};
