import React, { useCallback, useState } from 'react';
import ShareButton from './ShareButton';
import Dialog from './Dialog';
import PropTypes from 'prop-types';

const Widget = props => {
  const { onShared, buttonProps, instanceId } = props;
  const [dialogOpened, setDialogOpened] = useState(false);
  const onShareClicked = useCallback(() => {
    setDialogOpened(true);
  }, []);

  const onDialogClosed = useCallback(
    shouldUpdate => {
      setDialogOpened(false);
      if (shouldUpdate) onShared();
    },
    [onShared]
  );

  return (
    <>
      <ShareButton {...buttonProps} onClick={onShareClicked} />
      <Dialog
        open={dialogOpened}
        instanceId={instanceId}
        onClose={onDialogClosed}
      />
    </>
  );
};

Widget.propTypes = {
  buttonProps: PropTypes.object,
  instanceId: PropTypes.number.isRequired,
  onShared: PropTypes.func,
};

Widget.defaultProps = {
  buttonProps: {},
  onShared: () => {},
};

export default Widget;
