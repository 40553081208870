import React, { useCallback, useState } from 'react';
import CreateButton from './CreateButton';
import Dialog from './Dialog';
import PropTypes from 'prop-types';

const Widget = props => {
  const { onProcedureCreated } = props;
  const [dialogOpened, setDialogOpened] = useState(false);
  const onCreateClicked = useCallback(() => {
    setDialogOpened(true);
  }, []);

  const onDialogClosed = useCallback(
    shouldUpdate => {
      setDialogOpened(false);
      if (shouldUpdate) onProcedureCreated();
    },
    [onProcedureCreated]
  );

  return (
    <>
      <CreateButton onClick={onCreateClicked} />
      <Dialog open={dialogOpened} onClose={onDialogClosed} />
    </>
  );
};

Widget.propTypes = {
  onProcedureCreated: PropTypes.func,
};

Widget.defaultProps = {
  onProcedureCreated: () => {},
};

export default Widget;
