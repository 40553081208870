import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import ROUTES from '../../constants/routes';

class SharedProcedureDocumentUploaded extends React.Component {
  onClick = () => {
    this.props.history.push(
      `${ROUTES.ARCHIVIO_GATE}/${this.props.event.payload.shared_procedure.procedure.id}`
    );
  };

  render() {
    const { event } = this.props;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onClick}>
              Pratica n° {event.payload.shared_procedure.procedure.full_number}
            </TextLinkPrimary>
            <br />
            <b>File: </b> {event.payload.file_name}
            <br />
            <b>Caricato da: </b> {event.payload.performed_by.full_name}
            <br />
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(SharedProcedureDocumentUploaded));
