import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Citizen, DocumentType } from '../../api/model';
import FormControlValidated from '../../../common/FormControlValidated';
import { Input } from '@material-ui/core';
import { Button, StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { ChevronLeft, Search } from '@material-ui/icons';
import { CitizenCard } from './partial/CitizenCard';
import { getCitizenByCf } from '../../api/api';
import CodiceFiscale from 'codice-fiscale-js';

const styles = (theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    padding: '16px 48px',
    gap: '16px',
  },
  inputLayout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    alignSelf: 'center',
    width: '40%',
  },
  resultLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'center',
    gap: '8px',
  },
  footerContainer: {
    position: 'sticky',
    backgroundColor: theme.palette.white.main,
    bottom: 0,
    justifySelf: 'end',
    alignSelf: 'stretch',
    padding: '24px 32px 48px',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    justifyContent: 'space-between',
  },
});

interface SearchCitizenProps extends StyledComponentProps {
  data?: Citizen;
  onChange: (data?: Citizen) => void;
  onCreate: (data?: string) => void;
  goBack: () => void;
  forbiddenCFValues?: string[];
}

//GSTLNZ93E25E783F
//CVNFRZ83C18H501V

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultCitizen = {
  id: '0',
  firstName: 'Fabrizio',
  lastName: 'Cavaniglia',
  fiscalCode: 'Oscuro signore',
  documentType: DocumentType.drivingLicense,
  documentNumber: '00000000000',
  birthday: new Date('1983-03-18'),
  gender: 'F' as const,
  birthPlaceCountry: 'ITALY',
  birthPlaceCounty: 'LAZIO',
  birthPlace: 'ROMA',
  address: {},
};

const defaultCFInvalidMessage = 'Il codice fiscale non é valido';

const _SearchCitizen: FC<SearchCitizenProps> = ({
  data,
  onChange: onChangeCallback,
  onCreate: onCreateCallback,
  goBack,
  classes = {},
  forbiddenCFValues = [],
}) => {
  //TODO: rename to searchInput
  const [fiscalCode, setFiscalCode] = useState<
    Citizen['fiscalCode'] | undefined
  >(data?.fiscalCode);

  const [citizen, setCitizen] = useState<Citizen | undefined | 'NOT_FOUND'>(
    data
  );
  const [loading, setLoading] = useState(false);
  const [invalidCFMessage, setInvalidCFMessage] = useState<
    string | undefined
  >();

  //TODO: simplify this madness
  const isValidFiscalCode = useMemo(() => {
    setInvalidCFMessage(undefined);
    if (fiscalCode) {
      if (
        forbiddenCFValues
          ?.map(s => s.toLowerCase())
          .includes(fiscalCode.toLowerCase())
      ) {
        setInvalidCFMessage(
          'Attenzione! Il codice fiscale inserito corrisponde a quello indicato nello step precedente.'
        );
        return false;
      }
      try {
        if (new CodiceFiscale(fiscalCode).isValid()) {
          return true;
        }
      } catch (e) {
        //ignore
      }
      setInvalidCFMessage(defaultCFInvalidMessage);
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalCode]);

  const isInputDisabled = loading;

  useEffect(() => {
    async function getUser() {
      setLoading(true);
      try {
        const result = await getCitizenByCf(fiscalCode!);
        setCitizen(result ? result : 'NOT_FOUND');
      } catch (e) {
        if (e.status === 404) {
          setCitizen('NOT_FOUND');
        } else {
          console.error(
            'An error has occurred during citizen data retrival:',
            e
          );
          setInvalidCFMessage('Si verificato un errore. Riprova');
        }
      } finally {
        setLoading(false);
      }
    }
    if (isValidFiscalCode && !loading) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidFiscalCode]);

  const onChangeFiscalCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCitizen(undefined);
      setFiscalCode(event.target.value);
    },
    []
  );

  const onCreate = useCallback(() => {
    onCreateCallback(fiscalCode);
  }, [fiscalCode, onCreateCallback]);

  const onChange = useCallback(() => {
    if (citizen !== 'NOT_FOUND') onChangeCallback(citizen);
  }, [citizen, onChangeCallback]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.inputLayout}>
          <Search
            color={invalidCFMessage ? 'error' : 'primary'}
            style={{ marginTop: '36px' }}
          />
          <FormControlValidated
            inputId="searchString"
            label="Codice Fiscale"
            error={invalidCFMessage}
          >
            <Input
              id="searchString"
              name="searchString"
              type="text"
              onChange={onChangeFiscalCode}
              classes={
                {
                  inkbar: !invalidCFMessage
                    ? classes.inputInkbar
                    : classes.inputInkbarError,
                } as Record<any, any>
              }
              disabled={isInputDisabled}
              autoFocus
            />
          </FormControlValidated>
        </div>
        {citizen ? (
          citizen === 'NOT_FOUND' ? (
            <div className={classes.resultLayout}>
              <p>Nessun cittadino registrato con questo codice fiscale</p>
              <Button variant="raised" color="primary" onClick={onCreate}>
                Aggiungi nuovo cittadino
              </Button>
            </div>
          ) : (
            <div className={classes.resultLayout}>
              <CitizenCard data={citizen} />
            </div>
          )
        ) : null}
      </div>
      <div className={classes.footerContainer}>
        <Button variant="flat" color="primary" onClick={goBack}>
          <ChevronLeft />
          Indietro
        </Button>
        <Button
          variant="raised"
          color="primary"
          onClick={onChange}
          disabled={!citizen || citizen === 'NOT_FOUND'}
        >
          Avanti
        </Button>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const SearchCitizen = withStyles(styles)(_SearchCitizen);
