import { withStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Loader from '../../common/Loader';
import { Col, Row } from 'react-flexbox-grid';
import { DialogContentText } from '@material-ui/core';
import { Input } from '@material-ui/core';
import { Button } from '@material-ui/core';
import FormControlValidated from '../../common/FormControlValidated';

const styles = theme => ({
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

export default withStyles(styles)(props => {
  const { classes, loading, open, confirmError } = props;

  const [protocolNumber, setProtocolNumber] = useState('');
  const [error, setError] = useState(undefined);

  const onConfirm = useCallback(() => {
    if (protocolNumber) {
      props.onConfirm(protocolNumber);
    } else {
      setError('Il numero di protocollo è obbligatorio');
    }
  }, [protocolNumber, setError, props]);

  const handleKeyPress = useCallback(
    event => {
      if (event.key === 'Enter' && protocolNumber !== '') {
        onConfirm();
      }
    },
    [onConfirm, protocolNumber]
  );

  const onFieldChange = useCallback(
    event => {
      setProtocolNumber(event.target.value.trim());
      setError(false);
    },
    [setProtocolNumber, setError]
  );

  const onClose = useCallback(() => {
    setProtocolNumber('');
    props.onClose();
  }, [props]);

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={onClose}
      aria-labelledby="bp-process-dialog-title"
      aria-describedby="bp-process-dialog-description"
    >
      <DialogTitle id="bp-process-dialog-title">
        Presa in carico della pratica
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row>
            <Col xs={12}>
              <DialogContentText>
                Per procedere con la presa in carico della pratica è necessario
                specificare il numero di protocollo assegnato alla pratica.
              </DialogContentText>
            </Col>
            <Col xs={12} style={{ marginTop: '15px' }}>
              <FormControlValidated
                inputId="protocolNumber"
                label="N° Protocollo"
                error={error || confirmError}
              >
                <Input
                  placeholder="es. 2020/123456"
                  onKeyPress={handleKeyPress}
                  id="protocolNumber"
                  type="text"
                  defaultValue={protocolNumber}
                  onChange={onFieldChange}
                  classes={{
                    inkbar:
                      error || confirmError
                        ? classes.inputInkbar
                        : classes.inputInkbarError,
                  }}
                />
              </FormControlValidated>
            </Col>
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={onConfirm} disabled={loading}>
            Conferma
          </Button>
          <Button onClick={onClose} disabled={loading}>
            Annulla
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
});
