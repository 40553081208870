import React from 'react';
import { withStyles } from '@material-ui/core';
import DetailElement from '../../common/DetailElement';
import classNames from 'classnames';
import Subsection from '../../common/Subsection';
import { Col, Row } from 'react-flexbox-grid';
import PersonCard from '../../common/PersonCard';
import { getLabelOccupazioneSuoloType } from '../../../constants/common';
import {
  GeoJSON,
  MapContainer as Map,
  TileLayer,
  Tooltip,
} from 'react-leaflet';

const styles = () => ({
  simpleLabelValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

const getOccupationItems = details => {
  const allowed = {
    tables: 'Tavoli',
    chairs: 'Sedie',
    umbrellas: 'Ombrelloni',
    footboard: 'Pedana',
  };

  const items = [];

  Object.keys(allowed).forEach(key => {
    if (details[key] === true) {
      items.push(allowed[key]);
    }
  });

  if (details['other'] === true) {
    items.push(`Altro - ${details.other_description}`);
  }

  return items;
};

export const OccupazioneSuoloPubblicoDetailList = withStyles(styles)(props => {
  const { procedure } = props;

  return (
    <>
      <DetailElement
        label="Oggetto"
        value={procedure.subject}
        icon="nc-icon nc-email-83"
        separator
      />
      <DetailElement
        label="Ubicazione"
        value={procedure.details.address}
        icon="nc-icon nc-square-pin"
        separator
      />
      <DetailElement
        label="Tipologia"
        value={getLabelOccupazioneSuoloType(procedure.details.type)}
        icon="nc-icon nc-bullet-list-67"
        separator
      />
    </>
  );
});

export const OccupazioneSuoloPubblicoDetail = withStyles(styles)(props => {
  const { procedure, classes } = props;
  const occupationItems = getOccupationItems(procedure.details);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <i
            className={classNames(classes.headerIcon, 'nc-icon nc-settings-90')}
          />
          <span className={classes.headerTitle}>Dettagli</span>
        </div>
        <Subsection>
          <Row>
            <LabelValue
              label="Dimensioni"
              value={
                <AreaFormula
                  length={procedure.details.length}
                  width={procedure.details.width}
                  total={procedure.details.total_square_meters}
                />
              }
            />
            <LabelValue label="Dal" value={procedure.details.start_date} />
            <LabelValue
              label="Al"
              value={
                procedure.details.end_date ? procedure.details.end_date : '-'
              }
            />
            <LabelValue
              label="Durata Complessiva"
              value={
                procedure.details.end_date
                  ? `${procedure.details.total_duration} giorni`
                  : 'A tempo indeterminato'
              }
            />
            <Col xs={12} className={classes.simpleLabelValueContainer}>
              <span className={classes.simpleLabel}>Occupazione Tramite</span>
              <ul>
                {occupationItems.map(item => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </Col>
            {procedure.details.supplier && (
              <Col xs={12} className={classes.simpleLabelValueContainer}>
                <span className={classes.simpleLabel}>Ditta Affidataria</span>
                <span className={classes.simpleValue}>
                  <PersonCard
                    person={procedure.details.supplier}
                    personType="business"
                  />
                </span>
              </Col>
            )}
          </Row>
        </Subsection>
      </div>

      {procedure.details.affected_area && (
        <div className={classes.container}>
          <div className={classes.header}>
            <i
              className={classNames(
                classes.headerIcon,
                'nc-icon nc-square-pin'
              )}
            />
            <span className={classes.headerTitle}>Area Interessata</span>
          </div>
          <Subsection>
            <Row>
              <Col xs={12}>
                <Map
                  style={{ height: '300px' }}
                  zoom={14}
                  center={[44.316225, 9.323962]}
                >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <GeoJSON
                    style={() => {
                      return {
                        color: '#174f52',
                        width: 2,
                        fill: true,
                        fillColor: '#174f52',
                        fillOpacity: 0.4,
                      };
                    }}
                    data={procedure.details.affected_area}
                  >
                    <Tooltip>Area Interessata</Tooltip>
                  </GeoJSON>
                </Map>
              </Col>
            </Row>
          </Subsection>
        </div>
      )}
    </>
  );
});

const LabelValue = withStyles(styles)(props => {
  const { classes, label, value } = props;

  return (
    <Col md={3} sm={6} xs={12} className={classes.simpleLabelValueContainer}>
      <span className={classes.simpleLabel}>{label}</span>
      <span className={classes.simpleValue}>{value}</span>
    </Col>
  );
});

const AreaFormula = props => {
  const { length, width, total } = props;

  return (
    <span>
      {length} m &times; {width} m ({total} m<sup>2</sup>)
    </span>
  );
};
