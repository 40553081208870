import React, { FC, useCallback } from 'react';
import { withStyles, StyledComponentProps } from '@material-ui/core';
import { DetailField } from './DetailField';
import moment from 'moment';
import { withRouter } from 'react-router/index';
import { RouteComponentProps } from 'react-router-dom';
import ROUTES from '../../../../../../constants/routes';
import TextLinkPrimary from '../../../../../common/TextLinkPrimary';
import { PersonDataDTO } from '../../../../../../gen/api/welfare';

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
});
interface BeneficiarySectionProps
  extends StyledComponentProps,
    RouteComponentProps {
  beneficiary: PersonDataDTO;
}
const BeneficiarySection: FC<BeneficiarySectionProps> = ({
  classes = {},
  beneficiary,
  history,
}) => {
  const goToDetail = useCallback(() => {
    history.push(`/${ROUTES.CITTADINI}/${beneficiary.userId}`);
  }, [history, beneficiary]);
  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Beneficiario</label>
        <TextLinkPrimary bold onClick={goToDetail}>
          Dettaglio completo
        </TextLinkPrimary>{' '}
      </div>
      <div className={classes.fieldContainer}>
        <DetailField label="Nome" value={beneficiary.firstName} />
        <DetailField label="Cognome" value={beneficiary.lastName} />
        <DetailField label="Codice fiscale" value={beneficiary.fiscalCode} />
      </div>
      <div className={classes.fieldContainer}>
        <DetailField
          label="Data di nascita"
          value={moment(beneficiary.birthDate).format('DD/MM/YYYY')}
        />
      </div>
    </div>
  );
};

const DecoratedBeneficiarySection =
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  withStyles(styles)(withRouter(BeneficiarySection));

export { DecoratedBeneficiarySection as BeneficiarySection };
