import React, { useState, useMemo, useCallback } from 'react';
import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import PraticheInterne from '../../../api/PraticheInterne';
import store from '../../../store';
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SelectDeparmentsStep from './Steps/SelectDeparmentsStep';
import Loader from '../../common/Loader';

const styles = theme => ({
  container: {
    minWidth: '600px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
});

const STEP_SELECT_DEPARTMENTS = 'STEP_SELECT_DEPARTMENTS';
const STEP_SHARING = 'STEP_SHARING';
const STEPS = [STEP_SELECT_DEPARTMENTS, STEP_SHARING];

const Dialog = props => {
  const { classes, open, procedure, onClose } = props;
  const { snackBar } = store.app;
  const [step, setStep] = useState(0);
  const [departments, setDepartments] = useState([]);

  const currStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  const close = useCallback(
    (shouldUpdate = false) => {
      setStep(0);
      setDepartments([]);
      onClose(shouldUpdate);
    },
    [onClose]
  );

  const onCancel = useCallback(() => {
    close(false);
  }, [close]);

  const onSave = useCallback(() => {
    setStep(step => step + 1);
    PraticheInterne.share(
      procedure.id,
      departments,
      () => {
        close(true);
        snackBar.open('Atto condiviso con successo');
      },
      () => {
        setStep(step => step - 1);
        snackBar.open(
          'Si è verificato un errore imprevisto. Riprova fra qualche istante'
        );
      }
    );
  }, [close, procedure, departments, snackBar]);

  const addDepartment = useCallback(
    department => {
      const newDept =
        departments.findIndex(d => d === department) === -1 ? [department] : [];
      setDepartments([...departments, ...newDept]);
    },
    [departments]
  );

  const removeDepartment = useCallback(
    department => {
      const index = departments.findIndex(d => d === department);
      if (index > -1) {
        const newDepartments = [...departments];
        newDepartments.splice(index, 1);
        setDepartments(newDepartments);
      }
    },
    [departments]
  );

  return (
    <MaterialDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="internal-procedure-share-dialog-title"
      aria-describedby="internal-procedure-share-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle
          className={classes.dialogTitle}
          id="internal-procedure-share-dialog-title"
        >
          <span>
            {currStep === STEP_SHARING
              ? 'Condivisione in corso...'
              : 'Condividi atto dirigenziale'}
          </span>
          <span className={classes.closeIcon} onClick={onCancel}>
            &times;
          </span>
        </DialogTitle>
        <DialogContent>
          {currStep === STEP_SELECT_DEPARTMENTS && (
            <SelectDeparmentsStep
              departments={departments}
              procedure={procedure}
              message="Seleziona i dipartimenti con i quali vuoi condividere l'atto"
              onDepartmentSelected={addDepartment}
              onDepartmentRemoved={removeDepartment}
            />
          )}
          {currStep === STEP_SHARING && (
            <Loader loading={true} color="primary" />
          )}
        </DialogContent>
        <DialogActions>
          {currStep === STEP_SELECT_DEPARTMENTS && (
            <>
              <Button onClick={onCancel} className={classes.marginRightButton}>
                Annulla
              </Button>
              <Button
                onClick={onSave}
                variant="raised"
                color="primary"
                disabled={!departments.length}
              >
                Condividi
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  procedure: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Dialog);
