import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TextLinkPrimary from '../common/TextLinkPrimary';
import {
  getAbstractProcedureLink,
  getDepartmentLabel,
} from '../../constants/common';
import _ from 'lodash';

class SharedProcedureCreated extends React.Component {
  onClick = () => {
    const procedure = this.props.event.payload.shared_procedure.procedure;
    const url = getAbstractProcedureLink(procedure.type, procedure.id);
    this.props.history.push(url);
  };

  render() {
    const { event } = this.props;

    return (
      <>
        {event.payload && (
          <span>
            <TextLinkPrimary onClick={this.onClick}>
              Pratica n° {event.payload.shared_procedure.procedure.number}
            </TextLinkPrimary>
            <br />
            <b>Condivisa da: </b> {event.payload.performed_by.full_name}
            <br />
            <b>Condivisa con: </b>{' '}
            {getDepartmentLabel(
              _.isObject(event.payload.shared_procedure.shared_to)
                ? event.payload.shared_procedure.shared_to.name
                : event.payload.shared_procedure.shared_to
            )}
            <br />
          </span>
        )}
      </>
    );
  }
}

export default observer(withRouter(SharedProcedureCreated));
