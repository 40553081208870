import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectDocument from './Steps/SelectDocument';
import SignCredentials from './Steps/SignCredentials';
import OperationInProgress from './Steps/OperationInProgress';
import { ROLES } from '../../../../constants/security';
import store from '../../../../store';
import cx from 'classnames';
import FirmaDigitale from '../../../../api/FirmaDigitale';
import DocumentiTemporanei from '../../../../api/DocumentiTemporanei';

const STEP_SELECT_DOCUMENT = 'SELECT_DOCUMENT';
const STEP_SIGN_CREDENTIALS = 'SIGN_CREDENTIALS';
const STEP_SIGNING_IN_PROGRESS = 'SIGNING_IN_PROGRESS';
const STEP_UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS';

const STEPS = [
  STEP_SELECT_DOCUMENT,
  STEP_SIGN_CREDENTIALS,
  STEP_SIGNING_IN_PROGRESS,
  STEP_UPLOAD_IN_PROGRESS,
];

const STEP_TITLES = {
  [STEP_SELECT_DOCUMENT]: 'Seleziona il documento',
  [STEP_SIGN_CREDENTIALS]: 'Firma Digitale',
  [STEP_SIGNING_IN_PROGRESS]: 'Firma Digitale in corso...',
  [STEP_UPLOAD_IN_PROGRESS]: 'Caricamento file in corso...',
};

const styles = theme => ({
  container: {
    minWidth: '600px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
  floatRightButton: {
    justifySelf: 'flex-end',
  },
  root: {
    flex: 1,
  },
  action: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const Dialog = ({
  classes,
  open = false,
  onClose,
  onDocumentUploaded,
  uploadDocumentApi,
  digitalSignatureEnabled,
  digitalSignatureMandatory = false,
}) => {
  const { profile, snackBar } = store.app;
  const [step, setStep] = useState(0);
  const [document, setDocument] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({
    username: null,
    password: null,
    otp: null,
  });

  const currStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  const onNext = useCallback((skipSteps = 1) => {
    setStep(step => step + skipSteps);
  }, []);

  const prev = useCallback(() => {
    setStep(step => step - 1);
  }, []);

  const close = useCallback(() => {
    setStep(0);
    setDocument(null);
    setUsername('');
    setPassword('');
    setOtp('');
    onClose();
  }, [onClose]);

  const onSave = useCallback(
    (id, name) => {
      close();
      onDocumentUploaded(id, name);
    },
    [close, onDocumentUploaded]
  );

  const storeFile = useCallback(
    (file, name) => {
      if (uploadDocumentApi) {
        uploadDocumentApi(
          file,
          name,
          (id, name) => onSave(id, name),
          () => {
            snackBar.open(
              'Si è verificato un errore imprevisto durante il caricamento del file.'
            );
            close();
          }
        );
      } else {
        DocumentiTemporanei.upload(
          file,
          data => {
            onSave(data.id, name);
          },
          () => {
            snackBar.open(
              'Si è verificato un errore imprevisto durante il caricamento del file.'
            );
            close();
          }
        );
      }
    },
    [close, onSave, snackBar, uploadDocumentApi]
  );

  const uploadDocument = useCallback(() => {
    onNext(3);
    storeFile(document, document.name);
  }, [document, onNext, storeFile]);

  const signDocument = useCallback(() => {
    onNext();
    FirmaDigitale.sign(
      {
        username,
        password,
        otp,
      },
      document,
      file => {
        onNext();
        const documentName = document.name.endsWith('.p7m')
          ? document.name
          : document.name + '.p7m';
        storeFile(file, documentName);
      },
      err => {
        let message = 'Errore durante la firma del documento';
        if (err.status === 400) {
          message = 'Credenziali errate';
        }
        snackBar.open(message);
        prev();
      }
    );
  }, [document, onNext, otp, password, prev, snackBar, storeFile, username]);

  const onUsernameChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, username: null }));
    setUsername(value);
  }, []);

  const onPasswordChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, password: null }));
    setPassword(value);
  }, []);

  const onOtpChanged = useCallback(value => {
    setErrors(errors => ({ ...errors, otp: null }));
    setOtp(value);
  }, []);

  const onCancel = useCallback(() => {
    close();
  }, [close]);

  const onDocumentSelected = useCallback(d => {
    setDocument(d);
  }, []);

  const onDocumentDeleted = useCallback(() => {
    setDocument(undefined);
  }, []);

  const validCredentials = useMemo(() => {
    return Boolean(
      username && password && otp && !Object.values(errors).some(v => v)
    );
  }, [errors, username, password, otp]);

  const dialogTitle = useMemo(() => {
    return STEP_TITLES[STEPS[step]];
  }, [step]);

  const onCredentialsInserted = useCallback(() => {
    const newErrors = {};
    if (!username) newErrors.username = 'Questo campo è obbligatorio';
    if (!password) newErrors.password = 'Questo campo è obbligatorio';
    if (!otp) newErrors.otp = 'Questo campo è obbligatorio';
    if (Object.values(newErrors).some(v => Boolean(v))) {
      setErrors({ ...errors, newErrors });
    } else {
      signDocument();
    }
  }, [signDocument, errors, username, password, otp]);

  return (
    <MaterialDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={close}
      aria-labelledby="document-upload-dialog-title"
      aria-describedby="document-upload-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle
          className={classes.dialogTitle}
          id="document-upload-dialog-title"
        >
          <span>{dialogTitle}</span>
          {![STEP_SIGNING_IN_PROGRESS, STEP_UPLOAD_IN_PROGRESS].includes(
            currStep
          ) && (
            <span className={classes.closeIcon} onClick={onCancel}>
              &times;
            </span>
          )}
        </DialogTitle>
        <DialogContent>
          {currStep === STEP_SELECT_DOCUMENT && (
            <SelectDocument
              onDocumentSelected={onDocumentSelected}
              onDocumentDeleted={onDocumentDeleted}
              selectedDocument={document}
            />
          )}
          {currStep === STEP_SIGN_CREDENTIALS && (
            <SignCredentials
              username={username}
              password={password}
              otp={otp}
              errors={errors}
              onUsernameChange={onUsernameChanged}
              onPasswordChange={onPasswordChanged}
              onOtpChange={onOtpChanged}
            />
          )}
          {currStep === STEP_SIGNING_IN_PROGRESS && (
            <OperationInProgress message="Firma del documento in corso..." />
          )}
          {currStep === STEP_UPLOAD_IN_PROGRESS && (
            <OperationInProgress message="Upload del documento in corso..." />
          )}
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.root,
            action: classes.action,
          }}
        >
          {currStep === STEP_SELECT_DOCUMENT && (
            <>
              <Button onClick={onCancel}>Annulla</Button>
              <div>
                {(profile.hasRole(ROLES.MANAGER, true) &&
                  digitalSignatureEnabled) ||
                  (digitalSignatureMandatory && (
                    <Button
                      variant="outlined"
                      disabled={!document}
                      color="primary"
                      onClick={() => onNext()}
                      className={cx(
                        classes.marginRightButton,
                        classes.floatRightButton
                      )}
                    >
                      Firma digitalmente e carica
                    </Button>
                  ))}
                {!digitalSignatureMandatory && (
                  <Button
                    disabled={!document}
                    variant="raised"
                    color="primary"
                    onClick={uploadDocument}
                    className={classes.floatRightButton}
                  >
                    Carica
                  </Button>
                )}
              </div>
            </>
          )}
          {currStep === STEP_SIGN_CREDENTIALS && (
            <>
              <Button onClick={onCancel} className={classes.marginRightButton}>
                Annulla
              </Button>
              <div>
                <Button
                  onClick={onCredentialsInserted}
                  variant="raised"
                  color="primary"
                  disabled={!validCredentials}
                >
                  Firma e Carica
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  uploadDocumentApi: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  digitalSignatureEnabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Dialog);
