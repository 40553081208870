import React, { FC, useCallback, useState } from 'react';
import {
  StyledComponentProps,
  Dialog as MaterialDialog,
  withStyles,
  Button,
} from '@material-ui/core';
import { Loading } from './Loading';
import Credentials from './Credentials';
import { signInstance } from '../../../../../api/api';
import store from '../../../../../../../store';
import { SignWelfareInstanceRequest } from '../../../../../../../gen/api/welfare';
import { procedureUpdated } from '../../../../../../../stm/socialServices/procedureDetail.events';
import { StyleRules } from '@material-ui/core/styles';
import cx from 'classnames';
import { useProcedureDetail } from '../../../../../../../stm/socialServices/procedureDetail.stm';

const styles = (theme: any): StyleRules => ({
  container: {
    padding: '24px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
  root: {
    flex: 1,
  },
  action: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
});

interface SignInstanceDialogProps extends StyledComponentProps {
  id: string;
  onClose?: () => void;
}

const SignInstanceDialog: FC<SignInstanceDialogProps> = ({
  id,
  onClose,
  classes = {},
}) => {
  const { dispatch } = useProcedureDetail();
  const { snackBar } = store.app;
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [otp, setOtp] = useState<string>();

  const [errors, setErrors] = useState<Partial<SignWelfareInstanceRequest>>({});

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = useCallback(() => {
    setLoading(true);
    signInstance({
      id,
      signWelfareInstanceRequest: {
        username: username!,
        password: password!,
        otp: otp!,
      },
    })
      .then(response => {
        snackBar.open('Il documento è stato firmato correttamente');
        dispatch(procedureUpdated(response));
        if (onClose) onClose();
      })
      .catch(error => {
        snackBar.open(
          'Si é verificato un errore durante la firma della Delibera'
        );
        console.error(error);
        if (onClose) onClose();
      });
  }, [dispatch, id, onClose, otp, password, snackBar, username]);

  const onCredentialsInserted = useCallback(() => {
    const newErrors = {} as Partial<SignWelfareInstanceRequest>;
    if (!username) newErrors.username = 'Questo campo è obbligatorio';
    if (!password) newErrors.password = 'Questo campo è obbligatorio';
    if (!otp) newErrors.otp = 'Questo campo è obbligatorio';
    if (Object.values(newErrors).some(v => Boolean(v))) {
      setErrors(e => ({ ...e, newErrors }));
    } else {
      onSubmit();
    }
  }, [username, password, otp, onSubmit]);

  return (
    <MaterialDialog
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="document-upload-dialog-title"
      aria-describedby="document-upload-dialog-description"
      onClose={onClose}
    >
      {loading && <Loading />}
      {!loading && (
        <div className={classes.container}>
          <Credentials
            errors={errors as Record<string, string>}
            username={username}
            password={password}
            otp={otp}
            onOtpChange={setOtp}
            onPasswordChange={setPassword}
            onUsernameChange={setUsername}
          />
          <div className={classes.action}>
            <Button onClick={onClose}>Annulla</Button>
            <Button
              variant="outlined"
              onClick={onCredentialsInserted}
              color="primary"
              className={cx(classes.floatRightButton)}
            >
              Firma digitalmente
            </Button>
          </div>
        </div>
      )}
    </MaterialDialog>
  );
};

const DecoratedSignInstanceDialog = withStyles(styles)(SignInstanceDialog);
export { DecoratedSignInstanceDialog as SignInstanceDialog };
