import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import tableStyle from '../../styles/table.style';
import Pagination from '../common/Pagination';

class TablePagination extends React.Component {
  state = {
    per_page: 5,
  };

  handleChangePage = (event, page) => {
    this.props.handleChangePage({ page: page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ per_page: event.target.value }, () => {
      this.props.handleChangePage({ page: 1, per_page: event.target.value });
    });
  };

  render() {
    const { pagination, tableCols, handleChangePage } = this.props;
    return (
      pagination && (
        <Pagination
          tableCols={tableCols}
          total={pagination.total_pages}
          current={pagination.current_page}
          display={tableCols}
          onChange={handleChangePage}
          totalElement={pagination.total}
          elementNumber={pagination.count}
        />
      )
    );
  }
}

TablePagination.propTypes = {
  pagination: PropTypes.object,
  tableCols: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

TablePagination.defaultProps = {
  pagination: {
    total: 5,
    count: 1,
    per_page: 15,
    current_page: 1,
    total_pages: 1,
  },
};

export default withStyles(tableStyle)(TablePagination);
