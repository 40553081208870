import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import Subsection from '../common/Subsection';
import { SharingDetails } from '../common/SharingDetails';
import Pratiche from '../../api/PraticheGate';
import { Button } from '@material-ui/core';
import { FileUpload as UploadIcon } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import Loader from '../common/Loader';
import EmptyListMessage from '../common/EmptyListMessage';
import TableEventi from '../common/TableEventi';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import PersonDetailsModal from '../common/PersonDetailsModal';
import PersonCard from '../common/PersonCard';

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px!important',
  },
}))(Tooltip);

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  centeredContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  containerNoPadding: {
    padding: '0 !important',
  },
  detailList: {
    margin: 0,
    listStyle: 'none',
    padding: '0',
  },
  descriptionIcon: {
    color: theme.palette.secondary.main,
  },
  detailElementContainer: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
  },
  detailElementIconContainer: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    marginRight: '15px',
  },
  detailElementDataContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailElementLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  detailElementSeparator: {
    borderBottom: '1px solid #eee',
  },
  personContainer: {
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid #eee',
    padding: '15px',
    marginRight: '5px',
    marginBottom: '5px',
    minWidth: '250px',
  },
  personIcon: {
    color: theme.palette.secondary.main,
    fontSize: '24px',
    height: '100%',
    marginRight: '15px',
    textAlign: 'center',
  },
  personDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  personDetailsHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  personDetailsName: {
    fontSize: '16px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: '2px',
  },
  personDetailsType: {
    fontSize: '12px',
    color: '#555',
    fontWeight: '400',
  },
  personDetailBody: {
    listStyle: 'none',
    padding: '0',
  },
  personDetailBodyItem: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  personDetailsBodyIcon: {
    fontSize: '20px',
    color: theme.palette.secondary.main,
    marginRight: '10px',
  },
  personDetailsBodyValue: {
    height: '100%',
    lineHeight: '100%',
  },
  expertsList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  documentList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  documentContainer: {
    display: 'flex',
    borderRadius: '20px',
    border: '1px solid #eee',
    padding: '10px 15px',
    marginRight: '5px',
    marginBottom: '5px',
    maxWidth: '250px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    wordWrap: 'break-word',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#eee',
      cursor: 'pointer',
    },
  },
  documentIcon: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#555',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px',
    fontSize: '20px',
  },
  documentExtension: {
    marginTop: '2px',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  documentName: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  documentOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#6d6d6d',
    height: '100%',
    width: '100%',
    opacity: '.5',
    top: 0,
    left: 0,
    border: 'none',
    margin: '0',
    borderRadius: 'inherit',
    position: 'absolute',
    padding: 0,
    transition: 'opacity .5s',
    cursor: 'not-allowed',
  },
  documentDate: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
    bottom: '2px',
    right: '15px',
    color: '#555',
  },
  shareDocumentsActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: '10px',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
});

class LegacyProcedure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPerson: null,
    };
  }

  loadEvents = (pageNumber = { page: 1 }) => {
    this.props.loadEvents(pageNumber);
  };

  onPersonClicked = person => {
    this.setState({
      selectedPerson: person,
    });
  };

  onPersonDetailsModalClosed = () => {
    this.setState({
      selectedPerson: null,
    });
  };

  render() {
    const {
      classes,
      isOwner,
      procedure,
      loadingDocuments,
      documents,
      uploadingDocument,
      downloadingDocument,
      loadingEvents,
      events,
      paginationEvents,
      onDocumentClick,
      onUploadClicked,
    } = this.props;

    const { selectedPerson } = this.state;

    return (
      <>
        <div
          className={classNames(classes.container, classes.containerNoPadding)}
        >
          <span>
            <Row>
              <Col xs={12}>
                <ul className={classes.detailList}>
                  <DetailElement
                    label="Codice"
                    value={procedure.full_number}
                    icon="nc-icon nc-zoom-split"
                    separator
                  />
                  <DetailElement
                    label="Oggetto"
                    value={procedure.subject}
                    icon="nc-icon nc-email-83"
                    separator
                  />
                  <DetailElement
                    label="Categoria"
                    value={procedure.category}
                    icon="nc-icon nc-tag-content"
                    separator
                  />
                  <DetailElement
                    label="# Protocollo"
                    value={procedure.protocol}
                    icon="nc-icon nc-notes"
                    separator
                  />
                  <DetailElement
                    label="Ubicazione"
                    value={procedure.location}
                    icon="nc-icon nc-square-pin"
                  />
                </ul>
              </Col>
            </Row>
          </span>
        </div>

        {procedure.referrer && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(
                  classes.headerIcon,
                  'nc-icon nc-circle-09'
                )}
              />
              <span className={classes.headerTitle}>Referente</span>
            </div>
            <Subsection>
              <Row>
                <PersonCard
                  personType={procedure.referrer.gender}
                  person={procedure.referrer}
                  isLegacy
                  onPersonClicked={this.onPersonClicked}
                />
              </Row>
            </Subsection>
          </div>
        )}

        {procedure.experts.length > 0 && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(
                  classes.headerIcon,
                  'nc-icon nc-ruler-pencil'
                )}
              />
              <span className={classes.headerTitle}>Tecnici</span>
            </div>
            <Subsection>
              <Row>
                <div className={classes.expertsList}>
                  {procedure.experts.map((expert, index) => {
                    return (
                      <PersonCard
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        personType={procedure.referrer.gender}
                        person={expert}
                        isExpert
                        onPersonClicked={this.onPersonClicked}
                        isLegacy
                      />
                    );
                  })}
                </div>
              </Row>
            </Subsection>
          </div>
        )}

        {isOwner && (
          <SharingDetails
            classes={classes}
            procedure={procedure}
            loadSharedTo={Pratiche.getSharedTo}
          />
        )}

        <div className={classes.container}>
          <div className={classes.header}>
            <i
              className={classNames(
                classes.headerIcon,
                'nc-icon nc-single-copy-04'
              )}
            />
            <span className={classes.headerTitle}>Documenti</span>
            {isOwner && (
              <Col className={classes.shareDocumentsActions}>
                <BiggerTooltip
                  title="Carica Documento"
                  className={classes.tooltip}
                >
                  <Button
                    size="small"
                    variant="raised"
                    color="primary"
                    disabled={uploadingDocument || loadingDocuments}
                    className={classes.smallActionButton}
                    onClick={onUploadClicked}
                  >
                    {!uploadingDocument ? (
                      <UploadIcon className={classes.smallActionButtonIcon} />
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </Button>
                </BiggerTooltip>
              </Col>
            )}
          </div>
          <Subsection>
            <Loader size={40} loading={loadingDocuments} />
            <EmptyListMessage
              visible={documents.length === 0 && !loadingDocuments}
            />
            {documents.length > 0 && !loadingDocuments && (
              <Row>
                <div className={classes.documentList}>
                  {documents.map(document => {
                    return (
                      <DocumentCard
                        key={document.id}
                        document={document}
                        onClick={onDocumentClick}
                        isDownloading={downloadingDocument === document.id}
                      />
                    );
                  })}
                </div>
              </Row>
            )}
          </Subsection>
        </div>

        {isOwner && (
          <div className={classes.container}>
            <div className={classes.header}>
              <i
                className={classNames(classes.headerIcon, 'nc-icon nc-cctv')}
              />
              <span className={classes.headerTitle}>Eventi</span>
            </div>
            <Subsection>
              <Loader size={40} loading={loadingEvents} />
              <EmptyListMessage
                visible={events.length === 0 && !loadingEvents}
              />
              {events.length > 0 && (
                <TableEventi
                  goToUserDetail={this.props.goToUserDetails}
                  goToSessionDetails={this.props.goToSessionDetails}
                  loading={loadingEvents}
                  loadPage={this.loadEvents}
                  events={events}
                  pagination={paginationEvents}
                />
              )}
            </Subsection>
          </div>
        )}

        <PersonDetailsModal
          isLegacy
          person={selectedPerson}
          onClose={this.onPersonDetailsModalClosed}
        />
      </>
    );
  }
}

const DetailElement = withStyles(styles)(props => {
  const { classes, icon, label, value, separator } = props;

  return (
    <li
      className={classNames({
        [classes.detailElementSeparator]: separator,
      })}
    >
      <div className={classes.detailElementContainer}>
        {icon && (
          <div className={classes.detailElementIconContainer}>
            <i className={classNames(classes.detailElementIcon, icon)} />
          </div>
        )}
        <div className={classes.detailElementDataContainer}>
          <span className={classes.detailElementLabel}>{label}</span>
          {value ? value : 'N/A'}
        </div>
      </div>
    </li>
  );
});

/*const PersonCard = withStyles(styles)(props => {

    const { classes, person, isExpert } = props;

    return (
        <div className={classes.personContainer}>
            <div className={classes.personIcon}>
                { isExpert ? <i className="nc-icon nc-badge" /> :
                    ( person.gender === 'G' ? <i className="nc-icon nc-bag" /> : <i className="nc-icon nc-single-02" />)}
            </div>
            <div className={classes.personDetailsContainer}>
                <div className={classes.personDetailsHeader}>
                    <span className={classes.personDetailsName}>{person.full_name}</span>
                    <span className={classes.personDetailsType}>{person.gender === 'G' ? 'Persona Giuridica' : 'Persona Fisica'}</span>
                </div>
                <div>
                    <ul className={classes.personDetailBody}>
                        <li className={classes.personDetailBodyItem}>
                            <i className={classNames(classes.personDetailsBodyIcon, 'nc-icon', 'nc-email-85')} />
                            <span className={classes.personDetailsBodyValue}>{
                                person.email ?
                                    <a href={"mailto:" + person.email}>{person.email}</a> : 'N/A' }</span>
                        </li>
                        <li className={classes.personDetailBodyItem}>
                            <i className={classNames(classes.personDetailsBodyIcon, 'nc-icon', 'nc-pin-3')} />
                            <span className={classes.personDetailsBodyValue}>{ person.address ? person.address : 'N/A' }</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

});*/

const DocumentCard = withStyles(styles)(props => {
  const { classes, document, isDownloading } = props;

  const onClick = () => {
    if (props.onClick && !isDownloading) {
      props.onClick(document.id);
    }
  };

  return (
    <div className={classes.documentContainer} onClick={onClick}>
      <div className={classes.documentIcon}>
        <i className="nc-icon nc-single-copy-04" />
        <span className={classes.documentExtension}>{document.extension}</span>
      </div>
      <div className={classes.documentName}>{document.name}</div>
      <div className={classes.documentDate}>{document.date}</div>
      {isDownloading && (
        <div className={classes.documentOverlay}>
          <CircularProgress
            size={30}
            style={{ textAlign: 'center', color: '#fff' }}
          />
        </div>
      )}
    </div>
  );
});

DetailElement.defaultProps = {
  icon: null,
  separator: false,
};

DetailElement.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  separator: PropTypes.bool,
};

/*PersonCard.propTypes = {
    classes: PropTypes.object,
    person: PropTypes.object.isRequired,
    isExpert: PropTypes.bool
};

PersonCard.defaultProps = {
    isExpert: false,
};*/

DocumentCard.propTypes = {
  classes: PropTypes.object,
  document: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isDownloading: PropTypes.bool,
};

export default withStyles(styles)(observer(withRouter(LegacyProcedure)));
