import React, { FC } from 'react';
import { Dialog as MaterialDialog } from '@material-ui/core';
import {
  ApproveRejectStates,
  useApproveRejectInstanceContext,
} from '../../../../../../../stm/socialServices/approveRejectInstanceStm';
import { RejectInstanceDialog } from './RejectInstanceDialog';
import { Loading } from './Loading';
import { ApproveInstanceDialog } from './ApproveInstanceDialog';

const ComponentMap: { [key in ApproveRejectStates]: FC | undefined } = {
  [ApproveRejectStates.approving]: Loading as FC,
  [ApproveRejectStates.confirmApprove]: ApproveInstanceDialog as FC,
  [ApproveRejectStates.confirmReject]: RejectInstanceDialog as FC,
  [ApproveRejectStates.refusing]: Loading as FC,
  [ApproveRejectStates.waiting]: undefined,
};

export const ApproveRejectInstanceDialog: FC = () => {
  const stm = useApproveRejectInstanceContext();

  if (!stm.state.state || stm.state.state === ApproveRejectStates.waiting) {
    return null;
  }

  const Component = ComponentMap[stm.state.state]!;

  return (
    <MaterialDialog
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="dialog-title"
    >
      <Component />
    </MaterialDialog>
  );
};
