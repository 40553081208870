import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-flexbox-grid';
import PageContainer from '../../common/PageContainer';
import EmptyListMessage from '../../common/EmptyListMessage';
import PageTitle from '../../common/PageTitle';
import Pagination from '../../common/Pagination';
import ROUTES from '../../../constants/routes';
import {
  BUILDING_PROCEDURE_STATUS,
  getLabelBuildingProcedureCategory,
} from '../../../constants/common';
import Loader from '../../common/Loader';
import Api from '../../../api/Edilizia';
import { PraticaBox } from '../../common/PraticaBox';
import FilterItem from '../../common/filters/Filter';
import {
  FILTER_TYPE,
  FILTRI_CATEGORIA_PRATICA_EDILIZIA,
  FILTRI_IN_CORSO_PRATICA_EDILIZIA,
  FILTRI_STATO_PRATICA_EDILIZIA,
} from '../../common/filters/constants';
import GenericListFilters from '../../common/filters/GenericListFilters';
import ProcedureStatusBadge from './ProcedureStatusBadge';

const styles = theme => ({
  listBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '25px',
    marginBottom: '10px',
  },
  procedureTitle: {
    ...theme.typography.bodyStrong,
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  itemLabel: {
    fontWeight: 'bold',
  },
  procedureSubject: {
    ...theme.typography.bodyStrong,
    paddingBottom: '10px',
    fontSize: '13px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const TODO = 'todo';
const ARCHIVE = 'archive';

const TABS = [TODO, ARCHIVE];

class GestionePratiche extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listType: 'todo',
    };
  }

  onTabChange = tab => {
    this.setState({
      listType: TABS[tab],
    });
  };

  render() {
    return (
      <PageContainer
        whiteBg
        header={
          <PageTitle
            title="Elenco istanze"
            tabs={['Da Gestire', 'Archivio']}
            onTabChange={this.onTabChange}
          />
        }
      >
        {/* PERDONAME MADRE POR MI VIDA LOCA */}
        {this.state.listType === TODO && <ListaPratiche type={TODO} />}
        {this.state.listType === ARCHIVE && <ListaPratiche type={ARCHIVE} />}
      </PageContainer>
    );
  }
}

class ListaPratiche extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pratiche: [],
      pagination: null,
      currFilters: {},
    };
  }

  componentDidMount() {}

  onPageChange = changedPage => {
    this.loadList(changedPage.page, this.state.currFilters, true);
  };

  loadList = (page = 1, filters, loading = false) => {
    this.setState({ loading: loading }, () => {
      if (this.props.type === TODO) {
        filters.mode = TODO;
      }
      Api.getProcedureList(
        { page, filters: filters },
        response => {
          this.setState({
            loading: false,
            pratiche: response.procedures,
            currFilters: filters,
            pagination: response.meta.pagination,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    });
  };

  applyFilters = filters => {
    this.loadList(1, filters, true);
  };

  render() {
    const { type } = this.props;
    const { pagination } = this.state;
    const stateFilters =
      type !== TODO
        ? FILTRI_STATO_PRATICA_EDILIZIA
        : FILTRI_STATO_PRATICA_EDILIZIA.filter(filter => {
            return (
              [
                BUILDING_PROCEDURE_STATUS.NEW,
                BUILDING_PROCEDURE_STATUS.PROCESSED,
                BUILDING_PROCEDURE_STATUS.PENDING,
                BUILDING_PROCEDURE_STATUS.EXTENSION_REQUESTED,
              ].indexOf(filter.value) !== -1
            );
          });

    const filters = [
      new FilterItem(
        'number',
        FILTER_TYPE.NUMBER,
        'Numero',
        '',
        null,
        6,
        2,
        2,
        2
      ),
      new FilterItem('year', FILTER_TYPE.NUMBER, 'Anno', '', null, 6, 2, 2, 2),
      new FilterItem(
        'protocol',
        FILTER_TYPE.TEXT,
        'N° Protocollo',
        '',
        null,
        6,
        2,
        2,
        2
      ),
      new FilterItem(
        'category',
        FILTER_TYPE.SELECT,
        'Categoria',
        '',
        FILTRI_CATEGORIA_PRATICA_EDILIZIA,
        6,
        2,
        2,
        2
      ),
      new FilterItem(
        'status',
        FILTER_TYPE.SELECT,
        'Stato',
        '',
        stateFilters,
        6,
        2,
        2,
        2
      ),
      new FilterItem(
        'referrer_name',
        FILTER_TYPE.TEXT,
        'Nome',
        '',
        '',
        6,
        2,
        2,
        2
      ),
      new FilterItem(
        'referrer_surname',
        FILTER_TYPE.TEXT,
        'Cognome',
        '',
        '',
        6,
        2,
        2,
        2
      ),
    ];

    if (type !== TODO) {
      filters.push(
        new FilterItem(
          'is_active',
          FILTER_TYPE.SELECT,
          'In Corso/Terminata',
          '',
          FILTRI_IN_CORSO_PRATICA_EDILIZIA,
          6,
          2,
          2,
          2
        )
      );
    }

    const listName =
      type === TODO
        ? 'buildingProceduresListFilters'
        : 'buildingProceduresArchiveListFilters';

    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <GenericListFilters
            listName={listName}
            filters={filters}
            applyFilters={this.applyFilters}
          />
          <Loader size={40} loading={this.state.loading} />
          {!this.state.loading &&
            this.state.pratiche.map(pratica => {
              return (
                <PraticaBox
                  statusBadge={<ProcedureStatusBadge state={pratica.status} />}
                  key={pratica.id}
                  goTo={`/${ROUTES.PRATICHE_EDILIZIA}/${pratica.id}`}
                  categoryTransformer={getLabelBuildingProcedureCategory}
                  procedure={pratica}
                />
              );
            })}
          <EmptyListMessage
            visible={this.state.pratiche.length === 0 && !this.state.loading}
          />
          {pagination && (
            <Row>
              <Col xs={12} sm={12}>
                <Pagination
                  total={pagination.total_pages}
                  current={pagination.current_page}
                  display={3}
                  onChange={this.onPageChange}
                  totalElement={pagination.total}
                  elementNumber={pagination.count}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(observer(GestionePratiche));
