import CallManager from './NewApiCallManager';

export default {
  getSharedByMe: ({ department, pageNumber }, onSuccess, onError) => {
    CallManager.get(
      `/sharing/${department}/mine`,
      response => onSuccess(response.data),
      onError,
      {
        pageNumber,
      }
    );
  },
  getSharedWithMe: ({ department, pageNumber }, onSuccess, onError) => {
    CallManager.get(
      `/sharing/${department}/shared`,
      response => onSuccess(response.data),
      onError,
      { pageNumber }
    );
  },
  share: (
    { instanceId, ownerDepartment, destDepartment, subject },
    onSuccess,
    onError
  ) => {
    CallManager.post(
      `/sharing/instance`,
      response => onSuccess(response.data),
      onError,
      {
        instanceId,
        ownerDepartment,
        destDepartment,
        subject,
      }
    );
  },
  getInstanceShares: (instanceId, department, onSuccess, onError) => {
    CallManager.get(
      `/sharing/${department}/mine/${instanceId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  getInstanceDetails: (instanceId, onSuccess, onError) => {
    CallManager.get(
      `/sharing/instance/${instanceId}/detail`,
      response => onSuccess(response.data),
      onError
    );
  },
  reply: (instanceId, { department, notes, documents }, onSuccess, onError) => {
    CallManager.post(
      `/sharing/instance/${instanceId}/message`,
      response => onSuccess(response.data),
      onError,
      {
        department,
        notes,
        documents,
      }
    );
  },
  close: (instanceId, onSuccess, onError) => {
    CallManager.delete(
      `/sharing/instance/${instanceId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  assign: (instanceId, userUuid, onSuccess, onError) => {
    CallManager.post(
      `/sharing/instance/${instanceId}/assign`,
      response => onSuccess(response.data),
      onError,
      {
        assignTo: userUuid,
      }
    );
  },
  downloadDocument: (
    instanceId,
    documentId,
    documentName,
    onSuccess,
    onError
  ) => {
    CallManager.downloadFile(
      `/sharing/instance/${instanceId}/document/${documentId}`,
      onSuccess,
      onError,
      documentName
    );
  },
};
