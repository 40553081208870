import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Dialog,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Loader from './Loader';

const ConfirmDialog = props => {
  const { open, title, message, onConfirm, onCancel, loading } = props;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Loader size={30} loading={loading} />
          {!loading && message}
        </DialogContentText>
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button style={{ marginRight: '10px' }} onClick={onCancel} autoFocus>
            Annulla
          </Button>
          <Button onClick={onConfirm} variant="raised" color="primary">
            Conferma
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ConfirmDialog.defaultProps = {
  open: false,
  onCancel: () => {},
};

export default ConfirmDialog;
