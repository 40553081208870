import React from 'react';
import { StyledComponentProps, withStyles } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import cx from 'classnames';

interface AssigneeItemProps extends StyledComponentProps {
  firstName: string;
  lastName: string;
}

const styles = (theme: any) => ({
  container: {
    flex: 1,
    flexDirection: 'row!important',
    display: 'flex',
    alignItems: 'center!important',
  },
  selected: {
    backgroundColor: '#F0F0F0',
  },
  avatar: {
    color: '#fff',
    height: 32,
    width: 32,
    fontSize: 16,
    backgroundColor: theme.palette.primary.main,
    marginRight: 8,
  },
  user: {
    fontFamily: theme.typography.fontFamily,
  },
});

const AssigneeItem: React.FC<AssigneeItemProps> = ({
  classes = {},
  firstName,
  lastName,
}) => {
  return (
    <div className={cx(classes.container)}>
      <Avatar className={classes.avatar}>{firstName[0] + lastName[0]}</Avatar>
      <span className={classes.user}>{`${firstName} ${lastName}`}</span>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(AssigneeItem);
