import CallManager from './AxiosCallManager';

export default {
  getProcedureList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      '/departments/edilizia/procedures',
      response => onSuccess(response.data),
      onError,
      { page: page, ...filters }
    );
  },
  get: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `/departments/edilizia/procedures/${procedureId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  getDocuments: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `/departments/edilizia/procedures/${procedureId}/document`,
      response => onSuccess(response.data),
      onError
    );
  },
  getEvents: (procedureId, { page }, onSuccess, onError) => {
    CallManager.get(
      `/departments/edilizia/procedures/${procedureId}/events`,
      response => onSuccess(response.data),
      onError,
      { page: page }
    );
  },
  downloadDocument: (procedureId, documentId, onFinished, onError) => {
    CallManager.downloadFile(
      `/departments/edilizia/procedures/${procedureId}/document/${documentId}`,
      onFinished,
      onError
    );
  },
  uploadDocument: (procedureId, contents, onSuccess, onError) => {
    const data = new FormData();
    data.append('file', contents);
    data.append('name', contents.name);
    CallManager.post(
      `/departments/edilizia/procedures/${procedureId}/document`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  getSharedTo: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `/departments/edilizia/procedures/${procedureId}/shared_to`,
      response => onSuccess(response.data),
      onError
    );
  },
  process: (procedureId, onSuccess, onError, protocolNumber) => {
    CallManager.patch(
      `/departments/edilizia/procedures/${procedureId}/process`,
      response => onSuccess(response.data),
      onError,
      { protocol_number: protocolNumber }
    );
  },
  approve: (procedureId, onSuccess, onError, files) => {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append(`files[${i}]`, files[i]);
    }
    CallManager.post(
      `/departments/edilizia/procedures/${procedureId}/approve`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  reject: (procedureId, onSuccess, onError) => {
    CallManager.patch(
      `/departments/edilizia/procedures/${procedureId}/reject`,
      response => onSuccess(response.data),
      onError
    );
  },
  requestDocuments: (procedureId, documents, onSuccess, onError) => {
    CallManager.patch(
      `/departments/edilizia/procedures/${procedureId}/request_documents`,
      response => onSuccess(response.data),
      onError,
      { documents: documents }
    );
  },
  getExtensions: (procedureId, onSuccess, onError) => {
    CallManager.get(
      `/departments/edilizia/procedures/${procedureId}/extensions`,
      response => onSuccess(response.data.extensions),
      onError
    );
  },
  getExtension: (procedureId, extensionId, onSuccess, onError) => {
    CallManager.get(
      `departments/edilizia/procedures/${procedureId}/extensions/${extensionId}`,
      response => onSuccess(response.data),
      onError
    );
  },
  approveExtension: (procedureId, extensionId, onSuccess, onError) => {
    CallManager.patch(
      `/departments/edilizia/procedures/${procedureId}/extensions/${extensionId}`,
      response => onSuccess(response.data),
      onError,
      { action: 'APPROVED' }
    );
  },
  rejectExtension: (procedureId, extensionId, onSuccess, onError) => {
    CallManager.patch(
      `/departments/edilizia/procedures/${procedureId}/extensions/${extensionId}`,
      response => onSuccess(response.data),
      onError,
      { action: 'REJECTED' }
    );
  },
};
