import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const styles = theme => ({
  personContainer: {
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid #eee',
    padding: '15px',
    marginRight: '5px',
    marginBottom: '5px',
    minWidth: '250px',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid ' + theme.palette.primary.main,
    },
  },
  personIcon: {
    color: theme.palette.secondary.main,
    fontSize: '24px',
    height: '100%',
    marginRight: '15px',
    textAlign: 'center',
  },
  personDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  personDetailsHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  personDetailsName: {
    fontSize: '16px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: '2px',
  },
  personDetailsType: {
    fontSize: '12px',
    color: '#555',
    fontWeight: '400',
  },
  personDetailBody: {
    listStyle: 'none',
    padding: '0',
  },
  personDetailBodyItem: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  personDetailsBodyIcon: {
    fontSize: '20px',
    color: theme.palette.secondary.main,
    marginRight: '10px',
  },
  personDetailsBodyValue: {
    height: '100%',
    lineHeight: '100%',
  },
});

const PersonCard = withStyles(styles)(props => {
  const {
    classes,
    person,
    personType,
    isExpert,
    isLegacy = false,
    onPersonClicked,
    additionalInfo = '',
  } = props;

  const onPersonClick = useCallback(() => {
    onPersonClicked(person);
  }, [person, onPersonClicked]);

  return (
    <div className={classes.personContainer} onClick={onPersonClick}>
      <div className={classes.personIcon}>
        {isExpert ? (
          <i className="nc-icon nc-badge" />
        ) : person.gender === 'G' ? (
          <i className="nc-icon nc-bag" />
        ) : (
          <i className="nc-icon nc-single-02" />
        )}
      </div>
      <div className={classes.personDetailsContainer}>
        <div className={classes.personDetailsHeader}>
          {!isLegacy ? (
            <>
              <span className={classes.personDetailsName}>
                {personType === 'business' ? person.name : person.full_name}{' '}
                {additionalInfo}
              </span>
              <span className={classes.personDetailsType}>
                {personType === 'business'
                  ? 'Persona Giuridica'
                  : 'Persona Fisica'}
              </span>
            </>
          ) : (
            <>
              <span className={classes.personDetailsName}>
                {person.full_name} {additionalInfo}
              </span>
              <span className={classes.personDetailsType}>
                {personType === 'G' ? 'Persona Giuridica' : 'Persona Fisica'}
              </span>
            </>
          )}
        </div>
        <div>
          <ul className={classes.personDetailBody}>
            <li className={classes.personDetailBodyItem}>
              <i
                className={classNames(
                  classes.personDetailsBodyIcon,
                  'nc-icon',
                  'nc-email-85'
                )}
              />
              <span className={classes.personDetailsBodyValue}>
                {person.email ? (
                  <a href={'mailto:' + person.email}>{person.email}</a>
                ) : (
                  'N/A'
                )}
              </span>
            </li>
            <li className={classes.personDetailBodyItem}>
              <i
                className={classNames(
                  classes.personDetailsBodyIcon,
                  'nc-icon',
                  'nc-pin-3'
                )}
              />
              {!isLegacy ? (
                <span className={classes.personDetailsBodyValue}>
                  {person.address
                    ? `${person.address.street_name}, ${person.address.postcode} ${person.address.city} ${person.address.county_code}`
                    : 'N/A'}
                </span>
              ) : (
                <span className={classes.personDetailsBodyValue}>
                  {person.address}
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

PersonCard.propTypes = {
  classes: PropTypes.object,
  person: PropTypes.object.isRequired,
  isExpert: PropTypes.bool,
};

PersonCard.defaultProps = {
  isExpert: false,
};

export default PersonCard;
