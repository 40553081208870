/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExpenseTypeDTO {
    AmministrazioneCondominiale = 'AMMINISTRAZIONE_CONDOMINIALE',
    Riscaldamento = 'RISCALDAMENTO',
    SpeseSanitarieNonCoperteDaSsn = 'SPESE_SANITARIE_NON_COPERTE_DA_SSN',
    SpeseAssistenziali = 'SPESE_ASSISTENZIALI'
}

export function ExpenseTypeDTOFromJSON(json: any): ExpenseTypeDTO {
    return ExpenseTypeDTOFromJSONTyped(json, false);
}

export function ExpenseTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpenseTypeDTO {
    return json as ExpenseTypeDTO;
}

export function ExpenseTypeDTOToJSON(value?: ExpenseTypeDTO | null): any {
    return value as any;
}

