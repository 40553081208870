import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Chip, Input, withStyles } from '@material-ui/core';
import PraticheInterne from '../../../../api/PraticheInterne';
import store from '../../../../store';
import EmptyListMessage from '../../../common/EmptyListMessage';
import PropTypes from 'prop-types';
import { DialogContentText } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import Loader from '../../../common/Loader';
import useDebounce from '../../../../utils/hooks';
import { getDepartmentLabel } from '../../../../constants/common';

const styles = theme => ({
  selectedDepartments: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
  department: {
    marginRight: '5px',
    marginBottom: '5px',
    position: 'relative',
  },
  results: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    listStyle: 'none',
    marginBottom: '10px',
    padding: 0,
  },
  resultItem: {
    border: '1px solid ' + theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    padding: '10px 20px',
    borderRadius: '1px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
  resultsContainer: {
    width: '100%',
    marginTop: '20px',
    minHeight: '200px',
    maxHeight: '200px',
    overflow: 'auto',
  },
});

const SelectDepartmentStep = props => {
  const {
    classes,
    procedure,
    department,
    message,
    onDepartmentSelected,
    onDepartmentRemoved,
  } = props;
  const { snackBar, profile } = store.app;
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(query, 500);

  const search = useCallback(
    query => {
      setLoading(true);
      const filters = {
        query,
        ...(procedure ? { procedure: procedure.id } : {}),
        exclude: [
          profile.operatingAs.department,
          ...(department ? [department] : []),
        ],
      };
      PraticheInterne.availableDepartments(
        { filters },
        departments => {
          setResults(departments);
          setLoading(false);
        },
        () => {
          setResults([]);
          setLoading(false);
          snackBar.open(
            'Si è verificato un errore durante il caricamento dei dipartimenti'
          );
        }
      );
    },
    [department, procedure, profile.operatingAs.department, snackBar]
  );

  useEffect(() => {
    search(debouncedSearchTerm);
  }, [debouncedSearchTerm, search]);

  const onQueryChanged = useCallback(event => {
    setQuery(event.target.value);
  }, []);

  const onDepartmentClicked = useCallback(
    department => {
      onDepartmentSelected(department);
    },
    [onDepartmentSelected]
  );

  const searchResults = useMemo(() => {
    return results.filter(r => {
      return r !== department;
    });
  }, [results, department]);

  return (
    <div>
      <DialogContentText>{message}</DialogContentText>
      <FormControl style={{ width: '100%', marginTop: '30px' }}>
        <InputLabel htmlFor="search" shrink={true}>
          Cerca Dipartimento
        </InputLabel>
        <Input
          autoFocus={true}
          placeholder="Inizia a digitare..."
          id="search"
          value={query}
          onChange={onQueryChanged}
        />
      </FormControl>
      {department && (
        <div className={classes.selectedDepartments}>
          <Chip
            key={department.name}
            className={classes.department}
            label={getDepartmentLabel(department)}
            onDelete={() => onDepartmentRemoved()}
            color="primary"
          />
        </div>
      )}
      <div className={classes.resultsContainer}>
        <Loader loading={loading} color="primary" />
        {!loading && searchResults.length > 0 && (
          <ul className={classes.results}>
            {searchResults.map(r => (
              <li
                className={classes.resultItem}
                key={r}
                onClick={() => onDepartmentClicked(r)}
              >
                {getDepartmentLabel(r)}
              </li>
            ))}
          </ul>
        )}
        <EmptyListMessage
          visible={!loading && !results.length}
          message="Nessun dipartimento trovato"
        />
      </div>
    </div>
  );
};

SelectDepartmentStep.propTypes = {
  message: PropTypes.string.isRequired,
  department: PropTypes.string,
  onDepartmentSelected: PropTypes.func.isRequired,
  onDepartmentRemoved: PropTypes.func.isRequired,
};

export default withStyles(styles)(SelectDepartmentStep);
