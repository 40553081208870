import CallManager from './AxiosCallManager';

export default {
  getCountries: (onSuccess, onError) => {
    CallManager.get(
      '/countries',
      response => onSuccess(response.data),
      onError
    );
  },
  getCountyList: (onSuccess, onError) => {
    CallManager.get('/province', response => onSuccess(response.data), onError);
  },
  getMunicipalities: (provinceCode, onSuccess, onError) => {
    CallManager.get(
      `/province/${provinceCode}/cities`,
      response => onSuccess(response.data),
      onError
    );
  },
  getProfessionalTitles: (onSuccess, onError) => {
    CallManager.get(
      '/professional_titles',
      response => onSuccess(response.data),
      onError
    );
  },
};
