import React from 'react';
import { withStyles } from '@material-ui/core';
import DetailElement from '../../common/DetailElement';
import classNames from 'classnames';
import Subsection from '../../common/Subsection';
import { Col, Row } from 'react-flexbox-grid';
import PersonCard from '../../common/PersonCard';
import {
  TRASLOCHI_LAVORI_THROUGH,
  getLabelTraslochiLavoriThrough,
} from '../../../constants/common';
import {
  GeoJSON,
  MapContainer as Map,
  TileLayer,
  Tooltip,
} from 'react-leaflet';

const styles = () => ({
  simpleLabelValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

export const TraslochiLavoriDetailList = withStyles(styles)(props => {
  const { procedure } = props;

  const through =
    procedure.details.through !== TRASLOCHI_LAVORI_THROUGH.THROUGH_STOPOVER
      ? `${getLabelTraslochiLavoriThrough(procedure.details.through)} - ${
          procedure.details.through_details
        }`
      : getLabelTraslochiLavoriThrough(procedure.details.through);

  return (
    <>
      <DetailElement
        label="Oggetto"
        value={procedure.subject}
        icon="nc-icon nc-email-83"
        separator
      />
      <DetailElement
        label="Ubicazione"
        value={procedure.details.address}
        icon="nc-icon nc-square-pin"
        separator
      />
      <DetailElement
        label="Al Fine di"
        value={procedure.details.reason}
        icon="nc-icon nc-explore-2"
        separator
      />
      <DetailElement
        label="Mediante"
        value={through}
        icon="nc-icon nc-delivery-fast"
        separator
      />
    </>
  );
});

export const TraslochiLavoriDetail = withStyles(styles)(props => {
  const { procedure, classes } = props;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <i
            className={classNames(classes.headerIcon, 'nc-icon nc-settings-90')}
          />
          <span className={classes.headerTitle}>Dettagli</span>
        </div>
        <Subsection>
          <Row>
            <LabelValue
              label="Dimensioni"
              value={
                <AreaFormula
                  length={procedure.details.length}
                  width={procedure.details.width}
                  total={procedure.details.square_meters}
                />
              }
            />
            <LabelValue label="Dal" value={procedure.details.start_date} />
            <LabelValue label="Al" value={procedure.details.end_date} />
            <LabelValue
              label="Orario"
              value={`${procedure.details.start_time} - ${procedure.details.end_time}`}
            />
            <LabelValue
              label="Durata Complessiva"
              value={`${procedure.details.total_duration} giorni`}
            />
            {procedure.details.supplier && (
              <Col xs={12} className={classes.simpleLabelValueContainer}>
                <span className={classes.simpleLabel}>Ditta Affidataria</span>
                <span className={classes.simpleValue}>
                  <PersonCard
                    person={procedure.details.supplier}
                    personType="business"
                  />
                </span>
              </Col>
            )}
          </Row>
        </Subsection>
      </div>

      {procedure.details.affected_area && (
        <div className={classes.container}>
          <div className={classes.header}>
            <i
              className={classNames(
                classes.headerIcon,
                'nc-icon nc-square-pin'
              )}
            />
            <span className={classes.headerTitle}>Area Interessata</span>
          </div>
          <Subsection>
            <Row>
              <Col xs={12}>
                <Map
                  style={{ height: '300px' }}
                  zoom={14}
                  center={[44.316225, 9.323962]}
                >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <GeoJSON
                    style={() => {
                      return {
                        color: '#174f52',
                        width: 2,
                        fill: true,
                        fillColor: '#174f52',
                        fillOpacity: 0.4,
                      };
                    }}
                    data={procedure.details.affected_area}
                  >
                    <Tooltip>Area Interessata</Tooltip>
                  </GeoJSON>
                </Map>
              </Col>
            </Row>
          </Subsection>
        </div>
      )}
    </>
  );
});

const LabelValue = withStyles(styles)(props => {
  const { classes, label, value } = props;

  return (
    <Col md={3} sm={6} xs={12} className={classes.simpleLabelValueContainer}>
      <span className={classes.simpleLabel}>{label}</span>
      <span className={classes.simpleValue}>{value}</span>
    </Col>
  );
});

const AreaFormula = props => {
  const { length, width, total } = props;

  return (
    <span>
      {length} m &times; {width} m ({total} m<sup>2</sup>)
    </span>
  );
};
