import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ReportProblem as ErrorIcon } from '@material-ui/icons';
import { Input } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Check as SaveIcon } from '@material-ui/icons';
import { Close as CancelIcon } from '@material-ui/icons';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  Tooltip,
  FormControl,
  FormHelperText,
  withStyles,
} from '@material-ui/core';

const styles = theme => ({
  formControl: {
    width: '100%',
    marginTop: '5px',
  },
  editableFieldContainer: {
    width: '100%',
    minHeight: '56px',
    alignItems: 'center',
    padding: '20px',
    margin: '0',
    borderBottom: '1px solid #eee',
  },
  editableFieldLabel: {
    display: 'block',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  editableFieldValue: {
    padding: '0 20px',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  editableFieldActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  editableFieldInitialError: {
    color: theme.palette.error.main,
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
}))(Tooltip);

const EditableField = withStyles(styles)(props => {
  const [inputValue, setInputValue] = useState(props.value);
  const [value, setValue] = useState(props.value);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    classes,
    name,
    label,
    onValueChange,
    validateInput,
    renderValue,
    required,
    inputProps,
    labelProps,
    onEditing,
    disabled,
    initialError,
    initialErrorMessage,
  } = props;

  const onEdit = useCallback(() => {
    setEditMode(true);
    onEditing(name);
  }, [name, onEditing]);

  const onSave = useCallback(() => {
    const validationResult = validateInput(name, inputValue);
    if (!inputValue.trim() && required) {
      setError(true);
      setErrorMessage('Questo campo è obbligatorio');
    } else if (!validationResult.error) {
      onValueChange(name, inputValue);
      setValue(inputValue);
      setEditMode(false);
    } else {
      setError(true);
      setErrorMessage(validationResult.message);
    }
  }, [name, onValueChange, inputValue, required, validateInput]);

  const onChange = useCallback(event => {
    setInputValue(event.target.value);
    setError(false);
    setErrorMessage(null);
  }, []);

  const onCancel = useCallback(() => {
    setEditMode(false);
    setInputValue(value);
    onValueChange(name, value);
    setErrorMessage(null);
    setError(false);
  }, [name, onValueChange, value]);

  return (
    <Row className={classes.editableFieldContainer}>
      <Col xs={10} className={classes.editableFieldContent}>
        <span className={classes.editableFieldLabel} {...labelProps}>
          {label}
        </span>
        {!editMode ? (
          <div className={classes.editableFieldValue}>
            {!initialError ? (
              renderValue(name, value)
            ) : (
              <span className={classes.editableFieldInitialError}>
                <ErrorIcon style={{ marginRight: '10px' }} />{' '}
                {initialErrorMessage}
              </span>
            )}
          </div>
        ) : (
          <FormControl className={classes.formControl} error={error}>
            <Input
              autoFocus
              placeholder={label}
              id={name}
              value={inputValue}
              onChange={onChange}
              {...inputProps}
            />
            {error && <FormHelperText>{errorMessage}</FormHelperText>}
          </FormControl>
        )}
      </Col>
      <Col xs={2} className={classes.editableFieldActions}>
        {editMode ? (
          <>
            <BiggerTooltip title="Salva Modifiche" className={classes.tooltip}>
              <Button
                size="small"
                variant="raised"
                color="primary"
                className={classes.smallActionButton}
                onClick={onSave}
              >
                <SaveIcon className={classes.smallActionButtonIcon} />
              </Button>
            </BiggerTooltip>
            <BiggerTooltip title="Annulla" className={classes.tooltip}>
              <Button
                size="small"
                variant="raised"
                color="default"
                className={classes.smallActionButton}
                onClick={onCancel}
              >
                <CancelIcon className={classes.smallActionButtonIcon} />
              </Button>
            </BiggerTooltip>
          </>
        ) : (
          <BiggerTooltip title="Modifica" className={classes.tooltip}>
            <Button
              disabled={disabled}
              size="small"
              variant="raised"
              color="primary"
              className={classes.smallActionButton}
              onClick={onEdit}
            >
              <EditIcon className={classes.smallActionButtonIcon} />
            </Button>
          </BiggerTooltip>
        )}
      </Col>
    </Row>
  );
});

EditableField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  validateInput: PropTypes.func,
  renderValue: PropTypes.func,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  disabled: PropTypes.bool,
  onEditing: PropTypes.func,
  initialError: PropTypes.bool,
  initialErrorMessage: PropTypes.string,
};

EditableField.defaultProps = {
  value: '',
  validateInput: () => {
    return { error: false, message: '' };
  },
  renderValue: (name, value) => {
    return value;
  },
  required: false,
  inputProps: {},
  labelProps: {},
  disabled: false,
  onEditing: () => {},
  initialError: false,
};

export default EditableField;
