import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { getLabelShareState, SHARE_STATUS } from '../../constants/common';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
  },
  stateResolved: {
    backgroundColor: '#0277bd',
  },
  stateActive: {
    backgroundColor: '#ffb300',
  },
  stateClosed: {
    backgroundColor: '#00600f',
  },
});

const ShareStatus = withStyles(styles)(props => {
  const { classes, state } = props;

  return (
    <span
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateActive]: state === SHARE_STATUS.ACTIVE,
        [classes.stateResolved]: state === SHARE_STATUS.RESOLVED,
        [classes.stateClosed]: state === SHARE_STATUS.CLOSED,
      })}
    >
      {getLabelShareState(state)}
    </span>
  );
});

ShareStatus.propTypes = {
  state: PropTypes.string.isRequired,
};

export default ShareStatus;
