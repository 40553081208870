import React, { useCallback, useState } from 'react';
import { Interview } from '../../../api/model';
import { StyledComponentProps } from '@material-ui/core';
import { DetailField } from '../../../detail/layout/Detail/common/DetailField';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { SECTION_LABEL_MAP } from '../../../constants';
import Document from '../../../../virtualDesk/Common/Document';
import { fileExtension } from '../../../../../utils/string';
import { getDocument } from '../../../api/api';
import store from '../../../../../store';

interface ActivityDetailsProps extends StyledComponentProps {
  interview: Interview;
}

const styles = (theme: any) => ({
  container: {
    boxShadow: '0px 0px 24px rgba(92, 103, 113, 0.05)',
    backgroundColor: theme.palette.white.main,
    borderRadius: '4px',
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  titleSection: {
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: '20px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
  },
  fieldContainer: {
    margin: '24px 0px',
    '& > div': {
      width: '30%',
    },
    '& > div:not(:last-child)': {
      marginRight: '24px',
    },
  },
  notes: {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  notesLabel: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: theme.palette.black.main,
  },
  noteBody: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.grey.dark,
  },
  documents: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  documentsSeparator: {
    margin: '0 24px',
    height: 1,
    borderTop: '1px solid #ddd',
  },
});

const CitizenSummary: React.FC<ActivityDetailsProps> = ({
  classes = {},
  interview,
}) => {
  const { snackBar } = store.app;
  const [downloadingDocument, setDownloadingDocument] = useState();

  const onDocumentClicked = useCallback(
    documentId => {
      setDownloadingDocument(documentId);
      const document = interview.documents?.find(d => d.fid === documentId);
      if (!document) {
        snackBar.open('Si è verificato un errore durante il download del file');
        return;
      }
      getDocument(documentId, document.name)
        .catch(() => {
          snackBar.open(
            'Si è verificato un errore durante il download del file'
          );
        })
        .finally(() => setDownloadingDocument(undefined));
    },
    [interview.documents, snackBar]
  );

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <label className={classes.title}>Attività</label>
      </div>
      <div className={classes.fieldContainer}>
        <DetailField
          label="Inizio Attività"
          value={moment(interview.activityDuration.startDate).format(
            'DD/MM/YYYY [alle] HH:mm'
          )}
        />
        <DetailField
          label="Fine Attività"
          value={moment(interview.activityDuration.endDate).format(
            'DD/MM/YYYY [alle] HH:mm'
          )}
        />
        <DetailField
          label="Sezione"
          value={SECTION_LABEL_MAP[interview.section]}
        />
      </div>
      <div className={classes.notes}>
        <span className={classes.notesLabel}>Note</span>
        <span className={classes.notesBody}>{interview.note ?? '---'}</span>
      </div>
      {interview.documents && (
        <div className={classes.documents}>
          {interview.documents.map(doc => (
            <Document
              key={doc.fid}
              documentId={doc.fid}
              fileName={doc.name}
              extension={fileExtension(doc.name)}
              date={moment(doc.createdAt).format('DD/MM/YYYY [alle] HH:mm')}
              onClick={onDocumentClicked}
              isDownloading={downloadingDocument === doc.fid}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(CitizenSummary);
