import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import tableStyle from '../../styles/table.style';
import Loader from '../common/Loader';
import Departments from '../../api/Dipartimenti';
import ROUTES from '../../constants/routes';
import EmptyTableMessage from '../common/EmptyTableMessage';
import { getDepartmentLabel, getRoleLabel } from '../../constants/common';
import Subsection from '../common/Subsection';
import DepartmentIcon from '../../new/components/icons/departments';
import Icon from '../../new/components/icons';
import { Row } from 'react-flexbox-grid';
import { getDepartmentSectorLabel } from '../../constants/common';
import { HorizontalLabelValue } from '../common/HorizontalLabelValue';
import { Button } from '@material-ui/core';

const styles = theme => ({
  tabs: {
    marginTop: '30px',
  },
  card: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

class DettaglioGruppo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingUtenti: false,
      utenti: [],
      utentiPagination: null,
    };
  }

  componentDidMount() {
    this.loadDipendenti({});
  }

  loadDipendenti = ({ page = 1 }) => {
    const department = this.props.match.params.idGruppo;
    this.setState({ loadingUtenti: true }, () => {
      Departments.getDepartmentUsers(
        department,
        { page: page },
        response => {
          this.setState({
            utenti: response.users,
            utentiPagination: response.meta ? response.meta.pagination : null,
            loadingUtenti: false,
          });
        },
        () => {
          this.setState({ loadingUtenti: false });
        }
      );
    });
  };

  render() {
    const department = this.props.match.params.idGruppo;
    const { classes } = this.props;

    return (
      <PageContainer
        header={
          <PageTitle
            title="Dettagli Ufficio"
            subtitle={getDepartmentLabel(department)}
            backIcon={true}
          />
        }
      >
        <div className={classes.details}>
          <div className={classes.card}>
            <div className={classes.header}>
              <DepartmentIcon
                size={20}
                department={department}
                className={classes.headerIcon}
              />
              <span className={classes.headerTitle}>DETTAGLI</span>
            </div>
            <Subsection>
              <Row>
                <HorizontalLabelValue
                  label="Settore"
                  value={getDepartmentSectorLabel(department)}
                  xs={12}
                  sm={12}
                  md={12}
                />
              </Row>
            </Subsection>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.header}>
            <Icon type="Users" className={classes.headerIcon} />
            <span className={classes.headerTitle}>PERSONALE</span>
          </div>
          <Subsection>
            <TablePersone
              department={department}
              loading={this.state.loadingUtenti}
              utenti={this.state.utenti}
            />
          </Subsection>
        </div>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(observer(withRouter(DettaglioGruppo)));

const TablePersone = withRouter(
  withStyles(tableStyle)(props => {
    const { classes, department } = props;
    return (
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headRow}>
              <TableCell className={classes.headCell}>Nome</TableCell>
              <TableCell className={classes.headCell}>Ruolo</TableCell>
              <TableCell className={classes.headCell}>Aggiunto il</TableCell>
              <TableCell className={classes.headCell} />
            </TableRow>
          </TableHead>
          {!props.loading && (
            <TableBody>
              {props.utenti &&
                props.utenti.map(p => {
                  return (
                    <TableRow key={p.id} className={classes.bodyRow}>
                      <TableCell>
                        {p.first_name} {p.last_name}
                      </TableCell>
                      <TableCell>
                        {getRoleLabel(
                          p.roles.find(r => r.department === department).role,
                          department
                        )}
                      </TableCell>
                      <TableCell>{p.created_at}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        <Button
                          size="small"
                          variant="raised"
                          color="primary"
                          onClick={() => {
                            props.history.push(`/${ROUTES.UTENTI}/${p.id}`);
                          }}
                        >
                          Visualizza
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
        <Loader size={40} loading={props.loading} />
        <EmptyTableMessage
          visible={
            !props.utenti || (props.utenti.length === 0 && !props.loading)
          }
        />
      </div>
    );
  })
);

TablePersone.propTypes = {
  loading: PropTypes.bool,
};
