import React, { FC } from 'react';
import { DashboardItem, DashBoardItemProps } from './DashboardItem';
import { Col, Row } from 'react-flexbox-grid';
import { StyleRules } from '@material-ui/core/styles';
import { StyledComponentProps, withStyles } from '@material-ui/core';

const styles = (_theme: any): StyleRules => ({
  marginBottom: {
    marginBottom: '20px',
  },
});

export interface DashboardListProps {
  items: DashBoardItemProps[];
}

const DashboardList: FC<DashboardListProps & StyledComponentProps> = ({
  classes = {},
  items,
}) => {
  return (
    <Row>
      {items.map(elem => {
        return (
          <Col
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={elem.title}
            className={classes.marginBottom}
          >
            <DashboardItem {...elem} />
          </Col>
        );
      })}
    </Row>
  );
};
const DecoratedDashboard = withStyles(styles)(DashboardList);

export { DecoratedDashboard as DashboardList };
