import { StateMachine } from 'redux-sigma';
import {
  beneficairyNeeded,
  beneficiaryKnown,
  errorCreating,
  needsPersonalSituation,
  NewSocialServiceEvents,
  procedureCreated,
  skipPersonalSituation,
  UserLoadIseeType,
  UserSelectBeneficiaryType,
  UserSelectExpensesType,
  UserSelectFamilyMembersType,
  UserSelectKindType,
  UserSelectOtherBenefitsType,
  UserSelectPersonalSituation,
  UserSelectRelativeMembersType,
  UserSelectRequestorType,
  UserSelectRequestorTypeType,
  UserSelectSectionType,
} from './newProcedure.events';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import { createLocalStateMachineContext } from '@moveax/redux-stm-act-react-utils';
import { useContext } from 'react';
import {
  Citizen,
  CitizenContext,
  defaultCitizenContext,
  FamilyMember,
  PersonalSituation,
  TemporaryDocument,
} from '../../components/serviziSociali/api/model';
import {
  createInstance,
  getCitizenContext,
} from '../../components/serviziSociali/api/api';
import { CommonEvents } from './common.events';
import {
  BenefitDTO,
  FlagTypeDTO,
  FlagValueDTO,
  UndeductedExpenseDTO,
  WelfareInstanceCreationRequest,
  WelfareInstanceSectionDTO,
  WelfareInstanceTypeDTO,
  WelfareModuleTypeDTO,
  WelfareRequestorTypeDTO,
} from '../../gen/api/welfare';

export enum NewSocialServiceStates {
  selectingKind = 'states/selectingKind',
  selectingFamilyMembers = 'states/selectingFamilyMembers',
  selectingIsee = 'states/selectingIsee',
  selectingRelativeMembers = 'states/selectingRelativeMembers',
  selectingRequestor = 'states/selectingRequestor',
  selectingRequestorType = 'states/selectingRequestorType',
  selectingBeneficiary = 'states/selectingBeneficiary',
  selectingPersonalSituation = 'states/selectingPersonalSituation',
  selectingOtherBenefits = 'states/selectingOtherBenefits',
  selectingExpenses = 'states/selectingExpenses',
  submitting = 'states/submitting',
  error = 'states/error',
  success = 'states/success',
}

export interface Context {
  id?: string;
  actualForm: { [key: string]: unknown };
  defaults: CitizenContext;
  section?: WelfareInstanceSectionDTO;
  kind?: WelfareInstanceTypeDTO;
  requestor?: Citizen;
  requestorType?: {
    type: WelfareRequestorTypeDTO;
    documents: TemporaryDocument[];
  };
  beneficiary?: Citizen;
  flags?: PersonalSituation;
  familyMembers?: FamilyMember[];
  relativeMembers?: FamilyMember[];
  isee?: {
    value: string;
    document: TemporaryDocument;
  };
  otherBenefits?: BenefitDTO[];
  expenses?: UndeductedExpenseDTO[];
}

export class NewSocialServiceStm extends StateMachine<
  NewSocialServiceEvents & CommonEvents,
  NewSocialServiceStates,
  string,
  Context
> {
  protected readonly initialState = NewSocialServiceStates.selectingKind;

  readonly name = 'newSocialService';

  protected readonly spec = {
    [NewSocialServiceStates.selectingKind]: {
      transitions: {
        [CommonEvents.userPressedGoOn]: {
          target: NewSocialServiceStates.selectingRequestor,
          command: this.saveKind,
        },
      },
      reactions: {
        [NewSocialServiceEvents.userSelectKind]: this.storeKind,
        [NewSocialServiceEvents.userSelectSection]: this.storeSection,
      },
    },
    [NewSocialServiceStates.selectingRequestor]: {
      transitions: {
        [NewSocialServiceEvents.userSelectRequestor]: {
          target: NewSocialServiceStates.selectingRequestorType,
          command: this.storeRequestor,
        },
        [CommonEvents.userPressedBack]: NewSocialServiceStates.selectingKind,
      },
    },
    [NewSocialServiceStates.selectingRequestorType]: {
      transitions: {
        [NewSocialServiceEvents.beneficiaryNeeded]:
          NewSocialServiceStates.selectingBeneficiary,
        [NewSocialServiceEvents.needsPersonalSituation]:
          NewSocialServiceStates.selectingPersonalSituation,
        [NewSocialServiceEvents.skipPersonalSituation]:
          NewSocialServiceStates.selectingFamilyMembers,
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingRequestor,
      },
      reactions: {
        [NewSocialServiceEvents.userSelectRequestorType]: this.storeRequestType,
        [NewSocialServiceEvents.beneficiaryKnown]: this
          .checkIfPersonalSituationIsNeeded,
      },
    },
    [NewSocialServiceStates.selectingBeneficiary]: {
      transitions: {
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingRequestorType,
        [NewSocialServiceEvents.needsPersonalSituation]:
          NewSocialServiceStates.selectingPersonalSituation,
        [NewSocialServiceEvents.skipPersonalSituation]:
          NewSocialServiceStates.selectingFamilyMembers,
      },
      reactions: {
        [NewSocialServiceEvents.userSelectBeneficiary]: this.storeBeneficiary,
      },
    },
    [NewSocialServiceStates.selectingPersonalSituation]: {
      transitions: {
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingRequestorType,
        [NewSocialServiceEvents.userSelectPersonalSituation]: {
          target: NewSocialServiceStates.selectingFamilyMembers,
          command: this.storePersonalSituation,
        },
      },
    },
    [NewSocialServiceStates.selectingFamilyMembers]: {
      transitions: {
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingRequestorType,
        [NewSocialServiceEvents.userSelectFamilyMembers]: {
          target: NewSocialServiceStates.selectingIsee,
          command: this.saveFamilyMembers,
        },
      },
    },
    [NewSocialServiceStates.selectingIsee]: {
      transitions: {
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingFamilyMembers,
        [NewSocialServiceEvents.userSkipStep]:
          NewSocialServiceStates.selectingOtherBenefits,
        [NewSocialServiceEvents.userLoadIsee]: {
          target: NewSocialServiceStates.selectingOtherBenefits,
          command: this.saveIsee,
        },
      },
    },
    [NewSocialServiceStates.selectingOtherBenefits]: {
      transitions: {
        [NewSocialServiceEvents.userSelectOtherBenefits]: {
          target: NewSocialServiceStates.selectingExpenses,
          command: this.saveOtherBenefits,
        },
        [CommonEvents.userPressedBack]: NewSocialServiceStates.selectingIsee,
      },
    },
    [NewSocialServiceStates.selectingExpenses]: {
      transitions: {
        [NewSocialServiceEvents.userSelectExpenses]: {
          target: NewSocialServiceStates.selectingRelativeMembers,
          command: this.saveExpenses,
        },
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingOtherBenefits,
      },
    },
    [NewSocialServiceStates.selectingRelativeMembers]: {
      transitions: {
        [CommonEvents.userPressedBack]:
          NewSocialServiceStates.selectingExpenses,
        [NewSocialServiceEvents.userSelectRelativeMembers]: {
          target: NewSocialServiceStates.submitting,
          command: this.saveRelativeMembers,
        },
      },
    },
    [NewSocialServiceStates.submitting]: {
      onEntry: this.submit,
      transitions: {
        [NewSocialServiceEvents.errorCreating]: NewSocialServiceStates.error,
        [NewSocialServiceEvents.procedureCreated]:
          NewSocialServiceStates.success,
      },
    },
    [NewSocialServiceStates.error]: {
      transitions: {
        [CommonEvents.userPressRetry]: {
          command: this.resetContext,
          target: NewSocialServiceStates.selectingKind,
        },
      },
    },
    [NewSocialServiceStates.success]: {},
  };

  *resetContext(): SagaGenerator<void> {
    yield* this.setContext({
      actualForm: {},
      defaults: defaultCitizenContext,
    });
  }

  *saveKind(): SagaGenerator<void> {
    const kind = this.context.actualForm.kind as WelfareInstanceTypeDTO;
    const section = this.context.actualForm
      .section as WelfareInstanceSectionDTO;
    yield* this.setContext(ctx => {
      ctx.kind = kind;
      ctx.section = section;
    });
  }

  *storeKind(event: UserSelectKindType): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.actualForm.kind = event.payload.kind;
    });
  }

  *storeSection(event: UserSelectSectionType): SagaGenerator<void> {
    if (event.payload !== this.context.actualForm.section) {
      yield* this.setContext(ctx => {
        ctx.actualForm.section = event.payload;
        ctx.actualForm.kind = undefined;
      });
    }
  }

  *storeRequestor(event: UserSelectRequestorType): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.requestor = event.payload;
    });
  }

  *requestorIsBeneficiary(
    event: UserSelectRequestorTypeType
  ): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.requestorType = event.payload;
      ctx.beneficiary = ctx.requestor;
    });
  }

  *storeRequestType(event: UserSelectRequestorTypeType): SagaGenerator<void> {
    if (event.payload.type === WelfareRequestorTypeDTO.Beneficiario) {
      yield* this.setContext(ctx => {
        //ignoring documents if beneficiary
        ctx.requestorType = {
          type: event.payload.type,
          documents: [],
        };
        ctx.beneficiary = ctx.requestor;
      });
      yield* call(
        [this, this.getCitizenContextCB],
        this.context.requestor!.fiscalCode
      );
      yield* put(beneficiaryKnown());
    } else {
      yield* this.setContext(ctx => {
        ctx.requestorType = event.payload;
      });
      yield* put(beneficairyNeeded());
    }
  }

  *getCitizenContextCB(cf: string): SagaGenerator<void> {
    try {
      const defaults = yield* call(getCitizenContext, cf);
      yield* this.setContext(ctx => {
        ctx.defaults = defaults;
      });
    } catch (e) {
      console.error(e);
      console.error('error to get defaults');
    }
  }

  *storeBeneficiary(event: UserSelectBeneficiaryType) {
    yield* this.setContext(ctx => {
      ctx.beneficiary = event.payload;
    });
    yield* call([this, this.getCitizenContextCB], event.payload.fiscalCode);
    yield* this.checkIfPersonalSituationIsNeeded();
  }

  *checkIfPersonalSituationIsNeeded() {
    if (
      this.context.kind === WelfareInstanceTypeDTO.AssegniMaternita ||
      this.context.kind === WelfareInstanceTypeDTO.AssegniNucleoFamiliare
    ) {
      yield* put(skipPersonalSituation);
    } else {
      yield* put(needsPersonalSituation);
    }
  }

  *storePersonalSituation(event: UserSelectPersonalSituation) {
    yield* this.setContext(ctx => {
      ctx.defaults = { ...ctx.defaults, flags: event.payload };
      ctx.flags = event.payload;
    });
  }

  *saveFamilyMembers(event: UserSelectFamilyMembersType): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.defaults = { ...ctx.defaults, familyMembers: event.payload };
      ctx.familyMembers = event.payload;
    });
  }

  *saveRelativeMembers(
    event: UserSelectRelativeMembersType
  ): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.defaults.relativeMembers = event.payload;
      ctx.relativeMembers = event.payload;
    });
  }

  *saveIsee(event: UserLoadIseeType): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.isee = event.payload;
    });
  }

  *saveOtherBenefits(event: UserSelectOtherBenefitsType): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.otherBenefits = event.payload;
    });
  }

  *saveExpenses(event: UserSelectExpensesType): SagaGenerator<void> {
    yield* this.setContext(ctx => {
      ctx.expenses = event.payload;
    });
  }

  *submit(): SagaGenerator<void> {
    try {
      const request: WelfareInstanceCreationRequest = {
        type: this.context.kind!,
        section: this.context.section!,
        beneficiaryFiscalCode: this.context.beneficiary!.fiscalCode!,
        requestor: {
          type: this.context.requestorType!.type,
          documents: this.context.requestorType?.documents ?? [],
          personFiscalCode: this.context.requestor!.fiscalCode,
        },
        details: {
          flags: this.context.flags
            ? Object.entries(this.context.flags)
                .filter(([_, values]) => !!values)
                .map(([type, values]) => ({
                  type: type as FlagTypeDTO,
                  values: values as FlagValueDTO[],
                }))
            : undefined,
          familyMembers: this.context.familyMembers ?? [],
          relativeMembers: this.context.relativeMembers ?? [],
          isee: this.context.isee,
          benefits: this.context.otherBenefits ?? [],
          module: WelfareModuleTypeDTO.AModule,
          undeductedExpenses: this.context.expenses ?? [],
        },
      };
      const procedure = yield* call(createInstance, request);

      yield* this.setContext(ctx => {
        ctx.id = procedure.id;
      });
      yield* put(procedureCreated());
    } catch (e) {
      console.error('error during instance creation', e);
      yield* put(errorCreating());
    }
  }
}

export const newSocialServiceStm = new NewSocialServiceStm();

export const NewSocialServiceContext = createLocalStateMachineContext(
  newSocialServiceStm
);

export function useNewSocialService() {
  return useContext(NewSocialServiceContext);
}
