import React from 'react';
import { withStyles } from '@material-ui/core';
import Loader from '../../../common/Loader';

const styles = theme => ({
  container: {
    flex: 1,
    height: 150,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  text: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    lineHeight: '27px',
    textAlign: 'center',
  },
});

const OperationInProgress = ({ classes, message }) => {
  return (
    <div className={classes.container}>
      <Loader size={20} color="primary" loading />
      <div className={classes.text}>{message}</div>
    </div>
  );
};

export default withStyles(styles)(OperationInProgress);
