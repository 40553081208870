import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import PageContainer from '../../common/PageContainer';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from '../../common/PageTitle';
import AuditApi from '../../../api/Audit';
import Loader from '../../common/Loader';
import LabelWithValue from '../../common/LabelWithValue';
import { getLabelSessionState } from '../../../constants/common';
import ROUTES from '../../../constants/routes';
import TextLinkPrimary from '../../common/TextLinkPrimary';
import TableEventi from '../../common/TableEventi';

const styles = () => ({
  tabs: {
    marginTop: '30px',
    lineHeight: '1.5',
  },
  sessionDetails: {
    marginTop: '30px',
  },
});

class WorkSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      session: null,
      loadingSession: true,
      loadingEvents: false,
      events: [],
      paginationEvents: null,
      filtersEvents: [],
    };
  }

  componentDidMount() {
    this.loadSession(this.props.match.params.sessionId);
    this.loadEvents({ page: 1, filters: this.state.filtersEvents });
  }

  componentWillReceiveProps(nextProps, _nextContext) {
    this.loadSession(nextProps.match.params.sessionId);
  }

  goToUserDetail(user) {
    this.props.history.push(`/${ROUTES.UTENTI}/${user.id}`);
  }

  loadSession(sessionId) {
    this.setState(
      {
        loadingSession: true,
      },
      () => {
        AuditApi.loadSession(
          sessionId,
          session => {
            this.setState({
              session: session,
              loadingSession: false,
            });
          },
          () => {
            this.setState({
              session: null,
              loadingSession: false,
            });
          }
        );
      }
    );
  }

  loadEvents = ({ page = 1, filters = this.state.filtersEvents }) => {
    this.setState(
      {
        loadingEvents: true,
      },
      this.getEvents(page, filters)
    );
  };

  getEvents = (page, filters) => {
    const sessionId = this.props.match.params.sessionId;

    return () => {
      AuditApi.loadSessionEvents(
        { sessionId: sessionId, page: page, filters: filters },
        response => {
          this.setState({
            loadingEvents: false,
            events: response.events,
            paginationEvents: response.meta ? response.meta.pagination : null,
          });
        },
        () => {
          this.setState({ loadingEvents: false });
        }
      );
    };
  };

  render() {
    const { session, loadingSession } = this.state;
    const { classes } = this.props;

    return (
      <PageContainer
        header={<PageTitle title="Dettaglio Sessione" backIcon={true} />}
      >
        <Loader size={40} loading={loadingSession} />
        {session && !this.state.loadingSession && (
          <span>
            <Row className={classes.sessionDetails}>
              <Col xs={2} sm={2} md={2}>
                <LabelWithValue
                  label="Stato:"
                  value={<span>{getLabelSessionState(session.status)}</span>}
                />
              </Col>
              <Col xs={2} sm={2} md={2}>
                <LabelWithValue
                  label="Utente:"
                  value={
                    <span>
                      <TextLinkPrimary
                        onClick={() => this.goToUserDetail(session.user)}
                      >
                        {session.user.full_name}
                      </TextLinkPrimary>
                    </span>
                  }
                />
              </Col>
              <Col xs={2} sm={2} md={2}>
                <LabelWithValue
                  label="Indirizzo IP:"
                  value={<span>{session.ip}</span>}
                />
              </Col>
              <Col xs={3} sm={3} md={3}>
                <LabelWithValue
                  label="Iniziata il:"
                  value={<span>{session.created_at}</span>}
                />
              </Col>
              <Col xs={3} sm={3} md={3}>
                <LabelWithValue
                  label="Terminata il:"
                  value={
                    <span>
                      {session.status === 'expired'
                        ? session.updated_at
                        : 'In Corso'}
                    </span>
                  }
                />
              </Col>
            </Row>
            <div className={classes.tabs}>
              <TableEventi
                hidePerformedBy={true}
                hideGoToSession={true}
                loading={this.state.loadingEvents}
                loadPage={this.loadEvents}
                events={this.state.events}
                goToUserDetail={() => {}}
                goToSessionDetails={() => {}}
              />
            </div>
          </span>
        )}
      </PageContainer>
    );
  }
}

export default withStyles(styles)(observer(WorkSession));
