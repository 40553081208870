import React from 'react';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import store from '../../store';
import { Row } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import PrettySection from '../common/PrettySection';
import { Check as SuccessIcon } from '@material-ui/icons';
import {
  HorizontalList,
  HorizontalLabelValue,
} from '../common/HorizontalLabelValue';
import Detail from '../cittadini/Detail';

const styles = () => ({
  spidConnected: {
    color: '#28a745',
    fontWeight: '600',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  spidConnectedIcon: {
    marginRight: '10px',
    fontSize: '12px',
    fontWeigth: 'bold',
  },
});

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: store.app.profile.user,
      errors: {},
    };
  }

  connectSpid = () => {};

  render() {
    const { classes } = this.props;
    const { user } = this.state;

    return (
      <PageContainer
        header={<PageTitle title="Il tuo profilo" backIcon={true} />}
      >
        <div>
          <Row>
            <PrettySection
              title="Informazioni Account"
              icon="nc-circle-09"
              actions={
                store.app.profile.user.spidConnected
                  ? []
                  : [
                      <Button
                        key="spid"
                        variant="raised"
                        color="primary"
                        onClick={this.connectSpid}
                      >
                        Collega account SPID
                      </Button>,
                    ]
              }
              headerActions={
                !store.app.profile.user.spidConnected
                  ? []
                  : [
                      <div key="spid2" className={classes.spidConnected}>
                        <SuccessIcon className={classes.spidConnectedIcon} />
                        <span>Collegato con SPID</span>
                      </div>,
                    ]
              }
            >
              <HorizontalList>
                <HorizontalLabelValue
                  md={3}
                  sm={6}
                  label="Email"
                  value={user.email}
                />
                <HorizontalLabelValue
                  md={3}
                  sm={6}
                  label="Data Iscrizione"
                  value={user.created_at}
                />
              </HorizontalList>
            </PrettySection>
            {store.app.profile.user.person && (
              <Detail
                title="Informazioni Anagrafiche"
                editDisabled={true}
                user={store.app.profile.user}
              />
            )}
          </Row>
        </div>
      </PageContainer>
    );
  }
}

export default withStyles(styles)(withRouter(observer(Profile)));
