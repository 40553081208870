import React, { useCallback, useEffect, useState } from 'react';
import {
  StyledComponentProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Loader from '../../../../common/Loader';
import EmptyTableMessage from '../../../../common/EmptyTableMessage';
import { withStyles } from '@material-ui/core/styles';
import tableStyle from '../../../../../styles/table.style';
import { getSecretariatActivities } from '../../../api/api';
import store from '../../../../../store';
import {
  SecretariatActivityListItem,
  SecretariatActivityListResponse,
} from '../../../api/model';
import { TypePagination } from '../../../constants';
import { Item } from './Item';
import { Col, Row } from 'react-flexbox-grid';
import Pagination from '../../../../common/Pagination';
import cx from 'classnames';

const styles = () => ({
  ...tableStyle(),
  paginationContainer: {
    margin: 0,
  },
  container: {
    paddingBottom: 30,
  },
  totalItemCount: {
    width: '100%',
    display: 'flex',
    marginBottom: 8,
    fontSize: 18,
  },
});

const ListaSegretariatioSociale: React.FC<StyledComponentProps> = ({
  classes = {},
}) => {
  const { snackBar } = store.app;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [activities, setActivities] = useState<SecretariatActivityListItem[]>(
    []
  );
  const [pagination, setPagination] = useState<TypePagination>();

  const onPageChange = useCallback(changed => {
    setPage(changed.page - 1);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getSecretariatActivities({ pageNumber: page })
      .then((data: SecretariatActivityListResponse) => {
        setActivities(data.activities);
        setPagination(data.pagination);
      })
      .catch(() => {
        snackBar.open(
          'Si è verificato un errore durante il caricamento delle attività di segretariat'
        );
        setActivities([]);
      })
      .finally(() => setIsLoading(false));
  }, [page, snackBar]);

  return (
    <div className={cx(classes.tableContainer, classes.container)}>
      {pagination && (
        <div className={classes.totalItemCount}>
          N° Totale Attività:&nbsp;&nbsp;<b>{pagination.totalItemsCount}</b>
        </div>
      )}
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headRow}>
            <TableCell className={classes.headCell}>ID Attività</TableCell>
            <TableCell className={classes.headCell}>Codice Fiscale</TableCell>
            <TableCell className={classes.headCell}>Data</TableCell>
            <TableCell className={classes.headCell} style={{ width: '20%' }}>
              Assistente Sociale
            </TableCell>
            <TableCell className={classes.headCell} />
          </TableRow>
        </TableHead>
        {!isLoading && activities.length > 0 && (
          <TableBody>
            {activities.map(activity => (
              <Item key={activity.id} activity={activity} />
            ))}
          </TableBody>
        )}
      </Table>

      <Loader size={40} loading={isLoading} />
      <EmptyTableMessage visible={!isLoading && !activities.length} />
      {pagination && (
        <Row className={classes.paginationContainer}>
          <Col xs={12}>
            <Pagination
              total={pagination.totalPages}
              current={pagination.currentPage + 1}
              totalElement={pagination.totalItemsCount}
              elementNumber={pagination.pageItemsCount}
              onChange={onPageChange}
              display={3}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(ListaSegretariatioSociale);
