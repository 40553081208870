import { createAction } from '@reduxjs/toolkit';
import { WelfareInstanceListRequest } from '../../gen/api/welfare';

export enum SocialServiceListEvents {
  pageChanged = 'events/pageChanged',
  listLoaded = 'events/listLoaded',
  errorLoadingList = 'events/errorLoadingList',
  filterChanged = 'events/filterChanged',
}

export interface PageChangedPayload {
  page: number;
}

export type FilterChangedPayload = Pick<
  WelfareInstanceListRequest,
  | 'type'
  | 'section'
  | 'status'
  | 'beneficiaryFiscalCode'
  | 'createdAfter'
  | 'createdBefore'
  | 'assigneeFiscalCode'
>;

export const listLoaded = createAction(SocialServiceListEvents.listLoaded);
export const errorLoadingList = createAction(
  SocialServiceListEvents.errorLoadingList
);

export const pageChanged = createAction<PageChangedPayload>(
  SocialServiceListEvents.pageChanged
);

export type PageChangedType = ReturnType<typeof pageChanged>;

export const filterChanged = createAction<FilterChangedPayload>(
  SocialServiceListEvents.filterChanged
);

export type FilterChangedType = ReturnType<typeof filterChanged>;
