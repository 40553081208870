import React from 'react';
import { withStyles } from '@material-ui/core';
import DetailElement from '../../common/DetailElement';
import classNames from 'classnames';
import Subsection from '../../common/Subsection';
import { Col, Row } from 'react-flexbox-grid';
import {
  GeoJSON,
  MapContainer as Map,
  TileLayer,
  Tooltip,
} from 'react-leaflet';
import PersonCard from '../../common/PersonCard';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import tableStyle from '../../../styles/table.style';

const styles = () => ({
  simpleLabelValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  simpleLabel: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
});

export const RotturaSuoloDetailList = withStyles(styles)(props => {
  const { procedure } = props;

  return (
    <>
      <DetailElement
        label="Oggetto"
        value={procedure.details.reason}
        icon="nc-icon nc-email-83"
        separator
      />
      <DetailElement
        label="Causale Scavi"
        value={procedure.details.motive}
        icon="nc-icon nc-align-left-2"
        separator
      />
      <DetailElement
        label="Note Aggiuntive"
        value={procedure.details.notes}
        icon="nc-icon nc-simple-add"
        separator
      />
    </>
  );
});

export const RotturaSuoloDetail = withStyles(styles)(props => {
  const { procedure, classes } = props;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <i
            className={classNames(classes.headerIcon, 'nc-icon nc-settings-90')}
          />
          <span className={classes.headerTitle}>Dettagli Scavi</span>
        </div>
        <Subsection>
          <Row>
            <LabelValue
              label="Tipo Posa"
              value={procedure.details.laying_type}
            />
            <LabelValue label="Diametro" value={procedure.details.diameter} />
            <LabelValue
              label="Lunghezza"
              value={`${procedure.details.length} metri`}
            />
            <LabelValue
              label="Pavimentazione"
              value={
                procedure.details.flooring_type === 'standard'
                  ? 'Stradale'
                  : 'Di Pregio'
              }
            />
            <LabelValue
              label="Durata Lavori"
              value={`${procedure.details.duration} giorni`}
            />
            <LabelValue
              label="Data di Inizio"
              value={procedure.details.start_date}
            />
            <LabelValue
              label="Data di Fine"
              value={procedure.details.end_date}
            />
            <LabelValue
              label="Assicurato con Fidejussione"
              value={procedure.details.insurance.surety ? 'Sì' : 'No'}
            />
            <LabelValue
              label="Massimale Assicurativo"
              value={
                procedure.details.insurance.amount
                  ? procedure.details.insurance.amount.replace('.', ',') + ' €'
                  : 'N/A'
              }
            />
            <LabelValue
              label="Esecuzione Lavori"
              value={
                procedure.details.supplier
                  ? 'Affidati alla ditta'
                  : 'In Proprio'
              }
            />
            {procedure.details.supplier && (
              <Col xs={12} className={classes.simpleLabelValueContainer}>
                <span className={classes.simpleLabel}>Ditta Affidataria</span>
                <span className={classes.simpleValue}>
                  <PersonCard
                    person={procedure.details.supplier}
                    personType="business"
                  />
                </span>
              </Col>
            )}
          </Row>
        </Subsection>
      </div>

      <div className={classes.container}>
        <div className={classes.header}>
          <i
            className={classNames(classes.headerIcon, 'nc-icon nc-square-pin')}
          />
          <span className={classes.headerTitle}>Area Scavi</span>
        </div>
        <Subsection>
          <Row>
            <Col xs={12}>
              <Map
                style={{ height: '300px' }}
                zoom={14}
                center={[44.316225, 9.323962]}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <GeoJSON
                  style={() => {
                    return {
                      color: '#174f52',
                      width: 2,
                      fill: true,
                      fillColor: '#174f52',
                      fillOpacity: 0.4,
                    };
                  }}
                  data={procedure.details.excavation_details.geometry}
                >
                  <Tooltip>
                    Area Scavi #
                    {procedure.details.excavation_details.area_number}
                  </Tooltip>
                </GeoJSON>
                <GeoJSON
                  style={() => {
                    return {
                      color: '#700298',
                      width: 2,
                      fill: true,
                      fillColor: '#ce92ff',
                      fillOpacity: 0.5,
                    };
                  }}
                  data={procedure.details.building_site.geometry}
                >
                  <Tooltip>
                    Area Cantiere #{procedure.details.building_site.area_number}
                  </Tooltip>
                </GeoJSON>
              </Map>
            </Col>
            {procedure.details.excavation_details.addresses.length > 0 && (
              <AddressTable
                addresses={procedure.details.excavation_details.addresses}
              />
            )}
          </Row>
        </Subsection>
      </div>
    </>
  );
});

const LabelValue = withStyles(styles)(props => {
  const { classes, label, value } = props;

  return (
    <Col md={3} sm={6} xs={12} className={classes.simpleLabelValueContainer}>
      <span className={classes.simpleLabel}>{label}</span>
      <span className={classes.simpleValue}>{value}</span>
    </Col>
  );
});

const AddressTable = withStyles(tableStyle)(props => {
  const { classes, addresses } = props;

  return (
    <Col
      xs={12}
      style={{ marginTop: '30px' }}
      className={classes.tableContainer}
    >
      <b>STRADE COINVOLTE</b>
      <Table style={{ marginTop: '20px' }} className={classes.table}>
        <TableHead>
          <TableRow className={classes.headRow}>
            <TableCell className={classes.headCell}>Indirizzio</TableCell>
            <TableCell className={classes.headCell}>
              Da Civico - A Civico
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addresses.map((address, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index} className={classes.bodyRow}>
                <TableCell>{address.street_name}</TableCell>
                <TableCell>{`${address.from_street_number} - ${address.to_street_number}`}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Col>
  );
});
