import React, { FC, useCallback } from 'react';
import { MenuItem, StyledComponentProps } from '@material-ui/core';
import { Notification } from '../../new/types/notifications';
import { useNotifications } from '../../new/hooks/notifications';
import { withStyles } from '@material-ui/core/styles';
import { humanizedDiff } from '../../utils/dateutils';

const styles = (theme: any) => ({
  listComponent: {
    width: 300,
    height: 'auto',
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey.main}`,
    },
  },
  content: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    whiteSpace: 'initial',
  },
  meta: {
    width: '100%',
    fontSize: '12px',
    fontWeight: '300',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface Props extends StyledComponentProps {
  notification: Notification<any>;
  onNotificationClick?: (notification: Notification<any>) => void;
}

const NotificationMenuItem: FC<Props> = ({
  classes,
  notification,
  onNotificationClick,
}) => {
  const { formatNotification } = useNotifications();
  const { message } = formatNotification(notification);

  const onItemClicked = useCallback(() => {
    if (onNotificationClick) onNotificationClick(notification);
  }, [notification, onNotificationClick]);

  return (
    <MenuItem onClick={onItemClicked} className={classes?.listComponent}>
      <div className={classes?.content}>{message}</div>
      <div className={classes?.meta}>
        {humanizedDiff(notification.createdAt)}
      </div>
    </MenuItem>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(NotificationMenuItem);
