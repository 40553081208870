import {
  FormControl,
  Input,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { SelectionFlagProps } from './model';
import { StyleRules } from '@material-ui/core/styles';

const styles = (_theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '896px',
    padding: '4px',
    borderBottom: '1px solid #DDDDDD',
  },
  flagContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  selectContainer: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
  },
});

const NONE_OPTION = { id: 'NONE', text: 'Nessuno' };

const _SelectionFlag: FC<SelectionFlagProps> = ({
  label,
  data,
  onChange: onChangeCallback,
  options,
  classes,
}) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (event.target.value === NONE_OPTION.id) onChangeCallback(undefined);
      else {
        onChangeCallback([event.target.value]);
      }
    },
    [onChangeCallback]
  );

  return (
    <FormControl className={classes?.container}>
      <div className={classes?.flagContainer}>
        <p
          style={{
            color: '#5C6771',
          }}
        >
          {label}
        </p>
        <Select
          value={data ? data[0] : NONE_OPTION.id}
          onChange={onChange}
          input={<Input id="select-multiple-chip" />}
          placeholder="Seleziona un valore"
          style={{ width: '481px', color: data ? undefined : '#5c6771' }}
        >
          {[NONE_OPTION, ...options].map(({ id: oId, text }) => (
            <MenuItem value={oId} key={`option-${oId}`}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const SelectionFlag = withStyles(styles)(_SelectionFlag);
