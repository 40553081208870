import React, { FC, useCallback } from 'react';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import {
  userLoadIsee,
  userSkipStep,
} from '../../../../stm/socialServices/newProcedure.events';
import { userPressedBack } from '../../../../stm/socialServices/common.events';
import { SelectIseeForm } from '../../common/forms/SelectIseeForm';

export const SelectIsee: FC = () => {
  const { state, dispatch } = useNewSocialService();

  const onConfirm = useCallback(
    isee => {
      dispatch(userLoadIsee(isee));
    },
    [dispatch]
  );

  const skip = useCallback(() => {
    dispatch(userSkipStep());
  }, [dispatch]);

  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  return (
    <SelectIseeForm
      initialIsee={state.context?.isee}
      onAbort={goBack}
      onConfirm={onConfirm}
      skipAction={skip}
      instanceType={state.context!.kind!}
    />
  );
};
