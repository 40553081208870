/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareRequestorTypeDTO {
    Beneficiario = 'BENEFICIARIO',
    Tutore = 'TUTORE',
    Procuratore = 'PROCURATORE',
    Amministratore = 'AMMINISTRATORE'
}

export function WelfareRequestorTypeDTOFromJSON(json: any): WelfareRequestorTypeDTO {
    return WelfareRequestorTypeDTOFromJSONTyped(json, false);
}

export function WelfareRequestorTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelfareRequestorTypeDTO {
    return json as WelfareRequestorTypeDTO;
}

export function WelfareRequestorTypeDTOToJSON(value?: WelfareRequestorTypeDTO | null): any {
    return value as any;
}

