import React, { FC, useCallback } from 'react';
import { Button, StyledComponentProps, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../../constants/routes';

const styles = (theme: any) => ({
  container: {
    marginTop: '180px',
    alignSelf: 'center',
    width: '430px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    height: '96px',
    width: '96px',
    marginBottom: '24px',
    backgroundColor: '#fdd8dd',
    color: theme.palette.font.redDark,
    borderRadius: '48px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '700',
    marginBottom: '16px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    marginBottom: '56px',
  },
  button: {
    width: '232px',
    marginBottom: '8px',
  },
});

interface ErrorProps extends StyledComponentProps, RouteComponentProps {
  onRetry: () => void;
}

const Error: FC<ErrorProps> = ({ onRetry, classes = {}, history }) => {
  const goToHome = useCallback(() => {
    history.push(`/${ROUTES_SERVIZI_SOCIALI.DIARIO}`);
  }, [history]);

  return (
    <div className={classes.container}>
      <Close className={classes.image} />
      <label className={classes.title}>Operazione fallita</label>
      <label className={classes.text}>
        Si è verificato un errore imprevisto durante l&apos;inserimento
        dell&apos;attivita di segretariato. Si prega di riprovare più tardi
      </label>
      <Button
        variant="raised"
        color="primary"
        onClick={onRetry}
        className={classes.button}
      >
        Riprova la creazione
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={goToHome}
        className={classes.button}
      >
        Torna alla home
      </Button>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedError = withStyles(styles)(withRouter(Error));

export { DecoratedError as Error };
