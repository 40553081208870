import { Checkbox as LibCheckbox } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import React, { FC } from 'react';
import { ReactComponent as CheckBoxChecked } from '../../../../../assets/icons/check-checked.svg';
import { ReactComponent as CheckBoxUnchecked } from '../../../../../assets/icons/check-unchecked.svg';

export const Checkbox: FC<CheckboxProps> = ({ icon, checkedIcon, ...rest }) => {
  return (
    <LibCheckbox
      checkedIcon={checkedIcon ?? <CheckBoxChecked />}
      icon={icon ?? <CheckBoxUnchecked />}
      {...rest}
    />
  );
};
