import React, { useState, useMemo, useCallback } from 'react';
import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import store from '../../../store';
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import SelectDeparmentStep from './Steps/SelectDeparmentStep';
import Loader from '../../common/Loader';
import InsertSubjectStep from './Steps/InsertSubjectStep';
import Condivisione from '../../../api/Condivisione';

const styles = theme => ({
  container: {
    minWidth: '600px',
  },
  marginRightButton: {
    marginRight: '10px',
  },
  dialogTitle: {
    '& h2': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  closeIcon: {
    fontSize: '22px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary,
    },
  },
});

const STEP_SELECT_DEPARTMENT = 'STEP_SELECT_DEPARTMENT';
const STEP_INSERT_SUBJECT = 'STEP_INSERT_SUBJECT';
const STEP_SHARING = 'STEP_SHARING';
const STEPS = [STEP_SELECT_DEPARTMENT, STEP_INSERT_SUBJECT, STEP_SHARING];

const Dialog = props => {
  const { classes, open, instanceId, onClose } = props;
  const { snackBar, profile } = store.app;
  const [step, setStep] = useState(0);
  const [department, setDepartment] = useState();
  const [subject, setSubject] = useState('');

  const currStep = useMemo(() => {
    return STEPS[step];
  }, [step]);

  const onBack = useCallback(() => {
    setStep(Math.max(0, step - 1));
  }, [step]);

  const onNext = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const close = useCallback(
    (shouldUpdate = false) => {
      setStep(0);
      setSubject(undefined);
      setDepartment(undefined);
      onClose(shouldUpdate);
    },
    [onClose]
  );

  const onCancel = useCallback(() => {
    close(false);
  }, [close]);

  const onSave = useCallback(() => {
    setStep(step => step + 1);
    Condivisione.share(
      {
        instanceId: `${instanceId}`,
        subject,
        destDepartment: department,
        ownerDepartment: profile.operatingAs.department,
      },
      () => {
        close(true);
        snackBar.open('Parere richiesto con successo');
      },
      err => {
        let message =
          'Si è verificato un errore imprevisto. Riprova fra qualche istante';
        if (err.data.code === 'INSTANCE_ALREADY_SHARED') {
          message = `Questa istanza è già in condivisione con l'ufficio selezionato`;
        }
        onBack();
        store.app.snackBar.open(message);
      }
    );
  }, [close, department, instanceId, onBack, profile, snackBar, subject]);

  const removeDepartment = useCallback(() => {
    setDepartment(undefined);
  }, []);

  return (
    <MaterialDialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="share-dialog-title"
      aria-describedby="share-dialog-description"
    >
      <div className={classes.container}>
        <DialogTitle className={classes.dialogTitle} id="share-dialog-title">
          <span>
            {currStep === STEP_SHARING
              ? 'Invio in corso...'
              : 'Richiedi Parere'}
          </span>
          <span className={classes.closeIcon} onClick={onCancel}>
            &times;
          </span>
        </DialogTitle>
        <DialogContent>
          {currStep === STEP_SELECT_DEPARTMENT && (
            <SelectDeparmentStep
              department={department}
              message="Seleziona l'ufficio a cui richiedere un parere per l'istanza"
              onDepartmentSelected={setDepartment}
              onDepartmentRemoved={removeDepartment}
            />
          )}
          {currStep === STEP_INSERT_SUBJECT && (
            <InsertSubjectStep subject={subject} onChange={setSubject} />
          )}
          {currStep === STEP_SHARING && (
            <Loader loading={true} color="primary" />
          )}
        </DialogContent>
        <DialogActions>
          {currStep === STEP_SELECT_DEPARTMENT && (
            <>
              <Button onClick={onCancel} className={classes.marginRightButton}>
                Annulla
              </Button>
              <Button
                onClick={onNext}
                variant="raised"
                color="primary"
                disabled={!department}
              >
                Prosegui
              </Button>
            </>
          )}
          {currStep === STEP_INSERT_SUBJECT && (
            <>
              <Button onClick={onBack} className={classes.marginRightButton}>
                Indietro
              </Button>
              <Button
                onClick={onSave}
                variant="raised"
                color="primary"
                disabled={!department}
              >
                Prosegui
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  instanceId: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Dialog);
