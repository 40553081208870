import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Scadenziario from '../../../api/ScadenziarioEdilizia';
import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import { observer } from 'mobx-react';
import Loader from '../../common/Loader';
import { Col, Row } from 'react-flexbox-grid';
import { AccountCircle as UserIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Chip,
  Button,
  Switch,
  CircularProgress,
} from '@material-ui/core';
import { validate } from 'email-validator';
import ConfirmButton from '../../common/ConfirmButton';
import ROUTES from '../../../constants/routes';
import store from '../../../store';
import EditableField from '../../common/EditableField';

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  formContainer: {},
  emailList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  emailItem: {
    marginRight: '5px',
    marginBottom: '5px',
    position: 'relative',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  documentContainer: {
    display: 'flex',
    borderRadius: '20px',
    border: '1px solid #eee',
    padding: '10px 15px',
    marginRight: '5px',
    marginBottom: '5px',
    maxWidth: '250px',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    wordWrap: 'break-word',
    position: 'relative',
    alignItems: 'center',
  },
  documentIcon: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: '#555',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px',
    fontSize: '20px',
  },
  documentExtension: {
    marginTop: '2px',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  documentName: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  actionBox: {
    padding: '20px',
    margin: '0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attachmentContainer: {
    padding: '20px 30px',
  },
  attachmentContainerLabel: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginBottom: '20px',
  },
  attachmentContainerAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class Send extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      procedure: null,
      message: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadProcedure(this.props.match.params.procedureId);
  }

  componentWillReceiveProps(nextProps, _nextContext) {
    this.loadProcedure(nextProps.match.params.procedureId);
  }

  loadProcedure = procedureId => {
    this.setState({ loading: true }, () => {
      Scadenziario.get(
        procedureId,
        response => {
          this.setState({
            loading: false,
            procedure: response.procedure,
            message: response.message,
          });
        },
        () => {
          this.setState({ loading: false });
        }
      );
    });
  };

  onSendCompleted = () => {
    this.props.history.push(`/${ROUTES.SCADENZIARIO_EDILIZIA}`);
  };

  render() {
    const { procedure, message, loading } = this.state;
    const { classes } = this.props;

    return (
      <PageContainer
        header={
          <PageTitle
            title="Invia Pratica"
            subtitle={procedure ? `Pratica n° ${procedure.number}` : '-'}
            backIcon
          />
        }
      >
        <Loader size={40} loading={loading} />
        {procedure && message && !loading && (
          <div className={classes.container}>
            <span>
              <SendProcedureForm
                message={this.state.message}
                procedure={this.state.procedure}
                onSendCompleted={this.onSendCompleted}
              />
            </span>
          </div>
        )}
      </PageContainer>
    );
  }
}

const SendProcedureForm = withStyles(styles)(props => {
  const [messageData, setMessageData] = useState({
    to: props.message.to,
    cc: props.message.cc,
    bcc: props.message.bcc,
    subject: props.message.subject,
    body: props.message.body,
    include_sender: false,
  });
  const [checkActive, setCheckActive] = useState(false);
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [sending, setSending] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState([]);
  const [sendingDisabled, setSendingDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const { classes, procedure, onSendCompleted } = props;

  useEffect(() => {
    if (!messageData.to.length) {
      setSendingDisabled(true);
      errors.to = {
        message: 'Nessun destinatario specificato',
      };
      setErrors(errors);
    }
  }, [messageData, errors]);

  const onSendClicked = useCallback(
    onFinished => {
      setSending(true);
      setSendingDisabled(true);
      const data = {};
      Object.keys(messageData).forEach(key => {
        if (messageData[key] && messageData[key].length)
          data[key] = messageData[key];
      });

      data.include_sender = messageData.include_sender;
      Scadenziario.send(
        procedure.id,
        data,
        () => {
          store.app.snackBar.open('Pratica in elaborazione');
          onSendCompleted();
          setSending(false);
        },
        () => {
          setSending(false);
          onFinished();
          store.app.snackBar.open(
            "Errore nell'invio della pratica, riprovare successivamente o contattare il webmaster se il problema persiste."
          );
        }
      );
    },
    [messageData, onSendCompleted, procedure]
  );

  const renderMailListField = useCallback(
    (name, emails) => {
      return emails.trim() ? (
        <div className={classes.emailList}>
          {emails
            .split(',')
            .filter(email => email)
            .map(email => {
              return (
                <Chip
                  key={email}
                  avatar={<UserIcon className={classes.emailChipAvatar} />}
                  className={classes.emailItem}
                  label={email}
                />
              );
            })}
        </div>
      ) : (
        'Nessuna'
      );
    },
    [classes]
  );

  const renderBodyField = useCallback((name, body) => {
    return body.split('\n').map(item => {
      return item ? (
        <span>
          {item}
          <br />
        </span>
      ) : (
        <br />
      );
    });
  }, []);

  const onDataChange = useCallback(
    (name, newVal) => {
      if (['to', 'cc', 'bcc'].indexOf(name) !== -1) {
        messageData[name] = newVal
          .split(',')
          .map(value => value.trim())
          .filter(value => value);
      } else {
        messageData[name] = newVal;
      }

      setMessageData(messageData);

      if (name === 'include_sender') {
        setCheckActive(newVal);
      }

      const index = currentlyEditing.indexOf(name);
      if (index !== -1) currentlyEditing.splice(index, 1);
      setCurrentlyEditing(currentlyEditing);
      setSendingDisabled(Boolean(currentlyEditing.length));
    },
    [currentlyEditing, messageData]
  );

  const onFieldEditing = useCallback(
    name => {
      if (currentlyEditing.indexOf(name) === -1) {
        const copy = currentlyEditing;
        copy.push(name);
        setCurrentlyEditing(copy);
        setSendingDisabled(Boolean(copy.length));
      }
    },
    [currentlyEditing]
  );

  const validateField = useCallback(
    (name, newVal) => {
      let error = false;
      let message = '';
      if (['to', 'cc', 'bcc'].indexOf(name) !== -1) {
        const emails = newVal
          .split(',')
          .map(value => value.trim())
          .filter(value => value);

        emails.forEach(element => {
          if (!validate(element)) {
            error = true;
            message = `L'email '${element}' non è valida`;
            return false;
          } else {
            delete errors[name];
            setErrors(errors);
          }
        });
      }

      return {
        error,
        message,
      };
    },
    [errors, setErrors]
  );

  const showDocumentPreview = useCallback(() => {
    setLoadingAttachment(true);
    Scadenziario.preview(
      procedure.id,
      () => {
        setLoadingAttachment(false);
      },
      () => {
        setLoadingAttachment(false);
      }
    );
  }, [procedure]);

  return (
    <Row className={classes.formContainer}>
      <Col xs={12}>
        <EditableField
          name="to"
          label="Destinatari"
          value={messageData.to.join(', ')}
          onValueChange={onDataChange}
          validateInput={validateField}
          renderValue={renderMailListField}
          disabled={sending}
          onEditing={onFieldEditing}
          initialError={errors.hasOwnProperty('to')}
          initialErrorMessage={errors.to ? errors.to.message : undefined}
          required
        />
        <EditableField
          name="cc"
          label="In Copia (CC)"
          value={messageData.cc.join(', ')}
          onValueChange={onDataChange}
          validateInput={validateField}
          renderValue={renderMailListField}
          onEditing={onFieldEditing}
          disabled={sending}
        />
        <EditableField
          name="bcc"
          label="In Copia Nascosta (CCN)"
          value={messageData.bcc.join(', ')}
          onValueChange={onDataChange}
          validateInput={validateField}
          renderValue={renderMailListField}
          onEditing={onFieldEditing}
          disabled={sending}
        />
        <EditableField
          name="subject"
          label="Oggetto"
          value={messageData.subject}
          onValueChange={onDataChange}
          validateInput={validateField}
          disabled={sending}
          onEditing={onFieldEditing}
          required
        />
        <EditableField
          name="body"
          label="Corpo"
          value={messageData.body}
          onValueChange={onDataChange}
          validateInput={validateField}
          inputProps={{
            multiline: true,
            inputProps: {
              maxLength: 500,
            },
          }}
          renderValue={renderBodyField}
          disabled={sending}
          onEditing={onFieldEditing}
          required
        />
        <Row className={classes.attachmentContainer}>
          <Col xs={12} className={classes.attachmentContainerLabel}>
            Allegato
          </Col>
          <Col xs={10}>
            <div className={classes.documentContainer}>
              <div className={classes.documentIcon}>
                <i className="nc-icon nc-single-copy-04" />
                <span className={classes.documentExtension}>PDF</span>
              </div>
              <div className={classes.documentName}>{procedure.filename}</div>
            </div>
          </Col>
          <Col xs={2} className={classes.attachmentContainerAction}>
            <Button
              disabled={loadingAttachment}
              size="small"
              variant="raised"
              color="primary"
              className={classes.smallActionButton}
              onClick={showDocumentPreview}
            >
              {!loadingAttachment ? (
                'Anteprima'
              ) : (
                <CircularProgress size={15} color="#fff" />
              )}
            </Button>
          </Col>
        </Row>
        <Row className={classes.actionBox}>
          <ConfirmButton
            buttonProps={{
              size: 'small',
              variant: 'raised',
              color: 'primary',
              disabled: sendingDisabled,
            }}
            buttonLabel="Invia"
            onConfirm={onSendClicked}
            confirmMessage="Conferma operazione"
            text={
              "Sei sicuro di voler inviare la pratica ai destinatari selezionati? L'azione non è reversibile"
            }
            withLoading
          />
          <FormControlLabel
            control={
              <Switch
                checked={checkActive}
                disabled={sending}
                onChange={event => {
                  onDataChange('include_sender', event.target.checked);
                }}
              />
            }
            label="Inseriscimi in copia nascosta"
          />
        </Row>
      </Col>
    </Row>
  );
});

SendProcedureForm.propTypes = {
  message: PropTypes.object.isRequired,
  procedure: PropTypes.object.isRequired,
  onSendCompleted: PropTypes.func.isRequired,
};

export default withStyles(styles)(observer(withRouter(Send)));
