import React, { FC, useCallback } from 'react';
import { Card, StyledComponentProps } from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import cx from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES_SERVIZI_SOCIALI } from '../../../constants/routes';
import { FilterChangedPayload } from '../../../stm/socialServices/socialServiceList.events';
import {
  WelfareInstanceSectionDTO,
  WelfareInstanceTypeDTO,
} from '../../../gen/api/welfare';

export enum DETAIL_ACTION {
  SHOW_SUBSECTION = 'SHOW_SUBSECTION',
  GOTO_FILTERED_LIST = 'GOTO_FILTERED_LIST',
}

export interface ShowSubsectionAction {
  type: DETAIL_ACTION.SHOW_SUBSECTION;
  payload: WelfareInstanceSectionDTO;
}

export interface GotoFilteredList {
  type: DETAIL_ACTION.GOTO_FILTERED_LIST;
  payload: WelfareInstanceTypeDTO;
}

export interface DashBoardItemProps {
  title: string;
  count: number;
  detailAction?: ShowSubsectionAction | GotoFilteredList;
}

const styles = (theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '13px 0',
    maxWidth: '360px',
    boxShadow: '0px 0px 32px rgba(206, 206, 206, 0.25)',
    borderRadius: '12px',
  },
  horizontalPadding: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  title: {
    fontFamily: 'Titillium Web',
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    height: '48px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  separator: {
    height: '0px',
    borderTop: '1px solid #DCDCDC',
    alignSelf: 'stretch',
  },
  count: {
    fontFamily: 'Titillium Web',
    fontWeight: 700,
    fontSize: '40px',
    fontStyle: 'normal',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  instanceLabel: {
    fontFamily: 'Titillium Web',
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '0em',
    textAlign: 'left',
  },
  actionLabel: {
    fontFamily: 'Titillium Web',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '18px',
    fontWeight: 400,
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  chevron: {
    width: '18px',
    height: '18px',
    color: theme.palette.primary.main,
  },
});

const DashboardItem: FC<DashBoardItemProps &
  StyledComponentProps &
  RouteComponentProps> = ({
  classes = {},
  history,
  title,
  count,
  detailAction,
}) => {
  const onClick = useCallback(() => {
    if (detailAction?.type === DETAIL_ACTION.SHOW_SUBSECTION) {
      history.push(
        `/${ROUTES_SERVIZI_SOCIALI.DASHBOARD_BASE}/${detailAction.payload}`
      );
    }
    if (detailAction?.type === DETAIL_ACTION.GOTO_FILTERED_LIST) {
      history.push(`/${ROUTES_SERVIZI_SOCIALI.LISTA_PRATICHE}`, {
        type: [detailAction.payload],
      } as FilterChangedPayload);
    }
    return () => {
      return;
    };
  }, [detailAction, history]);

  return (
    <Card className={classes.container}>
      <div className={cx(classes.title, classes.horizontalPadding)}>
        {title}
      </div>
      <div
        className={classes.horizontalPadding}
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'baseline',
          margin: '8px 0 24px 0',
        }}
      >
        <span className={classes.count}>{count}</span>
        <span className={classes.instanceLabel}>Istanze</span>
      </div>
      <div className={classes.separator} style={{ margin: '0 8px' }} />
      <div
        className={classes.horizontalPadding}
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          paddingTop: '8px',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <span className={classes.actionLabel}>Vai al dettaglio</span>
        <ChevronRight
          className={classes.chevron}
          color="primary"
          onClick={onClick}
        />
      </div>
    </Card>
  );
};
const DecoratedDashboardItem = withRouter(withStyles(styles)(DashboardItem));

export { DecoratedDashboardItem as DashboardItem };
