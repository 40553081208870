import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import tableStyle from '../../styles/table.style';
import TablePagination from '../common/TablePagination';
import EmptyTableMessage from '../common/EmptyTableMessage';
import Loader from '../common/Loader';
import TextLinkPrimary from './TextLinkPrimary';
import { getLabelEvento } from '../../constants/common';
import { eventHasDetails, renderEvent } from '../events/map';
import EventDetails from '../events/EventDetails';

class TableEventi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      eventComponent: null,
      showModal: false,
    };
  }

  showEventDetails = ev => {
    this.setState(
      {
        selectedEvent: ev,
        eventComponent: renderEvent(ev),
      },
      () => {
        this.setState({
          showModal: true,
        });
      }
    );
  };

  onModalClose = () => {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.setState({
          selectedEvent: null,
          eventComponent: null,
        });
      }
    );
  };

  render() {
    const {
      classes,
      hidePerformedBy,
      hideGoToSession,
      loading,
      events,
      pagination,
    } = this.props;

    return (
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headRow}>
              <TableCell className={classes.headCell}>Data</TableCell>
              <TableCell className={classes.headCell}>Evento</TableCell>
              <TableCell className={classes.headCell}>Utente</TableCell>
              {!hidePerformedBy && (
                <TableCell className={classes.headCell}>Eseguito Da</TableCell>
              )}
              {!hideGoToSession && (
                <TableCell className={classes.headCell}>Sessione</TableCell>
              )}
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {events.map(e => {
                return (
                  <TableRow key={e.id} className={classes.bodyRow}>
                    <TableCell>{e.occurred_on}</TableCell>
                    <TableCell>
                      {getLabelEvento(e.type)}
                      {eventHasDetails(e) && (
                        <TextLinkPrimary
                          onClick={() => {
                            this.showEventDetails(e);
                          }}
                        >
                          &nbsp;[Dettagli]
                        </TextLinkPrimary>
                      )}
                    </TableCell>
                    <TableCell>
                      {e.target ? (
                        <TextLinkPrimary
                          onClick={() => this.props.goToUserDetail(e.target)}
                        >
                          {e.target.full_name}
                        </TextLinkPrimary>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    {!hidePerformedBy && (
                      <TableCell>
                        {e.performed_by ? (
                          <TextLinkPrimary
                            onClick={() =>
                              this.props.goToUserDetail(e.performed_by)
                            }
                          >
                            {e.performed_by.first_name +
                              ' ' +
                              e.performed_by.last_name}
                          </TextLinkPrimary>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    )}
                    {!hideGoToSession && (
                      <TableCell>
                        {e.session ? (
                          <TextLinkPrimary
                            onClick={() =>
                              this.props.goToSessionDetails(e.session.id)
                            }
                          >
                            Sessione &raquo;
                          </TextLinkPrimary>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        <Loader size={40} loading={loading} />
        <EmptyTableMessage visible={events.length === 0 && !loading} />
        <TablePagination
          pagination={pagination}
          tableCols={3}
          handleChangePage={this.props.loadPage}
        />
        <EventDetails
          event={this.state.selectedEvent}
          show={this.state.showModal}
          onClose={this.onModalClose}
        >
          {this.state.eventComponent}
        </EventDetails>
      </div>
    );
  }
}

TableEventi.propTypes = {
  hidePerformedBy: PropTypes.bool,
  hideGoToSession: PropTypes.bool,
  loading: PropTypes.bool,
  loadPage: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  events: PropTypes.array.isRequired,
  goToUserDetail: PropTypes.func.isRequired,
  goToSessionDetails: PropTypes.func,
};

TableEventi.defaultProps = {
  hidePerformedBy: false,
  hideGoToSession: false,
  loading: false,
  events: [],
  pagination: null,
  goToSessionDetail: () => {},
};

export default withStyles(tableStyle)(TableEventi);
