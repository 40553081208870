import React from 'react';
import { withStyles } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';
import cx from 'classnames';

const styles = theme => ({
  container: {
    flex: 1,
    margin: '0px 0px',
    textAlign: 'left',
    backgroundColor: theme.palette.grey.light,
  },
  content: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingTop: 20,
  },
  containerWhite: {
    backgroundColor: theme.palette.white.main,
  },
});

const PageContainer = ({ classes, header, children, whiteBg = false }) => (
  <div className={cx(classes.container, { [classes.containerWhite]: whiteBg })}>
    <Row style={{ textAlign: 'left', margin: 0 }}>
      {header && (
        <Col xs={12} sm={12} md={12} style={{ padding: 0 }}>
          {header}
        </Col>
      )}
      <Col xs={12} sm={12} md={12} className={classes.content}>
        {children}
      </Col>
    </Row>
  </div>
);

export default withStyles(styles)(PageContainer);
