import Client from './NewApiCallManager';
import { AxiosResponse } from 'axios';
import { SECTION_SERVICE_MAP } from '../components/serviziSociali/constants';
import {
  Interview,
  InterviewListResponse,
  CreateInterviewRequest,
  CreateSecretariatActivityRequest,
  SecretariatActivityListResponse,
  SecretariatActivity,
  InterviewListFilters,
} from '../components/serviziSociali/api/model';
import {
  WelfareInstanceSectionDTO,
  WelfareInstanceTypeDTO,
} from '../gen/api/welfare';

interface CountResponse {
  services: {
    service: WelfareInstanceTypeDTO;
    count: number;
  }[];
}

export type DashboardData = {
  [section in WelfareInstanceSectionDTO]: Partial<
    {
      [service in WelfareInstanceTypeDTO]: number;
    }
  >;
};

function countToDashboardDataTransformer(
  countData: CountResponse
): DashboardData {
  return countData.services.reduce(
    (acc, { service, count }) => {
      for (const section of Object.values<WelfareInstanceSectionDTO>(
        WelfareInstanceSectionDTO
      )) {
        if (SECTION_SERVICE_MAP[section].includes(service)) {
          acc[section][service] = count;
          return acc;
        }
      }
      return acc;
    },
    {
      [WelfareInstanceSectionDTO.Elders]: {} as DashboardData[WelfareInstanceSectionDTO.Elders],
      [WelfareInstanceSectionDTO.Poverty]: {} as DashboardData[WelfareInstanceSectionDTO.Poverty],
      [WelfareInstanceSectionDTO.Disabled]: {} as DashboardData[WelfareInstanceSectionDTO.Disabled],
      [WelfareInstanceSectionDTO.Family]: {} as DashboardData[WelfareInstanceSectionDTO.Family],
    }
  );
}

export default {
  dashboard: {
    get: (
      onSuccess: (data: DashboardData) => void,
      onError: (e?: Error) => void
    ) => {
      Client.get(
        '/welfare/instances/count',
        (response: AxiosResponse<CountResponse>) =>
          onSuccess(countToDashboardDataTransformer(response.data)),
        onError,
        { countByService: Object.values(WelfareInstanceTypeDTO) } //for dashboard we are interested in all services
      );
    },
  },
  journal: {
    interviews: {
      list: (
        query: {
          pageNumber: number;
          pageSize: number;
          filters: Partial<InterviewListFilters>;
        },
        onSuccess: (data: InterviewListResponse) => void,
        onError: (e?: Error) => void
      ) => {
        Client.get(
          `/welfare/journal/interviews`,
          (response: AxiosResponse<InterviewListResponse>) =>
            onSuccess(response.data),
          onError,
          {
            pageNumber: query.pageNumber,
            pageSize: query.pageSize,
            ...query.filters,
          }
        );
      },
      get: (
        id: string,
        onSuccess: (data: Interview) => void,
        onError: (e?: Error) => void
      ) => {
        Client.get(
          `/welfare/journal/interviews/${id}`,
          (response: AxiosResponse<Interview>) => onSuccess(response.data),
          onError
        );
      },
      create: (
        onSuccess: (data: Interview) => void,
        onError: (e?: Error) => void,
        data: CreateInterviewRequest
      ) => {
        Client.post(
          '/welfare/journal/interviews',
          (response: AxiosResponse<Interview>) => {
            onSuccess(response.data);
          },
          onError,
          data
        );
      },
    },
    secretariat: {
      list: (
        query: { pageNumber: number; pageSize: number },
        onSuccess: (data: SecretariatActivityListResponse) => void,
        onError: (e?: Error) => void
      ) => {
        Client.get(
          `/welfare/journal/secretariat`,
          (response: AxiosResponse<SecretariatActivityListResponse>) =>
            onSuccess(response.data),
          onError,
          query
        );
      },
      get: (
        id: string,
        onSuccess: (data: SecretariatActivity) => void,
        onError: (e?: Error) => void
      ) => {
        Client.get(
          `/welfare/journal/secretariat/${id}`,
          (response: AxiosResponse<SecretariatActivity>) =>
            onSuccess(response.data),
          onError
        );
      },
      create: (
        onSuccess: (data: SecretariatActivity) => void,
        onError: (e?: Error) => void,
        data: CreateSecretariatActivityRequest
      ) => {
        Client.post(
          '/welfare/journal/secretariat',
          (response: AxiosResponse<SecretariatActivity>) => {
            onSuccess(response.data);
          },
          onError,
          data
        );
      },
    },
  },
};
