import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  StyledComponentProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import tableStyle from '../../styles/table.style';
import Cittadini from '../../api/Cittadini';
import store from '../../store';
import { TableRowProcedure } from './TableRowProcedure';
import Loader from '../common/Loader';
import EmptyTableMessage from '../common/EmptyTableMessage';
import { Col, Row } from 'react-flexbox-grid';
import Pagination from '../common/Pagination';
import { TypePagination } from '../serviziSociali/constants';
import cx from 'classnames';

interface InstancesListProps extends StyledComponentProps {
  fiscalCode: string;
}

const styles = () => ({
  ...tableStyle(),
  paginationContainer: {
    margin: 0,
  },
  container: {
    paddingBottom: 30,
  },
});

const InstancesList: FC<InstancesListProps> = ({
  classes = {},
  fiscalCode,
}) => {
  const [page, setPage] = useState(0);
  const [instances, setInstances] = useState([]);
  const [pagination, setPagination] = useState<TypePagination>();
  const [loadingInstances, setLoadingInstances] = useState(false);

  const { profile, snackBar } = store.app;

  const loadInstances = useCallback(
    (pageNumber: number = 0, department) => {
      setLoadingInstances(true);
      setInstances([]);
      Cittadini.instances(
        {
          fiscalCode,
          department,
          pageNumber,
        },
        (data: any) => {
          setInstances(data.instances);
          setPagination(data.pagination);
          setLoadingInstances(false);
        },
        () => {
          snackBar.open(
            'Si è verificato un errore durante il caricamento delle istanze'
          );
        }
      );
    },
    [fiscalCode, snackBar]
  );

  useEffect(() => {
    loadInstances(page, profile.operatingAs.department);
  }, [page, loadInstances, profile]);

  const onPageChange = useCallback(changed => {
    setPage(changed.page - 1);
  }, []);

  return (
    <div className={cx(classes.tableContainer, classes.container)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headRow}>
            <TableCell className={classes.headCell} style={{ width: '12%' }}>
              N° Protocollo
            </TableCell>
            <TableCell className={classes.headCell} style={{ width: '12%' }}>
              Presentata Il
            </TableCell>
            <TableCell className={classes.headCell} style={{ width: '60%' }}>
              Tipologia
            </TableCell>
            <TableCell className={classes.headCell}>Stato</TableCell>
            <TableCell className={classes.headCell} />
          </TableRow>
        </TableHead>
        {instances.length > 0 && (
          <TableBody>
            {instances.map((i: any) => (
              <TableRowProcedure key={i.identifier} instance={i} />
            ))}
          </TableBody>
        )}
      </Table>
      <Loader size={40} loading={loadingInstances} />
      <EmptyTableMessage visible={!instances.length && !loadingInstances} />
      {pagination && (
        <Row className={classes.paginationContainer}>
          <Col xs={12}>
            <Pagination
              total={pagination.totalPages}
              current={pagination.currentPage + 1}
              totalElement={pagination.totalItemsCount}
              elementNumber={pagination.pageItemsCount}
              onChange={onPageChange}
              display={3}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export default withStyles(styles)(InstancesList);
