import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import {
  AppBar,
  Avatar,
  Button,
  Drawer,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MuiThemeProvider,
  Snackbar,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import {
  Link,
  Redirect,
  Route,
  Router,
  Switch,
  withRouter,
} from 'react-router-dom';
import store from './store';
import { observer } from 'mobx-react';
import './styles/react-select.css';
import './styles/react-datetime.css';
import 'flexboxgrid/dist/flexboxgrid.min.css';
import DefaultTheme from './styles/theme';
import Loader from './components/common/Loader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ROUTES /*, { ROUTES_SERVIZI_SOCIALI }*/, {
  ROUTES_SERVIZI_SOCIALI,
} from './constants/routes';
import Unauthorized from './components/Unauthorized';
import UnauthorizedPersonnel from './components/UnauthorizedPersonnel';
import {
  Close as CloseIcon,
  ExitToApp,
  Menu as MenuIcon,
  PersonOutline,
} from '@material-ui/icons';

import Login from './components/Login';
import ListaUtenti from './components/users/List';
import EditUtente from './components/users/Edit';
import DettaglioUtente from './components/users/Detail';
import EditGruppo from './components/departments/Edit';
import ListaGruppi from './components/departments/List';
import DettaglioGruppo from './components/departments/Detail';
import Audit from './components/audit';
import WorkSession from './components/audit/session';
import { AppLogo } from './components/Icons';
import history from './utils/history';
import Version from './api/Version';
import ListaPratiche from './components/legacy/List';
import DettaglioPratica from './components/legacy/Detail';
import DettaglioPraticaCondivisa from './components/share/Detail';
import DettaglioPraticaEdilizia from './components/edilizia/pratiche/Detail';
import ScadenziarioEdilizia from './components/edilizia/scadenziario/List';
import Send from './components/edilizia/scadenziario/Send';
import DocumentLoader from './components/DocumentLoader';
import StoricoScadenziario from './components/edilizia/scadenziario/StoricoScadenziario';
import Condivisione from './components/sharing';
import DettagliCondivisione from './components/sharing/Details';
import { postForm } from './utils/form';
import GestionePratiche from './components/edilizia/pratiche/List';
import GestioneCittadini from './components/cittadini/GestioneCittadini';
import DettaglioCittadino from './components/cittadini/DettaglioCittadino';
import DettaglioRichiesta from './components/cittadini/richieste/Detail';
import VirtualDesk from './components/virtualDesk';
import Profile from './components/users/Profile';
import navigateTo from './utils/navigate';
import * as Sentry from '@sentry/browser';
import { DEPARTMENTS, departmentsExcept, ROLES } from './constants/security';
import NotFoundPage from './components/NotFoundPage';
import ScrivaniaVirtuale from './components/virtualDesk/Detail';
import Certificati from './components/certificates';
import Icon from './new/components/icons';
import {
  ANALYTICS_URL,
  LOGOUT_URL,
  REDIRECT_URL,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_ENVIRONMENT,
} from './config';
import DepartmentIcon from './new/components/icons/departments';
import { getDepartmentLabel, getGroupHomepage } from './constants/common';
import { ServiziSocialiRouter } from './components/serviziSociali/ServiziSocialiSwitch';
import { NotificationBell } from './components/notification/NotificationBell';
import NotificationHandler from './new/components/notifications/NotificationHandler';
import DettagliCertificato from './components/certificates/DettagliCertificato';

Sentry.init({
  enabled: SENTRY_ENABLED,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
});

export const drawerWidth = 300;
const styles = theme => ({
  root: {
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontFamily: '"Titillium Web", "Roboto","Helvetica Neue",Arial,sans-serif',
    fontWeight: 'normal',
    lineHeight: '24px',
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  flex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    height: '100%',
    justifyContent: 'space-between',
  },
  appBarLogo: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRight: '1px solid #DCDCDC',
  },
  appBarLogoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '64px',
    width: drawerWidth - 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 24,
  },
  appBarLogoText: {
    color: '#5C6771',
    fontSize: '18px',
    marginLeft: '8px',
  },
  appBarContentContainer: {
    flex: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: '#5C6771',
    padding: '8px 24px',
    gap: '16px',
  },
  loginContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontFamily: '"Titillium Web", "Roboto","Helvetica Neue",Arial,sans-serif',
    textAlign: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    minWidth: '100%',
    minHeight: '100%',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '80px',
    flexGrow: 1,
    minWidth: 0, // So the Typography noWrap works
    [theme.breakpoints.up('md')]: {
      marginLeft: '300px',
    },
  },
  appBar: {
    position: 'fixed',
    height: 80,
    marginLeft: drawerWidth,
    backgroundColor: '#FFFFFF',
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    flex: 1,
    boxShadow: 'inset -1px 2px 7px rgba(0, 0, 0, 0.15)',
  },
  drawerPaper: {
    zIndex: '1000',
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      paddingTop: '80px',
    },
    background: '#FDFDFD',
    border: 'none',
  },
  nav: {
    marginTop: '0',
    float: 'none',
    display: 'block',
    flexWrap: 'wrap',
    paddingLeft: '0',
    marginBottom: '0',
    listStyle: 'none',
  },
  navDivider: {
    margin: '0 20px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
  },
  avatar: {
    backgroundColor: '#2667C5',
    textTransform: 'uppercase',
    color: '#fff',
    boxSizing: 'border-box',
    marginRight: '18px',
    fontWeight: 400,
    fontSize: '14px',
    height: 32,
    width: 32,
  },
  profileTrigger: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 102, 204, 0.06)',
    },
    borderRadius: '20px',
    padding: '12px',
  },
  userNameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  welcome: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#5C6771',
  },
  userName: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#2667C5',
  },
  bottomBar: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.9)',
    color: 'white',
    zIndex: '1000',
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 20px',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  menuImage: {
    height: '30px',
    width: '30px',
    marginRight: '15px',
  },
});

const PrivateRoute = ({ department, role, accessible, ...rest }) => {
  const profile = store.app.profile;
  const { routeComponent: Component } = rest;
  let allowed = false;
  if (accessible !== undefined) {
    allowed = accessible;
  } else {
    allowed = role
      ? profile.hasRoleIn(role, department)
      : profile.isMemberOf(department);
  }
  return (
    <Route
      {...rest}
      render={props =>
        profile.isAuthenticated ? (
          allowed ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: getGroupHomepage(
                  store.app.profile.operatingAs.department
                ),
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: `/${ROUTES.LOGIN}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

class ErrorHandler extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    return <StyledApp />;
  }
}

class App extends React.Component {
  state = {
    loading: false,
    mobileOpen: false,
    profileMenuState: null,
    newVersionAvailable: false,
  };

  componentDidMount() {
    //setInterval(this.checkVersion, 1000 * 60 * 10);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleAvatarClick = event => {
    this.setState({ profileMenuState: event.currentTarget });
  };

  handleProfileMenuClose = () => {
    this.setState({ profileMenuState: null });
  };

  handleMenuLogout = event => {
    this.handleProfileMenuClose(event);
    postForm(LOGOUT_URL, { redirect_url: REDIRECT_URL });
  };

  goToProfile = () => {
    navigateTo(`/${ROUTES.PROFILO}`);
  };

  checkVersion = () => {
    Version.getVersion(response => {
      const version = response.app_version;
      const url = document.getElementById('app-version').getAttribute('src');
      const queryParams = url.split('?').splice(-1)[0];
      const urlParams = new URLSearchParams(queryParams);
      const currVersion = urlParams.get('id');
      if (currVersion && version && version !== urlParams.get('id')) {
        this.refreshPage();
      }
    });
  };

  refreshPage = () => {
    window.location.reload();
  };

  render() {
    const { loading, profileMenuState } = this.state;
    const { classes, theme } = this.props;
    const profile = store.app.profile;
    const isAuthenticated = profile.isAuthenticated;
    const snackBar = store.app.snackBar;

    return (
      <MuiThemeProvider theme={DefaultTheme}>
        {loading ? (
          <Grid>
            <Row center="xs" style={{ marginTop: '50px' }}>
              <Col xs>
                <Loader loading={loading} size={40} />
              </Col>
            </Row>
          </Grid>
        ) : (
          <Router history={history}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <div className={classes.root}>
                {isAuthenticated && (
                  <>
                    <NotificationHandler />
                    <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={this.handleDrawerToggle}
                          className={classes.navIconHide}
                        >
                          <MenuIcon />
                        </IconButton>
                        <div className={classes.flex}>
                          <div className={classes.appBarLogoContainer}>
                            <div className={classes.appBarLogo}>
                              <AppLogo width={40} height={41} />
                              <span className={classes.appBarLogoText}>
                                Comune Di Chiavari
                              </span>
                            </div>
                          </div>
                          <div className={classes.appBarContentContainer}>
                            <NotificationBell
                              notifications={
                                profile.notificationListener.notifications
                              }
                              unreadCount={
                                profile.notificationListener
                                  .unreadNotificationCount
                              }
                            />
                            <div
                              className={classes.profileTrigger}
                              onClick={this.handleAvatarClick}
                            >
                              <Avatar
                                className={classes.avatar}
                                aria-owns={
                                  profileMenuState ? 'profileMenu' : null
                                }
                                aria-haspopup="true"
                              >
                                {profile.user.first_name[0] +
                                  profile.user.last_name[0]}
                              </Avatar>
                              <div className={classes.userNameContainer}>
                                <span className={classes.welcome}>
                                  Bentornato
                                </span>
                                <span className={classes.userName}>
                                  {profile.user.first_name}{' '}
                                  {profile.user.last_name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Menu
                          style={{ top: '50px' }}
                          id="profileMenu"
                          role="menu"
                          anchorEl={profileMenuState}
                          open={Boolean(profileMenuState)}
                          onClose={this.handleProfileMenuClose}
                        >
                          <MenuItem onClick={this.goToProfile}>
                            <ListItemIcon>
                              <PersonOutline />
                            </ListItemIcon>
                            <ListItemText inset primary="Profilo" />
                          </MenuItem>
                          <MenuItem onClick={this.handleMenuLogout}>
                            <ListItemIcon>
                              <ExitToApp />
                            </ListItemIcon>
                            <ListItemText inset primary="Logout" />
                          </MenuItem>
                        </Menu>
                      </Toolbar>
                    </AppBar>
                    <Hidden mdUp>
                      <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                          keepMounted: true, // Better open performance on mobile.
                        }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={this.handleDrawerToggle}
                          style={{
                            color: '#555',
                            textAlign: 'right',
                            marginLeft: '191px',
                          }}
                        >
                          <i className="fa fa-close" />
                        </IconButton>
                        <LeftMenu profile={profile} />
                      </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                      <Drawer
                        variant="permanent"
                        open
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                      >
                        <LeftMenu profile={profile} />
                      </Drawer>
                    </Hidden>
                  </>
                )}
                <div
                  className={
                    isAuthenticated ? classes.container : classes.loginContainer
                  }
                >
                  <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path={`/${ROUTES.LOGIN}`} exact component={Login} />
                    <Route
                      path="/documentLoading"
                      exact
                      component={DocumentLoader}
                    />
                    <Route
                      path={`/${ROUTES.UNAUTHORIZED}`}
                      component={Unauthorized}
                    />
                    <Route
                      path={`/${ROUTES.UNATHORIZED_PERSONNEL}`}
                      component={UnauthorizedPersonnel}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      path={`/${ROUTES.PROFILO}`}
                      routeComponent={Profile}
                      exact
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ADMIN}
                      path={`/${ROUTES.AUDIT}`}
                      routeComponent={Audit}
                      exact
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ADMIN}
                      path={`/${ROUTES.SESSIONI}/:sessionId`}
                      exact
                      routeComponent={WorkSession}
                    />
                    <PrivateRoute
                      accessible={
                        profile.isMemberOf(DEPARTMENTS.CED) ||
                        profile.hasRole([ROLES.MANAGER, ROLES.PO])
                      }
                      path={`/${ROUTES.UTENTI}`}
                      exact
                      routeComponent={ListaUtenti}
                    />
                    <PrivateRoute
                      accessible={
                        profile.isMemberOf(DEPARTMENTS.CED) ||
                        profile.hasRole([ROLES.MANAGER, ROLES.PO])
                      }
                      path={`/${ROUTES.NUOVO_UTENTE}`}
                      routeComponent={EditUtente}
                    />
                    <PrivateRoute
                      accessible={
                        profile.isMemberOf(DEPARTMENTS.CED) ||
                        profile.hasRole([ROLES.MANAGER, ROLES.PO])
                      }
                      path={`/${ROUTES.UTENTI}/:idUtente`}
                      exact
                      routeComponent={DettaglioUtente}
                    />
                    <PrivateRoute
                      accessible={
                        profile.isMemberOf(DEPARTMENTS.CED) ||
                        profile.hasRole([ROLES.MANAGER, ROLES.PO])
                      }
                      path={`/${ROUTES.UTENTI}/:idUtente/edit`}
                      exact
                      routeComponent={EditUtente}
                    />
                    <PrivateRoute
                      department={[DEPARTMENTS.ADMIN, DEPARTMENTS.CED]}
                      path={`/${ROUTES.DIPARTIMENTI}`}
                      exact
                      routeComponent={ListaGruppi}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ADMIN}
                      path={`/${ROUTES.DIPARTIMENTI}/:idGruppo/edit`}
                      exact
                      routeComponent={EditGruppo}
                    />
                    <PrivateRoute
                      department={[DEPARTMENTS.CED, DEPARTMENTS.ADMIN]}
                      exact
                      path={`/${ROUTES.DIPARTIMENTI}/:idGruppo`}
                      routeComponent={DettaglioGruppo}
                    />

                    <PrivateRoute
                      department={DEPARTMENTS.LOGISTIC}
                      exact
                      path={`/${ROUTES.ARCHIVIO_GATE}`}
                      routeComponent={ListaPratiche}
                    />

                    <PrivateRoute
                      department={DEPARTMENTS.LOGISTIC}
                      exact
                      path={`/${ROUTES.ARCHIVIO_GATE}/:procedureId`}
                      routeComponent={DettaglioPratica}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.BUILDING}
                      exact
                      path={`/${ROUTES.SCADENZIARIO_EDILIZIA}`}
                      routeComponent={ScadenziarioEdilizia}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.BUILDING}
                      exact
                      path={`/${ROUTES.SCADENZIARIO_EDILIZIA_STORICO}`}
                      routeComponent={StoricoScadenziario}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.BUILDING}
                      exact
                      path={`/${ROUTES.SCADENZIARIO_EDILIZIA_INVIO_PRATICA}`}
                      routeComponent={Send}
                    />
                    <PrivateRoute
                      department={[DEPARTMENTS.ADMIN, DEPARTMENTS.BUILDING]}
                      exact
                      path={`/${ROUTES.PRATICHE_EDILIZIA}`}
                      routeComponent={GestionePratiche}
                    />
                    <PrivateRoute
                      department={[DEPARTMENTS.ADMIN, DEPARTMENTS.BUILDING]}
                      exact
                      path={`/${ROUTES.PRATICHE_EDILIZIA}/:procedureId`}
                      routeComponent={DettaglioPraticaEdilizia}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      exact
                      path={`/${ROUTES.CONDIVISIONE}`}
                      routeComponent={Condivisione}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      exact
                      path={`/${ROUTES.DETTAGLIO_CONDIVISIONE}`}
                      routeComponent={DettagliCondivisione}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      exact
                      path={`/${ROUTES.CONDIVISIONE}/:shareId`}
                      routeComponent={DettaglioPraticaCondivisa}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      exact
                      path={`/${ROUTES.CITTADINI}`}
                      routeComponent={GestioneCittadini}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      exact
                      path={`/${ROUTES.DETTAGLIO_CITTADINO}`}
                      routeComponent={DettaglioCittadino}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      exact
                      path={`/${ROUTES.RICHIESTE}/:requestId`}
                      routeComponent={DettaglioRichiesta}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      role={ROLES.MANAGER}
                      exact
                      path={`/${ROUTES.SCRIVANIA_VIRTUALE}`}
                      routeComponent={VirtualDesk}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.ANY}
                      role={ROLES.MANAGER}
                      exact
                      path={`/${ROUTES.PRATICA_INTERNA}`}
                      routeComponent={ScrivaniaVirtuale}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.SERVIZI_DEMOGRAFICI}
                      exact
                      path={`/${ROUTES.CERTIFICATI_DEMOGRAFICI}`}
                      routeComponent={Certificati}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.STATO_CIVILE}
                      exact
                      path={`/${ROUTES.CERTIFICATI_STATO_CIVILE}`}
                      routeComponent={Certificati}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.SERVIZI_DEMOGRAFICI}
                      exact
                      path={`/${ROUTES.DEMOGRAFICI_DETTAGLIO}`}
                      routeComponent={DettagliCertificato}
                    />
                    <PrivateRoute
                      department={DEPARTMENTS.STATO_CIVILE}
                      exact
                      path={`/${ROUTES.STATO_CIVILE_DETTAGLIO}`}
                      routeComponent={DettagliCertificato}
                    />
                    <ServiziSocialiRouter />

                    <Route component={NotFoundPage} />
                  </Switch>
                </div>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={snackBar.isOpen}
                  onClose={snackBar.close}
                  SnackbarContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  autoHideDuration={6000}
                  message={<span id="message-id">{snackBar.message}</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={snackBar.close}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
              </div>
              {this.state.newVersionAvailable && (
                <div className={classes.bottomBar}>
                  <p>
                    <b>ATTENZIONE: </b> E&apos; disponibile un nuova versione
                    della DashboardList!
                  </p>
                  <Button
                    type="flat"
                    color="primary"
                    onClick={this.refreshPage}
                  >
                    AGGIORNA ORA
                  </Button>
                </div>
              )}
            </div>
          </Router>
        )}
      </MuiThemeProvider>
    );
  }
}

const StyledApp = withStyles(styles, { withTheme: true })(observer(App));

const menuItemStyle = () => ({
  navLink: {
    cursor: 'pointer',
    opacity: '.86',
    padding: '12px 28px',
    display: 'flex',
    alignItems: 'center',
    color: '#142373',
    '&:hover': {
      background: 'rgba(0, 102, 204, 0.06)',
      opacity: '1',
    },
    '&:active': {
      opacity: '1',
      background: 'rgba(0, 102, 204, 0.06)',
    },
  },
  navLinkActive: {
    cursor: 'pointer',
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    color: '#2667C5',
    fontWeight: 'bold',
    opacity: '1',
    background: 'rgba(0, 102, 204, 0.06)',
    borderLeft: '4px solid #0073E6',
  },
  menuIcon: {
    fontSize: '28px',
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  text: {
    fontSize: 14,
    lineHeight: '21px',
  },
});

const LeftMenuItem = withRouter(
  withStyles(menuItemStyle)(
    class extends React.Component {
      static propTypes = {
        text: PropTypes.string,
        route: PropTypes.string,
        visible: PropTypes.bool,
        icon: PropTypes.string,
        image: PropTypes.object,
      };

      static defaultProps = {
        visible: false,
      };

      redirect = () => {
        window.open(this.props.redirectTo, '_blank');
      };

      render() {
        const {
          text,
          route,
          redirectTo,
          classes,
          visible,
          icon,
          image,
        } = this.props;
        const isActive = window.location.pathname.startsWith(`/${route}`);
        const linkProps = { pathname: `/${route}`, key: Date.now() };

        return (
          visible &&
          (redirectTo ? (
            <li
              onClick={this.redirect}
              className={isActive ? classes.navLinkActive : classes.navLink}
            >
              {!image ? (
                <Icon className={classes.menuIcon} type={icon} />
              ) : (
                image
              )}
              <span className={classes.text}>{text}</span>
            </li>
          ) : (
            <Link to={linkProps} style={{ textDecoration: 'none' }}>
              <li
                className={isActive ? classes.navLinkActive : classes.navLink}
              >
                {!image ? (
                  <Icon className={classes.menuIcon} type={icon} />
                ) : (
                  image
                )}
                <span className={classes.text}>{text}</span>
              </li>
            </Link>
          ))
        );
      }
    }
  )
);

const dividerStyles = () => ({
  divider: {
    height: 48,
    paddingLeft: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  bar: {
    width: 95,
    height: 1,
    opacity: 0.5,
    backgroundColor: '#0073E6',
  },
});

const LeftMenuDivider = withStyles(dividerStyles)(({ classes }) => {
  return (
    <div className={classes.divider}>
      <div className={classes.bar} />
    </div>
  );
});

const sectionStyles = () => ({
  container: {
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#636363',
    marginTop: '24px',
  },
});

const LeftMenuSection = withStyles(sectionStyles)(
  ({ classes, title, visible = false }) => {
    return visible ? <div className={classes.container}>{title}</div> : null;
  }
);

const departmentSelectStyles = () => ({
  container: {
    padding: '20px 24px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '&:hover': {
      background: 'rgba(0, 102, 204, 0.06)',
      opacity: '1',
      cursor: 'pointer',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  department: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 700,
    color: '#2667C5',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    color: '#5C6771',
  },
  separator: {
    margin: '0 24px',
    flex: 1,
    borderBottom: '1px solid rgba(168, 176, 184, 0.5)',
  },
  icon: {
    marginRight: '12px',
  },
  empty: {
    paddingTop: '14px',
  },
});

const DepartmentSelect = withRouter(
  withStyles(departmentSelectStyles)(({ classes, history }) => {
    const { profile } = store.app;
    const [selector, setSelector] = useState();

    const onSelectorClicked = useCallback(event => {
      setSelector(event.currentTarget);
    }, []);

    const onClose = useCallback(() => {
      setSelector(undefined);
    }, []);

    const departments = useMemo(() => {
      return profile.isMemberOf(DEPARTMENTS.ADMIN, true)
        ? departmentsExcept(DEPARTMENTS.CED)
        : Array.from(new Set(profile.user.roles.map(r => r.department)));
    }, [profile]);

    const onItemClicked = useCallback(
      department => {
        profile.switchDepartment(department);
        const homepage = getGroupHomepage(department);
        history.replace(`${homepage}`);
        onClose();
      },
      [history, onClose, profile]
    );

    return departments.length > 1 ? (
      <div style={{ marginBottom: '14px' }}>
        <div
          className={classes.container}
          onClick={onSelectorClicked}
          aria-owns={selector}
        >
          <DepartmentIcon
            className={classes.icon}
            department={profile.operatingAs.department}
            size={32}
          />
          <div className={classes.content}>
            <div className={classes.label}>
              <span className={classes.department}>
                {getDepartmentLabel(profile.operatingAs.department)}
              </span>
              <span className={classes.subtitle}>
                Gestisci un altro ufficio
              </span>
            </div>
            <Icon type="ChevronDown" color="#414141" size={8} />
          </div>
        </div>
        <div className={classes.separator} />
        <Menu
          style={{ top: '78px' }}
          id="departmentMenu"
          role="menu"
          anchorEl={selector}
          open={Boolean(selector)}
          onClose={onClose}
        >
          {departments.map(d => (
            <MenuItem
              key={d}
              selected={profile.operatingAs.department === d}
              onClick={() => onItemClicked(d)}
            >
              <ListItemIcon>
                <DepartmentIcon department={d} />
              </ListItemIcon>
              <ListItemText inset primary={getDepartmentLabel(d)} />
            </MenuItem>
          ))}
        </Menu>
      </div>
    ) : (
      <div className={classes.empty} />
    );
  })
);

const LeftMenu = withStyles(styles)(({ classes, profile }) => {
  return (
    <div className={classes.toolbar}>
      <ul className={classes.nav}>
        <DepartmentSelect />
        <LeftMenuItem
          icon="Document"
          text="Richieste Certificati"
          route={ROUTES.CERTIFICATI_DEMOGRAFICI}
          visible={profile.isMemberOf(DEPARTMENTS.SERVIZI_DEMOGRAFICI)}
        />
        <LeftMenuItem
          icon="Document"
          text="Richieste Certificati"
          route={ROUTES.CERTIFICATI_STATO_CIVILE}
          visible={profile.isMemberOf(DEPARTMENTS.STATO_CIVILE)}
        />
        <LeftMenuItem
          icon="Archive"
          text="Elenco istanze"
          route={ROUTES.ARCHIVIO_GATE}
          visible={profile.isMemberOf(DEPARTMENTS.LOGISTIC)}
        />
        <LeftMenuItem
          icon="Archive"
          text="Elenco istanze"
          route={ROUTES_SERVIZI_SOCIALI.LISTA_PRATICHE}
          visible={profile.isMemberOf(DEPARTMENTS.SOCIAL_SERVICES)}
        />
        <LeftMenuItem
          icon="AnalyticsV2"
          text="Dashboard"
          route={ROUTES_SERVIZI_SOCIALI.DASHBOARD_BASE}
          visible={profile.isMemberOf(DEPARTMENTS.SOCIAL_SERVICES)}
        />
        <LeftMenuItem
          icon="Diary"
          text="Diario"
          route={ROUTES_SERVIZI_SOCIALI.DIARIO}
          visible={profile.isMemberOf(DEPARTMENTS.SOCIAL_SERVICES)}
        />
        <LeftMenuItem
          icon="Document"
          text="Elenco istanze"
          route={ROUTES.PRATICHE_EDILIZIA}
          visible={profile.isMemberOf(DEPARTMENTS.BUILDING)}
        />
        <LeftMenuItem
          icon="Users"
          text="Cittadini"
          route={ROUTES.CITTADINI}
          visible={profile.isMemberOf(DEPARTMENTS.ANY)}
        />
        <LeftMenuDivider />
        <LeftMenuItem
          icon="SharingCircle"
          text="Pareri da altri uffici"
          route={ROUTES.CONDIVISIONE}
          visible={profile.isMemberOf(departmentsExcept(DEPARTMENTS.CED))}
        />
        <LeftMenuItem
          icon="VirtualDesk"
          text="Atti Dirigenziali"
          route={ROUTES.SCRIVANIA_VIRTUALE}
          visible={profile.hasRole(ROLES.MANAGER)}
        />
        <LeftMenuItem
          icon="Analytics"
          text="Dati di pubblico interesse"
          redirectTo={ANALYTICS_URL}
          visible={
            profile.hasRole(ROLES.MANAGER) &&
            !profile.isMemberOf(DEPARTMENTS.CED)
          }
        />
        <LeftMenuSection
          title="Gestione"
          visible={profile.hasRole([ROLES.MANAGER, ROLES.PO])}
        />
        <LeftMenuItem
          icon="List"
          text="Audit"
          route={ROUTES.AUDIT}
          visible={profile.isMemberOf(DEPARTMENTS.ADMIN)}
        />
        <LeftMenuItem
          icon="Buildings"
          text="Uffici Comunali"
          route={ROUTES.DIPARTIMENTI}
          visible={profile.isMemberOf([DEPARTMENTS.ADMIN, DEPARTMENTS.CED])}
        />
        <LeftMenuItem
          icon="User"
          text="Utenti del comune"
          route={ROUTES.UTENTI}
          visible={profile.isMemberOf([DEPARTMENTS.ADMIN, DEPARTMENTS.CED])}
        />
        <LeftMenuItem
          icon="User"
          text="Utenti"
          route={ROUTES.UTENTI}
          visible={
            profile.hasRole([ROLES.MANAGER, ROLES.PO]) &&
            !profile.isMemberOf(DEPARTMENTS.ADMIN)
          }
        />
      </ul>
    </div>
  );
});

render(<ErrorHandler />, document.getElementById('root'));
