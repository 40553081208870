/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FlagTipoAccessoValueDTO {
    ProcuraOrdinaria = 'PROCURA_ORDINARIA',
    AccessoSpontaneo = 'ACCESSO_SPONTANEO',
    Familiari = 'FAMILIARI',
    ViciniDiCasa = 'VICINI_DI_CASA',
    MedicoDiBase = 'MEDICO_DI_BASE',
    UnitaValutazioneGeriatrica = 'UNITA_VALUTAZIONE_GERIATRICA',
    ProgettoMeglioACasa = 'PROGETTO_MEGLIO_A_CASA',
    ServizioSanitario = 'SERVIZIO_SANITARIO',
    RepartoOspedaliero = 'REPARTO_OSPEDALIERO',
    Sert = 'SERT',
    NucleoAlcologia = 'NUCLEO_ALCOLOGIA',
    SaluteMentale = 'SALUTE_MENTALE',
    ForzeDellOrdine = 'FORZE_DELL_ORDINE',
    EsecuzionePenaleEsterna = 'ESECUZIONE_PENALE_ESTERNA',
    GiudiceTutelare = 'GIUDICE_TUTELARE',
    Volontariato = 'VOLONTARIATO'
}

export function FlagTipoAccessoValueDTOFromJSON(json: any): FlagTipoAccessoValueDTO {
    return FlagTipoAccessoValueDTOFromJSONTyped(json, false);
}

export function FlagTipoAccessoValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlagTipoAccessoValueDTO {
    return json as FlagTipoAccessoValueDTO;
}

export function FlagTipoAccessoValueDTOToJSON(value?: FlagTipoAccessoValueDTO | null): any {
    return value as any;
}

