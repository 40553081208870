import React from 'react';
import classNames from 'classnames';
import Subsection from './Subsection';
import { withStyles } from '@material-ui/core';
import { Col, Row } from 'react-flexbox-grid';

const styles = theme => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
    width: '100%',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
    marginRight: '10px',
  },
  actionContainer: {
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px',
    paddingBottom: 0,
  },
});

export default withStyles(styles)(props => {
  const {
    children,
    classes,
    icon,
    title,
    actions = [],
    headerActions = [],
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <i className={classNames(classes.headerIcon, `nc-icon ${icon}`)} />
        <span className={classes.headerTitle}>{title}</span>
        {headerActions.length > 0 && (
          <Col className={classes.headerAction}>{headerActions}</Col>
        )}
      </div>
      <Subsection>
        <Row>{children}</Row>
        {actions.length > 0 && (
          <Row className={classes.actionContainer}>{actions}</Row>
        )}
      </Subsection>
    </div>
  );
});
