import { createAction } from '@reduxjs/toolkit';
import { WelfareInstanceEventDTO } from '../../gen/api/welfare';

export enum ProcedureEventsEvents {
  eventsLoaded = 'events/eventsLoaded',
  errorLoading = 'events/errorLoading',
  notFound = 'events/notFound',
  addedNewReply = 'events/addedNewReply',
  reloadRequested = 'events/reloadRequested',
}

export const eventsLoaded = createAction(ProcedureEventsEvents.eventsLoaded);
export const errorLoading = createAction(ProcedureEventsEvents.errorLoading);

export const notFound = createAction(ProcedureEventsEvents.notFound);

export const addedNewReply = createAction<WelfareInstanceEventDTO>(
  ProcedureEventsEvents.addedNewReply
);

export type AddedNewReply = ReturnType<typeof addedNewReply>;

export const reloadRequested = createAction(
  ProcedureEventsEvents.reloadRequested
);
