/* tslint:disable */
/* eslint-disable */
/**
 * Chiavari Welfare office
 * This is the api used by the chiavari backoffice for welfare operations
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: fabrizio.cavaniglia@moveax.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WelfareInstanceStatusDTO {
    Created = 'CREATED',
    Assigned = 'ASSIGNED',
    ApprovedByEmployee = 'APPROVED_BY_EMPLOYEE',
    RejectedByEmployee = 'REJECTED_BY_EMPLOYEE',
    ApprovedByPo = 'APPROVED_BY_PO',
    Rejected = 'REJECTED',
    ApprovedByAdministrative = 'APPROVED_BY_ADMINISTRATIVE',
    Approved = 'APPROVED'
}

export function WelfareInstanceStatusDTOFromJSON(json: any): WelfareInstanceStatusDTO {
    return WelfareInstanceStatusDTOFromJSONTyped(json, false);
}

export function WelfareInstanceStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelfareInstanceStatusDTO {
    return json as WelfareInstanceStatusDTO;
}

export function WelfareInstanceStatusDTOToJSON(value?: WelfareInstanceStatusDTO | null): any {
    return value as any;
}

