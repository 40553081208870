import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  EXTENSION_STATUS,
  getLabelExtensionState,
} from '../../constants/common';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  statusBadge: {
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '20px',
    color: '#fff',
  },
  stateNew: {
    backgroundColor: '#0277bd',
  },
  stateRejected: {
    backgroundColor: '#ba0101',
  },
  stateApproved: {
    backgroundColor: '#00600f',
  },
});

const ProcedureExtensionStatus = withStyles(styles)(props => {
  const { classes, state } = props;

  return (
    <span
      className={classNames({
        [classes.statusBadge]: true,
        [classes.stateNew]: state === EXTENSION_STATUS.NEW,
        [classes.stateApproved]: state === EXTENSION_STATUS.APPROVED,
        [classes.stateRejected]: state === EXTENSION_STATUS.REJECTED,
      })}
    >
      {getLabelExtensionState(state)}
    </span>
  );
});

ProcedureExtensionStatus.propTypes = {
  state: PropTypes.string.isRequired,
};

export default ProcedureExtensionStatus;
