import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { withStyles } from '@material-ui/core';
import DetailElement from '../../../common/DetailElement';
import StatusBadge from '../../Common/StatusBadge';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { formatTimestamp } from '../../../../utils/dateutils';
import { getDepartmentLabel } from '../../../../constants/common';

const styles = () => ({
  container: {
    minHeight: '200px',
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  details: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
});

const SharedInstanceDetail = props => {
  const { classes, instance, isOwner, isManager } = props;

  return (
    <div className={classes.container}>
      <span>
        <Row>
          <Col xs={12}>
            <ul className={classes.details}>
              <DetailElement
                label="Stato"
                value={
                  <StatusBadge
                    style={{ marginTop: '5px' }}
                    state={instance.status}
                  />
                }
                icon="nc-icon nc-chart-bar-32"
                separator
              />
              <DetailElement
                label={isOwner ? 'Condivisa Con' : 'Condivisa Da'}
                icon="nc-icon nc-time-alarm"
                value={getDepartmentLabel(
                  isOwner ? instance.destDepartment : instance.ownerDepartment
                )}
                separator
              />
              <DetailElement
                label="Oggetto"
                value={instance.subject}
                icon="nc-icon nc-email-83"
                separator
              />
              <DetailElement
                label="Data di creazione"
                value={formatTimestamp(instance.createdAt)}
                icon="nc-icon nc-time-alarm"
                separator={!isOwner && isManager}
              />
              {!isOwner && isManager && (
                <DetailElement
                  icon="nc-icon nc-single-02"
                  label="Assegnata A"
                  value={
                    instance.assignedTo
                      ? `${instance.assignedTo.firstName} ${instance.assignedTo.lastName}`
                      : 'Non Assegnata'
                  }
                />
              )}
            </ul>
          </Col>
        </Row>
      </span>
    </div>
  );
};

SharedInstanceDetail.propTypes = {
  instance: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles)(SharedInstanceDetail));
