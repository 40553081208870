import CallManager from './AxiosCallManager';
import NewApiCallManager from './NewApiCallManager';

export default {
  loadCittadini: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      '/citizens',
      response => onSuccess(response.data),
      onError,
      { page, ...filters }
    );
  },
  requestList: ({ page, filters }, onSuccess, onError) => {
    CallManager.get(
      '/registration_requests',
      response => onSuccess(response.data),
      onError,
      { page, ...filters }
    );
  },
  getRequest: (id, onSuccess, onError) => {
    CallManager.get(
      `/registration_requests/${id}`,
      response => onSuccess(response.data),
      onError
    );
  },
  approveRequest: (id, onSuccess, onError) => {
    CallManager.patch(
      `/registration_requests/${id}/process`,
      response => onSuccess(response.data),
      onError,
      {
        action: 'APPROVED',
      }
    );
  },
  rejectRequest: (id, reason, onSuccess, onError) => {
    CallManager.patch(
      `/registration_requests/${id}/process`,
      response => onSuccess(response.data),
      onError,
      {
        action: 'REJECTED',
        reason,
      }
    );
  },
  editRequest: (id, data, onSuccess, onError) => {
    CallManager.patch(
      `/registration_requests/${id}`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  editPerson: (id, data, onSuccess, onError) => {
    CallManager.patch(
      `/citizens/${id}`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  getCittadinoByFiscalCode: (fiscalCode, onSuccess, onError) => {
    CallManager.get(
      `/citizens/${fiscalCode}`,
      response => onSuccess(response.data),
      onError
    );
  },
  createCitizen: (data, onSuccess, onError) => {
    CallManager.post(
      `citizens`,
      response => onSuccess(response.data),
      onError,
      data
    );
  },
  instances: (
    { fiscalCode, department, pageNumber = 0, pageSize = 15 },
    onSuccess,
    onError
  ) => {
    NewApiCallManager.get(
      `/citizen/instances`,
      response => onSuccess(response.data),
      onError,
      {
        fiscalCode,
        department,
        pageNumber,
        pageSize,
      }
    );
  },
};
