/* eslint-disable react/no-array-index-key */

import React, { useCallback, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import store from '../../../../store';
import { ROLES } from '../../../../constants/security';
import { SHARED_INSTANCE_STATUS } from '../../../../constants/common';
import SharedInstanceDetail from './SharedInstanceDetail';
import { Row, Col } from 'react-flexbox-grid';
import MessageRow from './MessageRow';
import ReplyBox from './ReplyBox';

const styles = () => ({
  messages: {
    padding: '0 16px',
    marginBottom: '170px',
  },
});

const Conversation = ({ classes, instance }) => {
  const { profile } = store.app;
  const { sharedInstance } = instance;
  const [messages, setMessages] = useState(instance.sharedInstance.messages);

  const isCreator = useMemo(() => {
    return (
      profile.isMemberOf(sharedInstance.ownerDepartment) &&
      sharedInstance.sharedBy.id === profile.user.id
    );
  }, [profile, sharedInstance]);

  const isOwner = useMemo(() => {
    return (
      profile.isHigherOrEqualThanForDepartment([
        {
          department: sharedInstance.ownerDepartment,
          role: ROLES.PO,
        },
      ]) || isCreator
    );
  }, [profile, sharedInstance.ownerDepartment, isCreator]);

  const isAssignee = useMemo(() => {
    return (
      profile.isHigherOrEqualThanForDepartment([
        {
          department: sharedInstance.destDepartment,
          role: ROLES.PO,
        },
      ]) ||
      (profile.isMemberOf(sharedInstance.destDepartment) &&
        profile.user.id === sharedInstance.assignedTo?.id)
    );
  }, [profile, sharedInstance.destDepartment, sharedInstance.assignedTo]);

  const isManager = useMemo(() => {
    return (
      sharedInstance &&
      profile.isHigherOrEqualThanForDepartment([
        {
          department: sharedInstance.destDepartment,
          role: ROLES.PO,
        },
      ])
    );
  }, [sharedInstance, profile]);

  const canReply = useMemo(() => {
    return (
      sharedInstance.status === SHARED_INSTANCE_STATUS.OPEN &&
      (isOwner || isAssignee)
    );
  }, [sharedInstance.status, isAssignee, isOwner]);

  const onNewReply = useCallback(
    message => {
      setMessages([...messages, message]);
    },
    [messages]
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SharedInstanceDetail
            instance={sharedInstance}
            isOwner={isOwner}
            isManager={isManager}
          />
          <Row className={classes.messages}>
            {messages.map((m, i) => {
              return (
                <MessageRow key={i} message={m} instance={sharedInstance} />
              );
            })}
          </Row>
        </Col>
      </Row>
      {canReply && (
        <ReplyBox instance={sharedInstance} onNewReply={onNewReply} />
      )}
    </>
  );
};

export default withStyles(styles)(Conversation);
