import React, { useCallback, useEffect, useState } from 'react';
import store from '../../../store';
import { Col, Row } from 'react-flexbox-grid';
import EmptyListMessage from '../../common/EmptyListMessage';
import Pagination from '../../common/Pagination';
import ListItem from './ListItem';
import Loader from '../../common/Loader';
import PropTypes from 'prop-types';
import ListHeader from './ListHeader';
import { Tab, Tabs } from '@material-ui/core';
import {
  CERTIFICATE_REQUEST_STATE,
  CERTIFICATE_TYPES,
  getCertificateTypeLabel,
} from '../../../constants/common';
import GenericListFilters from '../../common/filters/GenericListFilters';
import FilterItem from '../../common/filters/Filter';
import { FILTER_TYPE } from '../../common/filters/constants';
import moment from 'moment';
import { DEPARTMENTS } from '../../../constants/security';

const FILTRI_TIPO_CERTIFICATO = Object.entries(CERTIFICATE_TYPES).map(
  ([_, v]) => {
    return {
      value: v,
      description: getCertificateTypeLabel(v),
    };
  }
);

const FILTRI_TIPO_CERTIFICATO_DEMOGRAFICI = FILTRI_TIPO_CERTIFICATO.filter(
  ({ value }) =>
    ![
      CERTIFICATE_TYPES.ANAGRAFICO_DI_MATRIMONIO,
      CERTIFICATE_TYPES.ANAGRAFICO_DI_MORTE,
      CERTIFICATE_TYPES.ANAGRAFICO_DI_NASCITA,
      CERTIFICATE_TYPES.STORICO_RESIDENZA_DALLA_DATA,
    ].includes(value)
);

const FILTRI_TIPO_CERTIFICATO_STATO_CIVILE = FILTRI_TIPO_CERTIFICATO.filter(
  ({ value }) =>
    [
      CERTIFICATE_TYPES.ANAGRAFICO_DI_MATRIMONIO,
      CERTIFICATE_TYPES.ANAGRAFICO_DI_MORTE,
      CERTIFICATE_TYPES.ANAGRAFICO_DI_NASCITA,
    ].includes(value)
);

const List = props => {
  const { onItemSelected, department } = props;
  const { snackBar } = store.app;
  const [currTab, setCurrentTab] = useState('PENDING');
  const [loading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [pagination, setPagination] = useState(undefined);

  //FILTERS
  const [certificateTypeFilter, setCertificateTypeFilter] = useState(undefined);
  const [requestorSearchString, setRequestorSearchString] = useState(undefined);
  const [handlerSearchString, setHandlerSearchString] = useState(undefined);

  const [createdBeforeFilter, setCreatedBeforeFilter] = useState(undefined);
  const [createdAfterFilter, setCreatedAfterFilter] = useState(undefined);

  const loadList = useCallback(
    (page = 0) => {
      setIsLoading(true);
      props.api.list(
        {
          page,
          filters: {
            status:
              currTab === 'PENDING'
                ? [
                    CERTIFICATE_REQUEST_STATE.FAILED,
                    CERTIFICATE_REQUEST_STATE.HANDLED,
                  ]
                : [
                    CERTIFICATE_REQUEST_STATE.SUCCESSFUL,
                    CERTIFICATE_REQUEST_STATE.RESOLVED,
                  ],
            type: [certificateTypeFilter],
            requestorSearchString,
            handlerSearchString,
            createdAfter: createdAfterFilter,
            createdBefore: createdBeforeFilter,
          },
        },
        response => {
          setRequests(response.certificates);
          setPagination(response.pagination);
          setIsLoading(false);
        },
        () => {
          snackBar.open('Si è verificato un errore imprevisto');
        }
      );
    },
    [
      certificateTypeFilter,
      createdAfterFilter,
      createdBeforeFilter,
      currTab,
      handlerSearchString,
      props.api,
      requestorSearchString,
      snackBar,
    ]
  );

  const applyFilters = useCallback(filters => {
    setRequestorSearchString(filters['requestorSearchString']);
    setCertificateTypeFilter(filters['certificateTypeFilter']);
    setCreatedAfterFilter(
      filters['createdAfterFilter']
        ? moment.unix(filters['createdAfterFilter']).toISOString()
        : undefined
    );
    setCreatedBeforeFilter(
      filters['createdBeforeFilter']
        ? moment.unix(filters['createdBeforeFilter']).toISOString()
        : undefined
    );
    setHandlerSearchString(filters['handlerSearchString']);
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList, currTab]);

  const onPageChange = useCallback(
    changedPage => {
      loadList(changedPage.page - 1);
    },
    [loadList]
  );

  const onTabChange = useCallback(
    (_, tabId) => {
      setCurrentTab(tabId);
    },
    [setCurrentTab]
  );

  return (
    <>
      <Tabs
        value={currTab}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value="PENDING" label="RICHIESTE FALLITE" />
        <Tab value="DONE" label="CERTIFICATI EMESSI" />
      </Tabs>
      <div>
        <GenericListFilters
          filters={[
            new FilterItem(
              'requestorSearchString',
              FILTER_TYPE.TEXT,
              'Richiedente (Nome, Cognome o CF)',
              '',
              '',
              6,
              4,
              4,
              3
            ),
            new FilterItem(
              'certificateTypeFilter',
              FILTER_TYPE.SELECT,
              'Tipo di certificato',
              '',
              department === DEPARTMENTS.SERVIZI_DEMOGRAFICI
                ? FILTRI_TIPO_CERTIFICATO_DEMOGRAFICI
                : FILTRI_TIPO_CERTIFICATO_STATO_CIVILE,
              6,
              8,
              4,
              3
            ),
            new FilterItem(
              'createdAfterFilter',
              FILTER_TYPE.DATE,
              'Data creazione dal',
              '',
              null,
              6,
              4,
              2,
              2,
              date => date.startOf('day')
            ),
            new FilterItem(
              'createdBeforeFilter',
              FILTER_TYPE.DATE,
              'Data creazione fino al',
              '',
              null,
              6,
              4,
              2,
              2,
              date => date.endOf('day')
            ),
            new FilterItem(
              'handlerSearchString',
              FILTER_TYPE.TEXT,
              'In Carico a (Nome, Cognome o CF)',
              '',
              '',
              6,
              4,
              4,
              3
            ),
          ]}
          applyFilters={applyFilters}
        />
      </div>
      <Row>
        <Col xs={12}>
          <Loader size={40} loading={loading} />
          {!loading && requests.length > 0 && (
            <>
              <div style={{ display: 'flex', flex: 1, marginBottom: '4px' }}>
                <div
                  style={{
                    justifySelf: 'end',
                    marginLeft: 'auto',
                    fontSize: '16px',
                  }}
                >
                  TOTALE RICHIESTE{' '}
                  <strong>{pagination.totalItemsCount ?? 0}</strong>
                </div>
              </div>
              <ListHeader />
              {requests.map(r => (
                <ListItem
                  key={r.id}
                  request={r}
                  onClick={() => onItemSelected(r)}
                  api={props.api}
                />
              ))}
            </>
          )}
          <EmptyListMessage visible={requests.length === 0 && !loading} />
          {pagination && (
            <Row>
              <Col xs={12}>
                <Pagination
                  total={pagination.totalPages}
                  current={pagination.currentPage + 1}
                  totalElement={pagination.totalItemsCount}
                  elementNumber={pagination.pageItemsCount}
                  onChange={onPageChange}
                  display={3}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

List.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  department: PropTypes.oneOf([
    DEPARTMENTS.SERVIZI_DEMOGRAFICI,
    DEPARTMENTS.STATO_CIVILE,
  ]),
};

export default List;
