import React, { FC, useCallback, useMemo, useState } from 'react';
import { SectionTitle } from '../../common/SectionTitle';
import { AddFamiliar } from '../../common/AddFamiliar';
import { Button, StyledComponentProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import { FamilyMember } from '../../api/model';
import { FamilyPanel } from '../../common/FamilyPanel';
import { useDialog } from '../../common/upload/useDialog';
import { FamiliarDialog } from '../../common/FamiliarDialog';
import { ChevronLeft } from '@material-ui/icons';
import { userPressedBack } from '../../../../stm/socialServices/common.events';
import { isEqual } from 'lodash';
import { userSelectFamilyMembers } from '../../../../stm/socialServices/newProcedure.events';

const styles = (theme: any) => ({
  footerContainer: {
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    width: '100%',
    alignSelf: 'stretch',
    padding: '24px 24px 48px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    height: '220px',
    alignSelf: 'flex-start',
    gap: '24px',
    padding: '0px 24px',
    marginLeft: 'auto',
    maxWidth: '1100px',
    marginRight: 'auto',
    flexWrap: 'wrap',
    flex: 1,
  },
  component: { height: '220px', width: '340px' },
});

const SelectFamilyMember: FC<StyledComponentProps> = ({ classes = {} }) => {
  const { state, dispatch } = useNewSocialService();
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>(
    state.context?.defaults?.familyMembers ?? []
  );

  const { open, openDialog, closeDialog } = useDialog();

  const onSave = useCallback(
    (fam: FamilyMember) => {
      setFamilyMembers(fams => [...fams, fam]);
      closeDialog();
    },
    [closeDialog]
  );

  const onEdit = useCallback((fam: FamilyMember) => {
    setFamilyMembers(fams => {
      const copiedFams = [...fams];
      copiedFams.splice(
        copiedFams.findIndex(f => f.id === fam.id),
        1,
        fam
      );
      return copiedFams;
    });
  }, []);

  const onRemove = useCallback(
    (id: string) => {
      setFamilyMembers(fams => fams.filter(fam => fam.id !== id));
    },
    [setFamilyMembers]
  );

  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  const confirm = useCallback(() => {
    dispatch(userSelectFamilyMembers(familyMembers));
  }, [dispatch, familyMembers]);

  const hasChanged = useMemo(
    () => !isEqual(state.context?.defaults?.familyMembers ?? [], familyMembers),
    [familyMembers, state.context]
  );

  const forbiddenCfs = useMemo(() => {
    return [
      ...([state.context?.beneficiary?.fiscalCode] ?? []),
      ...familyMembers.map(fm => fm.fiscalCode),
    ];
  }, [state, familyMembers]);

  return (
    <>
      <SectionTitle>Inserisci i membri del nucleo familiare</SectionTitle>
      <div className={classes.container}>
        {familyMembers.map(mem => (
          <div key={mem.id} className={classes.component}>
            <FamilyPanel
              forbiddenCFValues={forbiddenCfs.filter(
                cfs => cfs !== mem.fiscalCode
              )}
              item={mem}
              onEdit={onEdit}
              onRemove={onRemove}
            />
          </div>
        ))}

        <div className={classes.component}>
          <AddFamiliar handleClick={openDialog} />
        </div>
      </div>
      <FamiliarDialog
        open={open}
        onClose={closeDialog}
        onSave={onSave}
        forbiddenCFValues={forbiddenCfs}
      />
      <div className={classes.footerContainer}>
        <Button variant="flat" color="primary" onClick={goBack}>
          <ChevronLeft />
          Indietro
        </Button>
        {hasChanged ? (
          <Button variant="raised" color="primary" onClick={confirm}>
            Conferma i dati e prosegui
          </Button>
        ) : (
          <Button color="primary" variant="outlined" onClick={confirm}>
            Salta questo passaggio
          </Button>
        )}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedSelectFamilyMember = withStyles(styles)(SelectFamilyMember);

export { DecoratedSelectFamilyMember as SelectFamilyMember };
