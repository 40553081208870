import CallManager from './AxiosCallManager';

export default {
  login: ({ email, password, reason }, onSuccess, onError) => {
    CallManager.post(
      '/auth/login',
      response => {
        onSuccess(response.data);
      },
      onError,
      {
        email: email,
        password: password,
        reason: reason,
      }
    );
  },
  logout: (onSuccess, onError) => {
    CallManager.post('/auth/logout', onSuccess, onError);
    window.history.pushState(null, null, 'login');
  },
  checkAuth: (onSuccess, onError) => {
    CallManager.get('/profile', response => onSuccess(response.data), onError);
  },

  activateAccount: (
    token,
    password,
    passwordConfirmation,
    onSuccess,
    onError
  ) => {
    CallManager.patch(`/invites/${token}`, onSuccess, onError, {
      password: password,
      password_confirmation: passwordConfirmation,
    });
  },

  getInvite: (token, onSuccess, onError) => {
    CallManager.get(
      `/invites/${token}`,
      response => onSuccess(response.data),
      onError
    );
  },

  passwordForgot: (email, onSuccess, onError) => {
    CallManager.post('/password/forgot', onSuccess, onError, { email: email });
  },

  passwordReset: (
    token,
    email,
    password,
    passwordConfirmation,
    onSuccess,
    onError
  ) => {
    CallManager.post('/password/reset', onSuccess, onError, {
      token: token,
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
    });
  },
};
