import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import { getLabelEvento } from '../../constants/common';
import { Divider } from '@material-ui/core';

const styles = () => ({
  dialog: {
    minWidth: '400px',
  },
});

class EventDetails extends React.Component {
  closeModal = () => {
    this.setState(
      {
        show: false,
      },
      () => {
        if (this.props.onClose) this.props.onClose();
      }
    );
  };

  render() {
    const { event, show, classes, children } = this.props;
    return (
      <Dialog
        disableBackdropClick={true}
        open={show}
        onClose={this.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {event && (
          <DialogTitle id="alert-dialog-title">
            {getLabelEvento(event.type)}
          </DialogTitle>
        )}
        {event && (
          <DialogContent className={classes.dialog}>
            <DialogContentText id="alert-dialog-description">
              {event.performed_by && (
                <span>
                  <b>Effettuato da:</b> {event.performed_by.first_name}{' '}
                  {event.performed_by.last_name}
                  <br />
                </span>
              )}
              <b>Data: </b> {event.occurred_on}
              <br />
              <Divider style={{ margin: '15px 0' }} />
              {children ? (
                React.cloneElement(children, { event: event })
              ) : (
                <span />
              )}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={this.closeModal} color="primary" autoFocus>
            CHIUDI
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(observer(EventDetails));

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

EventDetails.defaultProps = {
  show: false,
};
