import React, { FC, useCallback } from 'react';
import { withStyles, StyledComponentProps, Button } from '@material-ui/core';
import { ROUTES_SERVIZI_SOCIALI } from '../../../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const styles = (theme: any) => ({
  container: {
    marginTop: '180px',
    alignSelf: 'center',
    width: '430px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  image: {
    marginBottom: '24px',
    backgroundColor: '#fdd8dd',
    color: theme.palette.font.redDark,
    borderRadius: '48px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: '700',
    marginBottom: '16px',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    marginBottom: '56px',
  },
});

const NotFound: FC<StyledComponentProps & RouteComponentProps> = ({
  classes = {},
  history,
}) => {
  const goToList = useCallback(() => {
    history.push(`/${ROUTES_SERVIZI_SOCIALI.LISTA_PRATICHE}`);
  }, [history]);

  return (
    <div className={classes.container}>
      <img
        src={require('../../../../assets/troll/benkenobi.gif')}
        alt="kenobi"
        className={classes.image}
      />
      <label className={classes.title}>Nessun risultato</label>
      <label className={classes.text}>
        Questa non è la pratica che stai cercando
      </label>
      <Button
        variant="outlined"
        color="primary"
        onClick={goToList}
        className={classes.button}
      >
        Torna alla lista
      </Button>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecorateNotFound = withStyles(styles)(withRouter(NotFound));

export { DecorateNotFound as NotFound };
