import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Subsection from './Subsection';
import { Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import Loader from './Loader';
import EmptyListMessage from './EmptyListMessage';
import Edilizia from '../../api/Edilizia';
import Proroghe from '../../api/Proroghe';
import ConfirmButton from './ConfirmButton';
import store from '../../store';
import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Visibility as DetailsIcon } from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ExtensionStatus from './ProcedureExtensionStatus';
import { CircularProgress } from '@material-ui/core';
import { FileDownload } from '@material-ui/icons';

const styles = theme => ({
  listContainer: {
    contents: {
      padding: 0,
      margin: 0,
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    height: '50px',
    borderBottom: '1px solid #eee',
    padding: '20px',
    alignItems: 'center',
  },
  listItemContent: {
    height: '100%',
    alignItems: 'center',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  smallActionButton: {
    minWidth: '40px',
    marginLeft: '10px',
  },
  smallActionButtonIcon: {
    height: '19px',
  },
  documentList: {
    width: '100%',
    maxHeight: '400px',
    listStyle: 'none',
    marginBottom: '10px',
    padding: 0,
  },
  documentListItem: {
    border: '1px solid ' + theme.palette.grey[300],
    backgroundColor: theme.palette.white.main,
    padding: '10px 20px',
    borderRadius: '1px',
    marginBottom: '5px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  documentIcon: {
    fontSize: '20px',
    display: 'flex',
  },
});

const BiggerTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '14px!important',
  },
}))(Tooltip);

export const ProcedureExtensionDetails = withStyles(styles)(props => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState(undefined);
  const [extensions, setExtensions] = useState([]);

  const { classes, procedure, loadExtensions, onApprove, onReject } = props;

  const loadExtensionsList = useCallback(() => {
    setLoading(true);
    setExtensions([]);
    loadExtensions(
      procedure.id,
      extensions => {
        setExtensions(extensions);
        setLoading(false);
      },
      () => {
        setExtensions([]);
        setLoading(false);
      }
    );
  }, [loadExtensions, setExtensions, procedure]);

  useEffect(() => {
    loadExtensionsList();
  }, [loadExtensionsList]);

  const onAction = useCallback(
    (callback, extensionId, action) => {
      const actionCallback =
        action === 'approve'
          ? Edilizia.approveExtension
          : Edilizia.rejectExtension;
      const parentCallback = action === 'approve' ? onApprove : onReject;
      actionCallback(
        procedure.id,
        extensionId,
        extension => {
          callback();
          setExtensions(extensions => {
            const index = extensions.findIndex(e => {
              return (e.fid = extension.id);
            });
            extensions[index] = extension;
            return extensions;
          });
          parentCallback();
          store.app.snackBar.open('Operazione effettuata con successo');
        },
        () => {
          store.app.snackBar.open(
            'Si è verificato un errore imprevisto. Riprova più tardi.'
          );
          callback();
        }
      );
    },
    [setExtensions, procedure, onApprove, onReject]
  );

  const onDetailsClick = useCallback(
    index => {
      const extension = extensions[index];
      setSelectedExtension(extension);
      setShowModal(true);
      setLoadingDocuments(true);
      Proroghe.getExtensionDocuments(
        extension.id,
        documents => {
          setDocuments(documents);
          setLoadingDocuments(false);
        },
        () => {
          setDocuments([]);
          setLoadingDocuments(false);
        }
      );
    },
    [extensions, setSelectedExtension, setShowModal]
  );

  const onDialogClose = useCallback(() => {
    setShowModal(false);
    setSelectedExtension(undefined);
    setDocuments([]);
    setLoadingDocuments(false);
  }, [setShowModal]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <i
          className={classNames(classes.headerIcon, 'nc-icon nc-time-alarm')}
        />
        <span className={classes.headerTitle}>Proroghe</span>
      </div>
      <Subsection noMargin>
        <Loader size={40} loading={loading} />
        <EmptyListMessage visible={extensions.length === 0 && !loading} />
        <Row>
          <Col xs={12}>
            <ul className={classes.list}>
              {extensions.map((extension, index) => (
                <li key={extension.id} className={classes.listItem}>
                  <Row className={classes.listItemContent}>
                    <Col xs={2}>
                      <ExtensionStatus state={extension.status} />
                    </Col>
                    <Col xs={2}>Proroga al {extension.expires_on}</Col>
                    <Col xs={8} className={classes.actionBar}>
                      {extension.status === 'NEW' && (
                        <ConfirmButton
                          confirmMessage="Vuoi approvare la proroga?"
                          withLoading
                          onConfirm={callback =>
                            onAction(callback, extension.id, 'approve')
                          }
                          text="Sei sicuro di vole approvare la proroga?"
                          buttonLabel="Approva"
                          buttonProps={{
                            style: {
                              marginRight: '10px',
                            },
                            size: 'small',
                            variant: 'raised',
                            color: 'primary',
                          }}
                        />
                      )}
                      {extension.status === 'NEW' && (
                        <ConfirmButton
                          confirmMessage="Vuoi approvare la proroga?"
                          withLoading
                          onConfirm={callback =>
                            onAction(callback, extension.id, 'reject')
                          }
                          text="Sei sicuro di vole rifiutare la proroga?"
                          buttonLabel="Rifiuta"
                          buttonProps={{
                            size: 'small',
                            color: 'error',
                          }}
                        />
                      )}
                      <BiggerTooltip
                        title="Visualizza Dettagli"
                        className={classes.tooltip}
                      >
                        <Button
                          size="small"
                          variant="raised"
                          color="default"
                          className={classes.smallActionButton}
                          onClick={() => onDetailsClick(index)}
                        >
                          <DetailsIcon
                            className={classes.smallActionButtonIcon}
                          />
                        </Button>
                      </BiggerTooltip>
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <DetailsDialog
          open={showModal}
          onDialogClose={onDialogClose}
          extension={selectedExtension}
          documents={documents}
          loadingDocuments={loadingDocuments}
        />
      </Subsection>
    </div>
  );
});

const DetailsDialog = withStyles(styles)(props => {
  const {
    open,
    onDialogClose,
    extension,
    documents,
    loadingDocuments,
    classes,
  } = props;

  const [downloadingDocuments, setDownloadingDocuments] = useState([]);

  const isDownloadingDocument = useCallback(
    documentId => {
      return Boolean(downloadingDocuments.indexOf(documentId) !== -1);
    },
    [downloadingDocuments]
  );

  const callDownloadDocument = useCallback(
    documentId => {
      const docs = Array.from(downloadingDocuments);
      Proroghe.downloadExtensionDocument(
        extension.id,
        documentId,
        () => {
          setDownloadingDocuments(docs.splice(docs.indexOf(documentId), 1));
        },
        () => {
          setDownloadingDocuments(docs.splice(docs.indexOf(documentId), 1));
          store.app.snackBar.open(
            'Si è verificato un errore imprevisto, riprova o contattaci se il problema persiste'
          );
        }
      );
    },
    [downloadingDocuments, setDownloadingDocuments, extension]
  );

  const downloadDocument = useCallback(
    documentId => {
      if (isDownloadingDocument(documentId)) return;
      const docs = Array.from(downloadingDocuments);
      docs.push(documentId);
      setDownloadingDocuments(docs);
      callDownloadDocument(documentId);
    },
    [
      downloadingDocuments,
      setDownloadingDocuments,
      callDownloadDocument,
      isDownloadingDocument,
    ]
  );

  return extension ? (
    <Dialog
      style={{
        fontFamily: 'Lato',
        lineHeight: '30px',
      }}
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={onDialogClose}
      aria-labelledby="share-details-dialog-title"
      aria-describedby="share-details-dialog-details"
    >
      <DialogTitle id="share-details-dialog-title">
        Dettagli Proroga
      </DialogTitle>
      <DialogContent>
        <span>
          <b>Stato: </b>
          <ExtensionStatus state={extension.status} />
          <br />
          <b>Richiesta Proroga al </b>
          {extension.expires_on}
          <br />
          <br />
          <b>Documenti allegati:</b>
          <br />
          <Loader size={30} loading={loadingDocuments} color="primary" />
          <EmptyListMessage
            visible={documents.length === 0 && !loadingDocuments}
            message="Nessun documento allegato."
          />
          {!loadingDocuments && documents.length > 0 && (
            <ul className={classes.documentList}>
              {documents.map(document => (
                <li
                  onClick={() => downloadDocument(document.id)}
                  className={classes.documentListItem}
                  key={document.id}
                >
                  {document.name}
                  {isDownloadingDocument(document.id) ? (
                    <span className={classes.documentIcon}>
                      <CircularProgress
                        size={20}
                        style={{ textAlign: 'center', color: '#141f78' }}
                      />
                    </span>
                  ) : (
                    <span className={classes.documentIcon}>
                      <FileDownload color="primary" />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </span>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  ) : (
    <div />
  );
});

DetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  extension: PropTypes.object,
  documents: PropTypes.array.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
};

ProcedureExtensionDetails.propTypes = {
  procedure: PropTypes.object.isRequired,
  loadExtensions: PropTypes.func.isRequired,
};

export default ProcedureExtensionDetails;
