import React, { FC, useCallback, useState } from 'react';
import { useNewSocialService } from '../../../../stm/socialServices/newSocialService.stm';
import { PersonalSituation } from '../../api/model';
import { SectionTitle } from '../../common/SectionTitle';
import { SERVICE_FLAGS_MAP } from '../../constants';
import { Flag } from '../../common/Flag';
import { Button, StyledComponentProps, withStyles } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { userPressedBack } from '../../../../stm/socialServices/common.events';
import { StyleRules } from '@material-ui/core/styles';
import { userSelectPersonalSituation } from '../../../../stm/socialServices/newProcedure.events';
import {
  FlagTypeDTO,
  WelfareInstanceTypeDTO,
} from '../../../../gen/api/welfare';

const styles = (theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'start',
    alignSelf: 'stretch',
  },
  footer: {
    marginTop: 'auto',
    position: 'sticky',
    background: `linear-gradient(to top, ${theme.palette.white.main} 75%, rgba(255,0,0, 0))`,
    bottom: 0,
    alignSelf: 'stretch',
    padding: '24px 32px 48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export interface SetPersonalSituationProps extends StyledComponentProps {}

export const _SetPersonalSituation: FC<SetPersonalSituationProps> = ({
  classes,
}) => {
  const {
    dispatch,
    state: { context },
  } = useNewSocialService();

  const kind = context?.kind;

  const [data, setData] = useState<PersonalSituation>(
    context?.defaults.flags ?? {}
  );

  const handleChange = useCallback(
    (flag: FlagTypeDTO) => {
      return (value?: string[]) => {
        setData({ ...data, [flag]: value });
      };
    },
    [data]
  );

  const goBack = useCallback(() => {
    dispatch(userPressedBack());
  }, [dispatch]);

  const onSetPersonalSituation = useCallback(() => {
    dispatch(userSelectPersonalSituation(data));
  }, [data, dispatch]);

  if (!kind) {
    console.error(
      'illegal ui state. cannot render personal situation if kind is not selected'
    );
    return null;
  }

  const flags = SERVICE_FLAGS_MAP[kind as WelfareInstanceTypeDTO]; //protocol 3ni

  return (
    <div className={classes?.container}>
      <SectionTitle>
        Compila le informazioni sulla situazione personale
      </SectionTitle>
      {flags.map(flag => {
        return (
          <Flag
            type={flag}
            data={data[flag]}
            onChange={handleChange(flag)}
            key={flag}
          />
        );
      })}
      <div className={classes?.footer}>
        <Button variant="flat" color="primary" onClick={goBack}>
          <ChevronLeft />
          Indietro
        </Button>
        <Button
          variant="raised"
          color="primary"
          onClick={onSetPersonalSituation}
        >
          Avanti
        </Button>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const SetPersonalSituation = withStyles(styles)(_SetPersonalSituation);
