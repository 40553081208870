import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-flexbox-grid';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';

import ListBox from '../common/ListBox';
import ROUTES from '../../constants/routes';
import { VISIBLE_DEPARTMENTS } from '../../constants/security';
import { getDepartmentLabel } from '../../constants/common';

const styles = theme => ({
  bodyStrong: theme.typography.bodyStrong,
});

const ListaGruppi = ({ classes }) => (
  <PageContainer whiteBg header={<PageTitle title="Uffici Comunali" />}>
    {Object.values(VISIBLE_DEPARTMENTS).map(dept => {
      return (
        <ListBox key={dept} goTo={`/${ROUTES.DIPARTIMENTI}/${dept}`}>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <div className={classes.bodyStrong}>
                {getDepartmentLabel(dept)}
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              {dept.description}
            </Col>
          </Row>
        </ListBox>
      );
    })}
  </PageContainer>
);

export default withStyles(styles)(observer(ListaGruppi));
