import React, { FC, useCallback, useEffect, useState } from 'react';
import { SectionTitle } from '../common/SectionTitle';
import { StyleRules } from '@material-ui/core/styles';
import { StyledComponentProps, TextField, withStyles } from '@material-ui/core';
import { ActivityDuration } from '../api/model';
import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment, { Moment } from 'moment';

const styles = (_theme: any): StyleRules => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 48px',
    gap: '32px',
    width: '496px',
  },
});

interface SetDurationProps extends StyledComponentProps {
  value?: ActivityDuration;
  onChange: (data?: ActivityDuration) => void;
}

function changeDateOnly(curr: Moment, next: Moment): Moment {
  const result = moment(curr);
  result.set({ date: next.date(), month: next.month(), year: next.year() });
  return result;
}

const SetDuration: FC<SetDurationProps> = ({
  value,
  onChange: onChangeCallback,
  classes,
}) => {
  const [startDate, setStartDate] = useState(
    value?.startDate ? moment(value?.startDate) : moment().subtract(1, 'hours')
  );
  const [endDate, setEndDate] = useState(
    value?.endDate ? moment(value?.endDate) : moment()
  );

  const onChangeDate = useCallback((date: Moment) => {
    setStartDate(curr => {
      return changeDateOnly(curr, date);
    });
    setEndDate(curr => {
      return changeDateOnly(curr, date);
    });
  }, []);

  const onChangeStartTime = useCallback(e => {
    console.log('onChangeStartTime', e.target.value);
    const [hours, minutes] = e.target.value.split(':');
    setStartDate(curr => {
      const result = moment(curr);
      result.set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
      });
      return result;
    });
  }, []);

  const onChangeEndTime = useCallback(e => {
    const [hours, minutes] = e.target.value.split(':');
    setEndDate(curr => {
      const result = moment(curr);
      result.set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
      });
      return result;
    });
  }, []);

  useEffect(() => {
    onChangeCallback({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  }, [endDate, onChangeCallback, startDate]);

  return (
    <div className={classes?.container}>
      <SectionTitle>Indica la durata totale dell&apos;attività</SectionTitle>
      <div style={{ width: '100%' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            style={{
              width: '100%',
              margin: '10px 0',
            }}
            label="Data del colloquio"
            name="birthday"
            value={startDate}
            onChange={onChangeDate}
            format="DD/MM/YYYY"
            disablePast={false}
            minDate={moment().subtract(120, 'years')}
            maxDate={moment()}
            clearable={true}
            cancelLabel="Annulla"
            clearLabel="Ripristina"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '32px',
            }}
          >
            <TextField
              id="startDate"
              label="Ora di inizio"
              type="time"
              value={startDate.format('HH:mm')}
              onChange={onChangeStartTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 60, // 5 min
                max: endDate.format('HH:mm'),
              }}
            />
            <TextField
              id="endDate"
              label="Ora di fine"
              type="time"
              value={endDate.format('HH:mm')}
              onChange={onChangeEndTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
                min: startDate.format('HH:mm'),
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

const DecoratedSetDuration = withStyles(styles)(SetDuration);
export { DecoratedSetDuration as SetDuration };
