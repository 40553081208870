import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import { ErrorOutline as WarningIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const style = theme => ({
  container: {
    marginTop: '10px',
    backgroundColor: '#FFF',
    border: '1px solid #eee',
    borderRadius: '4px',
    padding: '10px 20px',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorBorder: {
    borderColor: theme.palette.error.main,
  },
  icon: {
    marginRight: '10px',
  },
});

const InfoBox = withStyles(style)(props => {
  const { classes, type, message, visible } = props;

  return visible ? (
    <div
      className={classNames(classes.container, {
        [classes.errorBorder]: type === 'error',
      })}
    >
      <span>
        <Row>
          <Col xs={12} className={classes.messageContainer}>
            <WarningIcon className={classes.icon} color={type} />
            <Typography color={type}>{message}</Typography>
          </Col>
        </Row>
      </span>
    </div>
  ) : (
    <div />
  );
});

InfoBox.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'success']).isRequired,
  message: PropTypes.string,
};

export default InfoBox;
