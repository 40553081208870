import React, { useCallback, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core';
import cx from 'classnames';
import { Col, Row } from 'react-flexbox-grid';
import store from '../../../../store';
import { Bookmark as PinnedIcon } from '@material-ui/icons';
import Subsection from '../../../common/Subsection';
import PropTypes from 'prop-types';
import Document from './Document';
import Condivisione from '../../../../api/Condivisione';
import { getDepartmentLabel } from '../../../../constants/common';
import { formatTimestamp } from '../../../../utils/dateutils';

const styles = theme => ({
  container: {
    width: '100%',
  },
  card: {
    backgroundColor: 'white',
    marginTop: '10px',
    border: '1px solid #eee',
    borderRadius: '4px',
  },
  cardPinned: {
    backgroundColor: '#F1F8FF',
  },
  header: {
    height: '50px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  additionalHeader: {
    borderTop: '1px solid #eee',
  },
  headerIcon: {
    fontSize: '20px',
    marginRight: '10px',
    lineHeight: '100%',
  },
  headerTitle: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    fontWeight: 'normal',
    color: 'black',
    flex: 1,
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  pinnedIcon: {
    color: '#FF965B',
    fontSize: '20px',
  },
  documents: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

const DOCUMENT_EXTENSION = /(?:\.([^.]+))?$/;

const MessageRow = props => {
  const { classes, instance, message, pinned } = props;
  const { profile, snackBar } = store.app;
  const [downloadingDocument, setDownloadingDocument] = useState(undefined);

  const isOwner = useMemo(() => {
    return profile.operatingAs.department === message.department;
  }, [profile, message]);

  const onDocumentClicked = useCallback(
    documentId => {
      const document = message.documents.find(d => d.id === documentId);
      if (!document) return;
      setDownloadingDocument(document.id);
      Condivisione.downloadDocument(
        instance.id,
        document.id,
        document.name,
        () => {
          setDownloadingDocument(undefined);
        },
        () => {
          setDownloadingDocument(undefined);
          snackBar.open('Errore durante il download del documento');
        }
      );
    },
    [instance.id, message.documents, snackBar]
  );

  return (
    <div className={classes.container}>
      <Row>
        {isOwner && <Col xs={2} />}
        <Col
          className={cx({
            [classes.card]: true,
            [classes.cardPinned]: pinned,
          })}
          xs={10}
        >
          <div className={classes.header}>
            {pinned ? (
              <>
                <i
                  className={cx(classes.headerIcon, 'nc-icon nc-ruler-pencil')}
                />
                <span className={classes.headerTitle}>NOTE</span>
                <span className={classes.headerRight}>
                  <PinnedIcon className={classes.pinnedIcon} />
                </span>
              </>
            ) : (
              <>
                <span className={classes.headerTitle}>{`Da ${
                  message.postedByUser.firstName
                } ${message.postedByUser.lastName} (${getDepartmentLabel(
                  message.department
                )})`}</span>
                <span className={classes.headerRight}>
                  {formatTimestamp(message.createdAt)}
                </span>
              </>
            )}
          </div>
          <Subsection>{message.notes}</Subsection>
          {message.documents.length > 0 && (
            <>
              <div className={cx(classes.header, classes.additionalHeader)}>
                <i className={cx(classes.headerIcon, 'nc-icon nc-notes')} />
                <span className={classes.headerTitle}>ALLEGATI</span>
              </div>
              <Subsection>
                <Row>
                  <div className={classes.documents}>
                    {message.documents.map(doc => (
                      <Document
                        key={doc.id}
                        isDownloading={downloadingDocument === doc.id}
                        documentId={doc.id}
                        fileName={doc.name}
                        extension={DOCUMENT_EXTENSION.exec(doc.name)[1]}
                        onClick={onDocumentClicked}
                      />
                    ))}
                  </div>
                </Row>
              </Subsection>
            </>
          )}
        </Col>
        {!isOwner && <Col xs={2} />}
      </Row>
    </div>
  );
};

MessageRow.propTypes = {
  message: PropTypes.object.isRequired,
  instance: PropTypes.object.isRequired,
  pinned: PropTypes.bool,
};

MessageRow.defaultProps = {
  pinned: false,
};

export default withStyles(styles)(MessageRow);
