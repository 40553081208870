import React, { CSSProperties, FC } from 'react';
import { withStyles, StyledComponentProps, Tooltip } from '@material-ui/core';
import cx from 'classnames';
const styles = (theme: any) => ({
  container: {
    flex: 1,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.font.greyLight,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingTop: '8px',
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: theme.palette.grey.dark,
  },
  oneliner: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fieldContainer: {
    padding: '4px 0px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  field: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '600',
    color: theme.palette.black.main,
  },
  tooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
});

interface DetailFieldProps extends StyledComponentProps {
  label: string;
  value: string;
  className?: string;
  style?: CSSProperties;
  oneline?: boolean;
  tooltip?: string;
}
const DetailField: FC<DetailFieldProps> = ({
  classes = {},
  label,
  value = '---',
  style,
  tooltip,
  oneline = false,
  children,
}) => (
  <div className={classes.container} style={style}>
    <label className={classes.label}>{label}</label>
    <div className={classes.fieldContainer}>
      {tooltip && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={value}
          placement="top"
        >
          <label
            className={cx({
              [classes.field as string]: true,
              [classes.oneliner as string]: oneline,
            })}
          >
            {value}
          </label>
        </Tooltip>
      )}
      {!tooltip && (
        <label
          className={cx(classes.field, oneline ? classes.oneliner : undefined)}
        >
          {value}
        </label>
      )}
      <span>{children}</span>
    </div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedDetailField = withStyles(styles)(DetailField);

export { DecoratedDetailField as DetailField };
