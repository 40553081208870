import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StyledComponentProps,
  withStyles,
} from '@material-ui/core';
import { Flag } from '../../serviziSociali/common/Flag';
import { allFlagsList } from '../../serviziSociali/constants';
import { FlagTypeDTO, UpdateCitizenRequest } from '../../../gen/api/welfare';
import {
  CitizenContext,
  PersonalSituation,
} from '../../serviziSociali/api/model';
import store from '../../../store';
import { updateCitizen } from './api';
import { fromContextFlagToApi } from './utils';

const styles = (_theme: any) => ({
  container: {
    width: '900px',
    padding: '16px 42px',
  },
  dialogTitleContainer: {
    borderBottom: '1px solid #DCDCDC',
    fontSize: 24,
    padding: '16px 16px',
  },
  dialogContent: {
    borderBottom: '1px solid #DCDCDC',
    padding: '16px 0 24px 0',
    fontSize: '16px',
    fontFamily: 'Titillium Web',
    fontWeight: 'normal',
    lineHeight: '24px',
  },
  dialogTitle: {
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '150%',
  },
  dialogDescription: {
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
  },
  cancelButton: {
    width: '158px',
  },
  confirmButton: {
    width: '172px',
    marginRight: '16px',
  },
  oadeContainer: {
    width: '432px',
    padding: '16px',
  },
});

interface EditQuestionarioDialogProps extends StyledComponentProps {
  closeDialog: () => void;
  defaultCitizenData: CitizenContext;
  fiscalCode: string;
  updateCitizenContext: (value: CitizenContext) => void;
}

const EditQuestionarioDialog: FC<EditQuestionarioDialogProps> = ({
  classes = {},
  closeDialog,
  defaultCitizenData,
  fiscalCode,
  updateCitizenContext,
}) => {
  const [data, setData] = useState<PersonalSituation>(
    defaultCitizenData?.flags ?? {}
  );

  const handleChange = useCallback(
    (flag: FlagTypeDTO) => {
      return (value?: string[]) => {
        setData({ ...data, [flag]: value });
      };
    },
    [data]
  );

  const [isSaving, setIsSaving] = useState(false);

  const { snackBar } = store.app;

  const save = useCallback(async () => {
    setIsSaving(true);
    try {
      const request: UpdateCitizenRequest = {
        citizenUpdateRequest: {
          familyMembers: defaultCitizenData.familyMembers,
          relativeMembers: defaultCitizenData.relativeMembers,
          flags: fromContextFlagToApi(data),
        },
        fiscalCode,
      };
      const response = await updateCitizen(request);
      updateCitizenContext(response);
      closeDialog();
    } catch (e) {
      snackBar.open('Si è verificato un errore imprevisto');
    }
    setIsSaving(false);
  }, [
    closeDialog,
    data,
    defaultCitizenData,
    fiscalCode,
    snackBar,
    updateCitizenContext,
  ]);

  return (
    <MaterialDialog
      open
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="dialog-title"
      maxWidth={false}
    >
      <DialogTitle className={classes.dialogTitleContainer} id="dialog-title">
        <span className={classes.dialogTitle}>Modifica del questionario</span>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.dialogContent}>
          <span className={classes.dialogDescription}>
            Compila le informazioni sulla situazione personale
          </span>
          {allFlagsList.map(flag => (
            <Flag
              type={flag}
              data={data[flag]}
              onChange={handleChange(flag)}
              key={flag}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          color="primary"
          onClick={closeDialog}
          disabled={isSaving}
        >
          Annulla
        </Button>
        <Button
          className={classes.confirmButton}
          variant="raised"
          color="primary"
          onClick={save}
          disabled={isSaving}
        >
          {isSaving ? <CircularProgress size={24} /> : 'Salva'}
        </Button>
      </DialogActions>
    </MaterialDialog>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const DecoratedEditQuestionarioDialog = withStyles(styles)(
  EditQuestionarioDialog
);
export { DecoratedEditQuestionarioDialog as EditQuestionarioDialog };
