import React, { useMemo } from 'react';
import { Button, withStyles } from '@material-ui/core';
import store from '../../../store';
import { SHARED_INSTANCE_STATUS } from '../../../constants/common';
import { ROLES } from '../../../constants/security';
import { DangerButton } from '../../common/DangerButton';

const styles = _theme => ({
  marginRight: {
    marginRight: 5,
  },
});

const SharingActions = ({ classes, instance, onCloseClick, onAssignClick }) => {
  const { profile } = store.app;

  const isCreator = useMemo(() => {
    return (
      profile.isMemberOf(instance.sharedInstance.ownerDepartment) &&
      instance.sharedInstance.sharedBy.id === profile.user.id
    );
  }, [profile, instance]);

  const isOwner = useMemo(() => {
    return (
      profile.isHigherOrEqualThanForDepartment([
        {
          department: instance.sharedInstance.ownerDepartment,
          role: ROLES.PO,
        },
      ]) || isCreator
    );
  }, [instance.sharedInstance.ownerDepartment, isCreator, profile]);

  const isManager = useMemo(() => {
    return profile.isHigherOrEqualThanForDepartment([
      {
        department: instance.sharedInstance.destDepartment,
        role: ROLES.PO,
      },
    ]);
  }, [instance, profile]);

  const isOpen = instance.sharedInstance.status === SHARED_INSTANCE_STATUS.OPEN;

  return (
    <>
      {isOpen && (
        <>
          {isManager && (
            <Button
              className={classes.marginRight}
              color="primary"
              variant="raised"
              onClick={onAssignClick}
            >
              Assegna
            </Button>
          )}
          {isOwner && (
            <DangerButton
              className={classes.marginRight}
              onClick={onCloseClick}
            >
              Risolvi
            </DangerButton>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(SharingActions);
