import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageTitle from '../../common/PageTitle';
import PageContainer from '../../common/PageContainer';
import Condivisione from '../../../api/Condivisione';
import store from '../../../store';
import Conversation from './Conversation';
import Loader from '../../common/Loader';
import { withRouter } from 'react-router-dom';
import InstanceDetails from './InstanceDetails';
import SharingActions from './SharingActions';
import { ROLES } from '../../../constants/security';
import ConfirmDialog from '../../common/ConfirmDialog';
import SearchUser from '../SearchUser';

const ComponentsMap = [Conversation, InstanceDetails];

export default withRouter(({ match }) => {
  const { instanceId } = match.params;
  const { snackBar, profile } = store.app;
  const [selectedTab, setSelectedTab] = useState(0);
  const [instance, setInstance] = useState();
  const [loading, setLoading] = useState(false);
  const [isAssigningUser, setIsAssigningUser] = useState(false);
  const [closingInstance, setClosingInstance] = useState(false);
  const [isCloseDialogOpen, setCloseDialogOpened] = useState(false);
  const [isAssignDialogOpen, setAssignDialogOpened] = useState(false);

  const loadSharedInstance = useCallback(() => {
    setLoading(true);
    Condivisione.getInstanceDetails(
      instanceId,
      instance => {
        setLoading(false);
        setInstance(instance);
      },
      () => {
        setLoading(false);
        snackBar.open(
          'Si è verificato un errore imprevisto durante il caricamento della condivisione'
        );
      }
    );
  }, [instanceId, snackBar]);

  const isCreator = useMemo(() => {
    return (
      instance &&
      profile.isMemberOf(instance.sharedInstance.ownerDepartment) &&
      instance.sharedInstance.sharedBy.id === profile.user.id
    );
  }, [profile, instance]);

  const isOwner = useMemo(() => {
    return (
      (instance &&
        profile.isHigherOrEqualThanForDepartment([
          {
            department: instance.sharedInstance.ownerDepartment,
            role: ROLES.PO,
          },
        ])) ||
      isCreator
    );
  }, [instance, isCreator, profile]);

  const isManager = useMemo(() => {
    return (
      instance &&
      profile.isHigherOrEqualThanForDepartment([
        {
          department: instance.sharedInstance.destDepartment,
          role: ROLES.PO,
        },
      ])
    );
  }, [instance, profile]);

  useEffect(() => {
    loadSharedInstance();
  }, [loadSharedInstance]);

  const PageContent = useMemo(() => {
    return ComponentsMap[selectedTab];
  }, [selectedTab]);

  const showCloseDialog = useCallback(() => {
    setCloseDialogOpened(true);
  }, []);

  const hideCloseDialog = useCallback(() => {
    setCloseDialogOpened(false);
  }, []);

  const closeInstance = useCallback(() => {
    setClosingInstance(true);
    Condivisione.close(
      instance.sharedInstance.id,
      closedInstance => {
        setClosingInstance(false);
        hideCloseDialog();
        setInstance({
          ...instance,
          sharedInstance: closedInstance,
        });
        snackBar.open('Condivisione interrotta con successo');
      },
      () => {
        setClosingInstance(false);
        hideCloseDialog();
        snackBar.open(
          "Si è verificato un errore imprevisto durante l'interruzione della condivisione"
        );
      }
    );
  }, [hideCloseDialog, instance, snackBar]);

  const showAssignDialog = useCallback(() => {
    setAssignDialogOpened(true);
  }, []);

  const hideAssignDialog = useCallback(() => {
    setAssignDialogOpened(false);
  }, []);

  const assignToUser = useCallback(
    user => {
      setIsAssigningUser(true);
      Condivisione.assign(
        instanceId,
        user.uuid,
        assignedInstance => {
          setIsAssigningUser(false);
          setInstance({
            ...instance,
            sharedInstance: assignedInstance,
          });
          hideAssignDialog();
          snackBar.open('Istanza assegnata con successo');
        },
        () => {
          setIsAssigningUser(false);
          hideAssignDialog();
          snackBar.open("Errore imprevisto durante l'asegnazione dell'istanza");
        }
      );
    },
    [hideAssignDialog, instance, instanceId, snackBar]
  );

  return (
    <PageContainer
      header={
        <PageTitle
          backIcon
          title="Dettagli Richiesta"
          subtitle={`Richiesta n° ${String(instanceId).padStart(6, '0')}`}
          actions={
            !loading &&
            instance && (
              <SharingActions
                instance={instance}
                onCloseClick={showCloseDialog}
                onAssignClick={showAssignDialog}
              />
            )
          }
          tabs={['Dettagli Richiesta', 'Dettagli Istanza']}
          onTabChange={setSelectedTab}
        />
      }
    >
      <Loader loading={loading} />
      {!loading && instance && (
        <>
          {isOwner && (
            <ConfirmDialog
              open={isCloseDialogOpen}
              title="Risolvi Istanza"
              onConfirm={closeInstance}
              loading={closingInstance}
              onCancel={hideCloseDialog}
              message="Dichiarando l'istanza come risolta, gli uffici a cui hai richiesto un parare non potranno più visualizzare i dettagli dell'istanza"
            />
          )}
          {isManager && (
            <SearchUser
              open={isAssignDialogOpen}
              loading={isAssigningUser}
              onUserSelected={assignToUser}
              onClose={hideAssignDialog}
            />
          )}
          <PageContent instance={instance} />
        </>
      )}
    </PageContainer>
  );
});
