import React from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import PageContainer from '../common/PageContainer';
import PageTitle from '../common/PageTitle';
import { Col, Row } from 'react-flexbox-grid';
import { Input } from '@material-ui/core';
import { Button } from '@material-ui/core';
import ROUTES from '../../constants/routes';
import Dipartimenti from '../../api/Dipartimenti';
import { withRouter } from 'react-router-dom';
import ConfirmButton from '../common/ConfirmButton';
import store from '../../store';
import validators from '../../utils/validators';
import Loader from '../common/Loader';
import FormControlValidated from '../common/FormControlValidated';

const styles = theme => ({
  formControl: {
    width: '100%',
    margin: '10px 0px',
    textAlign: 'left',
  },
  labelError: {
    color: theme.palette.error,
  },
  inputLabelFocused: {
    color: theme.palette.primary.main,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inputInkbarError: {
    '&:after': {
      backgroundColor: theme.palette.error,
    },
  },
  table: {
    marginTop: '20px',
  },
  actions: {
    marginTop: '20px',
  },
});

class EditGruppo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      email: '',
      gruppo: {},
      errors: {},
      loadingGruppo: false,
    };
  }

  componentDidMount() {
    const idGruppo = this.props.match.params.idGruppo;
    if (idGruppo) {
      this.setState({ loadingGruppo: true }, () => {
        Dipartimenti.get(
          idGruppo,
          data => {
            this.setState({
              loadingGruppo: false,
              gruppo: data,
              email: data.email,
              description: data.description,
            });
          },
          () => {
            this.setState({ loadingGruppo: false });
          }
        );
      });
    }
  }

  cleanErrorOnField = fieldName => {
    const errors = this.state.errors;
    if (errors[fieldName]) {
      delete errors[fieldName];
    }
    this.setState({ errors: errors });
  };

  handleChangeEmail = event => {
    this.cleanErrorOnField('email');
    this.setState({ email: event.target.value });
  };

  handleChangeDescrizione = event => {
    this.cleanErrorOnField('description');
    this.setState({ description: event.target.value });
  };

  validate = () => {
    const errors = {};

    if (validators.isEmptyString(this.state.description)) {
      errors.description = 'Campo obbligatorio';
    }

    if (validators.isEmptyString(this.state.email)) {
      errors.email = 'Campo obbligatorio';
    } else if (!validators.isValidEmail(this.state.email)) {
      errors.email = 'La email inserita non è valida';
    }

    this.setState({ errors: errors });
    return Object.keys(errors).length === 0;
  };

  save = () => {
    if (this.validate()) {
      Dipartimenti.update(
        this.state.gruppo.id,
        {
          description: this.state.description,
          email: this.state.email,
        },
        () => {
          store.app.snackBar.open('Modifiche effettuate.');
          store.app.checkAuth();
        },
        error => {
          store.app.snackBar.open('Errore nel salvataggio.');
          if (error.status === 422) {
            this.setState({ errors: error.data.details });
          }
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { gruppo, loadingGruppo } = this.state;
    const loading = loadingGruppo;
    return (
      <PageContainer
        header={<PageTitle title="Modifica Dipartimento" backIcon={true} />}
      >
        <Loader size={40} loading={loading} />
        {!loading && (
          <>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <FormControlValidated
                  inputId="name"
                  label="Email Referente"
                  error={this.state.errors.email}
                  shrinkLabel={true}
                >
                  <Input
                    id="email"
                    inputProps={{ tabIndex: 2 }}
                    type="text"
                    label="Email Referente"
                    onChange={this.handleChangeEmail}
                    defaultValue={this.state.email}
                    classes={{
                      inkbar: !this.state.errors.email
                        ? classes.inputInkbar
                        : classes.inputInkbarError,
                    }}
                  />
                </FormControlValidated>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <FormControlValidated
                  inputId="description"
                  label="Descrizione"
                  error={this.state.errors.description}
                  shrinkLabel={true}
                >
                  <Input
                    id="description"
                    inputProps={{ tabIndex: 3 }}
                    type="text"
                    label="Descrizione"
                    onChange={this.handleChangeDescrizione}
                    defaultValue={this.state.description}
                    classes={{
                      inkbar: !this.state.errors.description
                        ? classes.inputInkbar
                        : classes.inputInkbarError,
                    }}
                  />
                </FormControlValidated>
              </Col>
            </Row>
            <Row className={classes.actions}>
              <Col xs={12} sm={12} md={12}>
                <UpdateActions save={this.save} gruppo={gruppo} />
              </Col>
            </Row>
          </>
        )}
      </PageContainer>
    );
  }
}

export default withStyles(styles)(observer(EditGruppo));

const UpdateActions = withRouter(props => {
  return (
    <span>
      <ConfirmButton
        buttonProps={{
          size: 'small',
          variant: 'raised',
          color: 'primary',
        }}
        buttonLabel="Salva"
        onConfirm={props.save}
      />
      &nbsp;&nbsp;
      <Button
        onClick={() =>
          props.history.push(`/${ROUTES.GRUPPI}/${props.gruppo.id}`)
        }
        size="small"
        variant="raised"
        color="default"
      >
        Annulla
      </Button>
    </span>
  );
});
