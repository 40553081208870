import React, { FC, useCallback, useEffect, useState } from 'react';
import PageContainer from '../../common/PageContainer';
import PageTitle from '../../common/PageTitle';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES_SERVIZI_SOCIALI } from '../../../constants/routes';
import { useLocalStateMachine } from '@moveax/redux-stm-act-react-utils';
import {
  SocialServiceListContext,
  socialServiceListStm,
} from '../../../stm/socialServices/socialServiceList.stm';
import { TableProcedure } from './partial/TableProcedure';
import { StaticContext } from 'react-router';
import {
  filterChanged,
  FilterChangedPayload,
} from '../../../stm/socialServices/socialServiceList.events';
import FilterItem from '../../common/filters/Filter';
import { FILTER_TYPE } from '../../common/newFilters/constants';
import GenericListFilters from '../../common/newFilters/GenericListFilters';
import { SERVICE_LABEL_MAP, STATUS_LABEL_MAP } from '../constants';
import moment, { Moment } from 'moment';
import {
  WelfareInstanceSectionDTO,
  WelfareInstanceStatusDTO,
  WelfareInstanceTypeDTO,
} from '../../../gen/api/welfare';
import store from '../../../store';
import { DEPARTMENTS, ROLES } from '../../../constants/security';
import { Citizen } from '../api/model';

type LocationState = FilterChangedPayload;

const typeOptions = Object.entries(SERVICE_LABEL_MAP).map(
  ([value, description]) => ({
    value,
    description,
  })
);

const statusOptions = Object.entries(STATUS_LABEL_MAP).map(
  ([value, description]) => ({
    value,
    description,
  })
);

//TODO: section has been disabled cause filter component does not support it. Code has been commented out for future implementation
const ListaPratiche: FC<RouteComponentProps<
  {},
  StaticContext,
  LocationState
>> = ({ history, location }) => {
  //const [section, setSection] = useState<typeof location.state.section>();
  const [type] = useState(location.state?.type);

  const contextValue = useLocalStateMachine(socialServiceListStm, {
    page: 0,
    filters: { type: location.state?.type },
  });

  const onNew = useCallback(() => {
    history.push(`/${ROUTES_SERVIZI_SOCIALI.NUOVA_PRATICA}`);
  }, [history]);

  // const filteredTypeOptions = useMemo(() => {
  //   if (!section || section.length === 0) return typeOptions;
  //   return typeOptions.filter(({ value }) =>
  //     SECTION_SERVICE_MAP[section[0]].includes(value as WelfareInstanceTypeDTO)
  //   );
  // }, [section]);

  // const sectionModifier = useCallback(
  //   (next: undefined | WelfareInstanceSectionDTO) => {
  //     setSection(next ? [next] : undefined);
  //     return next;
  //   },
  //   []
  // );

  const { profile } = store.app;
  const canCreateInstance = profile.hasRoleIn(
    [ROLES.MANAGER, ROLES.EMPLOYEE, ROLES.PO],
    DEPARTMENTS.SOCIAL_SERVICES,
    true
  );

  const applyFilters = useCallback(
    (
      filters: Partial<{
        typeFilter: WelfareInstanceTypeDTO;
        sectionFilter: WelfareInstanceSectionDTO;
        statusFilter: WelfareInstanceStatusDTO;
        beneficiaryFiscalCodeFilter: string;
        createdAfterFilter: number | null;
        createdBeforeFilter: number | null;
        socialWorkerFilter: { value: Citizen };
      }>
    ) => {
      const {
        sectionFilter,
        typeFilter,
        statusFilter,
        beneficiaryFiscalCodeFilter,
        createdAfterFilter,
        createdBeforeFilter,
        socialWorkerFilter,
      } = filters;
      const sectionArr = sectionFilter ? [sectionFilter] : undefined;
      const typeArr = typeFilter ? [typeFilter] : undefined;
      const statusArr = statusFilter ? [statusFilter] : undefined;
      contextValue.dispatch(
        filterChanged({
          type: typeArr,
          section: sectionArr,
          status: statusArr,
          beneficiaryFiscalCode: beneficiaryFiscalCodeFilter,
          createdAfter: createdAfterFilter
            ? moment.unix(createdAfterFilter).toISOString()
            : undefined,
          createdBefore: createdBeforeFilter
            ? moment.unix(createdBeforeFilter).toISOString()
            : undefined,
          assigneeFiscalCode: socialWorkerFilter?.value.fiscalCode,
        })
      );
    },
    [contextValue]
  );

  useEffect(() => {
    if (location.state?.type) {
      const state = location.state;
      delete state.type;
      history.push({ ...history.location, state });
    }
  }, [history, location.state]);

  return (
    <SocialServiceListContext.Provider value={contextValue}>
      <PageContainer
        key={JSON.stringify(type)}
        whiteBg
        header={
          <PageTitle
            /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
            // @ts-ignore
            title="Elenco istanze"
            caption={
              contextValue.state.context?.pagination
                ? `Totale istanze: ${contextValue.state.context.pagination.totalItemsCount}`
                : undefined
            }
            actions={
              canCreateInstance
                ? [
                    <Button
                      key="new"
                      onClick={onNew}
                      variant="raised"
                      color="primary"
                    >
                      Crea istanza
                    </Button>,
                  ]
                : []
            }
          />
        }
      >
        <GenericListFilters
          initialFilters={{
            typeFilter: type && type.length > 0 ? type[0] : undefined,
          }}
          filters={[
            new FilterItem(
              'typeFilter',
              FILTER_TYPE.SELECT,
              'Tipologia istanza',
              '',
              typeOptions,
              6,
              4,
              4,
              3
            ),
            new FilterItem(
              'statusFilter',
              FILTER_TYPE.SELECT,
              'Stato istanza',
              '',
              statusOptions,
              6,
              8,
              4,
              3
            ),
            new FilterItem(
              'beneficiaryFiscalCodeFilter',
              FILTER_TYPE.TEXT,
              'Codice fiscale beneficiario',
              '',
              null,
              null,
              6,
              4,
              2
            ),
            new FilterItem(
              'socialWorkerFilter',
              FILTER_TYPE.CITIZEN,
              'Assegnata a',
              undefined,
              null,
              6,
              4,
              4,
              4
            ),
            new FilterItem(
              'createdAfterFilter',
              FILTER_TYPE.DATE,
              'Data creazione dal',
              '',
              null,
              6,
              4,
              2,
              2,
              (date: Moment) => (date ? date.startOf('day') : null)
            ),
            new FilterItem(
              'createdBeforeFilter',
              FILTER_TYPE.DATE,
              'Data creazione fino al',
              '',
              null,
              6,
              4,
              2,
              2,
              (date: number | null) =>
                date ? moment.unix(date / 1000).endOf('day') : null
            ),
          ]}
          applyFilters={applyFilters}
        />
        <TableProcedure />
      </PageContainer>
    </SocialServiceListContext.Provider>
  );
};
const DecoratedListaPratiche = withRouter(ListaPratiche);

export { DecoratedListaPratiche as ListaPratiche };
