import { useEffect } from 'react';
import store from '../../store';
import moment from 'moment';
import {
  Notification,
  NotificationAction,
  NotificationMessages,
} from '../types/notifications';

export const useNotifications = (
  onNotificationReceived?: <T>(notification: Notification<T>) => void
) => {
  const { notifications } = store.app.profile.notificationListener;

  useEffect(() => {
    if (!onNotificationReceived) return;
    const latestNotification = Number.parseInt(
      window.localStorage.getItem('latestNotification') ?? '0'
    );
    if (notifications.length) {
      const createdAt = moment(notifications[0].createdAt).unix();
      if (createdAt > latestNotification) {
        window.localStorage.setItem('latestNotification', `${createdAt}`);
        onNotificationReceived(notifications[0]);
      }
    }
  }, [notifications, onNotificationReceived]);

  return {
    markAsRead: store.app.profile.notificationListener.markAsRead,
    formatNotification: (notification: Notification<any>) => {
      return {
        message:
          notification.type in NotificationMessages
            ? NotificationMessages[notification.type](notification.payload)
            : notification.type,
        action:
          notification.type in NotificationAction
            ? NotificationAction[notification.type](notification.payload)
            : undefined,
      };
    },
  };
};
